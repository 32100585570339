import { useParams } from 'react-router-dom'

import { MessageWithHelpLine } from 'domain/app'
import { KitOrder } from 'domain/createOrder/kit'
import { useKitQuery } from 'domain/kits'
import { ErrorBoundary, Loading } from 'ui'

export default function KitOrderPage() {
  const params = useParams<{ id: string }>()
  const { data, isError, isLoading } = useKitQuery(params.id as unknown as number)

  if (isLoading) return <Loading />
  if (isError || !data) return <MessageWithHelpLine title="Ocorreu um erro ao carregar os dados do kit." />

  return (
    <ErrorBoundary>
      <KitOrder kit={data} />
    </ErrorBoundary>
  )
}
