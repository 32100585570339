import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { handleRequestError } from 'api/HandleRequestError'

type ApprovalFn = (body?: any, onSuccess?: () => void) => Promise<void>

function useOrderQuotationApproval(orderId: number, action: 'approve' | 'reject'): [ApprovalFn, boolean] {
  const [isLoading, setLoading] = useState(false)
  const queryClient = useQueryClient()

  const handleOrderBudgetApproval: ApprovalFn = async (body, onSuccess) => {
    try {
      setLoading(true)
      await AuthorizedAPI.put(`/purchases/${orderId}/quote/${action}`, body)
      queryClient.invalidateQueries('orders')
      onSuccess && onSuccess()
    } catch (err) {
      handleRequestError(err)
    } finally {
      setLoading(false)
    }
  }

  return [handleOrderBudgetApproval, isLoading]
}

export default useOrderQuotationApproval
