import React, { useEffect } from 'react'
import { Checkbox, CheckboxProps, Form } from 'antd'
import { NamePath } from 'antd/lib/form/interface'

interface Props extends Omit<CheckboxProps, 'name' | 'value' | 'onChange'> {
  allNotariesQuantity: number
  name: NamePath
  onChange: (checked: boolean) => void
}

function LocationAllNotariesCheck({ disabled, name, allNotariesQuantity, onChange }: Props) {
  const form = Form.useFormInstance()

  useEffect(() => {
    if (allNotariesQuantity === 1) {
      form.setFieldValue(name, true)
      onChange(true)
    }
  }, [allNotariesQuantity])

  return (
    <>
      <Form.Item name={name} valuePropName="checked" noStyle>
        <Checkbox
          disabled={disabled || !allNotariesQuantity}
          onChange={e => onChange(e.target.checked)}
        >
          Todos os cartórios ({allNotariesQuantity} cartórios)
        </Checkbox>
      </Form.Item>

      <hr className="my-5" />
    </>
  )
}

export default LocationAllNotariesCheck
