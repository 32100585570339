import React from 'react'

import { RoutePaths } from 'app/routes'
import { Button } from 'ui'

export default function KeepBuyingButton({ disabled = false }: { disabled?: boolean }) {
  return (
    <Button href={RoutePaths.CREATE_ORDER} size="small" type="primary" ghost disabled={disabled}>
      Adicionar mais itens ao pedido
    </Button>
  )
}
