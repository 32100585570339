import { Form, FormItemProps, Radio } from 'antd'

import { FieldConfig } from 'domain/servicesCBRdoc/fields/types'
import { getFieldName } from 'domain/createOrder/shared'
import useSelectDefaultValue from '../helpers/useSelectDefaultValue'

export interface RadioGroupButtonsFieldProps extends Omit<FormItemProps, 'label'> {
  field: FieldConfig
  options?: any
  itemId?: number | string
  required?: boolean
  autoFocus?: boolean
  value?: any
  onChange?: (value: string) => void
  hasError?: boolean
}

export default function RadioGroupButtonsField({
  field,
  itemId,
  options = field.options,
  required = !field.optional,
  autoFocus,
  onChange,
  value,
  hasError,
  ...props
}: RadioGroupButtonsFieldProps) {
  const name = getFieldName(field.name, itemId)
  const handleChange = onChange ? (e: any) => onChange(e.target.value) : undefined

  useSelectDefaultValue({ field, name, onChange, availableOptions: options })

  return (
    <Form.Item
      name={name}
      label={required ? field.label : `${field.label} (opcional)`}
      rules={required ? requiredRule : undefined}
      help={hasError ? 'Erro ao carregar os dados.' : undefined}
      {...props}
    >
      <Radio.Group value={value} onChange={handleChange} buttonStyle="solid">
        {options?.map((option: any, index: number) => {
          const value = option[field.apiOptionsValueKey ?? 'value']
          const label = option[field.apiOptionsLabelKey ?? 'label']

          return (
            <Radio.Button key={value} value={value} autoFocus={autoFocus && index === 0}>
              {label}
            </Radio.Button>
          )
        })}
      </Radio.Group>
    </Form.Item>
  )
}

const requiredRule = [{ required: true, message: 'Campo obrigatório' }]
