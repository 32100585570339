import { convertToSelectedService } from 'domain/createOrder/document'
import { convertLocationInfo } from 'domain/orderItem'
import { SelectedService } from 'domain/orderItem/types'
import { displayDateAndTime, stringToDate } from 'utils/dateTime'

import {
  Notification,
  NotificationOrder,
  NotificationAPI,
  NotificationOrderAPI,
  LatestNotification,
  NotificationOrderItemAPI,
  NotificationOrderItem,
} from '../types'

export default function convertNotification(notificationAPI: NotificationAPI) {
  const notification = convertNotificationExceptOrderItem(notificationAPI) as unknown as Notification

  if (notificationAPI.data.order) {
    notification.orderItem = convertNotificationOrderItem(notificationAPI.data.order)
  }

  return notification
}

export function convertLatestNotification(notificationAPI: NotificationAPI) {
  const notification = convertNotificationExceptOrderItem(notificationAPI)

  if (notificationAPI.data.order) {
    notification.orderItem = convertNotificationOrderItem(notificationAPI.data.order)
  }

  return notification
}

function convertNotificationExceptOrderItem({ id, data, read_at, created_at }: NotificationAPI) {
  const { type, text, link } = data

  const notification: LatestNotification = {
    id,
    type,
    text,
    linkUrl: link,
    createdAt: stringToDate(created_at) as Date,
    createdAtDisplay: displayDateAndTime(created_at),
    readAt: stringToDate(read_at),
  }

  if (data.purchase) {
    notification.order = convertNotificationOrder(data.purchase)
  }

  return notification
}

function convertNotificationOrder(data: NotificationOrderAPI) {
  const { id, backoffice_id, first_order, name, total_items } = data

  const order: NotificationOrder = {
    id,
    firstOrder: convertNotificationOrderItem(first_order),
    name,
    orderNumber: backoffice_id,
    totalItems: total_items,
  }

  return order
}

function convertNotificationOrderItem(data: NotificationOrderItemAPI) {
  const { ai_service_name, backoffice_id, detailed_service_data, id, location_info, name, service } = data!

  const orderItem: NotificationOrderItem = {
    id,
    orderItemNumber: backoffice_id,
    name,
    isFromPassportWithoutAllData: false,
    locationInfo: convertLocationInfo(location_info, service.code),
    service: convertToSelectedService(service) as SelectedService,
    submitData: detailed_service_data!,
  }

  if (ai_service_name) {
    orderItem.service.name = `IA: ${ai_service_name}`
  }

  return orderItem
}
