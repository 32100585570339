import { v4 as uuidv4 } from 'uuid'

import { CreateOrderState, ShoppingStep } from 'domain/createOrder/types'
import { ShoppingCartOrder } from 'domain/shoppingCart/types'

import { DocumentConfig } from '../types'
import CBRdocDocument from './CBRdocDocument'

export default class SignatureCertification extends CBRdocDocument {
  public isKitAvailable: boolean

  constructor(config: Omit<DocumentConfig, 'steps'> & { steps?: ShoppingStep[] }) {
    super({
      allowMultipleItems: true,
      steps: config.steps ?? ['dados'],
      ...config,
    })

    this.isKitAvailable = config.isKitAvailable ?? true
  }

  protected generateFormItems = (createOrder: CreateOrderState) => {
    const { formItems, files } = createOrder

    if (!files) return formItems
    
    const formItemsGen = formItems
    const filesLen = files.length

    for (let i = 1; i < filesLen; i++) {
      const item = {
        ...formItems['0'],
        id: i,
      }

      Object.assign({...formItemsGen}, { [i]: item })
    }

    return formItemsGen
  }

  convertToCartItems = (createOrder: CreateOrderState) => {
    const { cartItemId, files } = createOrder

    if (files && files.length > 0) {
      const orders = files.map((file, index) => {
        const docItem = this._generateCreateOrderState({
          ...createOrder,
          cartItemId: index > 0 ? uuidv4() : cartItemId,
          files: [file],
          formItems: this.generateFormItems(createOrder),
        })

        const cartItem: ShoppingCartOrder = {
          ...docItem,
          submitData: this.getSubmitData(docItem),
        }

        return cartItem
      })

      return orders
    }

    const orders = this.generateCartItems(createOrder)
    const splittedOrders = orders.map(this.splitOrders).flat()
    return splittedOrders
  }
}
