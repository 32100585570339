import React from 'react'
import { Form } from 'antd'

import { Modal, SubmitButton, TextInput } from 'ui'

interface Props {
  currentName: string
  open: boolean
  onClose: () => void
  onSave: (newName: string) => void
}

function EditableOrderItemNameModal({ currentName, open, onClose, onSave }: Props) {
  const [form] = Form.useForm()

  const handleSubmit = (values: any) => {
    onSave(values.newName)
    onClose()
  }

  return (
    <Modal
      open={open}
      title="Alterar nome do item da compra"
      onCancel={onClose}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
        scrollToFirstError
        initialValues={{
          newName: currentName,
        }}
        className="max-w-lg"
      >
        <TextInput name="newName" label="Nome" required maxLength={120} />

        <SubmitButton label="Alterar" />
      </Form>
    </Modal>
  )
}

export default EditableOrderItemNameModal
