import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaInventarioFields = Record<
  FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ,
  FieldConfig
>

class PesquisaInventario extends Research {
  formFields: PesquisaInventarioFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
  }

  constructor() {
    super({
      description:
        'Retorna os dados de localização da escritura de inventário (estado, cidade e cartório) em caso de resultado positivo.',
      id: 38,
      shortName: 'Pesquisa Inventário',
    })
  }
}

const service = new PesquisaInventario().initialize()

export default service
