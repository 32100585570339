import { OrderItem } from 'domain/orderItem/types'
import { ResearchResultAPI } from 'domain/orderItem/typesAPI'
import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { displayDate } from 'utils/dateTime'
import { formatMoney } from 'utils/formatters'
import { checkIsLabelInValue } from 'utils/validators'

type PesquisaProcessosJudiciaisFields = Record<
  | FieldName.CPF
  | FieldName.CNPJ
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.NUMERO_PROCESSO
  | FieldName.TIPO_PESSOA
  | FieldName.TIPO,
  FieldConfig
>

class PesquisaProcessosJudiciais extends Research {
  formFields: PesquisaProcessosJudiciaisFields = {
    cpf: {
      ...Fields.cpf,
      showIf: props => {
        const tipo = props ? props.tipo ?? props['0']?.tipo : undefined
        if (checkIsLabelInValue(tipo)) {
          return tipo.value ? tipo.value === 'tipo-pessoa' : false
        }
        return tipo ? tipo === 'tipo-pessoa' : true // default value
      },
    },
    cnpj: {
      ...Fields.cnpj,
      showIf: props => {
        const tipo = props ? props.tipo ?? props['0']?.tipo : undefined
        if (checkIsLabelInValue(tipo)) {
          return tipo.value ? tipo.value === 'tipo-pessoa' : false
        }
        return tipo ? tipo === 'tipo-pessoa' : true // default value
      },
    },
    nome_pf: {
      ...Fields.nome_pf,
      showIf: props => {
        const tipo = props ? props.tipo ?? props['0']?.tipo : undefined
        if (checkIsLabelInValue(tipo)) {
          return tipo.value ? tipo.value === 'tipo-pessoa' : false
        }
        return tipo ? tipo === 'tipo-pessoa' : true // default value
      },
    },
    nome_pj: {
      ...Fields.nome_pj,
      showIf: props => {
        const tipo = props ? props.tipo ?? props['0']?.tipo : undefined
        if (checkIsLabelInValue(tipo)) {
          return tipo.value ? tipo.value === 'tipo-pessoa' : false
        }
        return tipo ? tipo === 'tipo-pessoa' : true // default value
      },
    },
    tipo_pessoa: {
      ...Fields.tipo_pessoa,
      showIf: props => {
        const tipo = props ? props.tipo ?? props['0']?.tipo : undefined
        if (checkIsLabelInValue(tipo)) {
          return tipo.value ? tipo.value === 'tipo-pessoa' : false
        }
        return tipo ? tipo === 'tipo-pessoa' : true // default value
      },
    },
    numero_processo: {
      ...Fields.numero_processo,
      optional: false,
      showIf: props => {
        const tipo = props ? props.tipo ?? props['0']?.tipo : undefined
        if (checkIsLabelInValue(tipo)) {
          return tipo.value ? tipo.value === 'num-processo' : false
        }
        return tipo ? tipo === 'num-processo' : false
      },
      orderNameText: 'Processo',
      dataScope: 'registree',
      kind: 'text',
      apiRemoveMask: true,
    },
    tipo: {
      ...Fields.tipo,
      apiExtraInfo: undefined,
      options: [
        {
          url: 'tipo-pessoa',
          nome: 'Selecionar pelo documento',
        },
        {
          url: 'num-processo',
          nome: 'Selecionar pelo número do processo',
        },
      ],
    },
  }

  constructor() {
    super({
      description:
        'Retorna o envolvimento do CPF/CNPJ pesquisado em ações judiciais e seus respectivos detalhamentos.',
      id: 41,
      shortName: 'Pesquisa Processos Judiciais',
      orderNameByShowIf: true,
    })
  }

  convertResults = (orderItem: OrderItem, results: ResearchResultAPI[] | null) => {
    if (!results || !results.length) {
      return []
    }

    return results.map((result: any, index) => {
      const { assuntos } = result
      const converted = this._convertResult(orderItem, result, index)

      converted.assuntos = assuntos ? assuntos.join(',') : ''
      converted.data_publicacao = displayDate(result.data_publicacao)
      converted.valor = formatMoney(result.valor)
      converted.partes_envolvidas = result.partes_envolvidas?.map(
        ({ nome, documento, polo }: any) => `${nome} - ${documento} - ${polo}`
      ) ?? ['']

      return converted
    })
  }
}

const service = new PesquisaProcessosJudiciais().initialize()

export default service
