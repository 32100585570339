import { useDrag } from 'react-dnd'

import { Icon, TextButton } from 'ui'

type Props = {
  itemIndex: number
  onMoveStart: () => void
}

export default function ItemsCollapseLabel({ itemIndex, onMoveStart }: Props) {
  return (
    <div className="flex flex-row items-center gap-2 relative">
      <ItemDragHandler onMoveStart={onMoveStart} />
      <span className="text-secondary font-bold">Assinador(a) ({itemIndex + 1})</span>
    </div>
  )
}

function ItemDragHandler({ onMoveStart }: { onMoveStart: () => void }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'card',
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  return (
    <div onClick={e => e.stopPropagation()}>
      <TextButton
        ref={drag}
        className={`cursor-move ${isDragging ? 'opacity-50' : ''}`}
        label={<Icon name="drag-handler" />}
        onMouseDown={onMoveStart}
      />
    </div>
  )
}
