import { CartOrderDetails, useShoppingCartState } from 'domain/shoppingCart'

import { useCheckoutStep } from '../state'
import ConfirmAddOrderItemModal from './ConfirmAddOrderItemModal'

export default function CheckoutOrderDetailsModal() {
  const { orderId } = useShoppingCartState()
  const [checkoutStep, setStep] = useCheckoutStep()
  const open = checkoutStep === 'order-details'

  const onClose = () => setStep('idle')

  return orderId ? (
    <ConfirmAddOrderItemModal open={open} orderId={orderId} onClose={onClose} />
  ) : (
    <CartOrderDetails open={open} onCancel={onClose} isCheckout />
  )
}
