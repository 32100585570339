import React from 'react'

import { ErrorMessage } from 'domain/app'
import { Notifications, useNotificationsQuery, useSetInitialValuesFromURL } from 'domain/notifications'
import { PageHeader } from 'layouts'
import { Loading } from 'ui'

function NotificationsPage() {
  useSetInitialValuesFromURL()

  const { data, error, isFetching, isLoading } = useNotificationsQuery()

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <ErrorMessage error={error} />
  }

  return (
    <>
      <PageHeader title="Notificações" />
      <Notifications notifications={data} loading={isFetching} />
    </>
  )
}

export default NotificationsPage
