import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoExtratoDebitosMunicipaisFields = Record<
  FieldName.URL_UF | FieldName.URL_CIDADE | FieldName.CPF | FieldName.CNPJ | FieldName.INSCRICAO_IMOVEL,
  FieldConfig
>

class CertidaoExtratoDebitosMunicipais extends Certificate {
  formFields: CertidaoExtratoDebitosMunicipaisFields  = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    cpf: Fields.cpf,
    cnpj: Fields.cnpj,
    inscricao_imovel: Fields.inscricao_imovel,
  }

  constructor() {
    super({
      id: 107,
      shortName: 'Extrato de Débitos Municipais',
      steps: ['dados'],
      priceByFields: [FieldName.URL_UF, FieldName.URL_CIDADE],
      availableFormatsFields: [FieldName.URL_UF, FieldName.URL_CIDADE],
    })
  }
}

const service = new CertidaoExtratoDebitosMunicipais().initialize()

export default service
