import { useEffect, useState } from 'react'

import { showErrorModal } from 'api/errors'
import { Button } from 'ui'
import { setStorageData } from 'utils/localStorage'
import useLoginWithToken from '../services/useLoginWithToken'
import { useMsal } from '@azure/msal-react'

const scopes = ['User.Read']

export default function MicrosoftSignIn() {
  const { instance: msalInstance } = useMsal()
  const [disabled, setDisabled] = useState(true)
  const loginWithToken = useLoginWithToken()
  const active = msalInstance.getActiveAccount()

  const msLogin = async () => {
    try {
      const tokenResponse = await msalInstance.handleRedirectPromise()

      if (tokenResponse?.accessToken) {
        await loginWithToken(tokenResponse.accessToken)
      } else {
        const authenticationResultWithAccessToken = await msalInstance.acquireTokenSilent({ scopes })

        if (authenticationResultWithAccessToken?.accessToken) {
          await loginWithToken(authenticationResultWithAccessToken.accessToken)
        }
      }
    } catch {
      //
    } finally {
      setDisabled(false)
    }
  }

  useEffect(() => {
    if (active) {
      msLogin()
    } else {
      setDisabled(false)
    }
  }, [active])

  const onClick = async () => {
    setDisabled(true)
    setStorageData('sso', { provider: 'microsoft' })

    try {
      if (active) {
        await msLogin()
      } else {
        await msalInstance.loginRedirect({ scopes })
      }
    } catch (error) {
      setDisabled(false)
      showErrorModal(error)
    }
  }

  return (
    <Button
      htmlType="button"
      type="default"
      size="middle"
      ghost
      icon="microsoft"
      onClick={onClick}
      disabled={disabled}
      className="flex-1 text-sm border-gray-300 text-gray-700 hover:border-primary hover:text-primary"
    >
      Continue com Microsoft
    </Button>
  )
}
