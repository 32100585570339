export type RealStateSearchFor =
  | 'matricula'
  | 'transcricao'
  | 'endereco'
  | 'condominio'
  | 'protocolo'
  | 'registro_livro3'
  | 'nome'
  | 'registro_imovel'
  | 'nome_pactuantes'

export const SearchForLabels: {
    [key in RealStateSearchFor]: string
  } = {
    matricula: 'Matrícula',
    endereco: 'Endereço',
    transcricao: 'Transcrição',
    condominio: 'Condomínio',
    protocolo: 'Protocolo',
    registro_livro3: 'Registro Livro 3',
    nome: 'Nome',
    registro_imovel: 'Número de registro',
    nome_pactuantes: 'Nome dos pactuantes',
  }

export type TipoCertidaoImovel = {
  url: string
  name: string
  description: 'string'
}
