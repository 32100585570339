import { useEffect } from 'react'

import { CartItems, CartItemsCountTag, OrderName } from 'domain/shoppingCart'
import KeepBuyingButton from 'domain/shoppingCart/components/KeepBuyingButton'
import ClearCartButton from 'domain/shoppingCart/components/ClearCartButton'

export default function ShoppingCartPage() {
  useEffect(() => {
    document.body.classList.add('jivoHideChat')

    return () => {
      document.body.classList.remove('jivoHideChat')
    }
  }, [])

  return (
    <>
      <div className="flex bg-gray-300 pb-4 pt-5 px-7 border-b border-gray-400 relative justify-between">
        <h1 className="text-secondary-500 flex-col leading-7 truncate w-full max-w-lg flex">
          <div className="w-fit flex items-center gap-4 mb-1 text-xl font-bold">
            Carrinho
            <CartItemsCountTag />
          </div>
          <OrderName />
        </h1>

        <div className="flex items-center gap-4">
          <KeepBuyingButton />
          <ClearCartButton />
        </div>
      </div>

      <CartItems />
    </>
  )
}
