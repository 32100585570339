import React from 'react'

import StepStatus, { StepStatusProps } from './StepStatus'

export interface StepProps extends Omit<StepStatusProps, 'stepIndex'> {
  className?: string
}

export default function Steps({ steps, current, status, className }: StepProps) {
  return (
    <div className={`flex-1 ${className}`}>
      <div className="flex justify-between relative">
        <div className="h-6 box-content border-2 border-gray-200 bg-gray-200 rounded-full absolute z-0 left-0 right-0"></div>

        <div className="bg-white rounded-full absolute z-0 left-0 right-0 m-3 border-t-2 border-white"></div>

        {steps.map((step, stepIndex) => {
          const isLastStep = steps.length - 1 === stepIndex

          return (
            <div
              key={stepIndex}
              className={`p-1 pb-0 relative z-10 ${isLastStep ? 'flex flex-col items-end' : ''}`}
            >
              <div
                className={`flex bg-white items-center rounded-full ${!step.date ? 'w-5' : ''} ${
                  isLastStep ? 'flex-row-reverse' : ''
                }`}
                style={{ width: step.date ? '93px' : 'auto' }}
              >
                <StepStatus steps={steps} current={current} stepIndex={stepIndex} status={status} />

                {step.date && (
                  <div className="px-1 pt-px text-2xs text-gray-600 text-center leading-snug">
                    {step.date}
                  </div>
                )}
              </div>
              <div className="mt-4 leading-none text-4xs uppercase font-bold text-gray-500">
                {step.title}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
