import React, { useEffect } from 'react'
import { cnpj as cnpjUtils } from 'cpf-cnpj-validator'
import { useHistory, useParams } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { RegisterForm } from 'domain/customers'
import { GuestLayout, LogoMessage } from 'layouts'

export default function RegisterCnpj2() {
  const history = useHistory()
  const { cnpj } = useParams<any>()

  useEffect(() => {
    if (!cnpjUtils.isValid(cnpj, true)) {
      history.push(RoutePaths.REGISTER_CNPJ)
    }
  }, [cnpj])

  return (
    <GuestLayout customHeader={<LogoMessage message={`CNPJ: ${cnpjUtils.format(cnpj)}`} />} showMessage>
      <h1 className="text-3xl font-light text-gray-700 mb-5">Crie sua conta de acesso</h1>

      <RegisterForm cnpj={cnpj} />
    </GuestLayout>
  )
}
