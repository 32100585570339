import React, { useState } from 'react'

import { handleRequestError } from 'api/HandleRequestError'
import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'
import { useGetTableSelection, useResetTableSelection } from 'domain/notifications/state'
import { DrawerActions } from 'ui'

function NotificationMassDelete() {
  const [loading, setLoading] = useState(false)
  const selection = useGetTableSelection()
  const resetSelection = useResetTableSelection()

  const onClick = async () => {
    try {
      setLoading(true)

      await AuthorizedAPI.delete(`/notifications/${selection.keys.join(',')}`)

      setLoading(false)
      resetSelection()
      queryClient.invalidateQueries(['notifications'])
    } catch (error) {
      setLoading(false)
      handleRequestError(error)
    }
  }

  return (
    <DrawerActions.Button icon="trash" loading={loading} onClick={onClick}>
      Excluir
    </DrawerActions.Button>
  )
}

export default NotificationMassDelete
