import { LabelInValue } from 'domain/app/types'
import { Location } from 'domain/servicesCBRdoc/types'

export function convertSelectOptionToLocation(
  location: LabelInValue | LabelInValue[] | undefined,
  portuguese = false
): Location | Location[] | undefined {
  if (!location) return undefined

  if (Array.isArray(location)) {
    return location.map(({ label, value }) => ({ [portuguese ? 'nome' : 'name']: label, url: value } as Location))
  }

  return { [portuguese ? 'nome' : 'name']: location.label, url: location.value! as string } as Location
}

export function convertLocationToSelectOption(
  location: Location | Location[] | undefined,
  multiple?: boolean
) {
  if (!location) return undefined

  const convert = ({ sigla, name, nome, url }: Location) => ({
    label: sigla ?? nome ?? name,
    value: url,
  })

  const converted = Array.isArray(location) ? location.map(convert) : convert(location)

  if (multiple) {
    return Array.isArray(converted) ? converted : [converted]
  }

  return Array.isArray(converted) ? converted[0] : converted
}
