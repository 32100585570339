import { PermissionName } from './types'

export const REDIRECT_URI = `${window.location.origin}/oauth`

export const MICROSOFT_CLIENT_ID = 'c7173a1d-8686-42e1-8f5a-18b646a2a098'

export const PermissionsLabels: Record<PermissionName, string> = {
  canApprovePurchasesQuotes: 'Aprovar cotações de compras',
  canDeleteOtherUsersUploadedFiles: 'Excluir os arquivos de outros usuários',
  canDeleteOwnUploadedFiles: 'Excluir os próprios arquivos',
  canInsertCredits: 'Inserir créditos',
  canManageAiModels: 'Administrar modelos de Extração de Dados',
  canManageUsersAndCompanyData: 'Administrar Usuários e Dados da Empresa',
  canOrderServices: 'Fazer pedidos',
  canSeeInvoices: 'Emitir faturas',
  canSeeOtherUsersOrders: 'Ver pedidos de outros usuários',
  canSeeOwnOrders: 'Ver os próprios pedidos',
  canSeeReports: 'Acessar relatórios',
  canShareOrders: 'Pode compartilhar pedidos',
  canUploadExplorerItems: 'Fazer upload de arquivos',
  canExtractDataUsingAi: 'Fazer pedidos de extração de dados'
}
