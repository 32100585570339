import { DueDiligence } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { ARQUIVOS, LOCAL_SERVICO, MENSAGEM } = FieldName

type DiligenciaFields = Record<
  FieldName.ARQUIVOS | FieldName.LOCAL_SERVICO | FieldName.MENSAGEM,
  FieldConfig
>

class DiligenciaAtasNotariais extends DueDiligence {
  formFields: DiligenciaFields = {
    [ARQUIVOS]: Fields.arquivos,
    [LOCAL_SERVICO]: {
      ...Fields.local_servico,
      placeholder: 'Informe onde a diligência será feita',
      dataScope: 'service',
    },
    [MENSAGEM]: Fields.mensagem,
  }

  constructor() {
    super({
      id: 110,
      shortName: 'Ata notarial',
      steps: ['dados'],
      displayIsNewTag: true,
    })
  }
}

const service = new DiligenciaAtasNotariais().initialize()

export default service
