import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'

import { Routes } from 'app/routes'
import { useCurrentUser } from 'domain/auth'
import {
  useCurrentPage,
  usePageSizeState,
  useSelectedRows,
  useSetSelectedRows,
  useTotalResults,
} from 'domain/kits/state'
import { Kit, KitItem } from 'domain/kits/types'
import { Empty, Icon, Pagination, Tag } from 'ui'
import { displayDateWithMonth } from 'utils/dateTime'

import KitRowActions from './KitsTableRowActions'
import KitsTableDocuments from './KitsTableDocuments'
import KitsTableMassActions from './KitsTableMassActions'

export interface Props {
  kits: Kit[]
  refetch: () => void
  isFetching: boolean
}

export default function KitsTable({ kits, isFetching, refetch }: Props) {
  const setSelectedRows = useSetSelectedRows()
  const [selected] = useSelectedRows()

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      className: 'md:min-w-56',
      render: (text: string, kit: any) => {
        return (
          <LinkToKit kitId={kit.id} ownerId={kit.owner.id} className="text-secondary-500 font-bold text-base">
            <Icon name="folder" className="text-secondary-500 w-4 h-4 mr-3" />
            {text}
          </LinkToKit>
        )
      },
    },
    {
      title: 'Tipo Pessoa',
      dataIndex: 'legalEntity',
      key: 'legalEntity',
      render: (legalEntity: string, kit: any) => {
        return (
          <LinkToKit kitId={kit.id} ownerId={kit.owner.id}>
            {legalEntity === 'fisica'
              ? 'Pessoa Física'
              : legalEntity === 'juridica'
                ? 'Pessoa Jurídica'
                : 'Indefinido'}
          </LinkToKit>
        )
      },
    },
    {
      title: 'Documentos',
      dataIndex: 'items',
      key: 'items',
      className: 'md:max-w-336',
      render: (items: KitItem[]) => <KitsTableDocuments items={items} />,
    },
    {
      title: 'Qtd.',
      dataIndex: 'items',
      key: 'qtde',
      render: (items: KitItem[]) => <Tag>{items.length}</Tag>,
    },
    {
      title: 'Responsável',
      dataIndex: ['owner', 'name'],
      key: 'owner',
      // width: 120,
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 110,
      render: (date: string) => <>{displayDateWithMonth(date)}</>,
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 110,
      render: (text: string, record: Kit) => <KitRowActions kit={record} refetch={refetch} />,
    },
  ]

  if (kits.length === 0) {
    return <Empty heading="Nenhum kit encontrado com os parâmetros informados." />
  }

  return (
    <>
      <Table
        dataSource={kits}
        rowKey={record => record.id}
        columns={columns}
        pagination={false}
        loading={isFetching}
        rowSelection={{
          selectedRowKeys: selected.map(s => s.id),
          onChange: e => setSelectedRows(kits.filter(({ id }) => e.includes(id))),
        }}
      />
      <KitsPagination />
      <KitsTableMassActions onFinish={refetch} />
    </>
  )
}

function KitsPagination() {
  const [currentPage, setCurrentPage] = useCurrentPage()
  const [pageSize, setPageSize] = usePageSizeState()
  const totalResults = useTotalResults()

  return (
    <Pagination
      className="pb-7"
      totalCount={totalResults}
      currentPage={currentPage}
      onChangePage={setCurrentPage}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
    />
  )
}

interface LinkToKitsProps {
  kitId: number
  ownerId: number
  children: React.ReactNode
  className?: string
}

function LinkToKit({ children, kitId, ownerId, className }: LinkToKitsProps) {
  const user = useCurrentUser()

  if (user.id !== ownerId) {
    return <span className={className}>{children}</span>
  }

  return (
    <Link className={className} to={Routes.EditKit(kitId)}>
      {children}
    </Link>
  )
}
