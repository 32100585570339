import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoDadosCadastraisImovelFields = Record<
  FieldName.URL_UF | FieldName.URL_CIDADE | FieldName.CPF | FieldName.CNPJ | FieldName.INSCRICAO_IMOVEL,
  FieldConfig
>

class CertidaoDadosCadastraisImovel extends Certificate {
  formFields: CertidaoDadosCadastraisImovelFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    cpf: Fields.cpf,
    cnpj: Fields.cnpj,
    inscricao_imovel: Fields.inscricao_imovel,
  }

  constructor() {
    super({
      id: 108,
      shortName: 'Certidão de Dados Cadastrais do Imóvel',
      steps: ['dados', 'entrega'],
      priceByFields: [FieldName.URL_UF, FieldName.URL_CIDADE],
      availableFormatsFields: [FieldName.URL_UF, FieldName.URL_CIDADE],
    })
  }
}

const service = new CertidaoDadosCadastraisImovel().initialize()

export default service
