import React, { useState } from 'react'
import { notification } from 'antd'

import {
  CreateRecurrenceForm as CreateRecurrenceFormType,
  CreateRecurrenceItem,
  CreateRecurrenceKind,
} from 'domain/recurrences/types'
import { Modal } from 'ui'
import { removeFromArrayById } from 'utils/transformData'

import useCreateRecurrence from '../../services/useCreateRecurrence'
import RecurrenceForm from './RecurrenceForm'
import CreateRecurrenceItems from './CreateRecurrenceItems'
import { OrderItemListing } from 'domain/orderItem/types'
import { Group } from 'domain/groups/types'
import { getUniqueGroups } from 'domain/groups/helpers'

type Props = {
  kind: CreateRecurrenceKind
  items: OrderItemListing[] | CreateRecurrenceItem[]
  openModal: boolean
  onCloseModal: () => void
  onSuccess?: () => void
  orderGroups?: Group[]
}

export default function CreateRecurrence({
  items,
  kind,
  openModal,
  onCloseModal,
  onSuccess,
  orderGroups,
}: Props) {
  const associatedGroups: Group[] = getUniqueGroups(items as OrderItemListing[])

  return (
    <Modal title="Criar Recorrência" open={openModal} onCancel={onCloseModal} destroyOnClose>
      <CreateRecurrenceForm
        groups={kind === 'order-items' ? associatedGroups : orderGroups}
        onSuccess={() => {
          onCloseModal()

          notification.success({
            message: 'Recorrência criada com sucesso',
            description:
              'Você pode visualizar e editar os pedidos recorrentes no menu lateral em “Recorrências”',
          })

          onSuccess && onSuccess()
        }}
        items={items}
        kind={kind}
      />
    </Modal>
  )
}

function CreateRecurrenceForm({
  groups,
  items,
  kind,
  onSuccess,
}: Pick<Props, 'items' | 'kind'> & {
  groups?: OrderItemListing['groups']
  onSuccess: () => void
}) {
  const [formData, setFormData] = useState<CreateRecurrenceFormType>({
    name: '',
    startsAt: undefined,
    frequency: '',
    items,
    daysOfWeek: [],
    groups: groups || [],
    notify: false,
  })

  const { status, submit } = useCreateRecurrence({
    kind,
    onSuccess,
  })

  const onRemoveItem = (itemId: number) => {
    const items = removeFromArrayById(formData.items, itemId)
    setFormData({ ...formData, items })
  }

  return (
    <RecurrenceForm
      header={
        kind === 'order-items' && (
          <CreateRecurrenceItems items={formData.items} kind={kind} onRemove={onRemoveItem} />
        )
      }
      formData={formData}
      setFormData={setFormData as any}
      status={status}
      onFinish={submit as any}
      buttonLabel="Criar recorrência"
    />
  )
}
