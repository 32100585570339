import React from 'react'
import { Form } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useDispatch } from 'react-redux'

import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document/state'
import { Extras } from 'domain/createOrder/types'

import ExtraCheckbox, { ExtraCheckboxProps } from '../../../shared/components/ExtraCheckbox'

interface Props extends Omit<ExtraCheckboxProps, 'onChange'> {
  name: keyof Extras
}

function ExtraCheckboxField({ name, ...props }: Props) {
  const dispatch = useDispatch()
  const { selectedExtras } = useCreateOrderState()

  const onChange = (e: CheckboxChangeEvent) => {
    dispatch(
      createOrderActions.setSelectedExtras({
        ...selectedExtras,
        [name]: e.target.checked,
      })
    )
  }

  return (
    <Form.Item name={name} valuePropName="checked" className="mb-0">
      <ExtraCheckbox {...props} checked={!!selectedExtras?.[name]} onChange={onChange} />
    </Form.Item>
  )
}

export default ExtraCheckboxField
