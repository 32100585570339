import React from 'react'

import { useGetExplorerItem } from 'domain/myFiles/state'
import { ViewPDF } from 'ui'

export default function ViewFileContent() {
  const explorerItem = useGetExplorerItem()!

  if (!explorerItem.file) return null

  if (explorerItem.file.contentType === 'application/pdf') {
    return (
      <ViewPDF fileUrl={explorerItem.file.signedUrl} fileName={explorerItem.name} />
    )
  }

  return (
    <div className=" m-4 flex justify-center items-center">
      <img
        src={explorerItem.file.signedUrl}
        alt={explorerItem.file.signedUrl}
        className="object-contain h-auto w-auto border border-white rounded shadow-md"
      />
    </div>
  )
}
