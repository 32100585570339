import React from 'react'

import { DrawerActions } from 'ui'
import MyModelsMassDelete from './MyModelsMassDelete'
import { AICreateOrderModel } from 'domain/openAI/types'

interface Props {
  selected: AICreateOrderModel[]
  onClose: () => void
}

export default function MyModelsMassActions({ selected, onClose }: Props) {
  return (
    <DrawerActions onClose={onClose} total={selected.length}>
      <MyModelsMassDelete selected={selected} onClose={onClose} />
    </DrawerActions>
  )
}
