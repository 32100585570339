import React from 'react'

import { ActionsBar, ListViewOptions, ListViewOptionsType } from 'ui'

import OrderSelectedResearchResults from './OrderSelectedResearchResults'

interface Props {
  tableView: ListViewOptionsType
  setTableView: (mode: ListViewOptionsType) => void
}

function ViewOrderResearchActionsBar({ tableView, setTableView }: Props) {
  return (
    <ActionsBar>
      <OrderSelectedResearchResults />

      <div className="flex">
        <ListViewOptions selected={tableView} onChange={setTableView} />
      </div>
    </ActionsBar>
  )
}

export default ViewOrderResearchActionsBar
