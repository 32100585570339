import { KitLegalEntity } from 'domain/kits/types'
import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { getLocationUrl } from 'domain/servicesCBRdoc/helpers/location'
import { Location, SelectedLocation } from 'domain/servicesCBRdoc/types'

const { CIDADE_NASCIMENTO_IBGE, FORMATO, TIPO_PESSOA, URL_CARTORIO, URL_CIDADE, URL_UF } = FieldName

type CertidaoInterdicaoFields = Record<
  | FieldName.ANO_APROX_ATO
  | FieldName.CIDADE_NASCIMENTO_IBGE
  | FieldName.CPF
  | FieldName.CNPJ
  | FieldName.DATA_NASCIMENTO
  | FieldName.NOME_MAE
  | FieldName.NOME_PAI
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.RG
  | FieldName.RG_EXPEDIDOR
  | FieldName.UF_NASCIMENTO
  | FieldName.URL_CARTORIO
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

class CertidaoInterdicao extends Certificate {
  formFields: CertidaoInterdicaoFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    url_cartorio: Fields.url_cartorio,
    cpf: Fields.cpf,
    cnpj: Fields.cnpj,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
    mae: Fields.mae,
    pai: Fields.pai,
    nascimento: Fields.nascimento,
    uf_nascimento: {
      ...Fields.uf_nascimento,
      ignoreOnKitsAutomaticForm: true,
    },
    [CIDADE_NASCIMENTO_IBGE]: {
      ...Fields.cidade_nascimento,
      ignoreOnKitsAutomaticForm: true,
    },
    rg: Fields.rg,
    rg_expedidor: {
      ...Fields.rg_expedidor,
      optional: true,
    },
    ano_aproximado_ato: Fields.ano_aproximado_ato,
  }

  private cidadesComPJ = ['BELFORD_ROXO', 'ITABORAI', 'RIO_DE_JANEIRO']

  constructor() {
    super({
      availableFormatsFields: [URL_CARTORIO, URL_CIDADE, URL_UF],
      id: 4,
      priceByFields: [FORMATO, URL_CARTORIO, URL_CIDADE, URL_UF, TIPO_PESSOA],
      shortName: 'Certidão Interdição',
      steps: ['localizacao', 'dados', 'entrega'],
    })
  }

  getAvailableCities = (locations: Location[], legalEntity: KitLegalEntity | undefined) => {
    if (legalEntity !== 'fisica') {
      return locations.filter(o => this.cidadesComPJ.includes(o.url))
    }

    return locations
  }

  getAvailableFederativeUnits = (locations: Location[], legalEntity: KitLegalEntity | undefined) => {
    if (legalEntity !== 'fisica') {
      return locations.filter(o => o.url === 'RJ')
    }

    return locations
  }

  hasPJ = (location: SelectedLocation) => {
    const { url_cidade, url_uf } = getLocationUrl(location)
    return url_uf === 'RJ' && url_cidade && this.cidadesComPJ.includes(url_cidade)
  }
}

const service = new CertidaoInterdicao().initialize()

export default service
