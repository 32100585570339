import React from 'react'

interface Props {
  children: React.ReactNode
  short?: boolean
  className?: string
  borderTop?: boolean
  customStyle?: React.CSSProperties
  customPadding?: string
  innerClassName?: string
  customBackgroundColor?: string
  justifyContent?:
    | 'justify-start'
    | 'justify-end'
    | 'justify-center'
    | 'justify-between'
    | 'jusity-around'
    | 'justify-evenly'
}

export default function ActionsBar({
  children,
  borderTop,
  short = false,
  className = '',
  customStyle = undefined,
  customPadding = '',
  innerClassName = '',
  justifyContent = 'justify-between',
  customBackgroundColor,
}: Props) { 
  return (
    <div
      style={customStyle}
      className={`flex border-x-0 border-solid border-b border-gray-300 
        ${borderTop ? "border-t mt-2" : "border-t-0"}
        ${customPadding ?? "px-7 py-3" }
        bg-[${customBackgroundColor ?? "#e5e7eb"}]
        ${className}
      `}
    >
      <div
        className={`flex flex-col md:flex-row flex-1 items-center gap-6 ${justifyContent} ${innerClassName} ${
          short ? 'max-w-lg' : ''
        }`}
      >
        {children}
      </div>
    </div>
  )
}
