import { useMemo, useState } from 'react'
import { List, message } from 'antd'

import { errorHandler } from 'api/errors'
import { useCurrentUser, useHasPermission } from 'domain/auth'
import { useSelectedRows } from 'domain/kits/state'
import { deleteKit } from 'domain/kits/services'
import { Kit } from 'domain/kits/types'
import { DrawerActions, Icon, Modal, TextButton } from 'ui'

interface Props {
  onFinish: () => void
}

export default function KitsTableMassActions({ onFinish }: Props) {
  const currentUser = useCurrentUser()
  const [notOwnerError, setNotOwnerError] = useState<Kit[]>([] as Kit[])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedKits, setSelectedKits] = useSelectedRows()
  const isAdmin = useHasPermission('canManageUsersAndCompanyData')

  const disableDelete = useMemo(() => {
    if (!isAdmin) return true
    return selectedKits.filter(k => k.owner.id === currentUser.id).length === 0
  }, [selectedKits, isAdmin])

  function deleteItems() {
    message.loading('Excluindo itens selecionados...')

    const itemsNotOwnedByUser = selectedKits.filter(k => k.owner.id !== currentUser.id)
    if (itemsNotOwnedByUser.length > 0) {
      message.error('Alguma coisa deu errado ao tentar excluir seus itens')
      setNotOwnerError(itemsNotOwnedByUser)
      return
    }

    Promise.allSettled(selectedKits.map(async item => await deleteKit(item.id)))
      .then(data => {
        const error = (data.find(res => res.status === 'rejected') as PromiseRejectedResult | undefined)
          ?.reason

        if (error) {
          throw new Error(error)
        }

        message.success('Todos os itens foram excluídos com sucesso!')
        onFinish()
        setSelectedKits([])
        setShowDeleteModal(false)
      })
      .catch(err => {
        errorHandler(err)
      })
  }

  return (
    <>
      <DrawerActions total={selectedKits.length} onClose={() => setSelectedKits([])}>
        <DrawerActions.Button icon="trash" disabled={disableDelete} onClick={() => setShowDeleteModal(true)}>
          Excluir
        </DrawerActions.Button>
      </DrawerActions>

      <Modal
        open={showDeleteModal}
        title="Você deseja excluir os itens selecionados?"
        okText="Confirmar"
        okButtonProps={{
          disabled: selectedKits.length === 0,
        }}
        cancelText="Cancelar"
        onOk={deleteItems}
        onCancel={() => {
          setShowDeleteModal(false)
        }}
      >
        <List
          dataSource={selectedKits}
          renderItem={item => (
            <List.Item key={item.id}>
              <List.Item.Meta avatar={<Icon name="folder" />} title={item.name} />
              <TextButton
                label="Remover"
                onClick={() => setSelectedKits(prev => prev.filter(p => p.id !== item.id))}
              />
            </List.Item>
          )}
        />
      </Modal>

      <Modal
        open={notOwnerError.length > 0}
        title="Você não tem permissão para excluir os itens a seguir"
        okText="Continuar"
        cancelText="Cancelar"
        onOk={() => {
          setSelectedKits(prev => prev.filter(i => !notOwnerError.includes(i)))
          setNotOwnerError([])
        }}
        onCancel={() => {
          setShowDeleteModal(false)
        }}
      >
        <>
          <p className="mb-2">Deseja remover estes itens para continuar?</p>

          <div className="flex flex-col gap-3">
            {Object.values(notOwnerError)?.map((message, index) => (
              <p key={index}>#{message.id} - {message.name}</p>
            ))}
          </div>
        </>
      </Modal>
    </>
  )
}
