import React from 'react'
import { Spin } from 'antd'

import { formatMoney } from 'utils/formatters'

interface Props {
  header?: React.ReactNode
  children?: React.ReactNode
  loading?: boolean
  totalPrice: number | null
}

function TotalPrice({ header, children, loading, totalPrice }: Props) {
  return (
    <div className="w-full pt-1 mb-6 border-t-2 border-gray-700 text-sm">
      {header}

      {loading ? (
        <div className="border-t border-gray-200 w-full text-center p-7">
          <Spin />
          <div className="text-gray-600 text-2xs mt-3">Calculando</div>
        </div>
      ) : (
        <>
          {Boolean(totalPrice) && (
            <div className="flex justify-between py-4 items-end border-b border-gray-300">
              <div className="text-gray-600">Valor do pedido:</div>
              <div className="font-bold text-xl text-gray-600">
                {formatMoney(totalPrice!)}
              </div>
            </div>
          )}

          {children}
        </>
      )}
    </div>
  )
}

export default TotalPrice
