import React, { useReducer } from 'react'

import { useGetMyFilesFilters } from 'domain/myFiles/state'
import { FilterButton } from 'ui'

import MyFilesFilterModal from './MyFilesFilterModal'

function MyFilesFilter() {
  const [modalOpen, toggleModalOpen] = useReducer(prev => !prev, false)
  const filters = useGetMyFilesFilters()

  return (
    <>
      <MyFilesFilterModal isOpen={modalOpen} toggleModal={toggleModalOpen} />
      <FilterButton activeFilters={filters} onClick={toggleModalOpen} />
    </>
  )
}

export default MyFilesFilter
