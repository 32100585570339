import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'

import { errorHandler } from 'api/errors'
import { RoutePaths } from 'app/routes'
import { MissingFieldsError } from 'domain/createOrder'
import { OrderMissingFieldsModal, useAddResearchResultsToCart } from 'domain/createOrder/researchResults'
import { MissingFields } from 'domain/createOrder/types'
import { useGetOrderItem } from 'domain/orderItem/shared'

import {
  useClearSelectedResults,
  useGetCountSelectedResults,
  useGetSelectedResults,
} from '../state/selectedResearchResults'
import useResultsToOrder from '../helpers/useResultsToOrder'

export default function OrderSelectedResearchResults() {
  const history = useHistory()
  const [missingFields, setMissingFields] = useState([] as any[])
  const [loading, setLoading] = useState(false)
  const selectedResults = useGetSelectedResults()
  const addResearchResultsToCart = useAddResearchResultsToCart()
  const clearSelectedOrders = useClearSelectedResults()
  const orderItem = useGetOrderItem()
  const resultsToOrder = useResultsToOrder(
    orderItem,
    selectedResults.find(o => o.orderItem.id === orderItem.id)?.selected ?? []
  )

  const orderAllSelectedResults = async () => {
    let orderWithMissingFields: MissingFields[] = []

    setLoading(true)

    for (const selectedOrder of selectedResults) {
      const results = selectedOrder.selected

      try {
        await addResearchResultsToCart(results, selectedOrder.orderItem)
      } catch (error) {
        if (error instanceof MissingFieldsError) {
          orderWithMissingFields = [...orderWithMissingFields, ...error.data]
        } else {
          setLoading(false)
          errorHandler(error, { code: '0x695' })
        }
      }
    }

    setLoading(false)

    if (orderWithMissingFields.length > 0) {
      setMissingFields(orderWithMissingFields)
    } else {
      clearSelectedOrders()
      history.push(RoutePaths.SHOPPING_CART)
    }
  }

  const onClose = () => {
    setLoading(false)
    setMissingFields([])
  }

  const { canOrder, reason } = resultsToOrder.any

  return (
    <>
      <Button
        onClick={orderAllSelectedResults}
        size="small"
        type="primary"
        disabled={!canOrder}
        loading={loading}
        title={!canOrder ? reason : 'Solicitar certidões dos resultados selecionados'}
      >
        Solicitar
        <TotalItemsCount />
      </Button>

      <OrderMissingFieldsModal missingFields={missingFields} onClose={onClose} />
    </>
  )
}

function TotalItemsCount() {
  const totalItems = useGetCountSelectedResults()

  if (!totalItems) {
    return null
  }

  return (
    <span className="ml-2 inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-red-400 rounded-full">
      {totalItems}
    </span>
  )
}
