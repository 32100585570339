import { AuthorizedAPI } from 'api/network/v2'
import { PlaceOrderResponseAPI } from 'domain/createOrder/types'
import { CheckoutData, PaymentMethod } from 'domain/checkout/types'
import {
  AnyDetailedService,
  ShoppingCartOrder,
  ShoppingCartPostAPI,
  ShoppingCartPostOrderItemData,
} from 'domain/shoppingCart/types'
import { ServiceType } from 'domain/servicesCBRdoc/types'

export type ShoppingCartData = {
  shoppingCartId: string
  creditsToInsert: number
  endpoint: string
  isCustomerPostPaid: boolean
  orderItems: ShoppingCartOrder[]
}

async function placeOrderAPI(
  { orderItems, endpoint, isCustomerPostPaid, creditsToInsert, shoppingCartId }: ShoppingCartData,
  { orderName, paymentMethod, shippingAddress, groups }: CheckoutData
): Promise<PlaceOrderResponseAPI> {
  const isOrderPostpaid = isCustomerPostPaid
    ? false
    : creditsToInsert > 0 && paymentMethod?.chosenMethod !== PaymentMethod.CREDIT_CARD

  const orderItemsPostData = orderItems.map(({ selectedService, submitData }) => {
    const {
      auto_purchase_certificate_from_result_negative,
      auto_purchase_certificate_from_result_positive,
      name,
      origin,
      ...serviceDetails
    } = submitData

    const postData: ShoppingCartPostOrderItemData = {
      name,
      detailed_service_data: serviceDetails as AnyDetailedService,
      origin,
      service_id: selectedService.id,
      service_category_id: selectedService.categoryId,
    }

    if (selectedService.type === ServiceType.RESEARCH) {
      postData.auto_purchase_certificate_from_result_positive =
        auto_purchase_certificate_from_result_positive ?? false
      postData.auto_purchase_certificate_from_result_negative =
        auto_purchase_certificate_from_result_negative ?? false
    }

    return postData
  })

  const orderToPlace: ShoppingCartPostAPI = {
    shopping_cart_id: shoppingCartId,
    name: orderName,
    groups_ids: groups.map(g => g.id),
    post_payment: isOrderPostpaid,
    orders: orderItemsPostData,
  }

  if (isOrderPostpaid) {
    orderToPlace.post_payment_method = paymentMethod!.chosenMethod
  }

  if (groups.length > 0) {
    orderToPlace.groups_ids = groups.map(g => g.id)
  }

  const isAddressNeeded = orderItems.some(item => item.submitData.formato !== 'email')

  if (isAddressNeeded && shippingAddress) {
    orderToPlace.shipping_address_public_place = shippingAddress.address_public_place
    orderToPlace.shipping_address_number = shippingAddress.address_number
    orderToPlace.shipping_address_complement = shippingAddress.address_complement
    orderToPlace.shipping_address_neighborhood = shippingAddress.address_neighborhood
    orderToPlace.shipping_address_uf = shippingAddress.address_uf
    orderToPlace.shipping_address_city = shippingAddress.address_city
    orderToPlace.shipping_address_zip_code = shippingAddress.address_zip_code
  }

  const response = await AuthorizedAPI.post(endpoint, orderToPlace)

  return response.data
}

export default placeOrderAPI
