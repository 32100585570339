import { ValidationError } from 'domain/checkout/types'
import { PurchaseAPIValidationError } from 'domain/checkout/typesAPI'
import { getFieldConfigByAPIName } from 'domain/servicesCBRdoc'

class PurchaseValidationError extends Error {
  data: ValidationError[]

  constructor(errors: PurchaseAPIValidationError) {
    super('Faltam dados para solicitar o serviço.')
    Object.setPrototypeOf(this, PurchaseValidationError.prototype)

    const entries = Object.entries(errors)
    const errorsByIndex: any = {}

    entries.forEach(([key, messages]) => {
      const parts = key.split('.')
      const serviceIndex = parts[1]
      const apiFieldName = parts[3]
      const currentMessages = errorsByIndex[serviceIndex]?.errors          
      let errors = [...messages]

      errors = errors.map(error => error.replace(`orders.${serviceIndex}.`, ''))
      errors = errors.map(error => error.replace('detailed service data.', ''))
      errors = errors.map(error => error.replace(apiFieldName, getFieldLabel(apiFieldName)))

      if (currentMessages === undefined) {
        errorsByIndex[serviceIndex] = {
          index: serviceIndex,
          errors,
        }
      } else {
        errorsByIndex[serviceIndex].errors = [...currentMessages, ...errors]
      }
    })

    this.data = Object.values(errorsByIndex)
  }
}

const getFieldLabel = (apiFieldName: string) => {
  if (apiFieldName === 'name') {
    return 'Nome'
  }

  const fieldConfig = getFieldConfigByAPIName(apiFieldName)

  if (fieldConfig) {
    return fieldConfig.label
  }

  return apiFieldName
}

export default PurchaseValidationError
