import { useShoppingCartState } from '../state'
import CardView from './list/CartItemsView'
import EmptyCart from './EmptyCart'
import CartResume from './CartResume'

export default function ShoppingCartItems() {
  const { orders } = useShoppingCartState()

  if (!orders || orders.length === 0) {
    return <EmptyCart />
  }

  return (
    <div className="flex h-full w-full gap-1 max-w-screen-lg mx-auto pb-4">
      <div className="flex-1 px-2">
        <CardView orders={orders} />
      </div>
      <CartResume />
    </div>
  )
}
