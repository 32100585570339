import React from 'react'
import { Form, Modal as AntModal, Spin, Button } from 'antd'
import { useDispatch } from 'react-redux'

import { useCurrentCustomer } from 'domain/customers'
import { useOrderOverviewQuery } from 'domain/orders'
import { CartOrderDetailsForm } from 'domain/shoppingCart'
import { shoppingCartStateActions } from 'domain/shoppingCart'
import { Modal } from 'ui'

import { useGetGroups, useSetCheckoutStep, useSetOrderDetailsConfirmed } from '../state'

interface Props {
  open: boolean
  onClose: () => void
  orderId: number
}

export default function ConfirmAddOrderItemModal({ orderId, open, onClose }: Props) {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const setOrderDetailsConfirmed = useSetOrderDetailsConfirmed()
  const setCheckoutStep = useSetCheckoutStep()
  const { data, isFetching } = useOrderOverviewQuery(orderId, true)
  const groups = useGetGroups()
  const customer = useCurrentCustomer()

  const onContinue = (action: 'existent-order' | 'new-order') => {
    return () => {
      const mustSelectGroup = customer.orderGroupRequired && groups.length === 0

      if (mustSelectGroup) {
        AntModal.error({ title: 'Grupo Associado', content: 'Você precisa associar o pedido a um grupo.' })
      } else {
        if (action === 'new-order') {
          dispatch(shoppingCartStateActions.setOrderId(undefined))
        }

        setOrderDetailsConfirmed(true)
        setCheckoutStep('in-progress')
      }
    }
  }

  return (
    <Modal open={open} title="Você está adicionando um item à compra" onCancel={onClose}>
      <CartOrderDetailsForm form={form} isCheckout hideOrderName>
        <hr className="mb-6" />

        <Spin spinning={isFetching}>
          <p className="text-base">
            Os itens serão adicionados à compra{' '}
            <strong>
              {data?.name} - #{data?.orderNumber}{' '}
            </strong>
            .
          </p>
        </Spin>

        <p className="font-bold mt-4 mb-2">Deseja continuar?</p>

        <Button type="primary" className="w-full mt-6" onClick={onContinue('existent-order')}>
          Incluir no pedido existente
        </Button>

        <Button type="link" className="w-full mt-4" onClick={onContinue('new-order')}>
          Incluir em um novo pedido
        </Button>
      </CartOrderDetailsForm>
    </Modal>
  )
}
