import React from 'react'

import { useCreateOrderState } from 'domain/createOrder/document/state'
import { CreateOrderFormItem } from 'domain/createOrder/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { ServiceTypeDescription } from 'domain/servicesCBRdoc/data'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { ServiceType } from 'domain/servicesCBRdoc/types'
import DeleteItem from './DeleteItem'

interface Props extends Omit<CPFOrCNPJProps, 'itemId'> {
  deletable: boolean
  item: CreateOrderFormItem
}

export default function ItemDetails({ deletable, item, ...props }: Props) {
  const { selectedService, selectedType } = useCreateOrderState()

  if (selectedService.type === ServiceType.DOCUMENT_REGISTRATION && selectedType !== 'com-assinaturas') {
    return null
  }

  return (
    <div className="flex justify-between items-center">
      <span className="text-secondary-500">
        &bull; <DocumentOrName itemId={item.id} {...props} />
      </span>

      {deletable && <DeleteItem key={item.id} itemId={item.id} />}
    </div>
  )
}

interface CPFOrCNPJProps {
  itemId: number
  itemIndex: number
}

function DocumentOrName({ itemIndex, itemId }: CPFOrCNPJProps) {
  const { formData, selectedService } = useCreateOrderState()
  let documentOrName =
    formData[itemId]?.[NOME_PF] ??
    formData[itemId]?.[NOME_PJ] ??
    formData[itemId]?.cpf ??
    formData[itemId]?.cnpj ??
    ''

  if (!documentOrName) {
    const orderName = itemId !== undefined ? formData[itemId]?.[NOME_ITEM_PEDIDO] : NOME_ITEM_PEDIDO

    if (orderName) {
      const config = getServiceConfig(selectedService.code!)
      documentOrName = orderName.replace(`${config.shortName ?? ''} - `, '')
    }
  }

  if (documentOrName) {
    return <span className="text-ellipsis overflow-hidden">{documentOrName}</span>
  }

  return (
    <span>
      {ServiceDescription[selectedService.type!]} {itemIndex + 1}
    </span>
  )
}

const ServiceDescription = {
  ...ServiceTypeDescription,
  [ServiceType.DOCUMENT_REGISTRATION]: 'Assinador(a)',
}

const { NOME_PF, NOME_PJ, NOME_ITEM_PEDIDO } = FieldName
