import saveAs from 'file-saver'
import ViewAIActions from './ViewAIActions'
import { OpenAIQuestions } from 'domain/openAI/enrich'
import { ViewCertificateTabs } from 'domain/orderItem/certificate'
import { ViewOrderLayout, useGetOrderItem } from 'domain/orderItem/shared'
import { Icon } from 'ui'
import { useMemo } from 'react'

export default function ViewAI() {
  const { explorerItem, ocr } = useGetOrderItem()
  const { aiModel, file } = explorerItem ?? {}

  if (!file) return null

  const handleDonloadXML = () => {
    saveAs(
      new Blob([aiModel?.questions[0].answer!]),
      `cbrdoc-IA-item-pedido-${explorerItem?.orderItemId}.xml`
    )
  }

  const canDownloadXml = useMemo(() => {
    if (!aiModel) return false
    if (aiModel.questions.length === 0) return false
    return aiModel.downloadAsXml
  }, [aiModel])

  return (
    <ViewOrderLayout
      showSidebar
      extraOptions={
        canDownloadXml ? [
          {
            label: 'Baixar em XML',
            key: 'extra.0',
            icon: <Icon name="xml" color="#527eed" className="text-base mr-2" />,
            onClick: handleDonloadXML,
          },
        ] : undefined
      }
      downloadButton={<ViewAIActions />}
    >
      <ViewCertificateTabs
        ocr={ocr!}
        fileUrl={file.signedUrl}
        onChange={() => null}
        openAITab={<OpenAIQuestions questions={aiModel?.questions}></OpenAIQuestions>}
      />
    </ViewOrderLayout>
  )
}
