import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { ExplorerItemsSearchResult } from '../types'
import { ExplorerItemAPI } from '../typesAPI'
import { convertExplorerItem } from './convertExplorer'
import QueryKeys from './MyFilesQueryKeys'

export default function useExplorerSearchQuery(searchTerm?: string, options: any = {}) {
  return useQuery(
    [...QueryKeys.MyFilesSearch, searchTerm],
    async () => {
      const res: AxiosResponse<ExplorerItemAPI[]> = await AuthorizedAPI.get('/explorer', {
        params: {
          'filter[name]': searchTerm,
          sort: '-last_operation_at',
        },
      })

      return (res.data?.map(convertExplorerItem) ?? []) as ExplorerItemsSearchResult[]
    },
    {
      enabled: !!searchTerm && searchTerm.length > 2,
      ...options,
    }
  )
}
