import React from 'react'
import { Form } from 'antd'

import { DocumentTypeSelect, useAIFormContext } from 'domain/openAI/models'
import { AIModelForm } from 'domain/openAI/types'
import { Section } from 'layouts'
import { SubmitButton, TextInput } from 'ui'

function FormModel({ onSubmit }: { onSubmit: (model: AIModelForm) => void }) {
  const [form] = Form.useForm()
  const [modelForm, setModelForm] = useAIFormContext()
  const service = Form.useWatch('service', form)

  const handleSubmit = async (data: any) => {
    const { documentName, service, ...formData } = data

    const updatedModelForm = {
      ...formData,
      id: modelForm.id,
      questions: modelForm.questions,
    }

    if (service.value !== 'other') {
      updatedModelForm.service = service
    } else {
      updatedModelForm.documentName = documentName
    }

    setModelForm(updatedModelForm)
    onSubmit(data)
  }

  return (
    <Section heading="Dados do Modelo">
      <Form
        layout="vertical"
        requiredMark={false}
        scrollToFirstError
        form={form}
        onFinish={handleSubmit}
        initialValues={modelForm}
      >
        <p className="text-gray-500 my-5">
          Informe o tipo de documento que fará upload para leitura via inteligência artificial.
        </p>

        <TextInput name="modelName" label="Dê um título para o modelo" maxLength={60} required autoFocus />

        <DocumentTypeSelect name="service" form={form} />

        {service?.value === 'other' && (
          <TextInput name="documentName" label="Nome do documento" maxLength={120} required />
        )}

        <SubmitButton />
      </Form>
    </Section>
  )
}

export default FormModel
