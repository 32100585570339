import { Icon } from 'ui'

interface Props {
  subtitle: string
  href: string
}

export default function LearnEverythingSubtitle({ subtitle, href }: Props) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="text-sm text-primary underline w-fit flex items-center gap-2"
    >
      <span className="mt-1">{subtitle}</span>
      <Icon name="external" size={24} className="rotate-90" />
    </a>
  )
}
