import React from 'react'

import { useSetSearchTerm } from 'domain/orders/state/filters'
import { FilterTag } from 'ui'

export default function FilterBySearchTermTag({ value }: { value: string }) {
  const setSearch = useSetSearchTerm()

  return <FilterTag onClick={() => setSearch('')}>{value}</FilterTag>
}
