import React, { useState } from 'react'
import { Checkbox, Pagination, Spin } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { Group } from 'domain/groups/types'
import { Modal, SearchInput, Tag } from 'ui'
import { removeFromArrayById, updateItemOnArrayById } from 'utils/transformData'

import { useGroupsFilterQuery } from '../services'
import EditGroupForm from './EditGroupForm'
import AssociationCheckbox from './GroupsAssociationCheckbox'

interface Props {
  selectedGroups: Group[]
  onSelectGroup: React.Dispatch<React.SetStateAction<Group[]>>
  onDeleteGroup?: (groupId: number) => void
}

export default function GroupsAssociationList({ selectedGroups, onSelectGroup, onDeleteGroup }: Props) {
  const [groupToEdit, setGroupToEdit] = useState<Group | undefined>(undefined)
  const [page, setPage] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const [search, setSearch] = useState('')
  const closeModal = () => setGroupToEdit(undefined)
  const { data: groups, isLoading } = useGroupsFilterQuery({ page, setTotalResults, search })

  const idsSelectedOnThisPage =
    selectedGroups.length > 0 && groups
      ? groups.filter(group => selectedGroups.some(g => g.id === group.id)).map(g => g.id)
      : []

  const allSelected =
    idsSelectedOnThisPage.length > 0 && groups?.every(g => idsSelectedOnThisPage.includes(g.id))

  const handleEditGroup = (group: Group) => {
    onSelectGroup(updateItemOnArrayById(selectedGroups, group))
    closeModal()
  }

  const handleDeleteGroup = (groupId: number) => {
    if (onDeleteGroup) {
      onDeleteGroup(groupId)
    } else {
      onSelectGroup(removeFromArrayById(selectedGroups, groupId))
    }
  }

  const onChangeCheckAll = (e: CheckboxChangeEvent) => {
    const removeCurrentPageSelection = () => {
      return selectedGroups.filter(g => !idsSelectedOnThisPage.includes(g.id))
    }

    if (e.target.checked) {
      const groupsSelectedOnOtherPages =
        idsSelectedOnThisPage.length > 0 ? removeCurrentPageSelection() : selectedGroups
      onSelectGroup([...groupsSelectedOnOtherPages, ...groups!])
    } else {
      onSelectGroup(removeCurrentPageSelection())
    }
  }

  return (
    <>
      <div className="flex justify-between items-center border-b border-gray-200 mb-2">
        <label className="p-2 w-56 rounded cursor-pointer">
          <Checkbox className="mr-2" checked={allSelected} onChange={onChangeCheckAll} disabled={!groups} />
          <span className="text-gray-500 uppercase text-2xs">Selecionar todos</span>
        </label>

        {selectedGroups.length > 0 && (
          <span className="text-gray-500 uppercase text-xs">
            Selecionados: <Tag className="ml-2">{selectedGroups.length}</Tag>
          </span>
        )}
      </div>

      <SearchInput
        className="w-full mb-2"
        placeholder="Pesquise por nome ou número do pedido"
        onSearch={setSearch}
        allowClear
      />

      <Spin spinning={isLoading}>
        {groups?.map(group => (
          <div key={group.id} className="pb-2 mb-2 border-b border-gray-200">
            <AssociationCheckbox
              group={group}
              isChecked={selectedGroups.some(o => o.id == group.id)}
              onDeleteGroup={handleDeleteGroup}
              onEditGroup={setGroupToEdit}
              onSelectGroup={onSelectGroup}
            />
          </div>
        ))}
      </Spin>

      <Pagination
        current={page}
        total={totalResults}
        onChange={setPage}
        showSizeChanger={false}
        size="small"
        className="mb-2"
      />

      <Modal open={!!groupToEdit} title="Editar grupo" onCancel={closeModal} destroyOnClose>
        <EditGroupForm group={groupToEdit!} onSuccess={handleEditGroup} />
      </Modal>
    </>
  )
}
