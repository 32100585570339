import { displayDate, diffDays } from 'utils/dateTime'
import { singularOrPlural } from 'utils/formatters'
import { StatusCode } from '../types'

const getEstimatedDateStr = (
  estimatedDeliveryAt: string | Date | undefined,
  status: StatusCode,
  hasActiveChallenge?: boolean
) => {
  if (status === StatusCode.ACAO_NECESSARIA || hasActiveChallenge) return 'Prazo Afetado'
  if (status === StatusCode.CANCELADO || !estimatedDeliveryAt) return ' '

  if (status === StatusCode.FINALIZADO) return ' '

  const datesDiference = diffDays(new Date(), estimatedDeliveryAt)

  if (datesDiference < 1) return displayDate(estimatedDeliveryAt)

  return singularOrPlural(datesDiference, 'Dia Útil', 'Dias Úteis')
}

export default getEstimatedDateStr
