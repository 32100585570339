import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaDivorcioFields = Record<FieldName.CPF | FieldName.NOME_PF, FieldConfig>

class PesquisaDivorcio extends Research {
  formFields: PesquisaDivorcioFields = {
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
  }

  constructor() {
    super({
      description:
        'Retorna os dados de localização da escritura (estado, cidade e cartório) em caso de resultado positivo.',
      id: 64,
      shortName: 'Pesquisa de Divórcio',
    })
  }
}

const service = new PesquisaDivorcio().initialize()

export default service
