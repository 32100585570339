import React from 'react'

import { NumberInput } from 'ui'

function FrequencyDaysInput() {
  return (
    <NumberInput
      label="Repete a cada (dias):"
      name="everyXDays"
      min="1"
      max="365"
      placeholder="Frequência em dias"
      required
    />
  )
}

export default FrequencyDaysInput
