import React from 'react'

import { ServiceCard } from 'domain/createOrder'
import { useCreateOrderState } from 'domain/createOrder/document'
import { Sidebar } from 'layouts'

import DueDiligencePriceDeliveryInfo from './DueDiligencePriceDeliveryInfo'
import TotalPrice from './DueDiligenceTotalPrice'

function DueDiligenceSidebar() {
  const { selectedService } = useCreateOrderState()

  return (
    <Sidebar>
      <ServiceCard serviceName={selectedService ? `Diligência - ${selectedService.name}` : 'Diligência'}>
        <DueDiligencePriceDeliveryInfo />
      </ServiceCard>
      <TotalPrice />
    </Sidebar>
  )
}

export default DueDiligenceSidebar
