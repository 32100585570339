import React, { useState } from 'react'

import { Alert, Boleto } from 'ui'

import { ChosenPaymentMethod, Invoice as InvoiceType } from '../types'
import InvoiceForm from './InvoiceForm'

interface Props {
  paymentValue: number
  isAddingCredits?: boolean
  onSuccess?: (method: ChosenPaymentMethod) => void
}

function Invoice({ paymentValue, onSuccess, isAddingCredits }: Props) {
  const [invoice, setInvoice] = useState<InvoiceType>()

  const handleSuccess = async (chosenMethod: ChosenPaymentMethod) => {
    if (chosenMethod.invoice) {
      setInvoice(chosenMethod.invoice)
    }

    onSuccess && onSuccess(chosenMethod)
  }

  return (
    <div>
      <Alert
        type="error"
        message="Após o pagamento do boleto, seus créditos podem levar até 3 dias úteis para serem liberados.
                    Caso tenha urgência na utilização, orientamos que opte pelo cartão de crédito ou PIX."
        className="my-4 mb-7"
      />

      {invoice && isAddingCredits ? (
        <Boleto barcode={invoice.bankSlipBarcode} url={invoice.bankSliptUrl} className="bg-gray-300" />
      ) : (
        <InvoiceForm
          paymentValue={paymentValue}
          onSuccess={handleSuccess}
          isAddingCredits={isAddingCredits}
        />
      )}
    </div>
  )
}

export default Invoice
