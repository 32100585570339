import React, { useMemo, useReducer } from 'react'
import { Button, Table } from 'antd'
import { Link } from 'react-router-dom'

import { Routes } from 'app/routes'
import { CreateRecurrenceItem, CreateRecurrenceKind } from 'domain/recurrences/types'
import { Icon, Modal, Tag } from 'ui'

type Props = {
  kind: CreateRecurrenceKind
  items: CreateRecurrenceItem[]
  onRemove: (fileId: number) => void
}

export default function CreateRecurrenceItems({ items, kind, onRemove }: Props) {
  const [open, toggleModal] = useReducer(prev => !prev, false)

  return (
    <div className="bg-gray-100 rounded-md p-2 flex flex-row gap-2 justify-between items-center mb-6 shadow">
      <div className="flex flex-row items-center gap-2">
        <Tag>{items.length}</Tag>
        <span className="font-bold">{items.length === 1 ? 'Item' : 'Itens'}</span>
      </div>

      <Button onClick={toggleModal} size="small" type="link">
        {items.length === 1 ? 'Visualizar' : 'Editar'}
      </Button>

      <Modal open={open} onCancel={toggleModal} title="Itens da Recorrência">
        <ItemsList kind={kind} items={items} onRemove={onRemove} />
      </Modal>
    </div>
  )
}

function ItemsList({
  kind,
  items,
  onRemove,
}: {
  kind: CreateRecurrenceKind
  items: CreateRecurrenceItem[]
  onRemove: (itemId: number) => void
}) {
  const columns: any = useMemo(() => {
    const isDeletable = items.length > 1

    return [
      {
        title: 'Documento',
        dataIndex: 'name',
        sorter: true,
        render: (itemName: string, item: CreateRecurrenceItem) => (
          <Link
            title="Ver pedido"
            target="_blank"
            to={kind === 'orders' ? Routes.Order(item.id) : Routes.ViewOrder(item.id)}
          >
            {itemName}
          </Link>
        ),
      },
      {
        title: 'Ação',
        dataIndex: 'id',
        className: 'text-center',
        render: (id: number) => {
          if (!isDeletable) {
            return null
          }

          return (
            <a
              href="#"
              title="Remover item da Recorrência"
              onClick={() => onRemove(id)}
            >
              <Icon size={20} name="trash" />
            </a>
          )
        },
      },
    ]
  }, [items])

  return <Table dataSource={items} columns={columns} rowClassName="bg-slate-50 p-2" />
}
