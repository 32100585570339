import { CertificateFlow } from 'domain/createOrder/certificates'
import { OrderNameField } from 'domain/createOrder/document'
import { DateField, NameField, TextField } from 'domain/servicesCBRdoc/fields'
import config from '../certidaoObitoConfig'

export default function CreateOrderCertidaoObito() {
  return (
    <CertificateFlow
      renderFormItem={(itemId: number) => <CertidaoObitoForm itemId={itemId} />}
      noRenderOrderName
    />
  )
}

const CertidaoObitoForm = ({ itemId }: { itemId: number }) => {
  return (
    <>
      <NameField itemId={itemId} field={nome_pf} autoFocus={itemId === 0} />
      <NameField itemId={itemId} field={mae} />
      <NameField itemId={itemId} field={pai} />
      <DateField itemId={itemId} field={obito} />
      <OrderNameField itemId={itemId} />

      <h2 className="uppercase">Dados complementares</h2>

      <hr className="my-5" />

      <TextField itemId={itemId} field={livro} />
      <TextField itemId={itemId} field={pagina} />
      <TextField itemId={itemId} field={termo} />
    </>
  )
}

const { livro, mae, nome_pf, obito, pai, pagina, termo } = config.formFields
