import { useReducer, useState } from 'react'
import { Button, Form, InputNumber, message } from 'antd'
import { useLocation } from 'react-router-dom'

import { useCurrentUser } from 'domain/auth'
import { DocumentSelect } from 'domain/kits'
import { Kit } from 'domain/kits/types'
import { SelectedService } from 'domain/orderItem/types'
import { CategoriesProvider } from 'domain/servicesCBRdoc'
import { Modal, SubmitButton } from 'ui'

import { useKit } from '../../state'
import { KitOrderStepURI } from '../../types'

export default function AddDocument() {
  const [form] = Form.useForm()
  const { pathname } = useLocation()  
  const [showModal, toggleModal] = useReducer(prev => !prev, false)
  const [kit, setKit] = useKit()
  const user = useCurrentUser()
  const [selectedService, setSelectedService] = useState<SelectedService | undefined>(undefined)
  const uri = pathname.split('/').pop()
  const isDisabled = '/' + uri === KitOrderStepURI.COMMON_DATA

  const onFinish = ({ quantity }: any) => {
    const items = [...kit.items]
    const counter = [...Array(quantity).keys()]

    counter.forEach((_: any, index: number) => {
      items.push({
        id: Date.now() + index,
        ownerId: user.id,
        service: { ...selectedService! },
      })
    })
    
    setKit({ ...kit, items } as Kit)
    setSelectedService(undefined)
    form.resetFields()
    message.success('Documento adicionado ao kit com sucesso.')
    toggleModal()
  }

  return (
    <CategoriesProvider>
      <Button
        type="primary"
        ghost
        onClick={toggleModal}
        disabled={isDisabled}
        title={isDisabled ? 'Não é possível adicionar novos documentos nesta etapa do kit' : undefined}
      >
        Adicionar outros documentos
      </Button>

      <Modal title="Adicionar documentos ao kit" open={showModal} onCancel={toggleModal}>
        <Form form={form} onFinish={onFinish} layout="vertical" requiredMark={false} className="max-w-lg">
          <DocumentSelect
            label="Documento"
            kitLegalEntity={kit.legalEntity}
            onChange={setSelectedService}
            required
            allowClear
            autoFocus
          />

          <Form.Item label="Quantidade" name="quantity">
            <InputNumber min={1} max={10} defaultValue={1} />
          </Form.Item>

          <SubmitButton label="Adicionar ao kit" />
        </Form>
      </Modal>
    </CategoriesProvider>
  )
}
