import React from 'react'

import { SumTotalInvoices } from 'domain/invoices/components'
import { useResetTableSelection, useGetTableSelection } from 'domain/invoices/state'
import { DrawerActions } from 'ui'

import PrepaidDownloadInvoice from './PrepaidDownloadInvoice'

function PrepaidInvoicesMassActions() {
  const selected = useGetTableSelection()
  const resetSelection = useResetTableSelection()

  return (
    <DrawerActions
      height={140}
      onClose={resetSelection}
      total={selected.rows.length}
      className="justify-center"
    >
      <SumTotalInvoices fieldName="amount" selectedRows={selected.rows} />
      <PrepaidDownloadInvoice />
    </DrawerActions>
  )
}

export default PrepaidInvoicesMassActions
