import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { AIFormProvider } from 'domain/openAI/models'
import { AIModelsPage } from 'pages/ai'
import { GoBackButton, PageLayout } from 'layouts'
import { ActionsBar } from 'ui'

import Sidebar from './AIOrderSidebar'
import Steps from './AIOrderSteps'
import ModelStep from './AIOrderModelStep'
import QuestionsStep from './AIOrderQuestionsStep'
import UploadStep from './AIOrderUploadStep'

export default function AIOrderFlow() {
  const { path } = useRouteMatch()

  return (
    <PageLayout pageTitle="Fazer pedido" extra={<Steps />} sidebar={<Sidebar />}>
      <ActionsBar>
        <GoBackButton />
      </ActionsBar>

      <AIFormProvider>
        <div className="mt-4">
          <Switch>
            <Route path={[path, `${path}/modelos`]} exact>
              <AIModelsPage />
            </Route>

            <Route path={`${path}/modelo`} exact>
              <ModelStep />
            </Route>

            <Route path={`${path}/dados`} exact>
              <QuestionsStep />
            </Route>

            <Route path={`${path}/arquivos`} exact>
              <UploadStep />
            </Route>
          </Switch>
        </div>
      </AIFormProvider>
    </PageLayout>
  )
}
