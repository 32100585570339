import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { DatePicker, Form } from 'antd'
import { Rule } from 'antd/lib/form'

interface DatePickerFieldProps {
  name: string
  className?: string
  label: string
  rules?: Rule[]
  disabledDate?: any
  value?: any
  onChange?: (event: any) => void
  showToday?: boolean
  renderExtraFooter?: () => React.ReactNode
  size?: SizeType
}

export default function DatePickerField({ name, className, label, rules, ...props }: DatePickerFieldProps) {
  return (
    <Form.Item name={name} className={className} label={label} rules={rules}>
      <DatePicker className="w-full" format="DD/MM/YYYY" picker="date" {...props} />
    </Form.Item>
  )
}
