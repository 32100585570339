import React, { useReducer } from 'react'
import { message } from 'antd'

import { IconName } from 'ui/Icon/Icon'
import { Button } from 'ui'

interface Props {
  fetchData: () => void
  kind: keyof typeof ExportKind
}

function ExportButton({ fetchData, kind }: Props) {
  const [loading, toggleLoading] = useReducer(prev => !prev, false)

  const handleClick = async () => {
    try {
      toggleLoading()
      await fetchData()
    } catch (err) {
      const error = err as any
      message.error(error.message ?? 'Falha no download do arquivo.')
    } finally {
      toggleLoading()
    }
  }

  return (
    <Button
      type="primary"
      icon={ExportKind[kind].icon}
      size="small"
      block
      ghost
      onClick={handleClick}
      loading={loading}
      className="h-10"
    >
      Exportar para {ExportKind[kind].label}
    </Button>
  )
}

const ExportKind: Record<any, { label: string; icon: IconName }> = {
  excel: {
    label: 'Excel',
    icon: 'file-xls',
  },
  pdf: {
    label: 'PDF',
    icon: 'file-pdf',
  },
}

export default ExportButton
