import TagManager from 'react-gtm-module'

import { RDS } from 'api/rdstation'
import { useCurrentUser } from 'domain/auth'
import { useRefreshCustomerCredits } from 'domain/credits'
import { useCurrentCustomer } from 'domain/customers'

import useGenerateQRCode from '../../checkout/services/useGenerateQRCode'
import { ChosenPaymentMethod, PaymentMethod } from '../../checkout/types'
import { walletDepositAPI } from '../../checkout/services/api'

function useBuyCreditsWithPix() {
  const refreshCredits = useRefreshCustomerCredits()
  const user = useCurrentUser()
  const customer = useCurrentCustomer()
  const generateQR = useGenerateQRCode()

  return async function buyCreditsWithPix(amount: number): Promise<ChosenPaymentMethod> {
    const { data } = await walletDepositAPI({
      amount,
      payment_method: PaymentMethod.PIX,
      send_email_after_approved: true,
    })

    if (data?.invoice_id && data?.amount) {
      refreshCredits()
    }
    
    const tagName = 'credito_adicionado_pix_via_credito'
    const code = await generateQR(data.pix_qr_code!)

    const pix: ChosenPaymentMethod = {
      chosenMethod: PaymentMethod.PIX,
      pix: {
        pixHash: data.pix_hash!,
        qrCode: code,
        pixKey: data.pix_qr_code!,
        invoiceId: data.invoice_id,
      },
    }

    TagManager.dataLayer({
      dataLayerName: 'PageDataLayer',
      dataLayer: {
        event: 'pixPayment',
        transactionId: data.invoice_id,
        transactionAffiliation: 'Sem-Cupom',
        transactionTotal: data.amount,
        transactionTax: 0,
        transactionShipping: 0,
        transactionProducts: [
          {
            sku: 'DD45',
            name: tagName,
            category: 'Crédito',
            price: data.amount,
            quantity: 1,
          },
        ],
      },
    })

    RDS.conversionEvent({
      event_type: 'CONVERSION',
      event_family: 'CDP',
      payload: {
        conversion_identifier: 'Crédito adicicionado - PIX',
        name: user.name,
        email: user.email,
        company_name: customer.companyName,
        mobile_phone: user.phone,
        tags: [tagName],
        cf_modo_pagamento: 'Pix',
        cf_valor_credito: data.amount,
      },
    })

    return pix
  }
}

export default useBuyCreditsWithPix
