import React from 'react'

import { formatMoney } from 'utils/formatters'
import useServiceTaxQuery from '../../services/useServiceTaxQuery'

function ServiceTax() {

  const { data, isFetching } = useServiceTaxQuery()

  return (
    <div className="flex justify-between py-3 border-b border-gray-300">
      <div className="text-gray-600">Taxa de serviço:</div>
      <div>-{isFetching || !data?.price ? 'Calculando...' : formatMoney(data.price)}*</div>
    </div>
  )
}

export default ServiceTax
