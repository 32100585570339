import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaReceitaFederalFields = Record<
  FieldName.DATA_NASCIMENTO | FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ,
  FieldConfig
>

class PesquisaReceitaFederal extends Research {
  formFields: PesquisaReceitaFederalFields = {
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    cnpj: Fields.cnpj,
    nome_pj: Fields.nome_pj,
    nascimento: Fields.nascimento
  }

  constructor() {
    super({
      doNotOrderResultsWithSameNotary: true,
      id: 74,
      shortName: 'Pesquisa Receita Federal',
    })
  }
}

const service = new PesquisaReceitaFederal().initialize()

export default service
