import React from 'react'
import { Checkbox, Modal } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { useCurrentCustomer, useSetCustomer } from '../state'
import useUpdateCustomer from '../services/useUpdateCustomer'

export default function RequiredGroupsCheckbox() {
  const customer = useCurrentCustomer()
  const setCustomer = useSetCustomer()
  const { updateCustomer } = useUpdateCustomer()

  function handleCheckbox(e: CheckboxChangeEvent) {
    Modal.warn({
      title: e.target.checked ? 'Permissão Ativada' : 'Permissão Desativada',
      content: e.target.checked
        ? 'Ao marcar esta permissão, ela passará a valer para todos os usuários da conta.'
        : 'Ao desmarcar esta permissão, ela deixará de valer para todos os usuários da conta.',
      onOk: async () => {
        await updateCustomer(
          {
            group_mandatory_on_purchase: e.target.checked,
          },
          setCustomer
        )
      },
      closable: true,
    })
  }

  return (
    <div className="flex items-center gap-2">
      <Checkbox name="required" checked={customer.orderGroupRequired} onChange={handleCheckbox} />
      Obrigatório informar o grupo na hora da compra
    </div>
  )
}
