import React from 'react'
import { Card, Form } from 'antd'

import queryClient from 'app/config/queryClient'
import { useAddKitItemMutation } from 'domain/kits/services'
import { Kit, KitItem, KitItemDefaultData } from 'domain/kits/types'

import AddKitItemForm from './AddKitItemForm'

interface Props {
  kit: Kit
  queryKey: any[]
}

interface AddKitItem extends KitItemDefaultData {
  categoryId?: number
  service?: number
}

function AddKitItem({ kit, queryKey }: Props) {
  const [form] = Form.useForm()

  const kitMutation = useAddKitItemMutation(queryKey, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      form.setFieldsValue({ service: undefined })
    },
  })

  const isLoading = kitMutation.status === 'loading'

  const onFinish = (item: Partial<KitItem>) => {
    kitMutation.mutate({ kitId: kit.id, kitItem: item })
  }

  return (
    <Card>
      <AddKitItemForm kitLegalEntity={kit.legalEntity} submitting={isLoading} onFinish={onFinish} />
    </Card>
  )
}

export default AddKitItem
