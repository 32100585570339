import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const {
  TIPO_PESSOA,
  URL_CARTORIO,
  URL_CIDADE,
  URL_UF,
} = FieldName

type CertidaoNegativaPropriedadeFields = Record<
  | FieldName.CNPJ
  | FieldName.CPF
  | FieldName.URL_CARTORIO
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

class CertidaoNegativaPropriedade extends Certificate {
  formFields: CertidaoNegativaPropriedadeFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    url_cartorio: Fields.url_cartorio,
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
  }

  constructor() {
    super({
      id: 45,
      isKitAvailable: false,
      priceByFields: [TIPO_PESSOA, URL_CARTORIO, URL_CIDADE, URL_UF],
      shortName: 'Certidão Negativa de Propriedade',
      steps: ['localizacao', 'dados'],
    })
  }
}

const service = new CertidaoNegativaPropriedade().initialize()

export default service
