import React from 'react'
import { Spin } from 'antd'
import { Link } from 'react-router-dom'

import { Routes } from 'app/routes'
import { useRecentFilesQuery } from 'domain/myFiles/services'
import { ExplorerHistoryOperation } from 'domain/myFiles/types'
import { Icon } from 'ui'
import { displayOperationDate } from 'utils/dateTime'

export default function RecentsFiles() {
  const { data, isFetching } = useRecentFilesQuery()

  return (
    <Spin spinning={isFetching}>
      <h2 className="text-gray-600 text-sm block mb-2">Arquivos recentes</h2>

      <div className="flex">
        {data?.map((file, index) => {
          const url = file.orderItemId ? Routes.ViewOrder(file.orderItemId) : Routes.ViewFile(file.id)
          const lastHistory = file?.history && file.history[file.history.length - 1]
          
          return (
            <Link
              to={url}
              key={index}
              className="w-40 flex-none mr-3 bg-gray-100 p-3 flex flex-col items-center border border-gray-400 rounded cursor-pointer hover:bg-white hover:border-primary hover:shadow-md transition-all"
            >
              <Icon name="file" className="text-2xl text-gray-600" />

              <h2 className="mt-2 mb-1 truncate w-full text-xs text-center">{file.name}</h2>
              
              {lastHistory && (
                <span className="text-3xs text-gray-600 text-center">
                  {Operation[lastHistory.operation as ExplorerHistoryOperation]}
                  {displayOperationDate(lastHistory.operation_time)}
                </span>
              )}
            </Link>
          )
        })}
      </div>
    </Spin>
  )
}

const Operation: Record<ExplorerHistoryOperation, string> = {
  [ExplorerHistoryOperation.CREATE]: 'Criado',
  [ExplorerHistoryOperation.VIEW]: 'Acessado',
  [ExplorerHistoryOperation.MOVE]: 'Movido',
  [ExplorerHistoryOperation.DELETE]: 'Removido',
  [ExplorerHistoryOperation.RENAME]: 'Renomeado',
  [ExplorerHistoryOperation.UPLOAD]: 'Adicionado',
  [ExplorerHistoryOperation.CREATE_TAG]: 'Tag criada',
  [ExplorerHistoryOperation.DELETE_TAG]: 'Tag removida',
  [ExplorerHistoryOperation.UPDATE_TAG]: 'Tag atualizada',
  [ExplorerHistoryOperation.ADD_TAG_TO_ITEM]: 'Tag adicionada',
  [ExplorerHistoryOperation.REMOVE_TAG_FROM_ITEM]: 'Tag removida',
}
