import { Spin } from 'antd'

import { UseExtraInformationQueryParams, useExtraInformationQuery } from 'domain/orderItem/shared'
import { FieldConfig } from '../types'
import RadioGroupButtonsField, { RadioGroupButtonsFieldProps } from './RadioGroupButtonsField'

export interface ExtraInfoSelectProps
  extends Omit<UseExtraInformationQueryParams<any>, 'information'>,
    Omit<RadioGroupButtonsFieldProps, 'hasError' | 'information' | 'loading' | 'options'> {
  field: FieldConfig
}

export default function ExtraInfoRadioButtonGroup({
  service,
  itemId,
  params,
  field,
  onChange,
  ...props
}: ExtraInfoSelectProps) {
  const { data, isFetching, isError } = useExtraInformationQuery<any>({
    service,
    information: field.apiExtraInfo!,
    itemId,
    params,
  })

  return (
    <Spin spinning={isFetching}>
      <RadioGroupButtonsField
        field={field}
        options={data}
        hasError={isError}
        onChange={onChange}
        itemId={itemId}
        {...props}
      />
    </Spin>
  )
}
