import { Form } from 'antd'
import { useDispatch } from 'react-redux'

import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document/state'
import { Box, ExtraCheckbox } from 'domain/createOrder/shared'
import { Extras } from 'domain/createOrder/types'

import SelectCopies from './SelectCopies'

interface Props {
  label: string
  description: string
  name: keyof Extras
}

export default function Xerox({ label, description, name }: Props) {
  const dispatch = useDispatch()
  const { selectedExtras } = useCreateOrderState()

  const onChange = (value: number | undefined) => {
    dispatch(createOrderActions.setSelectedExtras({ ...selectedExtras, [name]: value }))
  }

  const value = selectedExtras?.[name]

  return (
    <>
      <ExtraCheckbox
        label={label}
        description={description}
        checked={!!value}
        onChange={e => {
          onChange(e.target.checked ? 1 : undefined)
        }}
      />
      {!!value && (
        <Box>
          <Form.Item name={name} label="Número de cópias">
            <SelectCopies value={value} onSelect={onChange} />
          </Form.Item>
        </Box>
      )}
    </>
  )
}
