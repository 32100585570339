import React from 'react'
import { Form } from 'antd'
import { Dayjs } from 'dayjs'

import { OrderItem } from 'domain/orderItem/types'
import { DatePickerField, Modal, SubmitButton } from 'ui'
import { jsDate } from 'utils/dateTime'
import useEditExpirationDateMutation from '../services/useEditExpirationDateMutation'

interface Props {
  open?: boolean
  onSuccess: () => void
  onClose: () => void
  orderItem: OrderItem
}

function SetExpirationDateModal({ open, onSuccess, onClose, orderItem }: Props) {
  const [form] = Form.useForm()

  const { mutate, isLoading } = useEditExpirationDateMutation(orderItem.id, {
    onSuccess: () => {
      onSuccess()
      onClose()
    },
  })

  return (
    <Modal
      destroyOnClose
      open={open}
      title="Vencimento"
      subTitle="Após o documento ser finalizado na plataforma, por quantos dias será válido?"
      onCancel={onClose}
    >
      <Form
        form={form}
        requiredMark={false}
        layout="vertical"
        initialValues={{
          validUntil: jsDate(orderItem.validUntil),
        }}
        onFinish={(data: any) => {
          mutate(data.validUntil)
        }}
      >
        <ExpirationDateInput deliveredAt={orderItem.finishedAt!} />
        <SubmitButton label="Configurar" loading={isLoading} />
      </Form>
    </Modal>
  )
}

function ExpirationDateInput({ deliveredAt }: { deliveredAt: string }) {
  return (
    <DatePickerField
      label="Válido até"
      name="validUntil"
      disabledDate={(currentDate: Dayjs) => {
        const deliveryDate = jsDate(deliveredAt)
        return (
          currentDate &&
          (currentDate.isSame(deliveryDate, 'date') || currentDate.isBefore(deliveryDate, 'date'))
        )
      }}
    />
  )
}

export default SetExpirationDateModal
