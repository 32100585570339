import { useDispatch } from 'react-redux'

import { useResetCheckout } from 'domain/checkout'
import { shoppingCartStateActions } from './shoppingCartSlice'

export default function useResetShoppingCart() {
  const resetCheckout = useResetCheckout()
  const dispatch = useDispatch()
  
  return () => {
    dispatch(shoppingCartStateActions.resetState())
    resetCheckout()
  }
}
