import React, { useEffect, useRef } from 'react'
import { Icon } from 'ui'

interface Props { children: React.ReactNode, handleClickOutside?: () => void }

function FloatingContainer({ children, handleClickOutside }: Props) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const clickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClickOutside && handleClickOutside()
      }
    }
    document.addEventListener('click', clickOutside, true)
    return () => {
      document.removeEventListener('click', clickOutside, true)
    }
  }, [ handleClickOutside ])
  
  return (
    <div className="absolute bg-gray-900 bg-opacity-50 left-0 right-0 bottom-0 top-0 z-50 flex justify-center items-center">
      <div ref={ref} className="m-7 p-10 pt-7 rounded-md w-full max-w-3xl bg-white shadow-md">
        <div className="cursor-pointer flex justify-end" onClick={handleClickOutside}>
          <Icon name="close" />
        </div>
        {children}
      </div>
    </div>
  )
}

export default FloatingContainer
