/* eslint-disable @typescript-eslint/no-unused-vars */
import { CreateOrderParamsFields, CreateOrderState } from 'domain/createOrder/types'
import { OrderItem, ResearchResult } from 'domain/orderItem/types'
import { ResultCode } from 'domain/orders/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'

import { CertificateConfig } from '../types'
import CBRdocDocument from './CBRdocDocument'

export default class Certificate extends CBRdocDocument {
  availableFormatsFields: FieldName[] = []

  constructor(config: CertificateConfig) {
    super(config)

    const { availableFormatsFields } = config

    this.availableFormatsFields = availableFormatsFields ?? []
  }

  getAvailableFormatsParams = (createOrder: CreateOrderParamsFields, itemId: number = 0) => {
    if (!this.steps.includes('entrega')) {
      return null
    }

    return this.extractServiceParam(this.availableFormatsFields, createOrder, itemId)
  }

  extractResearchData = (
    result: ResearchResult,
    resultCode: ResultCode,
    orderItem: OrderItem
  ): Partial<CreateOrderState> => {
    return {
      formData: {
        ...orderItem.researchResultsOrderSettings![resultCode]!.placeOrder?.formData, 
        ...result,      
      }
    }
  }
}
