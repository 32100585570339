import { useDispatch } from 'react-redux'

import { CreateOrderState } from 'domain/createOrder/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { shoppingCartStateActions, useShoppingCartState } from 'domain/shoppingCart'
import { ShoppingCartOrder } from 'domain/shoppingCart/types'

export default function useAddToCart() {
  const dispatch = useDispatch()
  const shoppingCartData = useShoppingCartState()

  return function addOrderToCart(createOrder: CreateOrderState) {
    const service = getServiceConfig(createOrder.selectedService.code!)
    let ordersToAdd: ShoppingCartOrder[] = []

    ordersToAdd = service.convertToCartItems(createOrder)

    ordersToAdd.forEach(createOrderItem => {
      const isEdit = shoppingCartData.orders.find(cart => cart.cartItemId === createOrderItem.cartItemId)

      if (isEdit) {
        dispatch(shoppingCartStateActions.updateOrder(createOrderItem))
      } else {
        dispatch(shoppingCartStateActions.addOrder(createOrderItem))
      }
    })
  }
}
