import { useLocation } from 'react-router-dom'

import { Steps } from 'ui'
import { KitOrderStepURI } from '../types'

export default function KitOrderHeaderSteps() {
  const { pathname } = useLocation()
  const uri = pathname.split('/').pop()
  const step = STEPS.findIndex(o => o.uri === `/${uri}`)

  return <Steps current={step > -1 ? step : 0} steps={STEPS} className=" max-w-xl ml-8" />
}

const STEPS: { title: string; uri: KitOrderStepURI }[] = [
  { title: 'Dados iniciais', uri: KitOrderStepURI.SERVICE_DATA },
  { title: 'Detalhamento', uri: KitOrderStepURI.COMMON_DATA },
]
