import { Icon } from 'ui'

type Props = { onToggle: () => void }

export default function HamburguerButton({ onToggle }: Props) {
  return (
    <div className="p-3 mr[5px] bg-transparent sm:hidden">
      <button
        className={`flex justify-center items-center rounded-sm border-none text-gray-700 cursor-pointer 
          will-change-margin transition-all duration-150 ease-linear active:shadow-inset`}
        onClick={onToggle}
        onMouseDown={e => e.preventDefault()}
      >
        <Icon name="nav" size={24} />
      </button>
    </div>
  )
}
