import React from 'react'

import { CertificateFlow } from 'domain/createOrder/certificates'
import { CNPJInput } from 'ui'

export default function CreateOrderCertidaoConsultaSituacaoPJ() {
  return (
    <CertificateFlow
      header={
        <p className="mb-5 text-gray-600">
          Digite o número do CNPJ que deseja a consulta de situação de pessoa jurídica.
        </p>
      }
      renderFormItem={(itemId: number) => <CertidaoSituacaoPJForm itemId={itemId} />}
    />
  )
}

function CertidaoSituacaoPJForm({ itemId }: { itemId: number }) {
  return <CNPJInput autoFocus={itemId === 0} itemId={itemId} />
}
