import { useEffect, useState } from 'react'
import { NamePath } from 'antd/es/form/interface'

import {
  KIT_ORDER_COMMON_DATA,
  KIT_ORDER_CURRENT_ID,
  KIT_ORDER_CURRENT_KIT,
  KIT_ORDER_SERVICES_DATA,
  useSetCommonForm,
  useSetKit,
  useSetServicesForm,
} from 'domain/createOrder/kit/state'
import { Kit } from 'domain/kits/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import {
  getStorageData,
  getStorageInt,
  removeStorageItem,
  setStorageData,
  setStorageInt,
} from 'utils/localStorage'

export default function useLoadkitOrderInitialState(kit: Kit) {
  const [isReady, setIsReady] = useState(false)
  const setServicesForm = useSetServicesForm()
  const setCommonForm = useSetCommonForm()
  const setKit = useSetKit()

  useEffect(() => {
    if (!isReady) {
      persistCurrentKitIdAndRemoveStaleData(kit.id)
      const updatedKit = mergeCurrentKitWithPersistedData(kit)

      const commonForm = getStorageData(KIT_ORDER_COMMON_DATA, { registree: {} })
      setCommonForm(commonForm)

      const servicesForm = getInitialServicesForm(updatedKit)
      setServicesForm(servicesForm)

      setKit(updatedKit)
    }

    setIsReady(true)
  }, [])

  return isReady
}

function mergeCurrentKitWithPersistedData(kit: Kit) {
  const persistedKit = getStorageData(KIT_ORDER_CURRENT_KIT, { items: [] }) as Kit

  const merged = {
    ...persistedKit,
    ...kit,
    items: kit.items.map(item => {
      const persistedItem = persistedKit.items.find(o => o.id === item.id)

      if (!persistedItem) {
        return item
      }

      return {
        ...persistedItem,
        ...item,
      }
    }),
  }

  setStorageData(KIT_ORDER_CURRENT_KIT, merged)

  return merged
}

function persistCurrentKitIdAndRemoveStaleData(kitId: number) {
  const persistedKitId = getStorageInt(KIT_ORDER_CURRENT_ID)

  if (persistedKitId !== kitId) {
    removeStorageItem(KIT_ORDER_CURRENT_KIT)
    removeStorageItem(KIT_ORDER_COMMON_DATA)
    removeStorageItem(KIT_ORDER_SERVICES_DATA)
  }

  setStorageInt(KIT_ORDER_CURRENT_ID, kitId)
}

function getInitialServicesForm(kit: Kit) {
  const persistedServicesData = getStorageData(KIT_ORDER_SERVICES_DATA)

  if (persistedServicesData) {
    return persistedServicesData
  }

  const formData: Record<NamePath, any> = {}

  formData[FieldName.NOME_ITEM_PEDIDO] = `Kit #${kit.id}`
  formData.tipo_pessoa = kit.legalEntity === 'indefinido' ? 'fisica' : kit.legalEntity

  kit.items
    .filter(item => item.defaultData)
    .forEach(item => {
      const fields = Object.entries(item.defaultData!)

      fields.forEach(([fieldName, value]) => {
        if (!formData[item.id]) {
          formData[item.id] = {}
        }

        formData[item.id][fieldName] = value
      })
    })

  return formData
}
