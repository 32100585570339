import queryClient from 'app/config/queryClient'
import { useCurrentCustomer } from 'domain/customers'

function useRefreshCustomerCredits() {
  const customer = useCurrentCustomer()

  return function refreshCredits() {
    queryClient.invalidateQueries(['customer', customer.id])
  }
}

export default useRefreshCustomerCredits
