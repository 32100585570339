import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaParticipacaoSocietariaFields = Record<
  FieldName.DATA_NASCIMENTO | FieldName.CPF | FieldName.NOME_PF,
  FieldConfig
>

class PesquisaParticipacaoSocietaria extends Research {
  formFields: PesquisaParticipacaoSocietariaFields = {
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nascimento: {
      ...Fields.nascimento,
      optional: true
    },
  }

  constructor() {
    super({
      description:
        'Retorna as empresas e o relacionamento do CPF com cada uma delas (Ex:. Proprietário/Sócio/Representante Legal)',
      id: 43,
      shortName: 'Pesquisa Participação Societária',
    })
  }
}

const service = new PesquisaParticipacaoSocietaria().initialize()

export default service
