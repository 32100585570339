import React from 'react'

import { Pagination } from 'ui'
import { useCurrentPage, useGetTotalResults, usePageSize } from '../state'

function InvoicesPagination() {
  const [currentPage, setCurrentPage] = useCurrentPage()
  const [pageSize, setPageSize] = usePageSize()
  const totalResults = useGetTotalResults()

  return (
    <Pagination
      totalCount={totalResults}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      currentPage={currentPage}
      onChangePage={setCurrentPage}
    />
  )
}

export default InvoicesPagination
