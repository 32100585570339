import React from 'react'
import { Form } from 'antd'

import { GroupsAssociation } from 'domain/groups'
import { Group } from 'domain/groups/types'

interface Props {
  groups: Group[]
  onChange: (groups: Group[]) => void
}

function GroupsInput({ groups, onChange }: Props) {
  const form = Form.useFormInstance()

  return (
    <Form.Item name="groups_ids">
      <GroupsAssociation
        groups={groups}
        onFinish={groups => {
          form.setFieldValue(
            'groups_ids',
            groups.map(g => g.id)
          )

          onChange(groups)
        }}
      />
    </Form.Item>
  )
}

export default GroupsInput
