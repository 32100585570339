import { SidebarContainer, useGetOrderItem} from 'domain/orderItem/shared'
import { DownloadButton } from 'ui'

function ViewDocRegistrationActions() {
  const orderItem = useGetOrderItem()
  const { fileURL } = orderItem

  return <SidebarContainer downloadButton={<DownloadButton fileURL={fileURL} />} />
}

export default ViewDocRegistrationActions
