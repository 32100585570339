import React from 'react'
import { Form, Select } from 'antd'
import { SelectProps } from 'antd/lib/select'

import { normalizeString } from 'utils/formatters'
import useIBGEStatesQuery from '../services/useIBGEStatesQuery'
import { IBGEState } from '../types'

interface Props extends SelectProps<string> {
  label?: string
  name?: string
  required?: boolean
  disableOptions?: string[] | (() => (string | null | undefined)[])
  onSelectChange?: (state: IBGEState) => void
}

function IBGEStateSelect({
  label = 'Estado',
  name = 'ibgeFederativeUnit',
  required,
  disableOptions,
  ...props
}: Props) {
  const { data, isFetching } = useIBGEStatesQuery()

  return (
    <Form.Item name={name} label={label} rules={required ? requiredRule : undefined}>
      <Select
        loading={isFetching}
        disabled={!data}
        placeholder="Selecione o estado"
        optionFilterProp="children"
        filterOption={filterOption}
        allowClear
        showSearch
        {...props}
      >
        {data?.map(state => {
          let disabled = false
          if (disableOptions) {
            let disableOptionsArr = []
            if (Array.isArray(disableOptions)) {
              disableOptionsArr = disableOptions
            } else {
              disableOptionsArr = disableOptions()
            }
            disabled = disableOptionsArr.includes(state.sigla)
          }
          return (
            <Select.Option key={state.id} value={state.sigla} disabled={disabled}>
              {state.nome}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

const requiredRule = [{ required: true, message: 'Campo obrigatório' }]

const filterOption = (input: any, option: any) =>
  normalizeString((option as any)?.children).indexOf(normalizeString(input)) >= 0

export default IBGEStateSelect
