import { NamePath } from 'antd/lib/form/interface'
import Axios, { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { CitySelect } from 'ui'
import { SelectFieldProps } from './SelectField'

interface IBGECity {
  id: number
  nome: string
  microrregiao: {
    id: number
    nome: string
    mesorregiao: Record<any, any>
  }
}

interface Props extends Omit<SelectFieldProps, 'field'> {
  federativeUnit?: string
  name?: NamePath
  required?: boolean
}

export default function IBGECidadeNascimentoSelect({
  federativeUnit,
  name = CIDADE_NASCIMENTO_IBGE,
  required,
  ...props
}: Props) {
  const { data, isFetching } = useQuery(
    [`ibge-cities-${federativeUnit}`],
    async () => {
      const response: AxiosResponse<IBGECity[]> = await Axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${federativeUnit}/municipios`
      )
      return response.data
    },
    {
      enabled: !!federativeUnit,
    }
  )

  return (
    <CitySelect
      name={name}
      label="Cidade de nascimento"
      required={required}
      loading={isFetching}
      placeholder="Selecione a cidade"
      options={data}
      optionLabelKey="nome"
      optionValueKey="nome"
      labelInValue={false}
      {...props}
    />
  )
}

const { CIDADE_NASCIMENTO_IBGE } = FieldName
