import React from 'react'
import { DatePicker, Form } from 'antd'
import dayjs from 'dayjs'

import { Icon } from 'ui'
import { RangePickerProps } from 'antd/lib/date-picker'

const { RangePicker } = DatePicker

function KitsFiltersDateRangePicker() {
  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && dayjs(current as any).isAfter(new Date(), 'date')
  }

  return (
    <Form.Item name="createdBetween" label="Data de criação">
      <RangePicker
        separator={<Icon name="arrow-right" />}
        className="w-full"
        format="DD/MM/YYYY"
        disabledDate={disabledDate}
        allowClear
      />
    </Form.Item>

  )
}

export default KitsFiltersDateRangePicker
