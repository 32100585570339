import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs } from 'domain/createOrder/document'
import { CNPJAndNameFields, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../certidaoIbamaDebitosConfig'

export default function CreateOrderCertidaoIbama() {
  return (
    <CertificateFlow
      header={
        <p className="mb-5 text-gray-600">
          Selecione Pessoa ou Empresa e digite o número do CPF ou CNPJ que deseja a Certidão Ibama.
        </p>
      }
      renderFormItem={itemId => <CertidaoIbamaForm itemId={itemId} />}
    />
  )
}

const CertidaoIbamaForm = ({ itemId }: { itemId: number }) => {
  return (
    <CreateOrderLegalEntityTabs
      itemId={itemId}
      fisica={
        <>
          <CPFInput itemId={itemId} autoFocus={itemId === 0} />
          <NameField itemId={itemId} field={nome_pf} />
        </>
      }
      juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} autoFocus={itemId === 0} />}
    />
  )
}

const { nome_pf, nome_pj } = config.formFields
