import { useMutation } from 'react-query'

import { errorHandler } from 'api/errors'
import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'
import { AIModelForm } from 'domain/openAI/types'
import { AIModelAPI } from 'domain/openAI/typesAPI'
import { convertModelToUpsertAPI } from './convertModel'

export default function useSaveModel() {
  const { mutateAsync, status } = useMutation(async (model: AIModelForm) => await saveModel(model), {
    onError: error => {
      errorHandler(error, { code: '0x706' })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['ai-models'])
    },
  })

  return {
    saveModel: mutateAsync,
    loading: status === 'loading',
  }
}

async function insertModel(model: AIModelForm) {
  return await AuthorizedAPI.post<AIModelAPI>('/ai-models', convertModelToUpsertAPI(model))
}

async function updateModel(model: AIModelForm) {
  return await AuthorizedAPI.put<AIModelAPI>(`/ai-models/${model.id}`, convertModelToUpsertAPI(model))
}

export async function saveModel(model: AIModelForm) {
  return model.id ? updateModel(model) : insertModel(model)
}