import React from 'react'

export type TagColor = 'primary' | 'neutral' | 'secondary' | 'success' | 'warning'

export interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
  className?: string
  color?: TagColor
  rounded?: boolean
}

export default function Tag({ color = 'primary', className = '', children, rounded, ...props }: TagProps) {
  const tagStyle = rounded ? 'rounded-full min-w-32 min-h-[30px] px-1.5 flex justify-center items-center' : 'rounded px-1.5'
  const tagColor = color && tagColors[color] ? tagColors[color] : ''
  
  return (
    <span
      // eslint-disable-next-line max-len
      className={`text-xs font-bold text-center whitespace-nowrap inline ${tagStyle} ${tagColor} ${className}`}
      style={{ backgroundColor: color && typeof color === 'string' && tagColor === '' ? color : ''}}
      {...props}
    >
      {children}
    </span>
  )
}

const tagColors: Record<TagColor, string> = {
  neutral: 'bg-grey-200 text-grey-700',
  primary: 'bg-primary text-white',
  secondary: 'bg-secondary-500 text-white',
  success: 'bg-success-500 text-white',
  warning: 'bg-yellow-200 text-yellow-600',
}
