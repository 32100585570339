import React, { useState } from 'react'
import { Form } from 'antd'
import { useHistory } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { SelectExtras } from 'domain/createOrder/document'
import { Section } from 'layouts'
import { SubmitButton } from 'ui'

import { useAddDueDiligenceToCart } from '../services'

function ExtrasStep() {
  const history = useHistory()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const addToCart = useAddDueDiligenceToCart()

  const onFinish = async () => {
    try {
      setLoading(true)

      await addToCart()

      history.push(RoutePaths.SHOPPING_CART)
    } catch (error: any) {
      setLoading(false)
    }
  }

  return (
    <Section heading="Deseja serviços adicionais?">
      <Form form={form} className="max-w-lg" layout="vertical" onFinish={onFinish} requiredMark={false}>
        <SelectExtras />
        <SubmitButton autoFocus loading={loading} />
      </Form>
    </Section>
  )
}

export default ExtrasStep
