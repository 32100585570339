import React from 'react'

import { Steps } from 'ui'
import { displayDate } from 'utils/dateTime'

export default function SuccessOrderTimeline() {
  return (
    <Steps
      steps={[
        {
          title: 'Pedido recebido',
          status: 'done',
          date: displayDate(new Date()),
        },
        { title: 'Processando', status: 'pending' },
        { title: 'Enviado', status: 'pending' },
        { title: 'Finalizado', status: 'pending' },
      ]}
      className="w-full max-w-xl my-4 flex-grow-0 hidden sm:block"
    />
  )
}
