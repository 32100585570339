import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaJuntaComercialFields = Record<
  FieldName.CNPJ | FieldName.RAZAO_SOCIAL | FieldName.URL_UF,
  FieldConfig
>

const { URL_UF } = FieldName

class PesquisaJuntaComercial extends Research {
  formFields: PesquisaJuntaComercialFields = {
    url_uf: {
      ...Fields.url_uf,
      label: 'Estado da empresa',
    },
    cnpj: Fields.cnpj,
    razao_social: {
      ...Fields.razao_social,
      label: 'Razão Social Completa da Empresa (favor incluir ME, EPP, LTDA, etc)',
      placeholder: 'Nome da empresa',
      kitFieldEquivalent: FieldName.NOME_PJ,
    },
  }

  constructor() {
    super({
      description:
        'Retorna os atos que uma empresa possui na Junta Comercial. É possível selecionar o ato para solicitação.',
      id: 40,
      priceByFields: [URL_UF],
      shortName: 'Pesquisa Junta Comercial',
    })
  }
}

const service = new PesquisaJuntaComercial().initialize()

export default service
