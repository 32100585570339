import QRCode from 'qrcode'

function useGenerateQRCode() {
  const generateQR = async (text: string) => {
    return await QRCode.toDataURL(text)
  }

  return generateQR
}

export default useGenerateQRCode