import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { DOCUMENTO, FORMATO, NOME_PF } = FieldName

type CertidaoQuitacaoEleitoralFields = Record<
  | FieldName.CPF
  | FieldName.DATA_NASCIMENTO
  | FieldName.NOME_MAE
  | FieldName.NOME_PAI
  | FieldName.NOME_PF
  | FieldName.TITULO_ELEITOR,
  FieldConfig
>

class CertidaoQuitacaoEleitoral extends Certificate {
  formFields: CertidaoQuitacaoEleitoralFields = {
    nome_pf: Fields.nome_pf,
    mae: {
      ...Fields.mae,
      optional: true,
    },
    pai: Fields.pai,
    nascimento: Fields.nascimento,
    cpf: {
      ...Fields.cpf,
      apiName: DOCUMENTO,
      ignoreOnKitsAutomaticForm: true,
    },
    titulo_eleitor: {
      ...Fields.titulo_eleitor,
      apiName: DOCUMENTO,
      ignoreOnKitsAutomaticForm: true,
      apiRemoveMask: true,
    },
  }
  
  dynamicFields = {
    documento: {
      ...Fields.documento,
      apiRemoveMask: true,
    },
  }

  constructor() {
    super({
      id: 21,
      legalEntity: 'fisica',
      priceByFields: [FORMATO],
      orderNameField: NOME_PF,
      shortName: 'Quitação Eleitoral',
      shoudAutoLoadDynamicFields: false,
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoQuitacaoEleitoral().initialize()

export default service
