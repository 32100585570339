import React from 'react'
import { Form } from 'antd'

import { LabelInValue } from 'domain/app/types'
import { KitLegalEntity } from 'domain/kits/types'
import { SelectedService } from 'domain/orderItem/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { getLocationUrl } from 'domain/servicesCBRdoc/helpers/location'
import { getServiceConfig } from 'domain/servicesCBRdoc/products'
import { SelectedLocation } from 'domain/servicesCBRdoc/types'

import LocationFederativeUnitSelect from './LocationFederativeUnitSelect'
import LocationCitySelect from './LocationCitySelect'
import LocationNotarySelect from './LocationNotarySelect'
import LocationInput from 'domain/createOrder/dueDiligence/components/LocationInput'

interface Props {
  itemId?: number
  autoFocus?: boolean
  legalEntity: KitLegalEntity | undefined
  selectedLocation: SelectedLocation
  selectedService: SelectedService
  selectedType: string | LabelInValue | undefined
  onChange: (value: SelectedLocation) => void
  onChangeFederativeUnit?: () => void
  onChangeType: (value: string) => void
  required?: boolean
  requiredUrlUf?: boolean
  requiredUrlCidade?: boolean
  requiredUrlCartorio?: boolean
  warnings?: Partial<Record<keyof SelectedLocation, string>>
}

function LocationFields({
  autoFocus,
  itemId,
  required = true,
  requiredUrlUf,
  requiredUrlCidade,
  requiredUrlCartorio,
  legalEntity,
  selectedService,
  selectedLocation,
  selectedType,
  onChange,
  onChangeFederativeUnit,
  onChangeType,
  warnings = {},
}: Props) {
  const form = Form.useFormInstance()
  const service = getServiceConfig(selectedService.code!)
  const getName = (name: string) => (itemId !== undefined ? [itemId, name] : name)
  const allNotariesName = getName(TODOS_CARTORIOS_CIDADE)
  const { url_cartorio, url_cidade, url_uf } = getLocationUrl(selectedLocation)
  const showCityAndNotary = !service.cityDependsOnType || service.cityDependsOnType === selectedType

  const updateForm = (location: Partial<SelectedLocation>) => {
    form.setFieldsValue(location)
    onChange && onChange({ ...selectedLocation, ...location })
  }

  return (
    <>
      <LocationFederativeUnitSelect
        name={getName(URL_UF)}
        service={selectedService}
        legalEntity={legalEntity}
        onChange={(selected: LabelInValue | LabelInValue[] | undefined) => {
          updateForm({
            [URL_UF]: selected,
            [URL_CIDADE]: undefined,
            [URL_CARTORIO]: undefined,
            [TODOS_CARTORIOS_CIDADE]: false,
          })

          onChangeFederativeUnit && onChangeFederativeUnit()
        }}
        required={requiredUrlUf || required}
        autoFocus={autoFocus}
        labelInValue
        help={warnings.url_uf}
        validateStatus={warnings.url_uf ? 'warning' : undefined}
      />

      {!!service.LocationUFType && (
        <service.LocationUFType
          itemId={itemId}
          service={selectedService}
          location={selectedLocation}
          onChange={onChangeType}
          required={required}
        />
      )}

      {service.formFields.url_cidade !== undefined && showCityAndNotary && (
        <LocationCitySelect
          name={getName(URL_CIDADE)}
          service={selectedService}
          federativeUnit={url_uf as string}
          legalEntity={legalEntity}
          onChange={(selected: LabelInValue | LabelInValue[] | undefined) => {
            updateForm({
              [URL_CIDADE]: selected,
              [URL_CARTORIO]: undefined,
              [TODOS_CARTORIOS_CIDADE]: false,
            })
          }}
          required={requiredUrlCidade || required}
          help={warnings.url_cidade}
          validateStatus={warnings.url_cidade ? 'warning' : undefined}
          labelInValue
        />
      )}

      {service.formFields.url_cartorio !== undefined && showCityAndNotary && (
        <LocationNotarySelect
          name={getName(URL_CARTORIO)}
          checkAllName={allNotariesName}
          service={selectedService}
          federativeUnit={url_uf as string}
          city={url_cidade}
          value={url_cartorio}
          onChange={(selected: LabelInValue | LabelInValue[] | undefined, isAllNotaries?: boolean) => {
            updateForm({
              [URL_CARTORIO]: selected,
              [TODOS_CARTORIOS_CIDADE]: isAllNotaries,
            })
          }}
          required={requiredUrlCartorio || required}
          help={warnings.url_cartorio}
          validateStatus={warnings.url_cartorio ? 'warning' : undefined}
          labelInValue
        />
      )}

    </>
  )
}

const { TODOS_CARTORIOS_CIDADE, URL_CARTORIO, URL_CIDADE, URL_UF } = FieldName

export default React.memo(LocationFields)
