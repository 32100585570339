import { Checkbox, Form, Input } from 'antd'

import { GroupsSelect } from 'domain/groups'
import { ExplorerFilters } from 'domain/myFiles/types'
import { useMyFilesFilters } from 'domain/myFiles/state'
import { DocumentServicesSelect } from 'domain/servicesCBRdoc/fields'
import { DateRangePicker, Modal, SubmitButton } from 'ui'

import ClearButton from './MyFilesFilterClearButton'
import TypeSelect from './MyFilesFilterTypeSelect'
import OwnerSelect from './MyFilesFilterOwnerSelect'

interface Props {
  isOpen: boolean
  toggleModal: () => void
}

export default function MyFilesFilterModal({ isOpen, toggleModal }: Props) {
  const [form] = Form.useForm()
  const [filters, setFilters] = useMyFilesFilters()

  const onFinish = (formData: ExplorerFilters) => {
    setFilters(formData)
    toggleModal()
  }

  return (
    <Modal
      open={isOpen}
      title="Filtros"
      extra={<ClearButton closeModal={toggleModal} form={form} />}
      onCancel={toggleModal}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={filters}
        onFinish={onFinish}
        requiredMark={false}
        scrollToFirstError
      >
        <Form.Item name="name" label="Nome">
          <Input placeholder="Nome do arquivo" />
        </Form.Item>

        <TypeSelect />
        <DocumentServicesSelect />
        <OwnerSelect />

        <Form.Item name="dates" label="Data de criação">
          <DateRangePicker allowClear allowEmpty={allowEmpty} />
        </Form.Item>

        <GroupsSelect />

        <Form.Item name="ai" valuePropName="checked">
          <Checkbox>Extração de dados</Checkbox>
        </Form.Item>

        <SubmitButton label="Aplicar filtros" />
      </Form>
    </Modal>
  )
}

const allowEmpty: any = [true, true]
