import React from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { Button } from 'antd'

import { RoutePaths } from 'app/routes'
import { useViewKitValue } from 'state/kits'
import { SuccessMessage } from 'ui'

export default function CreateKitSuccessPage() {
  const kit = useViewKitValue()
  const history = useHistory()

  if (!kit) {
    return <Redirect to={RoutePaths.CREATE_KIT} />
  }

  return (
    <SuccessMessage
      title={
        <span className="text-secondary-500">
          <Link
            className="text-secondary-500 underline"
            to={RoutePaths.EDIT_KIT.replace(':kitId', String(kit.id))}
          >
            Kit {kit.name}
          </Link>{' '}
          foi criado com sucesso
        </span>
      }
    >
      <p className="text-secondary-500">Agora que você criou seu kit comece a utilizá-lo.</p>

      <div className="flex flex-row items-center mt-8 mb-20">
        <Button type="primary" ghost className="w-64 mr-3" onClick={() => history.push(RoutePaths.KITS)}>
          Ir para Meus Kits
        </Button>

        <Button
          type="primary"
          className="w-64"
          onClick={() => history.push(RoutePaths.CREATE_ORDER_KIT.replace(':id', String(kit.id)))}
        >
          Solicitar este Kit
        </Button>
      </div>
    </SuccessMessage>
  )
}
