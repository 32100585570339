import React from 'react'
import { Dropdown } from 'antd'

import { useHasPermission } from 'domain/auth'
import { Icon } from 'ui'

import { useConfirmAndDeleteGroup } from '../services'
import { Group } from '../types'

interface Props {
  group: Group
  children: JSX.Element
  setGroupToEdit: React.Dispatch<React.SetStateAction<Group | undefined>>
  onDeleteGroup?: (groupId: number) => void
}

export default function GroupsActionsDropdown({ group, children, setGroupToEdit, onDeleteGroup }: Props) {
  const confirmAndDelete = useConfirmAndDeleteGroup(onDeleteGroup)
  const isAdmin = useHasPermission('canManageUsersAndCompanyData')

  return (
    <Dropdown
      placement="bottomRight"
      menu={{
        onClick: ({ domEvent }) => domEvent.stopPropagation(),
        items: [
          {
            key: '0',
            label: 'Editar',
            onClick: () => {
              setGroupToEdit(group)
            },
            icon: <Icon name="edit" className="text-base mr-2" />,
          },
          {
            key: '1',
            label: 'Excluir',
            icon: <Icon name="trash" className="text-base mr-2" />,
            onClick: () => confirmAndDelete(group),
            disabled: !isAdmin,
          },
        ],
      }}
      trigger={['click']}
    >
      {children}
    </Dropdown>
  )
}
