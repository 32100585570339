import React from 'react'

import { Group } from 'domain/groups/types'
import { Tag } from 'ui'

interface Props {
  groups: Group[] | undefined
}

function AssociatedGroups({ groups }: Props) {
  return (
    <div className="flex flex-1 flex-col">
      <span className="text-gray-500 mb-1">Grupos associados</span>

      <AssociatedGroupsTags groups={groups} />
    </div>
  )
}

export function AssociatedGroupsTags({ groups }: Props) {
  if (!groups || !groups.length) {
    return null
  }

  return (
    <div className="flex flex-wrap mb-1 items-center gap-1">
      {groups.map((group: any) => (
        <Tag
          key={group.id}
          className="px-1 pt-1 rounded text-white text-2xs whitespace-no-wrap truncate w-[180px]"
          color={group.color}
        >
          {group.name}
        </Tag>
      ))}
    </div>
  )
}

export default AssociatedGroups
