import { CreateOrderState } from 'domain/createOrder/types'
import { DocumentRegistration } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type AtaNotarialFields = Record<
  | FieldName.ARQUIVOS
  | FieldName.MENSAGEM
  | FieldName.LOCAL_SERVICO
  | FieldName.TIPO_SERVICO
  | FieldName.URL_CARTORIO
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

const { URL_CARTORIO, URL_CIDADE, URL_UF, TIPO_SERVICO } = FieldName

class AtaNotarial extends DocumentRegistration {
  formFields: AtaNotarialFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    url_cartorio: Fields.url_cartorio,
    tipo_servico: Fields.tipo_servico,
    mensagem: Fields.mensagem,
    arquivos: Fields.arquivos,
    local_servico: {
      ...Fields.local_servico,
      showIf: ({ tipo_servico }) => tipo_servico === 'diligencia-presencial-do-tabeliao' 
    },
  }

  constructor() {
    super({
      id: 112,
      isKitAvailable: false,
      priceByFields: [URL_CARTORIO, URL_CIDADE, URL_UF, TIPO_SERVICO],
      shortName: 'Lavratura no Tabelionato de Notas',
      steps: ['localizacao', 'dados'],
      allowMultipleItems: false,
      displayIsNewTag: true,
    })
  }

  protected getSubmitData = (createOrderState: CreateOrderState) => {
    const { formData, selectedModel, selectedLocation } = createOrderState
    const { arquivos } = formData
    const orderName: any = {
      'ja-possuo-as-evidencias': 'Com evidências',
      'diligencia-presencial-do-tabeliao': 'Presencial'
    }

    const entries = Object.entries(formData).map(this.prepareFieldToSubmit)
    const data = Object.fromEntries(entries)
    const submitData: any = {
      ...data,
      ...this.getSubmitLocation(selectedLocation),
      formato: 'email',
      origin: 'Registro Doc',
      arquivos: arquivos?.map((a: any) => a.fileUploadId) ?? [],
      name: `${this.shortName} - ${orderName[selectedModel!]}`,
      tipo_servico: selectedModel,
    }

    return submitData
  }
}

const service = new AtaNotarial().initialize()

export default service
