import { useDispatch } from 'react-redux'

import { createOrderActions } from 'domain/createOrder/document'
import { ResearchFlow } from 'domain/createOrder/researches'
import { LicensePlateField, RadioGroupButtonsField } from 'domain/servicesCBRdoc/fields'

import config from '../pesquisaVeicularConfig'

export default function CreateOrderPesquisaVeicular() {
  return (
    <ResearchFlow
      header={<p className="mb-5 text-gray-600">Informe a placa que deseja pesquisar.</p>}
      renderFormItem={itemId => <PesquisaVeicularForm itemId={itemId} />}
    />
  )
}

function PesquisaVeicularForm({ itemId }: { itemId: number }) {
  const dispatch = useDispatch()

  return (
    <>
      <LicensePlateField itemId={itemId} field={placa} />

      <RadioGroupButtonsField
        itemId={itemId}
        field={tipo}
        onChange={value => {
          dispatch(createOrderActions.setSelectedType(value as any))
        }}
      />
    </>
  )
}

const { placa, tipo } = config.formFields
