import { useState } from 'react'
import { Table } from 'antd'

import { DropdownActionsButton, Modal } from 'ui'

import { useGroupsQuery } from '../services'
import { Group } from '../types'
import GroupsActionsDropdown from './GroupsActionsDropdown'
import EditGroupForm from './EditGroupForm'
import GroupTag from './GroupTag'
import GroupsPagination from './GroupsPagination'

export default function GroupsList() {
  const { data, isLoading } = useGroupsQuery()
  const [groupToEdit, setGroupToEdit] = useState<Group | undefined>(undefined)
  const closeModal = () => setGroupToEdit(undefined)

  const columns: any = [
    {
      title: 'Grupo',
      dataIndex: 'name',
      render: (name: string, group: Group) => <GroupTag name={group.name} color={group.color} />,
    },
    {
      title: '',
      dataIndex: 'id',
      align: 'right',
      render: (id: number, group: Group) => {
        return (
          <GroupsActionsDropdown group={group} setGroupToEdit={setGroupToEdit}>
            <DropdownActionsButton />
          </GroupsActionsDropdown>
        )
      },
    },
  ]

  return (
    <>
      <Table
        dataSource={data}
        rowKey={record => record.id}
        columns={columns}
        pagination={false}
        className="[&_th]:bg-gray-100"
        loading={isLoading}
      />

      <GroupsPagination />

      <Modal open={!!groupToEdit} title="Editar grupo" onCancel={closeModal} destroyOnClose>
        <EditGroupForm group={groupToEdit!} onSuccess={closeModal} />
      </Modal>
    </>
  )
}
