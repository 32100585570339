import React from 'react'

import { FeedbackMessage } from 'domain/app'
import { ViewOrderLayout, useGetOrderItem } from 'domain/orderItem/shared'
import { ViewPDF } from 'ui'

import DueDiligenceActions from './DueDiligenceActions'

function ViewDueDiligence() {
  return (
    <ViewOrderLayout icon="diligencias" downloadButton={<DueDiligenceActions />}>
      <ViewDueDiligenceContent />
    </ViewOrderLayout>
  )
}

function ViewDueDiligenceContent() {
  const { fileURL, status } = useGetOrderItem()

  if (fileURL) {
    return <ViewPDF fileUrl={fileURL!} />
  }

  return (
    <FeedbackMessage
      status={status}
      message={`
          Não foi possível realizar a diligência. 
          Verifique as informações e tente novamente, ou entre em contato para saber mais detalhes.
        `}
      alertType="error"
    />
  )
}

export default ViewDueDiligence
