import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector } from 'react-redux'

// dá pra apagar, mas no momento se remover dá erro de referência circular
import authReducer from './auth/slice_deprecated'
import createOrderReducer from 'domain/createOrder/document/state/createOrderSlice'
import kitsReducer from './kits/kitsSlice'
import sessionReducer from './session/slice'
import shoppingCartReducer from 'domain/shoppingCart/state/shoppingCartSlice'

export const rootReducer = combineReducers({
  auth: authReducer,
  createOrder: createOrderReducer,
  kits: kitsReducer,
  session: sessionReducer,
  shoppingCart: shoppingCartReducer,
})

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['recurrences/setFilters'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload.dates', 'payload.dateRange'],
        // Ignore these paths in the state
        ignoredPaths: ['recurrences.filters.dates', 'orders.filters.dateRange'],
      },
    }),
})

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, AppState, null, Action<string>>
export const useAppState: TypedUseSelectorHook<AppState> = useSelector

export default store
