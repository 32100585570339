import React from 'react'
import { Button, Dropdown, Modal } from 'antd'

import { ResearchResult } from 'domain/orderItem/types'
import { Icon } from 'ui'

import ViewOrderResultDetails from './ViewOrderResultDetails'
import useOrderResearchMenuItem from './useOrderResearchMenuItem'

interface Props {
  result: ResearchResult
}

function ViewOrderResearchActionsInline({ result }: Props) {
  const [orderResearchMenuItem, loading] = useOrderResearchMenuItem(result)

  const menu = {
    items: [
      {
        label: 'Ver detalhes',
        icon: <Icon name="eye" />,
        key: 'details',
        onClick: () =>
          Modal.info({
            title: 'Detalhes',
            content: <ViewOrderResultDetails result={result} />,
            okText: 'OK',
            closable: true,
          }),
      },
      orderResearchMenuItem,
    ],
  }

  return (
    <Dropdown placement="bottomRight" menu={menu} trigger={['click']}>
      <Button type="link" className="flex flex-row gap-2 items-center" loading={loading}>
        Ações <Icon name="chevron-down" />
      </Button>
    </Dropdown>
  )
}

export default ViewOrderResearchActionsInline
