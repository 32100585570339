import { useState } from 'react'

import { errorHandler } from 'api/errors'
import { AuthorizedAPI } from 'api/network/v2'
import { ApiStatus } from 'api/types'

import { CreateRecurrenceForm, CreateRecurrenceKind } from '../types'
import { convertCreateRecurrenceToPostAPI } from './convertRecurrence'

type Params = { 
  kind: CreateRecurrenceKind
  onSuccess: () => void 
}

const useCreateRecurrence = ({ kind, onSuccess }: Params) => {
  const [status, setStatus] = useState<ApiStatus>('idle')

  const submit = async (formData: CreateRecurrenceForm) => {
    try {
      setStatus('loading')

      const api = kind === 'order-items' ? '/recurrences' : '/recurrences/by-purchase'
      const postData = convertCreateRecurrenceToPostAPI(formData, kind)

      await AuthorizedAPI.post(api, postData)

      onSuccess()
      setStatus('success')
    } catch (error: any) {
      setStatus('idle')
      errorHandler(error, {
        code: '0x703',
      })
    }
  }

  return {
    submit,
    status,
  }
}

export default useCreateRecurrence
