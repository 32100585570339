import { ResearchFlow } from 'domain/createOrder/researches'
import { NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../pesquisaDivorcioConfig'

export default function CreateOrderPesquisaDivorcio() {
  return (
    <ResearchFlow
      header={<p className="mb-5 text-gray-600">Informe o nome e CPF que deseja pesquisar:</p>}
      renderFormItem={(itemId: number) => <PesquisaDivorcioForm itemId={itemId} />}
    />
  )
}

function PesquisaDivorcioForm({ itemId }: { itemId: number }) {
  return (
    <>
      <CPFInput itemId={itemId} autoFocus={itemId === 0} />
      <NameField itemId={itemId} field={nome_pf} />
    </>
  )
}

const { nome_pf } = config.formFields
