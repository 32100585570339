import React from 'react'

import { ViewOrderResearchActionsInline, ViewResearchActions, ViewResearchWithResults } from 'domain/orderItem'
import { ExcelGenerator, ResearchResult, TableColumn } from 'domain/orderItem/types'

function ViewPesquisaInventario() {
  return (
    <ViewResearchWithResults columns={columns} actions={<ViewResearchActions excel={excelGenerator} />} />
  )
}

const columns: TableColumn[] = [
  {
    title: 'Cidade',
    dataIndex: ['url_cidade', 'label'],
    className: 'font-bold',
  },
  {
    title: 'Estado',
    dataIndex: ['url_uf', 'label'],
  },
  {
    title: 'Cartório',
    dataIndex: ['url_cartorio', 'label'],
  },
  {
    title: 'Livro/Folha',
    dataIndex: 'livro_pagina',
  },
  {
    title: 'Tipo de documento',
    dataIndex: 'tipo_documento',
  },
  {
    title: 'Tipo de papel',
    dataIndex: 'tipo_papel',
  },
  {
    title: 'Emissão',
    dataIndex: 'data_emissao',
  },
  {
    title: '',
    dataIndex: 'actions',
    align: 'right',
    render: (_: any, result: ResearchResult) => {
      return <ViewOrderResearchActionsInline result={result} />
    },
  },
]

const excelGenerator: ExcelGenerator = {
  columns: [...columns].splice(0, columns.length - 1)
}

export default ViewPesquisaInventario
