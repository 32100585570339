import { CertificateFlow, useDontKnowBookPagePriceQuery } from 'domain/createOrder/certificates'
import { DynamicTypeSelect } from 'domain/createOrder/document'
import config from '../certidaoEscrituraConfig'
import FormCertidaoEscritura from './CreateOrderCertidaoEscrituraForm'

export default function CreateOrderCertidaoEscritura() {
  const { data: dontKnowBookPagePrices, isFetching } = useDontKnowBookPagePriceQuery()

  return (
    <CertificateFlow
      header={
        <>
          <p className="mb-5 text-gray-600">
            Selecione Pessoa ou Empresa e digite o número do CPF ou CNPJ que deseja a Certidão de Escritura.
          </p>

          <DynamicTypeSelect field={tipo} autoFocus />
        </>
      }
      renderFormItem={(itemId: number) => (
        <FormCertidaoEscritura
          itemId={itemId}
          loadingBookPagePrice={isFetching}
          dontKnowBookPagePrices={dontKnowBookPagePrices}
        />
      )}
      noRenderOrderName
    />
  )
}

const { tipo } = config.formFields
