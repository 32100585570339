import React from 'react'
import { useParams } from 'react-router-dom'
import { Provider } from 'jotai'

import { ViewOrderItem } from 'domain/orderItem'

export default function ViewOrderPage() {
  const params = useParams<{ id: string }>()

  return (
    <Provider>
      <ViewOrderItem orderItemId={params.id as unknown as number} />
    </Provider>
  )
}
