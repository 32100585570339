import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { KitItem } from 'domain/kits/types'
import { removeStorageItem } from 'utils/localStorage'
import {
  KIT_ORDER_CURRENT_ID,
  useGetKit,
  useGetUnavailableKitItems,
  useSetColumnsIdentification,
} from '../../state'
import AddingRowToCart from './KitSpreadsheetAddToCartRow'

type Props = { rows: any[]; setRows: React.Dispatch<React.SetStateAction<any[]>> }

export default function KitSpreadsheetAddToCartRows({ rows, setRows }: Props) {
  const validItems = useGetAvailablekitItems()

  useRedirectToCartWhenFinish(rows)

  return (
    <div className="max-h-80 overflow-auto">
      {rows.map(row => (
        <AddingRowToCart key={row.rowId} row={row} kitItems={validItems} setRows={setRows} />
      ))}      
    </div>
  )
}

function useRedirectToCartWhenFinish(rows: any[]) {
  const history = useHistory()
  const setColumnFields = useSetColumnsIdentification()

  useEffect(() => {
    if (rows.length === 0) {
      history.push(RoutePaths.SHOPPING_CART)
    }

    return () => {
      setColumnFields({})
      removeStorageItem(KIT_ORDER_CURRENT_ID)
    }
  }, [rows.length])
}

function useGetAvailablekitItems() {
  const kit = useGetKit()
  const unavailableKitItems = useGetUnavailableKitItems()

  return useMemo((): KitItem[] => {
    const unavailableKitItemsIds = unavailableKitItems.map(kitItem => kitItem.id)

    return kit.items.filter(kitItem => !unavailableKitItemsIds.includes(kitItem.id))
  }, [kit, unavailableKitItems])
}
