import { Permission, PermissionAPI, PermissionsCodeAPI } from 'domain/auth/types'
import { User, UserAPI } from 'domain/users/types'
import { displayDateAndTime } from 'utils/dateTime'
import { objectToCamelCase } from 'utils/transformData'

export function convertUser(data: UserAPI) {
  const { deleted_at, last_api_request_at, last_login_at, permissions, ...snakeCase } = data
  const camelCase = objectToCamelCase(snakeCase)

  const user: User = {
    lastRequest: last_api_request_at,
    lastLogin: last_login_at,
    permissions: permissions ? permissions.map(convertPermission) : [],
    ...camelCase,
  }

  if (deleted_at) {
    user.deletedAtDisplay = displayDateAndTime(deleted_at)
  }

  return user
}

export function convertPermission({
  code,
  id,
  name,
  permission_group_id,
}: PermissionAPI): Permission {
  return {
    id,
    name,
    groupId: permission_group_id,
    code: PermissionsCodeAPI[code],
  }
}
