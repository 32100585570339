import React, { useReducer } from 'react'

import { useGetExplorerItem } from 'domain/myFiles/state'
import { useAllServices } from 'domain/servicesCBRdoc'
import { Modal, TextButton } from 'ui'

import DocumentTypeForm from './DocumentTypeForm'

export default function DocumentType({ editable }: { editable: boolean }) {
  const [isModalOpen, toggleModal] = useReducer(prev => !prev, false)
  const explorerItem = useGetExplorerItem()
  const allServices = useAllServices()
  const service = allServices.find(service => service.id === explorerItem?.serviceId)

  return (
    <div className="mt-2 mb-8">
      <div className="text-sm text-gray-500 mb-1">Tipo do documento</div>

      <div className="flex justify-between gap-3">
        <span>{service?.name || 'Outros'}</span>

        {editable && (
          <>
            <TextButton label="Alterar" onClick={toggleModal} />

            <Modal title="Alterar o tipo do documento" open={isModalOpen} onCancel={toggleModal}>
              <DocumentTypeForm onFinish={toggleModal} />
            </Modal>
          </>
        )}
      </div>
    </div>
  )
}
