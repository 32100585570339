import React, { ChangeEvent, useState } from 'react'
import { Button, Input } from 'antd'

import { useEditKitMutation } from 'domain/kits/services'
import { Kit } from 'domain/kits/types'

interface Props {
  kit: Kit
  onFinish: () => void
  queryKey: string | any[]
}

function EditKitName({ kit, onFinish, queryKey }: Props) {
  const [kitNameInput, setKitNameInput] = useState(kit.name || '')

  const onEditingName = (e: ChangeEvent<HTMLInputElement>) => setKitNameInput(e.target.value)

  const { mutate, isLoading } = useEditKitMutation(queryKey, kit.id, {
    onSettled: onFinish,
  })

  return (
    <Input.Group
      compact
      className="transition duration-300 ease-in-out flex flex-row mt-3 mb-5"
      style={maxWidth}
    >
      <Input
        defaultValue={kitNameInput}
        onChange={onEditingName}
        placeholder="Nome do Kit"
        maxLength={100}
        className="mr-2"
      />
      <Button
        type="primary"
        loading={isLoading}
        disabled={isLoading || kitNameInput.trim().length === 0}
        onClick={() => mutate({ name: kitNameInput })}
      >
        Confirmar
      </Button>
    </Input.Group>
  )
}

const maxWidth = { maxWidth: 400 }

export default EditKitName
