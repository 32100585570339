import { Button, UploadProps } from 'antd'

import Icon from 'ui/Icon'

export interface UploadDraggerFilesProps extends Pick<UploadProps, 'accept' | 'fileList'> {
  maxCount: number
  maxFileFize: number
  onRemoveAll: () => void
}

export default function UploadDraggerFiles({
  accept,
  fileList,
  maxCount,
  maxFileFize,
  onRemoveAll,
}: UploadDraggerFilesProps) {
  return (
    <>
      <div className="border border-dashed border-primary bg-blue-50 text-primary flex flex-col justify-center items-center padding-6 py-8 px-4 mb-4">
        <Icon name="file-pdf" className="text-5xl text-primary mb-3" />

        <p className="text-xs">
          {maxCount > 1
            ? 'Solte um ou mais documentos para fazer o upload'
            : 'Solte um documento para fazer o upload'}
          <br />
          <AcceptedFilesDescription accept={accept!} />
        </p>
      </div>

      <p className="my-3 text-center">ou</p>

      <p className="text-xs text-primary underline text-center">
        {maxCount > 1
          ? 'Escolha um ou mais arquivos do seu computador'
          : 'Escolha um arquivo do seu computador'}
      </p>

      <p className="mt-2 text-2xs text-gray-500">Tamanho máximo: {maxFileFize}MB</p>

      {fileList && fileList.length > 0 && <RemoveAllFiles maxCount={maxCount} onRemoveAll={onRemoveAll} />}
    </>
  )
}

function RemoveAllFiles({
  maxCount,
  onRemoveAll,
}: Pick<UploadDraggerFilesProps, 'maxCount' | 'onRemoveAll'>) {
  return (
    <Button
      type="link"
      onClick={e => {
        e.stopPropagation()
        onRemoveAll()
      }}
      className="p-1 text-xs"
    >
      {maxCount > 1 ? 'Remover todos os arquivos' : 'Remover o arquivo'}
    </Button>
  )
}

function AcceptedFilesDescription({ accept }: { accept: string }) {
  const parts = accept.split(',').map(extension => extension.toLocaleUpperCase())

  if (parts.length === 1) {
    return <span>{parts[0]}</span>
  }

  const last = parts.pop()

  return (
    <span>
      {parts.join(',')} ou {last}
    </span>
  )
}
