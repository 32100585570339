import TagManager from 'react-gtm-module'

import { RDS } from 'api/rdstation'
import { useCurrentUser } from 'domain/auth'
import { useRefreshCustomerCredits } from 'domain/credits'
import { useCurrentCustomer } from 'domain/customers'

import { ChosenPaymentMethod, PaymentMethod } from '../../checkout/types'
import { walletDepositAPI } from '../../checkout/services/api'

function useBuyCreditsWithInvoice() {
  const refreshCredits = useRefreshCustomerCredits()
  const user = useCurrentUser()
  const customer = useCurrentCustomer()

  return async function buyCreditsWithInvoice(paymentValue: number) {
    const { data } = await walletDepositAPI({
      amount: paymentValue,
      payment_method: PaymentMethod.INVOICE,
      send_email_after_approved: true,
    })

    if (data) {
      const { amount, bank_slip_barcode, bank_slip_url, bonus_amount, invoice_id } = data

      if (invoice_id && amount) {
        refreshCredits()
      }

      const tagName = 'credito_adicionado_via_boleto'

      TagManager.dataLayer({
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          event: 'boletoPayment',
          transactionId: data.invoice_id,
          transactionAffiliation: 'Sem-Cupom',
          transactionTotal: data.amount,
          transactionTax: 0,
          transactionShipping: 0,
          transactionProducts: [
            {
              sku: 'DD45',
              name: tagName,
              category: 'Crédito',
              price: data.amount,
              quantity: 1,
            },
          ],
        },
      })

      RDS.conversionEvent({
        event_type: 'CONVERSION',
        event_family: 'CDP',
        payload: {
          conversion_identifier: 'Crédito adicicionado - Boleto',
          name: user.name,
          email: user.email,
          company_name: customer.companyName,
          mobile_phone: user.phone,
          tags: [tagName],
          cf_modo_pagamento: 'Boleto',
          cf_valor_credito: data.amount,
        },
      })

      const chosenMethod: ChosenPaymentMethod = {
        chosenMethod: PaymentMethod.INVOICE,
        invoice: {
          bankSlipBarcode: bank_slip_barcode!,
          bankSliptUrl: bank_slip_url!,
        },
        bonusAmount: bonus_amount,
      }

      return chosenMethod
    }
  }
}

export default useBuyCreditsWithInvoice
