import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs } from 'domain/createOrder/document'
import { CNPJAndNameFields, DateField, NameField, SelectField, TextField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../certidaoSTFDistribuidorConfig'

export default function CreateOrderCertidaoDistribuidor() {
  return (
    <CertificateFlow
      header={
        <p className="mb-5 text-gray-600">
          Selecione Pessoa ou Empresa e digite o número do CPF ou CNPJ que deseja a Certidão Distribuidor.
        </p>
      }
      renderFormItem={itemId => <CertidaoDistribuidorForm itemId={itemId} />}
    />
  )
}

const CertidaoDistribuidorForm = ({ itemId }: { itemId: number }) => {
  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={
          <>
            <CPFInput itemId={itemId} autoFocus={itemId === 0} />
            <NameField itemId={itemId} field={nome_pf} />
            <DateField itemId={itemId} field={nascimento} />
            <TextField itemId={itemId} field={rg} />
            <TextField itemId={itemId} field={rg_expedidor} />
            <NameField itemId={itemId} field={mae} />
            <NameField itemId={itemId} field={pai} />
            <SelectField itemId={itemId} field={estado_civil} />
            <SelectField itemId={itemId} field={nacionalidade} />
          </>
        }
        juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} autoFocus={itemId === 0} />}
      />
    </>
  )
}

const { estado_civil, mae, nascimento, nacionalidade, nome_pf, nome_pj, pai, rg, rg_expedidor } =
  config.formFields
