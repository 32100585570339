import { ServiceAPI } from 'domain/servicesCBRdoc/types'

export default function sortServiceByFavorites(serviceA: ServiceAPI, serviceB: ServiceAPI) {
  if (serviceA.is_favorite && serviceB.is_favorite) {
    if (serviceA.name.toLowerCase() > serviceB.name.toLowerCase()) return -1
    if (serviceA.name.toLowerCase() < serviceB.name.toLowerCase()) return 1
  }

  if (serviceA.is_favorite) return -1
  if (serviceB.is_favorite) return 1

  if (serviceA.name.toLowerCase() > serviceB.name.toLowerCase()) return 1
  if (serviceA.name.toLowerCase() < serviceB.name.toLowerCase()) return -1

  return 0
}
