import React from 'react'

import { Routes } from 'app/routes'
import { useHasPermission } from 'domain/auth'
import { AIShoppingStep } from 'domain/createOrder/types'
import { Icon } from 'ui'

function NewModelButton() {
  const canManageAiModels = useHasPermission('canManageAiModels')

  if (!canManageAiModels) {
    return null
  }

  return (
    <a href={Routes.CreateOrderAI(AIShoppingStep.MODELO)} className="btn-ghost flex justify-center items-center gap-1">
      <Icon name="plus-circle" className="mb-1" /> Criar novo
    </a>
  )
}

export default NewModelButton
