import React, { useState } from 'react'
import { Upload, UploadProps } from 'antd'
import { AxiosError } from 'axios'

import { errorHandler } from 'api/errors'
import { FileError, TempFile } from 'domain/app/types'
import Icon from '../Icon/Icon'
import UploadDraggerError from './UploadDraggerError'
import UploadDraggerFiles from './UploadDraggerFiles'
import { MAX_FILES_QUANTITY, MAX_FILE_SIZE } from 'app/config/files'

const { Dragger } = Upload

export interface UploadDraggerProps extends Omit<UploadProps, 'beforeUpload' | 'onChange'> {
  maxFileFize?: number
  fileList: TempFile[]
  setFileList: React.Dispatch<React.SetStateAction<TempFile[]>>
  onChange?: (files: TempFile[]) => void
  shouldBeforUpload?: boolean
}

export default function UploadDragger({
  fileList,
  setFileList,
  maxFileFize = MAX_FILE_SIZE,
  maxCount = MAX_FILES_QUANTITY,
  customRequest,
  accept = '.png, .jpg, .jpeg, .pdf',
  onChange,
  className = '',
  shouldBeforUpload = true,
  ...props
}: UploadDraggerProps) {
  const [errorMessage, setErrorMessage] = useState<FileError>()

  const showErrorMessage = (message: string, file?: any) => {
    setErrorMessage({
      validate: [],
      message: [message],
    })
  }

  const validateFileList = (list: any[]) => {
    setErrorMessage(undefined)

    const uploadList = list.map(file => {
      const isSizeValid = file.size / 1024 / 1024 < maxFileFize

      if (!isSizeValid) {
        showErrorMessage(
          `O arquivo precisa ter no máximo ${maxFileFize}mb. Por favor, tente novamente.`,
          file
        )
        return {
          ...file,
          status: 'error',
          name: file.name,
          uid: file.uid,
        }
      }

      const fileType = file.type.split('/')[1]

      if (!accept.includes(fileType)) {
        showErrorMessage('O arquivo não é de um tipo válido para upload.', file)
        return {
          ...file,
          status: 'error',
          name: file.name,
          uid: file.uid,
        }
      }

      if (!shouldBeforUpload && file.status === 'uploading') {
        file.status = 'done'
      }

      return file
    })

    return uploadList
  }

  const setFileListWithoutDuplicates = (list: TempFile[]) => {
    setFileList(prev => {
      const newArr: TempFile[] = []

      list.forEach(ul => {
        if (!prev.find(p => p.uid === ul.uid)) {
          newArr.push(ul)
        }
      })

      const returnArr = [...prev, ...newArr]

      return returnArr.filter(f => !f.uid || f.uid.startsWith('rc-upload-'))
    })
  }

  const beforeUpload = (_: any, list: any[]) => {
    const uploadList = validateFileList(list)

    if (list.length > maxCount) {
      showErrorMessage('Você atingiu o número máximo de arquivos por upload.')
      return false
    }

    setFileListWithoutDuplicates(uploadList)

    return customRequest ? _ : false
  }

  const onRemove = (file: TempFile) => {
    setFileList(prev => prev.filter(p => p.uid !== file.uid))
    setErrorMessage(undefined)
  }

  const onRemoveAll = () => {
    setFileList([])
    setErrorMessage(undefined)
  }

  const handleRequest = (params: any) => {
    if (fileList.find(f => f.uid === params.file.uid)?.status === 'error') {
      errorHandler(new Error(errorMessage?.message[0]), {
        code: '0x513',
        message: 'Erro ao enviar o arquivo',
        description:
          'Erro! O arquivo selecionado parece estar com problema. Verifique se está no formato correto, corrompido ou excede o tamanho máximo de upload permitido e tente novamente.',
      })

      return
    }

    if (customRequest) {
      customRequest({
        ...params,
        onError: (error: any) => {
          const { file } = params

          errorHandler(error, {
            code: '0x513',
            message: 'Erro ao enviar o arquivo',
            description:
              'Erro! O arquivo selecionado parece estar com problema. Verifique se está no formato correto, corrompido ou excede o tamanho máximo de upload permitido e tente novamente.',
          })

          setFileList(fileList.map(f => (f.uid === file.uid ? { ...f, status: 'error' } : f)))

          if (error?.errors) {
            setErrorMessage({
              validate: error.errors,
              message: Object.values(error.errors).flat() as string[],
            })
          } else {
            const hasVirus = error instanceof AxiosError && error.response?.data?.message.includes('vírus')
            const errorMessage = hasVirus
              ? 'O arquivo que você tentou enviar contém vírus.'
              : 'Erro inesperado. Verifique o arquivo enviado e tente novamente.'

            setErrorMessage({
              validate: [],
              message: [errorMessage],
            })
          }
        },
      })
    }
  }

  return (
    <div className={`ant-upload-wrapper p-4 bg-white border border-gray-200 ${className}`}>
      <Dragger
        accept={accept}
        listType="picture"
        className="border-0 bg-transparent"
        fileList={fileList}
        showUploadList={showUploadList}
        beforeUpload={shouldBeforUpload ? beforeUpload : undefined}
        customRequest={handleRequest}
        multiple={maxCount > 1}
        maxCount={maxCount > 1 ? maxCount : undefined}
        onRemove={onRemove}
        onChange={data => {
          const files = validateFileList(data.fileList)
          setFileListWithoutDuplicates(files)
          onChange && onChange(files)
        }}
        {...props}
      >
        {errorMessage ? (
          <UploadDraggerError error={errorMessage} setError={setErrorMessage} setFileList={setFileList} />
        ) : (
          <UploadDraggerFiles
            accept={accept}
            fileList={fileList}
            maxCount={maxCount}
            maxFileFize={maxFileFize}
            onRemoveAll={onRemoveAll}
          />
        )}
      </Dragger>
    </div>
  )
}

const showUploadList = {
  showRemoveIcon: true,
  removeIcon: <Icon name="trash" />,
}
