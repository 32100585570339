import { getErrorMessage } from 'api/errors'

export default function ErrorMessage({ error }: { error: any }) {
  const { message, description } = getErrorMessage(error)

  return (
    <div className="p-7">
      <h4>{message}</h4>
      {!!description && <p>{description}</p>}
    </div>
  )
}
