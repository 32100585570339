import { DueDiligence } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { LOCAL_SERVICO, MENSAGEM, NUMERO_PROCESSO, TIPO_PROCESSO } = FieldName

type DiligenciaFields = Record<
  FieldName.LOCAL_SERVICO | FieldName.MENSAGEM | FieldName.NUMERO_PROCESSO | FieldName.TIPO_PROCESSO,
  FieldConfig
>

class DiligenciaProtocolos extends DueDiligence {
  formFields: DiligenciaFields = {
    [LOCAL_SERVICO]: Fields.local_servico,
    [NUMERO_PROCESSO]: Fields.numero_processo,
    [TIPO_PROCESSO]: Fields.tipo_processo,
    [MENSAGEM]: Fields.mensagem,
  }

  constructor() {
    super({
      id: 87,
      shortName: 'Diligência - Protocolos',
    })
  }
}

const service = new DiligenciaProtocolos().initialize()

export default service

