import { useHasPermission } from 'domain/auth'

function useHasViewOrderPermission() {
  const canViewOwnOrders = useHasPermission('canSeeOwnOrders') 
  const canViewOthersUsersOrders = useHasPermission('canSeeOtherUsersOrders')

  return canViewOwnOrders || canViewOthersUsersOrders
}

export default useHasViewOrderPermission
