import { CertificateFlow } from 'domain/createOrder/certificates'
import {
  CreateOrderLegalEntityTabs,
  DynamicModelRadioButton,
  DynamicTypeRadio,
  useCreateOrderState,
  useLoadExtraInfos,
} from 'domain/createOrder/document'
import { AllExtraInfos } from 'domain/orderItem/types'
import { CNPJAndNameFields, ExtraInfoFields, NameField } from 'domain/servicesCBRdoc/fields'
import { getLocationUrl } from 'domain/servicesCBRdoc/helpers/location'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { CPFInput } from 'ui'

import config from '../certidaoEmpresaCartoriosPJConfig'

export default function CreateOrderCertidaoEmpresaCartoriosPJ() {
  const { data, isFetching } = useLoadExtraInfos()
  const { selectedLocation, selectedType } = useCreateOrderState()
  const location = getLocationUrl(selectedLocation)

  return (
    <CertificateFlow
      header={
        <>
          <DynamicTypeRadio field={tipo_documento} params={location} autoFocus />

          {selectedType && (
            <DynamicModelRadioButton
              field={tipo_certidao}
              params={{ ...location, tipo_documento: selectedType as string }}
            />
          )}
        </>
      }
      renderFormItem={(itemId: number) => (
        <CertidaoEmpresaCartoriosPJForm itemId={itemId} extraInfos={data} loading={isFetching} />
      )}
    />
  )
}

interface Props {
  itemId: number
  loading: boolean
  extraInfos: AllExtraInfos | undefined
}

const CertidaoEmpresaCartoriosPJForm = ({ itemId, extraInfos, loading }: Props) => {
  const { selectedService } = useCreateOrderState()

  return (
    <>
      {(extraInfos?.['campos-necessarios'] as FieldName[])?.length > 0 && (
        <>
          <ExtraInfoFields
            serviceCode={selectedService.code!}
            itemId={itemId}
            data={extraInfos}
            loading={loading}
          />

          <hr className="my-5" />
        </>
      )}

      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={
          <>
            <CPFInput itemId={itemId} />
            <NameField itemId={itemId} field={nome_pf} />
          </>
        }
        juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} />}
      />
    </>
  )
}

const { nome_pj, nome_pf, tipo_certidao, tipo_documento } = config.formFields
