import { useDispatch } from 'react-redux'

import { LegalEntity } from 'domain/app/types'
import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document'
import { TabsLegalEntity } from 'ui'

export interface Props {
  itemId: number
  fisica: JSX.Element
  juridica: JSX.Element | null
  disableTab?: LegalEntity
}

export default function CreateOrderLegalEntityTabs({
  itemId,
  fisica,
  juridica,
  disableTab,
}: Props) {
  const dispatch = useDispatch()
  const { formItems } = useCreateOrderState()
  const selectedEntityType = formItems[itemId]?.selectedEntityType ?? 'fisica'

  if (juridica === null) {
    return fisica
  }

  return (
    <TabsLegalEntity
      selected={selectedEntityType}
      onChange={activeKey => {
        dispatch(createOrderActions.setMultipleItemsLegalEntity({ id: itemId, value: activeKey }))
      }}
      naturalPersonContent={fisica}
      juridicalPersonContent={juridica}
      disableTab={disableTab}
    />
  )
}
