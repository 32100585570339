import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { ResearchFlow } from 'domain/createOrder/researches'
import {
  CreateOrderLegalEntityTabs,
  createOrderActions,
  useCreateOrderState,
} from 'domain/createOrder/document'
import { CNPJAndNameFields, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'
import config from '../pesquisaEmpresaCartoriosPJConfig'

export default function CreateOrderPesquisaEmpresaCartoriosPJ() {
  return (
    <ResearchFlow
      header={<p className="mb-5 text-gray-600">Informe o CNPJ que deseja pesquisar</p>}
      renderFormItem={(itemId: number) => <PesquisaEmpresaCartoriosPJForm itemId={itemId} />}
    />
  )
}

function PesquisaEmpresaCartoriosPJForm({ itemId }: { itemId: number }) {
  const dispatch = useDispatch()
  const { formItems } = useCreateOrderState()
  const selectedEntityType = formItems[itemId]?.selectedEntityType

  useEffect(() => {
    if (!selectedEntityType) {
      dispatch(createOrderActions.setMultipleItemsLegalEntity({ id: itemId, value: 'juridica' }))
    }
  }, [])

  if (!selectedEntityType) return null

  return (
    <CreateOrderLegalEntityTabs
      itemId={itemId}
      fisica={
        <>
          <CPFInput itemId={itemId} autoFocus={itemId === 0} />
          <NameField itemId={itemId} field={nome_pf} />
        </>
      }
      juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} autoFocus={itemId === 0} />}
    />
  )
}

const { nome_pf, nome_pj } = config.formFields
