import { atom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithReset, useResetAtom, atomWithStorage } from 'jotai/utils'
import { useAtomUri_deprecated } from 'utils/navigation'

import { ExplorerAction, ExplorerFilters, ExplorerItem, MyFilesPagination } from '../types'

type FilesSliceState = {
  action: ExplorerAction
  filters: ExplorerFilters
  currentItem: ExplorerItem | undefined
  latestStorageOperation: number | undefined
}

export const initialFilters = {
  ai: false,
  name: '',
  type: undefined,
  services: undefined,
  tags: undefined,
  owner: undefined,
  dates: undefined,
}

export const initialState: FilesSliceState = {
  action: undefined,
  currentItem: undefined,
  filters: initialFilters,
  latestStorageOperation: Date.now(),
}

const initialPaginationState = {
  page: 1,
  pageCount: 0,
  totalCount: 0,
  pageSize: 20
}

const actionAtom = atom<ExplorerAction | undefined>(undefined)
const filtersAtom = atomWithReset<ExplorerFilters>(initialFilters)
const latestStorageOperationAtom = atom<number | undefined>(Date.now())
const latestStorageOperationDerivedAtom = atom(
  (get) => get(latestStorageOperationAtom),
  (_, set) => {
    set(latestStorageOperationAtom, Date.now())
  },
)

// pagination atom
const paginationAtom = atomWithStorage('my-files-pagination', initialPaginationState)

export const useMyFilesPagination = () => useAtomUri_deprecated<MyFilesPagination>(paginationAtom)  
export const useSetMyFilesAction = () => useSetAtom(actionAtom)
export const useGetMyFilesAction = () => useAtomValue(actionAtom)
export const useUpdateLatestStorageOperation = () => useSetAtom(latestStorageOperationDerivedAtom)
export const useGetLatestStorageOperation = () => useAtomValue(latestStorageOperationDerivedAtom)
export const useMyFilesFilters = () => useAtomUri_deprecated<ExplorerFilters>(filtersAtom)
export const useSetMyFilesFilters = () => {
  const [, fn] = useMyFilesFilters()
  return fn
}
export const useGetMyFilesFilters = () => {
  const [val] = useMyFilesFilters()
  return val
}

export const useClearMyFilesFilters = () => useResetAtom(filtersAtom) 
