import React from 'react'
import { Form, Modal as AntModal, FormInstance } from 'antd'

import {
  DEFAULT_ORDER_NAME,
  useGroups,
  useSetCheckoutStep,
  useSetOrderDetailsConfirmed,
  useSetOrderName,
} from 'domain/checkout'
import { useCurrentCustomer } from 'domain/customers'
import { GroupsAssociation } from 'domain/groups'
import { TextInput } from 'ui'

interface Props {
  form: FormInstance
  children: React.ReactNode
  isValidating?: boolean
  initialValues?: any
  hideOrderName?: boolean
  isCheckout?: boolean
  onFinish?: (data: any) => void
}

export default function CartOrderDetailsForm({
  form,
  initialValues,
  isValidating = false,
  children,
  hideOrderName,
  isCheckout,
  onFinish,
}: Props) {
  const [groups, setSelectedGroups] = useGroups()
  const customer = useCurrentCustomer()
  const mustSelectGroup = customer.orderGroupRequired && groups.length === 0
  const setCartName = useSetOrderName()
  const setOrderDetailsConfirmed = useSetOrderDetailsConfirmed()
  const setCheckoutStep = useSetCheckoutStep()

  const handleSubmit = ({ orderName }: any) => {
    setCartName(orderName)

    if (mustSelectGroup) {
      AntModal.error({ title: 'Grupo Associado', content: 'Você precisa associar o pedido a um grupo.' })
    } else {
      if ((orderName !== DEFAULT_ORDER_NAME && !mustSelectGroup) || isCheckout) {
        setOrderDetailsConfirmed(true)

        if (isCheckout) {
          setCheckoutStep('in-progress')
        }
      }

      onFinish && onFinish({ orderName })
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      requiredMark={false}
      scrollToFirstError
      initialValues={initialValues}
      className="max-w-lg"
    >
      {!hideOrderName && (
        <TextInput 
          name="orderName" 
          label="Nome" 
          required 
          autoFocus 
          maxLength={120} 
          loading={isValidating}
        />
      )}

      <GroupsAssociation 
        actionLabel={groups.length === 0 ? 'Adicionar grupos' : 'Editar grupos'} 
        groups={groups} 
        onFinish={setSelectedGroups} 
      />

      {children}
    </Form>
  )
}
