import { useMutation } from 'react-query'
import { notification } from 'antd'

import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'
import { Recurrence, RecurrenceItem } from '../types'

type Props = {
  onSuccess?: () => void
}

function useDeleteRecurrenceItems(recurrenceId: number, { onSuccess }: Props = {}) {
  return useMutation(
    async (itemId: number | number[]) => {
      const ids = Array.isArray(itemId) ? itemId.join(',') : itemId
      return await AuthorizedAPI.delete(`/recurrences/${recurrenceId}/items/${ids}`)
    },
    {
      onMutate: async idsToDelete => {
        const queryKey = ['recurrence', recurrenceId]
        const previousData = queryClient.getQueryData(queryKey) as Recurrence

        if (previousData) {
          const filterDeleted = Array.isArray(idsToDelete)
            ? (item: RecurrenceItem) => !idsToDelete.includes(item.id)
            : (item: RecurrenceItem) => item.id !== idsToDelete

          queryClient.setQueryData(queryKey, {
            ...previousData,
            items: previousData.items.filter(filterDeleted),
          })
        }

        return previousData
      },
      onError: (data, itemId, previousData: any) => {
        queryClient.setQueryData(['recurrence', itemId], previousData)
      },
      onSuccess: (data, itemId: number | number[]) => {
        queryClient.invalidateQueries(['recurrences'])

        notification.success({
          message: 'Exclusão realizada',
          description:
            Array.isArray(itemId) && itemId.length > 1
              ? 'Os itens foram excluídos da recorrência.'
              : 'O item foi excluído da recorrência.',
        })

        onSuccess && onSuccess()
      },
    }
  )
}

export default useDeleteRecurrenceItems
