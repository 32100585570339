import { OrderItem, ResearchResult, ResearchResultsFieldName } from 'domain/orderItem/types'
import { ResearchResultAPI } from 'domain/orderItem/typesAPI'
import { ResultCode } from 'domain/orders/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { displayDate } from 'utils/dateTime'
import { formatCPFCNPJ } from 'utils/formatters'

import { getFieldConfigByAPIName } from '../serviceFields'
import { ResearchConfig } from '../types'
import CBRdocDocument from './CBRdocDocument'

class Research extends CBRdocDocument {
  autoOrderResults?: ResultCode
  doNotOrderResultsWithSameNotary?: boolean
  isResearch = true
  hasResultsInPDF?: boolean
  checkMissingFieldsOnResults?: boolean

  constructor(config: ResearchConfig) {
    super({
      ...config,
      steps: config.steps ?? ['dados'],
    })

    const { autoOrderResults, hasResultsInPDF, doNotOrderResultsWithSameNotary } = config

    this.autoOrderResults = autoOrderResults
    this.hasResultsInPDF = hasResultsInPDF
    this.doNotOrderResultsWithSameNotary = doNotOrderResultsWithSameNotary
  }

  protected _convertResult = (orderItem: OrderItem, detail: ResearchResult, index: number) => {
    // @ts-ignore
    const { cartorio, cidade, documento, livro, pagina, uf, ...fields } = detail
    const result: ResearchResult = { ...fields }

    // TODO: Retornar id do backend
    result.id = `${orderItem.id}_${index}`

    if (cartorio) {
      result.url_cartorio = {
        label: cartorio.nome,
        value: cartorio.url,
      }
    }

    if (cidade) {
      result.url_cidade = {
        label: cidade.nome,
        value: cidade.url,
      }
    }

    if (uf) {
      result.url_uf = {
        label: uf.sigla,
        value: uf.url,
      }
    }

    if (documento) {
      result.documento = formatCPFCNPJ(documento)
    }

    if (livro && pagina) {
      result.livro_pagina = livro && pagina ? `${livro}/${pagina}` : livro || pagina || '-'
      result.livro = livro
      result.pagina = pagina
    }

    Object.entries(fields).forEach(([apiName, value]) => {
      const config = getFieldConfigByAPIName(apiName)

      if (config) {
        const { kind } = config

        if (kind === 'date') {
          value = displayDate(value as string)
        } else if (kind === 'cpfOrCnpj') {
          value = formatCPFCNPJ(value as string)
        }

        result[apiName as FieldName] = value
      }
    })

    return result
  }

  protected _convertResults = (
    orderItem: OrderItem,
    results: ResearchResultAPI[] | null
  ): Partial<Record<ResearchResultsFieldName, any>>[] => {
    if (!results || !results.length) {
      return []
    }

    return results.map((result, index) => {
      return this._convertResult(orderItem, result, index)
    })
  }

  convertResults = (orderItem: OrderItem, results: ResearchResultAPI[] | null) => {
    return this._convertResults(orderItem, results)
  }
}

export default Research
