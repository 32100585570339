import React from 'react'
import { InputProps } from 'antd/lib/input'

import MaskedInput from './MaskedInput'

interface Props extends Omit<InputProps, 'defaultValue' | 'value'> {
  isAmex: boolean
  value?: string
}

function CreditCardCCVInput({
  name = 'cardCVV',
  required = true,
  style,
  isAmex,
  ...props
}: Props) {
  return (
    <MaskedInput
      mask={mask}
      placeholder={isAmex ? '0000' : '000'}
      name={name}
      label="Código CVV"
      required={required}
      rules={requiredRule}
      style={style}
      maskOptions={{        
        dispatch: function (appended: any, dynamicMasked: any) {
          return dynamicMasked.compiledMasks[isAmex ? 0 : 1]
        },
      }}
      {...props}
    />
  )
}

const amexMask = '0000'
const othersMask = '000'

const mask = [
  {
    mask: amexMask,
    lazy: false,
    placeholderChar: ' ',
  },
  {
    mask: othersMask,
    lazy: false,
    placeholderChar: ' ',
  },
]

const requiredRule = [{ required: true, message: 'Campo obrigatório' }]

export default CreditCardCCVInput
