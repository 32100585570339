import KitSidebar from '../registerKit/KitSidebar'
import KitSidebarName from '../registerKit/KitSidebarName'
import KitLegalEntity from './CreateKitEntityType'
import { useCreateKit } from './CreateKitContext'
import SubmitButton from './CreateKitSubmitButton'

export default function CreateKitSidebar() {
  const { items, name } = useCreateKit()

  return (
    <KitSidebar
      header={
        <>
          <KitSidebarName kitName={name} totalItems={items.length} />
          <KitLegalEntity compact name="legalEntity" />
        </>
      }
      items={items}
    >
      <SubmitButton />
    </KitSidebar>
  )
}
