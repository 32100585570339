import React from 'react'

interface Props {
  columnsOnLargeScreens?: number;
  columnsOnMediumScreens?: number;
  children: React.ReactNode;
}

function GridColumns({ columnsOnLargeScreens = 2, columnsOnMediumScreens = 2, children }: Props) {
  const classForLargeScreens = `3xl:grid-cols-${columnsOnLargeScreens}`
  const classForMediumScreens = `lg:grid-cols-${columnsOnMediumScreens}`

  return (
    <div className={`grid grid-cols-1 ${classForMediumScreens} ${classForLargeScreens} gap-4`}>
      {children}
    </div>
  )
}

export default GridColumns
