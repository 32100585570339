import { KitFieldConfig } from 'domain/createOrder/kit/types'
import { Fields } from 'domain/servicesCBRdoc/config'
import {
  AddressFields,
  CNPJAndNameFields,
  FederativeUnitsField,
  LicensePlateField,
  MaskedField,
  NameField,
  RadioGroupField,
  SelectField,
  TextField,
} from 'domain/servicesCBRdoc/fields'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import {
  CNPJInput,
  CompanyNameInput,
  CPFInput,
  DateInput,
  EmailInput,
  NumberInput,
  PhoneInput,
  TextAreaInput,
  ZipCodeInput,
} from 'ui'
import { useSetCommonField } from '../../state'
import KitDontKnowBookPage from './KitDontKnowBookPage'

interface Props {
  autoFocus?: boolean
  config: KitFieldConfig
}

export default function GenericPersonalFields({ autoFocus, config }: Props) {
  const setField = useSetCommonField()
  const { kind, label, legalEntity, mask, name, optional } = config
  const fieldName = ['registree', name]

  if (kind === 'cpfOrCnpjAndName') {
    return (
      <CNPJAndNameFields
        label={label}
        companyField={Fields.nome_pj}
        autoFocus={autoFocus}
        onCompanyNameChange={(name, value) => {
          setField(name as any, value)
        }}
        itemId="registree"
      />
    )
  }

  if (kind === 'cpfOrCnpj') {
    if (legalEntity === 'juridica') {
      return <CNPJInput label={label} itemId="registree" autoFocus={autoFocus} />
    }

    return <CPFInput label={label} itemId="registree" autoFocus={autoFocus} />
  }

  if (kind === 'licensePlate') {
    return <LicensePlateField field={config} itemId="registree" />
  }

  if (kind === 'name') {
    if (name === FieldName.NOME_PJ) {
      return <CompanyNameInput label={label} name={fieldName} autoFocus={autoFocus} />
    }

    return <NameField name={name} itemId="registree" field={config} required={optional !== true} />
  }

  if (mask) {
    return <MaskedField field={config} itemId="registree" required={optional !== true} />
  }

  if (kind === 'text') {
    return <TextField field={config} itemId="registree" required={optional !== true} />
  }

  if (kind === 'date') {
    return <DateInput label={label} name={fieldName} required={!optional} />
  }

  if (kind === 'select' && !config.apiExtraInfo) {
    return <SelectField field={config} itemId="registree" required={optional !== true} />
  }

  if (kind === 'radio') {
    return <RadioGroupField field={config} itemId="registree" />
  }

  if (kind === 'multiline') {
    return <TextAreaInput label={label} name={fieldName} required={optional !== true} />
  }

  if (kind === 'number') {
    return (
      <NumberInput
        label={label}
        name={fieldName}
        required={!config.optional}
        maxLength={config.maxLength}
        rules={config.validationRules}
      />
    )
  }

  if (kind === 'uf') {
    return <FederativeUnitsField field={config} itemId="registree" labelInValue={false} />
  }

  if (kind === 'fullAddress') {
    return <AddressFields itemId="registree" autoFocus={autoFocus} />
  }

  if (kind === 'zipCode') {
    return <ZipCodeInput name={fieldName} label={label} required={optional !== true} />
  }

  if (kind === 'dontKnowBookPage') {
    return <KitDontKnowBookPage itemId="registree" />
  }

  if (kind === 'phone') {
    return <PhoneInput name={fieldName} required={optional !== true} autoFocus={autoFocus} />
  }

  if (kind === 'email') {
    return (
      <EmailInput itemId="registree" name={fieldName} autoFocus={autoFocus} required={optional !== true} />
    )
  }

  return null
}
