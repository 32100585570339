import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { NOME_PF, NOME_PJ, TIPO_PESSOA, URL_UF } = FieldName

type CertidaoMPECriminalFields = Record<
  FieldName.URL_UF | FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ,
  FieldConfig
>

class CertidaoMPECriminal extends Certificate {
  formFields: CertidaoMPECriminalFields = {
    [URL_UF]: Fields[URL_UF],
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    [NOME_PF]: Fields[NOME_PF],
    [NOME_PJ]: Fields[NOME_PJ],
  }

  constructor() {
    super({
      id: 56,
      availableFormatsFields: [URL_UF],
      priceByFields: [TIPO_PESSOA, URL_UF],
      shortName: 'MPE Criminal',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoMPECriminal().initialize()

export default service
