import React from 'react'

import Icon, { IconName } from 'ui/Icon/Icon'

interface Props {
  icon: IconName | undefined
  text: string
  bold?: boolean
}

export default function IconText({ icon, text, bold = true }: Props) {
  return (
    <div className="flex flex-row items-center text-secondary-500">
      <Icon name={icon} className="text-xl mr-3" />

      <div className={`flex-1 leading-snug ${bold ? 'font-bold' : ''}`}>{text}</div>
    </div>
  )
}
