import { PROD } from 'api/network/config'
import { SecretAPI } from 'api/network/v2'
import { RDS } from 'api/rdstation'
import { onlyNumbers } from 'utils/formatters'

import { CreateAccountAPI, CreateAccountForm } from './types'

export async function createCustomerAPI(
  cnpj: string,
  { corporate_name, email, question_services, name, password, phone }: CreateAccountForm
) {
  const customerAccount: CreateAccountAPI = {
    customer: {
      corporate_name,
      document_number: onlyNumbers(cnpj),
      entity_type: 'PJ',
    },
    user: {
      email: email.trim().toLowerCase(),
      password: String(password),
      phone: onlyNumbers(phone),
      name,
    },
    quiz: {
      question_services: question_services,
    },
  }

  await SecretAPI.post('/accounts', customerAccount)

  RDS.conversionEvent({
    event_type: 'CONVERSION',
    event_family: 'CDP',
    payload: {
      conversion_identifier: 'Cadastro - PJ',
      name,
      email,
      company_name: corporate_name,
      mobile_phone: phone,
      tags: ['cadastro_feito', customerAccount.quiz.question_services],
    },
  })
}

const checkDocumentAlreadyUsedAPI = async (document: string | number) => {
  const response = await SecretAPI.get(`/customers/document-number-already-used/${document}`)
  return response.data
}

const checkAtReceitaFederal = async (document: string | number) => {
  let isValid = true

  try {
    const response = await SecretAPI.get(`/accounts/cnpj/${document}/valid`)
    isValid = response.data.valid !== false
  } catch {
    // deve ignorar erros
  }

  if (!isValid) {
    return Promise.reject('Este CNPJ está inativo na Receita Federal.')
  }

  return Promise.resolve()
}

export const checkCNPJAPI = async (cnpj: string) => {
  const promises = [checkDocumentAlreadyUsedAPI(cnpj)]

  if (PROD) {
    promises.push(checkAtReceitaFederal(cnpj))
  }

  const responses = await Promise.allSettled(promises)
  const error: any = responses.find(res => res.status === 'rejected')

  if (error) {
    return Promise.reject(error.reason)
  }

  return Promise.resolve()
}
