import { UploadFile } from 'antd'
import { useState } from 'react'

import AlertSpreadsheetUnvailable from './AlertSpreadsheetUnvailable'
import SpreadsheetFile from './KitSpreadsheetFile'
import IdentifyColumns from './KitSpreadsheetIdentifyCols'
import AddKitToCart from './KitSpreadsheetAddToCart'

export default function KitSpreadsheet() {
  const [file, setFile] = useState<UploadFile | undefined>(undefined)
  const [rows, setRows] = useState<any[]>([])

  return (
    <>
      <AlertSpreadsheetUnvailable />
      <SpreadsheetFile file={file} setFile={setFile} setRows={setRows} />
      
      {!!file && (
        <>
          <IdentifyColumns rows={rows} />
          <AddKitToCart file={file} />
        </>
      )}      
    </>
  )
}
