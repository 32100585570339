import { atom, useAtom, useSetAtom, useAtomValue } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { SortOrder } from 'domain/app/types'
import { OrderSortByField } from 'domain/orders/types'
import { getSortBy } from 'utils/transformData'
import { atomURI } from 'utils/navigation'

// Pedidos
const currentPage = atom<number | undefined>(undefined)
export const currentPageURI = atomURI(currentPage, 'p')
const pageSize = atomWithStorage('orders-page-size', 10)
const sortField = atomWithStorage<string | string[]>('orders-sort-field2', 'placed_at')
const sortDirection = atomWithStorage<SortOrder>('orders-sort-order', 'descend')

const sortBy = atom(get => {
  let field = get(sortField)

  if (field === 'orderDocument') {
    field = 'service.name'
  }

  return getSortBy(`${field},-id`, get(sortDirection))
})

const totalPages = atom(0)
const totalResults = atom(0)

export const useCurrentPage = () => useAtom(currentPage)
export const usePageSize = () => useAtom(pageSize)
export const useTotalPages = () => useAtom(totalPages)

export const useGetCurrentPage = () => useAtomValue(currentPage)
export const useGetPageSize = () => useAtomValue(pageSize)
export const useGetSortBy = () => useAtomValue(sortBy)
export const useGetTotalResults = () => useAtomValue(totalResults)

export const useSetCurrentPage = () => useSetAtom(currentPageURI)
export const useSetSortDirection = () => useSetAtom(sortDirection)
export const useSetSortField = () => useSetAtom(sortField)
export const useSetTotalPages = () => useSetAtom(totalPages)
export const useSetTotalResults = () => useSetAtom(totalResults)

// Todos os itens de pedidos
const itemsCurrentPage = atom<number | undefined>(undefined)
export const itemsCurrentPageURI = atomURI(itemsCurrentPage, 'p')
const itemsPageSize = atomWithStorage('items-page-size', 10)
const itemsSortField = atomWithStorage<OrderSortByField>('items-sort-field2', 'placed_at')
const itemsSortDirection = atomWithStorage<SortOrder>('items-sort-direction', 'descend')
const itemsTotalPages = atom(0)
const itemsTotalResults = atom(0)

const itemsSortBy = atom(get => {
  return getSortBy(`${get(itemsSortField)},-id`, get(itemsSortDirection))
})

export const useItemsCurrentPage = () => useAtom(itemsCurrentPageURI)
export const useItemsPageSize = () => useAtom(itemsPageSize)

export const useGetItemsCurrentPage = () => useAtomValue(itemsCurrentPage)
export const useGetItemsPageSize = () => useAtomValue(itemsPageSize)
export const useGetItemsSortBy = () => useAtomValue(itemsSortBy)
export const useGetItemsTotalPages = () => useAtomValue(itemsTotalPages)
export const useGetItemsTotalResults = () => useAtomValue(itemsTotalResults)

export const useSetItemsCurrentPage = () => useSetAtom(itemsCurrentPageURI)
export const useSetItemsTotalPages = () => useSetAtom(itemsTotalPages)
export const useSetItemsTotalResults = () => useSetAtom(itemsTotalResults)

// Itens de um pedido (Overview)
const overviewCurrentPage = atom<number | undefined>(undefined)
export const overviewCurrentPageURI = atomURI(overviewCurrentPage, 'p')
const overviewPageSize = atom(10)
const overviewSortField = atomWithStorage<OrderSortByField>('overview-sort-field', 'placed_at')
const overviewSortDirection = atomWithStorage<SortOrder>('overview-sort-direction', 'descend')

const overviewSortBy = atom(get => {
  return getSortBy(`${get(overviewSortField)},id`, get(overviewSortDirection))
})

const overviewTotalPages = atom(0)
const overviewTotalResults = atom(0)

export const useOverviewCurrentPage = () => useAtom(overviewCurrentPageURI)
export const useOverviewPageSize = () => useAtom(overviewPageSize)
export const useOverviewSortBy = () => useAtom(overviewSortBy)
export const useOverviewTotalPages = () => useAtom(overviewTotalPages)
export const useOverviewTotalResults = () => useAtom(overviewTotalResults)

export const useGetOverviewCurrentPage = () => useAtomValue(overviewCurrentPage)
export const useGetOverviewPageSize = () => useAtomValue(overviewPageSize)
export const useGetOverviewSortBy = () => useAtomValue(overviewSortBy)
export const useGetOverviewTotalResults = () => useAtomValue(overviewTotalResults)

export const useSetOverviewCurrentPage = () => useSetAtom(overviewCurrentPageURI)
export const useSetOverviewTotalPages = () => useSetAtom(overviewTotalPages)
export const useSetOverviewTotalResults = () => useSetAtom(overviewTotalResults)
