import { PROCESS_TYPES } from 'domain/createOrder/dueDiligence/data'
import { FieldConfig, FieldName } from '../fields/types'

type FieldConfigsType = {
  [key in FieldName]: FieldConfig
}

export const Fields: FieldConfigsType = {
  [FieldName.AIIM]: {
    name: FieldName.AIIM,
    kind: 'text',
    apiName: 'inscricao',
    apiRemoveMask: true,
    label: 'AIIM',
  },
  [FieldName.ARQUIVOS]: {
    name: FieldName.ARQUIVOS,
    kind: 'upload',
    apiName: FieldName.ARQUIVOS,
    label: 'Anexar arquivos',
  },
  [FieldName.ANO_APROX_ATO]: {
    name: FieldName.ANO_APROX_ATO,
    kind: 'year',
    apiName: 'ano_aproximado_ato',
    label: 'Ano aproximado do ato',
  },
  [FieldName.ANO_BASE]: {
    name: FieldName.ANO_BASE,
    kind: 'year',
    apiName: 'ano_base',
    label: 'Ano base',
  },
  [FieldName.ARQUIVO_NOME_ORIGINAL]: {
    name: FieldName.ARQUIVO_NOME_ORIGINAL,
    kind: 'text',
    apiName: 'arquivo_nome_original',
    label: 'Nome do Arquivo(s)',
  },
  [FieldName.ASSINANTES]: {
    name: FieldName.ASSINANTES,
    kind: 'text',
    apiName: 'assinantes',
    label: 'Assinantes',
    ignoreOnKitsDetailsForm: true,
    ignoreOnKitsAutomaticForm: true,
  },
  [FieldName.BUSCA_POR]: {
    name: FieldName.BUSCA_POR,
    kind: 'text',
    apiName: 'buscar_por',
    label: 'Buscar por',
    dataScope: 'service',
  },
  [FieldName.CAR]: {
    name: FieldName.CAR,
    apiName: 'car',
    kind: 'text',
    dataScope: 'registree',
    label: 'Cadastro Ambiental Rural (CAR)',
    apiRemoveMask: true,
    placeholder: 'UF-AAAAAAA-AAAA.AAAA.AAAA.AAAA.AAAA.AAAA.AAAA.AAAA',
    mask: [
      {
        mask: 'AA-xxxxxxx.xxxx.xxxx.xxxx.xxxx.xxxx.xxxx.xxxx.xxxx',
        prepare: (str: string) => str.toUpperCase(),
        definitions: {
          A: /[a-z]/i,
          x: /[a-z|0-9]/i,
        },
      },
    ],
  },
  [FieldName.CAEPF]: {
    name: FieldName.CAEPF,
    apiName: 'caepf',
    kind: 'number',
    label: 'CAEPF',
    mask: '000.000.000/000-00',
  },
  [FieldName.CEI]: {
    name: FieldName.CEI,
    apiName: 'cei',
    kind: 'text',
    label: 'CEI',
    optional: true,
    maxLength: 13,
    dataScope: 'registree',
  },
  // CEP pode ser parte de um formulário de endereço completo, ou pode aparecer como campo único
  [FieldName.CEP]: {
    name: FieldName.CEP,
    apiName: 'cep',
    apiRemoveMask: true,
    label: 'CEP',
    kind: 'zipCode',
    mask: '00.000-000',
  },
  [FieldName.CIDADE_NASCIMENTO_IBGE]: {
    name: FieldName.CIDADE_NASCIMENTO_IBGE,
    kind: 'ibgeCity',
    apiName: 'cidade_nascimento',
    label: 'Cidade de nascimento',
    dataScope: 'registree',
  },
  [FieldName.CIB]: {
    name: FieldName.CIB,
    kind: 'text',
    apiName: 'cib',
    label: 'CIB',
    dataScope: 'registree',
    maxLength: 8,
    validationRules: [
      {
        required: true,
        message: 'Campo obrigatório',
      },
      {
        len: 8,
        transform: (value: string) => value.trim(),
      },
    ],
  },
  [FieldName.CNPJ]: {
    name: FieldName.CNPJ,
    apiName: 'cnpj',
    apiRemoveMask: true,
    kind: 'cpfOrCnpj',
    label: 'CNPJ',
    legalEntity: 'juridica',
    mask: '00.000.000/0000-00',
    dataScope: 'registree',
  },
  [FieldName.CODIGO_IMOVEL_RURAL]: {
    name: FieldName.CODIGO_IMOVEL_RURAL,
    kind: 'text',
    apiName: 'codigo_imovel',
    label: 'Código do Imóvel Rural',
    mask: '000.000.000.000-0',
    dataScope: 'registree',
    apiRemoveMask: true,
  },
  [FieldName.COMARCA]: {
    name: FieldName.COMARCA,
    kind: 'select',
    apiName: 'comarca',
    apiExtraInfo: 'comarcas',
    apiOptionsLabelKey: 'nome',
    apiOptionsValueKey: 'url',
    label: 'Comarca',
    placeholder: 'Selecione a Comarca',
    stateKey: 'selectedComarca',
  },
  [FieldName.CONDOMINIO]: {
    name: FieldName.CONDOMINIO,
    kind: 'text',
    apiName: 'condominio',
    label: 'Condomínio',
    maxLength: 100,
  },
  [FieldName.CONJUGE1]: {
    name: FieldName.CONJUGE1,
    apiName: 'conjuge1',
    label: 'Nome do Cônjuge 1 na certidão',
    kind: 'name',
    maxLength: 200,
    legalEntity: 'fisica',
    kitFieldEquivalent: FieldName.NOME_PF,
    dataScope: 'registree',
  },
  [FieldName.CONJUGE1_CPF]: {
    name: FieldName.CONJUGE1_CPF,
    kind: 'cpfOrCnpj',
    apiName: 'conjuge1_cpf',
    label: 'CPF Cônjuge 1',
    kitFieldEquivalent: FieldName.CPF,
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.CONJUGE2]: {
    name: FieldName.CONJUGE2,
    apiName: 'conjuge2',
    label: 'Nome do Cônjuge 2 na certidão',
    kind: 'name',
    maxLength: 200,
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.CONJUGE2_CPF]: {
    name: FieldName.CONJUGE2_CPF,
    kind: 'cpfOrCnpj',
    apiName: 'conjuge2_cpf',
    label: 'CPF Cônjuge 2',
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.COMPLEMENTO]: {
    name: FieldName.COMPLEMENTO,
    kind: 'text',
    apiName: 'complemento',
    label: 'Complemento',
    maxLength: 250,
    optional: true,
  },
  [FieldName.CPF]: {
    name: FieldName.CPF,
    apiName: 'cpf',
    apiRemoveMask: true,
    kind: 'cpfOrCnpj',
    label: 'CPF',
    legalEntity: 'fisica',
    mask: '000.000.000-00',
    dataScope: 'registree',
  },
  [FieldName.CREA]: {
    name: FieldName.CREA,
    apiName: 'crea',
    apiRemoveMask: true,
    kind: 'number',
    label: 'CREA',
    maxLength: 12,
    dataScope: 'registree',
  },
  [FieldName.DATA_ATO]: {
    name: FieldName.DATA_ATO,
    apiName: 'data_ato',
    label: 'Data do ato',
    kind: 'date',
    optional: true,
    dataScope: 'registree',
  },
  [FieldName.DATA_BASE]: {
    name: FieldName.DATA_BASE,
    apiName: 'data_base',
    kind: 'date',
    label: 'Data base',
  },
  [FieldName.DATA_CASAMENTO]: {
    name: FieldName.DATA_CASAMENTO,
    apiName: 'casamento',
    label: 'Data do casamento',
    kind: 'date',
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.DATA_EMISSAO]: {
    name: FieldName.DATA_EMISSAO,
    kind: 'date',
    apiName: 'data_emissao',
    label: 'Data de emissão',
  },
  [FieldName.DATA_NASCIMENTO]: {
    name: FieldName.DATA_NASCIMENTO,
    apiName: 'nascimento',
    label: 'Data de nascimento',
    kind: 'date',
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.DATA_OBITO]: {
    name: FieldName.DATA_OBITO,
    apiName: 'obito',
    label: 'Data do óbito',
    kind: 'date',
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.DATA_REGISTRO]: {
    name: FieldName.DATA_REGISTRO,
    apiName: 'data_registro',
    label: 'Data do registro',
    kind: 'date',
    optional: true,
  },
  [FieldName.DATA_TITULO]: {
    name: FieldName.DATA_TITULO,
    apiName: 'data_titulo',
    label: 'Data do título',
    kind: 'date',
  },
  [FieldName.DESCRITIVO_DOCUMENTO]: {
    name: FieldName.DESCRITIVO_DOCUMENTO,
    kind: 'multiline',
    apiName: 'descritivo_documento',
    label: 'Descritivo sobre o documento',
    optional: true,
  },
  [FieldName.DOCUMENTO]: {
    name: FieldName.DOCUMENTO,
    apiName: 'documento',
    kind: 'number',
    label: 'Documento',
    maxLength: 10,
  },
  [FieldName.EMAIL]: {
    name: FieldName.EMAIL,
    apiName: 'email',
    label: 'E-mail',
    kind: 'email',
    maxLength: 200,
    dataScope: 'registree',
  },
  [FieldName.ENDERECO_IMOVEL]: {
    name: FieldName.ENDERECO_IMOVEL,
    apiName: 'endereco',
    label: 'Endereço do imóvel',
    kind: 'fullAddress',
  },
  // Campos de endereço no formulário do front
  // Usado em kit
  [FieldName.ENDERECO_LOGRADOURO]: {
    name: FieldName.ENDERECO_LOGRADOURO,
    apiName: 'endereco_logradouro',
    label: 'Endereço',
    kind: 'text',
  },
  [FieldName.ENDERECO_NUMERO]: {
    name: FieldName.ENDERECO_NUMERO,
    apiName: 'endereco_numero',
    label: 'Número',
    kind: 'text',
  },
  [FieldName.ENDERECO_COMPLEMENTO]: {
    name: FieldName.ENDERECO_COMPLEMENTO,
    apiName: 'endereco_complemento',
    label: 'Complemento',
    kind: 'text',
    optional: true,
  },
  [FieldName.ENDERECO_BAIRRO]: {
    name: FieldName.ENDERECO_BAIRRO,
    apiName: 'endereco_bairro',
    label: 'Bairro',
    kind: 'text',
  },
  [FieldName.ENDERECO_CIDADE]: {
    name: FieldName.ENDERECO_CIDADE,
    apiName: 'endereco_cidade',
    label: 'Cidade',
    kind: 'text',
  },
  [FieldName.ENDERECO_ESTADO]: {
    name: FieldName.ENDERECO_ESTADO,
    apiName: 'endereco_estado',
    label: 'Estado',
    kind: 'text',
  },
  [FieldName.ESTADO_CIVIL]: {
    name: FieldName.ESTADO_CIVIL,
    kind: 'select',
    apiExtraInfo: 'estados-civis',
    apiName: 'estado_civil',
    label: 'Estado Civil',
    placeholder: 'Selecione o Estado Civil',
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.FILTRO]: {
    name: FieldName.FILTRO,
    kind: 'select',
    apiExtraInfo: 'filtros',
    apiName: 'filtrar_por',
    apiOptionsLabelKey: 'nome',
    apiOptionsValueKey: 'url',
    label: 'Selecione o filtro',
    dataScope: 'service',
  },
  [FieldName.FORMATO]: {
    name: FieldName.FORMATO,
    apiName: 'formato',
    kind: 'text',
    label: 'Formato',
    dataScope: 'service',
  },
  [FieldName.GENERO]: {
    name: FieldName.GENERO,
    apiExtraInfo: 'generos',
    apiName: 'genero',
    label: 'Gênero',
    placeholder: 'Selecione o gênero',
    kind: 'radio',
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.GRUPO]: {
    name: FieldName.GRUPO,
    apiExtraInfo: 'grupos',
    apiName: 'grupo',
    apiOptionsLabelKey: 'nome',
    apiOptionsValueKey: 'code',
    kind: 'select',
    label: 'Grupo',
    dataScope: 'service',
  },
  [FieldName.INSCRICAO]: {
    name: FieldName.INSCRICAO,
    kind: 'text',
    apiName: 'inscricao',
    apiRemoveMask: true,
    label: 'Inscrição',
  },
  [FieldName.CDA]: {
    name: FieldName.CDA,
    kind: 'text',
    apiName: 'inscricao',
    apiRemoveMask: true,
    label: 'CDA',
  },
  [FieldName.ETIQUETA]: {
    name: FieldName.ETIQUETA,
    kind: 'text',
    apiName: 'inscricao',
    apiRemoveMask: true,
    label: 'Etiqueta',
  },
  [FieldName.RENAVAM]: {
    name: FieldName.RENAVAM,
    kind: 'text',
    apiName: 'inscricao',
    apiRemoveMask: true,
    label: 'Renavam',
  },
  [FieldName.INSCRICAO_ESTADUAL]: {
    name: FieldName.INSCRICAO_ESTADUAL,
    kind: 'text',
    apiName: 'inscricao_estadual',
    label: 'Inscrição Estadual',
    legalEntity: 'juridica',
    maxLength: 255,
  },
  [FieldName.INSCRICAO_IMOVEL]: {
    name: FieldName.INSCRICAO_IMOVEL,
    kind: 'text',
    apiName: 'inscricao_imovel',
    label: 'Inscrição do imóvel',
    dataScope: 'registree',
    minLength: 10,
    maxLength: 20,
  },
  [FieldName.INSCRICAO_MERCANTIL]: {
    name: FieldName.INSCRICAO_MERCANTIL,
    kind: 'number',
    apiName: 'inscricao_mercantil',
    label: 'Inscrição mercantil',
    maxLength: 7,
  },
  [FieldName.INSCRICAO_MUNICIPAL]: {
    name: FieldName.INSCRICAO_MUNICIPAL,
    kind: 'text',
    apiName: 'inscricao_municipal',
    label: 'Inscrição municipal',
  },
  [FieldName.INSCRICAO_CADASTRAL]: {
    name: FieldName.INSCRICAO_CADASTRAL,
    kind: 'text',
    apiName: 'inscricao_cadastral',
    label: 'Inscrição Cadastral',
  },
  [FieldName.INSTANCIA]: {
    name: FieldName.INSTANCIA,
    kind: 'radio',
    apiName: 'instancia',
    apiExtraInfo: 'instancias',
    apiOptionsLabelKey: 'nome',
    apiOptionsValueKey: 'url',
    label: 'Instância',
    stateKey: 'selectedInstance',
    dataScope: 'service',
  },
  [FieldName.IPTU]: {
    name: FieldName.IPTU,
    apiName: 'iptu',
    label: 'IPTU',
    kind: 'number',
    maxLength: 10,
  },
  [FieldName.LIVRO]: {
    name: FieldName.LIVRO,
    apiName: 'livro',
    label: 'Número do livro',
    optional: true,
    kind: 'text',
    maxLength: 5,
    placeholder: 'Livro',
  },
  [FieldName.LOCAL_SERVICO]: {
    name: FieldName.LOCAL_SERVICO,
    kind: 'text',
    apiName: 'local_servico',
    label: 'Localização',
    dataScope: 'service',
  },
  [FieldName.MATRICULA]: {
    name: FieldName.MATRICULA,
    kind: 'splittable',
    apiName: 'matricula',
    label: 'Matrícula',
    placeholder: '000.000.0000-0',
    minLength: 10,
    maxLength: 20,
  },
  [FieldName.MENSAGEM]: {
    name: FieldName.MENSAGEM,
    kind: 'multiline',
    apiName: 'mensagem',
    label: 'Mensagem',
    maxLength: 500,
  },
  [FieldName.MODELO]: {
    name: FieldName.MODELO,
    kind: 'select',
    apiName: 'modelo',
    apiExtraInfo: 'modelos',
    apiOptionsLabelKey: 'nome',
    apiOptionsValueKey: 'url',
    label: 'Modelo',
    placeholder: 'Selecione o modelo',
    stateKey: 'selectedModel',
    dataScope: 'service',
  },
  [FieldName.MODELO_IA]: {
    name: FieldName.MODELO_IA,
    kind: 'select',
    apiExtraInfo: 'modelos',
    apiName: 'modelo_ia_id',
    apiOptionsLabelKey: 'nome',
    apiOptionsValueKey: 'url',
    label: 'Modelo',
    placeholder: 'Selecione o modelo',
    stateKey: 'selectedModel',
    dataScope: 'service',
  },
  [FieldName.MOTIVO_SOLICITACAO]: {
    name: FieldName.MOTIVO_SOLICITACAO,
    kind: 'multiline',
    apiName: FieldName.MOTIVO_SOLICITACAO,
    label: 'Motivo da Solicitação',
  },
  [FieldName.NACIONALIDADE]: {
    name: FieldName.NACIONALIDADE,
    kind: 'select',
    apiExtraInfo: 'nacionalidades',
    apiName: 'nacionalidade',
    label: 'Nacionalidade',
    legalEntity: 'fisica',
    dataScope: 'registree',
    valueAsURL: true,
  },
  [FieldName.NAO_SEI_LIVRO_PAGINA]: {
    name: FieldName.NAO_SEI_LIVRO_PAGINA,
    kind: 'dontKnowBookPage',
    apiName: 'nao_sei_livro_pagina',
    label: 'Não sei o livro e a página',
    stateKey: 'dontKnowBookPage',
    defaultValue: false,
    dataScope: 'registree',
  },
  [FieldName.NATURALIDADE]: {
    name: FieldName.NATURALIDADE,
    apiName: 'naturalidade',
    apiExtraInfo: 'naturalidades',
    label: 'Naturalidade',
    kind: 'select',
    placeholder: 'Selecione a naturalidade',
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.NATUREZA]: {
    name: FieldName.NATUREZA,
    kind: 'select',
    apiName: 'natureza',
    apiExtraInfo: 'naturezas',
    apiOptionsLabelKey: 'nome',
    apiOptionsValueKey: 'url',
    label: 'Natureza',
    placeholder: 'Selecione a Natureza',
  },
  [FieldName.NATUREZA_JURIDICA]: {
    name: FieldName.NATUREZA_JURIDICA,
    kind: 'select',
    legalEntity: 'juridica',
    apiName: 'natureza_juridica',
    label: 'Natureza Jurídica',
    apiOptionsLabelKey: 'nome',
    apiOptionsValueKey: 'url',
    apiExtraInfo: 'naturezas-juridicas',
  },
  [FieldName.NIRF]: {
    name: FieldName.NIRF,
    kind: 'text',
    apiName: 'nirf',
    label: 'NIRF',
    dataScope: 'registree',
    maxLength: 8,
    validationRules: [
      {
        required: true,
        message: 'Campo obrigatório',
      },
      {
        len: 8,
        transform: (value: string) => value.trim(),
      },
    ],
  },
  [FieldName.NOME]: {
    name: FieldName.NOME,
    kind: 'name',
    apiName: 'nome',
    label: 'Nome da Pessoa ou Empresa',
    minLength: 4,
    maxLength: 200,
  },
  [FieldName.NOME_PF]: {
    name: FieldName.NOME_PF,
    kind: 'name',
    apiName: 'nome',
    label: 'Nome Completo',
    minLength: 4,
    maxLength: 200,
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.NOME_PJ]: {
    name: FieldName.NOME_PJ,
    kind: 'name',
    apiName: 'nome',
    label: 'Nome da Empresa',
    maxLength: 200,
    minLength: 4,
    legalEntity: 'juridica',
    dataScope: 'registree',
  },
  [FieldName.NOME_ITEM_PEDIDO]: {
    name: FieldName.NOME_ITEM_PEDIDO,
    apiName: 'name',
    label: 'Nome do Pedido',
    kind: 'text',
    maxLength: 120,
  },
  [FieldName.NOME_MAE]: {
    name: FieldName.NOME_MAE,
    apiName: 'mae',
    label: 'Nome da mãe',
    kind: 'name',
    maxLength: 200,
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.NOME_PAI]: {
    name: FieldName.NOME_PAI,
    apiName: 'pai',
    label: 'Nome do pai',
    optional: true,
    kind: 'name',
    maxLength: 200,
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.NOME_PROPRIEDADE]: {
    name: FieldName.NOME_PROPRIEDADE,
    apiName: 'nome_propriedade',
    label: 'Nome da propriedade',
    kind: 'text',
    optional: true,
    maxLength: 200,
  },
  [FieldName.NUMERO_ATO]: {
    name: FieldName.NUMERO_ATO,
    apiName: 'numero_ato',
    label: 'Número do ato desejado',
    kind: 'text',
    maxLength: 200,
  },
  [FieldName.NUMERO_PROCESSO]: {
    name: FieldName.NUMERO_PROCESSO,
    kind: 'text',
    apiName: 'numero_processo',
    label: 'Número do Processo',
    optional: true,
  },
  [FieldName.NUMERO_REGISTRO]: {
    name: FieldName.NUMERO_REGISTRO,
    kind: 'text',
    apiName: 'numero_registro',
    label: 'Número do Registro',
    maxLength: 40,
  },
  [FieldName.NUMERO_RIP]: {
    name: FieldName.NUMERO_RIP,
    kind: 'text',
    apiName: 'numero_rip',
    label: 'Número do RIP',
  },
  [FieldName.NUMERO_TITULO]: {
    name: FieldName.NUMERO_TITULO,
    kind: 'text',
    apiName: 'numero_titulo',
    label: 'Número do Título',
  },
  [FieldName.OBSERVACAO]: {
    name: FieldName.OBSERVACAO,
    kind: 'multiline',
    apiName: 'observacoes',
    label: 'Observações',
    optional: true,
  },
  [FieldName.ORGAO]: {
    name: FieldName.ORGAO,
    kind: 'select',
    apiExtraInfo: 'orgaos',
    apiName: 'orgao',
    label: 'Selecione o órgão',
    infoLabel: 'Órgão',
    dataScope: 'service',
  },
  [FieldName.ORGAO_EMISSOR]: {
    name: FieldName.ORGAO_EMISSOR,
    apiName: 'orgao_emissor',
    label: 'Órgão emissor',
    kind: 'text',
    maxLength: 20,
    dataScope: 'service',
  },
  [FieldName.PAGINA]: {
    name: FieldName.PAGINA,
    apiName: 'pagina',
    label: 'Número da página',
    optional: true,
    kind: 'text',
    maxLength: 3,
    placeholder: 'Página',
  },
  [FieldName.PLACA]: {
    name: FieldName.PLACA,
    kind: 'licensePlate',
    apiName: 'placa',
    label: 'Placa',
    apiRemoveMask: true,
    dataScope: 'registree',
  },
  [FieldName.PREFERENCIA]: {
    name: FieldName.PREFERENCIA,
    apiName: 'preferencia',
    label: 'Preferências',
    kind: 'radio',
    optional: true,
  },
  [FieldName.PROTOCOLO]: {
    name: FieldName.PROTOCOLO,
    apiName: 'protocolo',
    label: 'Protocolo',
    kind: 'text',
  },
  [FieldName.QUESITO]: {
    name: FieldName.QUESITO,
    apiName: 'quesito',
    label: 'Quesito',
    kind: 'text',
    maxLength: 200,
  },
  [FieldName.RAZAO_SOCIAL]: {
    name: FieldName.RAZAO_SOCIAL,
    kind: 'name',
    apiName: 'razao_social',
    label: 'Razão social',
    minLength: 4,
    maxLength: 200,
    legalEntity: 'juridica',
    dataScope: 'registree',
  },
  [FieldName.REGIAO]: {
    name: FieldName.REGIAO,
    kind: 'select',
    apiName: 'regiao',
    label: 'Região',
    dataScope: 'service',
  },
  [FieldName.REGISTRADO_ENDERECO_CEP]: {
    name: FieldName.REGISTRADO_ENDERECO_CEP,
    apiName: 'registrado_endereco_cep',
    label: 'CEP',
    kind: 'zipCode',
    mask: '00.000-000',
    dataScope: 'registree',
  },
  [FieldName.REGISTRADO_ENDERECO_COMPLETO]: {
    name: FieldName.REGISTRADO_ENDERECO_COMPLETO,
    apiName: 'registrado_endereco_completo',
    label: 'Endereço completo',
    kind: 'fullAddress',
    dataScope: 'registree',
  },
  [FieldName.REGISTRO]: {
    name: FieldName.REGISTRO,
    apiName: 'registro',
    label: 'Número de registro',
    kind: 'text',
    optional: true,
    maxLength: 40,
  },
  [FieldName.REGISTRO_LIVRO3]: {
    name: FieldName.REGISTRO_LIVRO3,
    kind: 'text',
    apiName: 'registro_livro3',
    label: 'Número de registro',
  },
  [FieldName.REPRESENTANTE_LEGAL]: {
    name: FieldName.REPRESENTANTE_LEGAL,
    apiName: 'representante_legal',
    label: 'Representante Legal',
    kind: 'text',
    maxLength: 200,
  },
  [FieldName.RG]: {
    name: FieldName.RG,
    apiName: 'rg',
    label: 'RG',
    kind: 'text',
    maxLength: 12,
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.RG_DATA_EXPEDICAO]: {
    name: FieldName.RG_DATA_EXPEDICAO,
    apiName: 'rg_data_expedicao',
    label: 'Data de expedição',
    kind: 'date',
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.RG_EXPEDIDOR]: {
    name: FieldName.RG_EXPEDIDOR,
    apiName: 'rg_expedidor',
    label: 'Órgão expedidor',
    kind: 'text',
    maxLength: 20,
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.RG_UF]: {
    name: FieldName.RG_UF,
    kind: 'uf',
    apiName: 'rg_uf_expedicao',
    label: 'Estado do RG',
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.SITUACAO]: {
    name: FieldName.SITUACAO,
    apiExtraInfo: 'situacoes',
    apiName: 'situacao',
    apiOptionsLabelKey: 'nome',
    apiOptionsValueKey: 'url',
    kind: 'radio',
    label: 'Situação',
    dataScope: 'service',
  },
  [FieldName.TELEFONE]: {
    name: FieldName.TELEFONE,
    kind: 'phone',
    apiName: FieldName.TELEFONE,
    label: 'Telefone',
    placeholder: 'Telefone',
    dataScope: 'registree',
    apiRemoveMask: true,
  },
  [FieldName.TEMPO_PESQUISA]: {
    name: FieldName.TEMPO_PESQUISA,
    kind: 'researchTime',
    apiExtraInfo: 'tempos-pesquisa',
    apiName: FieldName.TEMPO_PESQUISA,
    label: 'Tempo de pesquisa',
    stateKey: 'selectedResearchTime',
    dataScope: 'service',
  },
  [FieldName.TERMO]: {
    name: FieldName.TERMO,
    apiName: 'termo',
    label: 'Número do termo',
    optional: true,
    kind: 'text',
    maxLength: 7,
    placeholder: 'Digite o número',
  },
  [FieldName.TIPO]: {
    name: FieldName.TIPO,
    kind: 'select',
    apiName: FieldName.TIPO,
    label: 'Tipo',
    apiExtraInfo: 'tipos',
    apiOptionsLabelKey: 'nome',
    apiOptionsValueKey: 'url',
    optional: false,
    stateKey: 'selectedType',
    dataScope: 'service',
    vertical: true,
  },
  [FieldName.TIPO_CERTIDAO]: {
    name: FieldName.TIPO_CERTIDAO,
    apiName: 'tipo_certidao',
    apiExtraInfo: 'tipos-certidao',
    apiOptionsLabelKey: 'nome',
    apiOptionsValueKey: 'url',
    kind: 'radio',
    label: 'Tipo da certidão',
    optional: false,
    dataScope: 'service',
  },
  [FieldName.TIPO_DOCUMENTO]: {
    name: FieldName.TIPO_DOCUMENTO,
    label: 'Tipo do Documento',
    kind: 'radio',
    apiName: FieldName.TIPO_DOCUMENTO,
    apiExtraInfo: 'tipos-documento',
    // apiOptions: 'tipos',
    // apiOptionsLabelKey: 'nome',
    // apiOptionsValueKey: 'url',
    //optional: false,
    stateKey: 'selectedType',
    dataScope: 'service',
  },
  [FieldName.TIPO_SERVICO]: {
    name: FieldName.TIPO_SERVICO,
    kind: 'radio',
    apiName: 'tipo_servico',
    label: 'Tipo de serviço',
    labelDescription: 'Escolha a opção abaixo que melhor se adequa ao que você precisa',
    apiOptionsLabelKey: 'label',
    apiOptionsValueKey: 'value',
    options: [
      {
        value: 'ja-possuo-as-evidencias',
        label: 'Já possuo as evidências',
        description: `
          Tenho os arquivos das evidências e preciso apenas da lavratura da
          ata notarial, sem a necessidade de deslocamento do tabelião.`,
      },
      {
        value: 'diligencia-presencial-do-tabeliao',
        label: 'Diligência presencial do tabelião',
        description: `
          Preciso que o tabelião vá pessoalmente registrar os fatos no local e,
          posteriormente, faça a lavratura da ata notarial.`,
      },
    ],
    optional: false,
    stateKey: 'selectedModel',
    dataScope: 'service',
  },
  [FieldName.TIPO_FLUXO_ASSINATURAS]: {
    name: FieldName.TIPO_FLUXO_ASSINATURAS,
    kind: 'select',
    apiName: 'tipo_fluxo_assinaturas',
    label: 'Tipo de registro',
    apiOptionsLabelKey: 'label',
    apiOptionsValueKey: 'value',
    options: [
      {
        value: 'sem-assinaturas',
        label: 'Documento já está assinado',
        description: `
          Se o seu documento já possui todas as assinaturas necessárias 
          e está pronto para registro. (as assinaturas devem ter o padrão ICP-Brasil)`,
      },
      {
        value: 'com-assinaturas',
        label: 'Preciso coletar assinaturas para o documento',
        description: `
          Se você ainda precisa capturar as assinaturas e deseja cadastrar 
          os assinadores do documento antes de enviar para registro.`,
      },
    ],
    optional: false,
    stateKey: 'selectedModel',
    dataScope: 'service',
  },
  [FieldName.TIPO_PESSOA]: {
    name: FieldName.TIPO_PESSOA,
    apiName: 'tipo_pessoa',
    label: 'Tipo de Pessoa',
  },
  [FieldName.TIPO_PROCESSO]: {
    name: FieldName.TIPO_PROCESSO,
    kind: 'radio',
    apiName: 'tipo_processo',
    label: 'Tipo do Processo',
    optional: false,
    options: PROCESS_TYPES,
    apiOptionsLabelKey: 'label',
    apiOptionsValueKey: 'value',
    dataScope: 'service',
  },
  [FieldName.TIPO_SAFRA]: {
    name: FieldName.TIPO_SAFRA,
    apiName: 'tipo_safra',
    label: 'Tipo de safra',
    kind: 'text',
    optional: false,
    maxLength: 100,
  },
  [FieldName.TITULO_ELEITOR]: {
    name: FieldName.TITULO_ELEITOR,
    apiName: 'titulo_eleitor',
    label: 'Título de eleitor',
    optional: false,
    apiRemoveMask: true,
    kind: 'number',
    mask: '0000.0000.0000',
    legalEntity: 'fisica',
    dataScope: 'registree',
  },
  [FieldName.TODOS_CARTORIOS_CIDADE]: {
    name: FieldName.TODOS_CARTORIOS_CIDADE,
    apiName: 'todos_cartorios_cidade',
    label: 'Todos os cartórios',
    stateKey: 'allNotariesSelected',
    dataScope: 'service',
  },
  [FieldName.TRANSCRICAO]: {
    name: FieldName.TRANSCRICAO,
    apiName: 'transcricao',
    label: 'Transcrição',
    kind: 'text',
    maxLength: 18,
  },
  [FieldName.UF]: {
    name: FieldName.UF,
    kind: 'uf',
    apiName: 'uf',
    label: 'Estado',
  },
  [FieldName.UF_NASCIMENTO]: {
    name: FieldName.UF_NASCIMENTO,
    kind: 'uf',
    apiName: 'uf_nascimento',
    label: 'Estado do nascimento',
    dataScope: 'registree',
  },
  [FieldName.UPLOAD]: {
    name: FieldName.UPLOAD,
    kind: 'upload',
    apiName: 'arquivo',
    label: 'Arquivo(s)',
    uploadMultiple: false,
  },
  [FieldName.URL_CARTORIO]: {
    name: FieldName.URL_CARTORIO,
    kind: 'location',
    apiName: 'url_cartorio',
    apiExtraInfo: 'cartorios',
    apiOptionsLabelKey: 'simplificado',
    label: 'Cartório',
    labelInValue: true,
    dataScope: 'service',
  },
  [FieldName.URL_CIDADE]: {
    name: FieldName.URL_CIDADE,
    kind: 'location',
    apiName: 'url_cidade',
    label: 'Cidade',
    labelInValue: true,
    dataScope: 'service',
  },
  [FieldName.URL_UF]: {
    name: FieldName.URL_UF,
    kind: 'location',
    apiName: 'url_uf',
    label: 'Estado',
    labelInValue: true,
    dataScope: 'service',
  },
}

const fieldsValues = Object.values(Fields)

export const getFieldConfigByAPIName = (apiName: string) => {
  return fieldsValues.find(o => o.apiName === apiName)
}
