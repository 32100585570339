import React from 'react'
import { useParams } from 'react-router-dom'
import { Spin } from 'antd'

import { AIFormProvider, EditModelForm, useModelQuery } from 'domain/openAI/models'
import { AIModelForm } from 'domain/openAI/types'
import { GoBackButton, PageLayout } from 'layouts'
import { ActionsBar } from 'ui'

function EditAIModelPage() {  
  const params = useParams<{ id: string }>()
  const { data: model, isLoading, isError } = useModelQuery(params?.id as unknown as number)

  if (isLoading) return <Spin />
  if (isError || !model) return <p>Modelo não encontrado.</p>

  return (
    <PageLayout pageTitle="Editar Modelo">
      <ActionsBar>
        <GoBackButton />
      </ActionsBar>

      <AIFormProvider initialState={model as AIModelForm}>
        <EditModelForm />
      </AIFormProvider>
    </PageLayout>
  )
}

export default EditAIModelPage
