import { useGetLegalEntity, useGetServiceForm } from 'domain/createOrder/kit'
import InscricaoEstadualField from './InscricaoEstadualField'

export default function KitOrderCertidaoNegativaDebitosTributariosPGE({ itemId }: { itemId: number }) {
  const entity = useGetLegalEntity()
  const { url_uf } = useGetServiceForm(itemId)

  if (!url_uf || entity === 'fisica') return null

  return <InscricaoEstadualField itemId={itemId} federativeUnit={url_uf.value} />
}
