import React from 'react'
import { Button, Spin } from 'antd'
// @ts-ignore
import IntlCurrencyInput from 'react-intl-currency-input'

import { Icon } from 'ui'
import useGetBonusFor from '../services/useGetBonusFor'

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}

interface Props {
  suggestedValue: string
  totalDocuments: number
  onSuggestedValueChange: (e: any, value: any) => void
  onAddValueClick: () => void
  onRedoSimulationClick: () => void
}

export default function CreditSuggestion({
  suggestedValue,
  totalDocuments,
  onSuggestedValueChange,
  onAddValueClick,
  onRedoSimulationClick,
}: Props) {
  const { bonus, receive } = useGetBonusFor(parseFloat(suggestedValue))

  return (
    <>
      <div className="mt-6">
        <div className="p-6 bg-gray-700 rounded">
          <h1 className="text-white text-lg">Sugestão de recarga</h1>
          <div className="mt-4 flex items-center justify-between">
            <div className="text-gray-500 text-xs pr-3">
              Baseada na média para os {totalDocuments} documentos.
            </div>
            <div className="flex">
              <IntlCurrencyInput
                currency="BRL"
                config={currencyConfig}
                autoSelect
                defaultValue={suggestedValue}
                onChange={onSuggestedValueChange}
                className="ant-input p-2 py-0 text-xl sm:text-3xl font-light leading-normal sm:leading-6"
                max={Number.MAX_SAFE_INTEGER}
              />
            </div>
          </div>
          <Spin spinning={receive === '-'}>
            <div className="mt-6 pt-6 border-t border-gray-500 flex items-end text-white">
              <Icon name="money-hands" style={{ color: '#FFF' }} className="text-3xl -mb-2 mr-2" />
              <div className="text-lg leading-none">Receba</div>
              <div className="block h-px-1 pb-1 mx-3 border-t border-dotted border-gray-400 flex-1"></div>
              <div className="text-3xl leading-none font-light" style={{ color: '#40CA8F' }}>
                {receive}
              </div>
            </div>
            <div className="flex justify-end">
              <div className="mt-1 text-gray-500 text-xs">Incluso R${bonus} de bônus</div>
            </div>
          </Spin>
        </div>
      </div>

      <div className="mt-6">
        <Button type="primary" className="mt-5" block onClick={onAddValueClick}>
          Inserir créditos
        </Button>
        <Button type="link" className="mt-5" block onClick={onRedoSimulationClick}>
          Refazer simulação
        </Button>
      </div>
    </>
  )
}
