import React from 'react'

import { AuthorizedAPI } from 'api/network/v1'
import { saveAs } from 'utils/export'
import { Button, Icon } from 'ui'

export interface SpreadsheetDownloadProps {
  downloadUrl: string
  downloadFileName: string
}

export default function SpreadsheetDownload({ downloadUrl, downloadFileName }: SpreadsheetDownloadProps) {
  const handleClick = async () => {
    const { data } = await AuthorizedAPI.get(downloadUrl, {
      responseType: 'blob',
    })

    saveAs(data, downloadFileName)
  }

  return (
    <Button
      type="primary"
      icon="download"
      size="small"
      ghost
      onClick={handleClick}
    >
      Download da planilha base .XLS
    </Button>
  )
}
