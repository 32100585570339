import React from 'react'
import { Alert } from 'antd'

import { ServiceAPI } from 'domain/servicesCBRdoc/types'
import { Modal, Button } from 'ui'
import { downloadPDFLink } from 'utils/export'

import './ResearchExampleModal.css'

interface Props {
  open: boolean
  onCancel: () => void
  service: ServiceAPI
  onSelectService: (event: any) => void
  exampleHTMLContent?: string | null
  exampleFileURL?: string | null
}

function ResearchExampleModal({
  open,
  onCancel,
  service,
  onSelectService,
  exampleHTMLContent,
  exampleFileURL,
}: Props) {
  const handleFileDownload = () => {
    downloadPDFLink(exampleFileURL!, service.name)
  }

  return (
    <Modal
      title={service.short_name ?? service.name}
      open={open}
      onOk={onSelectService}
      onCancel={onCancel}
      cancelText="Voltar"
      okText="Solicitar"
    >
      {exampleHTMLContent ? (
        <>
          <div className="raw" dangerouslySetInnerHTML={{ __html: exampleHTMLContent ?? '' }} />
          <div>
            {!exampleFileURL && (
              <Alert
                type="error"
                message="Seu documento de exemplo ainda não está disponível para download"
                className="my-4 mb-7"
              />
            )}
            <Button
              type="link"
              icon="download"
              className="flex justify-start items-center hover:brightness-90"
              onClick={handleFileDownload}
              disabled={!exampleFileURL}
            >
              Baixar exemplo
            </Button>
          </div>
        </>
      ) : (
        <div>Ainda não possuimos exemplos desta pesquisa.</div>
      )}
    </Modal>
  )
}

export default ResearchExampleModal
