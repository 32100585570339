import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'

import {
  useGetCurrentPage,
  useGetFilterByStatus,
  useGetFilterByType,
  useGetIsHydrated,
  useGetPageSize,
  useSetTotalPages,
  useSetTotalResults,
} from '../state'
import { NotificationType, NotificationAPI } from '../types'
import convertNotification from './convertNotification'

function useNotificationsQuery() {
  const currentPage = useGetCurrentPage()
  const setPageCount = useSetTotalPages()
  const setTotalCount = useSetTotalResults()
  const pageSize = useGetPageSize()
  const type = useGetFilterByType()
  const status = useGetFilterByStatus()
  const isHydrated = useGetIsHydrated()

  return useQuery(
    ['notifications', 'all', pageSize, currentPage, status, type],
    async () => {
      const response = await getNotifications({
        page: currentPage,
        pageSize,
        status,
        type,
      })

      setPageCount(Number(response.headers['x-pagination-page-count']))
      setTotalCount(Number(response.headers['x-pagination-total-count']))

      return response.data?.map(convertNotification)
    },
    {
      enabled: isHydrated,
      keepPreviousData: true,
    }
  )
}

type GetNotificationsParams = {
  page: number
  pageSize: number
  type?: NotificationType
  status?: 'unread'
}

async function getNotifications({ page, pageSize, type, status }: GetNotificationsParams) {
  const params: any = {
    sort: '-created_at',
    page,
    'per-page': pageSize,
  }

  if (type) {
    params['filter[type]'] = type
  }

  if (status) {
    params['filter[read]'] = false
  }

  const response: AxiosResponse<NotificationAPI[]> = await AuthorizedAPI.get('/notifications', {
    params,
  })

  return response
}

export default useNotificationsQuery
