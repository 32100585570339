import React from 'react'

import { ShoppingCartOrder } from 'domain/shoppingCart/types'
import { getDeliveryInformation } from '../helpers'

function DeliveryInfo({ order }: { order: ShoppingCartOrder }) {
  const text = getDeliveryInformation(order)

  if (!text) return null
  return (
    <div className="flex items-center gap-2">
      <strong>Prazo: </strong>
      <span title={text}>{text}</span>
    </div>
  )
}

export default DeliveryInfo
