import { NamePath } from 'antd/lib/form/interface'

import SelectField, { SelectFieldProps } from './SelectField'

export interface CitySelectProps extends Omit<SelectFieldProps, 'label' | 'name' | 'placeholder'> {
  label?: string
  name?: NamePath
  multiple?: boolean
  suffix?: string
}

export default function CitySelect({
  name = 'city',
  multiple,
  suffix = '',
  label = `Cidade${multiple ? '(s)' : ''} ${suffix}`,
  optionLabelKey = 'name',
  optionValueKey = 'url',
  labelInValue = true,
  ...props
}: CitySelectProps) {
  return (
    <SelectField
      name={name}
      label={label}
      placeholder="Selecione a cidade"
      labelInValue={labelInValue}
      mode={multiple ? 'multiple' : undefined}
      optionLabelKey={optionLabelKey}
      optionValueKey={optionValueKey}
      {...props}
    />
  )
}
