import { Service } from '../types'
import { ServiceAPI } from '../typesAPI'

function convertService({
  ai_enabled,
  auto_purchase_certificate_available_result_negative,
  auto_purchase_certificate_available_result_positive,
  category1_id,
  category2_id,
  code,
  id,
  name,
  short_name,
  spreadsheet_purchase_available,
  type,
  can_be_monitored,
}: ServiceAPI): Service {
  return {
    canAutoPurchaseFromNegativeResearchResult: auto_purchase_certificate_available_result_negative,
    canAutoPurchaseFromPositiveResearchResult: auto_purchase_certificate_available_result_positive,
    category1Id: category1_id,
    category2Id: category2_id,
    code,
    hasAI: ai_enabled,
    hasSpreadsheet: spreadsheet_purchase_available,
    id,
    name,
    shortName: short_name || name,
    type,
    canBeMonitored: !!can_be_monitored,
  }
}

export default convertService
