import React from 'react'

import { useResetTableSelection, useSetSearch } from '../state'
import { SearchInput } from 'ui'

export default function SearchInvoice({ onSearch }: { onSearch?: (value: string) => void }) {
  const setSearch = useSetSearch()
  const resetSelectedRows = useResetTableSelection()

  const handleSearch = (value: string) => {
    setSearch(value)
    resetSelectedRows()

    onSearch && onSearch(value)
  }

  return (
    <div className="mb-2 md:mb-0 md:mr-5">
      <div className="font-bold text-gray-600 text-2xs mb-1 uppercase">Busca</div>
      <SearchInput
        placeholder="Pesquise por nome ou número do pedido"
        onSearch={handleSearch}
        className="flex-1 transition-all w-full md:w-64 lg:w-96 duration-300"
        allowClear
      />
    </div>
  )
}
