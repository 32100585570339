import { getAvailableFormats } from 'domain/createOrder/certificates'
import { CreateOrderState, DeliveryFormat } from 'domain/createOrder/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { Certificate } from 'domain/servicesCBRdoc/config'
import { checkIfObjectHasAllValues } from 'utils/validators'

async function getFormat(createOrder: CreateOrderState) {
  const { getAvailableFormatsParams } = getServiceConfig<Certificate>(createOrder.selectedService.code!)

  if (!getAvailableFormatsParams) {
    return null
  }

  let format: DeliveryFormat = 'email'
  const params = getAvailableFormatsParams(createOrder)

  if (params !== undefined && checkIfObjectHasAllValues(params)) {
    try {
      const availableFormatsQuery = await getAvailableFormats(createOrder.selectedService, params as any)

      if (availableFormatsQuery) {
        format = selectFormatFromOptions(availableFormatsQuery)
      }
    } catch {
      // ignora e envia "email"
    }
  }

  return format
}

function selectFormatFromOptions(availableFormats: DeliveryFormat[]) {
  return availableFormats.includes('email') ? 'email' : availableFormats[0]
}

export default getFormat
