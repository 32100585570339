import React from 'react'
import { Button } from 'antd'

import { formatMoney } from 'utils/formatters'
import useRequestRefund from '../../services/useRequestRefund'
import ServiceTax from './ServiceTax'

interface Props {
  orderItemId: number
  refundValue: number | undefined
  orderValue: number | undefined
  onSuccess: () => void
}

function RequestRefundForm({ orderItemId, refundValue, orderValue, onSuccess }: Props) {
  const showTaxInfo = refundValue !== undefined && orderValue && refundValue !== orderValue

  const { requestRefund, loading } = useRequestRefund(orderItemId, {
    onSuccess,
  })

  return (
    <>
      <div className="border-b border-gray-300 pt-1 pb-6">
        <p>
          Confira os dados abaixo e confirme sua solicitação de reembolso. Os créditos restantes estarão
          disponíveis em sua conta em instantes.
        </p>
      </div>

      <div className="w-full">
        <div className="pt-1 mb-6 border-t-2 border-gray-700">
          <div className="flex justify-between py-3 border-b border-gray-300">
            <div className="text-gray-600">Valor do crédito utilizado:</div>
            <div>{orderValue ? formatMoney(orderValue) : 'Calculando...'}</div>
          </div>

          {showTaxInfo && <ServiceTax />}

          <div className="flex justify-between py-4 items-end border-b border-gray-300">
            <div className="text-gray-600">Total de créditos que serão reembolsados:</div>
            <div className="font-bold text-xl">
              {refundValue !== undefined ? formatMoney(refundValue) : 'Calculando...'}
            </div>
          </div>
        </div>
      </div>

      {showTaxInfo && (
        <p className="text-2xs my-5">
          * A taxa de serviço refere-se ao tempo dedicado no andamento do pedido.
        </p>
      )}

      <Button type="primary" onClick={requestRefund} loading={loading} block>
        {loading ? 'Confirmando...' : 'Confirmar solicitação'}
      </Button>
    </>
  )
}

export default RequestRefundForm
