import React from 'react'

import { AuthorizedAPI } from 'api/network/v2'
import { errorHandler } from 'api/errors'
import queryClient from 'app/config/queryClient'
import { CategoryAPI, ServiceType } from 'domain/servicesCBRdoc/types'
import { Icon } from 'ui'

interface Props {
  isFavorite: boolean | undefined
  serviceId: number
  serviceType: ServiceType
}

export default function FavoriteStar({ isFavorite, serviceId, serviceType }: Props) {
  const onClick: React.MouseEventHandler<HTMLDivElement> = async e => {
    e.stopPropagation()
    try {
      const method = isFavorite ? 'delete' : 'put'

      await saveFavoriteService(serviceId, method)

      updateCategoriesQueryClient(serviceId, serviceType)
    } catch (error) {
      errorHandler(error, { code: '0x546' })
    }
  }

  return (
    <Icon
      name={isFavorite ? 'star-filled' : 'star'}
      className="cursor-pointer text-lg text-primary ml-2 transition ease-in-out duration-200 hover:scale-125"
      onClick={onClick}
    />
  )
}

function updateCategoriesQueryClient(serviceId: number, serviceType: ServiceType) {
  queryClient.setQueryData(['categories-services', 'type', serviceType], (old: any) => {
    return old?.map((category: CategoryAPI) => {
      return {
        ...category,
        services: category.services.map(service => {
          if (service.id !== serviceId) {
            return service
          }

          return { ...service, is_favorite: !(service.is_favorite ?? false) }
        }),
      }
    })
  })
}

const saveFavoriteService = async (serviceId: number, method: 'put' | 'delete') => {
  await AuthorizedAPI[method](`/services/${serviceId}/favorite`)
}
