import { ExplorerAction } from '../types'
import { useGetMyFilesAction, useSetMyFilesAction } from './myFilesState'

export const useSetAction = () => {
  const setActionHook = useSetMyFilesAction()
  const setAction = (action: ExplorerAction) => {
    setActionHook(action)
  }

  return setAction
}

export const useActionState = (): [ExplorerAction, (action: ExplorerAction) => void] => {
  const setAction = useSetAction()
  const action = useGetMyFilesAction()

  return [action, setAction]
}
