import React from 'react'
import * as Sentry from '@sentry/browser'
import { Icon } from 'ui'
import { useLogout } from 'domain/auth'
import { ErrorBoundary } from 'react-error-boundary'

function CustomErrorBoundary({ children }: { children: React.ReactNode }) {
  const logout = useLogout()
  return (
    <ErrorBoundary
      fallback={
        <div className="p-7 w-full h-full leading-loose flex flex-col justify-center items-center" role="alert">
          <Icon name="brokenComputer" />
          
          <h1 className="text-2xl text-center font-bold text-gray-700 mt-8">Algo deu errado</h1>

          <div>
            <p className="my-4 font-bold">Você pode tentar os seguintes procedimentos:</p>

            <ol className="list-none flex flex-col gap-4">
              <li>
                <a
                  href="https://www.google.com/search?q=como+limpar+o+cache+do+navegador"
                  rel="noopener noreferrer"
                  target="_blank"
                  className='text-black text-base font-bold'
                >
                1 - Limpar o cache do navegador:
                </a>
                <ul className="list-disc ml-6 -mt-2">
                  <li className='h-6'>Windows e Linux: CTRL + F5</li>
                  <li className='h-6'>Apple Safari: SHIFT + botão recarregar barra de ferramentas</li>
                  <li className='h-6'>Chrome e Firefox para Mac: CMD + SHIFT + R</li>
                </ul>
              </li>
              <li className='text-black text-base font-bold'>
              2 - Fazer login novamente (
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    logout()
                  }}
                >
                clique aqui
                </a>
              )
              </li>
              <li className='text-black text-base font-bold'>3 - Entrar em contato conosco.</li>
            </ol>
          </div>
        </div>
      }
      onError={(error, errorInfo: any) => {
        if (process.env.NODE_ENV === 'development') {
          console.log('error', error)
        } else {
          Sentry.captureException(error)
          Sentry.captureMessage(errorInfo)
        }
      }}
      onReset={details => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoundary>
  )
}

export default CustomErrorBoundary
