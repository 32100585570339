import React from 'react'
import Icon from 'ui/Icon'

export type StepStatus = 'pending' | 'current' | 'done' | 'failed'

export interface Step {
  title: string
  status?: StepStatus
  date?: string
  uri?: string
}

export interface StepStatusProps {
  steps: Step[]
  stepIndex: number
  current?: number
  status?: StepStatus
}

export default function StepStatus({ stepIndex, current, steps, status }: StepStatusProps) {
  const step = steps[stepIndex]
  let className = ''
  const isDone = Boolean(status === 'done' || step.status === 'done' || (current && stepIndex < current))

  if (isDone) {
    className += 'bg-secondary-500 text-white flex items-center justify-center'
  } else if (step.status === 'failed') {
    className += 'bg-error'
  } else if (current === stepIndex || step.status === 'current' || current === stepIndex) {
    className += 'bg-success'
  } else if (step.status === 'pending' || (current && stepIndex > current)) {
    className += 'bg-gray-200'
  }

  return (
    <div className={`w-5 h-5 rounded-full border-2 border-white ${className}`}>
      {!!isDone && <Icon name="check" size={10} strokeWidth={4} />}
    </div>
  )
}
