import AIChart from './AIChart'
import StatisticsCards from './StatisticsCards'
import AIExportToExcel from './AIExportToExcel'

export default function AIReportTab() {
  return (
    <>
      <StatisticsCards />
      
      <div className="py-7 bg-gray-900">
        <h2 className="uppercase text-white text-base ml-7 pb-5">Pedidos com extração de dados</h2>

        <AIChart />
      </div>

      <AIExportToExcel />
    </>
  )
}
