import React from 'react'
import { Badge, Tooltip } from 'antd'
import { ResultCode, OrderResults } from 'domain/orders/types'

interface Props {
  result: ResultCode | null
}

function OrderResult({ result }: Props) {
  if (!result) return null
  const config = OrderResults[result]

  return (
    config && (
      <Tooltip title={config.label}>
        <Badge
          status={config.color as any}
          text={<p className="inline text-primary cursor-default">{config.shortLabel}</p>}
        />
      </Tooltip>
    )
  )
}

export default OrderResult
