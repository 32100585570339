import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { atomURI } from 'utils/navigation'

import { PostpaidSortField, PrepaidSortField } from '../types'

// Current page
export const currentPageAtom = atom(1)
export const currentPageUpdateURIAtom = atomURI(currentPageAtom, 'p')

export const useCurrentPage = () => useAtom(currentPageUpdateURIAtom)
export const useGetCurrentPage = () => useAtomValue(currentPageAtom)
export const useSetCurrentPage = () => useSetAtom(currentPageUpdateURIAtom)

// Page size
const pageSizeAtom = atomWithStorage('invoices-page-size', 20)
export const useGetPageSize = () => useAtomValue(pageSizeAtom)
export const usePageSize = () => useAtom(pageSizeAtom)

// Sort
const prepaidSortField = atomWithStorage<PrepaidSortField>('pre-invoices-sort-field', 'purchase.name')
const postpaidSortField = atomWithStorage<PostpaidSortField>('pos-invoices-sort-field', 'order.name')
const sortDirection = atomWithStorage('invoices-sort-order', 'descend')

export const useGetPrepaidSortBy = () => useAtomValue(prepaidSortBy)
export const useGetPostpaidSortBy = () => useAtomValue(postpaidSortBy)

const prepaidSortBy = atom(get => {
  const field = get(prepaidSortField)
  return get(sortDirection) === 'descend' ? `-${field}` : field
})

const postpaidSortBy = atom(get => {
  const field = get(postpaidSortField)
  return get(sortDirection) === 'descend' ? `-${field}` : field
})

export const useGetSortDirection = () => useAtomValue(sortDirection)
export const useSetSortDirection = () => useSetAtom(sortDirection)
export const useSetPrepaidSortField = () => useSetAtom(prepaidSortField)
export const useSetPostpaidSortField = () => useSetAtom(postpaidSortField)

// Total pages
const totalPagesAtom = atom(0)
export const useSetTotalPages = () => useSetAtom(totalPagesAtom)

// Total results
const totalResultsAtom = atom(0)
export const useGetTotalResults = () => useAtomValue(totalResultsAtom)
export const useSetTotalResults = () => useSetAtom(totalResultsAtom)
