import { CreateOrderState } from 'domain/createOrder/types'
import { OrderItem, ResearchResult } from 'domain/orderItem/types'
import { ResultCode } from 'domain/orders/types'
import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const {
  FORMATO,
  TEMPO_PESQUISA,
  TIPO_PESSOA,
  TODOS_CARTORIOS_CIDADE,
  URL_CARTORIO,
  URL_CIDADE,
  URL_UF,
} = FieldName

type CertidaoProtestoFields = Record<
  | FieldName.CEP
  | FieldName.CNPJ
  | FieldName.CPF
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.RG  
  | FieldName.TODOS_CARTORIOS_CIDADE
  | FieldName.URL_CARTORIO
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

type CertidaoProtestoDynamicFields = Record<FieldName.TEMPO_PESQUISA, FieldConfig>

class CertidaoProtesto extends Certificate {
  formFields: CertidaoProtestoFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    url_cartorio: {
      ...Fields.url_cartorio,
      multiple: true,
    },
    todos_cartorios_cidade: Fields.todos_cartorios_cidade,
    cpf: Fields.cpf,
    cnpj: Fields.cnpj,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
    rg: Fields.rg,
    cep: {
      ...Fields.cep,
      optional: true,
      kitFieldEquivalent: FieldName.REGISTRADO_ENDERECO_CEP
    }    
  }

  dynamicFields: CertidaoProtestoDynamicFields = {
    tempo_pesquisa: {
      ...Fields.tempo_pesquisa,
      defaultValue: 5,
    },
  }

  constructor() {
    super({
      availableFormatsFields: [TEMPO_PESQUISA, URL_CARTORIO, URL_CIDADE, URL_UF],
      extraInfoFields: [URL_CARTORIO, URL_CIDADE, URL_UF],
      id: 11,
      shoudAutoLoadDynamicFields: true,
      priceByFields: [
        FORMATO,
        URL_CARTORIO,
        URL_CIDADE,
        URL_UF,
        TEMPO_PESQUISA,
        TIPO_PESSOA,
        TODOS_CARTORIOS_CIDADE,
      ],
      shortName: 'Protesto',
      steps: ['localizacao', 'dados', 'entrega'],
    })
  }

  extractResearchData = (
    result: ResearchResult,
    resultCode: ResultCode,
    orderItem: OrderItem
  ): Partial<CreateOrderState> => {
    const formData: any = {
      ...orderItem.researchResultsOrderSettings![resultCode]!.placeOrder?.formData,
      ...result,
      // TODOS_CARTORIOS_CIDADE interfere no preço para o estado de SP
      // a informação não vem do back e
      // é um caso complicado de resolver (Jhonatan), por isso sempre mandamos false
      [FieldName.TODOS_CARTORIOS_CIDADE]: false
    }

    if (!formData[FieldName.TEMPO_PESQUISA]) {
      formData[FieldName.TEMPO_PESQUISA] = 5
    }

    return {
      formData
    }
  }
}

const service = new CertidaoProtesto().initialize()

export default service
