import React from 'react'

import { ResultCode } from 'domain/orders/types'
import { useResults } from 'domain/orders/state/filtersOverview'
import { SelectableButton } from 'ui'

interface Props {
  result: ResultCode
  text: string
}

export default function FilterByResult({ result, text }: Props) {
  const [filterResults, setResults] = useResults()
  const isSelected = filterResults.includes(result)

  return (
    <SelectableButton
      selected={isSelected}
      onClick={() => {
        const updated = isSelected
          ? filterResults.filter((code: any) => code !== result)
          : [...filterResults, result]
        setResults(updated)
      }}
    >
      {text ?? result}
    </SelectableButton>
  )
}
