import React from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { useGetOrderItem } from 'domain/orderItem/shared'
import { ResearchResult } from 'domain/orderItem/types'
import { useGetSelectedResults, useSelectResearchResults } from '../state/selectedResearchResults'

interface Props {
  columns: ColumnsType<ResearchResult>
  data: ResearchResult[]
  pagination?: any
}

export default function ResearchResultsTable({ columns, data, pagination = false }: Props) {
  const orderItem = useGetOrderItem()
  const selectedOrders = useGetSelectedResults()
  const addSelectedOrder = useSelectResearchResults()

  const rowSelection = {
    selectedRowKeys: selectedOrders.find(s => s.orderItem.id == orderItem.id)?.selectedResultsKeys ?? [],
    preserveSelectedRowKeys: true,
    columnWidth: 36,
    onChange: (rowKeys: any[], selectedRows: ResearchResult[]) => {
      addSelectedOrder({
        selectedResultsKeys: rowKeys,
        selected: selectedRows,
        orderItem,
      })
    },
  }

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="id"
      rowSelection={rowSelection}
      pagination={pagination}
      className="mt-4 mb-7"
      scroll={scroll}
    />
  )
}

const scroll = { x: 800 }
