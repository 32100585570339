
import { useEffect } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import { useIsHydratedFromURL } from 'domain/app'
import { useSetCurrentPage } from './pagination'
import { useSetSearch } from '../filters'

export default function useSetCustomerModelsInitialValuesFromURL() {
  const history = useHistory()
  const location = history.location
  const setCurrentPage = useSetCurrentPage()
  const setSearch = useSetSearch()
  const [isHydrated, setIsHydrated] = useIsHydratedFromURL()

  useEffect(() => {
    if (!isHydrated) {
      if (location.search) {
        const { p, q } = queryString.parse(location.search)

        if (p) {
          setCurrentPage(Number(p))
        }

        if (q) {
          setSearch(q as string)
        }
      }

      setIsHydrated(true)
    }
    
  }, [isHydrated])
}
