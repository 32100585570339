export const getFieldName = (name: string, itemId: number | string | undefined) => {
  return itemId === undefined ? name : [itemId, name]
}

export const getFieldNameId = (itemId: number) => (name: string) => [itemId, name]

// export function getFieldNamesFromConfig(itemId: string, fieldsConfig: Record<any, FieldConfig>) {
//   const fieldNames: any = {}

//   Object.values(fieldsConfig).forEach(({ name }) => {
//     fieldNames[name] = `${itemId}[${name}]`
//   })

//   return fieldNames
// }

// export function extractFieldName(name: string) {
//   const value = name.match(/\[(.*?)\]/)

//   if (value && value.length > 1) {
//     return value[1]
//   }

//   return name
// }
