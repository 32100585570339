import React from 'react'
import { Spin } from 'antd'

import { useCustomerCreditsQuery } from 'domain/credits'
import { Icon } from 'ui'
import { formatMoney } from 'utils/formatters'

export default function AccountBalanceCredits() {
  const { data: accountBalance, isFetching } = useCustomerCreditsQuery()

  return (
    <Spin spinning={isFetching}>
      <div>
        <div className="flex items-center mb-1">
          <Icon name="money" className="text-lg lg:text-2xl flex-none text-gray-700 mr-2" />
          <div className="font-bold text-base lg:text-xl text-green-500 pt-1 leading-tight">
            {formatMoney(accountBalance)}
          </div>
        </div>
        <div className="text-2xs text-gray-600">Crédito disponível</div>
      </div>
    </Spin>
  )
}
