import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaEmpresaCartoriosPJFields = Record<
  FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ,
  FieldConfig
>

class PesquisaEmpresaCartoriosPJ extends Research {
  formFields: PesquisaEmpresaCartoriosPJFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
  }

  constructor() {
    super({
      description: 'Retorna se um CNPJ está registrado em cartório de pessoas jurídicas e seus atos.',
      id: 66,
      shortName: 'Pesquisa Empresa',
    })
  }
}

const service = new PesquisaEmpresaCartoriosPJ().initialize()

export default service
