import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { useCurrentUser } from 'domain/auth'
import { CustomerAPI } from 'domain/customers/types'
import { useSetCustomer } from '../state'
import convertCustomer from './convertCustomer'

export default function useLoadCurrentCustomer() {
  const user = useCurrentUser()
  const setCustomer = useSetCustomer()

  return useQuery(
    ['current-customer'],
    async () => {
      const response: AxiosResponse<CustomerAPI> = await AuthorizedAPI.get('/customers', {
        params: {
          include: 'users_count',
        },
      })

      if (response.data) {
        const customer = convertCustomer(response.data)

        setCustomer(customer)

        return customer
      }

      return null
    },
    {
      enabled: !!user.accessToken,
    }
  )
}
