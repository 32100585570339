import { useEffect } from 'react'

import { useResetShoppingCart } from 'domain/shoppingCart'
import useResetCreateOrder from './useResetCreateOrder'

function useResetCreateOrderAndCart() {
  const resetShoppingCart = useResetShoppingCart()

  useResetCreateOrder()

  useEffect(() => {
    resetShoppingCart()
  }, [])
}

export default useResetCreateOrderAndCart
