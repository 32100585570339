import React from 'react'
import { Button } from 'antd'

import { HelpPopover } from 'domain/app'

export default function QuestionHelp({ className }: { className?: string }) {
  return (
    <HelpPopover
      content={
        <>
          <p className="mb-1">
            Descreva aqui de forma sucinta e objetiva como a inteligência artificial deve buscar a informação
            deste campo.
          </p>
          <Button
            type="primary"
            ghost
            href="https://suporte.cbrdoc.com.br/hc/pt-br/articles/17457679312667"
            target="_blank"
          >
            Saiba mais
          </Button>
        </>
      }
      title="Instruções para Extração de Dados"
      className={className}
    />
  )
}
