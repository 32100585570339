import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { FORMATO } = FieldName

type CertidaoNegativaMPFFields = Record<FieldName.CNPJ | FieldName.CPF, FieldConfig>

class CertidaoNegativaMPF extends Certificate {
  formFields: CertidaoNegativaMPFFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
  }

  constructor() {
    super({
      id: 32,
      priceByFields: [FORMATO],
      shortName: 'MPF - Certidão Negativa',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoNegativaMPF().initialize()

export default service

