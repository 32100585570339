import { useHistory } from 'react-router'

import { showErrorModal } from 'api/errors'
import { SecretAPI } from 'api/network/v2'
import { RoutePaths } from 'app/routes'
import { convertUser } from 'domain/users'
import { getStorageData, setStorageData } from 'utils/localStorage'
import { useSetAuth } from '../state'

export default function useLoginWithToken() {
  const history = useHistory()
  const setAuth = useSetAuth()

  return async function loginWithToken(accessToken: string) {
    const provider = getStorageData('sso')?.provider

    if (provider) {
      SecretAPI.post(`/login/sso/${provider}`, { access_token: accessToken })
        .then((apiResponse: any) => {
          setStorageData('sso', { token: accessToken, provider })

          if (apiResponse.data) {
            const user = convertUser(apiResponse.data)

            setStorageData('auth', user)
            setAuth(user)

            history.push(RoutePaths.ORDERS)
          }
        })
        .catch((error: any) => {
          if (error.response.status === UNAUTHORIZED) {
            showErrorModal(error, {
              message: 'Login não autorizado',
              description: 'Apenas emails previamente autorizados podem efetuar login através de SSO.',
            })

            history.push(RoutePaths.LOGIN)
          } else {
            showErrorModal(error)
          }          
        })
    }
  }
}

const UNAUTHORIZED = 401
