import React from 'react'
import { Button, Popconfirm } from 'antd'

import { Icon } from 'ui'

interface Props {
  onConfirm: () => void
  loading?: boolean
  title?: string
  disabled?: boolean
}

function DeleteKitItemButton({ onConfirm, ...props }: Props) {
  return (
    <Popconfirm
      title="Tem certeza que deseja excluir o item do kit?"
      onConfirm={onConfirm}
      okText="Sim"
      cancelText="Não"
    >
      <Button
        type="link"
        
        size="small"
        icon={<Icon name="trash" width="28" height="28" className="text-gray-600 flex text-lg" />}
        {...props}
      />
    </Popconfirm>
  )
}

export default DeleteKitItemButton
