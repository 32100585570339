/* eslint-disable @typescript-eslint/no-unused-vars */
import { LabelInValue } from 'domain/app/types'
import { KitLegalEntity } from 'domain/kits/types'
import { SelectedService } from 'domain/orderItem/types'
import { LocationAPI } from 'domain/orders/typesAPI'
import { FieldConfig, FieldConfigs, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { Location, SelectedLocation } from 'domain/servicesCBRdoc/types'
import { extractValue } from 'utils/transformData'

import { ServiceConfig } from '../types'
import { Fields } from '../serviceFields'

type LocationTypeProps = {
  service: SelectedService
  location: SelectedLocation
  onChange: (type: string) => void
  itemId?: number
  required?: boolean
}

export default class CBRdocService {
  public allowMultipleItems
  public autoOrderResults?: string
  public cityDependsOnType?: string
  public description
  public dynamicFields: FieldConfigs = {}
  public formFields: FieldConfigs = {}
  public id
  public legalEntity: KitLegalEntity | undefined
  public orderNameField?: FieldName
  public orderNameFieldMap?: Record<string, FieldName>
  public orderNamePrefixField?: FieldName
  public orderNameByShowIf?: boolean
  public shortName
  public steps
  public startFromStep
  public LocationUFType?: (props: LocationTypeProps) => JSX.Element | null
  public allFields: FieldConfig[] = []
  public displayIsNewTag = false
  public multipleItemsCollapseHeaderTitleField?: FieldName
  public isUnavailable?: boolean = false

  constructor({
    allowMultipleItems,
    description,
    legalEntity,
    id,
    orderNameField,
    orderNameFieldMap,
    orderNamePrefixField,
    shortName,
    steps,
    displayIsNewTag,
    orderNameByShowIf,
    startFromStep,
    multipleItemsCollapseHeaderTitleField,
  }: ServiceConfig) {
    this.allowMultipleItems = allowMultipleItems ?? true
    this.description = description
    this.id = id
    this.legalEntity = legalEntity
    this.orderNameField = orderNameField
    this.orderNameFieldMap = orderNameFieldMap
    this.orderNamePrefixField = orderNamePrefixField
    this.shortName = shortName
    this.orderNameByShowIf = orderNameByShowIf
    this.steps = steps
    this.startFromStep = startFromStep
    this.displayIsNewTag = displayIsNewTag ?? false
    this.multipleItemsCollapseHeaderTitleField = multipleItemsCollapseHeaderTitleField
  }

  initialize() {
    this.allFields = [...Object.values(this.formFields), ...Object.values(this.dynamicFields)]

    if (!this.legalEntity) {
      const { cpf, cnpj } = this.formFields

      if (cpf || cnpj) {
        this.legalEntity = cpf && cnpj ? 'indefinido' : cnpj ? 'juridica' : 'fisica'
      }
    }

    return this
  }

  protected getUrlLocation = (
    fieldName: FieldName,
    selected: string | LabelInValue | LabelInValue[] | undefined
  ) => {
    if (!selected) return undefined
    if (typeof selected === 'string') return selected

    const urls = extractValue(selected)
    const isMultiple = (this.formFields[fieldName] ?? this.dynamicFields[fieldName])?.multiple

    if (Array.isArray(urls)) {
      return isMultiple ? urls : urls?.[0]
    }

    return isMultiple ? [urls] : urls
  }

  protected extractLocationUrls = (location: SelectedLocation) => {
    const urls: any = {}

    if (this.formFields[URL_UF]) {
      urls[URL_UF] = this.getUrlLocation(FieldName.URL_UF, location.url_uf)

      if (this.formFields[URL_CIDADE]) {
        urls[URL_CIDADE] = this.getUrlLocation(FieldName.URL_CIDADE, location.url_cidade)
      }

      if (this.formFields[URL_CARTORIO]) {
        urls[URL_CARTORIO] = this.getUrlLocation(FieldName.URL_CARTORIO, location.url_cartorio)
      }
    }

    return urls
  }

  getDefaultLegalEntity = () => {
    if (this.legalEntity) {
      return this.legalEntity === 'juridica' ? 'juridica' : 'fisica'
    }
  }

  protected transformDontKnow = (locations: Location[]) => {
    if (locations && this.formFields.nao_sei_livro_pagina) {
      const dontKnowIndex = locations.findIndex(
        (o: any) => o.url === 'NS' || o.url === 'NAO_SEI' || o.url.includes('NAOSEI')
      )

      if (dontKnowIndex !== -1) {
        const downKnow = locations.splice(dontKnowIndex, 1)
        locations = [...downKnow, ...locations]
      }
    }

    return locations
  }

  getAvailableCities = (locations: Location[], legalEntity: KitLegalEntity | undefined) => {
    return this.transformDontKnow(locations)
  }

  getAvailableFederativeUnits = (locations: Location[], legalEntity: KitLegalEntity | undefined) => {
    return this.transformDontKnow(locations)
  }

  getAvailableNotaries = (locations: Location[]) => {
    const data = this.transformDontKnow(locations)
    return data.map((item: LocationAPI) => ({ label: item.name, value: item.url }))
  }

  getFieldBy = (value: string, property: keyof FieldConfig) => {
    const findField = (field: FieldConfig) => field[property] === value

    return this.allFields.find(findField) ?? Object.values(Fields).find(findField)
  }

  getField = (value: string) => {
    return this.getFieldBy(value, 'name') ?? this.getFieldBy(value, 'apiName')
  }
}

const { URL_CARTORIO, URL_CIDADE, URL_UF } = FieldName
