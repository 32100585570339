import React from 'react'
import { Button, Modal } from 'antd'

import MultipleItemsSimilarOrders, { MultipleItemsSimilarOrdersProps } from './MultipleItemsSimilarOrders'

interface Props extends MultipleItemsSimilarOrdersProps {
  open: boolean
  onClose: () => void
  onContinue: () => void
}

export default function MultipleItemsOrderExistsModal({
  open,
  onClose,
  similarOrders,
  setSimilarOrders,
  onContinue,
  totalItems,
}: Props) {
  const handleCancel = () => {
    setSimilarOrders([])
    onClose()
  }

  const handleContinue = () => {
    onClose()
    onContinue()
  }

  return (
    <Modal open={open} title="Pedido já Realizado" onCancel={handleCancel} footer={null}>
      <div>
        <MultipleItemsSimilarOrders
          similarOrders={similarOrders}
          setSimilarOrders={setSimilarOrders}
          totalItems={totalItems}
        />

        <div className="pt-5">
          <p>Deseja continuar com o pedido?</p>
        </div>

        <div className="flex pt-5">
          <Button type="primary" block className="mr-2" onClick={handleContinue} tabIndex={1} autoFocus>
            Continuar
          </Button>

          <Button type="primary" ghost role="button" block onClick={handleCancel} tabIndex={2}>
            Cancelar
          </Button>
        </div>
      </div>
    </Modal>
  )
}
