import React, { useEffect, useState } from 'react'
import { notification } from 'antd'
import dayjs from 'dayjs'

import useEditRecurrence from '../../services/useEditRecurrence'
import { Recurrence, RecurrenceForm as RecurrenceFormType } from '../../types'
import RecurrenceForm from './RecurrenceForm'

type Props = {
  recurrence: Recurrence
}

const EditRecurrence = ({ recurrence }: Props) => {
  const [formData, setFormData] = useState<RecurrenceFormType>({} as RecurrenceFormType)

  const { status, mutate } = useEditRecurrence(recurrence!.id, {
    onSuccess: () => {
      notification.success({
        message: 'Sucesso',
        description: 'A Recorrência foi atualizada com sucesso.',
      })
    },
  })

  useEffect(() => {
    setFormData({
      ...recurrence!,
      startsAt: dayjs(recurrence!.startsAt),
      notify: !!recurrence.notifyResultChangesInItems,
    })
  }, [recurrence])

  return (
    <RecurrenceForm
      formData={formData}
      setFormData={setFormData}
      status={status}
      onFinish={mutate as any}
      buttonLabel="Salvar"
      recurrence={recurrence}
    />
  )
}

export default EditRecurrence
