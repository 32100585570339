import React from 'react'

interface Props {
  text: string
  statistic: string | number | undefined
  statisticColor: string
}

export default function DashboardCard({ text, statistic, statisticColor }: Props) {
  return (
    <div className="flex flex-row flex-1 justify-between items-end rounded bg-white px-3 py-1 text-secondary-500">
      <h4 className="text-gray-600 text-base">{text}</h4>
      <span className="text-3xl font-light" style={{ color: statisticColor }}>
        {statistic ?? '0'}
      </span>
    </div>
  )
}
