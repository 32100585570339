import React from 'react'
import { Form } from 'antd'

import { useOrderQuotationApproval } from 'domain/orders/services'
import { Modal, SubmitButton, TextAreaInput } from 'ui'

interface Props {
  orderId: number
  open: boolean
  onClose: () => void
}

function OrderPriceQuotationRejectModal({ open, onClose, orderId }: Props) {
  const [form] = Form.useForm()
  const [handleRejectBudget, loading] = useOrderQuotationApproval(orderId, 'reject')

  const onSubmit = async (data: any) => {
    handleRejectBudget(data, onClose)
  }

  return (
    <Modal open={open} title="Rejeitar Orçamento" onCancel={onClose} footer={null}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        requiredMark={false}
        scrollToFirstError
        className="max-w-lg"
      >
        <TextAreaInput
          name="quoted_rejected_reason"
          label="Motivo"
          className="h-40"
          maxLength={120}
          required
          autoFocus
        />

        <SubmitButton label="Rejeitar" loading={loading} />
      </Form>
    </Modal>
  )
}

export default OrderPriceQuotationRejectModal
