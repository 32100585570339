import { useMutation } from 'react-query'
import { message } from 'antd'

import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'
import { Kit, KitItem } from 'domain/kits/types'

import { convertKitItemPostAPI } from './convertToAPI'

type MutationParams = {
  kitId: number
  kitItem: Partial<KitItem>
}

type Options = {
  onSuccess?: () => void
}

function useAddKitItemMutation(queryKey: any[], { onSuccess }: Options = {}) {
  return useMutation(
    async ({ kitId, kitItem }: any) => {
      await AuthorizedAPI.post(`/kits/${kitId}/items`, convertKitItemPostAPI(kitItem))
    },
    {
      onMutate: async ({ kitItem }: MutationParams) => {
        const previousKit = queryClient.getQueryData(queryKey) as Kit

        // Add optimistic items to kit query
        queryClient.setQueryData(queryKey, {
          ...previousKit,
          items: [...previousKit.items, kitItem],
        })

        // Return context with the optimistic items
        return { previousKit }
      },
      onError: (data, variables, context: any) => {
        message.error('Ocorreu um erro ao tentar inserir o item no kit.')
        queryClient.setQueryData(queryKey, context.previousKit)
      },
      onSuccess: () => {
        message.success('O item foi inserido com sucesso.')
        onSuccess && onSuccess()
      },
    }
  )
}

export default useAddKitItemMutation
