import React from 'react'

import { NotificationTypesConfig } from '../data'
import { NotificationType as Type } from '../types'

function NotificationType({ type }: { type: Type }) {
  return <h4 className="text-secondary-500 uppercase font-bold">{NotificationTypesConfig[type].text}</h4>
}

export default NotificationType
