import React, { useState } from 'react'

import { OrderItemListing } from 'domain/orderItem/types'
import { SelectedItems } from 'domain/orders/types'

import OrderItemsTable from '../orderOverview/OrderItemsTable'
import OrderItemsMassActions from './actions/OrderItemsMassActions'

interface Props {
  items: OrderItemListing[]
  isLoading: boolean
  topRef: React.RefObject<HTMLDivElement>
}

export default function OrderOverviewList({ items, isLoading }: Props) {
  const [selected, setSelected] = useState<SelectedItems>({ items: [], ids: [] })

  const onClose = () => {
    setSelected({ items: [], ids: [] })
  }

  return (
    <>
      <OrderItemsTable
        items={items}
        loading={isLoading}
        selectedIds={selected.ids}
        setSelected={setSelected}
      />
      <OrderItemsMassActions selectedItems={selected.items} onClose={onClose} />
    </>
  )
}
