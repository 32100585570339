import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { CreateExplorerItemParams } from '../types'

export const editExplorerItemService = async (itemId: number, serviceId: number) => {
  return await AuthorizedAPI.patch(`/explorer/${itemId}/service`, {
    service_id: serviceId || null,
  })
}

export const createExplorerItem = async ({ name, type, parentId }: CreateExplorerItemParams) => {
  const { data }: AxiosResponse<any> = await AuthorizedAPI.post('/explorer/folder', {
    name,
    type,
    parent_id: parentId,
  })
  return data
}
