import { AxiosResponse } from 'axios'

import { API } from 'api/network/v2'
import { ExtraInfo, OrderItemDetails, SelectedService } from 'domain/orderItem/types'

export async function getExtraInformations<Type>(
  service: Pick<SelectedService, 'categoryId' | 'id'>,
  params: OrderItemDetails | null,
  information?: ExtraInfo
) {
  const details = params ? { detailed_service_data: params } : undefined

  const response: AxiosResponse<Type> = await API.post(
    `/services/${service.id}/categories/${service.categoryId}/extra-informations${
      information ? '/' + information : ''
    }`,
    details
  )

  return response.data
}
