import React from 'react'
import { message } from 'antd'

import { ExplorerItem, ExplorerItemType } from 'domain/myFiles/types'
import { DrawerActions } from 'ui'
import { useDownload } from 'utils/export'

interface Props {
  items: ExplorerItem[]
}

function MyFilesMassDownload({ items }: Props) {
  const [handleDownload, isFileLoading] = useDownload()

  const onDownloadClick = async () => {
    const { downloadableFiles, notDownloadableFiles } = items.reduce(
      (acc, file) => {
        if (file.type === ExplorerItemType.ORDER) {
          file.order?.file_preview_url
            ? acc.downloadableFiles.push(file)
            : acc.notDownloadableFiles.push(file)
        } else {
          acc.downloadableFiles.push(file)
        }
        return acc
      },
      { downloadableFiles: [] as ExplorerItem[], notDownloadableFiles: [] as ExplorerItem[] }
    )

    const filesId = downloadableFiles.map(file => file.id).join(',')
    const downloadUrl = `/explorer/${filesId}/download`

    if (notDownloadableFiles.length) {
      message.warning('Alguns documentos selecionados não estão disponíveis para download.')
    }

    await handleDownload(downloadUrl)
  }

  return (
    <DrawerActions.Button loading={isFileLoading} icon="download" onClick={onDownloadClick}>
      Baixar
    </DrawerActions.Button>
  )
}

export default MyFilesMassDownload
