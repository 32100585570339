import React, { useMemo, useReducer } from 'react'
import { Button } from 'antd'
import { twMerge } from 'tailwind-merge'

import { getNoPermissionText, useHasRecurrencePermission } from 'domain/auth'
import { OrderItem } from 'domain/orderItem/types'
import { CreateRecurrence } from 'domain/recurrences'
import { Icon } from 'ui'

interface Props {
  orderItem: OrderItem
}

export default function RecurrenceButton({ orderItem }: Props) {
  const [openModal, toggleModal] = useReducer(prev => !prev, false)
  const hasPermission = useHasRecurrencePermission()

  const buttonClassName = useMemo(() => {
    const baseClassName = 'flex text-primary border bg-white text-base border-primary'
    return twMerge(baseClassName, (orderItem.isFromPassportWithoutAllData || !hasPermission) && 'border-gray-500 text-gray-500')
  }, [orderItem, hasPermission])

  return (
    <>
      <Button
        className={buttonClassName}
        icon={<Icon name="repeat-alt" />}
        onClick={toggleModal}
        disabled={orderItem.isFromPassportWithoutAllData || !hasPermission}
        title={
          orderItem.isFromPassportWithoutAllData
            ? 'Este pedido não tem todos os dados necessários para criar uma Recorrência'
            : getNoPermissionText(hasPermission)
        }
        type="link"
      >
        Criar Recorrência
      </Button>

      <CreateRecurrence
        openModal={openModal}
        onCloseModal={toggleModal}
        items={[orderItem]}
        kind="order-items"
      />
    </>
  )
}
