import React from 'react'

import { EstimatedDeliveryTime } from 'domain/createOrder/types'
import { Icon } from 'ui'

interface Props {
  estimatedDeliveryTime: EstimatedDeliveryTime
}

export default function DeliveryInfo({ estimatedDeliveryTime }: Props) {
  return (
    <div className="flex justify-between items-center">
      <div className="text-gray-700">
        <Icon name="calendar" className="text-base mr-2" />
        <span className="leading-snug text-sm">Prazo estimado:</span>
      </div>
      <div className="text-gray-600 text-sm">
        {Array.isArray(estimatedDeliveryTime)
          ? `de ${estimatedDeliveryTime[0]} até ${estimatedDeliveryTime[1]}`
          : estimatedDeliveryTime}{' '}
        dias úteis
      </div>
    </div>
  )
}
