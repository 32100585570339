import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

import { atomURI } from 'utils/navigation'
import { RecurrenceFilters } from '../types'
import { currentPageURIAtom } from './pagination'

const searchAtom = atom<string>('')
const searchURIAtom = atomURI(searchAtom, 'q')

const ownerAtom = atom<number | undefined>(undefined)
const ownerURIAtom = atomURI(ownerAtom, 'owner')

const datesAtom = atom<string | undefined>(undefined)
const datesURIAtom = atomURI(datesAtom, 'dates')

const paginatedSearch = atom(get => get(searchAtom), (_, set, value: string) => {
  set(currentPageURIAtom, 1)
  set(searchURIAtom, value)
})

const clearFilters = atom(() => null, (_, set) => {
  set(searchURIAtom, '')
  set(ownerURIAtom, undefined)
  set(datesURIAtom, undefined)
})

const allFilters = atom(
  get => ({
    nameOrId: get(searchAtom),
    ownerId: get(ownerAtom),
    dates: get(datesAtom),
  }),
  (_, set, value: RecurrenceFilters) => {
    set(currentPageURIAtom, 1)
    set(searchURIAtom, value.nameOrId)
    set(ownerURIAtom, value.ownerId)
    set(datesURIAtom, value.dates)
  }
)

const hasFiltersAtom = atom(get => {
  const filters = get(allFilters)
  return Object.values(filters).some(Boolean)
})

export const useClearFilters = () => useSetAtom(clearFilters)
export const useGetFilters = () => useAtomValue(allFilters)
export const useHasFilters = () => useAtomValue(hasFiltersAtom)
export const useFilters = () => useAtom(allFilters)
export const useSearch = () => useAtom(paginatedSearch)
export const useSetDates = () => useSetAtom(datesAtom)
export const useSetOwner = () => useSetAtom(ownerAtom)
export const useSetSearch = () => useSetAtom(searchAtom)
