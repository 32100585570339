import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { useCurrentUser } from 'domain/auth'
import { KitAPI } from '../types'
import convertKit from './convertKit'

export default function useKitQuery(kitId: number | string | undefined, options: any = {}) {
  const authState = useCurrentUser()
  const queryKey = ['kits', kitId, authState.id]

  const query = useQuery(
    queryKey,
    async () => {
      const response: AxiosResponse<KitAPI> = await AuthorizedAPI.get(`kits/${kitId}`, {
        params: {
          include: 'items,items.service,owner',
        },
      })

      return convertKit(response.data)
    },
    {
      enabled: !!kitId,
      ...options,
    }
  )

  return {
    ...query,
    queryKey,
  }
}
