import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaSintegraFields = Record<
  | FieldName.CNPJ
  | FieldName.INSCRICAO_ESTADUAL
  | FieldName.NOME_PJ
  | FieldName.URL_UF,
  FieldConfig
>

class PesquisaSintegra extends Research {
  formFields: PesquisaSintegraFields = {
    cnpj: Fields.cnpj,
    inscricao_estadual: Fields.inscricao_estadual,
    nome_pj: Fields.nome_pj,
    url_uf: {
      ...Fields.url_uf,
      multiple: true,
    },
  }

  constructor() {
    super({
      description: 'Retorna a situação do cadastro junto à SEFAZ e o regime de tributação de ICMS.',
      id: 57,
      priceByFields: [FieldName.URL_UF],
      shortName: 'Pesquisa Sintegra',
    })
  }
}

const service = new PesquisaSintegra().initialize()

export default service
