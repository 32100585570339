import React from 'react'

import { Icon } from 'ui'

import Message from '../../domain/app/components/MessageWithHelpLine'

interface Props {
  title: React.ReactNode
  children: React.ReactNode
}

function SuccessMessage({ title, children }: Props) {
  return (
    <Message title={title} icon={<Icon name="checkmark-circle" size={32} className="text-green-500 text-6xl mb-6" />}>
      {children}
    </Message>
  )
}

export default SuccessMessage
