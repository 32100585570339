import React, { useMemo, useState } from 'react'
import { Popconfirm, Table } from 'antd'
import { Link } from 'react-router-dom'

import { Routes } from 'app/routes'
import { MassSelection } from 'domain/app/types'
import { Recurrence, RecurrenceItem } from 'domain/recurrences/types'
import { Icon } from 'ui'

import useDeleteRecurrenceItem from '../../services/useDeleteRecurrenceItems'
import RecurrenceItemsMassActions from './RecurrenceItemsMassActions'

interface Props {
  recurrence: Recurrence
}

function RecurrenceItems({ recurrence }: Props) {
  const [selected, setSelected] = useState(initialState)
  const { mutate } = useDeleteRecurrenceItem(recurrence.id)

  const rowSelection = {
    selectedRowKeys: selected.keys,
    hideSelectAll: false,
    preserveSelectedRowKeys: true,
    columnWidth: 36,
    onChange: (keys: React.Key[], rows: RecurrenceItem[]) => {
      setSelected({ keys, rows })
    },
  }

  const columns: any = useMemo(() => {
    const isDeletable = recurrence.items.length > 1

    return [
      {
        key: 'document',
        title: 'Documento',
        dataIndex: ['orderItem', 'name'],
        sorter: true,
        render: (itemName: string, item: RecurrenceItem) => (
          <Link title="Ver pedido" target="_blank" to={Routes.ViewOrder(item.orderItem.id)}>
            {itemName}
          </Link>
        ),
      },
      {
        key: 'action',
        title: 'Ação',
        dataIndex: 'id',
        className: 'text-center',
        render: (id: number) => {
          if (!isDeletable) {
            return null
          }

          return (
            <Popconfirm
              title="Tem certeza que deseja remover o item da Recorrência?"
              onConfirm={() => mutate(id)}
              okText="Remover"
              cancelText="Cancelar"
              placement="topLeft"
            >
              <a href="#" title="Remover item da Recorrência">
                <Icon size={20} name="trash" />
              </a>
            </Popconfirm>
          )
        },
      },
    ]
  }, [recurrence.items])

  return (
    <>
      <Table
        dataSource={recurrence.items}
        columns={columns}
        rowKey={record => record.id}
        rowSelection={rowSelection}
        rowClassName="bg-slate-50 p-2"
      />

      <RecurrenceItemsMassActions
        recurrenceId={recurrence.id}
        selected={selected.rows}
        onClose={() => setSelected(initialState)}
      />
    </>
  )
}

const initialState: MassSelection<RecurrenceItem> = { keys: [], rows: [] }

export default RecurrenceItems
