import React, { useState } from 'react'
import { Form } from 'antd'

import { errorHandler } from 'api/errors'
import { AuthorizedAPI } from 'api/network/v2'
import { useCurrentUser } from 'domain/auth'
import { PasswordInput, SubmitButton } from 'ui'

interface Props {
  setPasswordChanged: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ChangePasswordModalForm({ setPasswordChanged }: Props) {
  const [loading, setLoading] = useState(false)
  const user = useCurrentUser()

  const onSubmit = async (form: any) => {
    try {
      setLoading(true)
      await AuthorizedAPI.patch(`/users/${user.id}/password`, form)
      setPasswordChanged(true)
    } catch (error) {
      errorHandler(error, { code: '0x092' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form layout="vertical" onFinish={onSubmit} requiredMark={false}>
      <PasswordInput name="current_password" label="Senha atual" required autoFocus />
      <PasswordInput name="new_password" label="Nova senha" required />

      <PasswordInput
        label="Confirmar nova senha"
        name="confirm_new_password"
        dependencies={['new_password']}
        rules={confirmPasswordRule}
        required
      />

      <SubmitButton label="Alterar senha" loading={loading} />
    </Form>
  )
}

const confirmPasswordRule = [
  {
    required: true,
    message: 'Confirme sua senha',
  },
  ({ getFieldValue }: any) => ({
    validator(_: any, value: string) {
      if (!value || getFieldValue('new_password') === value) {
        return Promise.resolve()
      }
      return Promise.reject('A confirmação de senha deve ser igual a nova senha')
    },
  }),
]
