import { useDispatch } from 'react-redux'

import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs, createOrderActions, useLoadExtraInfos } from 'domain/createOrder/document'
import { AllExtraInfos } from 'domain/orderItem/types'
import { MaskedField, SelectField } from 'domain/servicesCBRdoc/fields'
import { CNPJInput, CPFInput } from 'ui'

import config from '../certidaoCCIRConfig'

export default function CreateOrderCertidaoCCIR() {
  const { data, isFetching } = useLoadExtraInfos()

  return (
    <CertificateFlow
      renderFormItem={(itemId: number) => (
        <CertidaoCCIRForm itemId={itemId} extraInfos={data} loading={isFetching} />
      )}
    />
  )
}

interface Props {
  itemId: number
  loading: boolean
  extraInfos: AllExtraInfos | undefined
}

function CertidaoCCIRForm({ itemId, extraInfos, loading }: Props) {
  const dispatch = useDispatch()
  const inputProps = { itemId, autoFocus: itemId === 0 }

  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={<CPFInput {...inputProps} />}
        juridica={
          <>
            <CNPJInput {...inputProps} />
            <SelectField
              itemId={itemId}
              field={natureza_juridica}
              extraInfos={extraInfos}
              onChange={(value: any) => {
                dispatch(createOrderActions.setMultipleItemsType({ id: itemId, value }))
              }}
              loading={loading}
            />
          </>
        }
      />

      <MaskedField field={codigo_imovel} itemId={itemId} />
    </>
  )
}

const { codigo_imovel, natureza_juridica } = config.formFields
