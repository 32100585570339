import { useMsal } from '@azure/msal-react'
import axios from 'axios'

import { getStorageData, removeStorageItem } from 'utils/localStorage'
import { useCurrentUser, useRemoveAuth } from '../state'

function useLogout() {
  const user = useCurrentUser()
  const removeAuth = useRemoveAuth()
  const { instance: msalInstance } = useMsal()

  return async () => {
    const sso = getStorageData('sso')

    if (sso) {
      if (sso.token) {
        if (sso.provider === 'google') {
          await revokeGoogleAccess(user.accessToken ?? sso.token)
        } else if (sso.provider === 'microsoft') {
          // @ts-ignore
          msalInstance.logoutPopup() 
        }
      }
      removeStorageItem('sso')
    }

    removeStorageItem('auth')
    removeAuth()
  }
}

function revokeGoogleAccess(token: string) {
  return axios
    .post('https://oauth2.googleapis.com/revoke', {
      token,
    })
    .catch(error => console.log(error))
}

export default useLogout
