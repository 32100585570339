import { useDownload } from 'utils/export'
import { useRefreshOverviewQuery } from '../state/order'
import { message } from 'antd'
import { OrderOverview } from '../types'

interface UseDownloadOrderItemsParams {
  downloadUrl: string
  order: OrderOverview
}

export function useDownloadOrderItems({ downloadUrl, order }: UseDownloadOrderItemsParams) {
  const [handleDownload, loading] = useDownload()
  const refreshQuery = useRefreshOverviewQuery()

  const onClick = async () => {
    const hide = message.loading('Preparando arquivos..')
    await handleDownload(downloadUrl, `cbrdoc-pedido-${order.orderNumber}`)
    refreshQuery()
    hide()
  }

  return { onClick, loading }
}

interface UseFirstDownloadOrderItemsParams {
  order: OrderOverview
}

export function useFirstDownloadOrderItems({ order }: UseFirstDownloadOrderItemsParams) {
  const downloadableItems = order.downloadableItemsIds.length
  const notDownloadedItemsIds = order.downloadableItemsIds.filter(
    id => !order.downloadedItemsIds.includes(id)
  )
  const notDownloadedItems = notDownloadedItemsIds.length

  const { onClick } = useDownloadOrderItems({
    downloadUrl: `orders/${notDownloadedItemsIds.join(',')}/download`,
    order,
  })

  return { downloadableItems, notDownloadedItems, onClick }
}
