import { useQuery } from 'react-query'

import { errorHandler } from 'api/errors'
import { getPriceShippingInfo } from 'domain/createOrder/shared/api'
import { useCreateOrderState } from 'domain/createOrder/document/state'
import { Extras } from 'domain/createOrder/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { Certificate } from 'domain/servicesCBRdoc/config'
import { checkIfObjectHasAllValues, checkParams } from 'utils/validators'

interface Params {
  itemId?: number
  origin?: string
  options?: any
}

export default function useServicePriceQuery(queryParams: Params = {}) {
  const { itemId, origin, options } = queryParams
  const createOrderState = useCreateOrderState()
  const { selectedExtras, selectedService } = createOrderState
  const { getPriceParams, getAvailableFormatsParams } = getServiceConfig<Certificate>(
    createOrderState.selectedService.code!
  )
  const params = getPriceParams(createOrderState, itemId)
  const formatsParams = getAvailableFormatsParams ? getAvailableFormatsParams(createOrderState, itemId) : null
  const enabled =
    validateExtras(selectedExtras) &&
    checkParams(selectedService, params) &&
    (formatsParams === null || checkIfObjectHasAllValues(formatsParams))

  return useQuery(
    ['price', params, selectedExtras],
    () => {
      return getPriceShippingInfo({
        origin: origin ?? 'simple',
        service: selectedService,
        params,
        extras: selectedExtras,
      })
    },
    {
      enabled,
      ...options,
      onError: errorHandler,
    }
  )
}

function validateExtras(extras?: Extras) {
  if (extras?.traducao_juramentada && extras.apostilamento) {
    return !!extras.tipo_apostilamento_com_traducao
  }

  return true
}
