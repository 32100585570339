import React from 'react'

import { TotalPriceForCustomer } from 'domain/createOrder/shared/components'
import { useServicePriceQuery } from '../services'

function OrderPrice() {
  const { data, isFetching } = useServicePriceQuery()

  return <TotalPriceForCustomer loading={isFetching} prices={data} />
}

export default OrderPrice
