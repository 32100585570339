import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { CREA, TIPO } = FieldName

type CertidaoRAISFields = Record<
  FieldName.ANO_BASE | FieldName.CAEPF | FieldName.CEI | FieldName.CNPJ | FieldName.CREA | FieldName.TIPO,
  FieldConfig
>

class CertidaoRAIS extends Certificate {
  formFields: CertidaoRAISFields = {
    ano_base: {
      ...Fields.ano_base,
      label: 'Ano',
      optional: false,
    },
    crea: {
      ...Fields.crea,
      optional: false,
    },
    tipo: {
      ...Fields.tipo,
      defaultValue: 'cnpj',
      options: [
        {
          value: 'cnpj',
          label: 'CNPJ',
        },
        {
          value: 'cei-cno',
          label: 'CEI/CNO',
        },
        {
          value: 'caepf',
          label: 'CAEPF',
        },
      ],
      apiOptionsLabelKey: 'label',
      apiOptionsValueKey: 'value',
    },
    cnpj: {
      ...Fields.cnpj,
      showIf: ({ tipo }) => tipo === 'cnpj',
    },
    caepf: {
      ...Fields.caepf,
      showIf: ({ tipo }) => tipo === 'caepf',
    },
    cei: {
      ...Fields.cei,
      optional: false,
      apiName: 'cei_cno',
      showIf: ({ tipo }) => tipo === 'cei-cno',
    },
  }

  constructor() {
    super({
      id: 71,
      orderNameField: CREA,
      priceByFields: [TIPO],
      shortName: 'Certidão RAIS',
      steps: ['dados'],
    })
  }
}

const service = new CertidaoRAIS().initialize()

export default service
