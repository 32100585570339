import React from 'react'

import { useGetExplorerItem } from 'domain/myFiles/state'
import { OpenAIEnrichment, SelectServiceId } from 'domain/openAI/enrich'

export default function MyFileOpenAI() {
  const explorerItem = useGetExplorerItem()

  if (!explorerItem.id) return null

  if (!explorerItem.serviceId) {
    return <SelectServiceId />
  }

  return (
    <OpenAIEnrichment
      fileUrl={explorerItem?.file?.signedUrl}
      ocr={explorerItem.ocr}
      serviceId={explorerItem.serviceId}
    />
  )
}
