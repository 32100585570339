import React from 'react'

import { ShoppingCartOrder } from 'domain/shoppingCart/types'
import { ListViewOptionsType } from 'ui'
import { getLabel } from 'utils/transformData'

interface Props {
  item: ShoppingCartOrder
  changeViewMode?: (mode: ListViewOptionsType) => void
}

function CartItemNotaries({ item, changeViewMode }: Props) {
  const notaries = getLabel(item.selectedLocation.url_cartorio, true) as string[]

  if (notaries.length === 0) return null

  const text = notaries.length > 1 ? `${notaries.length} emissores` : notaries[0]

  return (
    <div
      title={notaries.join('\n')}
      onClick={() => {
        if (notaries.length > 1) {
          changeViewMode && changeViewMode('card')
        }
      }}
      className="truncate max-w-56"
    >
      {text}
    </div>
  )
}

export default CartItemNotaries
