import React from 'react'

import { StepsByPath } from 'domain/app'
import { AIShoppingStep } from 'domain/createOrder/types'

function AIOrderSteps() {
  return <StepsByPath steps={STEPS} />
}

const STEPS: Record<AIShoppingStep, string> = {
  modelo: 'Modelo',
  dados: 'Dados',
  arquivos: 'Arquivos',
}

export default AIOrderSteps
