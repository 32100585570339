import { useOrdersOwnerFilterQuery } from 'domain/orders/services'
import { SelectMultiple } from 'ui'

export default function OrdersOwnerFilter() {
  const { data: owners } = useOrdersOwnerFilterQuery()

  return (
    <SelectMultiple
      name="ownersIds"
      label="Responsável"
      placeholder="Selecione o responsável"
      options={owners || []}
      size="middle"
      optionLabelKey="name"
      optionValueKey="id"
    />
  )
}
