import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoCEATFields = Record<
  | FieldName.CNPJ
  | FieldName.CPF
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.REGIAO
  | FieldName.MODELO,
  FieldConfig
>

const { TIPO_PESSOA, REGIAO } = FieldName

class CertidaoCEAT extends Certificate {
  formFields: CertidaoCEATFields = {
    regiao: {
      ...Fields.regiao,
      apiExtraInfo: 'regioes',
      apiOptionsLabelKey: 'nome',
      apiOptionsValueKey: 'url',
      stateKey: 'selectedRegion',
    },
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
    modelo: {
      ...Fields.modelo,
      ignoreOnKitsAutomaticForm: true,
      vertical: true,
    },
  }

  constructor() {
    super({
      availableFormatsFields: [REGIAO],
      id: 25,
      priceByFields: [REGIAO, TIPO_PESSOA],
      shoudAutoLoadDynamicFields: true,
      shortName: 'CEAT',
      steps: ['dados', 'entrega'],
    })
  }

}

const service = new CertidaoCEAT().initialize()

export default service
