import { FormInstance } from 'antd'

import { initialFilters, useClearMyFilesFilters } from 'domain/myFiles/state'
import { ClearFiltersButton } from 'ui'

type Props = { closeModal: () => void; form: FormInstance }

export default function MyFilesFilterClearButton({ closeModal, form }: Props) {
  const clearFilters = useClearMyFilesFilters()

  return (
    <ClearFiltersButton
      onClick={() => {
        clearFilters()
        form.setFieldsValue(initialFilters)
        closeModal()
      }}
    />
  )
}
