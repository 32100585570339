import React from 'react'
import { Radio } from 'antd'
import { RadioProps } from 'antd/lib/radio'

interface Props extends Omit<RadioProps, 'children'> {
  label: string
  description?: string
  short?: boolean
}

export default function RadioMultiline({ label, description, short, ...props }: Props) {
  return (
    <div className="mb-4">
      <Radio data-hj-allow {...props}>
        {description ? <p className="text-secondary-500 mb-1">{label}</p> : label}
        {description && <p className={`mb-1 text-xs text-gray-500 ${short ? 'lg:w-64' : ''}`}>{description}</p>}
      </Radio>
    </div>
  )
}
