import React, { useEffect, useState } from 'react'
import Cards, { Focused } from 'react-credit-cards'
import { Form } from 'antd'

import { errorHandler } from 'api/errors'
import {
  Alert,
  CPFOrCNPJInput,
  CreditCardCCVInput,
  CreditCardInput,
  Icon,
  SubmitButton,
  SuccessModalMessage,
  TextInput,
} from 'ui'
import { formatMoney } from 'utils/formatters'

import CreditCardExpiryDate from './CreditCardExpiryDate'
import { usePayWithCreditCard } from '../services'
import { CreditCardPayment } from '../services/usePayWithCreditCard'
import { ChosenPaymentMethod, CreditCardData } from '../types'

interface Props extends CreditCardPayment {
  onSuccess?: (method: ChosenPaymentMethod) => void
  onRequestClose: () => void
}

function CreditCardForm({ onSuccess, onRequestClose, isAddingCredits, ...params }: Props) {
  const [form] = Form.useForm()
  const [paymentReturnData, setPaymentReturnData] = useState({} as any)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [cardBrand, setCardBrand] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [cardCVV, setCardCVV] = useState('')
  const [cardExpiry, setCardExpiry] = useState('')
  const [cardName, setCardName] = useState('')
  const [cardFocus, setCardFocus] = useState<Focused>('number')

  const payWithCreditCard = usePayWithCreditCard()

  const onFinish = async (creditCardData: CreditCardData) => {
    setLoading(true)

    try {
      const data = await payWithCreditCard(creditCardData, { isAddingCredits, ...params })

      if (data) {
        setPaymentReturnData(data)
        setPaymentSuccess(true)
        
        onSuccess && onSuccess(data)
      }
    } catch (error) {
      setPaymentSuccess(false)
      errorHandler(error, { code: '0x802' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(
    () => () => {
      setPaymentSuccess(false)
      setPaymentReturnData({})
    },
    []
  )

  if (paymentSuccess && isAddingCredits) {
    return (
      <SuccessModalMessage title="Compra de créditos realizada com sucesso" onClick={onRequestClose}>
        {paymentReturnData && paymentReturnData.invoice_bonus_amount > 0 && (
          <Alert
            type="success"
            icon={<Icon name="award" />}
            className="max-w-md"
            message={
              <>
                Oba! Você ganhou{' '}
                <span className="text-success-500">
                  {formatMoney(paymentReturnData.invoice_bonus_amount)}
                </span>{' '}
                de bônus para utilizar os serviços da CBRdoc.
              </>
            }
          />
        )}
      </SuccessModalMessage>
    )
  }

  return (
    <Form form={form} onFinish={onFinish} layout="vertical" requiredMark={false} scrollToFirstError>
      <Cards
        cvc={cardCVV}
        expiry={cardExpiry}
        focused={cardFocus}
        name={cardName}
        number={cardNumber.replace(/-/g, '')}
        locale={{ valid: 'Valido até' }}
        placeholders={{ name: 'NOME DO TITULAR' }}
        callback={({ issuer }) => {
          setCardBrand(issuer)
        }}
      />

      <CreditCardInput
        value={cardNumber}
        onChange={e => setCardNumber(e.target.value.replaceAll('_', '').trim())}
        onFocus={() => setCardFocus('number')}
        isAmex={cardBrand === 'amex'}
        className="mt-6"
        autoFocus
      />

      <TextInput
        label="Nome do titular"
        name="holderName"
        value={cardName}
        onChange={e => setCardName(e.target.value)}
        onFocus={() => setCardFocus('name')}
        size="middle"
        placeholder="Exatamente como impresso no cartão de crédito"
        required
      />

      <div className="flex flex-row gap-3">
        <CreditCardExpiryDate 
          cardExpiry={cardExpiry} 
          setCardExpiry={setCardExpiry}
          setCardFocus={setCardFocus}
        />

        <CreditCardCCVInput
          name="cvv"
          size="middle"
          isAmex={cardBrand === 'amex'}
          value={cardCVV}
          onChange={e => setCardCVV(e.target.value)}
          onFocus={() => setCardFocus('expiry')}
        />
      </div>

      <CPFOrCNPJInput name="documentNumber" size="middle" onFocus={() => setCardFocus('number')} required />

      <SubmitButton label="Pagar" marginTop="mt-3" loading={loading} />

      <div className="flex justify-end">
        <img src={require('assets/images/image-site-blindado.png')} alt="Site blindado" className="w-56" />
      </div>
    </Form>
  )
}

export default CreditCardForm
