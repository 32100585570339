import React from 'react'

import { Alert, Boleto, Icon, PixViewData } from 'ui'
import { ChosenPaymentMethod } from '../types'
import BonusAlert from './BonusAlert'

interface Props {
  payment: ChosenPaymentMethod
}

export default function ChosenPaymentMethodDetails({ payment }: Props) {
  if (payment.chosenMethod === 'credit-card') {
    const hasBonus = Boolean(payment.bonusAmount)

    if (hasBonus) {
      return <BonusAlert bonusAmount={payment.bonusAmount!} isGranted />
    }

    return null
  }

  if (payment.chosenMethod === 'pix') {
    const { pix } = payment

    return (
      <PaymentDetails
        message="Você tem 30 minutos para efetuar o pagamento. Após este prazo esta chave PIX será cancelada."
        bonusAmount={payment.bonusAmount}
      >
        {pix && <PixViewData qrCode={pix!.qrCode!} pixKey={pix!.pixKey} />}
      </PaymentDetails>
    )
  }

  const { invoice } = payment

  return (
    <PaymentDetails
      header={
        <p className="pt-5 text-center">
          Caso ainda não tenha realizado o pagamento do boleto, efetue agora mesmo para darmos andamento ao
          seu pedido.
        </p>
      }
      message="Após o pagamento do boleto, seu crédito pode levar até 3 dias úteis para ser liberado."
      bonusAmount={payment.bonusAmount}
    >
      <Boleto
        barcode={invoice!.bankSlipBarcode}
        url={invoice!.bankSliptUrl}
        className="bg-white"
      />
    </PaymentDetails>
  )
}

interface PaymentDetailsProps {
  message: string
  header?: React.ReactNode
  children: React.ReactNode
  bonusAmount?: number
}

function PaymentDetails({ message, children, bonusAmount, header }: PaymentDetailsProps) {
  return (
    <div className="border-t border-gray-300 max-w-md mx-auto">
      {header}

      <div className="max-w-sm mx-auto">
        <Alert
          type="error"
          icon={<Icon name="warning" />}
          className="my-5 mb-6 text-gray-700"
          message={message}
        />
        {children}
      </div>

      {Boolean(bonusAmount) && <BonusAlert bonusAmount={bonusAmount!} />}
    </div>
  )
}
