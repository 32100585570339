import React, { useState } from 'react'
import { Form } from 'antd'

import {
  useGetKit,
  useGetLegalEntity,
  useGetServiceForm,
  useGetServicesForm,
  useServiceForm,
  useUpdateServiceForm,
} from 'domain/createOrder/kit/state'
import { KitItem } from 'domain/kits/types'
import { LocationFields } from 'domain/servicesCBRdoc/fields'
import { CertificateCode, DiligenceCode, SelectedLocation } from 'domain/servicesCBRdoc/types'
import { extractValue } from 'utils/transformData'
import LocationInput from 'domain/createOrder/dueDiligence/components/LocationInput'

interface Props {
  kitItem: KitItem
  autoFocus: boolean
  required?: boolean
}

function KitOrderLocationFields({ kitItem, autoFocus, required = true }: Props) {
  const { id } = kitItem
  const legalEntity = useGetLegalEntity()
  const { tipo, url_uf, url_cartorio, url_cidade } = useGetServiceForm(id)
  const updateForm = useUpdateServiceForm(id)
  const form = Form.useFormInstance()
  const { checkSameLocation, warnings } = useCheckSameLocation()
  const [, updateTjForm] = useServiceForm(id)

  const currentLocation = { url_uf, url_cartorio, url_cidade }

  const setType = (tipo: string) => {
    form.setFieldValue([id, 'tipo'], tipo)
    updateForm({ tipo })
  }

  const updateLocation = (selectedLocation: SelectedLocation) => {
    form.setFieldValue([id, 'todos_cartorios_cidade'], selectedLocation.todos_cartorios_cidade)
    form.setFieldValue([id, 'url_cartorio'], selectedLocation.url_cartorio)

    if (kitItem.service.code === CertificateCode.CERTIDAO_JUSTICA_ESTADUAL) {
      updateTjForm({ modelo: null, instancia: null })

      form.setFieldValue([id, 'modelo'], undefined)
      form.setFieldValue([id, 'instancia'], undefined)
    }

    updateForm(selectedLocation)

    checkSameLocation({
      selectedLocation,
      kitItem,
    })
  }

  const hasLocationInput =  Object.values(DiligenceCode).includes(kitItem.service.code as DiligenceCode)

  return (
    <>
      <LocationFields
        itemId={id}
        legalEntity={legalEntity}
        selectedLocation={currentLocation}
        selectedService={kitItem.service}
        selectedType={tipo}
        onChange={updateLocation}
        onChangeType={setType}
        autoFocus={autoFocus}
        warnings={warnings}
        required={required}
      />

      {hasLocationInput && <LocationInput name={[id, 'local_servico']} />}
    </>
  )
}

type CheckSameLocationFn = (params: { selectedLocation: SelectedLocation; kitItem: KitItem }) => void

type SameLocationWarnings = Partial<Record<keyof SelectedLocation, string>>

function useCheckSameLocation() {
  const kit = useGetKit()
  const servicesFormData = useGetServicesForm()
  const [warnings, setWarning] = useState<SameLocationWarnings>({})

  const checkSameLocation: CheckSameLocationFn = ({ selectedLocation, kitItem }) => {
    const { url_cartorio, url_cidade, url_uf } = selectedLocation

    const currentNotaryUrl = extractValue(url_cartorio, 'asArray').join(',')
    const currentCityUrl = extractValue(url_cidade, 'asArray').join(',')
    const currentUf = extractValue(url_uf, 'asArray').join(',')

    setWarning({})

    if (url_cartorio || url_cidade || url_uf) {
      const itemsWithSameService = kit.items.filter(
        item => item.id !== kitItem.id && item.service.id === kitItem.service.id
      )

      if (itemsWithSameService.length > 0) {
        itemsWithSameService.some(item => {
          const formData = servicesFormData[item.id] ?? {}

          if (currentNotaryUrl && formData.url_cartorio) {
            const urls = extractValue(formData.url_cartorio, 'asArray').join(',')

            if (currentNotaryUrl === urls) {
              setWarning({ url_cartorio: 'Um documento com o mesmo cartório já foi selecionado.' })
            }

            return true
          }

          if (currentCityUrl && formData.url_cidade) {
            const urls = extractValue(formData.url_cidade, 'asArray').join(',')

            if (currentCityUrl === urls) {
              setWarning({ url_cidade: 'Um documento com a mesma cidade já foi selecionado.' })
            }

            return true
          }

          if (currentUf && formData.url_uf) {
            const urls = extractValue(formData.url_uf, 'asArray').join(',')

            if (currentUf === urls) {
              setWarning({ url_uf: 'Um documento com o mesmo Estado já foi selecionado.' })
            }

            return true
          }
        })
      }
    }
  }

  return { checkSameLocation, warnings }
}

export default React.memo(KitOrderLocationFields)
