import React from 'react'
import saveAs from 'file-saver'

import ExportButton from 'domain/invoices/components/ExportButton'
import generateFileName from 'domain/invoices/helpers/generateFileName'
import { useGetSelectedMonth } from 'domain/invoices/state'

import generatePrepaidExcel from '../helpers/generatePrepaidExcel'
import extractDataToExport from '../helpers/extractDateToExport'
import getPrepaidInvoicesReport from '../services/getPrepaidInvoicesReport'

function ExportPrepaidInvoicesToExcel() {
  const selectedMonth = useGetSelectedMonth()

  return (
    <ExportButton
      kind="excel"
      fetchData={async () => {
        const year = selectedMonth.getFullYear()
        const month = selectedMonth.getMonth()
        const res = await getPrepaidInvoicesReport(year, month)

        if (res) {
          const excelData = extractDataToExport(res)
          const buffer = await generatePrepaidExcel(excelData)
          const fileName = generateFileName('xlsx', selectedMonth)

          saveAs(new Blob([buffer as Buffer]), fileName)
        }
      }}
    />
  )
}

export default ExportPrepaidInvoicesToExcel
