import { useRef } from 'react'
import { Spin, Tag } from 'antd'
import { useHistory, useParams } from 'react-router-dom'

import { FreeAnalysisPopup } from 'domain/openAI/enrich'
import { OrderOverview as OrderOverviewType } from 'domain/orders/types'
import { OrderOverviewPageParams } from 'domain/orders/types'
import { useOrderItemsQuery } from 'domain/orders/services'
import { useGetOverviewFilters } from 'domain/orders/state/filtersOverview'
import { useGetOverviewTotalResults } from 'domain/orders/state/pagination'
import { useGetIsRedirecting } from 'domain/orders/state/redirecting'
import { BasicLayout, GoBackButton } from 'layouts'
import { removeFalsyEntries } from 'utils/transformData'

import useOverviewInitialValuesFromURL from '../../state/useOverviewInitialValuesFromURL'
import AlertNoOrdersWithSelectedFilters from '../AlertNoOrdersWithSelectedFilters'
import OrderOverviewFilters from './filters/OrderOverviewFilters'
import OrderPriceQuotation from './actions/OrderPriceQuotation'
import OrderItemsList from './OrderOverviewList'
import OrderItemsPagination from '../orderOverview/OrderOverviewPagination'
import OrderMassActions from './actions/MassActions'
import DownloadActions from './actions/DownloadActions'
import { singularOrPlural } from 'utils/formatters'
import OrderOverviewDetails from './OrderOverviewDetails'

interface Props {
  order: OrderOverviewType
}

export default function OrderOverview({ order }: Props) {
  const topRef = useRef<HTMLDivElement>(null)
  const isRedirecting = useGetIsRedirecting()
  const filters = useGetOverviewFilters()
  const params = useParams<OrderOverviewPageParams>()
  const hasFilterApplied = removeFalsyEntries(filters).length > 0
  const overviewTotalResults = useGetOverviewTotalResults()
  const history = useHistory()

  useOverviewInitialValuesFromURL()

  const { data: orderItems, isFetching, isLoading } = useOrderItemsQuery(params.id)

  return (
    <BasicLayout
      subTitle={
        <div className="flex items-center gap-1">
          <GoBackButton hideLabel onClick={() => history.push('/pedidos')} />
          <p>Meus pedidos /</p>
          <p className="font-semibold">Nº do Pedido: {order.orderNumber}</p>
        </div>
      }
      className="bg-gray-100"
      innerClass="flex-col bg-gray-100"
    >
      <div ref={topRef} />

      <div className="flex item-center justify-between pb-8 px-7">
        <div className="flex items-center gap-6">
          <p className="text-lg font-bold text-ellipsis max-w-lg overflow-hidden text-nowrap">{order.name}</p>
          <Tag
            bordered={false}
            className="bg-secondary-300 text-white px-4 py-1 rounded-lg text-2xs leading-3"
          >
            {singularOrPlural(overviewTotalResults, 'Item', 'Itens')}
          </Tag>
        </div>
        {!!orderItems?.length && <OrderMassActions order={order} orderItems={orderItems} />}
      </div>

      <OrderOverviewDetails order={order} orderItems={orderItems || []} />

      <main className="p-7 flex-1">
        <Spin spinning={isFetching || isRedirecting}>
          <div className="bg-white rounded">
            <div className="flex items-center justify-between px-6 py-4">
              <OrderOverviewFilters orderType={order.type} className="px-0" />
              <DownloadActions order={order} />
            </div>
            {overviewTotalResults === 0 && hasFilterApplied ? (
              <AlertNoOrdersWithSelectedFilters />
            ) : (
              <OrderItemsList items={orderItems!} isLoading={isLoading} topRef={topRef} />
            )}
          </div>
          <OrderItemsPagination topRef={topRef} />
        </Spin>

        <OrderPriceQuotation order={order} />
      </main>
      {order.hasConsumedAiFreeAnalysis && <FreeAnalysisPopup position="top" />}
    </BasicLayout>
  )
}
