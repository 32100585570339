import { OCRDocument } from 'domain/myFiles/types'
import { OCRDocumentAPI } from 'domain/myFiles/typesAPI'

export default function convertOCR(ocr: OCRDocumentAPI | null): OCRDocument | null {
  if (!ocr) return null

  const { ai_enrich_data_available, id, pages, document_name } = ocr

  return {
    id,
    aiEnrichmentAvailability: ai_enrich_data_available,
    pages: pages?.map(page => ({
      id: page.id,
      pageNumber: page.page_no,
      content: page.content,
    })),
    totalPages: ocr.total_pages,
    documentName: document_name,
  }
}
