import { DatePicker, DatePickerProps, Form } from 'antd'

import { getFieldName } from 'domain/createOrder/shared'
import { FieldConfig } from '../types'

interface DatePickerFieldProps extends Omit<DatePickerProps, 'className'> {
  field: FieldConfig
  itemId?: number
}

export default function DatePickerField({
  field,
  itemId,
  required = !field.optional,
  ...props
}: DatePickerFieldProps) {
  const name = getFieldName(field.name, itemId)

  return (
    <Form.Item label={field.label} id={field.name} name={name} required={required}>
      <DatePicker
        className="w-full"
        format={format}
        picker="date"
        {...props}
      />
    </Form.Item>
  )
}

const format = {
  format: 'DD/MM/YYYY',
  type: 'mask',
} as const
