import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { errorHandler } from 'api/errors'
import { CreditSuggestion } from 'domain/credits'
import { Modal } from 'ui'
import { formatMoney } from 'utils/formatters'

import { getSimulation } from '../creditsAPI'
import { STATES_SIMULATOR } from '../data'
import { SimulationInputItem, SimulationOutput, SimulationType } from '../types'
import FormPriceSimulation from './FormPriceSimulation'

interface Props {
  open?: boolean
  onRequestClose?: () => void
  onAddValueClick: (value: string) => void
}

const INITIAL_INPUT_DATA: SimulationInputItem[] = [
  {
    id: 1,
    url_uf: null,
    data: [{ id: 1, service_id: null, quantity: 1 }],
  },
]

export default function PriceSimulator({ open, onRequestClose, onAddValueClick }: Props) {
  const [simulationType, setSimulationType] = useState<SimulationType>('simplified')
  const [suggestedValue, setSuggestedValue] = useState('0')
  const [simulationInput, setSimulationInput] = useState<SimulationInputItem[]>(INITIAL_INPUT_DATA)
  const [simulationOutput, setSimulationOutput] = useState<SimulationOutput>()

  const { isFetching } = useQuery(['simulator', simulationInput], () => getSimulation(simulationInput), {
    enabled: !!simulationInput.find(s => s.url_uf),
    onError: (error: any) => {
      errorHandler(error, { code: '0x863' })
    },
    onSuccess: data => {
      if (data && data.total_simulated_price) {
        setSimulationOutput(data)
        setSuggestedValue(data.total_simulated_price.toFixed(2))
      }
    },
  })

  const restartSimulation = () => {
    setSimulationInput(INITIAL_INPUT_DATA)
    setSimulationOutput(undefined)
  }

  return (
    <Modal
      open={open}
      title="Quanto devo recarregar?"
      onCancel={onRequestClose}
      footer={null}
      afterClose={restartSimulation}
    >
      <div className="pb-10">
        {!simulationOutput && (
          <FormPriceSimulation
            simulationType={simulationType}
            setSimulationType={setSimulationType}
            simulationInput={simulationInput}
            setSimulationInput={setSimulationInput}
            restartSimulation={restartSimulation}
            loading={isFetching}
          />
        )}

        {simulationOutput && (
          <>
            <div>
              {simulationOutput.detailed.map((details, idx) => (
                <div
                  key={idx}
                  className={`mb-3 ${
                    simulationType === 'simplified' ? '' : 'border border-gray-800 rounded-sm'
                  }`}
                >
                  {simulationType === 'simplified' ? (
                    <div className="mb-3">
                      Média de valores unitários para solicitação de documentos{' '}
                      <b>{STATES_SIMULATOR?.find(state => state.code === details.url_uf)?.name}</b>:
                    </div>
                  ) : (
                    <div className="p-4 bg-gray-300 uppercase">
                      <div>Média de valores unitários</div>
                      <b>{STATES_SIMULATOR?.find(state => state.code === details.url_uf)?.name}</b>
                    </div>
                  )}
                  <div className={`${simulationType === 'simplified' ? '' : 'p-4'}`}>
                    {details.data.map((data, idx) => (
                      <div
                        key={data.service.id}
                        className={`flex justify-between py-3 border-b border-gray-400 ${
                          simulationType === 'advanced' && details.data.length - 1 === idx
                            ? 'border-none'
                            : ''
                        }`}
                      >
                        <div className="text-gray-600 text-xs">{data.service.name}</div>
                        <div className="whitespace-no-wrap">
                          Entre <b>{formatMoney(data.prices.min)}</b> e <b>{formatMoney(data.prices.max)}</b>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            <CreditSuggestion
              suggestedValue={suggestedValue}
              totalDocuments={simulationOutput.total_simulated_quantity}
              onSuggestedValueChange={(e: any, value: any) => {
                setSuggestedValue(value)
              }}
              onAddValueClick={() => {
                onRequestClose && onRequestClose()
                onAddValueClick(suggestedValue)
              }}
              onRedoSimulationClick={restartSimulation}
            />
          </>
        )}
      </div>
    </Modal>
  )
}
