import React from 'react'

import { StepsByPath } from 'domain/app'
import { DueDiligenceStep } from 'domain/createOrder/dueDiligence/types'

function DueDiligenceSteps() {
  return <StepsByPath steps={STEPS_TITLE} />
}

const STEPS_TITLE: Record<DueDiligenceStep, string> = {
  localizacao: 'Localização',
  dados: 'Dados',
}

export default DueDiligenceSteps
