import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { message } from 'antd'

import { AuthorizedAPI } from 'api/network/v2'
import { handleRequestError } from 'api/HandleRequestError'
import queryClient from 'app/config/queryClient'
import { Group } from 'domain/groups/types'

function useAssociateOrderItemGroups(orderItemId: number) {
  const mutation = useMutation(
    async (groups: Group[]) => await associateOrderItemGroupsAPI(orderItemId, groups.map(o => o.id)),
    {
      onSuccess: (groups, [id]) => {
        queryClient.setQueryData(['order-item', id], (old: any) => ({
          ...old,
          groups,
        }))
        message.success('Grupos associados com sucesso.')
      },
      onError: error => {
        handleRequestError(error, { code: '0x708' })
      },
    }
  )

  return {
    associateGroups: mutation.mutate,
    loading: mutation.isLoading,
  }
}

const associateOrderItemGroupsAPI = async (id: number, groupsIds: number[]) => {
  const { data }: AxiosResponse<any> = await AuthorizedAPI.put(`orders/${id}/groups`, {
    groups_ids: groupsIds,
  })
  return data
}

export default useAssociateOrderItemGroups
