import React from 'react'
import { useHistory } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { useHasPermission } from 'domain/auth'
import { Button } from 'ui'

import AccountBalanceCredits from './AccountBalanceCredits'

interface Props {
  className?: string
  buttonPlacement?: 'right' | 'bottom'
}

const AccountBalance = ({ className, buttonPlacement }: Props) => {
  return (
    <div className={className}>
      <div className={`hidden md:flex ${buttonPlacement === 'bottom' ? 'flex-col w-full' : 'flex-row items-center'} `}>
        <AccountBalanceCredits />
        <InsertCreditsButton buttonPlacement={buttonPlacement} />
      </div>
    </div>
  )
}

function InsertCreditsButton({ buttonPlacement }: { buttonPlacement?: 'right' | 'bottom' }) {
  const canInsert = useHasPermission('canInsertCredits')
  const history = useHistory()

  if (!canInsert) {
    return null
  }

  return (
    <div
      className={`${
        buttonPlacement === 'bottom' ? 'mt-4 pt-4 border-t block' : 'ml-4 pl-4 border-l hidden sm:block'
      } border-gray-300`}
    >
      <Button
        type="link"
        icon="plus-circle"
        className="px-0"
        onClick={() => history.push(RoutePaths.CREDITS)}
      >
        Adicionar créditos
      </Button>
    </div>
  )
}

export default AccountBalance
