import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { APP_VERSION } from 'app/constants'

import {
  Extras,
  PriceShippingInfo,
  SelectedService,
} from 'domain/createOrder/types'
import { OrderItemDetails } from 'domain/orderItem/types'
import { ServiceType } from 'domain/servicesCBRdoc/types'

type GetShippingPriceParams = {
  origin: string
  service: SelectedService
  params: OrderItemDetails | null
  extras?: Extras | null
}

export default async function getPriceShippingInfo({
  origin,
  service,
  params,
  extras = null,
}: GetShippingPriceParams) {
  if (!service || !service.id || !service.categoryId) return null

  const format = params?.formato || 'email'
  const selectedExtras = service.type === ServiceType.DILIGENCE || format !== 'email' ? extras : {}

  const response: AxiosResponse<PriceShippingInfo> = await AuthorizedAPI.post(
    `/services/${service.id}/categories/${service.categoryId}/prices-shipping-info`,
    {
      detailed_service_data: {
        ...params,
        formato: format,
        origin: `${origin} v${APP_VERSION}`,
        ...selectedExtras,
      },
    }
  )
  return response.data
}
