import React from 'react'
import { Spin } from 'antd'

export interface InvoiceStat {
  credits: {
    sum: number
    count: number
  }
  debits: {
    sum: number
    count: number
  }
}

interface Props {
  loading: boolean
  children: React.ReactNode
}

const InvoicesSummaryContainer = ({ loading, children }: Props) => {
  return (
    <Spin spinning={loading}>
      <h3 className="text-gray-600 uppercase font-bold text-2xs pb-1 block">Resumo do mês</h3>

      <div className="flex flex-row gap-3 justify-between items-stretch w-full">{children}</div>
    </Spin>
  )
}

export default InvoicesSummaryContainer
