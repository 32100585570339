import { useMutation } from 'react-query'
import { message } from 'antd'
import { AxiosResponse } from 'axios'

import { errorHandler } from 'api/errors'
import { API } from 'api/network/v2'
import queryClient from 'app/config/queryClient'

import { CreateGroupParams, Group, GroupRegister } from '../types'

export default function useCreateGroups(onSuccess?: (newGroups: Group[]) => void) {
  const mutation = useMutation(async (newGroups: GroupRegister[]) => await createGroupsAPI(newGroups), {
    onSuccess: (newGroups: Group[]) => {
      queryClient.invalidateQueries(['groups', 'list'])
      message.success('Grupo adicionado com sucesso!')
      onSuccess && onSuccess(newGroups)
    },
    onError: (error: any) => {
      errorHandler(error, { code: '0x808' })
    },
  })

  const createGroups = (groups: GroupRegister[]) => mutation.mutate(groups)

  return { createGroups, loading: mutation.isLoading }
}

const createGroupsAPI = async (groups: GroupRegister[]) => {
  const params: CreateGroupParams = {
    groups,
  }

  const { data }: AxiosResponse<Group[]> = await API.post('/groups', params)
  return data
}
