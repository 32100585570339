import React, { useState } from 'react'
import { Collapse } from 'antd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import { OrderNameField } from 'domain/createOrder/document'
import { DeleteItem, useAutoFocusMultipleItems } from 'domain/createOrder/shared'
import { CreateOrderFormItem, SelectedService } from 'domain/createOrder/types'
import ItemsCollapseLabel from './ItemsCollapseLabel'
import ItemsCollapseDroppable from './ItemsCollapseDroppable'

interface Props {
  activeItem: number | undefined
  setActiveItem: React.Dispatch<React.SetStateAction<number | undefined>>
  items: CreateOrderFormItem[]
  service: SelectedService
  renderFormItem: (itemId: number) => JSX.Element
  noRenderOrderName?: boolean
}

export default function ItemsCollapse({
  activeItem,
  setActiveItem,
  items,
  renderFormItem,
  noRenderOrderName,
}: Props) {
  const [movingItemId, setMovingItemId] = useState<number | null>(null)

  useAutoFocusMultipleItems(activeItem)

  return (
    <DndProvider backend={HTML5Backend}>
      {items.map((item, index) => {
        const renderDroppable = (toIndex: number) => (
          <ItemsCollapseDroppable
            items={items}
            index={toIndex}
            movingItemId={movingItemId}
            setMovingItemId={setMovingItemId}
          />
        )

        return (
          <React.Fragment key={item.id}>
            {index === 0 && renderDroppable(0)}

            <Collapse
              accordion
              expandIconPosition="end"
              activeKey={activeItem}
              onChange={() => setActiveItem(activeItem === item.id ? undefined : item.id)}
              items={[
                {
                  key: item.id,
                  label: (
                    <ItemsCollapseLabel itemIndex={index} onMoveStart={() => setMovingItemId(item.id)} />
                  ),
                  children: (
                    <>
                      {renderFormItem(item.id)}
                      {!noRenderOrderName && <OrderNameField itemId={item.id} />}
                    </>
                  ),
                  extra: items.length > 1 ? <DeleteItem itemId={item.id} /> : undefined,
                },
              ]}
            />

            {renderDroppable(index + 1)}
          </React.Fragment>
        )
      })}
    </DndProvider>
  )
}
