import { ResultCode } from 'domain/orders/types'
import { SelectableButton } from 'ui'
import { useResultsFilter } from '../../state/filters'

type Props = { label: string; result: ResultCode }

export default function FilterByResultBtn({ label, result }: Props) {
  const [resultsFilter, setResultsFilter] = useResultsFilter()
  const isSelected = resultsFilter?.includes(result)

  return (
    <SelectableButton
      selected={isSelected}
      onClick={() => {
        if (isSelected) {
          setResultsFilter(resultsFilter.filter(s => s !== result))
        } else {
          setResultsFilter([...resultsFilter, result])
        }
      }}
    >
      {label}
    </SelectableButton>
  )
}
