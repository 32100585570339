import React, { useReducer, useState } from 'react'

import {
  CreditsAdvantages,
  CreditsFaq,
  CreditsOptions,
  CreditsPageHeader,
  PriceSimulator,
} from 'domain/credits'

function Credits() {
  const [creditAmountSelected, setCreditAmountSelected] = useState<number>(0)
  const [paymentModalVisible, setPaymentModalVisible] = useState(false)
  const [simulatorVisible, toggleSimulatorVisible] = useReducer(prev => !prev, false)
  
  return (
    <>
      <CreditsPageHeader onOpenSimulator={toggleSimulatorVisible} />

      <CreditsOptions
        creditAmountSelected={creditAmountSelected}
        setCreditAmountSelected={setCreditAmountSelected}
        paymentModalVisible={paymentModalVisible}
        setPaymentModalVisible={setPaymentModalVisible}
      />

      <PriceSimulator
        open={simulatorVisible}
        onRequestClose={toggleSimulatorVisible}
        onAddValueClick={value => {
          setCreditAmountSelected(parseFloat(value))
          setPaymentModalVisible(true)
        }}
      />

      <div className="bg-gray-100 flex flex-col lg:flex-row m-7 mt-0 lg:mt-7 lg:mb-10">
        <CreditsAdvantages />
        
        <div className="w-20 bg-gray-100" />
        <CreditsFaq onOpenSimulator={toggleSimulatorVisible} />
      </div>
    </>
  )
}

export default Credits
