import { useReducer } from 'react'

import { getStorageBoolean, setStorageBoolean } from 'utils/localStorage'
import { PERSIST_ORDER_FORM_DATA } from '../state'

export default function useReplicateCommonData() {
  const commonFormDataEnabled = getStorageBoolean(PERSIST_ORDER_FORM_DATA, false)
  const [enabled, toggleEnabled] = useReducer(prev => !prev, commonFormDataEnabled!)

  function toggleEnabledStorage() {
    setStorageBoolean(PERSIST_ORDER_FORM_DATA, !enabled)
    toggleEnabled()
  }

  return {
    enabled,
    toggleEnabled: toggleEnabledStorage,
  }
}
