import React from 'react'
import { Menu, message, Modal } from 'antd'

import deleteKit from 'domain/kits/services/deleteKit'
import { Kit } from 'domain/kits/types'
import { Icon } from 'ui'

import KitItems from '../viewKit/KitItems'

interface Props {
  kit: Kit
  refetch: () => void
}

function DeleteKitMenuItem({ kit, refetch }: Props) {
  return Modal.confirm({
    title: (
      <>
        Tem certeza que deseja excluir o kit <b>{kit.name}</b>?
      </>
    ),
    content: <KitItems items={kit.items} kitName={kit.name} deleteItems />,
    okText: 'Excluir',
    cancelText: 'Cancelar',
    onOk: async () => {
      await deleteKit(kit.id)
      message.success('O kit foi excluído.')
      refetch()
    },
  })
}

export default DeleteKitMenuItem
