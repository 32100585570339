import React from 'react'

import { useGetOrderItem } from 'domain/orderItem/shared'

import OCRComparison from './OCRComparison'

export default function ViewFileOCRContent() {
  const { fileURL, ocr } = useGetOrderItem()

  return (
    <OCRComparison pdfUrl={fileURL} ocr={ocr}/>
  )
}
