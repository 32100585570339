import { ServiceTabs } from 'domain/createOrder'
import { ServiceType } from 'domain/servicesCBRdoc/types'
import { GoBackButton, PageLayout } from 'layouts'
import { ActionsBar, LinkCard, Tag } from 'ui'

export default function CreateOrderRegistration() {
  return (
    <PageLayout pageTitle="Criar Registro">
      <ActionsBar>
        <GoBackButton />
      </ActionsBar>

      <ServiceTabs type={ServiceType.DOCUMENT_REGISTRATION}>
        <div className="px-7 2xl:max-w-screen-lg">
          <LinkCard
            icon="stamp"
            heading="Escritura - Lavratura em Tabelionato de Notas"
            disabled
            noChevron
            extra={
              <Tag color="secondary" rounded>
                Em breve
              </Tag>
            }
          />
        </div>
      </ServiceTabs>
    </PageLayout>
  )
}
