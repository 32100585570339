import { useMutation } from 'react-query'
import { message } from 'antd'

import { handleRequestError } from 'api/HandleRequestError'
import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'

function useRenameMutation(options: any = {}) {
  const mutation = useMutation(
    async (explorerItem: any) => await AuthorizedAPI.patch(`/explorer/${explorerItem.id}/name`, explorerItem),
    {
      onError: error => {
        handleRequestError(error, { code: '0x705' })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['my-files', 'list'])
        message.success('Item renomeado com sucesso.')
        options.onSuccess && options.onSuccess()
      },
    }
  )

  return {
    renameItem: mutation.mutate,
    loading: mutation.status === 'loading',
  }
}

export default useRenameMutation
