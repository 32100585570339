import { useMemo } from 'react'

import { CreateOrderFormItem } from 'domain/createOrder/types'
import { useCreateOrderState } from './createOrderSlice'

export const useGetSortableFormItems = () => {
  const { formItems } = useCreateOrderState()

  return useMemo(() => {
    return Object.values(formItems).sort((a, b) => a.itemOrder - b.itemOrder) as CreateOrderFormItem[]
  }, [formItems])
}
