import { Icon } from 'ui'
import { HelpLine } from 'domain/app'

import RejectedReason from '../details/RejectedReason'

function ViewOrderUnavailable() {

  return (
    <div className="pr-6 pl-8 flex flex-col flex-1 items-center bg-gray-100">
      <div className="w-full bg-white flex flex-col items-center rounded-lg gap-8 mb-4 py-14">
        <Icon name="warning-file"/>

        <h1 className="font-bold text-2xl text-center">
          Documento Indisponível
        </h1>

        <RejectedReason
          warning
          reason={
            "O sistema responsável pela emissão do seu documento encontra-se instável ou indisponível.\
            Estamos aguardando a normalização para envio do seu documento."
          }
        />      

        <HelpLine />
      </div>
    </div>
  )
}

export default ViewOrderUnavailable
