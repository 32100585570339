import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs, DynamicTypeSelect } from 'domain/createOrder/document'
import { CNPJAndNameFields, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../certidaoNegativaContasTCUConfig'

export default function CreateOrderCertidaoNegativaContasTCU() {
  return (
    <CertificateFlow
      header={<DynamicTypeSelect field={tipo} autoFocus />}
      renderFormItem={(itemId: number) => <CertidaoNegativaContasTCUForm itemId={itemId} />}
    />
  )
}

function CertidaoNegativaContasTCUForm({ itemId }: { itemId: number }) {
  return (
    <CreateOrderLegalEntityTabs
      itemId={itemId}
      fisica={
        <>
          <CPFInput itemId={itemId} />
          <NameField itemId={itemId} field={nome_pf} />
        </>
      }
      juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} />}
    />
  )
}

const { nome_pf, nome_pj, tipo } = config.formFields
