import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { message } from 'antd'

import { API } from 'api/network/v2'
import { useIsCustomerPostPaid } from 'domain/customers'
import { convertOrderItemListing } from 'domain/orderItem'
import { OrderItemAPI } from 'domain/orderItem/typesAPI'
import { getFilters } from 'utils/transformData'

import { checkOCRSearchError } from '../helpers'
import { useGetFilters } from '../state/filters'
import {
  useGetItemsSortBy,
  useSetItemsTotalPages,
  useSetItemsTotalResults,
  useGetItemsCurrentPage,
  useGetItemsPageSize,
} from '../state/pagination'

export default function useAllOrderItemsQuery() {
  const filters = useGetFilters()
  const setItemsTotalPages = useSetItemsTotalPages()
  const setItemsTotalResults = useSetItemsTotalResults()
  const itemsCurrentPage = useGetItemsCurrentPage()
  const itemsPageSize = useGetItemsPageSize()
  const itemsSortBy = useGetItemsSortBy()
  const isPostPaid = useIsCustomerPostPaid()
  const {
    ai,
    automaticGenerated,
    dateRange,
    isOCRSearch,
    isExpired,
    ownersIds,
    recurrenceId,
    results,
    searchTerm,
    servicesIds,
    statuses,
    groupsIds,
  } = filters
  const hasError = checkOCRSearchError(isOCRSearch, searchTerm)

  return useQuery(
    ['orders', 'all-items', filters, itemsCurrentPage, itemsPageSize, itemsSortBy],
    async () => {
      // Esses filtros não exibem itens de pedidos, apenas os pedidos. Por isso retornamos []
      if (automaticGenerated) {
        setItemsTotalPages(0)
        setItemsTotalResults(0)
        return []
      }

      const response: AxiosResponse<OrderItemAPI[]> = await API.get('/orders', {
        params: {
          append: [
            'ai_service_name',
            'has_ai_extracted_data',
            'has_ai_analysis_pending',
            'has_consumed_ai_free_analysis',
            'register',
            'is_expired',
          ],
          include: ['ocr', 'purchase.recurrence', 'service', 'service_category', 'user', 'active_challenge'],
          'per-page': itemsPageSize,
          sort: itemsSortBy,
          page: itemsCurrentPage,
          ...getFilters({
            ai: ai ? 'ai-yes' : 'any',
            group_id: groupsIds?.join(',') || '',
            name_or_id: !isOCRSearch ? searchTerm : undefined,
            ocr_content: isOCRSearch ? searchTerm : undefined,
            placed_between: dateRange,
            'purchase.recurrence_id': recurrenceId,
            result: results?.join(',') || '',
            service_id: servicesIds?.join(',') || '',
            status: statuses?.join(',') || '',
            user_id: ownersIds?.join(',') || '',
            expired: isExpired,
          }),
        },
      })

      setItemsTotalPages(Number(response.headers['x-pagination-page-count']))
      setItemsTotalResults(Number(response.headers['x-pagination-total-count']))

      const { data } = response

      if (data) {
        return data.map(item => convertOrderItemListing(item, isPostPaid!))
      }
    },
    {
      enabled: itemsCurrentPage !== undefined && !hasError && isPostPaid !== undefined,
      keepPreviousData: true,
      onError: (error: any) => {
        message.error(error.message)
      },
    }
  )
}
