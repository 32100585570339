import { useState } from 'react'
import { Form, FormInstance, message } from 'antd'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { errorHandler } from 'api/errors'
import { Routes } from 'app/routes'
import {
  createOrderActions,
  useAddCreateOrderStateToCart,
  useCreateOrderState,
} from 'domain/createOrder/document'
import { SignatureCertificationFlow } from 'domain/createOrder/signatureCertification'
import { SignatureCertificationCode } from 'domain/servicesCBRdoc/types'
import { getServiceConfig } from 'domain/servicesCBRdoc/products/services'
import { useAllServices } from 'domain/servicesCBRdoc/state/services'
import { Heading, SubmitButton } from 'ui'

import ColetaAssinaturasItems from './ColetaAssinaturaItems'

export default function CreateOrderColetaAssinaturas() {
  return (
    <SignatureCertificationFlow
      dataStep={<CollectSignaturesDataStep />}
      header="Inclua os assinadores do documento na ordem de assinatura. Todas as assinaturas são realizadas utilizando o padrão ICP-Brasil."
    />
  )
}

export type FormSingner = {
  nome: string
  cpf: string
  email: string
  certificado_necessario: boolean
  key: number
}

const CollectSignaturesDataStep = ({ formInstance }: { formInstance?: FormInstance }) => {
  const allServices = useAllServices()
  const form = formInstance ?? Form.useForm()[0]
  const dispatch = useDispatch()
  const [status, setStatus] = useState<'idle' | 'loading'>('idle')
  const addToCart = useAddCreateOrderStateToCart()
  const { files, formData, multipleItemsLoadingPrice } = useCreateOrderState()
  const history = useHistory()
  const isUploading = files?.some(f => f.status === 'uploading')
  const hasUploadError = files?.some(f => f.status === 'error')
  const isLoading = multipleItemsLoadingPrice === true || status === 'loading' || isUploading
  const isSubmitting = status === 'loading'
  const [activeItem, setActiveItem] = useState<number | undefined>(0)
  const [itemsQuantity, setItemsQuantity] = useState(1)

  const handleSubmit = async (formData: any) => {
    try {
      setStatus('loading')
      await form.validateFields()

      try {
        const isCertificateNecessary = formData.assinantes.some(
          ({ certificado_necessario }: any) => certificado_necessario
        )

        addToCart(formData, !isCertificateNecessary)

        if (isCertificateNecessary) {
          message.success('Assinantes adicionados ao carrinho com sucesso.', 2)
          message.warning('Você foi redirecionado para a página de certificados digitais.', 5)
          const certificateDigitalService = getServiceConfig(
            SignatureCertificationCode.CERTIFICADO_DIGITAL_CPF
          )
          const service = allServices.find(s => s.id === certificateDigitalService.id)

          if (!service) throw new Error('Serviço não encontrado')

          dispatch(createOrderActions.resetState())

          dispatch(
            createOrderActions.setSelectedService({
              id: service.id,
              code: service.code,
              type: service.type,
              name: service.name,
              categoryId: service.category1Id,
            })
          )
          let itemIds = 0
          formData.assinantes.forEach((assinante: any) => {
            if (assinante.certificado_necessario) {
              dispatch(
                createOrderActions.setFormData({
                  [itemIds]: {
                    ...assinante,
                    nome_pf: assinante.nome,
                  },
                })
              )
              itemIds++
            }
          })

          history.push(Routes.CreateOrder(SignatureCertificationCode.CERTIFICADO_DIGITAL_CPF))
        }
      } catch (error) {
        setStatus('idle')
        errorHandler(error)
      }
    } catch {
      message.error('Verifique o preenchimento do formulário.')
    }
  }

  const initialValues = { ...formData }

  if (initialValues.assinantes === undefined) {
    initialValues.assinantes = [{ nome: '', cpf: '', email: '', certificado_necessario: false, key: 0 }]
  }

  const handleAddFormItem = (active: number, add: any) => {
    for (let i = 0; i < itemsQuantity; i++) {
      add()
    }

    setActiveItem(active)
  }

  // Salva no localStorage
  const onValuesChange = (changedFields: any, allValues: any) => {
    // Não atualiza formData se o campo alterado for o nome do pedido,
    // senão o nome automático sobrescreve o input do usuário
    if (activeItem !== undefined && changedFields[activeItem]?.name === undefined) {
      dispatch(createOrderActions.setFormData({ ...formData, ...allValues }))
    }
  }

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      scrollToFirstError
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      onFinishFailed={() => {
        message.error('Verifique o preenchimento do formulário.')
      }}
    >
      <Heading
        size="3"
        text="DADOS DAS PESSOAS QUE IRÃO ASSINAR"
        description="Inclua os assinadores do documento na ordem de assinatura. Todas as assinaturas são realizadas utilizando o padrão ICP-Brasil."
      />

      <ColetaAssinaturasItems
        {...{
          handleAddFormItem,
          disableAddBtn: isSubmitting,
          form,
          itemsQuantity,
          setItemsQuantity,
          activeItem,
          setActiveItem,
        }}
      />

      <SubmitButton marginTop="mt-3" loading={isLoading} disabled={hasUploadError || isUploading} />

      <p className={`text-sm text-center ${hasUploadError ? 'text-error' : 'text-gray-400'}`}>
        {hasUploadError ? 'Houve um erro ao fazer o upload' : isUploading ? 'Upload em andamento...' : ''}
      </p>
    </Form>
  )
}
