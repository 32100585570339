import { AuthorizedAPI } from 'api/network/v2'
import { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { AxiosResponse } from 'axios'

import { formatMoney } from 'utils/formatters'

function useGetBonusFor(value: number) {
  const [bonus, setBonus] = useState<number>()
  const [receive, setReceive] = useState<number>()
  const [debouncedValue] = useDebounce(value, 300)

  useEffect(() => {
    const fetchBonusValueFor = async () => {
      const bonusValue = await getBonusValueFor(value)
      const receiveValue = bonusValue + value
      setBonus(bonusValue)
      setReceive(receiveValue)
    }

    fetchBonusValueFor()
  }, [debouncedValue])

  return {
    bonus,
    receive:
      receive !== undefined ? formatMoney(receive, { hideDecimalsZeros: true }).replace(/\s/g, '') : '-',
  }
}

const getBonusValueFor = async (value: number | string) => {
  const response: AxiosResponse<{ bonus: number }> = await AuthorizedAPI.get(
    `/wallets/deposits/bonus-for-value/${value}`
  )
  return response.data.bonus
}

export default useGetBonusFor
