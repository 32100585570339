import React from 'react'

import { useSetOwnersIds } from 'domain/orders/state/filters'
import { useOrdersOwnerFilterQuery } from 'domain/orders/services'
import { FilterTag, LoadingIcon } from 'ui'

export default function FilterByOwnersTags({ value }: { value: number[] }) {
  const setOwnerIds = useSetOwnersIds()
  const { data: owners, isLoading } = useOrdersOwnerFilterQuery()

  if (isLoading) return <LoadingIcon />

  const selectedOwners = value.map(ownerId => owners?.find(({ id }) => id === ownerId))

  return (
    <>
      {selectedOwners.map(owner => (
        <FilterTag key={owner!.id} onClick={() => setOwnerIds(value.filter(id => id !== owner!.id))}>
          {owner!.name}
        </FilterTag>
      ))}
    </>
  )
}
