import { useCallback, useEffect, useRef, useState } from 'react'

import { SimilarOrdersError } from 'domain/createOrder/shared'
import { KitItem } from 'domain/kits/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { getExcelDateToJSDate } from 'utils/dateTime'
import { normalizeString, onlyAlphanumeric } from 'utils/formatters'

import { useAddKitToCart } from '../../services'
import { useGetColumnsIdentification, useGetDetailsFieldsConfig, useGetServicesForm } from '../../state'
import { KitCommonForm } from '../../types'
import KitSpreadsheetAddToCartRowSimilar from './KitSpreadsheetAddToCartRowSimilar'

export default function KitSpreadsheetAddToCartRow({
  row,
  kitItems,
  setRows,
}: {
  row: any
  kitItems: KitItem[]
  setRows: React.Dispatch<React.SetStateAction<any[]>>
}) {
  const [status, setStatus] = useState('')
  const [error, setError] = useState<any>()
  const detailsFields = useGetDetailsFieldsConfig()
  const columnsFields = useGetColumnsIdentification()
  const isAddingToCart = useRef(false)
  const formServicesData = useGetServicesForm()

  const { addToCart } = useAddKitToCart({
    onChangeStatus: setStatus,
  })

  const addSpreadsheetRowToCart = useCallback(
    async ({ items, checkSimilars }: { items?: KitItem[]; checkSimilars: boolean }) => {

      const formCommonData: KitCommonForm = {
        registree: {},
      }

      setStatus('Processando dados do formulário...')

      const registreePromises = detailsFields.registreeFields.map(async field => {
        const rowIndex = columnsFields[field.name]

        if (rowIndex !== undefined && row[rowIndex] !== undefined) {
          if (field) {
            if (field.kind === 'date' && typeof row[rowIndex] === 'number') {
              formCommonData.registree[field.name] = getExcelDateToJSDate(row[rowIndex])
            } else if (field.apiExtraInfo) {
              // TODO
            } else if (field.valueAsURL) {
              formCommonData.registree[field.name] = transformToURL(row[rowIndex])
            } else if (field.kind !== 'number') {
              formCommonData.registree[field.name] = String(row[rowIndex])
            } else {
              formCommonData.registree[field.name] = row[rowIndex]
            }
          }
        }
      })

      await Promise.all(registreePromises)

      detailsFields.serviceFields?.forEach(({ kitItem, fields }) => {
        const formFields: any = {}

        fields.forEach((field) => {
          const serviceConfig = getServiceConfig(kitItem.service.code)
          const rowIndex = columnsFields[`${kitItem.id}_${field.name}`]

          if (rowIndex !== undefined && row[rowIndex] !== undefined) {
            const fieldConfig = serviceConfig.getField(field.name)

            if (fieldConfig) {
              if (fieldConfig.kind === 'date' && typeof row[rowIndex] === 'number') {
                formFields[field.name] = getExcelDateToJSDate(row[rowIndex])
              } else if (fieldConfig.kind !== 'number') {
                formFields[field.name] = String(row[rowIndex])
              } else {
                formFields[field.name] = row[rowIndex]
              }
            }
          }
        })

        formCommonData[kitItem.id] = formFields
      })

      try {
        await addToCart({
          items: items ?? kitItems,
          checkSimilars,
          formCommonData,
          formServicesData,
        })

        setRows(prev => prev.filter(prevRow => prevRow.rowId !== row.rowId))
      } catch (error) {
        setError(error)

        if (error instanceof SimilarOrdersError) {
          setStatus('Já existe pedido similar a este.')
        } else {
          setStatus('Ocorreu um erro.')
        }
      }
    },
    [detailsFields, kitItems]
  )

  useEffect(() => {
    if (!isAddingToCart.current) {
      isAddingToCart.current = true
      addSpreadsheetRowToCart({ checkSimilars: true })
    }
  }, [addSpreadsheetRowToCart])

  return (
    <div className="flex flex-row justify-between items-center border border-gray-300 rounded-lg p-2 mb-2 text-secondary-500">
      <div>
        <h3 className="text-base mb-1">Linha {row.rowId + 1}</h3>
        <p className={`text-sm font-bold ${error ? 'text-error' : ''}`}>{status}</p>
      </div>
      {error && error instanceof SimilarOrdersError && (
        <KitSpreadsheetAddToCartRowSimilar
          rowId={row.rowId}
          addToCart={addSpreadsheetRowToCart}
          similarOrders={error.data}
          setRows={setRows}
        />
      )}
    </div>
  )
}

function transformToURL(value: string) {
  return onlyAlphanumeric(normalizeString(value)).replaceAll(' ', '-')
}
