import { useCategoriesContext } from 'domain/servicesCBRdoc'
import { Icon } from 'ui'

interface Props {
  categoryId: number
  serviceId: number
}

export default function KitItemPanelHeader({ categoryId, serviceId }: Props) {
  const categories = useCategoriesContext()
  const category = categories?.find(o => o.id === categoryId)
  const service = category?.services.find(o => o.id === serviceId)

  if (!category || !service) return <p>Categoria ou serviço não encontrado.</p>

  return (
    <>
      <span className="inline-block align-middle">
        <Icon name={category.code as any} className="text-lg mr-2" />
      </span>
      <span
        id={`service_${service.id}_${category.id}`}
        title={service.name}
        className="text-secondary-500 font-bold inline-block align-middle text-base"
      >
        {service.short_name ?? service.name}
      </span>
    </>
  )
}
