import React from 'react'

import { useFilterByStatus } from 'domain/notifications/state'
import { SelectableButton } from 'ui'

function NotificationFiltersByStatus() {
  const [status, setStatus] = useFilterByStatus()

  return (
    <SelectableButton
      selected={status === 'unread'}
      onClick={() => {
        setStatus(status === 'unread' ? undefined : 'unread')
      }}
    >
      {status === 'unread' ? 'Apenas não lidas' : 'Todos os status'}
    </SelectableButton>
  )
}

export default NotificationFiltersByStatus
