import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoEntidadesSupervisionadasFields = Record<FieldName.CNPJ, FieldConfig>

class CertidaoEntidadesSupervisionadas extends Certificate {
  formFields: CertidaoEntidadesSupervisionadasFields = {
    cnpj: Fields.cnpj,
  }

  constructor() {
    super({
      id: 99,
      shortName: 'Entidades Supervisionadas',
      steps: ['dados'],
    })
  }
}

const service = new CertidaoEntidadesSupervisionadas().initialize()

export default service
