import { CertificateFlow } from 'domain/createOrder/certificates'
import { TextField } from 'domain/servicesCBRdoc/fields'

import config from '../certidaoITRConfig'

export default function CreateOrderCertidaoITR() {
  return (
    <CertificateFlow
      header={
        <p className="mb-5 text-gray-600">
          Informe o CIB que deseja a Certidão ITR - Débitos Relativos a Tributos Federais e à
          Dívida Ativa da União de Imóvel Rural.
        </p>
      }
      renderFormItem={(itemId: number) => <CertidaoITRForm itemId={itemId} />}
    />
  )
}

const CertidaoITRForm = ({ itemId }: { itemId: number }) => {
  return <TextField autoFocus={itemId === 0} field={cib} itemId={itemId} />
}

const {
  formFields: { cib },
} = config
