import { useState } from 'react'
import { FormInstance } from 'antd'

import { SecretAPI } from 'api/network/v2'
import { ERRORS, captureError } from 'api/errors'
import { ApiError, ApiStatus } from 'api/types'

const useResetPassword = (form: FormInstance) => {
  const [error, setError] = useState<ApiError | null>(null)
  const [status, setStatus] = useState<ApiStatus>('idle')

  const resetPassword = async ({ email }: { email: string }) => {
    try {
      setStatus('loading')
      setError(null)
      await SecretAPI.post('/password-reset', { email })

      form.resetFields()
      setStatus('success')
    } catch (error: any) {
      setStatus('error')
      if (error.response.status === 422) {
        setError({ message: 'Email inválido', description: 'Por favor, verifique se as informações estão corretas.' })
      } else {
        captureError(error)
        setError(ERRORS.internalServer)
      }
    }
  }

  return {
    resetPassword,
    error,
    status,
    resetStatus: () => setStatus('idle'),
  }
}

export default useResetPassword
