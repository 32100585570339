import { CopyFormat, ProcessType } from './types'

export const PROCESS_TYPES: { label: string; value: ProcessType }[] = [
  { label: 'Digital', value: 'digital' },
  { label: 'Físico', value: 'fisico' },
]

export const COPY_FORMATS: { label: string; value: CopyFormat }[] = [
  { label: 'Foto Legível', value: 'foto' },
  { label: 'Impresso', value: 'impresso' },
  { label: 'Digitalizado em PDF', value: 'pdf' },
]
