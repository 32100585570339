import { Redirect, Route, useLocation } from 'react-router-dom'

import { useGetIsAuthenticated } from 'domain/auth'
import { useLoadCurrentCustomer } from 'domain/customers'
import { useLoadAvailableServices } from 'domain/servicesCBRdoc'
import { AuthenticatedLayout } from 'layouts'

import { EditAIModelPage } from 'pages/ai'
import {
  AfterPurchaseWithError,
  AfterPurchaseWithSuccess,
  CreateOrderPage,
  KitOrderPage,
  ShoppingCartPage,
} from 'pages/createOrder'
import Credits from 'pages/credits/Credits'
import Home from 'pages/home/Home'
import { InvoicesPage } from 'pages/invoices'
import { CreateKitPage, CreateKitSuccessPage, EditKitPage, KitsPage } from 'pages/kits'
import { MyFilesPage, ViewUploadedFilePage } from 'pages/myFiles'
import NotificationsPage from 'pages/notifications/NotificationsPage'
import { OrderOverviewPage, OrdersPage, ViewOrderPage } from 'pages/orders'
import ReportsPage from 'pages/reports/ReportsPage'
import Settings from 'pages/settings/SettingsPage'

import Recurrences from '../../pages/recurrences/RecurrencesPage'
import EditRecurrence from '../../pages/recurrences/EditRecurrencePage'

import RoutePaths from './RoutePaths'

export default function PrivateRoutes() {
  const isAuthenticated = useGetIsAuthenticated()
  const location = useLocation()
  const { data: customer } = useLoadCurrentCustomer()

  useLoadAvailableServices()

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: RoutePaths.LOGIN, state: { from: location } }} />
  }

  return (
    <AuthenticatedLayout>
      <Route path={RoutePaths.HOME} exact component={Home} />
      <Route path={RoutePaths.ORDERS} component={OrdersPage} />
      <Route path={RoutePaths.SETTINGS} component={Settings} />
      <Route path={RoutePaths.ORDER_ITEMS} exact component={OrderOverviewPage} />
      <Route path={RoutePaths.CREATE_ORDER} component={CreateOrderPage} />
      <Route path={RoutePaths.CREATE_ORDER_KIT} component={KitOrderPage} />
      <Route path={RoutePaths.VIEW_ORDER} component={ViewOrderPage} />
      <Route path={RoutePaths.MY_FILES} exact component={MyFilesPage} />
      <Route path={RoutePaths.VIEW_FILE} exact component={ViewUploadedFilePage} />
      <Route path={RoutePaths.MY_FILES_2} component={MyFilesPage} />
      <Route path={RoutePaths.SHOPPING_CART} exact component={ShoppingCartPage} />
      <Route path={RoutePaths.INVOICES} component={InvoicesPage} />
      {customer?.isPostpaid === false && <Route path={RoutePaths.CREDITS} component={Credits} />}
      <Route path={RoutePaths.REPORTS} component={ReportsPage} />
      <Route path={RoutePaths.PURCHASED_WITH_SUCCESS} component={AfterPurchaseWithSuccess} />
      <Route path={RoutePaths.PURCHASED_WITH_ERROR} component={AfterPurchaseWithError} />
      <Route path={RoutePaths.KITS} exact component={KitsPage} />
      <Route path={RoutePaths.CREATE_KIT_SUCCESS} exact component={CreateKitSuccessPage} />
      <Route path={RoutePaths.CREATE_KIT} component={CreateKitPage} />
      <Route path={RoutePaths.EDIT_KIT} component={EditKitPage} />
      <Route path={RoutePaths.AI_EDIT_MODEL} component={EditAIModelPage} />
      <Route path={RoutePaths.NOTIFICATIONS} component={NotificationsPage} />
      <Route path={RoutePaths.RECURRENCIES} exact component={Recurrences} />
      <Route path={RoutePaths.RECURRENCY_EDIT} exact component={EditRecurrence} />
      {/* <Route path="*">
        <p>Página não encontrada.</p>
      </Route> */}
    </AuthenticatedLayout>
  )
}
