import React from 'react'

import { Icon, IconName } from 'ui'

export interface LinkCardProps {
  icon?: IconName
  heading: string
  description?: string
  bold?: boolean
  onClick?: (event: any) => void
  extra?: React.ReactNode
  children?: React.ReactNode
  noChevron?: boolean
  disabled?: boolean
}

export default function LinkCard({
  icon,
  heading,
  bold,
  description,
  extra,
  onClick,
  children,
  noChevron,
  disabled,
}: LinkCardProps) {
  const btnStyle = disabled
    ? 'bg-gray-200 cursor-not-allowed'
    : 'bg-white hover:border-primary cursor-pointer transition-colors duration-200'

  return (
    <div
      role="button"
      onClick={!disabled ? onClick : () => {}}
      className={`w-full outline-offset-2 border border-gray-300 rounded-sm mb-3 p-4 ${btnStyle}`}
    >
      <div
        className={`w-full flex flex-row justify-between items-center ${
          disabled ? 'text-gray-600' : 'text-secondary'
        }`}
      >
        <div className="flex flex-row items-center">
          <Icon name={icon ?? 'registro-civil'} className="text-xl mr-3" />

          <div className={`flex-1 leading-snug mt-1 ${bold ? 'font-bold' : ''}`}>{heading}</div>
        </div>

        <div className="flex flex-row items-center">
          {extra}

          {!noChevron && <Icon name="chevron-right" className="text-primary ml-2" />}
        </div>
      </div>

      <div className="flex flex-col items-start">
        {description && <div className="text-gray-600 mt-1 max-w-full truncate leading-6">{description}</div>}

        {children}
      </div>
    </div>
  )
}
