import { Customer, ProfileAPI, ProfileForm } from 'domain/customers/types'
import { onlyNumbers } from 'utils/formatters'
import useUpdateCustomer from './useUpdateCustomer'

function useUpdateCustomerProfile() {
  const { updateCustomer, status, error } = useUpdateCustomer()

  const updateCustomerProfile = async (
    {
      address,
      addressNumber,
      addressComplement,
      addressNeighborhood,
      addressCity,
      addressState,
      addressZipcode,
      phone,
    }: Partial<ProfileForm>,
    onSuccess?: (data: Customer) => void
  ) => {
    const updatedProfile: Partial<ProfileAPI> = {
      address_public_place: address,
      address_number: addressNumber,
      address_complement: addressComplement,
      address_neighborhood: addressNeighborhood,
      address_city: addressCity,
      address_uf: addressState,
      address_zip_code: addressZipcode ? onlyNumbers(addressZipcode) : undefined,
      phone,
    }

    await updateCustomer(updatedProfile, onSuccess)
  }

  return { updateCustomerProfile, status, error }
}

export default useUpdateCustomerProfile
