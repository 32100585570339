import { Form } from 'antd'
import { useHistory } from 'react-router-dom'

import { Routes } from 'app/routes'
import { Section } from 'layouts'
import { SubmitButton, TextInput } from 'ui'

import KitLegalEntity from './CreateKitEntityType'
import { useCreateKit, useSetCreateKit } from './CreateKitContext'

export default function CreateKitStep1() {
  const history = useHistory()
  const [form] = Form.useForm()
  const { name, legalEntity } = useCreateKit()
  const setKit = useSetCreateKit()

  const onFinish = () => {
    setKit({ name: form.getFieldValue('name') })
    history.push(Routes.CreateKitStep('selecionar-documentos'))
  }

  return (
    <Section heading="Criar um kit de documentos">
      <p className="text-secondary-500 mb-8">
        Para criar um kit de documentos você precisa dar um nome para identificá-lo posteriormente.
      </p>

      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ name, legalEntity }}
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
        scrollToFirstError
      >
        <TextInput name="name" label="Nome do kit:" maxLength={100} autoFocus type="text" required />

        <Form.Item name="legalEntity" label="Tipo de Pessoa:" className="mb-8" rules={requiredRule}>
          <KitLegalEntity />
        </Form.Item>

        <SubmitButton label="Selecionar documentos" block={false} />
      </Form>
    </Section>
  )
}

const requiredRule = [{ required: true, message: 'Este campo é obrigatório.' }]
