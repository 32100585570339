import { Form } from 'antd'
import { useHistory } from 'react-router-dom'
import { useIsFetching } from 'react-query'

import { Routes } from 'app/routes'
import { Fields } from 'domain/servicesCBRdoc'
import { TextField } from 'domain/servicesCBRdoc/fields'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { Section } from 'layouts'
import { SubmitButton } from 'ui'

import { useGetKit, useGetKitFiles, useSaveServiceFormOnLocalStorage, useServicesForm } from '../state'
import { KitOrderStepURI } from '../types'
import ServicesFormFields from './fields/KitOrderServiceFormFields'
import LegalEntityField from './fields/LegalEntityField'

export default function KitOrderServicesStep() {
  const [form] = Form.useForm()
  const kit = useGetKit()
  const [initialValues, setServicesForm] = useServicesForm()
  const files = useGetKitFiles()
  const saveOnLocalStorage = useSaveServiceFormOnLocalStorage()
  const history = useHistory()
  const isFetching = useIsFetching()
  
  const isUploading = files?.some(f => f.status === 'uploading')
  const hasUploadError = files?.some(f => f.status === 'error')

  const disableButton = !!isFetching || isUploading || hasUploadError
  const loadingButton = !!isFetching || isUploading

  const onFinish = (formServicesData: any) => {
    setServicesForm(formServicesData)
    history.push(Routes.CreateOrderKit(kit.id, KitOrderStepURI.COMMON_DATA))
  }

  return (
    <Section heading="Dados de seu Kit de Certidões">
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={(changedValues, allValues) => {
          const [key] = Object.keys(changedValues)
          const insideKeys = Object.keys(changedValues[key])

          const fixedAllValues = { ...allValues }

          if (insideKeys.indexOf('url_uf') >= 0) {
            const tjKeys = Object.keys(fixedAllValues[key])

            if (tjKeys.indexOf('instancia') >= 0)
              fixedAllValues[key] = { ...fixedAllValues[key], instancia: undefined }

            if (tjKeys.indexOf('modelo') >= 0)
              fixedAllValues[key] = { ...fixedAllValues[key], modelo: undefined }
          }

          if (insideKeys.indexOf('instancia') >= 0)
            fixedAllValues[key] = { ...fixedAllValues[key], modelo: undefined }

          saveOnLocalStorage(changedValues, fixedAllValues)
        }}
        requiredMark={false}
        scrollToFirstError
      >
        <LegalEntityField />
        <TextField field={Fields[FieldName.NOME_ITEM_PEDIDO]} />

        {kit.items.map((item, index) => {
          return <ServicesFormFields key={item.id} kitItem={item} autoFocus={index === 0} />
        })}

        <hr className="my-5" />

        <SubmitButton label="Continuar" loading={loadingButton} disabled={disableButton} />
      </Form>
    </Section>
  )
}
