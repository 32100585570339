import React from 'react'
import { Dropdown, message } from 'antd'

import { OCRPage as OCRPageType } from 'domain/myFiles/types'
import { Button, Icon } from 'ui'
import useDownloadOcrTranscription from 'domain/myFiles/services/useDownloadOcrTranscription'
import { handleRequestError } from 'api/HandleRequestError'

function OCRPage({ ocrId, page, totalPages }: { ocrId: number, page: OCRPageType; totalPages: number }) {
  const { handleOcrDownload } = useDownloadOcrTranscription()
  function copyToClipboard() {
    navigator.clipboard.writeText(page.content)
    message.success('Texto copiado com sucesso!')
  }

  async function handleDownload(type: 'docx' | 'pdf') {
    try {
      await handleOcrDownload(ocrId, type)
    } catch(e) {
      handleRequestError(e)
    }
  }

  return (
    <div className="mb-4 pb-3 relative text-sm border-b border-slate-300 bg-white p-6 drop-shadow-md">
      <Dropdown
        placement="bottomRight"
        className="absolute top-2 right-2 cursor-pointer"
        menu={{
          onClick: ({ domEvent }) => domEvent.stopPropagation(),
          items: [
            {
              key: 'copy',
              label: (
                <Button type="text" icon="copy" onClick={copyToClipboard} title="Clique para copiar">
                  Copiar página
                </Button>
              ),
            },
            {
              key: 'docx',
              label: (
                <Button type="text" icon="document" onClick={async () => await handleDownload('docx')} title="Clique para baixar docx">
                  Baixar para word
                </Button>
              ),
            },
            {
              key: 'pdf',
              label: (
                <Button type="text" icon="download" onClick={async () => await handleDownload('pdf')} title="Clique para baixar pdf">
                  Baixar para PDF
                </Button>
              ),
            },
          ],
        }}
        trigger={['click']}
      >
        <Button type="primary" ghost size="small" icon="ellipsis" className="pl-4" />
      </Dropdown>
      {page.content.split('\n').map((paragraph: string, index: number) => (
        <p className="pb-3 text-black" key={index}>
          {paragraph}
        </p>
      ))}

      <p className="font-bold text-center text-xs mt-4 text-primary">
        página {page.pageNumber} / {totalPages}
      </p>
    </div>
  )
}

export default OCRPage
