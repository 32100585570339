import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { displayDate } from 'utils/dateTime'
import { removeEmpties } from 'utils/transformData'

import { useMyFilesPagination } from '../state'
import { ExplorerList, GetMyFilesParams } from '../types'
import { ExplorerItemAPI, ExplorerListAPI } from '../typesAPI'
import QueryKeys from './MyFilesQueryKeys'
import { convertExplorerItem } from './convertExplorer'

export default function useExplorerQuery(
  params: Omit<GetMyFilesParams, 'page' | 'pageSize'>,
  options: any = {}
) {
  const [pagination, setPagination] = useMyFilesPagination()
  const { page: currentPage, pageCount, totalCount, pageSize } = pagination

  const query = useQuery(
    [...QueryKeys.MyFilesList, currentPage, pageSize, params],
    async () => {
      const { filters, parentId } = params
      const url = parentId ? `/explorer/${parentId}` : '/explorer'

      const response: AxiosResponse<ExplorerListAPI | ExplorerItemAPI[]> = await AuthorizedAPI.get(url, {
        params: {
          'filter[parent_id]': parentId ? parentId : 'null',
          ...removeEmpties({
            append: 'file,breadcrumb',
            'filter[ai]': filters?.ai ? 'ai-yes' : 'any',
            'filter[created_between]': filters?.dates,
            'filter[group_id]': filters?.groupsIds && filters?.groupsIds.join(','),
            'filter[owner_id]': filters?.ownerId,
            'filter[service_id]': filters?.servicesIds && filters?.servicesIds.join(','),
            'filter[type]': filters?.type,
            'filter[name]': filters?.name,
            include: 'order,order.service,owner,customer,parent,groups,children',
            'per-page': pageSize,
            page: currentPage,
            sort: params.sortBy,
          }),
        },
      })

      setPagination({
        ...pagination,
        pageCount: Number(response.headers['x-pagination-page-count']),
        totalCount: Number(response.headers['x-pagination-total-count']),
      })

      if (!response.data) return undefined

      const { data } = response
      const responseBody = (data as ExplorerListAPI).children
        ? (data as ExplorerListAPI).children
        : (data as ExplorerItemAPI[])

      const children = responseBody?.map((item: any) => {
        return {
          ...item,
          orderId: item.order?.id ?? item.order_id,
          mostRecentOperationTime: displayDate(item.last_operation_at),
          serviceName: item.order?.service?.name,
          owner: item.owner?.name,
        }
      })

      return {
        breadcrumb: (data as ExplorerListAPI).breadcrumb?.map(convertExplorerItem) ?? [],
        children: children?.map(convertExplorerItem),
      } as ExplorerList
    },
    {
      keepPreviousData: true,
      ...options,
    }
  )

  const setCurrentPage = (currentPage: number) => setPagination({ ...pagination, page: currentPage })
  const setPageSize = (pageSize: number) => setPagination({ ...pagination, pageSize })

  return {
    ...query,
    pagination: {
      currentPage,
      setCurrentPage,
      pageCount,
      totalCount,
      pageSize,
      setPageSize,
    },
  }
}
