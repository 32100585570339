import React, { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'

import NavButton from './MonthPickerNavButton'
import { DatePicker } from 'antd'

interface Props {
  value?: Date
  className?: string
  onChange?: (date: Date) => void
}

export default function MonthPicker({ value, className = '', onChange }: Props) {
  const [date, setDate] = useState(dayjs(value))

  const handleChange = (dayJsValue: Dayjs) => {
    setDate(dayJsValue)
    onChange && onChange(dayJsValue.toDate())
  }

  useEffect(() => {
    const valueAsDayJs = dayjs(value)

    if (!valueAsDayJs.isSame(date, 'day')) {
      setDate(valueAsDayJs)
    }
  }, [value])

  return (
    <div className="flex justify-between rounded-sm h-11">
      <NavButton direction="left" date={date} setDate={handleChange} />
      <DatePicker
        size="large"
        allowClear={false}
        value={date}
        format="MMM/YYYY"
        picker="month"
        className={`border-0 text-white bg-secondary-500 
          [&_input]:uppercase [&_input]:text-center ${className}`}
        onChange={handleChange as any}
        suffixIcon={null}
      />
      <NavButton direction="right" date={date} setDate={handleChange} />
    </div>
  )
}
