import { atom, useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'

import { ServicesById } from '../types'

const servicesById = atom<ServicesById>({})
const allAvailableServices = selectAtom(servicesById, all => Object.values(all))

export const useSetAvailableServices = () => useSetAtom(servicesById)

export const useServiceById = (id: number) => {
  const serviceConfig = useAtomValue(servicesById)[id]
  return id ? serviceConfig : undefined
}

export const useAllServices = () => useAtomValue(allAvailableServices)
