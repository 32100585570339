import { ResultCode, StatusCode } from 'domain/orders/types'

import FilterByStatusBtn from './FilterByStatusBtn'
import FilterByResultBtn from './FilterByResultBtn'
import FilterExpiredBtn from './FilterExpiredBtn'
import FiltersModal from './FiltersModal'

export default function OrdersFilters() {
  return (
    <div className="flex gap-2">
      <FilterByStatusBtn status={StatusCode.PROCESSANDO} label="Processando" />
      <FilterByStatusBtn status={StatusCode.FINALIZADO} label="Finalizados" />
      <FilterByResultBtn result={ResultCode.POSITIVE} label="Positivos" />
      <FilterByResultBtn result={ResultCode.NEGATIVE} label="Negativos" />
      <FilterByStatusBtn status={StatusCode.ACAO_NECESSARIA} label="Ação necessária" />
      <FilterExpiredBtn />
      <FiltersModal />
    </div>
  )
}
