import React from 'react'
import { Tooltip } from 'antd'

import { Icon, RadioGroupInput } from 'ui'

function DocumentsQuantityRadio() {
  return (
    <RadioGroupInput
      label={
        <div className="mr-12">
          Quantos documentos/pesquisas sua empresa solicita por mês?
          <Tooltip
            title="Utilizamos esta informação para direcionar melhor seu atendimento"
            placement="bottom"
          >
            <div className="inline-block">
              <Icon name="help-tooltip" className="text-primary text-lg ml-1" />
            </div>
          </Tooltip>
        </div>
      }
      name="question_services"
      optionType="default"
      options={questionServicesOptions}
      required
    />
  )
}

const questionServicesOptions = [
  {
    label: '0 a 10',
    value: '0_10_mes',
  },
  {
    label: '10 a 50',
    value: '10_50_mes',
  },
  {
    label: 'Mais de 50',
    value: 'mais_50_mes',
  },
]

export default DocumentsQuantityRadio
