import React from 'react'

import { CertificateFlow } from 'domain/createOrder/certificates'
import { CPFInput, EmailInput, PhoneInput } from 'ui'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { useCreateOrderState } from 'domain/createOrder/document'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { DateField, NameField, TextField } from 'domain/servicesCBRdoc/fields'

export default function CreateOrderCertificadoDigitalCpf() {
  const { selectedService, formData } = useCreateOrderState()
  const service = getServiceConfig(selectedService.code!)

  return (
    <CertificateFlow
      header={
        <p className="mb-5 text-gray-600">Dados das pessoas que irão aparecer no certificado digital.</p>
      }
      renderFormItem={(itemId: number) => (
        <CertificadoDigitalCpfForm itemId={itemId} fields={service.formFields} autoFocus={false} />
      )}
    />
  )
}

interface CertificadoDigitalCpfFormProps {
  itemId: number
  fields: Partial<Record<FieldName, FieldConfig>>
  autoFocus: boolean
}

const CertificadoDigitalCpfForm = ({ itemId, fields, autoFocus }: CertificadoDigitalCpfFormProps) => {
  const { cpf, nome_pf, nascimento, endereco, email, telefone } = fields

  return (
    <>
      <CPFInput {...{ cpf, itemId, autoFocus }} />

      {nome_pf && <NameField field={nome_pf} itemId={itemId} autoFocus={autoFocus} />}

      {nascimento && <DateField itemId={itemId} field={nascimento} autoFocus={autoFocus} />}

      {endereco && <TextField itemId={itemId} field={endereco} autoFocus={autoFocus} />}

      {email && <EmailInput itemId={itemId} name={email.name} autoFocus={autoFocus} />}

      {telefone && (
        <PhoneInput
          itemId={itemId}
          name={telefone.name}
          required={!telefone.optional}
          autoFocus={autoFocus}
        />
      )}
    </>
  )
}
