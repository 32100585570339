import React from 'react'

import { useStatuses } from 'domain/orders/state/filtersOverview'
import { OrderStatuses, StatusCode } from 'domain/orders/types'
import { SelectableButton } from 'ui'

interface Props {
  statusCode: StatusCode
  text?: string
}

function FilterByStatus({ text, statusCode }: Props) {
  const [statuses, setStatuses] = useStatuses()
  const status = OrderStatuses[statusCode]
  const isSelected = statuses.includes(status.code)

  return (
    <SelectableButton
      selected={isSelected}
      onClick={() => {
        const updated = isSelected
          ? statuses.filter((code: any) => code !== status.code)
          : [...statuses, status.code]
        setStatuses(updated)
      }}
    >
      {text ?? status.name}
    </SelectableButton>
  )
}

export default FilterByStatus
