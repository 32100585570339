import React from 'react'

import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs } from 'domain/createOrder/document'
import { CPFInput, CNPJInput } from 'ui'

export default function CreateOrderCertidaoCNDT() {
  return (
    <CertificateFlow
      header={
        <p className="mb-5 text-gray-600">
          Selecione Pessoa ou Empresa e digite o número do CPF ou CNPJ que deseja a Certidão Negativa de
          Débitos Trabalhistas - CNDT.
        </p>
      }
      renderFormItem={(itemId: number) => <CertidaoCNDTForm itemId={itemId} />}
    />
  )
}

const CertidaoCNDTForm = ({ itemId }: { itemId: number }) => {
  const inputProps = { itemId, autoFocus: itemId === 0 }

  return (
    <CreateOrderLegalEntityTabs
      itemId={itemId}
      fisica={<CPFInput {...inputProps} />}
      juridica={<CNPJInput {...inputProps} />}
    />
  )
}
