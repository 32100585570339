import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs, SelectLocationFields } from 'domain/createOrder/document'
import { AddressFields } from 'domain/servicesCBRdoc/fields'
import { CNPJInput, CPFInput } from 'ui'

export default function CertidaoAmbientalMunicipal() {
  return (
    <CertificateFlow
      header={
        <>
          <p className="mb-5 text-gray-600">
            Disponibilidade de entrega imediata pode sofrer impactos mediante alterações sistêmicas de cada
            estado do órgão emissor.
          </p>

          <SelectLocationFields autoFocus />
        </>
      }
      renderFormItem={(itemId: number) => <CertidaoAmbientalMunicipalForm itemId={itemId} />}
    />
  )
}

const CertidaoAmbientalMunicipalForm = ({ itemId }: { itemId: number }) => {
  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={<CPFInput itemId={itemId} />}
        juridica={<CNPJInput itemId={itemId} />}
      />

      <AddressFields autoFocus={false} itemId={itemId} />
    </>
  )
}
