import React from 'react'

import {
  ViewOrderResearchActionsInline,
  ViewResearchActions,
  ViewResearchWithResults
} from 'domain/orderItem'
import { ExcelGenerator, TableColumn } from 'domain/orderItem/types'
import { Tag } from 'ui'

function ViewPesquisaProcessosJudiciais() {
  return (
    <ViewResearchWithResults columns={columns} actions={<ViewResearchActions excel={excelGenerator} />} />
  )
}

const columns: TableColumn[] = [
  {
    title: 'Estado',
    dataIndex: ['url_uf', 'label'],
  },
  {
    title: 'Partes',
    dataIndex: 'partes_envolvidas',
    render: (partes: any) => {
      return (
        <div className="flex flex-col gap-1">
          {partes.map((partes: string, index: number) => (
            <Tag key={index} color="neutral">
              {partes}
            </Tag>
          ))}
        </div>
      )
    }
  },
  {
    title: 'Número do processo',
    dataIndex: 'protocolo',
  },
  {
    title: 'Assunto',
    dataIndex: 'assunto_principal',
  },
  {
    title: 'Outros assuntos',
    dataIndex: 'assuntos',
  },
  {
    title: 'Data de publicação',
    dataIndex: 'data_publicacao',
  },
  {
    title: 'Valor',
    dataIndex: 'valor',
  },
  {
    title: 'Tipo',
    dataIndex: 'tipo',
  },
  {
    title: 'Tipo de Tribunal',
    dataIndex: 'tipo_tribunal',
  },
  {
    title: 'Corpo Julgador',
    dataIndex: 'corpo_julgador',
  },
  {
    title: 'Status do processo',
    dataIndex: 'status',
  },
  {
    title: '',
    dataIndex: 'actions',
    align: 'right',
    render: (_: any, result: any) => <ViewOrderResearchActionsInline result={result} />,
  },
]

const excelGenerator: ExcelGenerator = {
  columns: [...columns].splice(0, columns.length - 1),
  transform: {
    partes_envolvidas: (value: string[]) => value.join('\n'),
  }
}

export default ViewPesquisaProcessosJudiciais
