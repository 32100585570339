class GenericError extends Error {
  errors: string[]
  code = 400

  constructor(message: string, description: string | string[]) {
    super(message)
    Object.setPrototypeOf(this, GenericError.prototype)

    this.errors = Array.isArray(description) ? description : [description]
  }
}

export default GenericError
