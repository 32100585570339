import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { useGetIsHydratedFromURL } from 'domain/app'
import { AICreateOrderModelAPI } from 'domain/openAI/typesAPI'

import { useGetSearch } from '../state/filters'
import { convertCreateOrderModel } from './convertModel'

export default function useCBRDocModelsQuery() {
  const isHydrated = useGetIsHydratedFromURL()
  const search = useGetSearch()

  return useQuery(
    ['cbrdoc-models', search],
    async () => {
      const params: any = {
        append: 'questions',
        include: 'service,service.category1',
        sortBy: 'name',
        'filter[visibility]': 'public',
      }

      if (search) {
        params['filter[name_or_id]'] = search
      }

      const response = await AuthorizedAPI.get<AICreateOrderModelAPI[]>('/ai-models', {
        params,
      })

      return response.data.map(convertCreateOrderModel)
    },
    {
      enabled: isHydrated,
    }
  )
}
