import React, { ReactNode } from 'react'

export type LabelColor = 'primary' | 'red' | 'secondary'

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  color?: LabelColor
  children: ReactNode
}

function Label({ color = 'secondary', className = '', children, ...props }: Props) {
  return (
    <span className={`rounded-md px-1.5 text-3xs mr-2 font-bold ${getColor(color)} ${className}`} {...props}>
      {children}
    </span>
  )
}

const getColor = (color: LabelColor) => {
  switch (color) {
    case 'red': return 'bg-red-400 text-white'
    case 'primary': return 'bg-primary text-white'
    default: return 'bg-secondary-500 text-white'
  }
}

export default Label
