import React from 'react'
import { Checkbox, Form } from 'antd'

import { ResultCode } from 'domain/orders/types'

interface Props {
  name: string
  resultsCode: ResultCode
}

export default function AutoPurchaseResultsCheckbox({ name, resultsCode }: Props) {
  return (
    <Form.Item name={name} valuePropName="checked">
      <Checkbox>
        Solicitar automaticamente resultados{' '}
        {resultsCode === ResultCode.NEGATIVE ? 'negativos' : 'positivos'}
      </Checkbox>
    </Form.Item>
  )
}
