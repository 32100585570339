import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { ITEMS_PER_PAGE } from 'app/constants'
import { UserAPI } from 'domain/users/types'
import { convertUser } from './convertUser'

type Params = { search: string; page: number; setTotal: (value: number) => void }

export default function useActiveUsersFilterQuery({ search, page, setTotal }: Params) {
  const params: any = {
    page,
    'per-page': ITEMS_PER_PAGE,
    sort: 'name',
  }

  if (search) {
    params['filter[name_or_email]'] = search
  }

  return useQuery(['users', 'active', 'all', search, page], async () => {
    const response: AxiosResponse<UserAPI[]> = await AuthorizedAPI.get('users', { params })

    setTotal(Number(response.headers['x-pagination-total-count']))

    return response.data?.map(convertUser)
  })
}
