import { Button, ButtonProps, Form } from 'antd'

interface Props extends Omit<ButtonProps, 'children' | 'size'> {
  label?: string
  marginTop?: string
  loading?: boolean
}

export default function SubmitButton({
  label = 'Continuar',
  marginTop = 'mt-6',
  loading,
  block = true,
  ...props
}: Props) {
  return (
    <Form.Item className={marginTop}>
      <Button loading={loading} htmlType="submit" type="primary" size="large" block={block} {...props}>
        {label}
      </Button>
    </Form.Item>
  )
}
