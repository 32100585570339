import React from 'react'
import { Spin } from 'antd'

import useCustomerModelsQuery from '../services/useMyModelsQuery'
import { useSetCustomerModelsInitialValuesFromURL } from '../state/myModels'
import EmptyModels from './EmptyModels'
import ModelsActionsBar from './ModelsActionsBar'
import MyModelsList from './MyModelsList'

export default function MyModelsTab() {
  useSetCustomerModelsInitialValuesFromURL()

  return (
    <div className="flex-col flex-1">
      <ModelsActionsBar />
      <ModelsList />
    </div>
  )
}

function ModelsList() {
  const { data, isLoading, isFetching } = useCustomerModelsQuery()

  if (isLoading || !data) {
    return <Spin />
  }

  if (data.length === 0) {
    return <EmptyModels />
  }

  return (
    <div className="px-7">
      <MyModelsList models={data} loading={isFetching} />
    </div>
  )
}
