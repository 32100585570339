import React from 'react'
import { Pagination as PaginationAnt } from 'antd'

interface Props {
  pageSize?: number
  currentPage: number
  totalCount?: number
  onChangePage: (pageNumber: number) => void
  onPageSizeChange?: (pageSize: number) => void
  className?: string
  topRef?: React.RefObject<HTMLDivElement | null>
}

export default function Pagination({
  pageSize = 10,
  onPageSizeChange,
  totalCount,
  currentPage,
  onChangePage,
  className = '',
  topRef,
}: Props) {
  if (!totalCount || totalCount === 0) return null

  const totalSoFar = currentPage * pageSize
  const showingCount = Math.min(totalSoFar, totalCount)

  return (
    <div className={`flex justify-between items-center mt-4 ${className}`}>
      {totalCount > pageSize ? (
        <PaginationAnt
          size="small"
          current={currentPage}
          defaultPageSize={pageSize}
          onShowSizeChange={onPageSizeChange ? (current, pageSize) => onPageSizeChange(pageSize) : undefined}
          total={totalCount}
          onChange={page => {
            topRef?.current?.scrollIntoView()
            page !== currentPage && onChangePage(page)
          }}
          showSizeChanger={onPageSizeChange !== undefined}
          pageSizeOptions={pageSizeOptions}
        />
      ) : (
        <div></div>
      )}
      <div className="text-sm text-gray-500 display-none sm:display-block">
        Mostrando {showingCount} de {totalCount} resultado{totalCount > 1 && 's'}
      </div>
    </div>
  )
}

const pageSizeOptions = [10, 25, 50]
