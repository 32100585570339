import React, { useReducer } from 'react'
import { Button } from 'antd'

import { errorHandler } from 'api/errors'
import { useGetSelectedMonth } from 'domain/invoices/state'
import { PostpaidInvoice } from 'domain/invoices/types'
import { Icon } from 'ui'

import generatePostpaidPDF from '../helpers/generatePostpaidPDF'

interface Props {
  selectedRows: PostpaidInvoice[]
  selectedKeys: React.Key[]
  onClose: () => void
}

export default function PostpaidInvoicesMassActionsDownload({ selectedRows, onClose }: Props) {
  const [loading, toggleLoading] = useReducer(prev => !prev, false)
  const selectedMonth = useGetSelectedMonth()

  const onClick = async () => {
    try {
      toggleLoading()
      generatePostpaidPDF(selectedMonth, selectedRows)
      onClose()
    } catch (error) {
      toggleLoading()
      errorHandler(error, { code: '0x004' })
    }
  }

  return (
    <Button
      type="primary"
      icon={<Icon name="download" className="mr-2" />}
      onClick={onClick}
      loading={loading}
      className="px-10"
    >
      Baixar fatura
    </Button>
  )
}
