import React from 'react'
import { useMutation } from 'react-query'
import { message } from 'antd'

import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'
import { ORDERS_QUERY_KEY } from 'domain/orders/services/useOrdersQuery'

import EditOrderNameModal from './EditOrderNameModal'

interface Props {
  orderName: string
  orderId: number
  open: boolean
  onClose: () => void
}

function OrdersActionsEditName({ orderId, orderName, open, onClose }: Props) {
  const mutation = useMutation(async (newName: string) => await changeOrderName(orderId, newName), {
    onError: () => {
      message.error('Ocorreu um erro ao salvar o nome do pedido.')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ORDERS_QUERY_KEY,
        refetchActive: true,
      })

      message.success('O nome do pedido foi atualizado.')

      onClose()
    },
  })

  return (
    <EditOrderNameModal
      currentName={orderName}
      open={open}
      onCancel={onClose}
      onSave={mutation.mutate as any}
      loading={mutation.isLoading}
    />
  )
}

async function changeOrderName(id: number | string, name: string) {
  return await AuthorizedAPI.patch(`purchases/${id}/name`, {
    name,
  })
}

export default OrdersActionsEditName
