import React, { useEffect, useState } from 'react'

import { useCustomerCreditsQuery } from 'domain/credits'
import { formatMoney } from 'utils/formatters'

import TotalPriceLayout from './TotalPrice'

interface Props {
  loading?: boolean
  totalPrice: number | null
}

function TotalPriceForPrePaidCustomer({ loading, totalPrice }: Props) {
  const { data: accountBalance, isFetching } = useCustomerCreditsQuery()
  const hasPrice = totalPrice !== null

  return (
    <TotalPriceLayout
      totalPrice={totalPrice}
      loading={loading || isFetching}
      header={
        <div className="flex justify-between text-gray-600 py-3 border-b border-gray-300">
          <div>Créditos disponíveis:</div>
          <div>{formatMoney(accountBalance)}</div>
        </div>
      }
    >
      {hasPrice && !!accountBalance && accountBalance > 0 && (
        <RemainingCredits totalPrice={totalPrice as number} accountBalance={accountBalance} />
      )}
    </TotalPriceLayout>
  )
}

function RemainingCredits({ totalPrice, accountBalance }: { totalPrice: number; accountBalance: number }) {
  const [remainingCredits, setRemainingCredits] = useState<number>()

  useEffect(() => {
    setRemainingCredits(accountBalance - totalPrice)
  }, [totalPrice, accountBalance])

  if (remainingCredits === undefined) return null

  return (
    <div className="flex flex-col py-3 items-end border-b border-gray-300">
      <div className="w-full flex justify-between items-end">
        <div className="text-gray-600">Saldo de créditos:</div>
        <div className={`${remainingCredits >= 0 ? 'text-green-500' : 'text-error'}`}>
          {formatMoney(remainingCredits)}
        </div>
      </div>
      {remainingCredits < 0 && (
        <div className="mt-2 text-error text-2xs">Saldo de créditos insuficiente para o pedido.</div>
      )}
    </div>
  )
}

export default TotalPriceForPrePaidCustomer
