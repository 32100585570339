import React from 'react'

const logoCBRDocNoSlogan = require('assets/images/logo-cbrdoc-horizontal-no-slogan.svg').default

interface Props {
  message: string
}

export default function LogoMessage({ message }: Props) {
  return (
    <div className="flex flex-col sm:flex-row items-center w-full p-7 bg-gray-100">
      <div className="block">
        <a href="/">
          <img src={logoCBRDocNoSlogan} alt="CBRdoc - Central Brasileira de Documentos" />
        </a>
      </div>
      <span className="sm:ml-14 pt-4 sm:pt-2">{message}</span>
    </div>
  )
}
