import { Popconfirm, PopconfirmProps } from 'antd'

import TextButton from 'ui/buttons/TextButton'
import Icon from 'ui/Icon'

export default function PopConfirmDelete(
  { disabled, ...props }: Pick<PopconfirmProps, 'title' | 'description' | 'onConfirm'> & { disabled?: boolean }
) {
  return (
    <Popconfirm
      okText="Sim"
      cancelText="Não"
      okButtonProps={{ className: 'bg-error' }}
      cancelButtonProps={{ type: 'text' }}
      icon={<Icon name="exclamation-circle" className="text-error mr-1 mt-0.5" strokeWidth={2} size={16} />}
      {...props}
    >
      <TextButton
        className="text-secondary-300 flex items-center justify-center"
        label={<Icon name="trash" size={20} />}
        disabled={disabled}
      />
    </Popconfirm>
  )
}
