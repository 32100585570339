import React from 'react'

import { SumTotalInvoices } from 'domain/invoices/components'
import { useGetTableSelection } from 'domain/invoices/state'
import { DrawerActions } from 'ui'

import Download from './PostpaidInvoicesMassActionsDownload'
import { PostpaidInvoice } from 'domain/invoices/types'

interface Props {
  onClose: () => void
}

function PostpaidInvoicesMassActions({ onClose }: Props) {
  const selected = useGetTableSelection()

  return (
    <DrawerActions
      height={140}
      onClose={onClose}
      total={selected.keys.length ?? 0}
      className="justify-center"
    >
      <SumTotalInvoices fieldName="debitAmount" selectedRows={selected.rows} />
      <Download
        selectedKeys={selected.keys}
        selectedRows={selected.rows as PostpaidInvoice[]}
        onClose={onClose}
      />
    </DrawerActions>
  )
}

export default PostpaidInvoicesMassActions
