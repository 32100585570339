import React from 'react'

import { StatusCode } from 'domain/orders/types'
import { RowItemsContainer } from 'ui'

import FilterByOwnership from './FilterByOwnership'
import FilterByResult from './FilterByResult'
import FilterByStatus from './FilterByStatus'
import FilterToggleStatus from './FilterToggleStatus'
import FilterExpired from './FilterExpired'

function OrdersFilters() {
  return (
    <RowItemsContainer title="Filtrar por:">
      <FilterByStatus />
      <FilterByResult />
      <FilterToggleStatus statusCode={StatusCode.ACAO_NECESSARIA} />
      <FilterByOwnership />
      <FilterExpired />
    </RowItemsContainer>
  )
}

export default OrdersFilters
