import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { useIsCustomerPostPaid } from 'domain/customers'
import { OrderAPI } from 'domain/orders/types'
import { convertOrderOverview } from './convertOrder'
import { useGetOverviewRefresh } from '../state/order'

function useOrderOverviewQuery(orderId: number | string | undefined, hasViewPermission: boolean) {
  const isPostPaid = useIsCustomerPostPaid()
  const refreshable = useGetOverviewRefresh()

  return useQuery(
    ['orders', 'overview', orderId, refreshable],
    async () => {
      const response: AxiosResponse<OrderAPI> = await AuthorizedAPI.get(`/purchases/${orderId}`, {
        params: {
          append: [
            'downloadable_orders_ids',
            'downloaded_orders_ids',
            'first_order.register',
            'has_ai_extracted_data',
            'has_consumed_ai_free_analysis',
            'last_status_change_at',
            'orders_status_count',
            'originated_from_orders',
          ],
          include: ['first_order.service', 'recurrence', 'user'],
        },
      })

      const { data } = response

      if (data) {
        return convertOrderOverview(data, isPostPaid!)
      }
    },
    {
      enabled: Boolean(hasViewPermission && orderId && isPostPaid !== undefined),
    }
  )
}

export default useOrderOverviewQuery
