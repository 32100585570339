import React from 'react'
import { Form } from 'antd'

import { Group, GroupRegister } from 'domain/groups/types'
import { SubmitButton, TextInput } from 'ui'
import { useEditGroup } from '../services'

interface Props {
  group: Group
  onSuccess: (group: Group) => void
}

export default function EditGroupForm({ group, onSuccess }: Props) {
  const [form] = Form.useForm()
  const { editGroup, loading } = useEditGroup(onSuccess)

  const onFinish = (formData: GroupRegister) => {
    editGroup(group.id, formData)
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      requiredMark={false}
      scrollToFirstError
      className="max-w-lg mt-5"
      initialValues={group}
    >
      <TextInput
        label="Nome"
        name="name"
        placeholder="Digite o nome do grupo"
        maxLength={40}
        required
        autoFocus
      />

      <SubmitButton label="Editar" loading={loading} />
    </Form>
  )
}