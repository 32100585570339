import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { TIPO_PESSOA, URL_UF } = FieldName

type CertidaoMPECivilFields = Record<
  FieldName.URL_UF | FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ,
  FieldConfig
>

class CertidaoMPECivil extends Certificate {
  formFields: CertidaoMPECivilFields = {
    [URL_UF]: Fields[URL_UF],
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
  }

  constructor() {
    super({
      id: 54,
      availableFormatsFields: [URL_UF],
      priceByFields: [TIPO_PESSOA, URL_UF],
      shortName: 'MPE Civil',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoMPECivil().initialize()

export default service
