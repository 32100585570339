import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { StatusCode } from '../types'

export interface Statistic {
  count: string
  status: StatusCode
}

function useOrdersStatisticsQuery() {
  return useQuery('orders-statistics', async () => {
    const response: AxiosResponse<Statistic[]> = await AuthorizedAPI.get('/reports/number-orders-per-status')
    return response.data
  })
}

export default useOrdersStatisticsQuery
