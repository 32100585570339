import React from 'react'
import { ExportAI } from 'domain/orderItem/ai'

interface Props {
  downloadButton: React.ReactElement
  expirationDateButton?: React.ReactElement
}

export default function SidebarContainer({ downloadButton, expirationDateButton }: Props) {
  return (
    <div className="flex justify-center flex-col items-start w-full gap-2">
      {downloadButton}
      
      {expirationDateButton}
      
      <ExportAI />
    </div>
  )
}
