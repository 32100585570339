import React from 'react'

import { useLogout } from 'domain/auth'

function LogoutMenu() {
  const logout = useLogout()

  return (
    <a
      href="#"
      onClick={e => {
        e.preventDefault()
        logout()
      }}
    >
      Sair
    </a>
  )
}

export default LogoutMenu
