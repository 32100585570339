import React from 'react'
import saveAs from 'file-saver'

import { ExportButton } from 'domain/invoices/components'
import { useGetSelectedMonth, useGetPostpaidSortBy } from 'domain/invoices/state'
import generatePostpaidExcel from '../helpers/generatePostpaidExcel'
import fetchExportPostpaidInvoices from '../services/fetchExportPostpaidInvoices'

function ExportPostpaidInvoicesExcel() {
  const selectedMonth = useGetSelectedMonth()

  return (
    <ExportButton
      kind="excel"
      fetchData={async () => {
        const month = selectedMonth
        const data = await fetchExportPostpaidInvoices(month)
        const buffer = await generatePostpaidExcel(data)

        saveAs(new Blob([buffer]), `Faturas-CBRDoc_${month.getMonth() + 1}/${month.getFullYear()}.xlsx`)
      }}
    />
  )
}

export default ExportPostpaidInvoicesExcel
