import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { SelectedService } from 'domain/createOrder/types'
import { KitLegalEntity } from 'domain/kits/types'
import { Location } from 'domain/servicesCBRdoc/types'

import { getServiceConfig } from '../products'

type Params = {
  federativeUnit: string | undefined
  legalEntity: KitLegalEntity | undefined
  service: SelectedService
}

function useServiceCitiesQuery({ federativeUnit, legalEntity, service } : Params) {
  return useQuery(
    [`service-cities-${service.id}-${service.categoryId}`, federativeUnit],
    async () => {
      const response: AxiosResponse<Location[]> = await AuthorizedAPI.get(
        `services/${service.id}/categories/${service.categoryId}/federative-units/${federativeUnit}`
      )

      const serviceConfig = getServiceConfig(service.code!)

      if (serviceConfig.getAvailableCities) {
        return serviceConfig.getAvailableCities(response.data, legalEntity)
      }

      return response.data
    },
    {
      enabled: Boolean(service.id && service.categoryId && federativeUnit),
    }
  )
}

export default useServiceCitiesQuery
