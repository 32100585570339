import { atom, useAtom, useSetAtom, useAtomValue } from 'jotai'

import queryClient from 'app/config/queryClient'
import { OrderItem } from '../../types'

const orderItem = atom<OrderItem>({} as OrderItem)

export const useOrderItem = () => useAtom(orderItem)
export const useGetOrderItem = () => useAtomValue(orderItem)
export const useSetOrderItem = () => useSetAtom(orderItem)

export const useViewOrderItem = (): [OrderItem, () => void] => {
  const state = useAtomValue(orderItem)

  const refresh = () => {
    queryClient.invalidateQueries('order-item')
  }

  return [state, refresh]
}
