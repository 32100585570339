import React from 'react'

interface Props {
  children: JSX.Element
  color?: 'green' | 'orange' | 'red'
  title?: string
  className?: string
  counter: number
  dot?: boolean
}

export default function Badge({ children, color = 'red', title, className, counter, dot }: Props) {
  return (
    <span className={`relative ${className}`}>
      {children}
      <span
        className={`
          absolute inline-block transform translate-x-1/2 -translate-y-2  
          border-1 border-white ${colors[color]} flex justify-center items-center ${
          dot
            ? counter > 0
              ? 'w-2 h-2 rounded-full top-1 right-1'
              : ''
            : 'py-0.5 px-1 text-white text-2xs min-w-5 min-h-4 rounded-md -top-2 right-0.5'
        }`}
        title={title}
      >
        {dot ? null : counter}
      </span>
    </span>
  )
}

const colors = {
  green: 'bg-green-600',
  red: 'bg-red-400',
  orange: 'bg-orange-400',
}
