import React, { useState } from 'react'

import { OrderList, SelectedOrders } from 'domain/orders/types'

import OrdersTable from './OrdersTable'
import OrdersMassActions from './actions/OrdersMassActions'

interface Props {
  orders: OrderList[]
  loading: boolean
}

export default function OrdersList({ orders, loading }: Props) {
  const [selected, setSelected] = useState<SelectedOrders>({ orders: [], ids: [] })

  const onFinishAction = () => {
    setSelected({ orders: [], ids: [] })
  }

  return (
    <>
      <OrdersTable orders={orders!} loading={loading} selectedIds={selected.ids} setSelected={setSelected} />
      <OrdersMassActions selected={selected.orders} onClose={onFinishAction} />
    </>
  )
}
