import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

import { MassSelection } from 'domain/app/types'
import { PostpaidInvoice, PrepaidInvoice } from '../types'
import { currentPageAtom } from './pagination'

const initialState: MassSelection<PostpaidInvoice | PrepaidInvoice> = { keys: [], rows: [] }

const selection = atom(initialState)

const resetSelectedRows = atom(
  () => null,
  (get, set) => {
    set(currentPageAtom, 1)
    set(selection, initialState)
  }
)

export const useGetTableSelection = () => useAtomValue(selection)
export const useResetTableSelection = () => useSetAtom(resetSelectedRows)

export const useTableSelection = () => useAtom(selection)
