import { Group } from 'domain/groups/types'
import { OrderItemListing } from 'domain/orderItem/types'

const getUniqueGroups = (orderItems: OrderItemListing[]) => {
  const groups = orderItems?.flatMap(o => o.groups).filter(g => g)
  const uniqueGroups: Group[] = []

  for (const i in groups) {
    if (!uniqueGroups.find(g => g.id === groups[i].id)) uniqueGroups.push(groups[i])
  }

  return uniqueGroups
}

export default getUniqueGroups
