import { Form, Input, InputProps } from 'antd'
import { NamePath } from 'antd/lib/form/interface'

import { onlyNumbersKeyPress } from 'utils/validators'
import { onlyNumbers } from 'utils/formatters'

export interface NumberInputProps extends Omit<InputProps, 'form' | 'name' | 'onChange'> {
  label?: string
  onChange?: (value: string) => void
  rules?: Record<string, any>[]
  noStyle?: boolean
  name: NamePath
}

export default function NumberInput({
  label,
  name,
  required,
  onChange,
  noStyle,
  className = 'w-full',
  maxLength = 20,
  min,
  max,
  rules = required ? requiredRule(min, max) : undefined,
  ...props
}: NumberInputProps) {
  const form = Form.useFormInstance()

  return (
    <Form.Item
      name={name}
      label={required ? label : `${label} (opcional)`}
      rules={rules}
      noStyle={noStyle}
      validateFirst
    >
      <Input
        onKeyDown={onlyNumbersKeyPress}
        onChange={e => {
          const numbers = onlyNumbers(e.target.value)
          form.setFieldValue(name, numbers)
          onChange && onChange(numbers)
        }}
        // Não funciona com type="number"
        // Não funciona se passar o valor como número
        maxLength={String(maxLength) as unknown as number}
        className={className}
        data-hj-allow
        {...props}
      />
    </Form.Item>
  )
}

const requiredRule = (min?: string | number, max?: string | number) => {
  return [
    {
      required: true,
      message: 'Campo obrigatório',
    },
    {
      validator: (_: any, value: any) => {
        value = Number(onlyNumbers(value))
        if (min && value < Number(min)) return Promise.reject(`Campo deve ser no mínimo ${min}.`)
        if (max && value > Number(max)) return Promise.reject(`Campo deve ser no máximo ${max}`)
        return Promise.resolve()
      },
    },
  ]
}
