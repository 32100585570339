import React, { useState } from 'react'

import { OrderItemListing } from 'domain/orderItem/types'
import { ServiceType } from 'domain/servicesCBRdoc/types'
import { DrawerActions } from 'ui'

import MassDownload from './OrderItemsMassDownload'
import MassRecurrence from './OrderItemsMassRecurrence'
import MassRedo from './OrderItemsMassRedo'
import MassIADownload from './OrderItemsMassAIDownload'

interface Props {
  selectedItems: OrderItemListing[]
  onClose: () => void
}

function OrderItemsMassActions({ selectedItems, onClose }: Props) {
  const [enableEsc, setEnableEsc] = useState(true)
  const itemsWithAiDownload = selectedItems.filter(item => item.hasAiExtractData)
  const itemsToRedo = selectedItems.filter(item => item.service.type !== ServiceType.AI)

  return (
    <DrawerActions onClose={onClose} total={selectedItems.length} keyboard={enableEsc}>
      <MassDownload orderItems={selectedItems} setEnableEsc={setEnableEsc} />
      <MassRedo orderItems={itemsToRedo} />
      <MassRecurrence orderItems={selectedItems} onSuccess={onClose} />
      {!!itemsWithAiDownload.length && <MassIADownload orderItems={itemsWithAiDownload} />}
    </DrawerActions>
  )
}

export default OrderItemsMassActions
