import React from 'react'
import { Checkbox, List } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { ITEMS_PER_PAGE } from 'app/constants'
import { useGetOrderItem } from 'domain/orderItem/shared'
import { ResearchResult } from 'domain/orderItem/types'
import { CardView } from 'ui'

import { useClearSelectedResults, useGetSelectedResults, useSelectResearchResults } from '../state/selectedResearchResults'

type Props = {
  dataSource: any[]
  columns: any[]
}

const ResearchResultCards = ({ dataSource, columns }: Props) => {
  const orderItem = useGetOrderItem()
  const selectedResults = useGetSelectedResults()
  const selectResults = useSelectResearchResults()
  const clearSelected = useClearSelectedResults()
  const selectedOrder = selectedResults.find(s => s.orderItem.id === orderItem.id)
  const selectedRowKeys = selectedOrder?.selectedResultsKeys ?? []
  const indeterminate = selectedRowKeys.length > 0 && selectedRowKeys.length < dataSource.length

  const handleChangeAll = (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      selectResults({
        selected: [...dataSource],
        selectedResultsKeys: dataSource.map(d => d.id),
        orderItem,
      })
      return
    }

    clearSelected()
  }

  return (
    <>
      <div className="my-4">
        <Checkbox
          className="mt-2"
          checked={selectedRowKeys.length === dataSource.length}
          indeterminate={indeterminate}
          onChange={handleChangeAll}
        >
          Selecionar todos
        </Checkbox>
      </div>
      <List
        dataSource={dataSource}
        pagination={{
          pageSize: ITEMS_PER_PAGE,
        }}
        renderItem={(result: ResearchResult) => {
          const value = (key: string | string[]) => {
            if (Array.isArray(key)) {
              let temp: any = result
              key.forEach(k => {
                temp = temp[k] ? temp[k] : ''
              })
              return temp
            }

            return result[key as keyof typeof result]
          }

          return (
            <CardView
              key={result.matricula}
              variant={selectedRowKeys.includes(result.id) ? 'selected' : 'normal'}
            >
              <>
                <div className="flex justify-between">
                  <Checkbox
                    className="mt-2"
                    checked={selectedRowKeys.includes(result.id)}
                    onChange={ev =>
                      ev.target.checked &&
                      selectResults({
                        selected: selectedOrder?.selected ? [...selectedOrder.selected, result] : [result],
                        selectedResultsKeys: selectedRowKeys ? [...selectedRowKeys, result.id] : [result.id],
                        orderItem,
                      })
                    }
                  />
                  {columns
                    .find((a: { dataIndex: string }) => a.dataIndex === 'actions')
                    ?.render?.('', result)}
                </div>
                {columns.map(
                  (column: any, colIndex: number) =>
                    column.dataIndex !== 'actions' && (
                      <CardView.Item
                        key={column.dataIndex}
                        label={column.title}
                        value={column.render?.(value(column.dataIndex), result) || value(column.dataIndex)}
                        divider={colIndex < columns.length}
                      />
                    )
                )}
              </>
            </CardView>
          )
        }}
      />
    </>
  )
}

export default ResearchResultCards
