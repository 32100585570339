import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs, SelectLocationFields } from 'domain/createOrder/document'
import { TextField } from 'domain/servicesCBRdoc/fields'
import { CNPJInput, CPFInput } from 'ui'

import config from '../certidaoDadosCadastraisImovelConfig'

function CertidaoDadosCadastraisImovel() {
  return (
    <CertificateFlow
      header={
        <>
          <p className="mb-5 text-gray-600">
            Primeiro selecione o estado e a cidade, depois selecione Pessoa ou Empresa e digite o número do
            CPF ou CNPJ e inscrição imobiliária que deseja a certidão.
          </p>

          <p className="mb-5 text-gray-600">
            Disponibilidade de entrega imediata pode sofrer impactos mediante alterações sistêmicas de cada
            estado do órgão emissor.
          </p>

          <SelectLocationFields autoFocus />
        </>
      }
      renderFormItem={(itemId: number) => <CertidaoDadosCadastraisImovelForm itemId={itemId} />}
    />
  )
}

const CertidaoDadosCadastraisImovelForm = ({ itemId }: { itemId: number }) => {
  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={<CPFInput itemId={itemId} />}
        juridica={<CNPJInput itemId={itemId} />}
      />

      <TextField itemId={itemId} field={inscricao_imovel} />
    </>
  )
}

const { inscricao_imovel } = config.formFields

export default CertidaoDadosCadastraisImovel
