import React from 'react'

import { useServicePriceQuery } from 'domain/createOrder/document'
import { TotalPriceForCustomer } from 'domain/createOrder/shared/components'

function DueDiligenceTotalPrice() {
  const { data, isLoading } = useServicePriceQuery({
    origin: 'diligence',
  })

  return <TotalPriceForCustomer loading={isLoading} prices={data} />
}

export default DueDiligenceTotalPrice
