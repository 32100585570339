import { ResearchFlow } from 'domain/createOrder/researches'
import { DateField, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../pesquisaParticipacaoSocietariaConfig'

export default function CreateOrderPesquisaParticipacaoSocietaria() {
  return (
    <ResearchFlow
      renderFormItem={itemId => <PesquisaParticipacaoSocietariaForm itemId={itemId} />}
    />
  )
}

function PesquisaParticipacaoSocietariaForm({ itemId }: { itemId: number }) {
  return (
    <>
      <CPFInput itemId={itemId} autoFocus={itemId === 0} />
      <NameField itemId={itemId} field={nome_pf} />
      <DateField itemId={itemId} field={nascimento} />
    </>
  )
}

const { nascimento, nome_pf } = config.formFields
