import React from 'react'

import ExportPostpaidInvoicesExcel from './ExportPostpaidInvoicesExcel'
import ExportPostpaidInvoicesPDF from './ExportPostpaidInvoicesPDF'

function ExportPostpaidInvoices() {
  return (
    <div className="ml-4 flex flex-col gap-2 justify-between">
      <ExportPostpaidInvoicesExcel />
      <ExportPostpaidInvoicesPDF />
    </div>
  )
}

export default ExportPostpaidInvoices
