import { LabelInValue } from 'domain/app/types'
import { getFieldName } from 'domain/createOrder/shared'
import { Fields } from 'domain/servicesCBRdoc/config'
import { TextField } from 'domain/servicesCBRdoc/fields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { CNPJInput, CPFInput, NumberInput } from 'ui'

interface Props {
  itemId: number
  selectedType: LabelInValue
  label?: string
}

const inscriptionField: FieldConfig = {
  ...Fields.inscricao_estadual,
  name: FieldName.INSCRICAO,
}

export default function RegisterInput({ itemId, selectedType }: Props) {
  const { label, value } = selectedType

  if (value === 'cpf') {
    return <CPFInput itemId={itemId} name="inscricao" required />
  }

  if (value === 'cnpj') {
    return <CNPJInput itemId={itemId} name="inscricao" required />
  }

  if (value === 'inscricao-estadual') {
    return <TextField itemId={itemId} field={inscriptionField} required />
  }

  return (
    <NumberInput
      label={label}
      name={getFieldName('inscricao', itemId)}
      maxLength={15}
      required
    />
  )
}
