import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { errorHandler } from 'api/errors'
import { ApiStatus } from 'api/types'
import { RoutePaths } from 'app/routes'

import { createCustomerAPI } from '../customersAPI'
import { CreateAccountForm } from '../types'

function useCreateAccount() {
  const history = useHistory()
  const [status, setStatus] = useState<ApiStatus>('idle')

  const createAccount = async (cnpj: string, formData: CreateAccountForm) => {
    try {
      setStatus('loading')

      await createCustomerAPI(cnpj, formData)

      localStorage.setItem('new_user', 'true')
      history.push(RoutePaths.LOGIN + '?conta-criada')
    } catch (error) {
      setStatus('error')
      errorHandler(error, { code: '0x002'})
    }
  }

  return {
    createAccount,
    loading: status === 'loading',
  }
}

export default useCreateAccount
