import { CreateGroups, GroupsList, useSetInitialValuesFromURL } from 'domain/groups'
import { RequiredGroupsCheckbox } from 'domain/customers'

export default function SettingsGroupsPage() {
  useSetInitialValuesFromURL()

  return (
    <>
      <h2 className="text-lg text-gray-700 uppercase mb-5">Meus grupos</h2>

      <div className="flex items-center justify-between">
        <CreateGroups />
        <RequiredGroupsCheckbox />
      </div>

      <GroupsList />
    </>
  )
}
