import React from 'react'
import { useDispatch } from 'react-redux'

import { errorHandler } from 'api/errors'
import { TempFile, UploadFormData } from 'domain/app/types'
import { useAddToCart } from 'domain/createOrder/shared'
import { CreateOrderState } from 'domain/createOrder/types'

import { useCreateOrderNextStep } from '../helpers'
import { createOrderActions, useCreateOrderState } from '../state'
import UploadSection from './UploadSection'

interface Props {
  maxCount?: number
  header?: React.ReactNode
  headingTitle?: string
  children?: React.ReactNode
}

export default function UploadStep({ children, ...props }: Props) {
  const dispatch = useDispatch()
  const [goToNextStep, nextStep] = useCreateOrderNextStep('upload')
  const createOrderState = useCreateOrderState()
  const { files } = createOrderState
  const addToCart = useAddToCart()

  const onChange = (files: TempFile[]) => {
    dispatch(createOrderActions.setFiles(files))
  }

  const onFinish = ({ files, ...otherFields }: UploadFormData) => {
    try {
      dispatch(createOrderActions.setFiles(files))
      dispatch(createOrderActions.updateFormData(otherFields))

      if (nextStep === 'cart') {
        const order: CreateOrderState = {
          ...createOrderState,
          files,
        }

        addToCart(order)
      }

      goToNextStep()
    } catch (error: any) {
      errorHandler(error)
    }
  }

  return (
    <UploadSection
      name="files"
      defaultFileList={files}      
      buttonLabel="Continuar"
      onChange={onChange}
      onFinish={onFinish}
      isFinalStep={nextStep === 'cart'}
      {...props}
    >
      {children}
    </UploadSection>
  )
}
