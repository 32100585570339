import React from 'react'
import { Dayjs } from 'dayjs'

import Icon from 'ui/Icon'

interface Props {
  direction: 'right' | 'left'
  date: Dayjs
  setDate: (value: Dayjs) => void
}

export default function MonthPickerNavButton({ direction, date, setDate }: Props) {
  return (
    <button
      className="px-2 outline-none text-white text-base bg-secondary-500 hover:bg-gray-700 hover:bg-opacity-50 transition-colors duration-150"
      onClick={() => {
        const subtractOrAdd = direction === 'left' ? 'subtract' : 'add'
        const newDate = date[subtractOrAdd](1, 'months')
        setDate(newDate)
      }}
    >
      <Icon name={`chevron-${direction}`} className="text-xl" />
    </button>
  )
}
