import { useHistory } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { useHasPermission } from 'domain/auth'
import { BrandButton, Icon } from 'ui'

type Props = { collapsed: boolean }

export default function SideNavOrderButton({ collapsed }: Props) {
  const history = useHistory()
  const canOrder = useHasPermission('canOrderServices')

  if (!canOrder) return null

  return (
    <BrandButton className="mb-8 font-semibold" onClick={() => history.push(RoutePaths.CREATE_ORDER)} block>
      {!collapsed && <span>Fazer pedido</span>}
      <Icon name="paper-plane" className="text-2xl" />
    </BrandButton>
  )
}
