import { useKit } from 'domain/createOrder/kit/state'
import { KitItem } from 'domain/kits/types'

import PlaceOfBirthFields from './PlaceOfBirthFields'

interface Props {
  kitItem: KitItem
}

export default function KitOrderCertidaoInterdicao({ kitItem }: Props) {
  const [kit] = useKit()

  if (kit.legalEntity === 'juridica') {
    return null
  }

  return <PlaceOfBirthFields itemId={kitItem.id} />
}
