import { useMutation } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'

function useDeleteNotification() {
  const mutation = useMutation(
    async (id: number) => {
      return await AuthorizedAPI.delete(`/notifications/${id}`)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries({ queryKey: ['notifications'] })
      },
    }
  )

  return mutation.mutate
}

export default useDeleteNotification
