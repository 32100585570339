import React from 'react'
import { Modal } from 'antd'
import { Button } from 'ui'

import useResetShoppingCart from '../state/useResetShoppingCart'

function ClearCartButton() {
  const resetCart = useResetShoppingCart()

  return (
    <Button
      onClick={() => {
        Modal.confirm({
          title: 'Limpar carrinho',
          content: 'Tem certeza que deseja apagar o carrinho?',
          okText: 'Apagar',
          cancelText: 'Cancelar',
          onOk: resetCart,
        })
      }}
      type="primary"
      size="small"
      ghost
    >
      Limpar Carrinho
    </Button>
  )
}

export default ClearCartButton
