import { useState } from 'react'
import { AxiosResponse } from 'axios'

import { errorHandler } from 'api/errors'
import { AuthorizedAPI } from 'api/network/v2'

function useRequestCancellation(orderItemId: number, { onSuccess }: { onSuccess: () => void }) {
  const [loading, setLoading] = useState(false)

  const requestCancellation = async () => {
    try {
      setLoading(true)

      await requestCancellationAPI(orderItemId)

      onSuccess()
    } catch (err) {
      errorHandler(err, { params: { orderItemId }, code: '0x933' })
    } finally {
      setLoading(false)
    }
  }

  return { requestCancellation, loading }
}

const requestCancellationAPI = async (orderId: number) => {
  const response: AxiosResponse<any> = await AuthorizedAPI.post(`/orders/${orderId}/cancel`)
  return response.data
} 

export default useRequestCancellation
