import React, { useMemo } from 'react'
import { Alert, Card, Form } from 'antd'
import { useHistory } from 'react-router'

import { errorHandler } from 'api/errors'
import { RoutePaths } from 'app/routes'
import { SelectedService } from 'domain/createOrder/types'
import { createKit } from 'domain/kits/services'
import { KitItem, KitItemDefaultData } from 'domain/kits/types'
import { SERVICES_WITH_FIXABLE_DATA } from 'domain/servicesCBRdoc/products'
import { Section } from 'layouts'
import { useSetViewKitState } from 'state/kits'
import { Button, Icon } from 'ui'
import { useScrollTo } from 'utils/screen'

import PanelHeader from '../registerKit/KitItemPanelHeader'
import KitItemForm from '../registerKit/KitItemForm'
import {
  useCreateKit,
  useCreateKitAddItem,
  useCreateKitUpdateDefaultData,
  useSetCreateKit,
} from './CreateKitContext'
import RemoveKitItem from './RemoveKitItem'

type KitItemsByService = {
  [index: string]: {
    service: SelectedService
    items: KitItem[]
  }
}

export default function CreateKitStep3() {
  const kit = useCreateKit()
  const setKit = useSetCreateKit()
  const setViewKit = useSetViewKitState()
  const addKitService = useCreateKitAddItem()
  const history = useHistory()

  const onFinish = async () => {
    setKit({ saving: true })

    try {
      const newKit = await createKit(kit)
      setViewKit(newKit)
      history.push(RoutePaths.CREATE_KIT_SUCCESS)
    } catch (error) {
      setKit({ saving: false })
      errorHandler(error, { code: '0x803' })
    }
  }

  const fixableItems = kit.items.filter(item => SERVICES_WITH_FIXABLE_DATA.includes(item.service.code!))
  // Os items são agrupados por serviço e categoria
  // Dessa forma, o formulário exibe agrupados itens de mesmo serviço/categoria ao clicar no botão Adicionar
  const fixableItemsByService = useMemo(() => {
    const fixableItems = kit.items.filter(item => SERVICES_WITH_FIXABLE_DATA.includes(item.service.code!))
    const byServiceMap: KitItemsByService = {}

    fixableItems.forEach(item => {
      const serviceKey = `${item.service.code}_${item.service.categoryId}`

      if (!byServiceMap[serviceKey]) {
        byServiceMap[serviceKey] = {
          service: item.service,
          items: [item],
        }
      } else {
        byServiceMap[serviceKey].items.push(item)
      }
    })

    return byServiceMap
  }, [kit.items])

  const duplicateKitItem = (item: KitItem) => () => {
    const defaultData: any = {
      tipo_pessoa: item.defaultData?.tipo_pessoa,
    }

    addKitService({
      service: item.service,
      defaultData,
    })
  }

  useScrollTo('page-title')

  return (
    <Section heading="Fixar dados do pedido" id="page-title">
      <Alert
        message={
          <div className="flex items-center justify-start">
            <Icon name="light-bulb" className="mr-3 text-lg text-yellow-500" />
            <p className="text-xs text-gray-700">
              Lembre-se que este não é um passo obrigatório, caso não deseje fixar os campos.
            </p>
          </div>
        }
        type="warning"
        closable
        className="text-secondary-500 mb-8"
      />

      <Form id="kit-register-form" onFinish={onFinish}></Form>

      {fixableItems.length > 0 &&
        Object.keys(fixableItemsByService).map(serviceKey => (
          <React.Fragment key={serviceKey}>
            {fixableItemsByService[serviceKey].items.map(item => (
              <Card
                key={item.id}
                title={<PanelHeader categoryId={item.service.categoryId} serviceId={item.service.id} />}
                className="mb-3"
                extra={<RemoveKitItem itemId={item.id} />}
              >
                <PanelContent item={item} />
              </Card>
            ))}

            <div className="flex justify-end mb-8">
              <Button
                onClick={duplicateKitItem(fixableItemsByService[serviceKey].items[0])}
                size="small"
                type="primary"
                ghost
                icon="plus-circle" 
              >
                Adicionar
              </Button>
            </div>
          </React.Fragment>
        ))}

      {fixableItems.length === 0 && (
        <p className="text-secondary-500 font-bold">
          Não existem dados a serem fixos para estes tipos de documentos.
        </p>
      )}
    </Section>
  )
}

function PanelContent({ item }: { item: KitItem }) {
  const { legalEntity } = useCreateKit()
  const updateKitItemDefaultData = useCreateKitUpdateDefaultData()

  const onChange = (data: Partial<KitItemDefaultData>) => updateKitItemDefaultData(item, data)

  return <KitItemForm kitLegalEntity={legalEntity!} item={item} onChange={onChange} />
}
