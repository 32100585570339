import React from 'react'
import { Link } from 'react-router-dom'

import { Routes } from 'app/routes'
import { useGetExplorerItem, useSetAction } from 'domain/myFiles/state'
import { Icon, TextButton } from 'ui'

import MoveItemModal from './MoveItemModal'

export default function MoveItem() {
  const explorerItem = useGetExplorerItem()
  const setAction = useSetAction()

  return (
    <div className="mb-3">
      <div className="text-xs mt-1 text-gray-500">Localização</div>

      <div className="flex flex-row justify-between items-center">
        <ul>
          <li>
            <span className="flex flex-row items-center gap-2">
              <Icon name="folder-minus" className="text-4xs mb-1" />
              Meus arquivos
            </span>
            <ul className="start">
              <>
                {explorerItem?.breadcrumb?.map((item: any) => {
                  if (item.id === explorerItem.id) return null
                  const itemUrl = Routes.MyFilesFolder(item.id)

                  return (
                    <li key={item.id}>
                      <Icon name="folder" style={{ height: '3em' }} className="text-4xs mx-1" />
                      <Link to={itemUrl} className="text-gray-700 font-semibold hover:opacity-50">
                        {item.name}
                      </Link>
                    </li>
                  )
                })}
              </>
            </ul>
          </li>
        </ul>

        <TextButton label="Mover para" onClick={() => setAction('move')} />
      </div>

      <MoveItemModal selectedItems={[explorerItem!]} />
    </div>
  )
}
