import { Spin } from 'antd'
import { AuthorizedAPI } from 'api/network/v2'
import { OrderItem } from 'domain/orderItem/types'
import { SignatureCertificationCode } from 'domain/servicesCBRdoc/types'
import { useQuery } from 'react-query'
import { Icon } from 'ui'

interface Props {
  orderItem: OrderItem
}

type SignatureCollectionStatus = {
  assinado: boolean
  cpf: string
  nome: string
  email: string
}

export default function SignatureCollectionStatus({ orderItem }: Props) {
  const { data, isLoading, isFetching } = useQuery([orderItem.id, 'signatureCollectionStatus'], async () => {
    const response = await AuthorizedAPI.get<SignatureCollectionStatus[]>(
      `/orders/${orderItem.id}/detailed-progress`
    )
    return response.data
  }, { enabled: orderItem.service.code === SignatureCertificationCode.COLETA_ASSINATURA })

  if (orderItem.service.code !== SignatureCertificationCode.COLETA_ASSINATURA) {
    return null
  }

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className="border border-gray-200 flex flex-col items-center justify-center p-6 mx-2">
        <h1 className="mb-4 text-gray-700 text-sm font-bold">Status atual da coleta de assinaturas:</h1>
        {data?.map(item => (
          <div key={item.cpf} className="flex items-center justify-between mb-2">
            <div
              className={`flex items-center justify-center rounded-full w-6 h-6 mr-2 ${
                item.assinado ? 'bg-green-400' : 'bg-gray-200'
              }`}
            >
              {item.assinado && <Icon name="check" className="w-4 h-4 text-white" />}
            </div>
            <div className="text-gray-700 font-normal text-sm mt-1">
              {item.nome} - {item.cpf} - {item.email}
            </div>
          </div>
        ))}
      </div>
    </Spin>
  )
}
