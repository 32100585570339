import { WaitingInvoicePayment, WaitingInvoicePaymentAPI } from 'domain/createOrder/types'
import { CustomerAPI } from 'domain/customers/types'
import { OrderItemListing } from 'domain/orderItem/types'
import { OrderItemAPI } from 'domain/orderItem/typesAPI'
import { RecurrenceAPI } from 'domain/recurrences/types'
import { Location } from 'domain/servicesCBRdoc/types'
import { User } from 'domain/users/types'

export interface ItemsFilters {
  nameOrId: ''
  statusCodes: StatusCode[]
  results: OrderResult[]
  createdFromRecurrence?: boolean
  ocrSearch?: boolean
  recurrenceId?: number
  ocrContent: string
}

export enum StatusCode {
  ACAO_NECESSARIA = 'waiting-action',
  AGUARDANDO_PAGAMENTO = 'waiting-payment',
  AGUARDANDO_RESPOSTA = 'waiting-response',
  AGUARDANDO_APROVACAO = 'waiting-approval',
  CANCELADO = 'canceled',
  FINALIZADO = 'finished',
  INDISPONIVEL = 'unavailable',
  PROCESSANDO = 'processing',
  REEMBOLSADO = 'refunded',
}

export enum ResultCode {
  INVALID = 'invalid',
  POSITIVE = 'positive',
  POSITIVE_NEGATIVE_EFFECT = 'positive-negative-effect',
  POSITIVE_SUPENDED_LIABILITY = 'positive-suspended-liability',
  NEGATIVE = 'negative',
}

type OrderResultsConfig = {
  label: string
  color: string
  shortLabel: string
}

export const OrderResults: Record<ResultCode, OrderResultsConfig> = {
  [ResultCode.INVALID]: {
    label: 'Inválido',
    color: 'default',
    shortLabel: 'Inválido',
  },
  [ResultCode.NEGATIVE]: {
    label: 'Negativo',
    color: 'processing',
    shortLabel: 'Negativo',
  },
  [ResultCode.POSITIVE]: {
    label: 'Positivo',
    color: 'error',
    shortLabel: 'Positivo',
  },
  [ResultCode.POSITIVE_NEGATIVE_EFFECT]: {
    label: 'Positivo com efeito negativo',
    color: 'error',
    shortLabel: 'Positivo E.N.',
  },
  [ResultCode.POSITIVE_SUPENDED_LIABILITY]: {
    label: 'Positivo com exigibilidade suspensa',
    color: 'error',
    shortLabel: 'Positivo E.S.',
  },
}

export type OrderResult = typeof OrderResults

export const OrderStatuses: {
  [key in StatusCode]: OrderStatus
} = {
  [StatusCode.PROCESSANDO]: {
    code: StatusCode.PROCESSANDO,
    name: 'Processando',
    color: 'secondary',
    colorHexa: '#4e78e3',
  },
  [StatusCode.FINALIZADO]: {
    code: StatusCode.FINALIZADO,
    name: 'Finalizado',
    color: 'success',
    colorHexa: '#5dd5a2',
  },
  [StatusCode.ACAO_NECESSARIA]: {
    code: StatusCode.ACAO_NECESSARIA,
    name: 'Ação necessária',
    color: 'red-500',
    colorHexa: '#ff7d5a',
  },
  [StatusCode.REEMBOLSADO]: {
    code: StatusCode.REEMBOLSADO,
    name: 'Reembolsado',
    color: 'red-200',
    colorHexa: '#ff9e89',
  },
  [StatusCode.CANCELADO]: {
    code: StatusCode.CANCELADO,
    name: 'Cancelado',
    color: 'neutral',
    colorHexa: '#ea5040',
  },
  [StatusCode.AGUARDANDO_PAGAMENTO]: {
    code: StatusCode.AGUARDANDO_PAGAMENTO,
    name: 'Aguardando pagamento',
    color: 'yellow-300',
    colorHexa: '#f5cb76',
  },
  [StatusCode.AGUARDANDO_RESPOSTA]: {
    code: StatusCode.AGUARDANDO_RESPOSTA,
    name: 'Aguardando resposta',
    color: 'yellow-300',
    colorHexa: '#f5cb76',
  },
  [StatusCode.AGUARDANDO_APROVACAO]: {
    code: StatusCode.AGUARDANDO_APROVACAO,
    name: 'Aguardando aprovação',
    color: 'yellow-300',
    colorHexa: '#f5cb76',
  },
  [StatusCode.INDISPONIVEL]: {
    code: StatusCode.INDISPONIVEL,
    name: 'Indisponível',
    color: 'yellow-300',
    colorHexa: '#f5cb76',
  },
}

export type OrderServiceType = 'inteiroteor' | 'breverelato' | 'completo'

export interface OrderStatus {
  code: StatusCode
  color: string
  colorHexa: string
  name: string
}

export interface InvolvedPart {
  document: string
  name: string
  polo: string
}

export interface LocationFederativeUnit extends Location {
  abbreviation: string | null
}

export enum OrderType {
  AI = 'AI',
  CERTIFICATE = 'Certificate',
  RESEARCH = 'Research',
  MIXED = 'Mixed',
}

export interface OrderAPI {
  backoffice_id: number
  concludedOrdersCount: number
  customer: CustomerAPI
  downloadable_orders_ids?: number[]
  downloaded_orders_ids?: number[]
  estimated_at?: string
  first_order?: {
    id: number
    register: string
  }
  has_ai_analysis_pending: boolean
  has_ai_extracted_data: boolean
  has_consumed_ai_free_analysis: boolean
  id: number
  imported_at?: string
  last_status_change_at?: string
  orders: OrderItemAPI[]
  orders_count?: number
  orders_status_count?: StatusCount[]
  ordersCount: number
  originated_from_orders: { id: number; backoffice_id: number }[]
  placed_at: Date
  quote_appraiser_id: number | null
  quoted_cost_postpaid_customer: number | null
  quoted_rejected_reason: string | null
  name: string
  recurrence?: RecurrenceAPI
  result: OrderResult | null
  shipping_address_city: string
  shipping_address_complement: string | null
  shipping_address_country: string | null
  shipping_address_is_international: number
  shipping_address_neighborhood: string
  shipping_address_number: string
  shipping_address_public_place: string
  shipping_address_uf: string
  shipping_address_zip_code: string
  short_name: string | null
  status: StatusCode
  total_cost: number
  total_estimated_cost_postpaid_customer?: number
  type: OrderType
  user: User
  waiting_invoice_payment?: WaitingInvoicePaymentAPI
}

export type StatusCount = {
  count: number
  status: StatusCode
}

export type OrderLatestView = {
  date: Date
  id: number
  name: string
  serviceCode: string
}

export type OrderDetails = {
  orderName: string
}

export type OrdersTab = 'orders' | 'items'

export type OrderOrigin = { id: number; orderNumber: number }

export interface BasicOrderData {
  id: number
  name: string
  orderNumber: number
}

export interface OrderList extends BasicOrderData {
  createdAt: Date
  createdAtDisplay: string
  downloadableItemsIds: number[]
  hasAiAnalysisPending: boolean
  hasAiExtractData: boolean
  priceQuotationDisplay: string
  recurrence?: RecurrenceOrigin
  register: string
  statusCode: StatusCode
  totalConcludedItems: number // Itens finalizados, cancelados, reembolsados ou com problema na entrega
  totalItems: number
  type: OrderType
  updatedAtDisplay: string
  user: Pick<User, 'id' | 'name'>
}

export type OrderPaymentInfo = {
  isPriceQuotationApproved: boolean
  priceDisplay: string
  priceQuotationValue: number | null
  priceValue: number
  quotationRejectedReason: string | null
  waitingInvoicePayment?: WaitingInvoicePayment
}

export type RecurrenceOrigin = {
  id: number
  name: string
}

export interface OrderOverview extends Omit<OrderList, 'hasAiAnalysisPending'>, OrderPaymentInfo {
  downloadedItemsIds: number[]
  estimatedDeliveryAt?: string
  hasConsumedAiFreeAnalysis: boolean
  originatedFromOrders: OrderOrigin[]
  totalItemsByStatus: StatusCount[]
}

export type Address = {
  city: string
  complement?: string
  neighborhood: string
  number: string
  street: string
  uf: string
  zipCode: string
}

export interface Order extends BasicOrderData, OrderPaymentInfo {
  importedAt?: string
  deliveryAddress?: Address
  downloadableOrdersIds?: number[]
  type: OrderType
}

export type SelectedOrders = { orders: OrderList[]; ids: number[] }

export type EditOrderType = Pick<BasicOrderData, 'id'>

export type SelectedItems = { items: OrderItemListing[]; ids: number[] }

export type OrderOverviewPageParams = { id: string; page?: string }

export interface OrdersFilters {
  ai: boolean
  automaticGenerated: boolean
  dateRange: string | undefined
  groupsIds: number[]
  isOCRSearch: boolean
  isExpired: boolean | undefined
  onlyFromRecurrence: boolean
  ownersIds: number[]
  recurrenceId: number | undefined
  results: ResultCode[]
  searchTerm: string
  servicesIds: number[]
  statuses: StatusCode[]
}

export type OrderOverviewFilters = Pick<OrdersFilters, 'isExpired' | 'results' | 'statuses'>

export type OrderSortByFieldAPI =
  | 'name'
  | 'id'
  | 'placed_at'
  | 'type'
  | 'service.name'
  | 'currentStatus.name'
  | 'result'

export type OrderSortByField = OrderSortByFieldAPI | 'orderDocument'

export type OrderSortByAPIField = OrderSortByField | `-${OrderSortByField}`

export type OrderItemSortByField = OrderSortByField
export type OrderItemSortByAPIField = OrderSortByAPIField

export interface OrdersUrlParams extends Partial<OrdersFilters> {
  p?: string
}
