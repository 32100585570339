import React, { useMemo } from 'react'
import { Button, Dropdown } from 'antd'
import { useHistory } from 'react-router'
import { MenuItemType } from 'antd/lib/menu/hooks/useItems'

import { Routes } from 'app/routes'
import { useCurrentUser } from 'domain/auth'
import { Kit } from 'domain/kits/types'
import Icon from 'ui/Icon/Icon'

import DeleteKitMenuItem from '../deleteKit/DeleteKitMenuItem'
import DuplicateKitMenuItem from './DuplicateKitMenuItem'

interface Props {
  kit: Kit
  refetch: () => void
}

export default function KitsTableRowActions({ kit, refetch, ...props }: Props) {
  const history = useHistory()
  const user = useCurrentUser()
  const isOwner = kit.owner.id === user.id

  const menu = useMemo(() => {
    const items: MenuItemType[] = [
      {
        label: 'Duplicar',
        icon: <Icon name="copy" />,
        onClick: () => DuplicateKitMenuItem({ kit, refetch }),
        key: 'duplicate',
      },
    ]

    if (isOwner) {
      items.push({
        label: 'Editar',
        key: 'edit',
        icon: <Icon name="edit" />,
        onClick: () => history.push(Routes.EditKit(kit.id)),
      })
      items.push({
        label: 'Excluir',
        key: 'delete',
        icon: <Icon name="trash" />,
        danger: true,
        onClick: () => DeleteKitMenuItem({ kit, refetch }),
      })
    }

    return { items }
  }, [kit, isOwner])

  const handleButtonClick = (ev: any) => {
    ev.stopPropagation()
    history.push(Routes.CreateOrderKit(kit.id))
  }

  return (
    <div className="flex items-center" {...props}>
      <Button onClick={handleButtonClick} size="small" type="primary" className="font-xs h-8">
        Solicitar
      </Button>

      <Dropdown menu={menu} placement="bottomRight" className="text-primary max-h-8 ml-4">
        <Button type="primary" ghost size="small" icon={<Icon name="ellipsis" />} />
      </Dropdown>
    </div>
  )
}
