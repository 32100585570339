import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoNegativaFeitosFields = Record<
  FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ | FieldName.URL_UF,
  FieldConfig
>

class CertidaoNegativaFeitos extends Certificate {
  formFields: CertidaoNegativaFeitosFields = {
    url_uf: Fields.url_uf,
    cpf: Fields.cpf,
    cnpj: Fields.cnpj,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
  }

  constructor() {
    super({
      id: 102,
      priceByFields: [FieldName.URL_UF],
      shortName: 'Certidão Negativa Feitos',
      steps: ['localizacao', 'dados'],
    })
  }
}

const service = new CertidaoNegativaFeitos().initialize()

export default service
