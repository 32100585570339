import { Group } from 'domain/groups/types'

export type CheckoutStep =
  | 'idle'
  | 'in-progress'
  | 'order-details'
  | 'address'
  | 'payment'
  | 'all-good'
  | 'submitting'

export interface Pix {
  qrCode: string | undefined
  pixHash: string
  pixKey: string
  invoiceId: number
}

export interface ShoppingName {
  name: string
}

export interface ShippingAddress {
  id: string
  name: string
  address_zip_code: string
  address_neighborhood: string
  address_public_place: string
  address_number: string
  address_city: string
  address_uf: string
  address_complement: string
  address_is_international?: boolean
  address_country?: string
}

export interface Boleto {
  barcode: string
  url: string
}

export interface CheckoutData {
  groups: Group[]
  orderDetailsConfirmed: boolean
  orderName: string
  shippingAddress: ShippingAddress | null
  paymentMethod: ChosenPaymentMethod | null
}

export interface PixAPI {
  qrCode: string | undefined
  pixHash: string
  pixQRCodeString: string
  invoiceId: number
}

export type InvoiceAPI = {
  invoice_bank_slip_barcode: string
  invoice_bank_slip_url: string
  invoice_id: number
  invoice_amount: number
}

export interface ShippingAddress {
  id: string
  name: string
  address_zip_code: string
  address_neighborhood: string
  address_public_place: string
  address_number: string
  address_city: string
  address_uf: string
  address_complement: string
  address_is_international?: boolean
  address_country?: string
}

export enum PaymentMethod {
  INVOICE = 'bank-slip',
  CREDIT_CARD = 'credit-card',
  PIX = 'pix',
}

export type CreditCardData = {
  cardNumber: string
  cvv: string
  documentNumber: string
  dueDate: string
  holderName: string
}

export type Invoice = {
  bankSlipBarcode: string
  bankSliptUrl: string
}

export type ChosenPaymentMethod = {
  chosenMethod?: PaymentMethod
  pix?: Pix
  invoice?: Invoice
  bonusAmount?: number
}

export type PaymentResponseAPI = InvoiceAPI &
  PixAPI & {
    invoice_id: number
    invoice_amount: number
    invoice_bonus_amount: number
    invoice_payment_method: PaymentMethod
    invoice_send_email_after_approved: boolean
  }

export interface ValidationError {
  index: number
  errors?: {
    [index: string]: string[]
  }
}

export type PaymentMethodData = {
  method: PaymentMethod
  bankSlipInvoice?: Invoice | null
  pixData?: Pix | null
}

export interface Pix {
  qrCode: string | undefined
  pixHash: string
  //pixQRCodeString: string
  invoiceId: number
}

export interface ShoppingName {
  name: string
}

export interface ShippingAddress {
  id: string
  name: string
  address_zip_code: string
  address_neighborhood: string
  address_public_place: string
  address_number: string
  address_city: string
  address_uf: string
  address_complement: string
  address_is_international?: boolean
  address_country?: string
}

export interface Boleto {
  barcode: string
  url: string
}
