import { useDispatch } from 'react-redux'

import { useAvailableFormatsQuery } from 'domain/createOrder/certificates'
import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document/state'
import { DeliveryFormat } from 'domain/createOrder/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { Certificate } from 'domain/servicesCBRdoc/config'

function useLoadAvailableFormats() {
  const dispatch = useDispatch()
  const createOrderState = useCreateOrderState()
  const { selectedFormat, selectedService } = createOrderState

  const service = getServiceConfig<Certificate>(selectedService.code!)
  const params = service.getAvailableFormatsParams && service.getAvailableFormatsParams(createOrderState)

  return useAvailableFormatsQuery(selectedService, params, {
    onSuccess: (availableFormats: DeliveryFormat[]) => {
      if (availableFormats) {
        dispatch(createOrderActions.setAvailableFormats(availableFormats))

        if (!selectedFormat || !availableFormats.includes(selectedFormat)) {
          dispatch(
            createOrderActions.setSelectedFormat(
              availableFormats.includes('email') ? 'email' : availableFormats[0]
            )
          )
        }
      }
    },
  })
}

export default useLoadAvailableFormats
