import React from 'react'
import { Form, FormItemProps, Input } from 'antd'

interface Props extends FormItemProps {
  disabled?: boolean
  tabIndex?: number
  label?: string
  name?: string
  autoFocus?: boolean
}

export default function PasswordInput({
  disabled,
  tabIndex,
  label = 'Senha',
  name = 'password',
  required = true,
  autoFocus,
  ...props
}: Props) {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={required ? (props.rules ? props.rules : requiredRule) : undefined}
      {...props}
    >
      <Input.Password
        autoComplete="current-password"
        disabled={disabled}
        tabIndex={tabIndex}
        maxLength={255}
        autoFocus={autoFocus}
      />
    </Form.Item>
  )
}

const requiredRule = [{ required: true, message: 'Informe a senha.' }]
