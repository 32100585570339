import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Routes } from 'app/routes'
import { createOrderActions } from 'domain/createOrder/document/state'
import { AIShoppingStep } from 'domain/createOrder/types'
import { AICreateOrderModel } from 'domain/openAI/types'
import { getService, useServiceById } from 'domain/servicesCBRdoc'
import { AICode } from 'domain/servicesCBRdoc/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'

export default function useStartAIOrder() {
  const dispatch = useDispatch()
  const history = useHistory()
  const serviceConfig = getService(AICode.AI_DOCUMENT_ANALYSIS)
  const service = useServiceById(serviceConfig.id)

  return function startAIOrder(model: AICreateOrderModel) {
    if (!service) {
      throw new Error('Serviço não encontrado.')
    }

    dispatch(
      createOrderActions.startNewOrder({
        selectedService: {
          id: service.id,
          name: model.service?.label ?? 'Outro',
          code: service.code,
          categoryId: service.category1Id,
          type: service.type,
        },
        selectedFormat: 'email',
        selectedModel: model.id,
        formData: {
          [FieldName.MODELO_IA]: model.id,
        },
      })
    )

    history.push(Routes.CreateOrderAI(AIShoppingStep.ARQUIVOS))
  }
}
