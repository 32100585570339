import { useLocation } from 'react-router-dom'

function useCreateKitCurrentStep() {
  const location = useLocation()
  const currentStep: any = location.pathname.split('/').pop()

  if (['selecionar-documentos', 'fixar-dados'].includes(currentStep)) {
    return currentStep
  }

  return 'inicio'
}

export default useCreateKitCurrentStep
