import React from 'react'

import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs } from 'domain/createOrder/document'
import { CPFInput, CNPJInput } from 'ui'

export default function CreateOrderImprobabilidadeAdm() {
  return (
    <CertificateFlow renderFormItem={(itemId: number) => <CertidaoImprobidadeAdmForm itemId={itemId} />} />
  )
}

const CertidaoImprobidadeAdmForm = ({ itemId }: { itemId: number }) => {
  const inputProps = { itemId, autoFocus: itemId === 0 }

  return (
    <CreateOrderLegalEntityTabs
      itemId={itemId}
      fisica={<CPFInput {...inputProps} />}
      juridica={<CNPJInput {...inputProps} />}
    />
  )
}
