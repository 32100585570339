import React from 'react'

import { useSearchState } from 'domain/kits/state'
import { SearchInput } from 'ui'

export default function KitsSearch() {
  const [search, setSearch] = useSearchState()

  return (
    <div className="max-w-md px-7 py-5">
      <SearchInput
        className="lg:min-w-96"
        placeholder="Pesquise por nome do kit"
        onSearch={setSearch}
        defaultValue={search}
        allowClear
      />
    </div>
  )
}
