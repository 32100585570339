import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { ServiceAPI } from 'domain/servicesCBRdoc/types'
import { convertDefaultModel } from './convertModel'

export default function useServiceAIDefaultModelAndQuestionsQuery(id: number, options?: any) {
  return useQuery(
    ['service-default-ai', id],
    async () => {
      const response: AxiosResponse<ServiceAPI> = await AuthorizedAPI.get(`services/${id}`, {
        params: { 
          append: ['aiDefaultModel.questions'],
          include: ['aiDefaultModel']
        },
      })

      const { ai_default_model, ai_default_model_questions } = response.data

      if (!ai_default_model) return undefined

      return convertDefaultModel(ai_default_model, ai_default_model_questions)
    },
    options
  )
}
