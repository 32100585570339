import React, { useState } from 'react'
import { Tooltip } from 'antd'

import { AIModel, EnrichmentAvailability } from 'domain/openAI/types'
import { Button, ButtonProps, Modal } from 'ui'

interface Props extends ButtonProps {
  aiModel?: AIModel
  availability?: EnrichmentAvailability
  isAiAvailable?: boolean
  isFetching?: boolean
  handleEnrichment: () => void
}

export default function OpenAIEnrichmentButton({
  aiModel,
  availability,
  handleEnrichment,
  isAiAvailable = false,
  isFetching = false,
}: Props) {
  const [modalConfig, setModalConfig] = useState<any>()

  const onClick = () => {
    if (availability === EnrichmentAvailability.AVAILABLE) {
      handleEnrichment()
    } else {
      const modalMessage = getUnavailableReason(availability)
      setModalConfig(modalMessage)
    }
  }

  const closeModal = () => {
    setModalConfig(undefined)
  }

  return (
    <>
      <Tooltip
        title={isAiAvailable ? 'Nós já temos todos esses dados para consulta' : undefined}
        color="#527edd"
      >
        <Button
          className="rounded-lg flex items-center justify-center"
          onClick={onClick}
          loading={isFetching}
          disabled={aiModel?.hasAnswers}
          type="primary"
        >
          Enriquecer todos esses dados
        </Button>
      </Tooltip>

      <Modal
        title={modalConfig?.title}
        open={modalConfig !== undefined}
        okText="Ok"
        cancelText="Voltar"
        onCancel={closeModal}
        onOk={closeModal}
        okButtonProps={{
          className: 'min-w-[154px]',
        }}
        cancelButtonProps={{
          className: 'min-w-[154px]',
        }}
      >
        {modalConfig?.message}
      </Modal>
    </>
  )
}

const getUnavailableReason = (availability: EnrichmentAvailability | undefined) => {
  switch (availability) {
    case EnrichmentAvailability.DISABLED_FOR_SERVICE:
    case EnrichmentAvailability.UNKNOWN_SERVICE:
      return {
        title: 'Não está ativado para o serviço',
        message:
          'A Extração de Dados ainda não está habilitada para este tipo de documento. Por favor, entre em contato conosco para entendermos sua necessidade e como podemos ajudar.',
      }
    case EnrichmentAvailability.DOCUMENT_TOO_LONG:
      return {
        title: 'Documento muito longo',
        message:
          'Este documento possui mais palavras do que o permitido para sua análise neste momento. Entre em contato para mais detalhes.',
      }
    default:
      return {
        title: 'Atenção',
        message:
          'A inteligência artificial ainda não está habilitada, ou a permissão não está ativada para sua conta. Entre em contato com o time comercial.',
      }
  }
}
