import React from 'react'
import { InputProps } from 'antd/lib/input'

import MaskedInput from './MaskedInput'

interface Props extends Omit<InputProps, 'defaultValue' | 'value'> {
  isAmex: boolean
  value?: string
}

function CreditCardInput({
  name = 'cardNumber',
  required = true,
  isAmex,
  onChange,
  ...props
}: Props) {
  return (
    <MaskedInput
      mask={mask}
      onChange={onChange}
      size="middle"
      placeholder="0000 000000 00000"
      name={name}
      label="Número do Cartão"
      required={required}
      rules={requiredRule}
      maskOptions={{
        dispatch: function (appended: any, dynamicMasked: any) {
          return dynamicMasked.compiledMasks[isAmex ? 0 : 1]
        },
      }}
      {...props}
    />
  )
}

const amexMask = '0000 000000 00000'
const othersMask = '0000 0000 0000 0000'

const mask = [
  {
    mask: amexMask,
    lazy: false,
    placeholderChar: ' ',
  },
  {
    mask: othersMask,
    lazy: false,
    placeholderChar: ' ',
  },
]

const requiredRule = [{ required: true, message: 'Campo obrigatório' }]

export default CreditCardInput
