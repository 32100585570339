import React from 'react'
import { Button, Spin } from 'antd'

import { formatMoney } from 'utils/formatters'
import { Icon } from 'ui'
import useGetBonusFor from '../services/useGetBonusFor'

interface BonusCardProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  value: number
  input?: React.ReactNode
  onButtonClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export default function BonusCard({ value, input, onButtonClick, className, ...props }: BonusCardProps) {
  const { bonus, receive } = useGetBonusFor(value)
  const formattedValue = formatMoney(value, { hideDecimalsZeros: true }).replace(/\s/g, '')

  return (
    <div className={`flex-1 ${className}`} {...props}>
      <div className="bg-gray-700 lg:rounded-t relative flex lg:flex-col sm:border-l border-white lg:border-0">
        <div className="px-6 py-5 lg:pb-0 flex-1">
          <h3 className="text-white text-lg">Recarregue</h3>
          <div className="flex flex-col justify-center h-24 lg:mb-5 lg:border-b border-gray-600">
            {input ? (
              <div className="flex">
                <div>
                  {input}
                  <div className="text-gray-600 text-2xs mt-1">Digite o valor desejado</div>
                </div>
              </div>
            ) : (
              <div className="text-green-500 text-4xl lg:text-5xl font-light p-0">{formattedValue}</div>
            )}
          </div>
        </div>
        <div
          role="button"
          onClick={onButtonClick}
          className="px-6 py-5 lg:pt-0 relative flex-1 flex flex-col justify-between lg:block bg-gray-800 bg-opacity-50 lg:bg-opacity-0"
        >
          <span className="text-white text-lg">
            <Icon name="money-hands" className="mr-2 text-3xl" />
            Receba
          </span>
          <div className="">
            <Spin spinning={receive === '-'}>
              <div className="text-white lg:text-green-500 mt-1 text-xl lg:text-3xl font-light">
                {receive}
              </div>
            </Spin>
          </div>
          <Spin spinning={bonus === undefined}>
            <span className="text-gray-600 text-xs">Incluso R${bonus ?? '-'} de bônus</span>
          </Spin>
          <div className="absolute right-0 top-0 bottom-0 flex items-center mr-3 lg:hidden">
            <Icon name="chevron-right" className="text-primary text-2xl" />
          </div>
        </div>
      </div>
      <div className="bg-white p-6 lg:rounded-b border border-gray-200 border-t-0 hidden lg:block">
        <Button type="primary" onClick={onButtonClick} block>
          Inserir créditos
        </Button>
      </div>
    </div>
  )
}
