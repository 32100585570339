import React, { useEffect } from 'react'
import { Form, Input } from 'antd'
import { NamePath } from 'antd/lib/form/interface'

import { convertTextToArray } from 'utils/transformData'
import { onlyNumbersKeyPress } from 'utils/validators'

interface Props {
  name: NamePath
  label: React.ReactNode
  placeholder: string
  onChangeValue?: (value: string[]) => void
  autoFocus?: boolean
  apiName?: string
  maxLength: number
  required?: boolean
  onlyNumbers?: boolean
}

export default function SplittableTextInput({
  name,
  onChangeValue,
  autoFocus,
  label,
  maxLength,
  placeholder,
  required = true,
  onlyNumbers,
}: Props) {
  const value = Form.useWatch(name)

  const onKeyDown = (e: React.KeyboardEvent<any>) => {
    if (onlyNumbers) {
      onlyNumbersKeyPress(e)
    }
  }

  useEffect(() => {
    if (onChangeValue) {
      const registrations = convertTextToArray(value)
      onChangeValue(registrations)
    }
  }, [value])

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        { required, message: 'Campo obrigatório.' },
        {
          validator(_, value) {
            const registrations = convertTextToArray(value)
            const error = registrations.find((text: string) => text.length > maxLength)

            if (error === undefined) {
              return Promise.resolve()
            }

            return Promise.reject(`${error} não é válido. Deve ter no máximo ${maxLength} caracteres.`)
          },
        },
      ]}
    >
      <Input.TextArea
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
        autoSize
        data-hj-allow
      />
    </Form.Item>
  )
}
