import React from 'react'

import { useIsCustomerPostPaid } from 'domain/customers'
import { useInvoicesURLParamsEffect } from 'domain/invoices'

import PrepaidInvoicesPage from './PrepaidInvoicesPage'
import PostpaidInvoicesPage from './PostpaidInvoicesPage'

const InvoicesPage = () => {
  const isCustomerPostPaid = useIsCustomerPostPaid()

  useInvoicesURLParamsEffect()

  return isCustomerPostPaid ? <PostpaidInvoicesPage /> : <PrepaidInvoicesPage /> 
}

export default InvoicesPage
