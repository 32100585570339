import React, { useEffect, useState } from 'react'
import { Button, message } from 'antd'

import { OrderList } from 'domain/orders/types'
import { DrawerActions, Modal } from 'ui'
import { useDownload } from 'utils/export'

interface Props {
  selected: OrderList[]
  setEnableEsc: (value: boolean) => void
}

export default function OrdersMassDownload({ selected, setEnableEsc }: Props) {
  const [handleDownload, loading] = useDownload()
  const [modalOpen, setModalVisible] = useState(false)
  const countUnavailable = selected.filter(({ downloadableItemsIds }) => !downloadableItemsIds.length).length

  const onToggleModal = () => setModalVisible(!modalOpen)

  const downloadAvailableFiles = async () => {
    try {
      const ordersIds = selected.map(({ id }) => id).join(',')
      await handleDownload(`purchases/${ordersIds}/download`, 'cbrdoc-pedidos')
    } catch (err: any) {
      message.error(err.message)
    }
  }

  const handleClick = () => {
    // Mostra o modal com a lista de downloads apenas se houver arquivos indisponíveis
    if (countUnavailable > 0) {
      setModalVisible(true)
    } else {
      downloadAvailableFiles()
    }
  }

  const isDisabled = selected.length === countUnavailable

  useEffect(() => {
    setEnableEsc(!modalOpen)
  }, [modalOpen])

  return (
    <>
      <DrawerActions.Button icon="download" disabled={isDisabled} loading={loading} onClick={handleClick}>
        Baixar documentos
      </DrawerActions.Button>

      <Modal
        open={modalOpen}
        onCancel={onToggleModal}
        destroyOnClose
        footer={[
          <Button key="ok" type="primary" onClick={downloadAvailableFiles}>
            Baixar documentos disponíveis
          </Button>,
        ]}
      >
        <h3 className="font-black text-2xl mb-4 text-secondary-500">Importante</h3>

        <p className="text-secondary-500 mb-4">
          Identificamos um ou mais documentos selecionados que ainda não estão disponíveis para download. Mas
          não se preocupe. <b>Você poderá baixar os arquivos que estão disponíveis.</b>
        </p>

        <h4 className="text-secondary-500 text-base font-bold mb-2">Confira a lista de documentos:</h4>

        <div style={{ maxHeight: 320, overflowY: 'auto' }}>
          <ul>
            {selected.map(o => (
              <li key={o.id} className="flex justify-between mb-2">
                <span className="pr-3 overflow-ellipsis overflow-hidden text-black">&bull; {o.name}</span>
                <DownloadableStatus isAvailable={o.downloadableItemsIds.length > 0} />
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  )
}

function DownloadableStatus({ isAvailable }: { isAvailable: boolean }) {
  if (isAvailable) {
    return <span className="text-green-500 w-24">Disponível</span>
  }

  return <span className="text-red-500 w-24">Indisponível</span>
}
