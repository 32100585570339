import React from 'react'

import { PROD } from 'api/network/config'
import { getDisplayAppVersion } from 'app/config/appVersion'

function LatestAppRelease({ showProd }: { showProd?: boolean}) {
  if (PROD && !showProd) {
    return null
  }

  return (
    <div
      className={`text-xs text-center fixed bottom-0 left-0 ${
        PROD ? 'text-gray-500 p-2 z-[9999]' : 'text-white bg-green-600 z-[1001] p-1 rounded'
      }`}
    >
      Versão <span className="font-bold">{getDisplayAppVersion()}</span>
    </div>
  )
}

export default LatestAppRelease
