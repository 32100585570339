import { useAddToCart } from 'domain/createOrder/shared/services'
import { useCreateOrderState } from 'domain/createOrder/document/state'
import { CreateOrderFormData, CreateOrderState } from 'domain/createOrder/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'

function useAddDueDiligenceToCart() {
  const addToCart = useAddToCart()
  const diligenceState = useCreateOrderState()

  return async function addDueDiligenceToCart() {
    const { selectedService, formData } = diligenceState

    const createOrderState = {
      ...diligenceState,
      selectedService,
      selectedFormat: 'email',
      countItems: 1,
      createStatus: 'review',
      formData: {
        ...formData,
        [FieldName.NOME_ITEM_PEDIDO]: generateOrderName(selectedService!.name, formData),
      },
    } as CreateOrderState

    return addToCart(createOrderState)
  }
}

function generateOrderName(serviceName: string, formData: CreateOrderFormData) {
  const location = formData.localtion ?? formData.local_servico
  const base = `Diligência ${serviceName}`
  if (location) {
    return `${base} em ${location}`
  }

  return base
}

export default useAddDueDiligenceToCart
