import React, { useMemo } from 'react'
import { Tabs } from 'antd'

import { LegalEntity } from 'domain/app/types'

export interface TabsLegalEntityProps {
  selected: LegalEntity
  onChange: (activeKey: LegalEntity) => void | React.Dispatch<React.SetStateAction<LegalEntity>>
  naturalPersonContent: JSX.Element | null
  juridicalPersonContent: JSX.Element | null
  disableTab?: LegalEntity | 'both'
}

export default function TabsLegalEntity({
  selected,
  onChange,
  naturalPersonContent,
  juridicalPersonContent,
  disableTab,
}: TabsLegalEntityProps) {
  const items = useMemo(() => {
    return [
      {
        label: 'Pessoa',
        key: 'fisica',
        disabled: disableTab === 'fisica' || disableTab === 'both'
      },
      {
        label: 'Empresa',
        key: 'juridica',
        disabled: disableTab === 'juridica' || disableTab === 'both'
      },
    ]
  }, [disableTab])

  return (
    <>
      <Tabs 
        defaultActiveKey={selected} 
        items={items} 
        onChange={onChange as any}
        type="card"
      />
      {selected === 'juridica' ? juridicalPersonContent : naturalPersonContent}
    </>
  )
}
