import { useReducer } from 'react'
import { OrderOverview } from '../types'
import { exportToPDF, useDownload } from 'utils/export'
import { useOrderItemDownloadPDF } from '../services'
import { message } from 'antd'
import { PrintConfig } from 'domain/app/types'

interface UseDownloadOrderItemsReportParams {
  orderId: OrderOverview['id']
  orderNumber: OrderOverview['orderNumber']
}

export function useDownloadOrderItemsReport({ orderId, orderNumber }: UseDownloadOrderItemsReportParams) {
  const [open, toggleModal] = useReducer(prev => !prev, false)
  const [oneResultPerRow, toggleResultOption] = useReducer(prev => !prev, false)
  const [handleDownload, loading] = useDownload()

  const onDownload = async () => {
    const fileName = `cbrdoc-dossie-pedido-${orderNumber}`
    await handleDownload(`purchases/${orderId}/report?oneResultPerRow=${oneResultPerRow}`, fileName)
    toggleModal()
  }

  return {
    open,
    toggleModal,
    toggleResultOption,
    onDownload,
    loading,
  }
}

const columns: PrintConfig[] = [
  {
    header: 'Nome da compra',
    dataKey: 'Nome da compra',
  },
  {
    header: 'Nº do pedido',
    dataKey: 'Nº do pedido',
  },
  {
    header: 'Data do pedido',
    dataKey: 'Data do pedido',
  },
  {
    header: 'Documento',
    dataKey: 'Documento',
  },
  {
    header: 'Status',
    dataKey: 'Status',
  },
]

const columnStyles = {
  'Nome da compra': { cellWidth: 'auto', minCellWidth: 40 },
  'Nº do pedido': { minCellWidth: 25 },
  Registro: { minCellWidth: 25 },
  'Data do pedido': { minCellWidth: 25 },
  Documento: { minCellWidth: 30 },
  Status: { minCellWidth: 20 },
}

interface UseDownloadOrderOverviewPDFParams {
  order: OrderOverview
}

export function useDownloadOrderOverviewPDF({ order }: UseDownloadOrderOverviewPDFParams) {
  const { data } = useOrderItemDownloadPDF(order.id)

  const handleExport = () => {
    const fileName = `Items_Pedido_${order.name}-${order.orderNumber}`
    if (data) {
      exportToPDF({ body: data, fileName, columns, styles: { columnStyles } })
    } else {
      message.error({
        content: 'Não foi possível baixar seu resumo.',
      })
    }
  }

  return { handleExport }
}
