import { useRef } from 'react'
import { Form, message } from 'antd'

import Button from './Button'

interface Props {
  label: string
  onSuccess: (itemId: number) => void
  disabled?: boolean
}

export default function AddFormItemButton({ label, disabled, onSuccess }: Props) {
  const lastItemId = useRef(0)
  const form = Form.useFormInstance()

  const handleClick = () => {
    form
      .validateFields()
      .then(() => {
        lastItemId.current += 1
        const itemId = lastItemId.current

        onSuccess(itemId)
      })
      .catch(() => {
        message.error('Verifique o preenchimento do formulário.')
      })
  }

  return (
    <Button
      icon="plus-circle"
      type="primary"
      size="small"
      ghost
      onClick={handleClick}
      disabled={disabled}
      className="px-6"
    >
      {label}
    </Button>
  )
}
