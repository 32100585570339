import {
  OrderList,
  OrderAPI,
  OrderOverview,
  BasicOrderData,
  OrderPaymentInfo,
  Order,
} from 'domain/orders/types'
import { displayDate, displayDateAndTime } from 'utils/dateTime'
import { formatMoney } from 'utils/formatters'

import { CONCLUDED_STATUS } from '../../orderItem/shared/data'
import { StatusCount } from '../types'

export function convertOrder(originalOrder: OrderAPI, isPostPaid: boolean) {
  const {
    downloadable_orders_ids,
    imported_at,
    shipping_address_city,
    shipping_address_complement,
    shipping_address_neighborhood,
    shipping_address_number,
    shipping_address_public_place,
    shipping_address_uf,
    shipping_address_zip_code,
    type,
  } = originalOrder

  const order: Order = {
    ...convertBasicOrderData(originalOrder),
    ...convertOrderPaymentInfo(originalOrder, isPostPaid),
    downloadableOrdersIds: downloadable_orders_ids ?? [],
    type,
  }

  if (imported_at) {
    order.importedAt = displayDateAndTime(imported_at)
  }

  if (shipping_address_public_place) {
    order.deliveryAddress = {
      city: shipping_address_city,
      complement: shipping_address_complement as string,
      neighborhood: shipping_address_neighborhood,
      number: shipping_address_number,
      street: shipping_address_public_place,
      uf: shipping_address_uf,
      zipCode: shipping_address_zip_code,
    }
  }

  return order
}

export function convertOrderOverview(originalOrder: OrderAPI, isPostPaid: boolean) {
  const {
    downloaded_orders_ids,
    has_consumed_ai_free_analysis,
    orders_status_count,
    originated_from_orders,
    estimated_at,
  } = originalOrder

  const order: OrderOverview = {
    ...convertOrderList(originalOrder),
    ...convertOrderPaymentInfo(originalOrder, isPostPaid),
    downloadedItemsIds: downloaded_orders_ids ?? [],
    hasConsumedAiFreeAnalysis: has_consumed_ai_free_analysis,
    estimatedDeliveryAt: estimated_at,
    originatedFromOrders:
      originated_from_orders?.map(o => ({ id: o.id, orderNumber: o.backoffice_id })) ?? [],
    totalItemsByStatus: orders_status_count!,
  }

  return order
}

export function convertOrderList(originalOrder: OrderAPI) {
  const {
    backoffice_id,
    downloadable_orders_ids,
    first_order,
    has_ai_extracted_data,
    has_ai_analysis_pending,
    id,
    last_status_change_at,
    name,
    orders_status_count,
    placed_at,
    quoted_cost_postpaid_customer,
    recurrence,
    status,
    type,
    user,
  } = originalOrder

  const [totalItems, totalConcludedItems] = getTotalItems(orders_status_count!)

  const order: OrderList = {
    createdAt: placed_at,
    createdAtDisplay: displayDate(placed_at),
    downloadableItemsIds: downloadable_orders_ids ?? [],
    hasAiAnalysisPending: has_ai_analysis_pending,
    hasAiExtractData: has_ai_extracted_data,
    id,
    name,
    orderNumber: backoffice_id,
    priceQuotationDisplay: quoted_cost_postpaid_customer ? formatMoney(quoted_cost_postpaid_customer) : '',
    register: first_order?.register ?? '',
    statusCode: status,
    totalConcludedItems, // Itens finalizados, cancelados, reembolsados ou com problema na entrega
    totalItems,
    type,
    updatedAtDisplay: displayDateAndTime(last_status_change_at),
    user: {
      id: user.id,
      name: user.name,
    },
  }

  if (recurrence) {
    order.recurrence = {
      id: recurrence.id,
      name: recurrence.name,
    }
  }

  return order
}

export function convertOrderPaymentInfo(originalOrder: OrderAPI, isPostPaid: boolean) {
  const {
    quote_appraiser_id,
    quoted_cost_postpaid_customer,
    quoted_rejected_reason,
    total_cost,
    total_estimated_cost_postpaid_customer,
    waiting_invoice_payment,
  } = originalOrder

  const priceValue = isPostPaid ? total_estimated_cost_postpaid_customer! : total_cost

  const payment: OrderPaymentInfo = {
    isPriceQuotationApproved: quote_appraiser_id !== null,
    priceDisplay: formatMoney(priceValue),
    priceValue,
    priceQuotationValue: quoted_cost_postpaid_customer,
    quotationRejectedReason: quoted_rejected_reason,
  }

  if (waiting_invoice_payment) {
    const { bank_slip_barcode, bank_slip_url, payment_method, pix_hash, pix_qr_code } =
      waiting_invoice_payment

    payment.waitingInvoicePayment = {
      bankSlipBarcode: bank_slip_barcode,
      bankSlipURL: bank_slip_url,
      paymentMethod: payment_method,
      pixQRCode: pix_qr_code,
      pixKey: pix_hash,
    }
  }

  return payment
}

export function convertBasicOrderData(originalOrder: OrderAPI) {
  const { backoffice_id, id, name } = originalOrder

  const order: BasicOrderData = {
    id,
    name,
    orderNumber: backoffice_id,
  }

  return order
}

function getTotalItems(statusCount: StatusCount[]) {
  let total = 0
  let concluded = 0

  statusCount!.forEach(({ count, status }) => {
    total += count

    if (CONCLUDED_STATUS.includes(status)) {
      concluded += count
    }
  })

  return [total, concluded]
}
