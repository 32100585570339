import React from 'react'
import { Dropdown } from 'antd'

import { NotificationTypesConfig } from 'domain/notifications/data'
import { useFilterByType } from 'domain/notifications/state'
import { SelectableButton } from 'ui'

function NotificationFiltersByType() {
  const [type, setType] = useFilterByType()

  const items = [{ label: 'Todos os tipos', key: 'all', onClick: () => setType(undefined) }]

  Object.values(NotificationTypesConfig).forEach(type => {
    items.push({
      key: type.code,
      label: type.text,
      onClick: () => setType(type.code),
    })
  })

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <div>
        <SelectableButton selected={!!type}>
          {type ? NotificationTypesConfig[type].text : 'Todos os tipos'}
        </SelectableButton>
      </div>
    </Dropdown>
  )
}

export default NotificationFiltersByType
