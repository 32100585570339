import React from 'react'

import { Icon } from 'ui'

function AlertLastDayOfMonth() {
  return (
    <div className="border-2 border-red-600 flex gap-5 items-center my-5 p-3 rounded text-red-600">
      <Icon name="alert" color="currentColor" size={42} />
      
      <span>
        Em meses com menos de 31 dias, a recorrência será aplicada no último dia do mês.
      </span>
    </div>
  )
}

export default AlertLastDayOfMonth
