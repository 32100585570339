import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SecretAPI } from 'api/network/v2'
import { ERRORS, captureError } from 'api/errors'
import { ApiError, ApiStatus } from 'api/types'
import { RoutePaths } from 'app/routes' 

const useRedefinePassword = () => {
  const [error, setError] = useState<ApiError | null>(null)
  const [status, setStatus] = useState<ApiStatus>('idle')
  const history = useHistory()

  const redefinePassword = async (token: string, password: string) => {
    try {
      setStatus('loading')
      setError(null)
      await SecretAPI.patch(`/password-reset/${token}`, { new_password: password })
      setStatus('success')
      history.push(RoutePaths.LOGIN + '?senha-redefinida=1')
    } catch (error: any) {
      setStatus('error')

      if (error.response.status === 422) {
        setError({ message: 'Dados inválidos', description: 'Por favor, verifique as informações enviadas' })
      } else {
        setError(ERRORS.internalServer)
        captureError(error)
      }
    }
  }

  return {
    redefinePassword,
    error,
    status,
    resetStatus: () => setStatus('idle'),
  }
}

export default useRedefinePassword
