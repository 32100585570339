import React from 'react'
import { Popconfirm } from 'antd'

import useDeleteItem from '../../state/useDeleteItem'

interface Props {
  itemId: number
  onDeleted?: (itemId: number) => void
}

export default function DeleteItem({ itemId, onDeleted }: Props) {
  const confirm = useDeleteItem(itemId, onDeleted)

  return (
    <Popconfirm
      title="Tem certeza?"
      onConfirm={confirm}
      okText="Sim"
      cancelText="Não"
      className="cursor-pointer"
      placement="left"
    >
      <a href="#">Excluir</a>
    </Popconfirm>
  )
}
