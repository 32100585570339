import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import {
  useGetCurrentPage,
  useGetPageSize,
  useGetSearch,
  useGetSelectedMonth,
  useGetPostpaidSortBy,
  useSetTotalPages,
  useSetTotalResults,
} from 'domain/invoices/state'
import { InvoicePostpaidAPI } from 'domain/invoices/types'
import { displayMonth, displayYear } from 'utils/dateTime'

import convertPostpaidInvoice from './convertPostpaidInvoice'

const usePostpaidInvoicesQuery = () => {
  const search = useGetSearch()
  const currentPage = useGetCurrentPage()
  const setTotalPages = useSetTotalPages()
  const setTotalResults = useSetTotalResults()
  const pageSize = useGetPageSize()
  const selectedMonth = useGetSelectedMonth()
  const sortBy = useGetPostpaidSortBy()

  const query = useQuery(
    ['invoices', 'postpaid', currentPage, pageSize, selectedMonth, search, sortBy],
    async () => {
      const dayDate = displayMonth(selectedMonth)
      const yearDate = displayYear(selectedMonth)

      const response: AxiosResponse<InvoicePostpaidAPI[]> = await AuthorizedAPI.get(
        `/invoice-postpaids/${yearDate}/${dayDate}`,
        {
          params: {
            include: 'order,order.user',
            'per-page': pageSize,
            'filter[order_name_or_id]': search,
            sort: sortBy,
            page: currentPage,
          },
        }
      )
      setTotalPages(Number(response.headers['x-pagination-page-count']))
      setTotalResults(Number(response.headers['x-pagination-total-count']))

      return response.data.map(convertPostpaidInvoice)
    },
    {
      keepPreviousData: true,
    }
  )

  return query
}

export default usePostpaidInvoicesQuery
