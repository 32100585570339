import React from 'react'

import { useGetExplorerItem } from 'domain/myFiles/state'
import { Sidebar, SidebarHeading2 } from 'layouts'
import { LargeButton, ViewInfo, ViewInfoDate } from 'ui'

import DeleteItemButton from './DeleteItemButton'
import ExplorerActions from './ExplorerActions'
import ExplorerGroupsAssociation from './ExplorerGroupsAssociation'

export default function ViewItemSidebar() {
  const explorerItem = useGetExplorerItem()!

  return (
    <Sidebar padding="p-3">
      <div className="flex gap-4 items-center">
        <LargeButton
          icon="download"
          disabled={!explorerItem.file?.signedUrl}
          href={explorerItem.file?.signedUrl ?? '#'}
          target="_blank"
          download
        >
          Baixar
        </LargeButton>

        <DeleteItemButton />
      </div>

      <div className="p-4">
        <SidebarHeading2 text="Arquivo" />

        {explorerItem.owner && <ViewInfo label="Responsável" icon="user" value={explorerItem.owner.name} />}
        
        {explorerItem.name && (
          <ViewInfo label="Nome do arquivo" icon="registro-civil" value={explorerItem.name} />
        )}

        {explorerItem.createdAt && (
          <ViewInfoDate label="Data do upload" date={explorerItem.createdAt} />
        )}

        <ExplorerActions>
          <ExplorerGroupsAssociation />
        </ExplorerActions>
      </div>
    </Sidebar>
  )
}
