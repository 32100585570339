import { DynamicField } from 'domain/servicesCBRdoc/fields'
import { useUpdateServiceForm } from '../../state'
import { KitFieldConfig } from '../../types'

type Props = { field: KitFieldConfig; itemId: number }

export default function DynamicSelectField({ field, itemId }: Props) {
  const updateForm = useUpdateServiceForm(itemId)

  return (
    <DynamicField
      field={field}
      onChangeSelectable={value => updateForm({ [field.name]: value })}
      itemId={itemId}
      labelInValue
    />
  )
}
