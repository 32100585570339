import { Checkbox, Form, Spin } from 'antd'

import { getFieldName } from 'domain/createOrder/shared'
import { Fields } from 'domain/servicesCBRdoc/config'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { formatMoney } from 'utils/formatters'

import TextField from './TextField'

export interface DontKnowBookPageFieldsProps {
  itemId: number | string
  price: number | undefined
  loading?: boolean
  onChangeDontKnow?: (checked: boolean) => void
}

export default function DontKnowBookPageFields({
  itemId,
  price,
  loading,
  onChangeDontKnow,
}: DontKnowBookPageFieldsProps) {
  const form = Form.useFormInstance()
  const bookField = getFieldName(LIVRO, itemId)
  const pageField = getFieldName(PAGINA, itemId)
  const dontKnowPageField = getFieldName(NAO_SEI_LIVRO_PAGINA, itemId)
  const dontKnowBookPage = Form.useWatch(dontKnowPageField, form)

  return (
    <>
      <p className="mb-5 text-gray-600">
        Dados obrigatórios para localização de seu documento. Caso não saiba, selecione {'"'}não sei{'"'} que
        pesquisamos para você.
      </p>

      <TextField
        name={bookField}
        field={Fields.livro}
        required={!dontKnowBookPage}
        disabled={dontKnowBookPage}
      />

      <TextField
        field={Fields.pagina}
        name={pageField}
        required={!dontKnowBookPage}
        disabled={dontKnowBookPage}
      />

      <div className="flex justify-between items-center mb-6">
        <Form.Item name={dontKnowPageField} valuePropName="checked" noStyle>
          <Checkbox
            onChange={e => {
              const { checked } = e.target

              if (checked) {
                form.setFieldsValue({
                  [itemId]: {
                    [LIVRO]: '',
                    [PAGINA]: '',
                    [NAO_SEI_LIVRO_PAGINA]: true,
                  }
                })
              } else {
                form.setFieldValue(dontKnowPageField, false)
              }

              onChangeDontKnow && onChangeDontKnow(checked)
            }}
          >
            Não sei o livro e a página
          </Checkbox>
        </Form.Item>

        <Spin spinning={loading} size="small">
          <span className="text-base text-gray-600">{price && formatMoney(price)}</span>
        </Spin>
      </div>
    </>
  )
}

const { LIVRO, NAO_SEI_LIVRO_PAGINA, PAGINA } = FieldName
