import { useState } from 'react'
import { AxiosResponse } from 'axios'

import { errorHandler } from 'api/errors'
import { AuthorizedAPI } from 'api/network/v2'

function useShareOrderItem(): [ShareOrderFn, boolean] {
  const [loading, setLoading] = useState(false)

  const shareOrder: ShareOrderFn = async ({ orderItemId, params, onSuccess }) => {
    try {
      setLoading(true)
      const data = await shareOrderItemAPI(orderItemId, params)
      onSuccess(data)
    } catch (error) {
      errorHandler(error, { code: '0x753' })
    } finally {
      setLoading(false)
    }
  }

  return [shareOrder, loading]
}

type ShareOrderFn = (args: {
  orderItemId: string | number,
  params: ShareParams,
  onSuccess: (data: any) => void
}) => void

type ShareVia = 'whatsapp' | 'email' | 'link'

interface ShareParams {
  via: ShareVia
  destination_email?: string
}

const shareOrderItemAPI = async (orderId: string | number, shareParams: ShareParams) => {
  const response: AxiosResponse<{ generated_link: string }> = await AuthorizedAPI.post(
    `/orders/${orderId}/share`,
    {
      via: shareParams.via,
      destination_email: shareParams.destination_email,
    }
  )
  return response.data
}

export default useShareOrderItem
