import React from 'react'

import NewModelButton from './NewModelButton'
import SearchModelInput from './SearchModelInput'

export default function ModelsActionsBar() {
  return (
    <div className="px-7 py-3 mt-4">
      <div className="flex flex-row flex-1 items-center gap-6">
        <SearchModelInput />
        <NewModelButton />
      </div>
    </div>
  )
}
