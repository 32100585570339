import { useMemo } from 'react'
import { ResultCode } from 'domain/orders/types'
import { Checkbox, Form } from 'antd'

function AutoPurchaseFromResearchResult({ automaticResults }: { automaticResults: ResultCode[] }) {
  const form = Form.useFormInstance()
  const checkedList = form.getFieldValue('automaticResults')
  const options = useMemo(() => {
    if (!automaticResults || !Array.isArray(automaticResults)) return []
    const options = automaticResults.map(result => {
      if (result === ResultCode.POSITIVE) {
        return { label: 'Solicitar para resultados positivos', value: ResultCode.POSITIVE }
      }

      return { label: 'Solicitar para resultados negativos', value: ResultCode.NEGATIVE }
    })

    return options
  }, [automaticResults])

  if (!options || options.length === 0) {
    return null
  }

  return (
    <div className='px-4 pt-6 my-6 bg-white'>
      <Form.Item
        label={(
          <div className='flex flex-col'>
            <h3 className="uppercase text-secondary-500 mb-3">
              Solicitar matrícula automaticamente
            </h3>
            <div className='mb-2 font-normal'>
              Habilite esta opção para solicitar automaticamente
              a matrícula dos resultados positivos da pesquisa.
            </div>
          </div>
        )}
        name="automaticResults"
        valuePropName="checked"
        className="flex flex-col gap-3"
      >
        <Checkbox.Group className='round-checkbox' options={options} value={checkedList} />
      </Form.Item>
    </div>
  )
}

export default AutoPurchaseFromResearchResult
