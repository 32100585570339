import { useCreateOrderState } from 'domain/createOrder/document/state'
import { useShoppingCartState } from 'domain/shoppingCart'
import { ShoppingCartOrder } from 'domain/shoppingCart/types'

import { checkSimilarOrdersBeforeSubmit } from '../helpers'

function useCheckSimilarOrdersOnCart() {
  const { selectedService } = useCreateOrderState()
  const { orders } = useShoppingCartState()

  return function checkSimilarOrdersOnCart(shoppingCartItem: ShoppingCartOrder) {
    const ordersOfSameService = orders.filter(item => item.selectedService!.id === selectedService!.id)
    return checkSimilarOrdersBeforeSubmit(shoppingCartItem, ordersOfSameService)
  }
}

export default useCheckSimilarOrdersOnCart
