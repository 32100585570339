import { useDownload } from 'utils/export'

interface Props {
  orderNumber: number
  orderId: number
}

export default function useOpenAiDownload({ orderNumber, orderId }: Props) {
  const [handleDownload, loading] = useDownload()

  const download = async () => {
    const fileName = `cbrdoc-IA-pedido-${orderNumber}`
    handleDownload(`reports/ai-answers/xlsx?type=purchase&purchase_id=${orderId}`, fileName)
  }

  return { download, loading }
}
