import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { SignaturesProgressAPI } from 'domain/orderItem/typesAPI'

function useSignaturesProgressQuery(itemId: number) {
  return useQuery(['signatures-progress', itemId], async () => {
    const response: AxiosResponse<SignaturesProgressAPI[]> = await AuthorizedAPI.get(
      `orders/${itemId}/detailed-progress`
    )
    return response.data
  })
}

export default useSignaturesProgressQuery
