import React from 'react'

import { PrepaidInvoices, PrepaidInvoicesSummaryAndExport } from 'domain/invoices/prepaid'
import { PageHeader } from 'layouts'

const PrepaidInvoicesPage = () => {
  return (
    <>
      <PageHeader title="Faturas" footer={<PrepaidInvoicesSummaryAndExport />} />
      <PrepaidInvoices />
    </>
  )
}

export default PrepaidInvoicesPage
