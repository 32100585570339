import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { FORMATO } = FieldName

type CertidaoSituacaoPJFields = Record<FieldName.CNPJ, FieldConfig>

class CertidaoSituacaoPJ extends Certificate {
  formFields: CertidaoSituacaoPJFields = {
    cnpj: Fields.cnpj,
  }

  constructor() {
    super({
      id: 94,
      priceByFields: [FORMATO],
      shortName: 'Situação PJ',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoSituacaoPJ().initialize()

export default service
