import TagManager from 'react-gtm-module'

import { RDS } from 'api/rdstation'
import { useCurrentUser } from 'domain/auth'
import { useRefreshCustomerCredits } from 'domain/credits'
import { formatCreditCardDate } from 'utils/dateTime'

import { ChosenPaymentMethod, CreditCardData, PaymentMethod } from '../types'
import { walletDepositAPI } from './api'

export type CreditCardPayment = {
  companyName?: string
  paymentValue: number
  isAddingCredits?: boolean
}

function usePayWithCreditCard() {
  const user = useCurrentUser()
  const refreshCredits = useRefreshCustomerCredits()

  return async function payWithCreditCard(
    { cardNumber, cvv, documentNumber, dueDate, holderName }: CreditCardData,
    { paymentValue, isAddingCredits, companyName }: CreditCardPayment
  ) {
    const { data } = await walletDepositAPI({
      amount: paymentValue,      
      credit_card_document_number: documentNumber,
      credit_card_holder_name: holderName,
      credit_card_number: cardNumber.replace(/ /g, '') as unknown as number,
      credit_card_cvv: cvv as unknown as number,
      credit_card_due_date: formatCreditCardDate(dueDate),
      payment_method: PaymentMethod.CREDIT_CARD,
      send_email_after_approved: isAddingCredits ?? false,
    })

    const tagSuffix = isAddingCredits ? 'via_credito' : 'via_pedido'
    const tagName = `credito_adicionado_cartao_${tagSuffix}`

    if (data) {
      const { bonus_amount } = data

      refreshCredits()

      TagManager.dataLayer({
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          event: 'creditcardPayment',
          transactionId: data.invoice_id,
          transactionAffiliation: 'Sem-Cupom',
          transactionTotal: data.amount,
          transactionTax: 0,
          transactionShipping: 0,
          transactionProducts: [
            {
              sku: 'DD44',
              name: tagName,
              category: 'Crédito',
              price: data.amount,
              quantity: 1,
            },
          ],
        },
      })

      RDS.conversionEvent({
        event_type: 'CONVERSION',
        event_family: 'CDP',
        payload: {
          conversion_identifier: 'Crédito adicionado - Cartão',
          name: user.name,
          email: user.email,
          company_name: companyName,
          mobile_phone: user.phone,
          tags: [tagName],
          cf_modo_pagamento: 'Cartão de crédito',
          cf_valor_credito: data.amount,
        },
      })

      const chosenMethod: ChosenPaymentMethod = {
        chosenMethod: PaymentMethod.CREDIT_CARD,
        bonusAmount: bonus_amount,
      }

      return chosenMethod
    }
  }
}

export default usePayWithCreditCard
