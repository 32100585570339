import React from 'react'

import { CertificateFlow } from 'domain/createOrder/certificates'
import { CNPJInput } from 'ui'

export default function CreateOrderCertidaoEntidadesSupervisionadas() {
  return (
    <CertificateFlow
      header={
        <p className="mb-5 text-gray-600">
          Digite o número do CNPJ que deseja a Certidão para Entidades Supervisionadas.
        </p>
      }
      renderFormItem={(itemId: number) => <CertidaoEntidadesSupervisionadasForm itemId={itemId} />}
    />
  )
}

function CertidaoEntidadesSupervisionadasForm({ itemId }: { itemId: number }) {
  return <CNPJInput itemId={itemId} autoFocus={itemId === 0} />
}
