import React from 'react'

import { useSetDatesRange } from 'domain/orders/state/filters'
import { displayDate } from 'utils/dateTime'
import { FilterTag } from 'ui'

export default function FilterByPeriodTag({ value }: { value: string }) {
  const setDateRange = useSetDatesRange()
  const dates = value.split(',')
  const initialDate = displayDate(dates[0])
  const finalDate = displayDate(dates[1])

  return (
    <FilterTag onClick={() => setDateRange(undefined)}>
      {initialDate} a {finalDate}
    </FilterTag>
  )
}
