import React from 'react'

import { DocumentTypeForm } from 'domain/myFiles'
import { AlertFullPage, Icon } from 'ui'

export default function SelectServiceId() {
  return (
    <div className="flex flex-col flex-1 items-center justify-center">
      <AlertFullPage
        title="Tipo de documento não identificado"
        description="A inteligência artificial não está habilitada para esse arquivo porque não foi identificado o tipo de documento. Selecione abaixo para identificar:"
        icon={<Icon name="file" className="mb-4 mx-auto text-6xl text-secondary-500" />}
      />
      <DocumentTypeForm />
    </div>
  )
}
