import { CertificateFlow } from 'domain/createOrder/certificates'
import { useUpdateSplittableQuantity } from 'domain/createOrder/document'
import { SplittableField } from 'domain/servicesCBRdoc/fields'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'

import config from '../certidaoPreviaMatriculaConfig'

export default function CreateOrderPreviaMatricula() {
  return <CertificateFlow renderFormItem={(itemId: number) => <PreviaMatriculaForm itemId={itemId} />} />
}

const PreviaMatriculaForm = ({ itemId }: { itemId: number }) => {
  const onChangeMatriculas = useUpdateSplittableQuantity(itemId, FieldName.MATRICULA)

  return (
    <SplittableField
      itemId={itemId}
      field={config.formFields.matricula}
      onChangeValue={onChangeMatriculas}
      autoFocus={itemId === 0}
    />
  )
}
