import React from 'react'

import { AlertFullPage } from 'ui'

function AlertViewOrderPermission() {
  return (
    <AlertFullPage
      title="Você não tem permissão para visualizar os pedidos."
      description="Seu usuário não possui permissão para visualizar os pedidos. Entre em contato com o administrador da sua conta para solicitação de acesso."
    />
  )
}

export default AlertViewOrderPermission
