import React from 'react'
import { useHistory } from 'react-router-dom'

import { useDeleteExplorerItem } from 'domain/myFiles/services'
import { useGetExplorerItem } from 'domain/myFiles/state'
import { LargeButton } from 'ui'

export default function DeleteItemButton() {
  const history = useHistory()
  const explorerItem = useGetExplorerItem()
  const { deleteExplorerItem, permissionError } = useDeleteExplorerItem(explorerItem!)

  return (
    <LargeButton
      icon="trash"
      onClick={() =>
        deleteExplorerItem({
          onSuccess: history.goBack,
        })
      }
      disabled={!explorerItem.id || !!permissionError}
      title={permissionError}
    >
      Excluir
    </LargeButton>
  )
}
