import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'

import { CreateKitForm, Kit, KitAPI, KitItemDefaultData } from '../types'
import revertLegalEntityAPI from './revertLegalEntityAPI'
import convertKit from './convertKit'
import { convertLegalEntityToAPI } from './convertToAPI'

type PostKitItem = {
  default_data?: Partial<KitItemDefaultData>
  service_category_id: number
  service_id: number
  service?: {
    name: string
  }
}

async function createKit({ legalEntity, name, items }: CreateKitForm): Promise<Kit> {
  const response: AxiosResponse<KitAPI> = await AuthorizedAPI.post('/kits', {
    name,
    restrict_entity_type_to: convertLegalEntityToAPI(legalEntity),
    items: items.map(item => ({
      service_id: item.service.id,
      service_category_id: item.service.categoryId,
      default_data: item.defaultData ?? {},
    })),
  })

  return convertKit(response.data)
}

export async function duplicateKit(kit: Kit): Promise<Kit> {
  const items: PostKitItem[] = []

  kit.items.map(item => {
    items.push({
      service_id: item.service.id,
      service_category_id: item.service.categoryId,
      default_data: item.defaultData,
    })
  })

  const response: AxiosResponse<KitAPI> = await AuthorizedAPI.post('/kits', {
    name: `Cópia de ${kit.name}`,
    items,
    restrict_entity_type_to: revertLegalEntityAPI(kit.legalEntity!),
  })

  return convertKit(response.data)
}

export default createKit
