import { useEffect } from 'react'

import { useSetCurrentTab } from '../state/tabs'
import { useGetItemsTotalResults, useGetTotalResults } from '../state/pagination'
import { useGetAutomaticGenerated } from '../state/filters'

export default function useSetTabEffect(loading: boolean) {
  const totalResults = useGetTotalResults()
  const itemsTotalResults = useGetItemsTotalResults()
  const setCurrentTab = useSetCurrentTab()
  const automaticGenerated = useGetAutomaticGenerated()

  useEffect(() => {
    if (!loading) {
      if (totalResults === 0 && itemsTotalResults > 0 && (!automaticGenerated || totalResults === 0)) {
        setCurrentTab('items')
      } else if ((totalResults > 0 && itemsTotalResults === 0) || automaticGenerated) {
        setCurrentTab('orders')
      }
    }
  }, [totalResults, itemsTotalResults, loading])
}
