import { CertificateFlow } from 'domain/createOrder/certificates'
import CertidaoInterdicaoForm from './CreateOrderCertidaoInterdicaoForm'

export default function CreateOrderCertidaoInterdicao() {
  return (
    <CertificateFlow
      header={
        <p className="mb-5 text-gray-600">
          Selecione Pessoa ou Empresa e digite o número do CPF ou CNPJ que deseja a Certidão de Interdição.
        </p>
      }
      renderFormItem={(itemId: number) => <CertidaoInterdicaoForm itemId={itemId} />}
      noRenderOrderName
    />
  )
}
