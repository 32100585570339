import React from 'react'

import {
  ExportPostpaidInvoices, 
  PostpaidInvoices,
  PostpaidInvoicesFilters,
  PostpaidInvoicesSummary,
} from 'domain/invoices/postpaid'
import { PageHeader } from 'layouts'

function PostpaidInvoicesPage() {
  return (
    <>
      <PageHeader
        title="Faturas"
        footer={
          <PostpaidInvoicesSummary>
            <ExportPostpaidInvoices />
          </PostpaidInvoicesSummary>
        }
      />
      <PostpaidInvoices />
    </>
  )
}

export default PostpaidInvoicesPage
