import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { useGetIsHydratedFromURL } from 'domain/app'
import { Group } from 'domain/groups/types'
import {
  useGetCurrentPage,
  useGetPageSize,
  useSetPageCountResults,
  useSetTotalResults,
} from '../state/pagination'

export default function useGroupsQuery() {
  const page = useGetCurrentPage()
  const pageSize = useGetPageSize()
  const setPageResults = useSetPageCountResults()
  const setTotalResults = useSetTotalResults()
  const isHydrated = useGetIsHydratedFromURL()

  return useQuery(
    ['groups', 'list', page, pageSize],
    async () => {
      const response: AxiosResponse<Group[]> = await AuthorizedAPI.get('/groups', {
        params: {
          'per-page': pageSize,
          sort: 'name',
          page,
        },
      })

      setPageResults(Number(response.headers['x-pagination-page-count']))
      setTotalResults(Number(response.headers['x-pagination-total-count']))

      return response.data
    },
    {
      enabled: isHydrated,
    }
  )
}
