import React from 'react'
import { Checkbox } from 'antd'

export default function OneResultByRowCheckbox({ onToggle }: { onToggle: () => void }) {
  return (
    <>
      <p className="mb-5">
        O relatório exibe uma compra por linha. Se deseja exibir um resultado de pesquisa por linha, selecione
        o campo abaixo:
      </p>

      <Checkbox className="mb-4" onChange={onToggle}>
        Exportar um resultado por linha
      </Checkbox>
    </>
  )
}
