import * as Sentry from '@sentry/browser'

import { PlaceOrderResponseAPI } from 'domain/createOrder/types'
import { CreateOrderInProgressError, PurchaseValidationError } from 'domain/createOrder'

import { ChosenPaymentMethod } from '../types' 
import { useSetPaymentMethod } from '../state'
import useGenerateQRCode from './useGenerateQRCode'

function usePlaceOrder() {
  const generateQR = useGenerateQRCode()
  const setChosenPaymentMethod = useSetPaymentMethod()

  return async function placeOrder(onSubmit: () => Promise<PlaceOrderResponseAPI>) {
    try {
      const response = await onSubmit()
      // quando é um pedido por planilha a api v1 manda a resposta em camelCase
      const invoicePaymentData = response.waiting_invoice_payment 
        ? response.waiting_invoice_payment 
        : (response as any).waitingInvoicePayment 
          ? (response as any).waitingInvoicePayment 
          : null

      if (invoicePaymentData) {
        const {
          bank_slip_barcode,
          bank_slip_url,
          bonus_amount,
          invoice_id,
          payment_method,
          pix_qr_code,
          pix_hash,
        } = invoicePaymentData

        const chosenMethod: ChosenPaymentMethod = {
          chosenMethod: payment_method,
          bonusAmount: bonus_amount,
        }

        if (payment_method === 'pix') {
          const qrCode = await generateQR(pix_qr_code)

          chosenMethod.pix = {
            qrCode,
            pixKey: pix_qr_code,
            pixHash: pix_hash,
            invoiceId: invoice_id,
          }
        } else {
          chosenMethod.invoice = {
            bankSlipBarcode: bank_slip_barcode,
            bankSliptUrl: bank_slip_url,
          }
        }

        // Usado para exibir os dados de boleto e pix na tela de sucesso
        if (['bank-slip', 'pix'].includes(payment_method)) {
          setChosenPaymentMethod(chosenMethod)
        }
      }

      return response
    } catch (error: any) {
      Sentry.captureException(error)
      if (error.response?.status === 425 || error.response?.status === 409) {
        throw new CreateOrderInProgressError()
      }

      if (error.response?.status === 422 && error.response.data?.errors) {
        throw new PurchaseValidationError(error.response.data.errors)
      }

      throw error
    }
  }
}

export default usePlaceOrder
