import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaComplianceFields = Record<
  | FieldName.NOME_PF
  | FieldName.CPF,
  FieldConfig
>

class PesquisaCompliance extends Research {
  formFields: PesquisaComplianceFields = {
    nome_pf: Fields.nome_pf,
    cpf: Fields.cpf,
  }

  constructor() {
    super({
      id: 100,
      shortName: 'Pesquisa de KYC e Compliance',
      description: 'A pesquisa KYC e Compliance ajuda a proteger as empresas de atividades criminosas e garante que elas estejam operando dentro da lei.'
    })
  }
}

const service = new PesquisaCompliance().initialize()

export default service
