import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { ITEMS_PER_PAGE } from 'app/constants'
import { Group } from 'domain/groups/types'

type Params = {
  page: number
  setTotalResults: (value: number) => void
  search?: string
}

export default function useGroupsFilterQuery({ page, setTotalResults, search }: Params) {
  return useQuery(
    ['groups', 'list', 'filters', page, search],
    async () => {
      const params: any = {
        'per-page': ITEMS_PER_PAGE,
        sort: 'name',
        page,
      }

      if (search) {
        params['filter[name]'] = search
      }

      const response: AxiosResponse<Group[]> = await AuthorizedAPI.get('/groups', {
        params,
      })

      setTotalResults(Number(response.headers['x-pagination-total-count']))

      return response.data
    }   
  )
}
