import useDeleteItem from 'domain/createOrder/document/state/useDeleteItem'
import { PopConfirmDelete } from 'ui'

type Props = { itemId: number }

export default function DeleteItem({ itemId }: Props) {
  const onConfirm = useDeleteItem(itemId)

  return (
    <PopConfirmDelete
      title="Exclusão de item"
      description="Tem certeza que deseja excluir este item?"
      onConfirm={onConfirm}
    />
  )
}
