import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { FORMATO, TIPO_PESSOA } = FieldName

type CertidaoNegativaCorrecionalFields = Record<FieldName.CPF | FieldName.CNPJ, FieldConfig>

class CertidaoNegativaCorrecional extends Certificate {
  formFields: CertidaoNegativaCorrecionalFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
  }

  constructor() {
    super({
      id: 65,
      priceByFields: [FORMATO, TIPO_PESSOA],
      shortName: 'Certidão Negativa Correcional',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoNegativaCorrecional().initialize()

export default service
