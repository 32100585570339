import { useEffect, useRef } from 'react'
import axios, { AxiosResponse } from 'axios'

import { getAppVersion, setAppVersion } from 'app/config/appVersion'

function useCheckAppVersionListener() {
  const intervalId = useRef<any>(null)

  useEffect(() => {
    async function checkVersion() {
      const currentVersion = getAppVersion()
      const response: AxiosResponse<any> = await axios.get('/version.html')
      const latestVersion: number = response?.data ?? Date.now()

      setAppVersion(latestVersion)

      if (currentVersion  && latestVersion > currentVersion) {
        // @ts-ignore
        window.location.reload(true)
      }
    }

    const isFirstMount = !intervalId.current

    if (isFirstMount) {
      checkVersion()
      intervalId.current = setInterval(checkVersion, 1000 * 60 * 15)
    }
  }, [])
}

export default useCheckAppVersionListener
