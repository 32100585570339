import React from 'react'
import { useHistory } from 'react-router'

import { RoutePaths } from 'app/routes'
import { Button } from 'ui'

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  textColor?: string
}

export default function ReportButton({ textColor = 'text-primary', ...props }: Props) {
  const history = useHistory()

  return (
    <div {...props}>
      <Button
        type="primary"
        size="large"
        ghost
        onClick={() => history.push(RoutePaths.REPORTS)}
        icon="barChart"
        className={textColor}
      >
        Ver relatórios
      </Button>
    </div>
  )
}
