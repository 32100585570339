import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { BreadcrumbProps as AntBreadcrumbProps } from 'antd/es/breadcrumb';

export interface BreadcrumbProps extends AntBreadcrumbProps {
  className?: string;
}

function Breadcrumb({
  className,
  items
}: BreadcrumbProps) {

return (
  <AntdBreadcrumb
    className={className}
    items={items}
  />
)}

export default Breadcrumb;