import React from 'react'

import { ExplorerItem } from 'domain/myFiles/types'
import { DrawerActions, DrawerActionsProps } from 'ui'
import { useActionState } from 'domain/myFiles/state'

import MyFilesMassDownload from './MyFilesMassDownload'
import MyFilesMassDelete from './MyFilesMassDelete'

interface Props extends Pick<DrawerActionsProps, 'onClose'> {
  items: ExplorerItem[]
}

function MyFilesMassActions({ items, onClose }: Props) {
  const [action, setAction] = useActionState()
  const hasModalOpen = action === 'move'

  return (
    <DrawerActions onClose={onClose} total={items.length} keyboard={!hasModalOpen}>
      <DrawerActions.Button icon="folder" onClick={() => setAction('move')}>
        Mover para...
      </DrawerActions.Button>

      <MyFilesMassDownload items={items} />

      <MyFilesMassDelete items={items} onClose={onClose} />
    </DrawerActions>
  )
}

export default MyFilesMassActions
