import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoValorVenalFields = Record<
  FieldName.URL_UF | FieldName.URL_CIDADE | FieldName.CPF | FieldName.CNPJ | FieldName.INSCRICAO_IMOVEL,
  FieldConfig
>

class CertidaoValorVenal extends Certificate {
  formFields: CertidaoValorVenalFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    cpf: Fields.cpf,
    cnpj: Fields.cnpj,
    inscricao_imovel: Fields.inscricao_imovel,
  }

  constructor() {
    super({
      id: 106,
      shortName: 'Certidão de Valor Venal – Prefeitura',
      steps: ['dados'],
      priceByFields: [FieldName.URL_UF, FieldName.URL_CIDADE],
    })
  }
}

const service = new CertidaoValorVenal().initialize()

export default service
