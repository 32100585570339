import { Spin } from 'antd'

import { UseExtraInformationQueryParams, useExtraInformationQuery } from 'domain/orderItem/shared'
import { FieldConfig } from '../types'
import RadioGroupField, { RadioGroupFieldProps } from './RadioGroupField'

export interface ExtraInfoSelectProps
  extends Omit<UseExtraInformationQueryParams<any>, 'information'>,
    Omit<RadioGroupFieldProps, 'hasError' | 'information' | 'loading' | 'options'> {
  field: FieldConfig
}

export default function ExtraInfoRadioGroup({
  service,
  itemId,
  params,
  field,
  ...props
}: ExtraInfoSelectProps) {
  const { data, isFetching, isError } = useExtraInformationQuery<any>({
    service,
    information: field.apiExtraInfo!,
    itemId,
    params,
  })

  return (
    <Spin spinning={isFetching}>
      <RadioGroupField field={field} options={data} hasError={isError} itemId={itemId} {...props} />
    </Spin>
  )
}
