import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { SelectedService } from 'domain/createOrder/types'
import { KitLegalEntity } from 'domain/kits/types'
import { getServiceConfig } from 'domain/servicesCBRdoc/products'
import { Location } from 'domain/servicesCBRdoc/types'

export default function useServiceFederativeUnitsQuery(
  service: SelectedService,
  legalEntity: KitLegalEntity | undefined
) {
  return useQuery(
    [`service-federative-units-${service.id}-${service.categoryId}`],
    async () => {
      const serviceConfig = getServiceConfig(service.code!)

      const response: AxiosResponse<Location[]> = await AuthorizedAPI.get(
        `services/${service.id}/categories/${service.categoryId}/federative-units`
      )

      if (serviceConfig.getAvailableFederativeUnits) {
        const availableUfs = serviceConfig.getAvailableFederativeUnits(response.data, legalEntity)
        return availableUfs
      }

      return response.data
    },
    {
      enabled: Boolean(service.id && service.categoryId),
    }
  )
}
