import { cnpj as CNPJUtils } from 'cpf-cnpj-validator'

import { Fields } from 'domain/servicesCBRdoc'
import MaskedInput, { MaskedInputProps } from './MaskedInput'

interface Props extends Omit<MaskedInputProps, 'id' | 'mask' | 'name' | 'placeholder' | 'rules'> {
  itemId?: number | string
  label?: string
  name?: string
}

export default function CNPJInput({
  label = 'CNPJ',
  name = 'cnpj',
  required = true,
  itemId,
  ...props
}: Props) {
  return (
    <MaskedInput
      mask={Fields.cnpj.mask!}
      placeholder="00.000.000/0000-00"
      name={itemId !== undefined ? [itemId, name] : name}
      label={label}
      required={required}
      rules={required ? validationRules : undefined}
      id="cnpj"
      {...props}
    />
  )
}

const validationRules = [
  {
    validator: (rule: object, value: string) =>
      CNPJUtils.isValid(value, true) ? Promise.resolve() : Promise.reject('Informe um CNPJ válido.'),
  },
]
