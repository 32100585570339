import { CreateOrderState, ShoppingStep } from 'domain/createOrder/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'

import { Fields } from '../serviceFields'
import { DocumentConfig } from '../types'
import CreateOrderService from './CreateOrderService'

export default class DueDiligence extends CreateOrderService {
  public isDueDiligence = true
  public isKitAvailable: boolean

  constructor(config: Omit<DocumentConfig, 'steps' | 'priceByFields'> & { steps?: ShoppingStep[] }) {
    super({
      allowMultipleItems: false,
      priceByFields: [URL_UF, URL_CIDADE],
      steps: config.steps ?? ['dados', 'entrega'],
      ...config,
    })

    this.isKitAvailable = config.isKitAvailable ?? true
  }

  initialize = () => {
    this.formFields = {
      [URL_UF]: Fields.url_uf,
      [URL_CIDADE]: Fields.url_cidade,
      ...this.formFields,
    }

    super.initialize()

    return this
  }

  convertToCartItems = (createOrder: CreateOrderState) => {
    const orders = this.generateCartItems(createOrder)
    return orders
  }
}

const { URL_CIDADE, URL_UF } = FieldName
