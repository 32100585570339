import React from 'react'
import { Button } from 'antd'

import Icon from '../Icon/Icon'

interface Props {
  title: string
  children?: React.ReactNode
  buttonLabel?: string
  onClick: () => void
}

export default function SuccessModalMessage({ title, children, buttonLabel = 'OK', onClick }: Props) {
  return (
    <div>
      <div className="border-b border-gray-300 pt-6 pb-7">
        <div className="flex items-center">
          <Icon name="checkmark-circle" className="text-green-500 text-6xl mr-5" />
          <h1 className="font-bold text-2xl">{title}</h1>
        </div>
      </div>

      {children}

      <Button type="primary" className="mt-7" onClick={onClick} block>
        {buttonLabel}
      </Button>
    </div>
  )
}
