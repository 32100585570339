import { Link } from 'react-router-dom'

import { Routes } from 'app/routes'
import { Icon } from 'ui'
import { OrderItemsNavigation as Navigation } from '../../types'

export default function OrderItemsNavigation({ navigation }: { navigation: Navigation | undefined }) {
  if (!navigation) {
    return <div></div>
  }

  const { currentIndex, nextId, previousId, totalItems } = navigation
  const isFirstItem = !previousId
  const isLastItem = !nextId

  const mutedIconClass = 'flex text-gray-400 pointer-events-none'
  const normalIconClass = 'flex text-primary'

  return (
    <div className="flex flex-row justify-between items-center">
      <Link
        className={isFirstItem ? mutedIconClass : normalIconClass}
        to={isFirstItem ? '#' : Routes.ViewOrder(previousId)}
      >
        <Icon name="caret-left" />
      </Link>

      <p className="flex text-secondary-700 align-center text-center leading-[15px] font-semibold text-sm px-4">
        Item {currentIndex} de {totalItems}
      </p>

      <Link
        className={isLastItem ? mutedIconClass : normalIconClass}
        to={isLastItem ? '#' : Routes.ViewOrder(nextId)}
      >
        <Icon name="caret-right" />
      </Link>
    </div>
  )
}
