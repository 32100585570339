import { Tabs } from 'antd'
import { useState } from 'react'

import { PROD } from 'api/network/config'
import { Section } from 'layouts'
import DetailsForm from './DetailsForm'
import KitSpreadsheet from './KitSpreadsheet'

export default function KitOrderDetailsStep() {
  const [entryMode, setEntryMode] = useState<'manual' | 'spreadsheet'>('manual')

  return (
    <Section heading="Dados de seu Kit de Certidões" fullWidth>
      {!PROD && (
        <Tabs defaultActiveKey={entryMode} items={tabs} onChange={setEntryMode as any} className="mb-4" />
      )}

      {entryMode === 'manual' ? <DetailsForm /> : <KitSpreadsheet />}
    </Section>
  )
}

const tabs = [
  {
    label: 'Formulário',
    key: 'manual',
  },
  {
    label: 'Planilha',
    key: 'spreadsheet',
  },
]
