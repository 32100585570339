import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const {
  FORMATO,
  NAO_SEI_LIVRO_PAGINA,
  TIPO,
  TIPO_PESSOA,
  URL_CARTORIO,
  URL_CIDADE,
  URL_UF,
} = FieldName

type CertidaoEscrituraFields = Record<
  | FieldName.CPF
  | FieldName.CNPJ
  | FieldName.DATA_ATO
  | FieldName.LIVRO
  | FieldName.NAO_SEI_LIVRO_PAGINA
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.PAGINA
  | FieldName.TIPO
  | FieldName.URL_CARTORIO
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

class CertidaoEscritura extends Certificate {
  formFields: CertidaoEscrituraFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    url_cartorio: Fields.url_cartorio,
    cpf: Fields.cpf,
    cnpj: Fields.cnpj,
    nome_pf: {
      ...Fields.nome_pf,
      placeholder: 'Nome do outorgado na escritura',
    },
    nome_pj: {
      ...Fields.nome_pj,
      placeholder: 'Nome da empresa outorgada na escritura',
    },
    livro: {
      ...Fields.livro,
      optional: false,
      ignoreOnKitsAutomaticForm: true,
    },
    pagina: {
      ...Fields.pagina,
      optional: false,
      ignoreOnKitsAutomaticForm: true,
    },
    nao_sei_livro_pagina: {
      ...Fields.nao_sei_livro_pagina,
    },
    data_ato: {
      ...Fields.data_ato,
      optional: true,
    },
    tipo: Fields.tipo,
  }

  constructor() {
    super({
      availableFormatsFields: [URL_CARTORIO, URL_CIDADE, URL_UF],
      id: 6,
      priceByFields: [FORMATO, NAO_SEI_LIVRO_PAGINA, URL_CARTORIO, URL_CIDADE, URL_UF, TIPO, TIPO_PESSOA],
      shoudAutoLoadDynamicFields: true,
      shortName: 'Escritura',
      steps: ['localizacao', 'dados', 'entrega'],
    })
  }
}

const service = new CertidaoEscritura().initialize()

export default service
