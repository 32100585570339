import { useAddToCart } from 'domain/createOrder/shared/services'
import { CreateOrderState } from 'domain/createOrder/types'
import { useIsCustomerPostPaid } from 'domain/customers'
import { OrderItem, ResearchResult } from 'domain/orderItem/types'
import { ResultCode } from 'domain/orders/types'
import ResearchResultOrder from '../classes/ResearchResultOrder'

function useAddResearchResultToCart() {
  const addToCart = useAddToCart()
  const isPostPaid = useIsCustomerPostPaid()

  const addResearchResultToCart = async (result: ResearchResult, orderItem: OrderItem) => {
    const isPositive = !result.resultado || result.resultado === ResultCode.POSITIVE
    const resultCode = isPositive ? ResultCode.POSITIVE : ResultCode.NEGATIVE
    const createOrder = await new ResearchResultOrder(orderItem, result, resultCode).generate(isPostPaid!)

    if (createOrder) {
      addToCart(createOrder as CreateOrderState)
    }
  }

  return addResearchResultToCart
}

export default useAddResearchResultToCart
