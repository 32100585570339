import { CertificateFlow } from 'domain/createOrder/certificates'
import { DateField, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../certidaoSTMAcoesCriminaisConfig'

export default function CreateOrderCertidaoSTMCriminais() {
  return (
    <CertificateFlow
      renderFormItem={(itemId: number) => <CertidaoSTMCriminaisForm itemId={itemId} />}
    />
  )
}

const CertidaoSTMCriminaisForm = ({ itemId }: { itemId: number }) => {
  return (
    <>
      <CPFInput itemId={itemId} autoFocus={itemId === 0} />
      <NameField itemId={itemId} field={nome_pf} />
      <DateField itemId={itemId} field={nascimento} />
      <NameField itemId={itemId} field={mae} />
    </>
  )
}

const { mae, nascimento, nome_pf } = config.formFields
