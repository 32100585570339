import { Divider, Form, Pagination } from 'antd'

import { ITEMS_PER_PAGE } from 'app/constants'
import { requiredRule } from 'app/config/validationRules'
import { SelectFieldProps } from './SelectField'
import Select from './Select'

interface Props
  extends Omit<
    SelectFieldProps,
    'allowClear' | 'autoClearSearchValue' | 'className' | 'dropdownRender' | 'filterOption' | 'onSearch'
  > {
  hasError?: boolean
  page: number
  pageSize?: number
  total: number
  setPage: (value: number) => void
  setSearch: (term: string) => void
}

export default function SelectPaginated({
  hasError,
  help,
  label,
  name,
  page,
  pageSize = ITEMS_PER_PAGE,
  required,
  size = 'middle',
  setSearch,
  setPage,
  total,
  validateStatus,
  ...props
}: Props) {
  const setSearchTerm = (term: string) => {
    setSearch(term)
    setPage(1)
  }

  return (
    <Form.Item
      name={name}
      label={label}
      rules={required ? requiredRule : undefined}
      help={hasError ? 'Erro ao carregar os dados.' : help}
      validateStatus={hasError ? 'error' : validateStatus}
    >
      <Select
        optionFilterProp="label"
        onClear={() => {
          setSearchTerm('')
        }}
        onSearch={setSearchTerm}
        dropdownRender={menu => {
          if (total <= pageSize) {
            return menu
          }

          return (
            <>
              {menu}
              <Divider className="my-2" />
              <Pagination size="small" current={page} pageSize={10} total={total} onChange={setPage} />
            </>
          )
        }}
        size={size}
        {...props}
      />
    </Form.Item>
  )
}
