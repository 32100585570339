import { Table } from 'antd'
import { useMemo } from 'react'

import KitSpreadsheetIdentifyColsSelect from './KitSpreadsheetIdentifyColsSelect'

type Props = {
  rows: any[]
}

export default function KitSpreadsheetIdentifyCols({ rows }: Props) {
  const colsQuantity = rows[0] ? Object.keys(rows[0]).length : 0

  const columns = useMemo(() => {
    const cols: any[] = []

    for (let i = 0; i < colsQuantity; i++) {
      cols.push({
        className: 'normal-case text-sm',
        title: <KitSpreadsheetIdentifyColsSelect column={i} />,
        dataIndex: i,
      })
    }

    return cols
  }, [colsQuantity])

  return (
    <>
      <h3 className="text-lg text-secondary font-bold mb-2 mt-8">2. Identifique as colunas</h3>
      <p className="text-sm text-gray-500 mb-3 max-w-md">
        Exibe as primeiras linhas da planilha para a identificação dos campos por coluna.
      </p>

      <Table
        columns={columns}
        dataSource={rows}
        pagination={false}
        scroll={scroll}
        rowKey="rowId"
        size="small"
      />
    </>
  )
}

const scroll = { x: 800 }
