import { KitItem } from 'domain/kits/types'
import {
  KitOrderCertidaoJuntaComercial,
  KitOrderCertidaoNegativaDebitosTributariosPGE,
  KitOrderPesquisaDividaAtivaPGE,
  KitOrderPesquisaObito,
} from 'domain/servicesCBRdoc/products'
import KitOrderCertidaoInterdicao from 'domain/servicesCBRdoc/products/certificates/certidaoInterdicao/components/KitOrderCertidaoInterdicao'
import { CertificateCode, ResearchCode } from 'domain/servicesCBRdoc/types'
import { KitCommonFormConfig } from '../../types'

export default function OtherServiceFields({
  kitItem,
  fields,
}: {
  kitItem: KitItem
  fields?: KitCommonFormConfig
}) {
  if (kitItem.service.code === CertificateCode.CERTIDAO_JUNTA_COMERCIAL) {
    return <KitOrderCertidaoJuntaComercial kitItem={kitItem} />
  }

  if (kitItem.service.code === CertificateCode.CERTIDAO_NEGATIVA_DEBITOS_TRIBUTARIOS_PGE) {
    return <KitOrderCertidaoNegativaDebitosTributariosPGE itemId={kitItem.id} />
  }

  if (kitItem.service.code === CertificateCode.CERTIDAO_INTERDICAO) {
    return <KitOrderCertidaoInterdicao kitItem={kitItem} />
  }

  if (kitItem.service.code === ResearchCode.PESQUISA_DIVIDA_ATIVA_PGE) {
    return <KitOrderPesquisaDividaAtivaPGE kitItemId={kitItem.id} />
  }

  if (kitItem.service.code === ResearchCode.PESQUISA_OBITO) {
    return <KitOrderPesquisaObito kitItem={kitItem} />
  }

  if (fields && fields.registreeFields.length === 0 && fields.serviceFields.length === 0) {
    return (
      <p className="mt-4">
        Todos os dados necessários já foram preenchidos. Clique em Finalizar para criar seu pedido.
      </p>
    )
  }

  return null
}
