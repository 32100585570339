import React from 'react'
import { Form } from 'antd'

import { useOrdersOwnerFilterQuery } from 'domain/orders/services'
import { Select } from 'ui'

export default function RecurrencesFilterOwner() {
  const { data: owners, isFetching } = useOrdersOwnerFilterQuery()

  return (
    <Form.Item label="Responsável" name="ownerId">
      <Select
        placeholder="Selecione o responsável"
        options={owners || []}
        size="middle"
        optionLabelKey="name"
        optionValueKey="id"
        loading={isFetching}
      />
    </Form.Item>
  )
}
