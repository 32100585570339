import React from 'react'

import { SearchInvoice } from 'domain/invoices/components'
import { useSelectedMonth } from 'domain/invoices/state'
import { MonthPicker } from 'ui'

function PostpaidInvoicesFilters() {
  const [selectedMonth, setSelectedMonth] = useSelectedMonth()

  return (
    <div className="p-7 py-5 w-full flex flex-col md:flex-row bg-gray-200 border-b border-gray-300">
      <div className="mb-5 md:mb-0 md:mr-5">
        <div className="font-bold text-gray-600 text-2xs mb-1 uppercase">Mês/Ano</div>
        <MonthPicker
          value={selectedMonth}
          onChange={setSelectedMonth}
        />
      </div>

      <SearchInvoice />
    </div>
  )
}

export default PostpaidInvoicesFilters
