import React, { useState } from 'react'
import { Input, Button, Form, Select } from 'antd'

import { IBGEStateSelect } from 'domain/app'
import { Service, ServiceType } from 'domain/servicesCBRdoc/types'
import { Icon } from 'ui'

import { ServiceListType, SimulationInputItem } from '../types'

interface Props {
  simulationInput: SimulationInputItem[]
  setSimulationInput: React.Dispatch<React.SetStateAction<SimulationInputItem[]>>
  serviceType: ServiceType
  onServiceTypeChange: (type: ServiceListType) => void
  servicesList?: Service[]
  loading: boolean
}

export default function FormPriceSimulationAdvanced({
  simulationInput,
  setSimulationInput,
  servicesList,
  loading,
}: Props) {
  const [actualSimulationInput, setActualSimulationInput] = useState(simulationInput)

  const onSubmit = () => {
    setSimulationInput(actualSimulationInput)
  }

  return (
    <Form onFinish={onSubmit} className="mt-7 flex flex-col">
      <div className="flex-1">
        {actualSimulationInput?.map(item => {
          const currentItemIndex = actualSimulationInput.findIndex(currItem => currItem.id === item.id)
          const newInput = [...actualSimulationInput]
          const currentItem = newInput[currentItemIndex]
          const currentItemData = currentItem.data

          return (
            <div key={item.id} className="mt-3 border-b border-gray-300">
              <div className="mt-3">
                <div className="flex">
                  <div className="flex-1">
                    <div className="mb-1 uppercase text-3xs font-bold text-gray-600">Estado</div>
                    {/* TODO: Estados fixos? */}
                    <IBGEStateSelect
                      label=""
                      size="middle"
                      disableOptions={() => actualSimulationInput.map(input => input.url_uf)}
                      value={item.url_uf || undefined}
                      onChange={state => {
                        newInput[currentItemIndex] = {
                          ...item,
                          url_uf: state,
                        }

                        setActualSimulationInput(newInput)
                      }}
                    />
                  </div>
                  {newInput.length > 1 && newInput.length === currentItemIndex + 1 && (
                    <div
                      className="mt-6 py-2 pl-2 pr-1 cursor-pointer hover:opacity-75"
                      onClick={() => {
                        newInput.splice(currentItemIndex, 1)
                        setActualSimulationInput(newInput)
                      }}
                    >
                      <Icon name="trash" className="text-xl text-gray-500" />
                    </div>
                  )}
                </div>
                {item.url_uf &&
                  item.data?.map(serviceItem => {
                    const currentServiceIndex = currentItemData.findIndex(data => data.id === serviceItem.id)
                    const canBeRemoved =
                      currentItemData.length > 1 && currentItemData.length === currentServiceIndex + 1

                    let finalServicesList = servicesList && [...servicesList]
                    if (currentItem?.url_uf && EXCLUDE_STATES_CODES.includes(currentItem.url_uf)) {
                      finalServicesList = finalServicesList?.filter(service => service.id !== 9)
                    }

                    return (
                      <div key={serviceItem.id} className="mt-3">
                        <div className="flex w-full">
                          <div className="w-20">
                            <div className="mb-1 uppercase text-3xs font-bold text-gray-600">Quantidade</div>
                            <Input
                              type="number"
                              size="middle"
                              className="w-full"
                              min={1}
                              value={serviceItem.quantity}
                              onChange={e => {
                                currentItemData[currentServiceIndex] = {
                                  ...serviceItem,
                                  quantity: parseInt(e.target.value),
                                }
                                setActualSimulationInput(newInput)
                              }}
                            />
                          </div>
                          <div className="w-3 flex-none"></div>
                          <div className="flex-1">
                            <div className="mb-1 uppercase text-3xs font-bold text-gray-600">
                              Tipo de documento
                            </div>
                            <div className={`max-w-lg ${canBeRemoved ? 'md:max-w-336' : 'md:max-w-368'}`}>
                              <Select
                                size="middle"
                                className="w-full"
                                placeholder="Selecione o serviço"
                                value={serviceItem.service_id || undefined}
                                onChange={value => {
                                  currentItemData[currentServiceIndex] = {
                                    ...serviceItem,
                                    service_id: value,
                                  }
                                  setActualSimulationInput(newInput)
                                }}
                              >
                                {finalServicesList?.map(service => {
                                  const disabled = currentItemData.some(
                                    data => data.service_id === service.id
                                  )
                                  return (
                                    <Select.Option key={service.id} value={service.id} disabled={disabled}>
                                      {service.name}
                                    </Select.Option>
                                  )
                                })}
                              </Select>
                            </div>
                          </div>
                          {canBeRemoved && (
                            <div
                              className="mt-6 py-2 pl-2 pr-1 cursor-pointer hover:opacity-75"
                              onClick={() => {
                                currentItemData.splice(currentServiceIndex, 1)
                                setActualSimulationInput(newInput)
                              }}
                            >
                              <Icon name="trash" className="text-xl text-gray-500" />
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })}
              </div>
              <div>
                <Button
                  type="link"
                  icon={<Icon name="plus-circle" className="mr-2" />}
                  className="px-0"
                  disabled={!item.url_uf}
                  onClick={() => {
                    const initialData = INITIAL_INPUT_DATA[0].data[0]
                    const lastId = currentItemData[currentItemData.length - 1]?.id || initialData.id
                    newInput[currentItemIndex].data = [...item.data, { ...initialData, id: lastId + 1 }]
                    setActualSimulationInput(newInput)
                  }}
                >
                  Adicionar documento
                </Button>
              </div>
            </div>
          )
        })}

        <div>
          <Button
            type="link"
            icon={<Icon name="plus-circle" className="mr-2" />}
            className="px-0"
            onClick={() => {
              const initialItem = INITIAL_INPUT_DATA[0]
              const lastId = actualSimulationInput[actualSimulationInput.length - 1]?.id || initialItem.id
              const newInput = [...actualSimulationInput, { ...initialItem, id: lastId + 1 }]
              setActualSimulationInput(newInput)
            }}
          >
            Adicionar Estado
          </Button>
        </div>
      </div>

      <div className="mt-7 relative z-0">
        <Button
          htmlType="submit"
          type="primary"
          block
          disabled={
            !actualSimulationInput[0].data[0].quantity ||
            !actualSimulationInput[0].data[0].service_id ||
            !actualSimulationInput[0].url_uf
          }
          loading={loading}
        >
          Ver simulação de valor
        </Button>
      </div>
    </Form>
  )
}

const EXCLUDE_STATES_CODES = ['AC', 'AL', 'AP', 'BA', 'GO', 'MA', 'MT', 'PB', 'PI', 'RN', 'RS', 'RR', 'SE']

const INITIAL_INPUT_DATA: SimulationInputItem[] = [
  {
    id: 1,
    url_uf: null,
    data: [{ id: 1, service_id: null, quantity: 1 }],
  },
]
