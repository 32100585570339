import React, { useRef } from 'react'
import { Spin } from 'antd'

import { useSetTabEffect } from 'domain/orders/helpers'
import { useAllOrderItemsQuery, useOrdersQuery } from 'domain/orders/services'
import { OrderList } from 'domain/orders/types'
import { useHasFilters } from 'domain/orders/state/filters'
import { useGetIsRedirecting } from 'domain/orders/state/redirecting'
import { useGetCurrentTab } from 'domain/orders/state/tabs'
import { OrderItemListing } from 'domain/orderItem/types'
import { Loading } from 'ui'

import OrderItemsList from '../orderOverview/OrderItemsList'
import HasResultsContainer from './HasResultsContainer'
import OrdersFilters from './filters/OrdersFilters'
import OrdersMoreFilters from './filters/OrdersMoreFilters'
import Search from './filters/OrdersSearch'
import OrdersTabs from './OrdersTabs'
import OrdersList from './OrdersList'
import OrdersPagination from './OrdersPagination'
import OrderItemsPagination from '../orderOverview/OrderItemsPagination'

export default function Orders() {
  const topRef = useRef<HTMLDivElement>(null)
  const isRedirecting = useGetIsRedirecting()

  const { data: orders, isLoading, isFetching } = useOrdersQuery()
  const { data: orderItems, isLoading: isLoadingItems, isFetching: isFetchingItems } = useAllOrderItemsQuery()

  if (isLoading || isLoadingItems) {
    return <Loading message="Carregando pedidos..." />
  }

  return (
    <div className="flex flex-1 flex-col bg-gray-150 overflow-hidden" ref={topRef}>
      <div className="flex flex-1 flex-col p-7 overflow-hidden">
        <Spin spinning={isRedirecting}>
          <div className="flex flex-row flex-wrap justify-between items-center w-full mb-4 overflow-hidden">
            <div className="flex flex-row gap-3 justify-start items-end">
              <OrdersFilters />
              <OrdersMoreFilters />
            </div>
            <Search className="pt-5 justify-end" />
          </div>

          <OrdersAndItemsTabs
            topRef={topRef}
            orders={orders}
            orderItems={orderItems}
            loadingOrders={isFetching}
            loadingItems={isFetchingItems}
          />
        </Spin>
      </div>
    </div>
  )
}

interface Props {
  orders: OrderList[] | undefined
  orderItems: OrderItemListing[] | undefined
  loadingOrders: boolean
  loadingItems: boolean
  topRef: React.RefObject<HTMLDivElement>
}

function OrdersAndItemsTabs({ orders, orderItems, loadingOrders, loadingItems, topRef }: Props) {
  const currentTab = useGetCurrentTab()
  const hasFilterApplied = useHasFilters()

  useSetTabEffect(loadingOrders || loadingItems)

  return (
    <>
      <div className="flex flex-col bg-white rounded">
        <div className="px-8 py-4">
          <OrdersTabs />
        </div>
        <HasResultsContainer
          total={orders?.length || orderItems?.length}
          hasFilterApplied={hasFilterApplied}
          showFiltersAlert
        >
          {currentTab === 'orders' && <OrdersList orders={orders!} loading={loadingOrders} />}

          {currentTab === 'items' && <OrderItemsList items={orderItems!} loading={loadingItems} />}
        </HasResultsContainer>
      </div>
      {currentTab === 'orders' && <OrdersPagination topRef={topRef} />}
      {currentTab === 'items' && <OrderItemsPagination topRef={topRef} />}
    </>
  )
}
