import React from 'react'
import { Redirect } from 'react-router-dom'

import { AIOrderFlow } from 'domain/createOrder/ai'
import { SelectServiceByCodeInURL, useCreateOrderState } from 'domain/createOrder/document'
import { productsCreateOrder } from 'domain/servicesCBRdoc/products'
import { DocumentOrResearchCode, ServiceType } from 'domain/servicesCBRdoc/types'

import CreateDueDiligencePage from './CreateDueDiligencePage'

function CreateOrderServicePage() {
  const { createStatus, selectedService } = useCreateOrderState()

  if (!selectedService.code) {
    return <SelectServiceByCodeInURL />
  }

  if (createStatus === 'review') {
    return <Redirect to="/carrinho" />
  }

  if (selectedService!.type === ServiceType.DILIGENCE) {
    return <CreateDueDiligencePage />
  }

  if (selectedService!.type === ServiceType.AI) {
    return <AIOrderFlow />
  }

  const CreateOrderComponent = productsCreateOrder[selectedService!.code as DocumentOrResearchCode]

  if (!CreateOrderComponent) return null

  return <CreateOrderComponent />
}

export default CreateOrderServicePage
