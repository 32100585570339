import { useState } from 'react'
import { Button, Form } from 'antd'

import { KitItem, KitItemDefaultData, KitLegalEntity } from 'domain/kits/types'
import { SelectedService } from 'domain/orderItem/types'

import KitItemFormContent from '../registerKit/KitItemFormContent'
import DocumentSelect from './DocumentSelect'

interface Props {
  kitLegalEntity: KitLegalEntity
  submitting?: boolean
  onFinish: (item: Partial<KitItem>) => void
}

export default function AddKitItemForm({ kitLegalEntity, submitting, onFinish }: Props) {
  const [item, setItem] = useState<Partial<KitItem>>({})
  const [form] = Form.useForm()

  const onChangeDefaultData = (defaultData: Partial<KitItemDefaultData>) => {
    setItem({ ...item, defaultData: { ...item.defaultData, ...defaultData } as KitItemDefaultData })
  }

  const hasSelectedService = item.service?.categoryId && item.service?.code

  const onChangeDocument = (service: SelectedService) => {
    setItem({ service })
  }

  const handleFinish = () => {
    onFinish(item)
    form.setFieldValue('document', undefined)
    setItem({})
  }

  return (
    <Form form={form} onFinish={handleFinish} layout="vertical" requiredMark={false} className="max-w-lg">
      <DocumentSelect
        name="document"
        label="Selecione um documento para adicionar ao kit"
        kitLegalEntity={kitLegalEntity}
        onChange={onChangeDocument}
        autoFocus
      />

      <hr className="my-5" />

      {hasSelectedService && (
        <KitItemFormContent
          kitLegalEntity={kitLegalEntity}
          item={item as KitItem}
          onChange={onChangeDefaultData}
        />
      )}

      <Button
        htmlType="submit"
        disabled={submitting || !hasSelectedService}
        loading={submitting}
        type="primary"
        className="w-full"
      >
        Adicionar documento ao Kit
      </Button>
    </Form>
  )
}
