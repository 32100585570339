import { KitItem } from 'domain/kits/types'
import { Alert } from 'ui'
import { useGetUnavailableKitItems } from '../../state'

export default function AlertSpreadsheetUnvailable() {
  const kitWithUnavailableServices = useGetUnavailableKitItems()  
 
  if (kitWithUnavailableServices.length === 0) {
    return null
  }

  return <Warning kitItems={kitWithUnavailableServices} />
}

function Warning({ kitItems }: { kitItems: KitItem[] }) {
  const servicesSet = new Set<string>()
  
  for (const kitItem of kitItems) {
    servicesSet.add(kitItem.service.name)
  }

  const uniqueServicesName = [...servicesSet]

  return (
    <Alert
      type="warning"
      className="max-w-md"
      message="Os seguintes serviços não estão disponíveis via planilha:"
      description={
        <>
          <ul className="list-disc list-inside mt-2 text-gray-600">
            {uniqueServicesName.map((name, index) => <li key={index}>{name}</li>)}
          </ul>

          <p className="mt-3 font-bold">Estes serviços não serão adicionados ao carrinho.</p>
        </>
      }
    />
  )
}
