import { KitItem, PostKitItemAPI } from 'domain/kits/types'
import { Kit, KitLegalEntity, PostKitAPI } from '../types/kitsTypes'

export function convertLegalEntityToAPI(legalEntity: KitLegalEntity) {
  if (legalEntity === 'fisica') return 'PF'
  if (legalEntity === 'juridica') return 'PJ'
  return null
}

export function convertPartialKitToAPI(kit: Partial<Kit>) {
  const data: Partial<PostKitAPI> = {}

  if (kit.legalEntity) {
    data.restrict_entity_type_to = convertLegalEntityToAPI(kit.legalEntity)
  }

  if (kit.name) {
    data.name = kit.name
  }

  return data
}

export function convertKitItemPostAPI(item: KitItem) {
  const data: PostKitItemAPI = {
    service_id: item.service.id,
    service_category_id: item.service.categoryId,
    default_data: item.defaultData ?? {},
  }

  if (item.id) {
    data.id = item.id
  }

  return data
}
