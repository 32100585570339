import { useDispatch } from 'react-redux'

import { ExtraInfoSelect, ExtraInfoSelectProps } from 'domain/servicesCBRdoc/fields'
import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document/state'

export default function DynamicTypeSelect(
  props: Omit<ExtraInfoSelectProps, 'onChange' | 'service' | 'value'>
) {
  const dispatch = useDispatch()
  const createOrder = useCreateOrderState()
  const { selectedType, selectedService } = createOrder

  return (
    <ExtraInfoSelect
      service={selectedService}
      value={selectedType}
      onChange={(value: any) => {
        dispatch(createOrderActions.setSelectedType(value))
      }}
      {...props}
    />
  )
}
