import React from 'react'
import { Button, Form } from 'antd'

import { RoutePaths } from 'app/routes'
import { useCheckCNPJ } from 'domain/customers'
import { GuestLayout } from 'layouts'
import { CNPJInput, SubmitButton } from 'ui'

export default function RegisterCnpj() {
  const [form] = Form.useForm()
  const { checkCNPJ, loading } = useCheckCNPJ()

  const onSubmit = async ({ cnpj }: any) => {
    await checkCNPJ(cnpj)
  }

  return (
    <GuestLayout bgImage="bg-[url('assets/images/bg-register-lateral2.png')]">
      <h1 className="text-3xl font-light text-gray-700 mb-5">Qual é o seu CNPJ?</h1>

      <Form form={form} onFinish={onSubmit} layout="vertical" requiredMark={false} className="w-100">
        <CNPJInput autoFocus />

        <Form.Item noStyle>
          <Button
            type="link"
            href="https://documentonobrasil.com.br/?utm_source=cbrdoc&utm_medium=crie-uma-conta&utm_campaign=nao-tem-cnpj"
            target="_blank"
            className="px-0"
          >
            <span className="underline">Não tenho CNPJ</span>
          </Button>
        </Form.Item>

        <SubmitButton loading={loading} />
      </Form>

      <p className="mt-10 font-md text-center">
        <b>Já tem uma conta?</b>{' '}
        <Button type="link" className="px-1" href={RoutePaths.LOGIN}>
          <span className="underline">Faça seu login</span>
        </Button>
      </p>
    </GuestLayout>
  )
}
