import React from 'react'
import { Link } from 'react-router-dom'

import Routes from 'app/routes/Routes'
import { NotificationOrderItem } from 'domain/notifications/types'

interface Props {
  orderItem: Pick<NotificationOrderItem, 'id' | 'locationInfo' | 'name' | 'orderItemNumber'>
  noActions?: boolean
}

function NotificationExpired({ orderItem, noActions }: Props) {
  return (
    <Link to={Routes.ViewOrder(orderItem.id)} className="text-slate-500 hover:text-primary cursor-pointer">
      <p>
        O item #{orderItem.orderItemNumber} venceu hoje.{' '}
        {noActions ? '' : 'Que tal refazer o pedido em Ações?'}
      </p>
    </Link>
  )
}

export default NotificationExpired
