import { getFieldName } from 'domain/createOrder/shared'
import { DateInput, MaskedInputProps } from 'ui'
import { FieldConfig } from '../types'

interface Props extends Omit<MaskedInputProps, 'mask' | 'name'> {
  field: FieldConfig
  itemId?: number
}

export default function DateField({ field, itemId, required = !field.optional, ...props }: Props) {
  const name = getFieldName(field.name, itemId)

  return <DateInput label={field.label} id={field.name} name={name} required={required} {...props} />
}
