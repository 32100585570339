import React from 'react'

import { OrderStatuses } from 'domain/orders/types'
import { SelectMultiple } from 'ui'

function OrdersStatusFilter() {
  return (
    <SelectMultiple
      name="statuses"
      label="Status"
      placeholder="Selecione o status"
      size="middle"
      optionLabelKey="name"
      optionValueKey="code"
      options={Object.values(OrderStatuses)}
    />
  )
}

export default OrdersStatusFilter
