import React from 'react'
import { Link } from 'react-router-dom'

import Routes from 'app/routes/Routes'
import { NotificationOrder } from '../types'

interface Props {
  order: NotificationOrder
}

function LinkToOrder({ order }: Props) {
  return (
    <Link to={Routes.Order(order.id)} className="text-slate-500 hover:text-primary cursor-pointer">
      <p>
        {order.name} &nbsp; #{order.orderNumber}
      </p>
    </Link>
  )
}

export default LinkToOrder
