import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

export const currentPage = atom(1)
const pageSize = atomWithStorage('notifications-page-size', 20)
const totalResults = atom(0)
const totalPages = atom(0)

export const useCurrentPage = () => useAtom(currentPage)
export const usePageSize = () => useAtom(pageSize)

export const useGetCurrentPage = () => useAtomValue(currentPage)
export const useGetPageSize = () => useAtomValue(pageSize)
export const useGetTotalResults = () => useAtomValue(totalResults)

export const useSetTotalPages = () => useSetAtom(totalPages)
export const useSetTotalResults = () => useSetAtom(totalResults)
