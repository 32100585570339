import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { List } from 'antd'

import { useCurrentUser, useHasPermission } from 'domain/auth'
import { ExplorerItem, ExplorerItemType } from 'domain/myFiles/types'
import { useDeleteExplorerItems } from 'domain/myFiles/services'
import { DrawerActions, Icon, Modal, TextButton } from 'ui'

interface Props {
  items: ExplorerItem[]
  onClose: () => void
}

export default function MyFilesMassDelete({ items, onClose }: Props) {
  const canDeleteOtherUsersUploadedFiles = useHasPermission('canDeleteOtherUsersUploadedFiles')
  const canDeleteOwnUploadedFiles = useHasPermission('canDeleteOwnUploadedFiles')
  const hasPermission = canDeleteOtherUsersUploadedFiles || canDeleteOwnUploadedFiles

  if (!hasPermission || items.length === 0) {
    return null
  }

  return <MyFilesMassDeleteAction items={items} onClose={onClose} />
}

function MyFilesMassDeleteAction({ items, onClose }: Props) {
  const [showDeleteModal, toggleModal] = useReducer(prev => !prev, false)
  const user = useCurrentUser()
  const [removableItems, setRemovableItems] = useState<ExplorerItem[]>([])
  const canDeleteOtherUsersUploadedFiles = useHasPermission('canDeleteOtherUsersUploadedFiles')

  const deleteItems = useDeleteExplorerItems({
    onSuccess: () => {
      setRemovableItems([] as ExplorerItem[])
      toggleModal()
      onClose()
    },
  })

  const removeItem = (itemId: number) => {
    return () => {
      const itemsToRemove = removableItems.filter(o => o.id !== itemId)
      setRemovableItems(itemsToRemove)

      if (itemsToRemove.length === 0) {
        resolveRemovableItems()
        toggleModal()
      }
    }
  }

  const deleteSelectedItems = () => {
    deleteItems(removableItems)
  }

  const resolveRemovableItems = useCallback(() => {
    const userFiles = canDeleteOtherUsersUploadedFiles ? items : items.filter(o => o.ownerId === user.id)

    const allowedItems = userFiles.filter(
      o => o.type === ExplorerItemType.UPLOADED_FILE || o.type === ExplorerItemType.FOLDER
    )

    setRemovableItems(allowedItems)
  }, [items, user])

  useEffect(() => {
    resolveRemovableItems()
  }, [resolveRemovableItems])

  return (
    <>
      <DrawerActions.Button icon="trash" disabled={removableItems.length === 0} onClick={toggleModal}>
        Excluir
      </DrawerActions.Button>

      <Modal
        open={showDeleteModal}
        title="Você deseja excluir os itens selecionados?"
        okText="Confirmar"
        cancelText="Cancelar"
        onOk={deleteSelectedItems}
        onCancel={toggleModal}
        destroyOnClose
      >
        <List
          dataSource={removableItems}
          renderItem={item => (
            <List.Item key={item.id}>
              <List.Item.Meta
                avatar={<Icon name={item.type === 'folder' ? 'folder' : 'upload'} />}
                title={item.name}
              />
              <TextButton label="Remover" onClick={removeItem(item.id)} />
            </List.Item>
          )}
        />
      </Modal>
    </>
  )
}
