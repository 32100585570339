import { CertificateFlow } from 'domain/createOrder/certificates'
import { OrderNameField } from 'domain/createOrder/document'
import { DateField, NameField, TextField } from 'domain/servicesCBRdoc/fields'
import config from '../certidaoCasamentoConfig'

export default function CreateOrderCertidaoCasamento() {
  return (
    <CertificateFlow
      renderFormItem={(itemId: number) => <CertidaoCasamentoForm itemId={itemId} />}
      noRenderOrderName
    />
  )
}

const CertidaoCasamentoForm = ({ itemId }: { itemId: number }) => {
  return (
    <>
      <NameField itemId={itemId} field={conjuge1} autoFocus={itemId === 0} />
      <NameField itemId={itemId} field={conjuge2} />
      <DateField itemId={itemId} field={casamento} />
      <OrderNameField itemId={itemId} />

      <h2 className="uppercase">Dados complementares</h2>

      <hr className="my-5" />

      <TextField itemId={itemId} field={livro} />
      <TextField itemId={itemId} field={pagina} />
      <TextField itemId={itemId} field={termo} />
    </>
  )
}

const { casamento, conjuge1, conjuge2, livro, pagina, termo } = config.formFields
