import { ViewResearchActions, ViewResearchWithResults } from 'domain/orderItem'
import { ExcelGenerator, TableColumn } from 'domain/orderItem/types'

export default function ViewPesquisaEmpresaCartoriosPJ() {
  return (
    <ViewResearchWithResults columns={columns} actions={<ViewResearchActions excel={excelGenerator} />} />
  )
}

const columns: TableColumn[] = [
  {
    title: 'Nome',
    dataIndex: 'nome',
  },
  {
    title: 'CNPJ',
    dataIndex: 'documento',
  },
  {
    title: 'Número do Registro',
    dataIndex: 'numero_registro',
  },
  {
    title: 'Data',
    dataIndex: 'data_registro',
  },
  {
    title: 'Cidade',
    dataIndex: ['url_cidade', 'label'],
    className: 'font-bold',
  },
  {
    title: 'Estado',
    dataIndex: ['url_uf', 'label'],
  },
  {
    title: 'Cartório',
    dataIndex: ['url_cartorio', 'label'],
  },
]

const excelGenerator: ExcelGenerator = {
  columns: columns
}
