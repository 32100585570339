import { Icon } from 'ui'

interface Props {
  collapsed: boolean
  onToggle: () => void
}

export default function SideNavToggleButton({ collapsed, onToggle }: Props) {
  const handleToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onToggle()
  }

  return (
    <button
      className="hidden sm:flex w-full gap-3 items-center text-white text-base cursor-pointer ml-8 mb-9"
      onClick={handleToggle}
    >
      <span className={`transition-all transform duration-300 ${collapsed ? '-rotate-180' : 'mt-1'}`}>
        <Icon name="arrow-left" size={22} />
      </span>{' '}
      {!collapsed && 'Minimizar'}
    </button>
  )
}
