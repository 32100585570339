import React from 'react'
import { Modal } from 'antd'

import { AICreateOrderModel } from 'domain/openAI/types'
import { DrawerActions } from 'ui'
import useDeleteMyModels from '../services/useDeleteMyModels'

interface Props {
  selected: AICreateOrderModel[]
  onClose: () => void
}

export default function MyModelsMassDelete({ selected, onClose }: Props) {
  const { mutate } = useDeleteMyModels({
    onSuccess: onClose
  })

  return (
    <DrawerActions.Button
      title="Excluir Modelos"
      onClick={() => {
        Modal.confirm({
          title: 'Exclusão de Modelos',
          content: <>Tem certeza que deseja excluir os modelos selecionados?</>,
          okText: 'Excluir',
          cancelText: 'Cancelar',
          onOk: () => {
            mutate(selected.map(({ id }) => id))
          },
        })
      }}
      icon="trash"
    >
      Excluir
    </DrawerActions.Button>
  )
}
