import { Spin } from 'antd'

import { useIsCustomerPostPaid } from 'domain/customers'
import { StorageUsageCard } from 'domain/myFiles'
import { StatisticCard } from 'ui'
import { formatMoney } from 'utils/formatters'

import { useGetPeriodFilter } from '../../filters/filtersState'
import useStatisticsQuery from '../services/useStatisticsQuery'

export default function StatisticCards() {
  const period = useGetPeriodFilter()
  const { data: reportOrderStats, isLoading } = useStatisticsQuery(period)
  const isPostPaid = useIsCustomerPostPaid()

  return (
    <Spin spinning={isLoading}>
      <div className="pb-7 bg-gray-200 flex flex-row gap-3 justify-between items-stretch">
        <div className="w-full flex flex-row gap-3 justify-between items-stretch">
          <StatisticCard title="Pedidos" value={reportOrderStats?.count ?? 0} />
          <StatisticCard
            title={isPostPaid ? 'Custo Estimado' : 'Créditos utilizados'}
            value={formatMoney(reportOrderStats?.expendedAmount)}
          />
          <StatisticCard
            title="Ticket médio"
            value={formatMoney(reportOrderStats?.averageExpendedPerOrder)}
          />
          <StorageUsageCard />
        </div>
      </div>
    </Spin>
  )
}
