import { extractValue } from 'utils/transformData'
import { SelectedLocation } from '../types'

type Value = string | string[] | undefined

export function getLocationUrl(location: SelectedLocation) {
  const { url_cartorio, url_cidade, url_uf } = location ?? {}

  const uf = extractValue(url_uf) as Value
  const city = extractValue(url_cidade) as unknown as string | undefined
  const notary = extractValue(url_cartorio) as Value

  return { url_cartorio: notary, url_cidade: city, url_uf: uf }
}
