import Button from './Button'

export default function DownloadButton({ fileURL }: { fileURL: string | undefined | null }) {
  return (
    <Button
      type="link"
      icon="download"
      href={fileURL ?? '#'}
      target="_blank"
      unstyled
      disabled={!fileURL}
      className={`dropdown-options-primary h-auto gap-2 flex items-center p-0 ${!fileURL && 'cursor-not-allowed'}`}
    >
      Baixar
    </Button>
  )
}
