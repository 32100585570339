import { useEffect } from 'react'
import { Form } from 'antd'
import { NamePath } from 'antd/lib/form/interface'

import { FieldConfig } from 'domain/servicesCBRdoc/fields/types'

function useSelectDefaultOption<Type = string>({
  field,
  name,
  onChange,
  availableOptions = field.options as Type[],
}: {
  field: FieldConfig
  name?: NamePath
  onChange?: (type: Type) => void
  availableOptions?: Type[]
}) {
  const form = Form.useFormInstance()

  useEffect(() => {
    try {
      const fieldName = name ?? field.name

      if (availableOptions && !form.getFieldValue(fieldName)) {
        const defaultOption = availableOptions.find(value => value === field.defaultValue)
        const value = defaultOption ?? availableOptions[0]
        onChange && onChange(value)
        form.setFieldValue(fieldName, value)
      }
    } catch {
      // Não faz nada
    }
  }, [availableOptions])
}

export default useSelectDefaultOption
