import { Form, Input } from 'antd'
import { InputProps } from 'antd/lib/input'
import { isRequired } from 'app/config/validationRules'

import { getFieldName } from 'domain/createOrder/shared'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { validatePlacaVeiculo } from 'utils/validators'

interface Props extends Omit<InputProps, 'form'> {
  field: FieldConfig
  itemId?: number | string
}

export default function LicensePlateField({ field, itemId, autoFocus }: Props) {
  const form = Form.useFormInstance()
  const name = getFieldName(field.name, itemId)
  const rules = [
    isRequired,
    () => ({
      validator(_: any, value: string) {
        if (value && !validatePlacaVeiculo(value.replace('-', ''))) {
          return Promise.reject('Placa do veículo inválida.')
        }

        return Promise.resolve()
      },
    }),
  ]

  return (
    <Form.Item name={name} label={field.label} rules={rules} validateFirst>
      <Input
        id={FieldName.PLACA}
        placeholder="XXX-XXXX"
        maxLength={8}
        onChange={e => {
          const licensePlate = e.target.value
            .replace(/([a-z | A-Z]{3})([a-z | A-Z | 0-9]{4})/, '$1-$2')
            .toUpperCase()

          form.setFieldValue(name, licensePlate)
        }}
        autoFocus={autoFocus}
      />
    </Form.Item>
  )
}
