import { requiredRule } from 'app/config/validationRules'
import { getFieldName } from 'domain/createOrder/shared'
import { MaskedInput, MaskedInputProps } from 'ui'
import { FieldConfig } from '../types'

interface Props extends Omit<MaskedInputProps, 'mask' | 'name'> {
  field: FieldConfig
  itemId?: number | string
}

export default function MaskedField({
  field,
  itemId,
  placeholder = field.placeholder,
  required = !field.optional,
  ...props
}: Props) {
  const name = getFieldName(field.name, itemId)

  return (
    <MaskedInput
      label={field.label}
      mask={field.mask}
      placeholder={placeholder ?? (field.mask as string)}
      id={field.name}
      name={name}
      required={required}
      rules={required ? requiredRule : undefined}
      {...props}
    />
  )
}
