import React, { useEffect, useState } from 'react'
import { Button, message } from 'antd'

import { OrderItemListing } from 'domain/orderItem/types'
import { useRefreshOverviewQuery } from 'domain/orders/state/order'
import { DrawerActions, Modal } from 'ui'
import { useDownload } from 'utils/export'

interface Props {
  orderItems: OrderItemListing[]
  setEnableEsc: (value: boolean) => void
}

export default function OrderItemsMassDownload({ orderItems, setEnableEsc }: Props) {
  const [handleDownload, loading] = useDownload()
  const [modalOpen, setModalVisible] = useState(false)
  const refreshQuery = useRefreshOverviewQuery()

  const availableItems = orderItems.filter(({ fileURL }) => !!fileURL)
  const countAvailables = availableItems.length
  const hasUnavailableItems = orderItems.length > countAvailables

  const onToggleModal = () => setModalVisible(!modalOpen)

  const downloadAvailableFiles = async () => {
    try {
      const ordersIds = availableItems.map(({ id }) => id).join(',')
      await handleDownload(`orders/${ordersIds}/download`, 'cbrdoc-pedidos')
      refreshQuery()
    } catch (err: any) {
      message.error(err.message)
    }
  }

  const handleClick = () => {
    // Mostra o modal com a lista de downloads apenas se houver arquivos indisponíveis
    if (hasUnavailableItems) {
      setModalVisible(true)
    } else {
      downloadAvailableFiles()
    }
  }

  const isDisabled = availableItems.length === 0

  useEffect(() => {
    setEnableEsc(!modalOpen)
  }, [modalOpen])

  return (
    <>
      <DrawerActions.Button icon="download" disabled={isDisabled} loading={loading} onClick={handleClick}>
        Baixar
      </DrawerActions.Button>
      <Modal
        open={modalOpen}
        onCancel={onToggleModal}
        destroyOnClose
        footer={[
          <Button
            key="ok"
            type="primary"
            icon={undefined}
            onClick={downloadAvailableFiles}
            disabled={countAvailables === 0}
          >
            Baixar documentos disponíveis
          </Button>,
        ]}
      >
        <h3 className="font-black text-2xl mb-4 text-secondary-500">Importante</h3>

        <p className="text-secondary-500 mb-4">
          Identificamos um ou mais documentos selecionados que ainda não estão disponíveis para download.
          {orderItems.length < countAvailables && (
            <>
              Mas não se preocupe. <b>Você poderá baixar os arquivos que estão disponíveis.</b>
            </>
          )}
        </p>

        <h4 className="text-secondary-500 text-base font-bold mb-2">Confira a lista de documentos:</h4>

        <div style={{ maxHeight: 320, overflowY: 'auto' }}>
          <ul>
            {orderItems.map(o => (
              <li key={o.id} className="flex justify-between mb-2">
                <span className="pr-3 overflow-ellipsis overflow-hidden text-black">&bull; {o.name}</span>
                <span style={{ width: 87 }} className={o.fileURL ? 'text-green-500' : 'text-red-500'}>
                  {o.fileURL ? 'Disponível' : 'Indisponível'}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  )
}
