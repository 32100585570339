import React from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Spin } from 'antd'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { RoutePaths } from 'app/routes'
import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document/state'
import { ServiceAPI } from 'domain/servicesCBRdoc/types'

const SelectServiceByCodeInURL = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams<{ serviceCode: any }>()
  const { selectedService } = useCreateOrderState()

  useQuery(['service', params.serviceCode], () => getServiceByCode(params.serviceCode), {
    enabled: !selectedService.code,
    onSuccess: data => {
      if (data) {
        dispatch(createOrderActions.setSelectedService({
          id: data.id,
          code: data.code,
          name: data.name,
          type: data.type,
          categoryId: !data.category2_id ? data.category1_id : 0,
        }))
      }
    },
    onError: () => history.push(RoutePaths.CREATE_ORDER),
  })

  return <Spin className="mt-7" />
}

const getServiceByCode = async (serviceCode?: String) => {
  if (serviceCode) {
    const response: AxiosResponse<ServiceAPI> = await AuthorizedAPI.get(`/services/code/${serviceCode}`)
    return response.data
  }
}

export default SelectServiceByCodeInURL
