import { LabelInValue, SortBy, SortOrder } from 'domain/app/types'

const stringToCamelCase = (s: string) => {
  return s.replace(/([-_][a-z])/gi, ($1: any) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

export const objectToCamelCase = (obj: any) => {
  const entries = Object.entries(obj).map(([key, value]) => {
    if (value && !Array.isArray(value) && typeof value === 'object') {
      value = objectToCamelCase(value)
    }

    return [stringToCamelCase(key), value]
  })

  return Object.fromEntries(entries)
}

export function updateItemOnArrayByIndex(items: any[], newData: any, index: number) {
  const updateList = [...items]

  if (index > -1) {
    const original = updateList[index]

    updateList[index] = {
      ...original,
      ...newData,
    }
  }

  return updateList
}

export function updateItemOnArray(items: any[], newData: any, indexFinder: (item: any) => boolean) {
  const index = items.findIndex(indexFinder)
  return updateItemOnArrayByIndex(items, newData, index)
}

export function removeFromArrayById(items: any[], itemId: number) {
  const newItems = [...items]
  const tagIndex = newItems.findIndex(o => o.id === itemId)

  if (tagIndex >= 0) {
    newItems.splice(tagIndex, 1)
  }

  return newItems
}

export function removeIndexFromArray(items: any[], index: number) {
  const newItems = [...items]

  if (index > -1) {
    return newItems.splice(index, 1)
  }

  return newItems
}

export function updateItemOnArrayById(items: any[], newData: any) {
  return updateItemOnArray(items, newData, o => o.id === newData.id)
}

export function removeUndefined(data: any) {
  if (!data) return {}
  const entries = Object.entries(data).filter(([, value]) => value !== undefined && value !== null)
  return Object.fromEntries(entries)
}

export function removeEmpties(data: any) {
  const entries = Object.entries(data).filter(([, value]) => {
    return (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      (!Array.isArray(value) || value.length > 0) &&
      (typeof value !== 'object' || Object.keys(value as any).length > 0)
    )
  })
  return Object.fromEntries(entries)
}

export function removeFalsyEntries(data: any) {
  return Object.entries(data).filter(([, value]) => value && (!Array.isArray(value) || value.length > 0))
}

export function getFilters(data: Record<any, any>) {
  const entries = Object.entries(data)
  const filters: any = {}

  entries.forEach(([key, value]) => {
    const hasData =
      value !== undefined && value !== null && value !== '' && (!Array.isArray(value) || value.length > 0)

    if (hasData) {
      filters[`filter[${key}]`] = value
    }
  })

  return filters
}

export function convertObjectKeys(origin: Record<string, any>, keysMap: Record<string, string>) {
  const entries = Object.entries(origin).map(([key, value]) => [keysMap[key], value])
  return Object.fromEntries(entries)
}

export const convertTextToArray = (value: string) => {
  if (value === null || value === undefined) return []

  return value
    .split('\n')
    .map(item => item.trim())
    .filter(Boolean)
}

export function convertUrlParamsToArray(paramsString?: string) {
  if (paramsString) {
    return paramsString.split(',')
  }

  return []
}

// Formato a ser enviado para a API
export const convertSortBy = ({ field, direction }: SortBy<any>) => {
  return direction === 'descend' ? `-${field}` : field
}

export const getSortBy = (field: string | string[], direction: SortOrder) => {
  if (Array.isArray(field)) {
    field = field.join('.')
  }

  return direction === 'descend' ? `-${field}` : field
}

export const extract = (fields: string[], from: Record<any, any>) => {
  const data: any = {}

  fields.forEach(field => {
    data[field] = from[field]
  })

  return data
}

function extractValue(data: LabelInValue | LabelInValue[] | undefined, asArray?: 'asArray'): string[]
function extractValue(
  data: LabelInValue | LabelInValue[] | undefined,
  asArray?: 'asArray'
): string | string[] | undefined {
  const isMultiple = asArray === 'asArray'

  if (!data) {
    return isMultiple ? [] : undefined
  }

  if (Array.isArray(data)) {
    return (data.map(({ value }) => value) as string[]) ?? []
  }

  return isMultiple ? ([data.value] as string[]) : (data.value as string)
}

export { extractValue }

export function getValue(data: LabelInValue | any) {
  return ((data as LabelInValue)?.value as string) ?? data
}

export function getLabel(data: LabelInValue | LabelInValue[] | undefined, asArray?: boolean) {
  if (!data) return asArray ? [] : ''

  if (Array.isArray(data)) {
    const items = data.map(({ label }) => label)
    return asArray ? items : items.join(', ')
  }

  const label = data?.label ?? ''
  return asArray ? [label] : label
}

export const asArray = (data: any) => (Array.isArray(data) ? data : data.split(','))
