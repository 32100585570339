import React from 'react'
import { Button } from 'antd'

import Icon from 'ui/Icon'

export type ListViewOptionsType = 'card' | 'table'

interface Props {
  selected: ListViewOptionsType
  onChange: (mode: ListViewOptionsType) => void
}

function ListViewOptions(props: Props) {
  return (
    <>
      <ViewButton kind="table" {...props} />
      <ViewButton kind="card" {...props} />
    </>
  )
}

interface ViewButtonProps extends Props {
  kind: ListViewOptionsType
}

function ViewButton({ kind, selected, onChange }: ViewButtonProps) {
  const isSelected = kind === selected
  const style = isSelected ? 'bg-primary text-white' : 'bg-white text-primary'

  return (
    <Button onClick={() => onChange(kind)} className={`flex justify-center items-center w-9 h-9 ${style}`} size="small">
      <Icon name={kind === 'table' ? 'view-table' : 'view-card'} />
    </Button>
  )
}

export default ListViewOptions
