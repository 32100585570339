import { CreateOrderState } from 'domain/createOrder/types'
import { DocumentRegistration } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type RTDFields = Record<
  | FieldName.CPF
  | FieldName.NOME_PF
  | FieldName.EMAIL
  | FieldName.TIPO_FLUXO_ASSINATURAS
  | FieldName.URL_CARTORIO
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

const { TIPO_FLUXO_ASSINATURAS, URL_CARTORIO, URL_CIDADE, URL_UF } = FieldName

class RTD extends DocumentRegistration {
  formFields: RTDFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    url_cartorio: Fields.url_cartorio,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    email: Fields.email,
    tipo_fluxo_assinaturas: Fields.tipo_fluxo_assinaturas,
  }

  constructor() {
    super({
      id: 101,
      isKitAvailable: false,
      priceByFields: [TIPO_FLUXO_ASSINATURAS, URL_CARTORIO, URL_CIDADE, URL_UF],
      shortName: 'RTD',
      steps: ['localizacao', 'upload', 'assinantes'],
    })
  }

  protected getSubmitData = (createOrderState: CreateOrderState) => {
    return this.getDocRegistrationSubmitData(createOrderState)
  }
}

const service = new RTD().initialize()

export default service
