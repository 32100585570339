import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { NotificationAPI } from '../types'
import { convertLatestNotification } from './convertNotification'

function useLatestNotificationsQuery() {
  return useQuery(
    ['notifications', 'latest'],
    async () => {
      const response: AxiosResponse<NotificationAPI[]> = await AuthorizedAPI.get('/notifications', {
        params: {
          sort: '-created_at',
          'per-page': 5,
          'filter[read]': false,
        },
      })

      return response.data?.map(convertLatestNotification)
    },
    {
      refetchInterval: 15 * 60 * 1000, // 15 minutos
    }
  )
}

export default useLatestNotificationsQuery
