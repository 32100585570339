import { SelectedService } from 'domain/createOrder/types'
import { ServiceAPI } from 'domain/servicesCBRdoc/types'

function convertToSelectedService(service: ServiceAPI, categoryId: number = 0): SelectedService {
  return {
    id: service.id,
    code: service.code,
    name: service.name,
    shortName: service.short_name,
    categoryId: categoryId || !service.category2_id ? service.category1_id : 0,
    type: service.type,
  }
}

export default convertToSelectedService
