import React, { useMemo } from 'react'

import { useAllServices } from 'domain/servicesCBRdoc'
import { ServiceType } from 'domain/servicesCBRdoc/types'
import { SelectField, SelectFieldProps } from 'ui'
import { FormInstance } from 'antd'

interface Props extends Omit<SelectFieldProps, 'label'> {
  form: FormInstance
}

function DocumentTypeSelect(props: Props) {
  const services = useAllServices()

  const options = useMemo(() => {
    const options = [
      ...services.filter(o => o.type === ServiceType.CERTIFICATE),
      { id: 'other', name: 'Outro' },
    ]
    return options
  }, [services])

  return (
    <SelectField
      label="Tipo de Documento"
      placeholder="Selecione o tipo de documento do upload"
      options={options}
      optionLabelKey="name"
      optionValueKey="id"
      required
      allowClear={false}
      labelInValue
      {...props}
    />
  )
}

export default DocumentTypeSelect
