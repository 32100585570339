import { Spin } from 'antd'

import useRecurrencesQuery from '../services/useRecurrencesQuery'
import RecurrencesTable from './RecurrencesTable'

export default function RecurrencesList() {
  const { data, isFetching, isLoading, queryKey } = useRecurrencesQuery()

  if (isLoading) {
    return (
      <div className="mx-auto my-auto w-fit">
        <Spin />
      </div>
    )
  }

  return <RecurrencesTable recurrences={data} loading={isFetching} queryKey={queryKey} />
}
