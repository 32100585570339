import React from 'react'
import { StatusCode } from 'domain/orders/types'
import OrderStatusTag from './OrderStatusTag'

interface Props {
  totalItems: number
  totalFinalized: number
  statusCode: StatusCode
}

function OrderStatus({ totalItems, totalFinalized, statusCode }: Props) {
  return (
    <OrderStatusTag statusCode={statusCode}>
      {totalItems > 1 && (
        <>
          {totalFinalized} / {totalItems}
        </>
      )}
    </OrderStatusTag>
  )
}

export default OrderStatus
