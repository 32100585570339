import { useEffect } from 'react'
import { Form, FormItemProps, Spin } from 'antd'

import { getFieldName } from 'domain/createOrder/shared'
import { SelectedService } from 'domain/createOrder/types'
import { useExtraInformationQuery } from 'domain/orderItem/shared'
import { ExtraInfo } from 'domain/orderItem/types'
import { FieldConfig } from 'domain/servicesCBRdoc/fields/types'
import RadioGroupField from './RadioGroupField'

interface Props extends Omit<FormItemProps, 'name'> {
  information: ExtraInfo
  service: SelectedService
  params: Record<any, any>
  field: FieldConfig
  itemId?: number
  onChange?: (type: string) => void
}

export default function DynamicRadio({ information, service, itemId, params, ...props }: Props) {
  const { data, isFetching, isSuccess } = useExtraInformationQuery({
    service,
    information,
    params,
    itemId,
  })

  if (!(isFetching || isSuccess)) return null

  return (
    <Spin spinning={isFetching}>
      {!!data && <TypeSelect itemId={itemId} availableOptions={data as any} {...props} />}
    </Spin>
  )
}

interface TypeSelectProps extends Omit<Props, 'information' | 'params' | 'service'> {
  availableOptions: string[]
}

function TypeSelect({ itemId, field, onChange, availableOptions, ...props }: TypeSelectProps) {
  const form = Form.useFormInstance()
  const name = getFieldName(field.name, itemId)
  const options = field.options!.filter((o: any) => availableOptions.includes(o.value ?? o))

  useEffect(() => {
    if (availableOptions.length > 0) {
      const currentSelected = form.getFieldValue(name)

      if (!currentSelected || !availableOptions.find(option => option === currentSelected)) {
        const firstOption = availableOptions[0]
        const defaultType = field.defaultValue
          ? availableOptions.find(option => option === field.defaultValue) ?? firstOption
          : firstOption
        form.setFieldValue(name, defaultType)
        onChange && onChange(defaultType)
      }
    }
  }, [])

  return (
    <RadioGroupField
      field={field}
      options={options}
      onChange={onChange}
      itemId={itemId}
      vertical
      {...props}
    />
  )
}
