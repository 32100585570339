import { PrepaidInvoice, PrepaidInvoiceAPI } from 'domain/invoices/types'
import { displayDate } from 'utils/dateTime'
import { formatMoney } from 'utils/formatters'

function convertPrepaidInvoice(invoiceAPI: PrepaidInvoiceAPI): PrepaidInvoice {
  const { amount, approved_at, bonus_amount, created_at, id, operation, purchase, user } = invoiceAPI
  const defaultName = operation === 'C' ? `Crédito ${approved_at ? 'Sistema' : ''}` : 'Débito Sistema'

  return {
    amount,
    amountDisplay: (operation === 'C' ? '+' : '-') + formatMoney(amount),
    approvedAtDisplay: displayDate(approved_at),
    createdAtDisplay: displayDate(created_at),
    deliveredAtDisplay: getDeliveredDate(invoiceAPI),
    order: {
      name: purchase?.name || defaultName,
      orderNumber: purchase?.backoffice_id,
    },
    bonusAmountDisplay: formatMoney(bonus_amount),
    user: {
      name: user.name,
    },
    id,
    operation,
  }
}

// TODO: essa lógica deveria estar no back
const getDeliveredDate = (invoice: PrepaidInvoiceAPI) => {
  if (invoice.operation === 'C' && invoice.approved_at) {
    return displayDate(invoice.approved_at)
  }

  if (invoice.operation === 'D' && invoice.purchase) {
    const orderItems = invoice.purchase.orders
    const deliveredItems = orderItems.map(item => item.finished_at).filter(Boolean) as string[]
    const isAllDelivered = deliveredItems.length === orderItems.length

    if (isAllDelivered) {
      const deliveredDates = deliveredItems.map(date => new Date(date).getTime())
      const latestDelivery = Math.max(...deliveredDates)
      return displayDate(new Date(latestDelivery))
    }
  }

  return '-'
}

export default convertPrepaidInvoice
