import React from 'react'
import { Redirect } from 'react-router-dom'

import { RoutePaths } from 'app/routes'

function Home() {
  return <Redirect to={RoutePaths.ORDERS} from={RoutePaths.HOME} />
}

export default Home
