import { atom, createStore, useAtom, useAtomValue } from 'jotai'

export const globalScope = createStore()

// const topRefAtom = atom<React.RefObject<HTMLDivElement> | undefined>(undefined)
// const useSetTopRef = (ref: any) => useSetAtom(topRefAtom)

const isHydratedFromURLAtom = atom(false)

export const useIsHydratedFromURL = () => useAtom(isHydratedFromURLAtom, { store: globalScope })
export const useGetIsHydratedFromURL = () => useAtomValue(isHydratedFromURLAtom, { store: globalScope })
