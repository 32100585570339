import React from 'react'

import { ReactComponent as EmptyState } from 'assets/images/empty-state.svg'
import { AlertFullPage, Button } from 'ui'

interface Props {
  title: string
  description?: string
}

export default function AlertNoRecurrences({ title, description }: Props) {
  return (
    <AlertFullPage title={title} description={description} icon={<EmptyState className="mb-4 mx-auto" />}>
      <div className="w-full flex flex-col sm:flex-row max-w-md">
        <Button
          size="middle"
          type="primary"
          href="https://suporte.cbrdoc.com.br/hc/pt-br/articles/7816572836507-Como-criar-uma-recorr%C3%AAncia"
          className="mt-4 mx-auto rounded-sm min-w-56 max-w-xs flex items-center justify-center text-sm font-semibold"
          target="_blank"
          ghost
        >
          Saiba mais
        </Button>
      </div>
    </AlertFullPage>
  )
}
