import { useQuery } from 'react-query'
import Axios, { AxiosResponse } from 'axios'

import { IBGEState } from '../types'

function useIBGEStatesQuery() {
  return useQuery(
    'ibge-states',
    async () => {
      const response: AxiosResponse<IBGEState[]> = await Axios.get(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
      )
      response.data.sort((a, b) => a.nome.localeCompare(b.nome))
      return response.data
    },
  )
}

export default useIBGEStatesQuery
