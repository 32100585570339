import { useQuery } from 'react-query'

import {
  useGetCurrentPage,
  useGetPageSize,
  useSetTotalPages,
  useSetTotalResults,
  useGetPrepaidSortBy,
  useGetSelectedMonth,
  useGetSearch,
  useGetOperation,
} from 'domain/invoices/state'
import getPrepaidInvoices from './getPrepaidInvoices'

function usePrepaidInvoicesQuery() {
  const selectedMonth = useGetSelectedMonth()
  const searchValue = useGetSearch()
  const operation = useGetOperation()
  const sort = useGetPrepaidSortBy()
  const currentPage = useGetCurrentPage()
  const pageSize = useGetPageSize()
  const setTotalPages = useSetTotalPages()
  const setTotalResults = useSetTotalResults()

  return useQuery(
    ['invoices', 'prepaid', selectedMonth, searchValue, pageSize, currentPage],
    async () => {
      const response = await getPrepaidInvoices({
        pageSize,
        currentPage,
        selectedMonth,
        searchValue,
        sortField: sort,
        operation,
      })
      setTotalPages(Number(response.headers['x-pagination-page-count']))
      setTotalResults(Number(response.headers['x-pagination-total-count']))

      return response.data
    },
    { keepPreviousData: true }
  )
}

export default usePrepaidInvoicesQuery
