import React from 'react'

interface Props {
  children: React.ReactNode
  title: string
  className?: string
  direction?: 'row' | 'col'
  innerClassName?: string
}

function RowItemsContainer({ children, title, className, innerClassName, direction = 'row' }: Props) {
  return (
    <div className={`flex flex-col ${className}`}>
      <h4 className="text-gray-600 uppercase mb-2 text-xs">{title}</h4>

      <div
        className={`flex flex-wrap w-max gap-2 ${
          direction === 'row' ? '' : 'flex-col items-start'
        } ${innerClassName} `}
      >
        {children}
      </div>
    </div>
  )
}

export default RowItemsContainer
