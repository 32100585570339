import React from 'react'
import { useDispatch } from 'react-redux'

import { createOrderActions } from 'domain/createOrder/document'
import { FormLabel, TextInput, TextInputProps } from 'ui'
import { FieldConfig } from '../types'
import { getFieldName } from 'domain/createOrder/shared'

export interface LocationTextInputProps extends Omit<TextInputProps, 'label'> {
  field: FieldConfig
  itemId?: number | string
  label?: string
}

function  LocationTextInput({
  field,
  itemId,
  label = field.label,
  ...props
}: LocationTextInputProps) {
  const name = getFieldName(field.name, itemId)
  const dispatch = useDispatch()

  const saveOnLocalStorage = (e: any) => {
    dispatch(createOrderActions.updateFormData({ local_servico: e.target.value }))
  }

  return (
    <TextInput
      label={<FormLabel label={label} description={field.labelDescription} required={props.required} />}
      name={name}
      maxLength={field.maxLength ?? 200}
      onChange={saveOnLocalStorage}
      {...props}
    />
  )
}

export default LocationTextInput
