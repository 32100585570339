import { Form, Radio } from 'antd'
import { useDispatch } from 'react-redux'

import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document'
import { Box, ExtraCheckbox } from 'domain/createOrder/shared'
import { Extras } from 'domain/createOrder/types'

export default function InteiroTeor() {
  const dispatch = useDispatch()
  const form = Form.useFormInstance()
  const { selectedExtras } = useCreateOrderState()

  const selectExtra = (selected: Partial<Extras>) => {
    dispatch(
      createOrderActions.setSelectedExtras({
        ...selectedExtras,
        ...selected,
      })
    )
  }

  return (
    <>
      <ExtraCheckbox
        label="Inteiro teor"
        description="Todos os dados da certidão para procedimentos específicos, como processo de cidadania."
        checked={!!selectedExtras?.tipo_inteiro_teor}
        onChange={e => {
          const checked = e.target.checked

          selectExtra({
            tipo_inteiro_teor: checked ? 'transcricaodigital' : undefined,
          })

          if (checked) {
            if (!form.getFieldValue('tipo_inteiro_teor')) {
              form.setFieldValue('tipo_inteiro_teor', 'transcricaodigital')
            }
          } else {
            form.setFieldValue('tipo_inteiro_teor', undefined)
          }
          
        }}
      />

      {!!selectedExtras?.tipo_inteiro_teor && (
        <Box>
          <Form.Item name="tipo_inteiro_teor" label="Selecione o tipo de certidão de inteiro teor" className="[&_label]:mb-4">
            <Radio.Group
              value={selectedExtras?.tipo_inteiro_teor || 'transcricaodigital'}
              onChange={e => selectExtra({ tipo_inteiro_teor: e.target.value })}
            >
              <Radio value="transcricaodigital">
                <b>Transcrição digital</b>
                <div className="text-2xs text-gray-600 pt-1 whitespace-normal">
                  Datilografada e impressa com base nas informações extraídas do Livro de Registros.
                </div>
              </Radio>
              <Radio value="reprografica">
                <b>Reprográfica</b>
                <div className="text-2xs text-gray-600 pt-1 whitespace-normal">
                  Xerox ou fotocópia do Livro de Registros.
                </div>
              </Radio>
              <Radio value="transcricaodigitalreprografica">
                <b>Transcrição digital + Reprográfica</b>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Box>
      )}
    </>
  )
}
