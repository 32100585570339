import React from 'react'

import { ViewResearchActions, ViewResearchWithResults } from 'domain/orderItem'
import { ExcelGenerator, TableColumn } from 'domain/orderItem/types'

function ViewPesquisaProtesto() {
  return (
    <ViewResearchWithResults columns={columns} actions={<ViewResearchActions excel={excelGenerator} />} />
  )
}

const columns: TableColumn[] = [
  {
    title: 'CPF / CNPJ',
    dataIndex: 'documento',
  },
  {
    title: 'Valor',
    dataIndex: 'valor_total',
  },
  {
    title: 'Cartório',
    dataIndex: ['url_cartorio', 'label'],
  },
  {
    title: 'Cidade',
    dataIndex: ['url_cidade', 'label'],
    className: 'font-bold',
  },
  {
    title: 'Estado',
    dataIndex: ['url_uf', 'label'],
  },
]

const excelGenerator: ExcelGenerator = {
  columns
}

export default ViewPesquisaProtesto
