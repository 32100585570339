import React, { useState } from 'react'
import { Button, Spin, message } from 'antd'

import queryClient from 'app/config/queryClient'
import { useMoveMutation } from 'domain/myFiles/services'
import { useActionState, useGetExplorerItem } from 'domain/myFiles/state'
import { ExplorerItem, ExplorerItemType } from 'domain/myFiles/types'
import { Icon, Modal } from 'ui'

import CreateFolder from '../folder/CreateFolder'
import useExplorerQuery from 'domain/myFiles/services/useExplorerQuery'

interface Props {
  selectedItems: ExplorerItem[]
  onFolderCreated?: () => void
  onItemMoved?: () => void
}

export default function MoveItemModal({ selectedItems, onFolderCreated, onItemMoved }: Props) {
  const explorerItem = useGetExplorerItem()
  const [action, setAction] = useActionState()
  const [selectedFolderId, setSelectedFolderId] = useState<number>()
  const [folderParentId, setFolderParentId] = useState<number | undefined | null>(explorerItem?.parentId)

  const {
    data: items,
    isFetching,
    refetch,
  } = useExplorerQuery({ parentId: folderParentId as number, filters: { type: ExplorerItemType.FOLDER } })

  const { moveItem, loading } = useMoveMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['my-files', 'item'])
      onItemMoved && onItemMoved()
      setAction(undefined)
    },
  })

  const selectedItemsIds = selectedItems.map(item => item.id) || []
  const selectedItemsParentId = selectedItems[0]?.parentId || undefined

  const handleMoveItem = () => {
    moveItem({ id: selectedItemsIds.join(','), parentId: selectedFolderId ? selectedFolderId : 'root' })
  }

  const itemName =
    selectedItems && selectedItems.length === 1 ? selectedItems[0].name : `${selectedItems?.length} arquivos`

  const lastBreadcrumb = items?.breadcrumb[items?.breadcrumb?.length - 1]

  return (
    <>
      <Modal
        open={action === 'move'}
        title={
          <>
            Mover <b>{itemName}</b> para
          </>
        }
        onCancel={() => setAction(undefined)}
        footer={null}
        afterClose={() => {
          setFolderParentId(undefined)
          setSelectedFolderId(undefined)
        }}
      >
        <Spin spinning={isFetching}>
          <div className="bg-gray-200 mb-4 overflow-hidden">
            <div className="py-3 bg-white shadow-md flex item-center justify-between">
              {lastBreadcrumb?.id ? (
                <div
                  role="button"
                  className="flex items-center text-primary font-semibold hover:opacity-75"
                  onClick={() => {
                    setFolderParentId(lastBreadcrumb?.parentId)
                    setSelectedFolderId(lastBreadcrumb?.parentId)
                  }}
                >
                  <Icon name="arrow-left" className="mr-2 -mt-1" />
                  <div>{lastBreadcrumb?.name}</div>
                </div>
              ) : (
                <div className="font-semibold">Meus documentos</div>
              )}
              <CreateFolder
                parentId={folderParentId}
                onFolderCreated={folder => {
                  refetch()
                  setSelectedFolderId(folder.id)
                  setFolderParentId(folder.id)
                  message.success('Sua pasta foi criada com sucesso!')
                  onFolderCreated && onFolderCreated()
                }}
              />
            </div>

            <div className="py-2 px-6 bg-gray-100 h-80 shadow-b-inner overflow-y-auto">
              {items?.children?.length === 0 && (
                <div className="p-2 text-gray-600">Esta pasta está vazia</div>
              )}
              <>
                {items?.children?.map(item => {
                  if (item.type === 'folder') {
                    return (
                      <div
                        key={item.id}
                        className={`p-1 mb-1 flex items-center ${
                          item.id === selectedFolderId
                            ? 'border-2 border-primary bg-white hover:bg-opacity-75'
                            : 'border-2 border-white bg-white hover:bg-gray-100'
                        } transition-colors duration-150 cursor-pointer ${
                          selectedItemsIds.includes(item.id) ? 'py-3 opacity-50 pointer-events-none' : ''
                        }`}
                      >
                        <div
                          role="button"
                          className="flex-1 flex items-center"
                          onClick={() => {
                            if (!selectedItemsIds.includes(item.id)) {
                              setSelectedFolderId(prev =>
                                prev === item.id ? Number(folderParentId) : item.id
                              )
                            }
                          }}
                        >
                          <Icon name="folder" className="ml-2 mr-2 flex-none" />
                          <div className="flex-1 pr-10">{item.name}</div>
                        </div>
                        {!selectedItemsIds.includes(item.id) && (
                          <div
                            role="button"
                            onClick={() => {
                              setSelectedFolderId(item.id)
                              setFolderParentId(item.id)
                            }}
                            className="w-10 h-10 flex items-center justify-center flex-none transition-colors duration-150 hover:bg-gray-200 rounded"
                          >
                            <Icon name="arrow-right" className="text-primary" />
                          </div>
                        )}
                      </div>
                    )
                  }

                  return (
                    <div
                      key={item.id}
                      className="p-3 mb-1 flex items-center hover:bg-gray-100 transition-colors duration-150 cursor-pointer opacity-50"
                    >
                      <Icon name="file" className="mr-2 flex-none" />
                      <div className="flex-1">{item.name}</div>
                    </div>
                  )
                })}
              </>
            </div>
          </div>
        </Spin>
        <Button
          type="primary"
          onClick={handleMoveItem}
          disabled={
            (selectedFolderId ? selectedFolderId : undefined) === selectedItemsParentId ||
            selectedItemsIds.length === 0
          }
          loading={loading}
          block
        >
          Mover {selectedFolderId === folderParentId && 'aqui'}
        </Button>
      </Modal>
    </>
  )
}
