import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Routes } from 'app/routes'
import { createOrderActions } from 'domain/createOrder/document/state'
import { AIShoppingStep } from 'domain/createOrder/types'
import { ModelForm } from 'domain/openAI/models'
import { AIModelForm } from 'domain/openAI/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'

export default function AIOrderModelStep() {
  const history = useHistory()
  const dispatch = useDispatch() 

  const onSubmit = async (model: AIModelForm) => {
    dispatch(createOrderActions.updateFormData({ [FieldName.NOME_ITEM_PEDIDO]: `IA - ${model.modelName}` }))
    history.push(Routes.CreateOrderAI(AIShoppingStep.DADOS))
  }

  return <ModelForm onSubmit={onSubmit} />
}
