import React from 'react'

type Props = {
  label: string
  value?: any
  divider?: boolean
}

function CardViewItem({ label, value, divider = false }: Props) {
  return (
    <li className={`${divider ? 'border-b-2' : ''} flex justify-between items-center py-2`}>
      <div className="flex justify-between w-full">
        <span className="text-secondary-500 font-extrabold uppercase"> {label}</span>
        <span className="w-5/6"> {value}</span>
      </div>
    </li>
  )
}

export default CardViewItem
