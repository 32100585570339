import { useMemo } from 'react'
import { InputProps } from 'antd/lib/input'
import { NamePath } from 'antd/lib/form/interface'
import { Rule } from 'antd/lib/form'

import { isAfter, validateDate } from 'utils/dateTime'
import MaskedInput from './MaskedInput'

interface DateInputProps extends Omit<InputProps, 'defaultValue' | 'name' | 'value'> {
  name?: NamePath
  required?: boolean
  onChange?: (event: any) => void
  label?: string
  value?: string
  allowFutureDate?: boolean
}

export default function DateInput({ required = true, allowFutureDate, ...props }: DateInputProps) {
  const validationRules = useMemo(() => {
    const rules: Rule[] = [
      {
        validator: (rule: object, value: string) => {
          if (!value) {
            return Promise.resolve()
          }

          const isValidDate = validateDate(value)

          if (!isValidDate) {
            return Promise.reject('Informe uma data válida.')
          }

          if (!allowFutureDate) {
            const isFutureDate = isAfter(value)

            if (isFutureDate) {
              return Promise.reject('A data não pode ser futura.')
            }
          }          

          return Promise.resolve()
        },
      },
    ]

    if (required) {
      rules.push({ required: true, message: 'Campo obrigatório.' })
    }

    return rules
  }, [required, allowFutureDate])

  return <MaskedInput required={required} rules={validationRules} mask="00/00/0000" {...props} />
}
