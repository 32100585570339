import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomURI } from 'utils/navigation'

// Has filters
export const useHasPostpaidFilters = () => {
  const search = useAtomValue(searchAtom)
  return search !== ''
}

// Month
const monthAtom = atom<Date>(new Date)
const monthUpdateURIAtom = atomURI(monthAtom, 'month')

export const useSelectedMonth = () => useAtom(monthUpdateURIAtom)
export const useGetSelectedMonth = () => useAtomValue(monthAtom)
export const useSetSelectedMonth = () => useSetAtom(monthUpdateURIAtom)

// Prepaid Operation
const operationAtom = atom<'C' | 'D' | ''>('')
const operationURIAtom = atomURI(operationAtom, 'operation')

export const useGetOperation = () => useAtomValue(operationAtom)
export const useOperation = () => useAtom(operationURIAtom)
export const useSetOperation = () => useSetAtom(operationURIAtom)

// Search
const searchAtom = atom('')
const searchUpdateURIAtom = atomURI(searchAtom, 'q')

export const useSearch = () => useAtom(searchUpdateURIAtom)
export const useGetSearch = () => useAtomValue(searchAtom)
export const useSetSearch = () => useSetAtom(searchUpdateURIAtom)
