import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { useIsCustomerPostPaid } from 'domain/customers'
import { shoppingCartStateActions } from 'domain/shoppingCart'
import { LargeButton } from 'ui'

interface Params {
  orderId: number
  disable?: boolean
}

export default function AddItemsToOrderButton({ orderId, disable = false }: Params) {
  const dispatch = useDispatch()
  const history = useHistory()
  const isPostPaid = useIsCustomerPostPaid()

  const handleAddItems = () => {
    dispatch(shoppingCartStateActions.setOrderId(orderId))
    history.push(RoutePaths.CREATE_ORDER)
  }

  if (!isPostPaid || disable) {
    return null
  }

  return (
    <LargeButton icon="plus-circle" onClick={handleAddItems} className="bg-white rounded px-4 py-2">
      Adicionar mais itens
    </LargeButton>
  )
}
