import React from 'react'
import { Space, message } from 'antd'
import { Link } from 'react-router-dom'

import { Icon, Modal, SubmitButton } from 'ui'

import useChangeRecurrenceStatus from '../services/useChangeRecurrenceStatus'
import { Recurrence } from '../types'
import { Routes } from 'app/routes'

type Props = {
  onClose: () => void
  recurrence?: Recurrence
  queryKey: any[]
}

function InactiveRecurrence({ queryKey, recurrence, onClose }: Props) {
  const { mutate: inactivateRecurrence, isLoading } = useChangeRecurrenceStatus({
    queryKey,
    onSuccess: () => {
      message.success('Recorrência desativada com sucesso.')
      onClose()
    },
  })

  const onConfirmInactivate = async () => {
    await inactivateRecurrence({ id: recurrence!.id, active: false })
  }

  return (
    <Modal
      open={recurrence !== undefined}
      title={<div className="flex justify-between items-center">Atenção</div>}
      onCancel={onClose}
      footer={null}
    >
      <Space direction="vertical" size="large">
        <div className="flex items-center gap-5">
          <Icon name="alert" size={42} className="text-error" />
          <b>Deseja desativar a recorrência?</b>
        </div>
        {recurrence && (
          <p>
            Você também pode <Link to={Routes.EditRecurrence(recurrence.id)}>editar a recorrência</Link> ou
            reativar a qualquer momento.
          </p>
        )}
      </Space>

      <SubmitButton label="Desativar" className="w-full" loading={isLoading} onClick={onConfirmInactivate} />
    </Modal>
  )
}

export default InactiveRecurrence
