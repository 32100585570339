import React, { useCallback } from 'react'
import { Spin, Table } from 'antd'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Link } from 'react-router-dom'

import { RoutePaths, Routes } from 'app/routes'
import { SortOrder } from 'domain/app/types'
import { useMoveMutation } from 'domain/myFiles/services'
import { ExplorerItem, ExplorerItemType } from 'domain/myFiles/types'
import { Icon, Pagination } from 'ui'

import MyFilesInlineActions from './MyFilesInlineActions'
import DraggableBodyRow from './DraggableBodyRow'

export interface MyFilesTableProps {
  data: ExplorerItem[] | undefined
  isFetching: boolean
  pagination: any
  setSortField: React.Dispatch<React.SetStateAction<string>>
  setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>
  rowSelection: any
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<any>>
  setSelectedItems: React.Dispatch<React.SetStateAction<any>>
}

export default function MyFilesTable({
  data,
  isFetching,
  pagination,
  setSortField,
  setSortOrder,
  rowSelection,
  setSelectedRowKeys,
  setSelectedItems,
}: MyFilesTableProps) {
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortField(sorter.field)
    setSortOrder(sorter.order)
  }

  const handleDrop = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const moveIsAllowed =
        data &&
        data[dragIndex]?.type !== ExplorerItemType.FOLDER &&
        data[hoverIndex].type === ExplorerItemType.FOLDER

      if (moveIsAllowed) {
        moveItem({ id: data[dragIndex].id.toString(), parentId: data[hoverIndex].id })
      }
    },
    [data]
  )

  const { moveItem } = useMoveMutation()

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
      render: (text: string, record: any) => {
        let IconName: 'file' | 'folder' = 'file'
        if (record.type === 'folder') {
          IconName = 'folder'
          return (
            <Link
              to={`${RoutePaths.MY_FILES}/pastas/${record.id}`}
              className="text-gray-700 font-bold hover:text-gray-500 flex items-center"
            >
              <Icon name={IconName} className="text-xl mr-3 flex-none" />
              <div className="text-sm">{text}</div>
            </Link>
          )
        }
        return (
          <Link
            to={record.type === 'order' ? Routes.ViewOrder(record.orderId) : Routes.ViewFile(record.id)}
            className="text-gray-700 hover:text-gray-500 flex items-center"
          >
            <div className="flex items-center">
              <Icon name={IconName} className="text-xl mr-3 flex-none" />
              <div>
                <div className="text-xs">{text}</div>
                <div className="flex">
                  {record.tags?.map((tag: any) => (
                    <div
                      key={tag.id}
                      className="px-1 pt-1 rounded text-white text-3xs mr-1 whitespace-no-wrap"
                      style={{ backgroundColor: tag.color }}
                    >
                      {tag.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Link>
        )
      },
    },
    {
      title: 'Documento',
      dataIndex: 'serviceName',
      render: (text: string) => (
        <span className="overflow-ellipsis overflow-hidden max-w-xs" style={{ fontSize: 12 }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Responsável',
      dataIndex: 'owner',
    },
    {
      title: 'Tipo',
      dataIndex: 'docType',
      sorter: true,
    },
    {
      title: 'Últ. Modif.',
      dataIndex: 'mostRecentOperationTime',
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (text: string, record: any) => (
        <MyFilesInlineActions
          explorerItem={record}
          setSelectedRowKeys={setSelectedRowKeys}
          setSelectedItems={setSelectedItems}
        />
      ),
    },
  ]

  return (
    <Spin spinning={isFetching}>
      <DndProvider backend={HTML5Backend}>
        <Table
          rowSelection={rowSelection}
          rowKey={(row: any) => `${row.type}-${row.id}`}
          columns={columns}
          dataSource={data}
          onChange={handleTableChange}
          components={components}
          onRow={(_, index) => {
            const attr = {
              index,
              handleDrop,
            }
            return attr as React.HTMLAttributes<any>
          }}
          pagination={false}
          expandable={expandable}
          className="mask-text"
        />
      </DndProvider>

      <Pagination
        totalCount={pagination.totalCount}
        currentPage={pagination.currentPage}
        onChangePage={pageNumber => pagination.setCurrentPage(pageNumber)}
        pageSize={pagination.pageSize}
        onPageSizeChange={pageSize => pagination.setPageSize(pageSize)}
      />
    </Spin>
  )
}

const expandable = { expandIcon: () => null }

const components = {
  body: {
    row: DraggableBodyRow,
  },
}
