import React from 'react'

import { FeedbackMessage } from 'domain/app'
import { useHasPermission } from 'domain/auth'
import { useCurrentCustomer } from 'domain/customers'
import { Icon } from 'ui'
import { formatMoney } from 'utils/formatters'
import { useGetOrderItem } from '../../state/viewOrderItem'

function ViewOrderApproval() {
  const orderItem = useGetOrderItem()
  const { valueForOrderApproval } = useCurrentCustomer()
  const canApproveBudget = useHasPermission('canApprovePurchasesQuotes')
  const { priceQuotationValue } = orderItem.order

  if (!priceQuotationValue || priceQuotationValue < valueForOrderApproval) {
    return null
  }

  return (
    <FeedbackMessage
      status={orderItem.status}
      message={
        canApproveBudget
          ? `
          Este pedido completo foi orçado em ${formatMoney(priceQuotationValue)}, 
          o que ultrapassa o limite de ${formatMoney(valueForOrderApproval)} definido pela empresa,
          e precisa de aprovação.`
          : `Esse pedido foi orçado e excede o limite definido pela empresa. 
          Solicite a aprovação de um usuário com acesso para continuar com o pedido.`
      }
      alertType="error"
      icon={<Icon name="hourglass" className="animate-spin-slow text-5xl mt-5 mb-5 text-secondary-500" />}
    />
  )
}

export default ViewOrderApproval
