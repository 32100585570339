import { useEffect, useReducer, useState } from 'react'
import { Button, Checkbox, Form, Modal } from 'antd'

import { checkOCRSearchError } from 'domain/orders/helpers'
import { Icon, SearchInput } from 'ui'

interface Props {
  text: string
  isOCR: boolean
  setIsOCR: (value: boolean, searchTerm: string) => void
  onSearch: (value: string) => void
  inline?: boolean
  className?: string
}

export default function OrdersSearchInput({ text, isOCR, setIsOCR, onSearch, inline, className }: Props) {
  const [searchInputValue, setSearchInputValue] = useState(text || '')
  const [showHelp, toggleShowHelp] = useReducer(prev => !prev, false)
  const hasError = checkOCRSearchError(isOCR, searchInputValue)

  const handleSearch = (inputValue: string) => {
    if (!hasError) {
      const value = inputValue.trim()
      onSearch(value)
    }
  }

  const containerStyle = inline
    ? 'flex-row flex-wrap items-center mt-4'
    : 'flex-col flex-1 max-h-10 w-fit max-w-lg'

  // Atualiza o input quando o valor for setado através do modal Mais Filtros
  useEffect(() => {
    setSearchInputValue(text)
  }, [text])

  return (
    <>
      <div className={`relative flex ${containerStyle} ${className}`}>
        <div className={`flex items-center ${inline ? 'pb-6' : 'pb-2'}`}>
          <Checkbox
            className="text-2xs items-center"
            checked={isOCR}
            onChange={e => setIsOCR(e.target.checked, searchInputValue)}
          >
            Buscar dentro de documentos
          </Checkbox>

          <Button
            type="link"
            icon={<Icon name="help-circle" size={20} />}
            className="p-0 text-xs h-4"
            onClick={toggleShowHelp}
            size="middle"
          />
        </div>

        <Form.Item
          validateStatus={hasError ? 'error' : undefined}
          help={hasError ? 'Texto muito curto, tente com um texto maior!' : undefined}
        >
          <SearchInput
            className="lg:min-w-96"
            placeholder="Pesquise por nome ou número do pedido"
            onSearch={handleSearch}
            onChange={e => setSearchInputValue(e.target.value)}
            value={searchInputValue}
          />
        </Form.Item>
      </div>

      <Modal
        title="Pesquisar dentro dos documentos"
        open={showHelp}
        onCancel={toggleShowHelp}
        onOk={toggleShowHelp}
      >
        <h2 className="text-base uppercase mt-2 mb-5">Tecnologia de Imagem para Texto</h2>
        <p className="pb-6 mb-6">
          Além de buscar os nomes e números de seus pedidos, a CBRdoc permite que você faça uma pesquisa
          minuciosa dentro de cada página de cada um de seus documentos.
        </p>
      </Modal>
    </>
  )
}
