
import { InvoicePostpaidAPI, PostpaidInvoice } from 'domain/invoices/types'
import { displayDate } from 'utils/dateTime'
import { formatMoney } from 'utils/formatters'

function convertPostpaidInvoice({
  created_at,
  debit_amount, fiscal_amount,
  id,
  order,
}: InvoicePostpaidAPI): PostpaidInvoice {
  return {
    createdAtDisplay: displayDate(created_at),
    debitAmount: debit_amount,
    debitAmountDisplay: formatMoney(debit_amount),
    deliveredAtDisplay: displayDate(order.finished_at),
    fiscalAmountDisplay: formatMoney(fiscal_amount),
    id,
    orderItem: {
      id: order.id,
      name: order.name,
      orderItemNumber: order.backoffice_id,
      user: {
        id: order.user.id,
        name: order.user.name,
      },
    },
  }
}

export default convertPostpaidInvoice
