import {
  NotificationConfig,
  NotificationSettingName,
  NotificationType,
  NotificationTypeConfig,
  SettingsByMedium,
} from './types'

export const NotificationTypesConfig: Record<NotificationType, NotificationTypeConfig> = {
  [NotificationType.ACTION_REQUIRED]: {
    code: NotificationType.ACTION_REQUIRED,
    text: 'Ação Necessária',
    color: 'text-red-500',
    icon: 'flag',
  },
  [NotificationType.CERTIFICATE_EXPIRED]: {
    code: NotificationType.CERTIFICATE_EXPIRED,
    text: 'Certidão Vencida',
    color: 'text-red-400',
    icon: 'calendar',
  },
  [NotificationType.INFORMATION]: {
    code: NotificationType.INFORMATION,
    text: 'Atualizações e informações',
    color: 'text-sky-800',
    icon: 'news',
  },
  [NotificationType.ORDER_FINALIZED]: {
    code: NotificationType.ORDER_FINALIZED,
    text: 'Pedido finalizado',
    color: 'text-emerald-600',
    icon: 'check-all',
  },
  [NotificationType.ORDER_ITEM_FINALIZED]: {
    code: NotificationType.ORDER_ITEM_FINALIZED,
    text: 'Item finalizado',
    color: 'text-green-500',
    icon: 'check',
  },
  [NotificationType.ORDER_ITEM_UPDATED]: {
    code: NotificationType.ORDER_ITEM_FINALIZED,
    text: 'Item atualizado',
    color: 'text-green-500',
    icon: 'check',
  },
  [NotificationType.UNAVAILABILITY_WARNING]: {
    code: NotificationType.UNAVAILABILITY_WARNING,
    text: 'Indisponibilidade',
    color: 'text-amber-500',
    icon: 'warning',
  },
  [NotificationType.SUMMARY_EXTRACTED]: {
    code: NotificationType.SUMMARY_EXTRACTED,
    text: 'Resumo extraído',
    color: 'text-green-500',
    icon: 'check',
  },
}

export const NotificationSettings: Record<NotificationSettingName, NotificationConfig> = {
  bankSlipCreated: {
    label: 'Boleto gerado',
    code: 'bank_slip_notification_via',
    description: 'Receber notificação toda vez que um novo boleto for emitido.',
  },
  placedOrder: {
    label: 'Pedido realizado',
    code: 'placed_purchase_notification_via',
    description: 'Receber notificação toda vez que um novo pedido for solicitado com sucesso.',
  },
  refundedOrder: {
    label: 'Item reembolsado',
    code: 'refunded_order_notification_via',
    description: 'Receber notificação toda vez que um item for reembolsado.',
  },
  pendingAction: {
    label: 'Ação necessária',
    code: 'pending_action_notification_via',
    description: 'Receber notificação toda vez que um item necessitar a ação do usuário para prosseguimento.',
  },
  finishedOrder: {
    label: 'Pedido finalizado',
    code: 'finished_order_notification_via',
    description:
      'Receber notificação toda vez que uma compra inteira (todos os itens do pedido) for finalizada.',
  },
  finishedOrderItem: {
    label: 'Item finalizado',
    code: 'finished_purchase_notification_via',
    description: 'Receber notificação toda vez que qualquer item do pedido for finalizado.',
  },
  spreadsheetPlacedOrder: {
    label: 'Pedido por planilha realizado',
    code: 'spreadsheet_placed_purchase_notification_via',
    description: 'Receber notificação toda vez que um novo pedido via planilha for solicitado com sucesso.',
  },
  certificateExpired: {
    label: 'Certidão vencida',
    code: 'certificate_expired_notification_via',
    description: 'Aviso sobre a validade dos documentos.',
  },
  systemInformation: {
    label: 'Atualizações e informações',
    code: 'system_information_notification_via',
    description: 'Avisos sobre novidades no produto.',
  },
  systemUnavailable: {
    label: 'Sistema indisponível',
    code: 'system_unavailable_notification_via',
    description: 'Avisos sobre indisponibilidade no sistema.',
  },
  extractedSummary: {
    label: 'Ficha do documento',
    code: 'summary_extracted_notification_via',
    description: 'Receber uma notificação quando as informações da ficha do documento estiverem disponíveis.',
  },
}

export const NotificationsSettingsByMedium: SettingsByMedium[] = [
  {
    medium: 'mail',
    label: 'Notificação por e-mail',
    settings: [
      'bankSlipCreated',
      'placedOrder',
      'refundedOrder',
      'pendingAction',
      'finishedOrder',
      'finishedOrderItem',
      'spreadsheetPlacedOrder',
    ],
  },
  {
    medium: 'database',
    label: 'Notificação na plataforma',
    settings: [
      'pendingAction',
      'certificateExpired',
      'systemInformation',
      'finishedOrder',
      'finishedOrderItem',
      'systemUnavailable',
      'extractedSummary',
    ],
  },
]
