import { useMemo } from 'react'
import { RadioGroupProps, Spin } from 'antd'

import { getFieldName } from 'domain/createOrder/shared'
import { SelectedService } from 'domain/createOrder/types'
import { useExtraInformationQuery } from 'domain/orderItem/shared'
import { RadioGroupField, useSelectDefaultValue } from 'domain/servicesCBRdoc/fields'
import { getLocationUrl } from 'domain/servicesCBRdoc/helpers/location'
import { SelectedLocation } from 'domain/servicesCBRdoc/types'

import config from '../certidaoImovelConfig'
import { TipoCertidaoImovel } from '../types'

interface Props extends Omit<RadioGroupProps, 'onChange'> {
  service: SelectedService
  location: SelectedLocation
  itemId?: number
  onChange?: (value: string) => void
  autoFocus?: boolean
}

export default function CertidaoImovelType({
  service,
  location,
  itemId,
  onChange,
  ...props
}: Props) {
  const locationUrls = getLocationUrl(location)

  const { data, isFetching } = useExtraInformationQuery<TipoCertidaoImovel[]>({
    service,
    information: 'tipos',
    params: locationUrls,
    itemId,
  })

  const typeFieldName = getFieldName(tipo.name, itemId)

  const options = useMemo(() => {
    if (!data) return []

    if (locationUrls.url_uf === 'PR') {
      return (data).map(option => {
        if (option.url === 'onus') {
          option.name = 'Certidão de Ônus'
        }

        return option
      })
    }

    return data
  }, [location.url_uf, data])

  useSelectDefaultValue({
    field: tipo,
    name: typeFieldName,
    onChange,
    availableOptions: options,
  })

  if (!location.url_cartorio) return null

  return (
    <Spin spinning={isFetching}>
      <RadioGroupField
        field={tipo}
        name={typeFieldName}
        itemId={itemId}
        options={options}
        onChange={onChange}
        {...props}
      />
    </Spin>
  )
}

const { tipo } = config.formFields
