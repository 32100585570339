import React, { useState } from 'react'
import { Button, Collapse } from 'antd'

const { Panel } = Collapse

interface Props {
  onOpenSimulator: React.DispatchWithoutAction
}

function CreditsFaq({ onOpenSimulator }: Props) {
  const [activePanelKey, setActivePanelKey] = useState<string | string[]>('')

  return (
    <div className="w-full">
      <Collapse
        bordered={false}
        activeKey={activePanelKey}
        onChange={key => setActivePanelKey(key)}
        accordion
        className="custom-collapse w-full"
      >
        {faq({ onOpenSimulator }).map((f, i) => (
          <Panel header={f.question} key={f.id || i}>
            <div className="px-6">{f.answer}</div>
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}

export const faqBonus: any = {
  id: 'bonus',
  question: 'Como funciona o bônus?',
  answer: (
    <p>
      A plataforma funciona com recarga de crédito, que pode ser feita via cartão de crédito ou boleto.
      Dependendo do valor creditado, você ganha um bônus.{' '}
      <b>Por exemplo, se você colocar R$ 1.000,00 de crédito, irá receber R$ 1.045,00</b>. E seus créditos e
      bônus nunca expiram!
    </p>
  ),
}

const faq = ({ onOpenSimulator }: any) => [
  {
    question: 'Como funciona o sistema de créditos?',
    answer: (
      <p>
        A plataforma funciona com a recarga de créditos. Isto evita o preenchimento dos dados do cartão a cada
        compra. Você também ganha um bônus em créditos de acordo como o valor recarregado.
      </p>
    ),
  },
  {
    question: 'Só preciso de um documento, terei que pagar mais que o valor dele?',
    answer: (
      <p>
        <b>Não, você irá pagar apenas o que consumir.</b> Você escolhe entre adicionar créditos (e
        possivelmente ganhar bônus) ou solicitar apenas o documento.
      </p>
    ),
  },
  faqBonus,
  {
    question: 'Qual é a validade dos créditos?',
    answer: (
      <p>
        <b>Não possui validade</b>, você insere seus créditos, ganha bônus e solicita os serviços que precisa.
      </p>
    ),
  },
  {
    question: 'Quanto devo recarregar?',
    answer: (
      <p>
        Você deve recarregar <b>de acordo com a sua demanda no mês</b>, consulte nossa{' '}
        <Button type="link" onClick={onOpenSimulator}>
          página de créditos
        </Button>{' '}
        para simular o valor e bônus ideal para você!
      </p>
    ),
  },
  {
    question: 'O que acontece se meu documento não for localizado ou rejeitado?',
    answer: (
      <p>
        O status do seu pedido muda e você pode escolher entre informar novos dados para uma nova busca ou
        receber o reembolso em créditos na plataforma, descontando a taxa pelo serviço de pesquisa.
      </p>
    ),
  },
]

export default CreditsFaq
