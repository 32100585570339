/* eslint-disable indent */
import { Select as AntSelect } from 'antd'
import { SelectProps as AntdSelectProps } from 'antd/es/select'

import { normalizeString } from 'utils/formatters'

export interface SelectProps extends Omit<AntdSelectProps<any>, 'options'> {
  optionValueKey?: string
  optionLabelKey?: string
  options?: Record<string, any>[]
}

export default function Select({
  filterOption = filterOptionString,
  placeholder = 'Selecione uma opção',
  optionValueKey = 'value',
  optionLabelKey = 'label',
  className = 'w-full',
  allowClear = true,
  showSearch = true,
  options,
  optionFilterProp = 'label',
  ...props
}: SelectProps) {
  const selectOptions = convertOptions(options, optionValueKey, optionLabelKey)
  const hasDescription = !!selectOptions[0]?.description

  return (
    <AntSelect
      placeholder={placeholder}
      optionFilterProp={optionFilterProp}
      // @ts-ignore
      autoComplete="off"
      filterOption={filterOption}
      className={className + ' group'}
      allowClear={allowClear}
      showSearch={showSearch}
      options={selectOptions}
      data-hj-allow
      optionRender={
        hasDescription
          ? ({ data, label }) => (
              <>
                <span className="leading-5 font-bold text-secondary-500">{label}</span>
                <span className="text-xs text-gray-500 whitespace-normal mt-2">
                  <br />
                  {data.description}
                </span>
              </>
            )
          : undefined
      }
      {...props}
    />
  )
}

const convertOptions = (options: any[] | undefined, optionValue: string, optionLabel: string) => {
  if (!options) return []

  if (optionValue === 'value' && optionLabel === 'label') {
    return options
  }

  return options.map(option => {
    const convertedOption = {
      value: option[optionValue],
      label: option[optionLabel],
      description: option.description,
    }

    return convertedOption
  })
}

const filterOptionString = (input: string, option: any) => {
  return normalizeString(option.label).includes(normalizeString(input))
}

Select.Option = AntSelect.Option
