import { useCallback } from 'react'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

import { Group } from 'domain/groups/types'
import { displayDateSmallYear } from 'utils/dateTime'
import { CheckoutData, CheckoutStep, ChosenPaymentMethod, ShippingAddress } from '../types'

export const DEFAULT_ORDER_NAME = 'Pedido ' + displayDateSmallYear(new Date())

const _stepAtom = atom<CheckoutStep>('idle')
const _chosenPaymentMethod = atom<ChosenPaymentMethod | null>(null)
const shippingAddressAtom = atom<ShippingAddress | null>(null)
const orderNameAtom = atom<string>(DEFAULT_ORDER_NAME)
const orderDetailsConfirmedAtom = atom(false)
const groupsAtom = atom<Group[]>([])

const chosenPaymentMethod = atom(
  get => get(_chosenPaymentMethod),
  (_, set, value: ChosenPaymentMethod | null) => {
    set(_chosenPaymentMethod, value)
    set(_stepAtom, value === null ? 'idle' : 'in-progress')
  }
)

const stepAtom = atom(get => get(_stepAtom), (_, set, value: CheckoutStep) => {
  set(_stepAtom, value)

  if (value === 'idle') {
    set(_chosenPaymentMethod, null)
  }
})

export const useClearShippingAddress = () => {
  const setStep = useSetAtom(stepAtom)
  const setShippingAddress = useSetAtom(shippingAddressAtom)

  return () => {
    setStep('idle')
    setShippingAddress(null)
  }
}

export const useResetCheckout = () => {
  const setDetailsConfirmed = useSetAtom(orderDetailsConfirmedAtom)
  const setOrderName = useSetAtom(orderNameAtom)
  const setStep = useSetAtom(stepAtom)
  const setShippingAddress = useSetAtom(shippingAddressAtom)
  const setGroups = useSetAtom(groupsAtom)

  return useCallback(() => {
    setStep('idle')
    setOrderName(DEFAULT_ORDER_NAME)
    setDetailsConfirmed(false)
    setShippingAddress(null)
    setGroups([])
  }, [])
}

export const useGetCheckoutData = (): CheckoutData => {
  const orderDetailsConfirmed = useAtomValue(orderDetailsConfirmedAtom)
  const orderName = useAtomValue(orderNameAtom)
  const shippingAddress = useAtomValue(shippingAddressAtom)
  const groups = useAtomValue(groupsAtom)
  const paymentMethod = useAtomValue(_chosenPaymentMethod)

  return {
    groups,
    orderDetailsConfirmed,
    orderName,
    paymentMethod,
    shippingAddress,
  }
}

export const useGetCheckoutLoading = () => {
  const step = useAtomValue(stepAtom)
  return step === 'in-progress' || step === 'all-good'
}

export const useCheckoutStep = () => useAtom(stepAtom)
export const useChosenPaymentMethod = () => useAtom(chosenPaymentMethod)
export const useGetCheckoutStep = () => useAtomValue(stepAtom)
export const useGetChosenPaymentMethod = () => useAtomValue(chosenPaymentMethod)
export const useGetGroups = () => useAtomValue(groupsAtom)
export const useGetOrderDetailsConfirmed = () => useAtomValue(orderDetailsConfirmedAtom)
export const useGetOrderName = () => useAtomValue(orderNameAtom)
export const useGetShippingAddress = () => useAtomValue(shippingAddressAtom)
export const useGroups = () => useAtom(groupsAtom)
export const useSetCheckoutStep = () => useSetAtom(stepAtom)
export const useSetOrderDetailsConfirmed = () => useSetAtom(orderDetailsConfirmedAtom)
export const useSetOrderName = () => useSetAtom(orderNameAtom)
export const useSetPaymentMethod = () => useSetAtom(chosenPaymentMethod)
export const useSetShippingAddress = () => useSetAtom(shippingAddressAtom)
