import { getFieldName } from 'domain/createOrder/shared'
import { FederativeUnitSelect } from 'ui'
import { SelectFieldProps } from './SelectField'

interface Props
  extends Omit<SelectFieldProps, 'multiple' | 'onChange' | 'options' | 'optionLabelKey' | 'optionValueKey'> {
  onChange?: (selected: string) => void
}

export default function FederativeUnitsField({
  field,
  itemId,
  onChange,
  ...props
}: Props) {
  return (
    <FederativeUnitSelect
      name={getFieldName(field.name, itemId)}
      label={field.label}
      onChange={selected => onChange && onChange(selected.value)}
      {...props}
    />
  )
}
