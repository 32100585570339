import { useHistory } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { UploadFormData } from 'domain/app/types'
import { UploadSection, useCreateOrderState } from 'domain/createOrder/document'
import { useAddToCart } from 'domain/createOrder/shared/services' 
import { CreateOrderState } from 'domain/createOrder/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'

export default function AIOrderUploadStep() {
  const history = useHistory()
  const createOrderState = useCreateOrderState()
  const addToCart = useAddToCart()

  const onFinish = ({ files }: UploadFormData) => {
    files.forEach(file => {
      const order: CreateOrderState = {
        ...createOrderState,
        formData: {
          ...createOrderState.formData,
          [UPLOAD]: file.fileUploadId,
          [ARQUIVO_NOME_ORIGINAL]: file.name,
          [NOME_ITEM_PEDIDO]: file.name,
        },
      }

      addToCart(order)
    })

    history.push(RoutePaths.SHOPPING_CART)
  }

  return <UploadSection name="files" buttonLabel="Finalizar pedido" onFinish={onFinish} isFinalStep />
}

const { ARQUIVO_NOME_ORIGINAL, NOME_ITEM_PEDIDO, UPLOAD } = FieldName
