import { useMutation } from 'react-query'

import { errorHandler } from 'api/errors'
import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'

import { EditRecurrenceForm, RecurrenceAPI } from '../types'
import { convertEditRecurrenceToPostAPI } from './convertRecurrence'

type Params = {
  onSuccess: () => void
}

const useEditRecurrence = (recurrenceId: number, { onSuccess }: Params) => {
  const queryKey = ['recurrence', recurrenceId]

  return useMutation(
    async (formData: EditRecurrenceForm) => {
      const response = await AuthorizedAPI.put<RecurrenceAPI>(
        `/recurrences/${recurrenceId}`,
        convertEditRecurrenceToPostAPI(formData)
      )

      return response.data
    },
    {
      onMutate: () => queryClient.getQueryData(queryKey),
      onError: (data, variables, previousData: any) => {
        queryClient.setQueryData(queryKey, previousData)
        errorHandler(data, { code: '0x0013' })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['recurrences'])
        onSuccess()
      },
    }
  )
}

export default useEditRecurrence
