import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { OAuthRedirect } from 'domain/auth'
import { ForgotPassword, LoginPage, NewPassword, RegisterCnpj, RegisterCnpj2 } from 'pages/guest'
import SharedOrder from 'pages/share/SharedOrder'
import PrivateRoutes from './PrivateRoutes'
import RoutePaths from './RoutePaths'

export default function MainRouter() {
  return (
    <Router>
      <Switch>
        <Route path={RoutePaths.LOGIN} component={LoginPage} />
        <Route path={RoutePaths.REGISTER_CNPJ} exact component={RegisterCnpj} />
        <Route path={RoutePaths.REGISTER_CNPJ_2} component={RegisterCnpj2} />
        <Route path={RoutePaths.SHARED_ORDER} component={SharedOrder} />
        <Route path={RoutePaths.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={RoutePaths.NEW_PASSWORD} component={NewPassword} />
        <Route path={RoutePaths.OAUTH} component={OAuthRedirect} />

        <Route path="*">
          <PrivateRoutes />
        </Route>
      </Switch>
    </Router>
  )
}
