import { KitItem } from 'domain/kits/types'
import { useGetServiceForm } from 'domain/createOrder/kit/state'
import { getDisplayLocation } from 'domain/orders/helpers'
import config from '../certidaoJuntaComercialConfig'
import ActNumberInput from './CertidaoJuntaComercialActNumberInput'

interface Props {
  kitItem: KitItem
}

export default function KitOrderCertidaoJuntaComercial({ kitItem }: Props) {
  const serviceForm = useGetServiceForm(kitItem.id)
  const { tipo: type } = serviceForm
  const location = getDisplayLocation(serviceForm)

  return (
    <div className="mt-8">
      <h3 className="font-bold text-lg mb-1">{kitItem.service.name}</h3>

      {!!location && <p className="text-gray-600 text-sm mb-4">{location}</p>}

      <ActNumberInput type={type as any} name={[kitItem.id, numero_ato.name]} required />
    </div>
  )
}

const { numero_ato } = config.formFields
