import React from 'react'

import { useAllServices } from 'domain/servicesCBRdoc'
import { SelectMultiple } from 'ui'

function DocumentServicesSelect() {
  const services = useAllServices()

  return (
    <SelectMultiple
      name="servicesIds"
      label="Item"
      placeholder="Selecione o item"
      size="middle"
      options={services}
      optionLabelKey="name"
      optionValueKey="id"
    />
  )
}

export default DocumentServicesSelect
