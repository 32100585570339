import { NameField } from 'domain/servicesCBRdoc/fields'
import { ResearchFlow } from 'domain/createOrder/researches'
import { CPFInput } from 'ui'

import config from '../pesquisaComplianceConfig'

export default function CreateOrderPesquisaCompliance() {
  return <ResearchFlow renderFormItem={(itemId: number) => <PesquisaComplianceForm itemId={itemId} />} />
}

function PesquisaComplianceForm({ itemId }: { itemId: number }) {
  return (
    <>
      <NameField itemId={itemId} field={nome_pf} autoFocus={itemId === 0} />
      <CPFInput itemId={itemId} />
    </>
  )
}

const { nome_pf } = config.formFields
