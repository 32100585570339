import Excel from 'exceljs'

import { PostpaidInvoice } from 'domain/invoices/types'

async function generatePostpaidExcel(invoices: PostpaidInvoice[]) {
  const workbook = new Excel.Workbook()

  const sheet = workbook.addWorksheet('faturas-cbrdoc')

  sheet.columns = [{ width: 35 }, { width: 15 }, { width: 18 }, { width: 18 }, { width: 18 }, { width: 20 }]

  //add headers
  const rowHeader = sheet.addRow([
    'Nome do pedido',
    'Nº do pedido',
    'Data do pedido',
    'Nota fiscal',
    'Nota de débito',
    'Data de envio',
    'Usuário',
  ])

  rowHeader.font = { bold: true }

  invoices.forEach(invoice => {
    sheet.addRow([
      invoice.orderItem.name,
      invoice.orderItem.orderItemNumber,
      invoice.createdAtDisplay,
      invoice.fiscalAmountDisplay,
      invoice.debitAmountDisplay,
      invoice.deliveredAtDisplay,
      invoice.orderItem.user.name,
    ])
  })

  const buf = await workbook.xlsx.writeBuffer()
  return buf as Buffer
}

export default generatePostpaidExcel
