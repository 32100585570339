import { atom, useAtomValue, useSetAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'

import { FieldConfig } from 'domain/servicesCBRdoc/fields/types'
import { CertificateCode, ResearchCode, ServiceCode } from 'domain/servicesCBRdoc/types'
import { fieldNames as addressFieldNames } from 'domain/servicesCBRdoc/fields/components/AddressFields'
import {
  ColumnIdentification,
  FieldIdentificationOption,
  FieldIdentificationSelectedOption,
  KitFieldConfig,
  NecessaryField,
} from '../types'
import { detailsFieldsConfigAtom } from './detailsFieldsConfig'
import { kitAtom } from './kit'
import { Fields } from 'domain/servicesCBRdoc'

const SPREADSHEET_AVAILABLE: ServiceCode[] = [
  CertificateCode.CERTIDAO_AMBIENTAL,
  CertificateCode.CERTIDAO_AMBIENTAL_MUNICIPAL,
  CertificateCode.CERTIDAO_ANTECEDENTES_CRIMINAIS,
  // CertificateCode.CERTIDAO_BAIXA_PROTESTO, --> tem upload
  CertificateCode.CERTIDAO_CAFIR,
  CertificateCode.CERTIDAO_CAPA_IPTU,
  CertificateCode.CERTIDAO_CASAMENTO,
  CertificateCode.CERTIDAO_CCIR,
  CertificateCode.CERTIDAO_CEAT,
  CertificateCode.CERTIDAO_CNDT,
  CertificateCode.CERTIDAO_CNDTNIDA,
  CertificateCode.CERTIDAO_SITUACAO_PJ,
  CertificateCode.CERTIDAO_CUMPRIMENTO_PCDS,
  CertificateCode.CERTIDAO_DADOS_CADASTRAIS_IMOVEL,
  CertificateCode.CERTIDAO_DEBITOS_ESTADUAIS,
  CertificateCode.CERTIDAO_DEBITOS_MT,
  CertificateCode.CERTIDAO_DEBITOS_MUNICIPAIS,
  CertificateCode.CERTIDAO_EMPRESA_CARTORIO_PJ,
  CertificateCode.CERTIDAO_ENTIDADES_SUPERFISIONADAS,
  CertificateCode.CERTIDAO_ESCRITURA,
  CertificateCode.CERTIDAO_EXTRATO_DEBITOS_MUNICIPAIS,
  CertificateCode.CERTIDAO_IBAMA_DEBITOS,
  CertificateCode.CERTIDAO_IBAMA_EMBARGOS,
  CertificateCode.CERTIDAO_IMOVEL,
  CertificateCode.CERTIDAO_IMPROBILIDADE_ADMINISTRATIVA,
  // CertificateCode.CERTIDAO_INTERDICAO, --> tem algumas cidades com PJ
  CertificateCode.CERTIDAO_IPTU,
  CertificateCode.CERTIDAO_ITR,
  // CertificateCode.CERTIDAO_JUNTA_COMERCIAL, --> número do ato
  // CertificateCode.CERTIDAO_JUSTICA_ESTADUAL, --> campos dinâmicos
  CertificateCode.CERTIDAO_MPE_CIVIL,
  CertificateCode.CERTIDAO_MPE_CRIMINAL,
  CertificateCode.CERTIDAO_MT_TRABALHISTAS,
  CertificateCode.CERTIDAO_NASCIMENTO,
  CertificateCode.CERTIDAO_NEGATIVA_CONTAS_TCU,
  CertificateCode.CERTIDAO_NEGATIVA_CORRECIONAL,
  CertificateCode.CERTIDAO_NEGATIVA_CORRECIONAL_CGU,
  // CertificateCode.CERTIDAO_NEGATIVA_DEBITOS_TRIBUTARIOS_PGE, --> inscrição est obrigatória p alguns estados
  CertificateCode.CERTIDAO_NEGATIVA_FEITOS,
  CertificateCode.CERTIDAO_NEGATIVA_FGTS,
  CertificateCode.CERTIDAO_NEGATIVA_MPF,
  // CertificateCode.CERTIDAO_NEGATIVA_STJ, --> campos dinâmicos
  CertificateCode.CERTIDAO_NEGATIVA_CONTAS_TCU,
  CertificateCode.CERTIDAO_OBITO,
  CertificateCode.CERTIDAO_PENHOR_SAFRA,
  CertificateCode.CERTIDAO_PREVIA_MATRICULA,
  CertificateCode.CERTIDAO_PROCURACAO,
  CertificateCode.CERTIDAO_PRONAF_APTIDAO,
  CertificateCode.CERTIDAO_PROPRIEDADE_AERONAVE,
  // CertificateCode.CERTIDAO_PROTESTO, --> campos dinâmicos
  // CertificateCode.CERTIDAO_QUITACAO_ELEITORAL,  --> campos dinâmicos
  CertificateCode.CERTIDAO_RAIS,
  CertificateCode.CERTIDAO_SPU,
  CertificateCode.CERTIDAO_DISTRIBUIDOR,
  CertificateCode.CERTIDAO_STM_CRIMINAIS,
  CertificateCode.CERTIDAO_TRF_DISTRIBUICAO,
  CertificateCode.CERTIDAO_TRIBUNAL_CONTAS,
  CertificateCode.CERTIDAO_VALOR_VENAL,
  // ResearchCode.PESQUISA_BENS, --> campos dinâmicos
  ResearchCode.PESQUISA_CAR,
  ResearchCode.PESQUISA_COMPLIANCE,
  ResearchCode.PESQUISA_DADOS_CADASTRAIS,
  // ResearchCode.PESQUISA_DIVIDA_ATIVA_PGE,  --> campos dinâmicos
  ResearchCode.PESQUISA_DIVORCIO,
  ResearchCode.PESQUISA_EMPRESA_CARTORIOS_PJ,
  ResearchCode.PESQUISA_ESCRITURA,
  ResearchCode.PESQUISA_IMOVEL_RURAL,
  ResearchCode.PESQUISA_INVENTARIO,
  ResearchCode.PESQUISA_JUNTA_COMERCIAL,
  ResearchCode.PESQUISA_OBITO,
  ResearchCode.PESQUISA_PARTICIPACAO_SOCIETARIA,
  ResearchCode.PESQUISA_PROCESSOS_JUDICIAIS,
  ResearchCode.PESQUISA_PROCURACAO,
  ResearchCode.PESQUISA_PROPRIEDADE_AERONAVE,
  ResearchCode.PESQUISA_PROPRIEDADE_VEICULOS,
  ResearchCode.PESQUISA_PROTESTO,
  ResearchCode.PESQUISA_RECEITA_FEDERAL,
  ResearchCode.PESQUISA_SINTEGRA,
  ResearchCode.PESQUISA_VEICULAR,
]

const unavailableKitItemsAtom = atom(get => {
  const kit = get(kitAtom)

  return kit.items.filter(item => !SPREADSHEET_AVAILABLE.includes(item.service.code))
})

export const useGetUnavailableKitItems = () => useAtomValue(unavailableKitItemsAtom)

const spreadsheetDetailsFieldsConfigAtom = atom(get => {
  const detailsFields = get(detailsFieldsConfigAtom)

  const splitAddressFieldIntoSpecificColumns = (fields: KitFieldConfig[]) => {
    const addressFieldIndex = fields.findIndex(f => f.name === 'endereco')

    if (addressFieldIndex !== -1) {
      const serviceFields = [...fields]
      serviceFields.splice(addressFieldIndex, 1)

      Object.values(addressFieldNames).forEach(fieldName => {
        const fieldConfigIndex = serviceFields.findIndex(f => f.name === fieldName)

        if (fieldConfigIndex !== -1) {
          serviceFields[fieldConfigIndex].optional = false
        } else {
          serviceFields.push(Fields[fieldName])
        }
      })
      
      return serviceFields
    }

    return fields
  }

  const transformDontKnowBookPageIntoOpcionalBookAndPageFields = (
    fields: KitFieldConfig[],
  ) => {
    const dontKnowFieldIndex = fields.findIndex(f => f.name === 'nao_sei_livro_pagina')

    if (dontKnowFieldIndex !== -1) {
      const serviceFields = [...fields]
      serviceFields.splice(dontKnowFieldIndex, 1)
      serviceFields.push({ ...Fields.livro, optional: true })
      serviceFields.push({ ...Fields.pagina, optional: true })
      return serviceFields
    }

    return fields
  }

  detailsFields.serviceFields.forEach(({ fields }, index) => {
    const fieldsWithAddressSplitted = splitAddressFieldIntoSpecificColumns(fields)
    const allFieldsTransformed =
      transformDontKnowBookPageIntoOpcionalBookAndPageFields(fieldsWithAddressSplitted)

    detailsFields.serviceFields[index].fields = allFieldsTransformed
  })

  return detailsFields
})

spreadsheetDetailsFieldsConfigAtom.debugLabel = 'spreadsheetDetailsFieldsConfigAtom'
const necessaryFieldsAtom = atom(get => {
  const fields = get(spreadsheetDetailsFieldsConfigAtom)

  const necessaryFields: NecessaryField[] = Object.values(fields.registreeFields).map(
    (fieldConfig: FieldConfig) => ({
      label: fieldConfig.label,
      value: fieldConfig.name,
      optional: fieldConfig.optional === true
    })
  )

  fields.serviceFields.forEach(({ kitItem, fields }) => {
    fields.forEach(field => {
      const fieldOption: NecessaryField = {
        id: kitItem.id,
        label: field.label,
        value: `${kitItem.id}_${field.name}`,
        optional: field.optional === true
      }

      necessaryFields.push(fieldOption)
    })
  })

  return necessaryFields
})
necessaryFieldsAtom.debugLabel = 'necessaryFieldsAtom'

export const useGetNecessaryFields = () => useAtomValue(necessaryFieldsAtom)

const columnsIdentificationAtom = atom<ColumnIdentification>({})

export const useGetColumnsIdentification = () => useAtomValue(columnsIdentificationAtom)
export const useSetColumnsIdentification = () => useSetAtom(columnsIdentificationAtom)

const missingFieldsLabelsAtom = atom(get => {
  const necessaryFields = get(necessaryFieldsAtom)
  const columnsFields = get(columnsIdentificationAtom)
  const missingFields = necessaryFields.filter(
    field => columnsFields[field.value!] === undefined && field.optional !== true
  )

  if (missingFields.length === 0) {
    return null
  }

  return missingFields.map(field => field.label).join(', ')
})

export const useGetMissingFieldsLabels = () => useAtomValue(missingFieldsLabelsAtom)

const missingFieldsAtom = atomFamily((currentSelected: FieldIdentificationSelectedOption | undefined) =>
  atom(get => {
    const columnsFields = get(columnsIdentificationAtom)
    const necessaryFields = get(necessaryFieldsAtom)

    const missingFields = necessaryFields.filter(field => {
      const fieldName = field.value!
      return columnsFields[fieldName] === undefined
    })

    const kit = get(kitAtom)

    if (currentSelected) {
      missingFields.push(currentSelected as unknown as NecessaryField)
    }

    const registreeFields = missingFields.filter(field => !field.id)
    const options: FieldIdentificationOption[] = registreeFields

    kit.items.forEach(kitItem => {
      const kitItemMissingFields = missingFields.filter(field => field.id === kitItem.id)

      if (kitItemMissingFields.length > 0) {
        const label = (kitItem.service.shortName ?? kitItem.service.name) + ` (#${kitItem.id})`

        options.push({
          label,
          title: label,
          options: kitItemMissingFields.map(({ label, value }) => ({
            label,
            value,
          })),
        })
      }
    })

    return options
  })
)

export const useGetMissingFields = (currentSelected: FieldIdentificationSelectedOption | undefined) =>
  useAtomValue(missingFieldsAtom(currentSelected))
