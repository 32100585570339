import { useState } from 'react'
import { AxiosResponse } from 'axios'

import { handleRequestError } from 'api/HandleRequestError'
import { AuthorizedAPI } from 'api/network/v2'

function useRequestRefund(orderItemId: number, { onSuccess }: { onSuccess: () => void}) {
  const [loading, setLoading] = useState(false)

  const requestRefund = async () => {
    try {
      setLoading(true)

      await requestRefundApi(orderItemId)
      
      onSuccess()
    } catch (err) {
      handleRequestError(err, { params: { orderItemId }, code: '0x933' })
    } finally {
      setLoading(false)
    }
  }

  return { requestRefund, loading }
}

const requestRefundApi = async (orderId: string | number) => {
  const response: AxiosResponse<any> = await AuthorizedAPI.post(`/orders/${orderId}/refund`)
  return response.data
}

export default useRequestRefund
