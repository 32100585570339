import { CreateOrderLegalEntityTabs } from 'domain/createOrder/document'
import { ResearchFlow } from 'domain/createOrder/researches'
import { CPFInput } from 'ui'

import config from '../pesquisaReceitaFederalConfig'
import { CNPJAndNameFields, DateField, NameField } from 'domain/servicesCBRdoc/fields'

export default function CreateOrderPesquisaReceitaFederal() {
  return (
    <ResearchFlow
      header={<p className="mb-5 text-gray-600">Informe o CPF ou CNPJ que deseja pesquisar.</p>}
      renderFormItem={itemId => <PesquisaReceitaFederalForm itemId={itemId} />}
    />
  )
}

function PesquisaReceitaFederalForm({ itemId }: { itemId: number }) {
  return (
    <CreateOrderLegalEntityTabs
      itemId={itemId}
      fisica={
        <>
          <CPFInput itemId={itemId} autoFocus={itemId === 0} />
          <NameField itemId={itemId} field={nome_pf} />
          <DateField itemId={itemId} field={nascimento} />
        </>
      }
      juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} autoFocus={itemId === 0} />}
    />
  )
}

const { nascimento, nome_pf, nome_pj } = config.formFields
