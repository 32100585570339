import React from 'react'
import { Form } from 'antd'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

import { RoutePaths } from 'app/routes'
import { useResetPassword } from 'domain/auth'
import { GuestLayout } from 'layouts'
import { Alert, EmailInput, ScrollableErrorAlert } from 'ui'

export default function ForgotPassword() {
  const [form] = Form.useForm()
  const { resetPassword, error, status } = useResetPassword(form)
  const isLoading = status === 'loading'

  return (
    <GuestLayout backgroundLogo showMessage>
      <h1 className="text-3xl font-light text-gray-700">Esqueci minha senha</h1>

      <p className="font-normal text-xs text-secondary-500 my-5">
        Por favor, confirme o e-mail cadastrado para redefinir sua senha.
      </p>

      {error && <ScrollableErrorAlert error={error} />}

      {status === 'success' && (
        <Alert
          type="success"
          message="Solitação enviada com sucesso"
          description="Siga as instruções no email"
        />
      )}

      <Form form={form} layout="vertical" onFinish={resetPassword} requiredMark={false} scrollToFirstError>
        <EmailInput label="Email cadastrado" required />

        <Button disabled={isLoading} loading={isLoading} type="primary" htmlType="submit" block>
          Enviar
        </Button>
      </Form>

      <p className="mt-10 text-center text-sm">
        <Link className="underline" to={RoutePaths.LOGIN}>
          Voltar para login
        </Link>
      </p>
    </GuestLayout>
  )
}
