import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { TIPO_PESSOA } = FieldName

type CertidaoEmbargosIbamaFields = Record<FieldName.CNPJ | FieldName.CPF, FieldConfig>

class CertidaoEmbargosIbama extends Certificate {
  formFields: CertidaoEmbargosIbamaFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
  }

  constructor() {
    super({
      id: 49,
      priceByFields: [TIPO_PESSOA],
      shortName: 'Ibama - Embargos',
      steps: ['dados'],
    })
  }
}

const service = new CertidaoEmbargosIbama().initialize()

export default service
