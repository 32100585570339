import React from 'react'

import { GroupsAssociation } from 'domain/groups'
import { useExplorerItem } from 'domain/myFiles/state'
import { useAssociateExplorerGroups } from 'domain/myFiles/services'
import { removeFromArrayById } from 'utils/transformData'

export default function ExplorerGroupsAssociation() {
  const [explorerItem, setExplorerItem] = useExplorerItem()
  const { associateGroups, loading } = useAssociateExplorerGroups(explorerItem!.id)

  const onDeleteGroup = (groupId: number) => {
    setExplorerItem({ ...explorerItem!, groups: removeFromArrayById(explorerItem!.groups!, groupId) })
  }

  return (
    <GroupsAssociation
      groups={explorerItem?.groups ?? []}
      onFinish={associateGroups}
      onDeleteGroup={onDeleteGroup}
      loading={loading}
    />
  )
}
