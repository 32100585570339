import { useParams } from 'react-router-dom'
import { Card } from 'antd'

import { EditRecurrence, RecurrenceItems, useViewRecurrenceQuery } from 'domain/recurrences'
import { BasicLayout, GoBackButton } from 'layouts'
import { AlertFullPage, Loading } from 'ui'
import { useHasPermission } from 'domain/auth'

export default function EditRecurrencePage() {
  const params = useParams<{ id: string }>()
  const canEditRecurrence = useHasPermission('canManageUsersAndCompanyData')

  const { data: recurrence, isLoading, isError } = useViewRecurrenceQuery(Number(params!.id))

  if (isLoading) return <Loading />
  if (!canEditRecurrence || isError || !recurrence)
    return (
      <AlertFullPage title="Não é possível editar a recorrência">
        {!canEditRecurrence &&
          'Você não tem permissão para realizar essa ação. '}
        {!recurrence && canEditRecurrence && 'Recorrência não enontrada'}
      </AlertFullPage>
    )

  return (
    <BasicLayout
      pageTitle={`Editar recorrência ${recurrence.name}`}
      header={<GoBackButton className="-ml-3" />}
    >
      <div className="flex flex-1 flex-wrap flex-row gap-4 mx-7 mt-4">
        <Card title="Configurações" className="mb-3 w-full md:max-w-lg">
          <EditRecurrence recurrence={recurrence} />
        </Card>

        <Card title="Itens da Recorrência" className="mb-3 w-full md:max-w-lg">
          <RecurrenceItems recurrence={recurrence} />
        </Card>
      </div>
    </BasicLayout>
  )
}
