import React, { createContext, useContext, useEffect, useState } from 'react'

import { useCurrentUser } from 'domain/auth'
import { CreateKitForm, KitItem, KitItemDefaultData } from 'domain/kits/types'
import { ServiceCode } from 'domain/servicesCBRdoc/types'
import { getStorageData, removeStorageItem, setStorageData } from 'utils/localStorage'

type CreateKitContext = {
  kit: CreateKitForm
  setKit: React.Dispatch<React.SetStateAction<CreateKitForm>>
}

const CreateKitContext = createContext<CreateKitContext | undefined>(undefined)

export const useCreateKitContext = () => useContext(CreateKitContext) as CreateKitContext
export const useCreateKit = () => useContext(CreateKitContext)!.kit

export const useSetCreateKit = () => {
  const { setKit } = useContext(CreateKitContext)!

  return (data: Partial<CreateKitForm>) => {
    setKit(prev => ({
      ...prev,
      ...data,
    }))
  }
}

export const useCreateKitAddItem = () => {
  const { setKit } = useContext(CreateKitContext)!
  const user = useCurrentUser()

  return (item: Pick<KitItem, 'service' | 'defaultData'>) => {
    const newItem = {
      ...item,
      id: Date.now(),
      ownerId: user.id,
      defaultData: item.defaultData ?? {},
    } as KitItem

    setKit(prev => ({
      ...prev,
      items: [...prev.items, newItem].sort(compareFn),
    }))
  }
}

export const useCreateKitRemoveService = () => {
  const { setKit } = useContext(CreateKitContext)!

  return (serviceCode: ServiceCode, categoryId: number) => {
    setKit(prev => ({
      ...prev,
      items: prev.items.filter(item => {
        if (item.service.categoryId !== categoryId) {
          return true
        }

        if (item.service.code !== serviceCode) {
          return true
        }

        return false
      }).sort(compareFn), 
    }))
  }
}

export const useCreateKitUpdateDefaultData = () => {
  const { kit, setKit } = useContext(CreateKitContext)!

  return (currentItem: KitItem, updateData: Partial<KitItemDefaultData>) => {
    const items = [...kit.items]
    const index = items.findIndex(item => item.id === currentItem.id)

    if (index > -1) {
      const updatedItem = JSON.parse(JSON.stringify(items[index]))

      updatedItem.defaultData = {
        ...updatedItem.defaultData,
        ...updateData,
      }

      items[index] = updatedItem
    }

    setKit(prev => ({
      ...prev,
      items: items.sort(compareFn),
    }))
  }
}

const initialState: CreateKitForm = {
  name: '',
  legalEntity: 'indefinido',
  items: [],
  saving: false,
}

export const CreateKitProvider = ({ children }: { children: React.ReactNode }) => {
  const [kit, setKit] = useState(getStorageData('createKit', initialState))

  useEffect(() => {
    setStorageData('createKit', kit)
    return () => removeStorageItem('createKit')
  }, [kit])

  return <CreateKitContext.Provider value={{ kit, setKit }}>{children}</CreateKitContext.Provider>
}

function compareFn(a: KitItem, b: KitItem) {
  if (a.kitId! < b.kitId!) {
    return -1
  } 
  
  if (a.kitId! > b.kitId!) {
    return 1
  }
  
  // a must be equal to b
  return 0
}
