import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

import { OrderOverviewFilters, ResultCode, StatusCode } from 'domain/orders/types'
import { atomURI } from 'utils/navigation'
import { checkForFilters } from 'utils/validators'

const isExpiredAtom = atom<boolean | undefined>(undefined)
const resultsAtom = atom<ResultCode[]>([])
const statusesAtom = atom<StatusCode[]>([])

const isExpiredURIAtom = atomURI(isExpiredAtom, 'expired')
const resultsURIAtom = atomURI(resultsAtom, 'results')
const statusesURIAtom = atomURI(statusesAtom, 'status')

const filters = atom(
  get => {
    const filters: OrderOverviewFilters = {
      isExpired: get(isExpiredAtom),
      results: get(resultsAtom),
      statuses: get(statusesAtom),
    }

    return filters
  },
  (get, set, filters: OrderOverviewFilters) => {
    set(isExpiredURIAtom, filters.isExpired)
    set(resultsURIAtom, filters.results)
    set(statusesURIAtom, filters.statuses)
  }
)

export const useIsExpired = () => useAtom(isExpiredURIAtom)
export const useResults = () => useAtom(resultsURIAtom)
export const useStatuses = () => useAtom(statusesURIAtom)

export const useSetIsExpired = () => useSetAtom(isExpiredURIAtom)
export const useSetResults = () => useSetAtom(resultsURIAtom)
export const useSetStatuses = () => useSetAtom(statusesURIAtom)

const hasFilters = atom(get => checkForFilters(get(filters)))
export const useHasOverviewFilters = () => useAtomValue(hasFilters)

export const useOverviewFilters = () => useAtom(filters)
export const useGetOverviewFilters = () => useAtomValue(filters)
