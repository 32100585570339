import Axios, { InternalAxiosRequestConfig } from 'axios'

import { User } from 'domain/users/types'
import { getStorageData, removeStorageItem } from 'utils/localStorage'

const baseHeaders = {
  'Content-Type': 'application/json',
}

export const PROD = window.location.href.includes('app.cbrdoc.com.br')
export const LOCAL = window.location.href.includes('localhost')

export const getAPIConfig = (version: string = '') => {
  const baseURL = getBaseURL(version)
  const SecretAPI = getSecretAPIConfig(baseURL)
  const AuthorizedAPI = getAuthorizedAPIConfig(baseURL)

  return { AuthorizedAPI, SecretAPI }
}

export const getBaseURL = (version: string = '') => {
  if (window.location.hostname === 'localhost') {
    // baseURL = 'http://localhost:8000'
    return `https://api${version}.casamentonobrasil.com.br`
    // baseURL = `https://api${version}.correspondentelegal.com.br`
  }

  const domain = window.location.hostname.replace(/app./, '')

  return `https://api${version}.${domain}`
}

const getSecretAPIConfig = (baseURL: string) => {
  return Axios.create({
    baseURL,
    headers: {
      ...baseHeaders,
      Authorization: 'Bearer DsctfMfRWZ3i1YOWFSnvCHqiB7ey4A',
    },
  })
}

const getAuthorizedAPIConfig = (baseURL: string) => {
  const AuthorizedAPI = Axios.create({
    baseURL,
    headers: {
      ...baseHeaders,
    },
  })

  AuthorizedAPI.interceptors.request.use(
    (config = {} as InternalAxiosRequestConfig) => {
      const authState: User = getStorageData('auth')

      if (authState) {
        // @ts-ignore
        config.headers.Authorization = `Bearer ${authState.accessToken || authState.access_token || ''}`
      }

      return config
    },
    error => Promise.reject(error)
  )

  AuthorizedAPI.interceptors.response.use(
    response => response,
    error => {
      const headers = error.config && error.config.headers
      const statusCode = error.response && error.response.status

      if (headers && 'Authorization' in headers && [401].includes(statusCode)) {
        window.location.href = '/login?session=expired'
        removeStorageItem('auth')
      }

      return Promise.reject(error)
    }
  )

  return AuthorizedAPI
}
