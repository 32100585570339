import React from 'react'

import { ViewOrderResearchActionsInline, ViewResearchActions, ViewResearchWithResults } from 'domain/orderItem'
import { ExcelGenerator, ResearchResult, TableColumn } from 'domain/orderItem/types'

function ViewPesquisaObito() {
  return (
    <ViewResearchWithResults columns={columns} actions={<ViewResearchActions excel={excelColGenerator} />} />
  )
}

const columns: TableColumn[] = [
  {
    title: 'Nome',
    dataIndex: 'nome',
  },
  {
    title: 'Documento',
    dataIndex: 'cpf',
  },
  {
    title: 'Data do óbito',
    dataIndex: 'obito',
  },
  {
    title: 'Nome da mãe',
    dataIndex: 'mae',
  },
  {
    title: 'Nome do pai',
    dataIndex: 'pai',
  },
  {
    title: 'Livro/Página',
    dataIndex: 'livro_pagina',
  },
  {
    title: 'Número do termo',
    dataIndex: 'termo',
  },
  {
    title: 'Cidade',
    dataIndex: ['url_cidade', 'label'],
  },
  {
    title: 'Estado',
    dataIndex: ['url_uf', 'label'],
  },
  {
    title: 'Cartório',
    dataIndex: ['url_cartorio', 'label'],
  },
  {
    title: '',
    dataIndex: 'actions',
    align: 'right',
    render: (_: any, result: ResearchResult) => <ViewOrderResearchActionsInline result={result} />,
  },
]

const excelColGenerator: ExcelGenerator = {
  columns: [...columns].splice(0, columns.length - 1),
}

export default ViewPesquisaObito
