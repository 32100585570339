import { CreateOrderState } from 'domain/createOrder/types'
import CreateOrderService from 'domain/servicesCBRdoc/config/classes/CreateOrderService'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type AIFields = Record<
  | FieldName.ARQUIVO_NOME_ORIGINAL
  | FieldName.MODELO_IA
  | FieldName.UPLOAD
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

const { ARQUIVO_NOME_ORIGINAL, MODELO_IA, UPLOAD, URL_CIDADE, URL_UF } = FieldName

class AI extends CreateOrderService {
  formFields: AIFields = {
    [URL_UF]: Fields[URL_UF],
    [URL_CIDADE]: Fields[URL_CIDADE],
    [MODELO_IA]: Fields[MODELO_IA],
    [ARQUIVO_NOME_ORIGINAL]: Fields[ARQUIVO_NOME_ORIGINAL],
    [UPLOAD]: Fields[UPLOAD],
  }

  constructor() {
    super({
      allowMultipleItems: false,
      id: 96,
      shortName: 'AI',
      steps: ['dados'],
    })
  }

  convertToCartItems = (createOrder: CreateOrderState) => {
    const cartItem = this.generateCartItems(createOrder)
    return cartItem
  }
}

const service = new AI().initialize()

export default service
