import { CreateOrderState } from 'domain/createOrder/types'
import { SignatureCertification } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { ASSINANTES, UPLOAD, CPF, NOME_PF, EMAIL } = FieldName

type AssinaturaFields = Record<
  FieldName.ASSINANTES | FieldName.UPLOAD | FieldName.CPF | FieldName.NOME_PF | FieldName.EMAIL,
  FieldConfig
>

class ColetaAssinaturas extends SignatureCertification {
  formFields: AssinaturaFields = {
    [ASSINANTES]: Fields.assinantes,
    [UPLOAD]: Fields.arquivo,
    [CPF]: {
      ...Fields.cpf,
      ignoreOnKitsDetailsForm: true,
    },
    [NOME_PF]: {
      ...Fields.nome_pf,
      ignoreOnKitsDetailsForm: true,
    },
    [EMAIL]: {
      ...Fields.email,
      ignoreOnKitsDetailsForm: true,
    },
  }

  constructor() {
    super({
      id: 121,
      shortName: 'Quero coletar assinaturas para o documento',
      steps: ['upload', 'dados'],
      startFromStep: 'upload',
      multipleItemsCollapseHeaderTitleField: NOME_PF,
      isKitAvailable: false,
    })
  }

  protected getSubmitData = (createOrderState: CreateOrderState) => {
    const { files, formData, origin, selectedFormat, selectedService } = createOrderState

    if (!files) throw new Error('No files found')
    const fileName = files[0].fileName ?? files[0].name ?? 'Documento sem nome'
     
    const submitData: any = {
      arquivo: files[0].fileUploadId,
      assinantes: formData.assinantes ?? [],
      origin: origin.description,
      formato: selectedFormat || 'email',
      service_id: selectedService.id,
      service_category_id: selectedService.categoryId,
      name: `Assinaturas para documento - ${fileName}`,
    }

    return submitData
  }
}

const service = new ColetaAssinaturas().initialize()

export default service
