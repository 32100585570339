import React from 'react'

import Svg from '../Svg'

function MicrosoftIcon() {
  return (
    <Svg
      width="36"
      height="36"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 3H11V11H3V3Z" fill="#f25022" />
      <path d="M3 13H11V21H3V13Z" fill="#00a4ef" />
      <path d="M13 3H21V11H13V3Z" fill="#7fba00" />
      <path d="M13 13H21V21H13V13Z" fill="#ffb900" />
    </Svg>
  )
}

export default MicrosoftIcon
