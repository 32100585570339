import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { useMemo } from 'react'
import { useUpdateUri_deprecated, useUrlQuery } from 'utils/navigation'
import { Kit, KitUrlParams } from '../types'

export const currentPageAtom = atom(1)
const pageSizeAtom = atomWithStorage('kits-page-size', 20)
const sortFieldAtom = atomWithStorage('kits-sort-field2', 'created_at')
const sortDirectionAtom = atomWithStorage('kits-sort-order', 'descend')
const sortByAtom = atom(get => {
  const field = get(sortFieldAtom)
  return get(sortDirectionAtom) === 'descend' ? `-${field}` : field
})

const selectedIdsAtom = atom<string[]>([])
const selectedRowsAtom = atom<Kit[]>([])
const totalResultsAtom = atom(0)
const totalPagesAtom = atom(0)

const resetSelectedRows = atom(
  () => null,
  (get, set) => {
    set(currentPageAtom, 1)
    set(selectedIdsAtom, [])
    set(selectedRowsAtom, [])
  }
)

export const useGetCurrentPage = () => {
  const pageValue = useAtomValue(currentPageAtom)
  const params = useUrlQuery<KitUrlParams>()
  
  return useMemo(() => {
    if (params?.p) {
      const page = parseInt(params.p)
      
      if (Number.isInteger(page)) {
        return page
      }
    }
    
    return pageValue
  }, [params?.p])
}

export const useSetCurrentPage = () => {
  const updateUri = useUpdateUri_deprecated<KitUrlParams>()
  const setCurrPage = useSetAtom(currentPageAtom)

  return function setCurrentPage(page: number) {
    updateUri({ p: page.toString() })
    setCurrPage(page)

  }
}

export const useCurrentPage = (): [number, (value: number) => void] => {
  const val = useGetCurrentPage()
  const setFn = useSetCurrentPage()
  return [val, setFn]
}

export const usePageSize = () => {
  const pageSizeVal = useAtomValue(pageSizeAtom)
  const params = useUrlQuery<KitUrlParams>()
  
  return useMemo(() => {
    if (params?.pageSize) {
      const size = parseInt(params.pageSize)
      
      if (Number.isInteger(size)) {
        return size
      }
    }
    
    return pageSizeVal
  }, [params?.pageSize])
}

export const useSetPageSize = () => {
  const updateUri = useUpdateUri_deprecated<KitUrlParams>()
  const setFn = useSetAtom(pageSizeAtom)

  return function setCurrentPage(size: number) {
    updateUri({ pageSize: size.toString() })
    setFn(size)
  }
}

export const usePageSizeState = (): [number, (value: number) => void] => {
  const val = usePageSize()
  const setFn = useSetPageSize()
  return [val, setFn]
}

export const useSetSelectedRows = () => useSetAtom(selectedRowsAtom)
export const useSelectedRows = () => useAtom(selectedRowsAtom)
export const useResetTableSelection = () => useSetAtom(resetSelectedRows)
export const useSelectedIdsState = () => useAtom(selectedIdsAtom)
export const useSetSortDirection = () => useSetAtom(sortDirectionAtom)
export const useSetSortField = () => useSetAtom(sortFieldAtom)
export const useSetTotalPages = () => useSetAtom(totalPagesAtom)
export const useSetTotalResults = () => useSetAtom(totalResultsAtom)
export const useSortBy = () => useAtomValue(sortByAtom)
export const useTotalResults = () => useAtomValue(totalResultsAtom)
