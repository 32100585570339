import React from 'react'

import { HelpLine } from 'domain/app'
import { GeneratePixQRCode } from 'domain/checkout'
import { PaymentMethod } from 'domain/checkout/types'
import { Alert, Boleto, ErrorBoundary, Icon } from 'ui'
import { useGetOrderItem } from '../../state/viewOrderItem'

function ViewOrderPayment() {
  const orderItem = useGetOrderItem()
  const { bankSlipBarcode, bankSlipURL, paymentMethod, pixQRCode } = orderItem.order.waitingInvoicePayment!
  const isInvoice = paymentMethod === PaymentMethod.INVOICE

  return (
    <div className="pr-6 pl-8 flex flex-col flex-1 items-center bg-gray-100">
      <div className="w-full bg-white flex flex-col items-center rounded-lg gap-8 mb-4 py-14">
        <div className="flex justify-center mb-4">
          <Icon name="clock" size={52} className="text-secondary-500" />
        </div>

        <h1 className="font-bold text-xl text-center mb-3">Aguardando pagamento</h1>

        <p className="text-center">
          Caso ainda não tenha realizado o pagamento do {isInvoice ? 'boleto' : 'PIX'}, efetue agora mesmo
          para darmos andamento ao seu pedido.
        </p>

        <div className="max-w-sm mx-auto">
          <Alert
            type="error"
            icon={<Icon name="warning" />}
            className="my-8 mb-12 text-gray-700"
            message={
              isInvoice
                ? 'Após o pagamento do boleto, seu crédito pode levar até 3 dias úteis para ser liberado.'
                : 'Você tem 30 minutos para efetuar o pagamento do pix. Após este prazo esta chave PIX será cancelada.'
            }
          />
          {isInvoice ? (
            <Boleto barcode={bankSlipBarcode} url={bankSlipURL} className="bg-white" />
          ) : (
            <ErrorBoundary>
              <GeneratePixQRCode pixKey={pixQRCode} />
            </ErrorBoundary>
          )}
        </div>
        
        <HelpLine />
      </div>
    </div>
  )
}

export default ViewOrderPayment
