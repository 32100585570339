import { useGetKit } from 'domain/createOrder/kit/state'
import { Sidebar } from 'layouts'

import KitOrderPreviewCard from './KitOrderPreviewCard'
import AddDocument from './kitEdition/AddDocument'

export default function KitOrderSidebar() {
  const kit = useGetKit()  

  return (
    <Sidebar
      sectionTitle={
        <>
          Seu Kit{' '}
          <span className="ml-2 text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-primary text-white rounded-full">
            {kit.items.length}
          </span>
        </>
      }
    >
      {kit.items.map((kitItem, index) => (
        <KitOrderPreviewCard key={index} kitItem={kitItem} />
      ))}

      <AddDocument />
    </Sidebar>
  )
}
