import { NamePath } from 'antd/lib/form/interface'

import SelectField, { SelectFieldProps } from './SelectField'

export interface FederativeUnitSelectProps
  extends Omit<
    SelectFieldProps,
    'label' | 'labelInValue' | 'name' | 'optionLabelKey' | 'optionValueKey' | 'placeholder'
  > {
  label?: string
  name?: NamePath
  multiple?: boolean
  labelInValue?: boolean
  suffix?: string
}

function FederativeUnitSelect({
  name = 'serviceFederativeUnit',
  multiple,
  suffix = '',
  options = FederativeUnits,
  label = `Estado${multiple ? '(s)' : ''} ${suffix}`,
  labelInValue = true,
  ...props
}: FederativeUnitSelectProps) {
  return (
    <SelectField
      name={name}
      label={label}
      placeholder="Selecione o estado"
      labelInValue={labelInValue}
      mode={multiple ? 'multiple' : undefined}
      optionLabelKey="name"
      optionValueKey="url"
      options={options}
      {...props}
    />
  )
}

const FederativeUnits = [
  { url: 'AC', name: 'Acre' },
  { url: 'AL', name: 'Alagoas' },
  { url: 'AP', name: 'Amapá' },
  { url: 'AM', name: 'Amazonas' },
  { url: 'BA', name: 'Bahia' },
  { url: 'CE', name: 'Ceará' },
  { url: 'DF', name: 'Distrito Federal' },
  { url: 'ES', name: 'Espírito Santo' },
  { url: 'GO', name: 'Goiás' },
  { url: 'MA', name: 'Maranhão' },
  { url: 'MT', name: 'Mato Grosso' },
  { url: 'MS', name: 'Mato Grosso do Sul' },
  { url: 'MG', name: 'Minas Gerais' },
  { url: 'PA', name: 'Pará' },
  { url: 'PB', name: 'Paraíba' },
  { url: 'PR', name: 'Paraná' },
  { url: 'PE', name: 'Pernambuco' },
  { url: 'PI', name: 'Piauí' },
  { url: 'RJ', name: 'Rio de Janeiro' },
  { url: 'RN', name: 'Rio Grande do Norte' },
  { url: 'RS', name: 'Rio Grande do Sul' },
  { url: 'RO', name: 'Rondônia' },
  { url: 'RR', name: 'Roraima' },
  { url: 'SC', name: 'Santa Catarina' },
  { url: 'SP', name: 'São Paulo' },
  { url: 'SE', name: 'Sergipe' },
  { url: 'TO', name: 'Tocantins' },
]

export const FederativeUnitAbbreviations = FederativeUnits.map(o => o.url)

export default FederativeUnitSelect
