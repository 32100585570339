export enum PermissionsCodeAPI {
  'can-approve-purchases-quotes' = 'canApprovePurchasesQuotes',
  'can-delete-other-users-uploaded-files' = 'canDeleteOtherUsersUploadedFiles',
  'can-delete-own-uploaded-files' = 'canDeleteOwnUploadedFiles',
  'can-insert-credits' = 'canInsertCredits',
  'can-manage-ai-models' = 'canManageAiModels',
  'can-manage-users-and-company-data' = 'canManageUsersAndCompanyData',
  'can-order-services' = 'canOrderServices',
  'can-see-invoices' = 'canSeeInvoices',
  'can-see-other-users-orders' = 'canSeeOtherUsersOrders',
  'can-see-own-orders' = 'canSeeOwnOrders',
  'can-see-reports' = 'canSeeReports',
  'can-share-orders' = 'canShareOrders',
  'can-upload-explorer-items' = 'canUploadExplorerItems',
  'can-extract-data-using-ai' = 'canExtractDataUsingAi',
} 

export type PermissionName =
  | 'canApprovePurchasesQuotes'
  | 'canDeleteOtherUsersUploadedFiles'
  | 'canDeleteOwnUploadedFiles'
  | 'canInsertCredits'
  | 'canManageAiModels'
  | 'canManageUsersAndCompanyData'
  | 'canOrderServices'
  | 'canSeeInvoices'
  | 'canSeeOtherUsersOrders'
  | 'canSeeOwnOrders'
  | 'canSeeReports'
  | 'canShareOrders'
  | 'canUploadExplorerItems'
  | 'canExtractDataUsingAi'
  
export type PermissionCode = keyof typeof PermissionsCodeAPI

export type PermissionAPI = {
  code: PermissionCode
  id: number
  name: string
  permission_group_id: number
}

export type PermissionGroup = {
  id: number
  name: string
  permissions: Permission[]
}

export type PermissionGroupAPI = {
  id: number
  name: string
  permissions: PermissionAPI[]
}

export type Permission = {
  code: PermissionName
  groupId: number
  id: number
  name: string
}
