import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs } from 'domain/createOrder/document'
import { CNPJAndNameFields, NameField, TextField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../certidaoPenhorSafraConfig'

export default function CreateOrderCertidaoPenhorSafra() {
  return <CertificateFlow renderFormItem={(itemId: number) => <CertidaoPenhorSafraForm itemId={itemId} />} />
}

const CertidaoPenhorSafraForm = ({ itemId }: { itemId: number }) => {
  const inputProps = { itemId, autoFocus: itemId === 0 }

  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={
          <>
            <CPFInput {...inputProps} />
            <NameField itemId={itemId} field={nome_pf} />
          </>
        }
        juridica={<CNPJAndNameFields {...inputProps} companyField={nome_pj} />}
      />

      <TextField field={registro} itemId={itemId} />
      <TextField field={tipo_safra} itemId={itemId} />
      <TextField field={nome_propriedade} itemId={itemId} />
    </>
  )
}

const { nome_pf, nome_pj, nome_propriedade, registro, tipo_safra } = config.formFields
