import React, { useMemo } from 'react'
import { Dropdown } from 'antd'

import { useResults } from 'domain/orders/state/filters'
import { ResultCode } from 'domain/orders/types'
import { SelectableButton } from 'ui'

export default function FilterByResult() {
  const [results, setResults] = useResults()

  const items = useMemo(() => {
    return [
      {
        label: 'Positivos',
        key: ResultCode.POSITIVE,
        onClick: () => setResults(results.includes(ResultCode.POSITIVE) ? [] : [ResultCode.POSITIVE]),
      },
      {
        label: 'Negativos',
        key: ResultCode.NEGATIVE,
        onClick: () => setResults(results.includes(ResultCode.NEGATIVE) ? [] : [ResultCode.NEGATIVE]),
      },
    ]
  }, [results])

  return (
    <Dropdown menu={{ items }} trigger={['hover']}>
      <div>
        <SelectableButton
          onClick={() => {
            setResults(results.length === 0 ? [ResultCode.POSITIVE] : [])
          }}
          selected={!!results.length}
        >
          {results.includes(ResultCode.NEGATIVE) ? 'Negativos' : 'Positivos'}
        </SelectableButton>
      </div>
    </Dropdown>
  )
}
