import React from 'react'
import { useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'

import { createOrderActions } from 'domain/createOrder/document/state'
import { CreateOrderFormItem, FormItems } from 'domain/createOrder/types'

type Props = {
  index: number
  items: CreateOrderFormItem[]
  movingItemId: number | null
  setMovingItemId: React.Dispatch<React.SetStateAction<number | null>>
}

export default function ItemsCollapseDroppable({ index, items, movingItemId, setMovingItemId }: Props) {
  const dispatch = useDispatch()

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'card',
      drop: () => {
        const fromIndex = items.findIndex(o => o.id === movingItemId)
        const lastIndex = items.length - 1
        const shouldMoveToTheBottom = index === lastIndex + 1 
        const toIndex = shouldMoveToTheBottom ? lastIndex : index

        if (fromIndex !== toIndex) {
          const arr = [...items]

          arr.splice(fromIndex, 1)
          arr.splice(toIndex, 0, items[fromIndex])

          const formItems: FormItems = {}

          arr.forEach((item, index) => {
            formItems[item.id] = {
              ...item,
              itemOrder: index
            }
          })

          dispatch(createOrderActions.setFormItems(formItems))
        }        

        setMovingItemId(null)
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [movingItemId]
  )

  return (
    <div ref={drop} className="h-4 w-full flex flex-col justify-center">
      {isOver && <div className="h-1 w-full border-b border-secondary-500" />}
    </div>
  )
}
