/**
 * Recupera as permissões do usuário e atualiza o redux
 */
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { convertUser } from 'domain/users'
import { UserAPI } from 'domain/users/types'
import { useCurrentUser, useSetAuth } from '../state'

export default function useRefreshUserPermissions() {
  const user = useCurrentUser()
  const setAuth = useSetAuth()

  const { data: fetchedUser } = useQuery(
    ['current-user', user.id],
    async () => {
      const response: AxiosResponse<UserAPI> = await AuthorizedAPI.get(`users/${user.id}`, {
        params: { include: 'permissions' },
      })
      return response.data
    },
    {
      enabled: Boolean(user.accessToken),
      // refetchInterval: 30 * 60 * 1000, // 30 minutos
    }
  )

  useEffect(() => {
    if (fetchedUser) {
      const authenticatedUser = convertUser({
        ...fetchedUser,
        access_token: user.accessToken,
      })

      const permissionChanged = authenticatedUser.permissions
        .map(p => user.permissions?.includes(p))
        .some(p => !p)

      if (permissionChanged) {
        setAuth(authenticatedUser)
      }
    }
  }, [fetchedUser])
}
