import { NameField } from 'domain/servicesCBRdoc/fields'
import { CPFOrCNPJInput, EmailInput } from 'ui'

import config from '../rgiConfig'

interface Props {
  itemId: number
  autoFocus?: boolean
}

export default function CreateOrderRGIPartForm({ itemId, autoFocus }: Props) {
  return (
    <>
      <NameField itemId={itemId} field={nome} autoFocus={autoFocus} required />
      <CPFOrCNPJInput name={documento.name} itemId={itemId} required />
      <EmailInput name={[itemId, email.name]} required />
    </>
  )
}

const { documento, email, nome } = config.formFields
