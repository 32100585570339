import { useMutation } from 'react-query'
import { message } from 'antd'

import { errorHandler } from 'api/errors'
import { API } from 'api/network/v2'
import queryClient from 'app/config/queryClient'
import { useCurrentUser } from 'domain/auth'

import {
  NotificationConfigFieldName,
  NotificationConfigForm,
  NotificationMedium,
  NotificationSettingName,
  UserNotificationSettingsAPI,
} from '../types'
import { NotificationSettings } from '../data'

export default function useUpdateUserNotificationsSettings() {
  const user = useCurrentUser()

  return useMutation(
    async (settings: NotificationConfigForm) => {
      const payload: Partial<UserNotificationSettingsAPI> = {}
      const entries = Object.entries(settings) as [NotificationConfigFieldName, boolean][]

      entries.forEach(([fieldName, checked]) => {
        const mediumAndName = fieldName.split('_')
        const medium = mediumAndName[0] as NotificationMedium
        const settingName = mediumAndName[1] as NotificationSettingName

        const config = NotificationSettings[settingName]

        if (config) {
          if (!payload[config.code]) {
            payload[config.code] = []
          }

          if (checked) {
            payload[config.code]!.push(medium)
          }
        }
      })

      return await API.put(`/users/${user.id}/notifications-preferences`, payload)
    },
    {
      onError: error => {
        errorHandler(error, { code: '0x807' })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['user-notifications-settings', user.id])
        message.success('Suas preferências foram salvas.')
      },
    }
  )
}
