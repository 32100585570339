import React from 'react'

import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs } from 'domain/createOrder/document'
import { CNPJInput, CPFInput } from 'ui'

export default function CreateOrderCertidaoEmbargosIbama() {
  return (
    <CertificateFlow
      header={
        <p className="mb-5 text-gray-600">
          Selecione Pessoa ou Empresa e digite o número do CPF ou CNPJ que deseja a IBAMA - Certidão de
          Embargos.
        </p>
      }
      renderFormItem={(itemId: number) => <CertidaoEmbargosIbamaForm itemId={itemId} />}
    />
  )
}

function CertidaoEmbargosIbamaForm({ itemId }: { itemId: number }) {
  const inputProps = { itemId, autoFocus: itemId === 0 }

  return (
    <CreateOrderLegalEntityTabs
      itemId={itemId}
      fisica={<CPFInput {...inputProps} />}
      juridica={<CNPJInput {...inputProps} />}
    />
  )
}
