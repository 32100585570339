import { useState } from 'react'
import { Form, message } from 'antd'
import { useParams } from 'react-router-dom'

import { TempFile } from 'domain/app/types'
import { GroupsAssociation } from 'domain/groups'
import { useUploadExplorerFile } from 'domain/myFiles/services'
import { useUpdateLatestStorageOperation } from 'domain/myFiles/state'
import { GroupAPI } from 'domain/groups/types'
import { Modal, SubmitButton, UploadDragger } from 'ui'

import ServiceSelect from '../register/ServiceSelect'

interface Props {
  open: boolean
  toggleOpen: () => void
}

export default function UploadItemModal({ open, toggleOpen }: Props) {
  const updateLatestStorageOperation = useUpdateLatestStorageOperation()
  const { parentId } = useParams<{ parentId: string }>()
  const { upload, status, resetStatus } = useUploadExplorerFile()
  const [groups, setGroups] = useState<GroupAPI[]>([])
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState<TempFile[]>([])

  const onClose = () => {
    form.resetFields()
    setFileList([])
    toggleOpen()
  }

  const onSubmit = async (filledForm: any) => {
    const formData = new FormData()

    if (parentId && parseInt(parentId) > 0) {
      formData.append('parent_id', parentId)
    }

    if (groups.length > 0) {
      groups.forEach(g => formData.append('groups_ids[]', g.id.toString()))
    }

    if (filledForm.serviceId) {
      formData.append('service_id', filledForm.serviceId)
    }

    if (fileList) {
      fileList.forEach((f: any) => formData.append('files[]', f.originFileObj))
    }

    await upload(formData)

    setGroups([])
    updateLatestStorageOperation()
    message.success('Arquivos enviados com sucesso.')
    onClose()
  }

  return (
    <Modal open={open} title="Enviar arquivo" onCancel={onClose}>
      <Form form={form} onFinish={onSubmit} layout="vertical" requiredMark={false} className="max-w-lg">
        <ServiceSelect name="serviceId" />

        <div className="max-w-lg">
          <UploadDragger
            name="explorer"
            fileList={fileList}
            setFileList={(list: any) => {
              resetStatus()
              setFileList(list)
            }}
            disabled={status === 'loading'}
            shouldBeforUpload={false}
          />

          <GroupsAssociation className="mt-6" groups={groups} onFinish={setGroups} />
        </div>

        <SubmitButton
          label="Enviar Arquivos"
          marginTop="mt-3"
          disabled={
            status === 'loading' ||
            status === 'error' ||
            !fileList.length ||
            fileList.some(f => f.status === 'error')
          }
          loading={status === 'loading'}
        />
      </Form>
    </Modal>
  )
}
