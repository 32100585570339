import { ErrorMessage } from 'domain/app'
import { useKitsQuery } from 'domain/kits/services'
import { useHasFilters } from 'domain/kits/state'
import { Loading } from 'ui'

import KitsEmpty from './KitsEmpty'
import KitsSearch from './KitsSearch'
import KitsTable from './KitsTable'
import KitsFilters from './KitsFilters'

export default function Kits() {
  const { data: kits, isLoading, isFetching, error, refetch } = useKitsQuery()
  const hasFilters = useHasFilters()

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (kits.length === 0 && !hasFilters) {
    return <KitsEmpty />
  }

  return (
    <>
      <div className="flex items-center bg-white rounded-t">
        <KitsSearch />
        <KitsFilters />
      </div>
      <KitsTable kits={kits} isFetching={isFetching} refetch={refetch} />
    </>
  )
}
