import React from 'react'

import { Icon } from 'ui'

function RejectedReason({ reason, warning }: { reason: string; warning?: boolean }) {
  return (
    <div
      className={`my-6 mx-auto flex p-4 py3 bg-opacity-15 border-2 rounded-lg w-3/5 ${
        warning ? 'bg-yellow-500 border-yellow-500' : 'bg-red-400 border-red-500'
      }`}
    >
      <div className="w-10 h-10 rounded-full flex flex-none justify-center items-center mr-3">
        <Icon name={warning ? 'warning' : 'cross-circle'} color={warning ? '#c97a04' : 'red'} size={24} />
      </div>
      <div
        className="mr-3 text-sm my-auto text-wrap hyphens-auto break-words w-full pr-10 [&>ul]:list-disc [&>ul]:list-inside [&_li]:marker:text-primary"
        dangerouslySetInnerHTML={{ __html: reason }}
      />
    </div>
  )
}

export default RejectedReason
