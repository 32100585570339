import { AuthorizedAPI } from 'api/network/v2'
import { useQuery } from 'react-query'

type DownloadPurchaseResponse = {
  'Nome da compra': string
  'Nº da compra': number
  'Nome do pedido': string
  'Nº do pedido': number
  Registro: string
  'Data do pedido': string
  'Data de envio': string
  Documento: string
  Status: string
  Solicitante: string
  'Créditos utilizados': number
  'Link para download': string
  Grupos: string
  Resultado: string
  Validade: string
  'Informações extras': string
}

function useOrderItemDownloadPDF(orderId: number) {
  return useQuery(['orderItesm', 'download', orderId], async () => {
    const response = await AuthorizedAPI.get<DownloadPurchaseResponse[]>(`/purchases/${orderId}/report/json`)
    return response.data
  })
}

export default useOrderItemDownloadPDF 
