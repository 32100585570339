import React from 'react'

import { DeliveryInfo } from 'domain/createOrder/shared/components'
import { CreateOrderFormItem } from 'domain/createOrder/types'

export default function MultipleItemsDeliveryInfo({ items }: { items: CreateOrderFormItem[] }) {
  const estimatedDeliveryTime = items.reduce((acc: any, item) => {
    const { estimatedDeliveryTime } = item.orderDetails

    if (estimatedDeliveryTime) {
      if (Array.isArray(acc)) {
        if (Array.isArray(estimatedDeliveryTime)) {
          if (estimatedDeliveryTime[0] > acc[0]) {
            acc[0] = estimatedDeliveryTime[0]
          }
          if (estimatedDeliveryTime[1] > acc[1]) {
            acc[1] = estimatedDeliveryTime[1]
          }
        } else if (estimatedDeliveryTime > acc[1]) {
          acc[1] = estimatedDeliveryTime
        }
      } else {
        if (Array.isArray(estimatedDeliveryTime)) {
          if (estimatedDeliveryTime[1] > acc) {
            acc = estimatedDeliveryTime
          }
        } else if (estimatedDeliveryTime > acc) {
          acc = estimatedDeliveryTime
        }
      }
    }

    return acc
  }, 0)

  if (!estimatedDeliveryTime) return null

  return <DeliveryInfo estimatedDeliveryTime={estimatedDeliveryTime} />
}
