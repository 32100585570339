import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal } from 'antd'

import { RoutePaths } from 'app/routes'
import { useCurrentUser } from 'domain/auth'
import {
  Checkout,
  useCheckoutFlow,
  useCheckoutValidationErrorsModal,
  useGetCheckoutData,
  useGetCheckoutLoading,
} from 'domain/checkout'
import {
  CreateOrderInProgressError,
  PurchaseValidationError,
  placeOrderAPIWithMetrics,
} from 'domain/createOrder'
import { PurchaseWithErrorState } from 'domain/createOrder/types'
import { useCustomerCreditsQuery } from 'domain/credits'
import { useCurrentCustomer, useIsCustomerPostPaid } from 'domain/customers'

import { useResetShoppingCart, useShoppingCartState } from '../state'
import { BrandButton } from 'ui'

export default function CartCheckoutButton() {
  const { orders } = useShoppingCartState()
  const { startCheckout, loading, creditsToInsert } = useCheckout()

  return (
    <>
      <BrandButton
        onClick={startCheckout}
        loading={loading}
        disabled={orders.length < 1 || loading}
        title={orders.length < 1 ? 'Adicione ao menos 1 pedido ao seu carrinho' : ''}
        className="mx-auto text-base"
        autoFocus
        block
      >
        Finalizar pedido
      </BrandButton>

      <Checkout paymentValue={creditsToInsert} />
    </>
  )
}

function useCheckout() {
  const history = useHistory()
  const { orderId, orders: orderItems, shoppingCartId, totalPrice } = useShoppingCartState()
  const isAddressNeeded = orderItems?.some(order => order.selectedFormat && order.selectedFormat !== 'email')
  const user = useCurrentUser()
  const customer = useCurrentCustomer()!
  const isCustomerPostPaid = useIsCustomerPostPaid()!
  const creditsToInsert = useGetLackingCredits(totalPrice)
  const checkoutData = useGetCheckoutData()
  const loading = useGetCheckoutLoading()
  const resetCart = useResetShoppingCart()
  const showValidationErrors = useCheckoutValidationErrorsModal()

  const startCheckout = useCheckoutFlow({
    onSubmit: async () => {
      return await placeOrderAPIWithMetrics(
        {
          shoppingCartId,
          customer,
          orderItems,
          isCustomerPostPaid,
          creditsToInsert,
          purchaseTotal: totalPrice,
          endpoint: orderId ? `purchases/${orderId}/orders` : '/purchases',
          user,
          origin,
        },
        checkoutData
      )
    },
    onError: (error: any) => {
      if (error instanceof PurchaseValidationError) {
        showValidationErrors(error.data, orderItems)
      } else if (error instanceof CreateOrderInProgressError) {
        resetCart()
        Modal.error({
          icon: false,
          title: 'Criação do Pedido em progresso',
          content: (
            <div>
              <p className="mb-3">Por favor, aguarde alguns instantes. </p>

              <p>
                Seu pedido deve aparecer em <b>Meus Pedidos</b> em breve.
              </p>
            </div>
          ),
          closable: true,
          maskClosable: true,
          okText: 'Meus Pedidos',
          onOk() {
            history.push(RoutePaths.ORDERS)
          },
        })
      } else {
        history.push(RoutePaths.PURCHASED_WITH_ERROR, {
          message: error.message,
          requestID: error.response?.RID,
          origin,
        } as PurchaseWithErrorState)
      }
    },
    isAddressNeeded,
    creditsToInsert,
  })

  return { startCheckout, loading, creditsToInsert }
}

function useGetLackingCredits(totalPrice: number) {
  const isPostPaid = useIsCustomerPostPaid()
  const { data: accountBalance } = useCustomerCreditsQuery({ showError: true })

  return useMemo(() => {
    if (isPostPaid || accountBalance === undefined) return 0
    if (accountBalance < 0) return totalPrice
    if (accountBalance >= totalPrice) return 0

    return totalPrice - accountBalance
  }, [isPostPaid, totalPrice, accountBalance])
}
