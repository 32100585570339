import { useEffect } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import { useIsHydratedFromURL } from 'domain/app'
import { useSetPeriodFilter } from 'domain/reports/filters'
import {
  useSetCurrentPage,
  useSetIsExpired,
  useSetOwners,
  useSetResults,
  useSetSearch,
  useSetServices,
  useSetStatuses,
} from 'domain/reports/orders'

export default function useSetInitialValuesFromURL() {
  const history = useHistory()  
  const location = history.location
  const [isHydrated, setIsHydrated] = useIsHydratedFromURL()
  const setCurrentPage = useSetCurrentPage()
  const setPeriod = useSetPeriodFilter()
  const setSearch = useSetSearch()
  const setOwners = useSetOwners()
  const setServices = useSetServices()
  const setStatuses = useSetStatuses()
  const setResults = useSetResults()
  const setIsExpired = useSetIsExpired()

  useEffect(() => {
    if (!isHydrated) {
      if (location.search) {
        const { expired, owner, p, period, q, result, service, status } = queryString.parse(location.search)
        
        if (p) {
          setCurrentPage(Number(p))
        }
        if (period) {
          setPeriod(period)
        }
        if (owner) {
          setOwners((owner as string).split(','))
        }
        if (q) {
          setSearch(q)
        }
        if (service) {
          setServices((service as string).split(','))
        }
        if (status) {
          setStatuses((status as string).split(','))
        }
        if (result) {
          setResults((result as string).split(','))
        }
        if (expired) {
          setIsExpired(expired === 'true')
        }
      }
      setIsHydrated(true)
    }
  }, [])
}
