import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'

type TaxPrice = { price: number }

function useServiceTaxQuery() {
  return useQuery(['service-tax'], getServiceTax)
}

const getServiceTax = async () => {
  const response: AxiosResponse<TaxPrice> = await AuthorizedAPI.get('/services/tax-price')
  return response.data
}

export default useServiceTaxQuery
