import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Spin, Tabs } from 'antd'

import { FeedbackMessage } from 'domain/app'
import {
  MyFileOpenAI,
  ViewItemSidebar,
  ViewFileContent,
  ViewFileOCRContent,
  useFileQuery,
  useExplorerItem,
} from 'domain/myFiles'
import { ExplorerItem } from 'domain/myFiles/types'
import { GoBackButton, PageLayout } from 'layouts'
import { ActionsBar, Icon } from 'ui'

export default function ViewUploadedFilePage() {
  const params = useParams<{ id: string }>()
  const [activeTab, setActiveTab] = useState('original')
  const [explorerItem, setExplorerItem] = useExplorerItem()

  const { isLoading, isError } = useFileQuery(params.id as unknown as number, {
    onSuccess: (data: ExplorerItem) => {
      setExplorerItem(data)
    },
  })

  return (
    <PageLayout
      pageTitle={explorerItem.name || 'Ver arquivo'}
      sidebar={activeTab === 'original' && <ViewItemSidebar />}
    >
      <ActionsBar>
        <GoBackButton />
      </ActionsBar>

      <Spin spinning={isLoading} className="flex-1">
        {isError ? (
          <FeedbackMessage
            title="Ocorreu um erro"
            message="Não foi possível visualizar esse arquivo. Tente novamente."
            alertType="error"
            icon={<Icon name="cross-circle" className="text-6xl mt-5 mb-5" />}
          />
        ) : (
          <Tabs
            defaultActiveKey="original"
            className="mt-3 flex flex-1 h-full"
            onChange={setActiveTab}
            items={tabItems}
            tabBarStyle={tabStyle}
          />
        )}
      </Spin>
    </PageLayout>
  )
}

const tabStyle = { height: 30, marginLeft: 24 }

const tabItems = [
  {
    label: 'Original',
    key: 'original',
    children: <ViewFileContent />,
  },
  {
    label: 'Transcrição',
    key: 'transcript',
    children: <ViewFileOCRContent />,
  },
  {
    label: 'Extração de Dados',
    key: 'openAI',
    children: <MyFileOpenAI />,
  },
]
