import React from 'react'
import { Button } from 'antd'

interface Props {
  text: string
  linkUrl?: string
  shortenText?: boolean 
}

function NotificationText({ text, linkUrl, shortenText }: Props) {
  return (
    <>
      <p className={`text-slate-500 text-wrap hyphens-auto break-words ${shortenText && "line-clamp-3"}`}>
        {text}
      </p>

      {linkUrl && (
        <div className="mt-1">
          <Button
            href={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="px-3 text-xs cursor-pointer [&>span]:text-white"
            type="primary"
            size="small"
          >
            Ver
          </Button>
        </div>
      )}
    </>
  )
}

export default NotificationText
