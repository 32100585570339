import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { Group } from 'domain/groups/types'

export default function useGroupByNameQuery(name: string | undefined) {
  return useQuery(['groups', 'name', name], async () => {
    const response: AxiosResponse<Group[]> = await AuthorizedAPI.get('/groups', {
      params: {
        'filter[name]': name,
        'per-page': 1,
      },
    })

    return response.data ? response.data[0] : undefined
  }, {
    enabled: !!name
  })
}
