import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs, SelectLocationFields } from 'domain/createOrder/document'
import { CNPJAndNameFields, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'
import config from '../certidaoMPECriminalConfig'

export default function CreateOrderCertidaoMPECriminal() {
  return (
    <CertificateFlow
      header={<SelectLocationFields autoFocus />}
      renderFormItem={(itemId: number) => <CertidaoMPECriminalForm itemId={itemId} />}
    />
  )
}

const CertidaoMPECriminalForm = ({ itemId }: { itemId: number }) => {
  const inputProps = { itemId, autoFocus: itemId === 0 }

  return (
    <CreateOrderLegalEntityTabs
      itemId={itemId}
      fisica={
        <>
          <CPFInput {...inputProps} />
          <NameField itemId={itemId} field={nome_pf} />
        </>
      }
      juridica={<CNPJAndNameFields {...inputProps} companyField={nome_pj} />}
    />
  )
}

const { nome_pf, nome_pj } = config.formFields
