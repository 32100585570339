import { CertificateFlow } from 'domain/createOrder/certificates'
import { useLoadExtraInfos } from 'domain/createOrder/document'
import CertidaoProtestoForm from './CreateOrderCertidaoProtestoForm'

export default function CreateOrderCertidaoProtesto() {
  const { data, isFetching } = useLoadExtraInfos()

  return (
    <CertificateFlow
      header={
        <p className="mb-5 text-gray-600">
          Selecione Pessoa ou Empresa e digite o número do CPF ou CNPJ que deseja a Certidão de Protesto.
        </p>
      }
      renderFormItem={(itemId: number) => (
        <CertidaoProtestoForm itemId={itemId} extraInfos={data} loading={isFetching} />
      )}
    />
  )
}
