import { Customer } from 'domain/customers/types'
import { PrepaidInvoice } from 'domain/invoices/types'
import generateInvoicePDF from 'domain/invoices/helpers/generateInvoicePDF'
import extractDataToExport from './extractDateToExport'

type Params = {
  tableData: PrepaidInvoice[]
  customer: Customer
  month: Date
}

async function generatePrepaidPDF({ customer, month, tableData }: Params) {
  const columns = [
    { header: 'Nome do pedido', dataKey: 'purchase_name' },
    { header: 'Nº do pedido', dataKey: 'purchase_number' },
    { header: 'Data do pedido', dataKey: 'created_at' },
    { header: 'Data de entrega', dataKey: 'delivered_at' },
    { header: 'Créditos', dataKey: 'amount' },
    { header: 'Bônus', dataKey: 'bonus' },
    { header: 'Usuário', dataKey: 'user_name' },
  ]

  return await generateInvoicePDF({ body: extractDataToExport(tableData), columns, customer, month })
}

export default generatePrepaidPDF
