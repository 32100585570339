import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import CertidaoJuntaComercialSelectType from './components/CertidaoJuntaComercialSelectType'

const { TIPO, URL_UF } = FieldName

type CertidaoJuntaComercialFields = Record<
  | FieldName.CNPJ
  | FieldName.RAZAO_SOCIAL
  | FieldName.URL_UF
  | FieldName.TIPO
  | FieldName.NUMERO_ATO,
  FieldConfig
>

class CertidaoJuntaComercial extends Certificate {
  LocationUFType = CertidaoJuntaComercialSelectType

  formFields: CertidaoJuntaComercialFields = {
    url_uf: Fields.url_uf,
    tipo: {
      ...Fields.tipo,
      kind: 'radio',
      ignoreOnKitsAutomaticForm: true,
      defaultValue: 'inteiroteor',
      options: [
        {
          value: 'simples',
          label: 'Simples',
          description: `
            A certidão simplificada emite informações como: nome empresarial, CNPJ, endereço da sede,
            data de início das atividades, objeto social, capital social, sócios e suas respectivas
            participações no capital social, dentre outras.`,
        },
        {
          value: 'inteiroteor',
          label: 'Inteiro Teor',
          description: `
            A certidão de inteiro teor é a cópia digitalizada dos atos arquivados pela empresa na Junta
            Comercial. Ou seja, é a reprodução integral do documento registrado.`,
        },
        {
          value: 'ficha-cadastral',
          label: 'Ficha Cadastral',
          description: `
            Contém informações cadastrais e o histórico de uma empresa, como nome, endereço, atividades
            econômicas e situação legal. É utilizado para confirmar a regularidade e a autenticidade dos
            dados empresariais.`,
        },
      ],
      apiOptionsLabelKey: 'label',
      apiOptionsValueKey: 'value',
    },
    cnpj: Fields.cnpj,
    razao_social: {
      ...Fields.razao_social,
      label: 'Razão Social Completa da Empresa (favor incluir ME, EPP, LTDA, etc)',
      placeholder: 'Nome da empresa',
      kitFieldEquivalent: FieldName.NOME_PJ
    },
    numero_ato: {
      ...Fields.numero_ato,
      ignoreOnKitsAutomaticForm: true,
    },
  }

  constructor() {
    super({
      extraInfoFields: [URL_UF],
      id: 14,
      priceByFields: [TIPO, URL_UF],
      shortName: 'Certidão Junta Comercial',
      splittableField: FieldName.NUMERO_ATO,
      steps: ['localizacao', 'dados'],
      shoudAutoLoadDynamicFields: false,
      extractedReportSections: [
        {
          title: 'Resumo da certidão',
          properties: [
            { label: 'Data do ato', key: 'data_ato' },
            { label: 'Integralização de capital com imóveis', key: 'integralizacao_capital_com_imoveis' },
            { label: 'Objeto social', key: 'objeto_social' },
            { label: 'Tipo societário', key: 'tipo_societario' },
            { label: 'Capital social', key: 'capital_social' },
            { label: 'Sócios acionistas', key: 'socios_acionistas' },
            { label: 'Administração representação', key: 'administracao_representacao' },
            { label: 'Alterações societárias', key: 'alteracoes_societarias' },
          ],
        },
      ],
    })
  }
}

const service = new CertidaoJuntaComercial().initialize()

export default service
