import { useGetAllDynamicFields, useGetServiceForm } from 'domain/createOrder/kit'
import { RadioGroupField } from 'domain/servicesCBRdoc/fields'
import config from '../certidaoCEATConfig'

interface Params {
  itemId: number
}

export default function KitOrderCertidaoCEAT({ itemId }: Params) {
  const { regiao } = useGetServiceForm(itemId)
  const dynamicFields = useGetAllDynamicFields()

  if (!regiao) return null

  const dynamicRegions = dynamicFields[itemId]?.[0]

  if (!dynamicRegions) return null

  const selectedRegion = dynamicRegions.options!.find(r => r.url === regiao.value)
  const models = selectedRegion?.modelos

  if (!models?.length) return null

  return <RadioGroupField field={config.formFields.modelo} options={models} vertical />
}
