import { LabelInValue, LegalEntity } from 'domain/app/types'
import { KitItem, KitLegalEntity } from 'domain/kits/types'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { AllAvailableTypes } from 'domain/servicesCBRdoc/types'
import { CreateOrderForm } from '../types'

export interface KitFieldConfig extends FieldConfig {
  defaultData?: any
  kitItem?: KitItem
}

interface KitServiceSelectField extends KitFieldConfig {
  value: LabelInValue
}

export type LegalEntityFields = {
  fisica: KitFieldConfig[]
  juridica: KitFieldConfig[]
}

export type FederalRegionFields = {
  federalRegion: KitServiceSelectField
  federalRegionAgency: KitServiceSelectField
  federalRegionCertificateFilter: KitServiceSelectField
  federalRegionCertificateType: KitServiceSelectField
}

interface FieldConfigAvailableTypes extends FieldConfig {
  value: AllAvailableTypes
}

interface FieldConfigBoolean extends FieldConfig {
  value: boolean
}

export type KitNotaryFields = {
  [FieldName.URL_UF]: KitServiceSelectField
  [FieldName.URL_CIDADE]: KitServiceSelectField
  [FieldName.URL_CARTORIO]: KitServiceSelectField
  selectedResearchType?: FieldConfigAvailableTypes // Pesquisa de bens
  allNotariesSelected?: FieldConfigBoolean
}

export type FormServiceSpecificFields = {
  notaryFields: KitNotaryFields | undefined
  federalRegionFields: FederalRegionFields | undefined
}

export interface KitServiceFormConfig {
  kitItem: KitItem
  serviceFields: KitFieldConfig[]
}

export type KitCommonFormServiceConfig = {
  kitItem: KitItem,
  fields: KitFieldConfig[]
}

export interface KitCommonFormConfig {
  registreeFields: KitFieldConfig[]
  serviceFields: KitCommonFormServiceConfig[]
}

export type CreateOrderKit = {
  dynamicFields?: Record<number, Record<any, any>>
  kitLegalEntity: KitLegalEntity
  formCommonData?: KitCommonForm
  formServicesData?: KitServicesForm
}

export type CreateOrderKitItem = Omit<CreateOrderKit, 'dynamicFields'> & {
  requiredDynamicFields?: FieldName[] | undefined
}

export type AddFieldFn = (name: string, value: any) => void

export type AllItemsDynamicFieldsConfig = Record<number, FieldConfig[]>

export enum KitOrderStepURI {
  COMMON_DATA = '/detalhamento',
  SERVICE_DATA = '',
}

export type KitCommonForm = {
  registree: CreateOrderForm
  [index: number]: CreateOrderForm
}

export type KitServicesForm = {
  tipo_pessoa?: LegalEntity
  [FieldName.NOME_ITEM_PEDIDO]?: string
  [index: number]: CreateOrderForm
}

export type AddPersonStatus = 'idle' | 'start' | 'reset'

export type GroupValue = `${number}_${FieldName}`
export type SelectFieldName = FieldName | GroupValue

export type NecessaryField = {
  label: string
  value: FieldName | GroupValue | undefined
  id?: number
  optional: boolean
}

export type FieldIdentificationValue = FieldName | GroupValue

export type FieldIdentificationSelectedOption = {
  label: string
  value: FieldIdentificationValue | undefined
}

export type FieldIdentificationOption = {
  label: string
  title?: string
  value?: FieldIdentificationValue
  options?: FieldIdentificationSelectedOption[]
}

export type ColumnIdentification = Partial<Record<SelectFieldName, number>>
