import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoTribunalContasFields = Record<
  FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ,
  FieldConfig
>

class CertidaoTribunalContas extends Certificate {
  formFields: CertidaoTribunalContasFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
  }

  constructor() {
    super({
      id: 63,
      priceByFields: [FieldName.FORMATO],
      shortName: 'TC',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoTribunalContas().initialize()

export default service
