import { useState } from 'react'

import { SecretAPI } from 'api/network/v2'
import { ApiError, ApiStatus } from 'api/types'

const useCheckResetToken = () => {
  const [error, setError] = useState<ApiError | null>(null)
  const [status, setStatus] = useState<ApiStatus>('idle')
  const [data, setData] = useState()

  const checkResetToken = async (token: string) => {
    try {
      setStatus('loading')
      setError(null)
      const response = await SecretAPI.get(`/password-reset/${token}`)
      setData(response.data)
      setStatus('success')
    } catch (error) {
      setStatus('error')
      setError({ message: 'Token inválido', description: 'Envie um novo email para redefinir sua senha' })
    }
  }

  return {
    checkResetToken,
    data,
    error,
    status,
    resetStatus: () => setStatus('idle'),
  }
}

export default useCheckResetToken
