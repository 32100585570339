import React from 'react'
import { Button, ButtonProps } from 'antd'

type Props = Omit<ButtonProps, 'size' | 'type'>

export default function BrandButton({ children, className = '', ...props }: Props) {
  return (
    <Button
      type="primary"
      size="large"
      className={`shadow-none flex flex-row items-center justify-center h-14 gap-2  
        active:shadow-inner focus:bg-primary hover:bg-tint hover:shadow-blue my-4 
        disabled:bg-primary/40 disabled:text-white ${className}`}
      {...props}
    >
      {children}
    </Button>
  )
}
