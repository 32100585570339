import { useParams } from 'react-router-dom'

import { FORBIDDEN } from 'api/errors'
import { ErrorMessage } from 'domain/app'
import { AlertViewOrderPermission, OrderItemError } from 'domain/orderItem'
import { OrderOverview, useHasViewOrderPermission, useOrderOverviewQuery } from 'domain/orders'
import { OrderOverviewPageParams } from 'domain/orders/types'
import { Loading } from 'ui'

export default function OrderOverviewPage() {
  const params = useParams<OrderOverviewPageParams>()
  const hasViewPermission = useHasViewOrderPermission()

  const { data: order, isLoading, error } = useOrderOverviewQuery(params.id, hasViewPermission)

  if (!hasViewPermission || (error as any)?.code === FORBIDDEN) {
    return <AlertViewOrderPermission />
  }

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (!order) {
    return <OrderItemError />
  }

  return <OrderOverview order={order} />
}
