import React from 'react'

import { LabelInValue } from 'domain/app/types'
import { ShoppingCartOrder } from 'domain/shoppingCart/types'
import { getCityNameFederativeUnitText } from '../helpers'

function CartItemLocation({ item }: { item: ShoppingCartOrder }) {
  let text = getCityNameFederativeUnitText(item)

  if (!text) {
    const { url_cidade, url_uf } = item.selectedLocation

    if (url_cidade) {
      const city = (url_cidade as LabelInValue)?.label
      const federativeUnit = Array.isArray(url_uf)
        ? url_uf.map(({ label }) => label).join(', ')
        : url_uf?.label

      if (city && federativeUnit) {
        text = `${city} - ${federativeUnit}`
      } else {
        text = city ?? federativeUnit ?? ''
      }
    }
  }

  return text ? <span title={text}>{text}</span> : null
}

export default CartItemLocation
