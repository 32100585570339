import React, { useEffect, useState } from 'react'

import { PixViewData } from 'ui'

import useGenerateQRCode from '../services/useGenerateQRCode'

function GeneratePixQRCode({ pixKey }: { pixKey: string }) {
  const [qrCodeImage, setQrCodeImage] = useState('')
  const generateQR = useGenerateQRCode()

  useEffect(() => {
    const getCode = async () => {
      if (pixKey) {
        const code = await generateQR(pixKey)
        setQrCodeImage(code!)
      }
    }

    getCode()
  }, [pixKey])

  return <PixViewData qrCode={qrCodeImage} pixKey={pixKey} />
}

export default GeneratePixQRCode
