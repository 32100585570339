import React from 'react'

import {
  useGetItemsTotalResults,
  useItemsCurrentPage,
  useItemsPageSize,
} from 'domain/orders/state/pagination'
import { Pagination } from 'ui'

interface Props {
  topRef: React.RefObject<HTMLDivElement>
}

function OrderItemsPagination({ topRef }: Props) {
  const [itemsCurrentPage, setItemsCurrentPage] = useItemsCurrentPage()
  const [itemsPageSize, setItemsPageSize] = useItemsPageSize()
  const itemsTotalResults = useGetItemsTotalResults()

  const handleChangePage = (page: number) => {
    setItemsCurrentPage(Number(page))
    topRef.current?.scrollIntoView()
  }

  return (
    <Pagination
      className="pb-7"
      totalCount={itemsTotalResults}
      currentPage={Number(itemsCurrentPage)}
      onChangePage={handleChangePage}
      pageSize={itemsPageSize}
      onPageSizeChange={setItemsPageSize}
    />
  )
}

export default OrderItemsPagination
