import { RoutePaths } from 'app/routes'
import {
  RecurrencesList,
  useSetInitialValuesFromURL,
} from 'domain/recurrences'
import { BasicLayout, LearnEverythingSubtitle } from 'layouts'

export default function SettingsRecurrencePage() {
  useSetInitialValuesFromURL()

  return (
    <BasicLayout
      pageTitle="Recorrências"
      subTitle={
        <LearnEverythingSubtitle subtitle="Entenda como funciona a recorrência: " href={RoutePaths.RECURRENCE_TUTORIAL}/>
      }
      innerClass="bg-gray-100"
    >
      <RecurrencesList />
    </BasicLayout>
  )
}
