import { SelectableButton } from 'ui'
import { useExpiredFilter } from '../../state/filters'

export default function FilterExpiredBtn() {
  const [isExpired, setExpiredFilter] = useExpiredFilter()

  return (
    <SelectableButton selected={isExpired} onClick={() => setExpiredFilter(!isExpired)}>
      Vencidos
    </SelectableButton>
  )
}
