import React, { useEffect, useReducer } from 'react'
import { useHistory } from 'react-router-dom'

import { useCurrentUser } from 'domain/auth'
import { Button, Modal, Icon, IconName, Tag } from 'ui'
import { getStorageString, setStorageString } from 'utils/localStorage'

export type FeatureModalConfig = {
  id: string
  label: string
  icon: IconName
  title: string
  texts: string[]
  btnOK: {
    label: string
    href: string
  }
  btnCancel: string
}

interface Props {
  config: FeatureModalConfig
  show: boolean
}

export default function FeatureModal({ config, show }: Props) {
  const userId = useCurrentUser()?.id
  const history = useHistory()
  const [isModalOpen, toggleModal] = useReducer(prev => !prev, false)
  const { btnCancel, btnOK, icon, label, title, texts } = config

  const handleOk = () => {
    toggleModal()
  }

  useEffect(() => {
    if (show && userId) {
      const storageKey = `featured-modal-${userId}`
      const alreadyShown = getStorageString(storageKey)

      if (!alreadyShown || alreadyShown !== config.id) {
        setStorageString(storageKey, config.id)
        toggleModal()
      }
    }
  }, [show, userId])

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={toggleModal}
      footer={null}
      title=""
      className="relative top-24"
      contentClassName="min-h-fit"
    >
      <div className="flex flex-col gap-4">
        <Tag color="success" className="self-start px-4 text-xs" rounded>
          {label}
        </Tag>

        <div className="flex flex-row gap-4 items-center">
          <div>
            <div className="rounded-full bg-primary flex justify-center items-center p-5">
              <Icon name={icon} size={28} color="white" />
            </div>
          </div>
          <h3 className="text-xl text-primary font-bold">{title}</h3>
        </div>

        {texts.map((text, index) => (
          <p className="text-base text-secondary-300" key={index}>{text}</p>
        ))}

        <div className="grid grid-cols-2 gap-6 mt-4">
          <Button
            type="primary"
            size="middle"
            onClick={() => {
              toggleModal()
              history.push(btnOK.href)
            }}
          >
            {btnOK.label}
          </Button>
          <Button type="primary" size="middle" ghost onClick={toggleModal}>
            {btnCancel}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
