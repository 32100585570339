import { useGetOrderItem } from 'domain/orderItem/shared'
import SignaturesProgress from './SignaturesProgress'

export default function DocRegistrationProcessing() {
  const orderItem = useGetOrderItem()

  if (orderItem.submitData.tipo_fluxo_assinaturas === 'sem-assinaturas') {
    return (
      <p className="text-center text-sm">
        Seu pedido já está em andamento. Em breve, teremos novidades.
      </p>
    )
  }

  return <SignaturesProgress />
}
