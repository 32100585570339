import { useMutation } from 'react-query'
import { message } from 'antd'

import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'
import { Kit, KitItem } from 'domain/kits/types'
import { updateItemOnArrayById } from 'utils/transformData'

import { convertKitItemPostAPI } from './convertToAPI'

type MutationParams = {
  kitId: number
  kitItem: KitItem
}

function useEditKitItemtMutation(queryKey: any[]) {
  return useMutation(
    async ({ kitId, kitItem }: any) => {
      await AuthorizedAPI.put(`/kits/${kitId}/items/${kitItem.id}`, convertKitItemPostAPI(kitItem))
    },
    {
      onMutate: async ({ kitItem }: MutationParams) => {
        const previousKit = queryClient.getQueryData(queryKey) as Kit

        // Add optimistic items to kit query
        queryClient.setQueryData(queryKey, {
          ...previousKit,
          items: updateItemOnArrayById(previousKit.items, kitItem),
        })

        // Return context with the optimistic items
        return { previousKit }
      },
      onError: (data, variables, context: any) => {
        message.error('Ocorreu um erro ao tentar editar o kit.')
        queryClient.setQueryData(queryKey, context.previousKit)
      },
      onSuccess: () => {
        message.success('Os dados foram atualizados com sucesso.')
      },
    }
  )
}

export default useEditKitItemtMutation
