import { CertificateFlow } from 'domain/createOrder/certificates'
import {
  CreateOrderLegalEntityTabs,
  SelectLocationFields,
  useCreateOrderState,
} from 'domain/createOrder/document'
import { CNPJAndNameFields, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'
import { getValue } from 'utils/transformData'

import config from '../certidaoNegativaDebitosTributariosPGEConfig'
import InscricaoEstadualField from './InscricaoEstadualField'

export default function CreateOrderCertidaoNegativaDebitosTributariosPGE() {
  return (
    <CertificateFlow
      header={
        <>
          <p className="mb-5 text-gray-600">
            Selecione Pessoa ou Empresa e digite o número do CPF ou CNPJ que deseja a Certidão Negativa de
            Débitos Tributários - PGE.
          </p>

          <SelectLocationFields autoFocus />
        </>
      }
      renderFormItem={(itemId: number) => <CertidaoNegativaDebitosTributariosPGEForm itemId={itemId} />}
    />
  )
}

function CertidaoNegativaDebitosTributariosPGEForm({ itemId }: { itemId: number }) {
  const { selectedLocation } = useCreateOrderState()

  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={
          <>
            <CPFInput itemId={itemId} />
            <NameField itemId={itemId} field={nome_pf} />
          </>
        }
        juridica={
          <>
            <CNPJAndNameFields itemId={itemId} companyField={nome_pj} />
            <InscricaoEstadualField itemId={itemId} federativeUnit={getValue(selectedLocation.url_uf)} />
          </>
        }
      />
    </>
  )
}

const { nome_pf, nome_pj } = config.formFields
