import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import queryClient from 'app/config/queryClient'
import {
  CreateFolder,
  MyFilesAdd,
  MyFilesBreadcrumbs,
  MyFilesContent,
  MyFilesFilter,
  RecentsFiles,
  SearchFiles,
  useExplorerQuery,
  useFilters,
  useSetExplorerItem,
} from 'domain/myFiles'
import { ExplorerList, SortBy } from 'domain/myFiles/types'
import { BasicLayout } from 'layouts'
import { ErrorBoundary, ActionsBar } from 'ui'

export default function MyFilesPage() {
  const { parentId } = useParams<{ parentId: string }>() ?? {}
  const [sortField, setSortField] = useState<string>('mostRecentOperationTime')
  const [sortOrder, setSortOrder] = useState<SortOrder>('descend')
  const setExplorerItem = useSetExplorerItem()
  const paramParentId = parentId ? Number(parentId) : undefined
  const filters = useFilters()

  const {
    data: explorerList,
    isFetching,
    pagination,
  } = useExplorerQuery(
    {
      parentId: paramParentId,
      sortBy: sortOrder && getSortBy(sortOrder, sortField),
      filters,
    },
    {
      onSuccess: (data: ExplorerList) => {
        if (paramParentId) {
          setExplorerItem(data!.breadcrumb![data!.breadcrumb!.length - 1])
        }
      },
    }
  )

  const handleRefetchData = async () => {
    queryClient.invalidateQueries(['my-files', 'list'])
  }

  return (
    <BasicLayout
      pageTitle="Meus arquivos"
      innerClass="flex-col"
      extra={
        <>
          <CreateFolder parentId={paramParentId} onFolderCreated={handleRefetchData} />
          <MyFilesAdd />
        </>
      }
    >
      <main className="flex-1 bg-gray-100 p-7 pt-0 pb-20">
        <ErrorBoundary>
          {explorerList && explorerList.children && explorerList.children.length > 0 && (
            <div className="w-full flex flex-row px-5 py-4 items-center gap-8 bg-white rounded-t">
              <SearchFiles />
              <MyFilesFilter />
            </div>
          )}
        </ErrorBoundary>

        <MyFilesContent
          data={explorerList?.children}
          refetchData={handleRefetchData}
          isFetching={isFetching}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          pagination={pagination}
        />
      </main>
    </BasicLayout>
  )
}

const getSortBy = (sortOrder: SortOrder, sortField: string): SortBy => {
  const sortModifier = sortOrder === 'descend' ? '-' : ''

  if (sortField === 'mostRecentOperationTime') {
    return `type,${sortModifier}last_operation_at` as SortBy
  }

  if (sortField === 'docType') {
    return `${sortModifier}type` as SortBy
  }

  return `${sortModifier}last_operation_at` as SortBy
}

export type SortOrder = 'descend' | 'ascend'
