import React, { useReducer } from 'react'

import { getNoPermissionText, useHasRecurrencePermission } from 'domain/auth'
import { OrderItemPurchasable } from 'domain/orderItem/types'
import { CreateRecurrence } from 'domain/recurrences'
import { ServiceType } from 'domain/servicesCBRdoc/types'
import { DrawerActions } from 'ui'

interface Props {
  orderItems: OrderItemPurchasable[]
  onSuccess: () => void
}

export default function OrderItemsMassRecurrence({ orderItems, onSuccess }: Props) {
  const hasPermission = useHasRecurrencePermission()
  const [isModalOpen, toggleModalOpen] = useReducer(prev => !prev, false)

  const isDocRegistration = orderItems.some(o => o.service.type === ServiceType.DOCUMENT_REGISTRATION)

  if (isDocRegistration || orderItems.length === 0) return null

  const anyItemIsMissingData = orderItems.some(item => item.isFromPassportWithoutAllData)
  return (
    <>
      <DrawerActions.Button
        title={
          anyItemIsMissingData
            ? 'Alguns itens não possuem todos os dados necessários para criar uma recorrência'
            : getNoPermissionText(hasPermission)
        }
        disabled={anyItemIsMissingData || !hasPermission}
        icon="calendar"
        onClick={toggleModalOpen}
      >
        Criar recorrência
      </DrawerActions.Button>

      <CreateRecurrence
        openModal={isModalOpen}
        onSuccess={onSuccess}
        onCloseModal={toggleModalOpen}
        items={orderItems.filter(o => !o.isFromPassportWithoutAllData)}
        kind="order-items"
      />
    </>
  )
}
