import React, { createContext, useContext, useEffect, useState } from 'react'

import { AIModelForm, AIQuestion } from 'domain/openAI/types'

type AIFormContextType = [
  AIModelForm,
  React.Dispatch<React.SetStateAction<AIModelForm>>
]

const AIFormContext = createContext<AIFormContextType>([] as any)

type ProviderProps = { children: React.ReactNode; initialState?: AIModelForm }

const initialQuestion: AIQuestion = {
  id: 0,
  questionToShow: '',
  questionToAi: '',
}

const initialForm = { questions: [initialQuestion] } as AIModelForm

export function AIFormProvider({ children, initialState = initialForm }: ProviderProps) {
  const formState = useState(initialState)

  return <AIFormContext.Provider value={formState}>{children}</AIFormContext.Provider>
}

export const useAIFormContext = () => useContext(AIFormContext)
export const useResetAIForm = () => {
  const [, setForm] = useContext(AIFormContext)

  useEffect(() => {
    setForm(initialForm)
  }, [])
}
