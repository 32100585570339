export enum RoutePaths {
  CREDITS = '/creditos',
  CONTACT_EXTERNAL = 'https://suporte.cbrdoc.com.br/hc/pt-br/requests/new',
  HOME = '/',
  HELP_EXTERNAL = 'https://suporte.cbrdoc.com.br/hc/pt-br',
  INVOICES = '/faturas',
  OAUTH = '/oauth',
  REPORTS = '/relatorios',
  SETTINGS = '/configuracoes',
  SHARED_ORDER = '/pedido-compartilhado',

  // Login e Criar conta
  REGISTER_CNPJ = '/criar-conta-empresa',
  REGISTER_CNPJ_2 = '/criar-conta-empresa/:cnpj',
  REGISTER_CPF = '/criar-conta-pessoal',
  REGISTER_CPF_2 = '/criar-conta-pessoal/:cpf',
  FORGOT_PASSWORD = '/esqueci-minha-senha',
  NEW_PASSWORD = '/redefinir-senha',
  LOGIN = '/login',

  // Criar pedido
  CREATE_ORDER = '/fazer-pedido',
  CREATE_ORDER_AI = '/fazer-pedido/ia-extracao-dados',
  CREATE_ORDER_BY_SPREADSHEET = '/purchases/spreadsheet',
  CREATE_ORDER_DOCUMENT = '/fazer-pedido/documentos',
  CREATE_ORDER_DOCUMENT_REGISTRATION = '/fazer-pedido/registro',
  CREATE_ORDER_DUE_DILIGENCE = '/fazer-pedido/diligencias',
  CREATE_ORDER_RESEARCH = '/fazer-pedido/pesquisas',
  CREATE_ORDER_SIGNATURES_CERTIFICATES = '/fazer-pedido/signatures-certificates',
  CREATE_ORDER_KIT = '/fazer-pedido-kit/:id',
  CREATE_ORDER_SERVICE = '/fazer-pedido/:serviceCode',
  CREATE_ORDER_SHEET = '/fazer-pedido-via-planilha',
  CREATE_ORDER_SHEET_VALIDATE = '/purchases/spreadsheet/validate',
  DOWNLOAD_SPREADSHEET_SERVICE = '/purchases/spreadsheet/download-template/service',
  DOWNLOAD_SPREADSHEET_KIT = '/purchases/spreadsheet/download-template/kit',
  PURCHASED_WITH_SUCCESS = '/carrinho/sucesso',
  PURCHASED_WITH_ERROR = '/carrinho/erro',
  SHOPPING_CART = '/carrinho',
  UPLOAD = '/orders/temp-file',
  UPLOAD_EXPLORER_ITEM = '/explorer/upload',

  // Pedidos
  ORDERS = '/pedidos',
  ORDER_ITEMS = '/itens-pedido/:id',
  VIEW_ORDER = '/pedido/:id',

  // Extração de Dados
  AI_EDIT_MODEL = '/modelo-ia/:id',

  // kits
  KITS = '/meus-kits',
  CREATE_KIT = '/meus-kits/criar-kit',
  CREATE_KIT_SUCCESS = '/meus-kits/sucesso',
  VIEW_KIT = '/meus-kits/kit/:id',
  EDIT_KIT = '/meus-kits/editar/:id',
  KITS_TUTORIAL = 'https://suporte.cbrdoc.com.br/hc/pt-br/articles/4561679122203-Kit-de-Documentos',

  // Arquivos
  MY_FILES = '/meus-arquivos',
  MY_FILES_2 = '/meus-arquivos/pastas/:parentId',
  VIEW_FILE = '/meus-arquivos/arquivo/:id',

  // Recorrências
  RECURRENCIES = '/recorrencias',
  RECURRENCY_EDIT = '/recorrencias/:id',
  RECURRENCE_TUTORIAL = 'https://suporte.cbrdoc.com.br/hc/pt-br/articles/7816572836507-Como-criar-uma-recorr%C3%AAncia',

  // Notificações
  NOTIFICATIONS = '/notificacoes',
}

export default RoutePaths
