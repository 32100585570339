import React, { useCallback, useMemo } from 'react'
import { Collapse } from 'antd'

import { OrderNameField, useCreateOrderState } from 'domain/createOrder/document'
import { CreateOrderFormItem, SelectedService } from 'domain/createOrder/types'
import { ServiceType } from 'domain/servicesCBRdoc/types'

import useAutoFocusMultipleItems from '../helpers/useAutoFocusMultipleItems'
import DeleteItem from './DeleteItem'
import { getServiceConfig } from 'domain/servicesCBRdoc'

interface Props {
  activeItem: number | undefined
  setActiveItem: React.Dispatch<React.SetStateAction<number | undefined>>
  items: CreateOrderFormItem[]
  service: SelectedService
  renderFormItem: (itemId: number) => JSX.Element
  noRenderOrderName?: boolean
}

export default function ItemsCollapse({
  activeItem,
  setActiveItem,
  items,
  service,
  renderFormItem,
  noRenderOrderName,
}: Props) {
  useAutoFocusMultipleItems(activeItem)
  const { formFields, multipleItemsCollapseHeaderTitleField: headerField } = getServiceConfig(service.code!)
  const { formData } = useCreateOrderState()

  const generateItemLabel = useCallback(
    (index: number) => {
      const typeTitle = Title[service.type!] || 'Item'
      if (headerField) {
        const field = formFields[headerField]!
        const headerValue = formData[index]?.[field.name]
        if (headerValue) {
          return `${typeTitle} (${index + 1}) - ${headerValue}`
        }
      }

      return `${typeTitle} (${index + 1})`
    },
    [formData, headerField, service]
  )

  const collapseItems = useMemo(() => {
    return items.map((item, index) => ({
      key: item.id,
      forceRender: true,
      label: generateItemLabel(index),
      children: (
        <>
          {renderFormItem(item.id)}
          {!noRenderOrderName && <OrderNameField itemId={item.id} />}
        </>
      ),
      extra: items.length > 1 ? <DeleteItem itemId={item.id} /> : undefined,
    }))
  }, [items])

  return (
    <Collapse
      accordion
      expandIconPosition="end"
      className="spaced-collapse"
      activeKey={activeItem}
      onChange={(key: any) => setActiveItem(parseInt(key))}
      items={collapseItems}
    />
  )
}

const Title: Partial<Record<ServiceType, string>> = {
  [ServiceType.CERTIFICATE]: 'Certidão',
  [ServiceType.RESEARCH]: 'Pesquisa',
  [ServiceType.SIGNATURES_CERTIFICATES]: 'Pessoa',
}
