import React from 'react'
import { Form, Modal as ModalAntd } from 'antd'

import { useMyFileMutation } from 'domain/myFiles/services'
import { useGetExplorerItem } from 'domain/myFiles/state'
import { SubmitButton } from 'ui'

import DocumentTypeSelect from '../register/ServiceSelect'

interface Props {
  onFinish?: () => void
}

export default function DocumentTypeForm({ onFinish }: Props) {
  const [form] = Form.useForm()
  const mutation = useMyFileMutation()
  const explorerItem = useGetExplorerItem()

  const handleFinish = (formData: any) => {
    const hasAIData = !!explorerItem!.aiModel?.hasAnswers

    const saveData = async () => {
      mutation.mutate(formData.serviceId)
      onFinish && onFinish()
    }

    if (hasAIData) {
      ModalAntd.confirm({
        title: 'Atenção',
        content: (
          <div className="my-4">
            <p className="mb-3">
              <strong>Seus dados de IA serão perdidos ao completar essa alteração.</strong>
            </p>

            <p>Tem certeza que deseja continuar?</p>
          </div>
        ),
        okText: 'Sim, alterar',
        cancelText: 'Cancelar',
        onOk: saveData,
        closable: true,
      })
    } else {
      saveData()
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      requiredMark={false}
      scrollToFirstError
      className="w-full max-w-md"
      initialValues={{ serviceId: explorerItem!.serviceId }}
    >
      <DocumentTypeSelect name="serviceId" />

      <SubmitButton label="Alterar" className="h-11 text-xs mt-1" marginTop="mt-2" />
    </Form>
  )
}
