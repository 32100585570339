import { CertificateFlow } from 'domain/createOrder/certificates'
import { useCreateOrderState, useUpdateSplittableQuantity } from 'domain/createOrder/document'
import { CNPJAndNameFields } from 'domain/servicesCBRdoc/fields'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'

import config from '../certidaoJuntaComercialConfig'
import CertidaoJuntaComercialActNumberInput from './CertidaoJuntaComercialActNumberInput'

export default function CreateOrderCertidaoJuntaComercial() {
  return (
    <CertificateFlow
      renderFormItem={(itemId: number) => <CertidaoJuntaComercialForm itemId={itemId} />}
    />
  )
}

const CertidaoJuntaComercialForm = ({ itemId }: { itemId: number }) => {
  return (
    <>
      <CNPJAndNameFields itemId={itemId} companyField={razao_social} autoFocus={itemId === 0} />
      <ActNumberInput itemId={itemId} />

      <hr className="my-5" />
    </>
  )
}

function ActNumberInput({ itemId }: { itemId: number }) {
  const { selectedType, submitData } = useCreateOrderState()
  const updateQuantity = useUpdateSplittableQuantity(itemId, numero_ato.name as FieldName)

  return (
    <CertidaoJuntaComercialActNumberInput
      name={[itemId, numero_ato.name]}
      type={selectedType as string}
      onChangeValue={updateQuantity}
      required={!!submitData?.pesquisa_origem_id}
    />
  )
}

const { numero_ato, razao_social } = config.formFields
