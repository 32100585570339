import { AddressFields, DateField, NameField, SplittableField, TextField } from 'domain/servicesCBRdoc/fields'
import { useUpdateSplittableQuantity } from 'domain/createOrder/document'
import { useCreateOrderState } from 'domain/createOrder/document'
import { CPFOrCNPJInput } from 'ui'
import { AllExtraInfos } from 'domain/orderItem/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { Spin } from 'antd'
import config from '../certidaoImovelConfig'

interface Props {
  itemId: number
  loading: boolean
  extraInfos: AllExtraInfos | undefined
}
export default function CreateOrderCertidaoImovelForm({ itemId, extraInfos, loading }: Props) {
  const { selectedType } = useCreateOrderState()

  return (
    <Spin spinning={loading}>
      {extraInfos?.['campos-necessarios']?.map((name: FieldName) => {
        if (matricula.name === name) {
          return <CreateOrderMatriculaInput key="matricula" itemId={itemId} />
        }

        const field = dynamicFields.find(field => field.apiName === name || field.name === name)

        if (!field || field.name === 'cep') return null

        if (field.kind === 'date') {
          return <DateField field={field} key={field.apiName} itemId={itemId} />
        }

        if (field.kind === 'name') {
          return <NameField field={field} key={field.apiName} itemId={itemId} />
        }

        if (field.kind === 'cpfOrCnpj') {
          return <CPFOrCNPJInput key={field.apiName} name={field.name} itemId={itemId} required />
        }

        if (field.kind === 'fullAddress') {
          return <AddressFields key={field.apiName} autoFocus={false} itemId={itemId} />
        }

        return <TextField field={field} key={field.apiName} itemId={itemId} />
      })}

      {selectedType === 'quesitos' && (
        <TextField
          field={config.formFields.observacao}
          itemId={itemId}
          required
          placeholder="Observações"
        />
      )}
    </Spin>
  )
}

function CreateOrderMatriculaInput({ itemId }: { itemId: number }) {
  const onChangeMatriculas = useUpdateSplittableQuantity(itemId, FieldName.MATRICULA)

  return <SplittableField field={matricula} itemId={itemId} onChangeValue={onChangeMatriculas} />
}

const { matricula } = config.dynamicFields
const dynamicFields = Object.values(config.dynamicFields)
