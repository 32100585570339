import React from 'react'

import { OCRDocument as OCRDocumentType } from 'domain/myFiles/types'
import { AlertFullPage } from 'ui'

interface Props {
  ocr: OCRDocumentType | null | undefined
  sidebar?: React.ReactNode
}

export default function OpenAILayout({ ocr, sidebar }: Props) {
  if (!ocr) {
  return (
      <AlertFullPage
        title="Dados insuficientes para a Extração de Dados"
        description="Este documento não possui transcrição, ou os dados ainda estão em processamento."
      />
    )
  }

  return (
    <div className="w-full">
      <div className="flex flex-1 flex-col">{sidebar}</div>
    </div>
  )
}
