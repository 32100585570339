import { AxiosResponse } from 'axios'
import React, { useEffect, useReducer, useState } from 'react'

import { AuthorizedAPI } from 'api/network/v2'
import { getNoPermissionText, useHasRecurrencePermission } from 'domain/auth'
import { useIsCustomerPostPaid } from 'domain/customers'
import { OrderList } from 'domain/orders/types'
import { convertOrderItemListing } from 'domain/orderItem'
import { OrderItemAPI } from 'domain/orderItem/typesAPI'
import { OrderItemListing } from 'domain/orderItem/types'
import { CreateRecurrence } from 'domain/recurrences'
import { DrawerActions } from 'ui'

interface Props {
  selected: OrderList[]
  onClose: () => void
}

export default function OrdersMassRecurrence({ selected, onClose }: Props) {
  const isPostPaid = useIsCustomerPostPaid()
  const hasPermission = useHasRecurrencePermission()
  const [openModal, toggleModal] = useReducer(prev => !prev, false)
  const [allOrderItems, setAllOrderItems] = useState<OrderItemListing[]>([])
  const [isFetchingAll, setIsFetchingAll] = useState(false) 

  useEffect(() => {
    const fetchAllOrderItems = async () => {
      setIsFetchingAll(true)
      try {
        const itemsResults = await Promise.all(selected.map(({ id }) => fetchOrder(id, isPostPaid)))
        setAllOrderItems(itemsResults.flat())
      } catch (error) {
        console.error('Error fetching order items:', error)
      } finally {
        setIsFetchingAll(false)
      }
    }
    fetchAllOrderItems()
  }, [selected])

  const anyItemIsMissingData = allOrderItems.some(item => item.isFromPassportWithoutAllData)

  return (
    <>
      <DrawerActions.Button
        title={
          selected.length > 1
            ? 'Para criar uma recorrência, selecione apenas um pedido'
            : anyItemIsMissingData
              ? 'Alguns itens não possuem todos os dados necessários para criar uma recorrência'
              : getNoPermissionText(hasPermission)
        }
        disabled={isFetchingAll || anyItemIsMissingData || !hasPermission || selected.length > 1}
        onClick={toggleModal}
        icon="calendar"
      >
        Criar Recorrência
      </DrawerActions.Button>

      <CreateRecurrence
        items={selected}
        kind="orders"
        openModal={openModal}
        onCloseModal={toggleModal}
        onSuccess={onClose}
      />
    </>
  )
}

const fetchOrder = async (orderId: number, isPostPaid?: boolean) => {
  const response: AxiosResponse<OrderItemAPI[]> = await AuthorizedAPI.get('/orders', {
    params: {
      append: ['ai_service_name', 'has_ai_extracted_data', 'register', 'is_expired'],
      include: ['groups', 'ocr', 'service', 'service_category', 'user'],
      filter: {
        purchase_id: orderId,
      },
    },
  })

  return response.data?.map(item => convertOrderItemListing(item, isPostPaid!))
}
