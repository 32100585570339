import { Spin } from 'antd'
import { UseQueryResult } from 'react-query'
import { useDispatch } from 'react-redux'

import { createOrderActions } from 'domain/createOrder/document'
import { RadioGroupField } from 'domain/servicesCBRdoc/fields'
import config from '../certidaoSPUConfig'

interface Props {
  query: UseQueryResult
  itemId: number
  autoFocus?: boolean
}

export default function CreateOrderCertidaoSPUType({ query, itemId, autoFocus }: Props) {
  const dispatch = useDispatch()
  const { data, isFetching, isError } = query

  const onChange = (value: any) => {
    dispatch(
      createOrderActions.setMultipleItemsType({
        id: itemId,
        value,
      })
    )
  }

  return (
    <Spin spinning={!data && isFetching}>
      <RadioGroupField
        field={tipo}
        onChange={onChange}
        options={(data as any)?.[tipo.apiExtraInfo!]}
        itemId={itemId}
        hasError={isError}
        autoFocus={autoFocus}
      />
    </Spin>
  )
}

const { tipo } = config.formFields
