import { Customer } from 'domain/customers/types'
import { ShippingAddress } from '../types'

function extractCustomerAddress(customer: Customer): ShippingAddress | null {
  if (!customer.addressZipcode) return null

  return {
    id: 'main',
    name: 'Principal',
    address_zip_code: customer.addressZipcode,
    address_neighborhood: customer.addressNeighborhood,
    address_public_place: customer.address,
    address_number: customer.addressNumber,
    address_city: customer.addressCity,
    address_uf: customer.addressState,
    address_complement: customer.addressComplement || '',
  }
}

export default extractCustomerAddress
