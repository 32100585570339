import { CreateOrderLegalEntityTabs } from 'domain/createOrder/document'
import { ResearchFlow } from 'domain/createOrder/researches'
import { CNPJAndNameFields, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../pesquisaProcuracaoConfig'

export default function CreateOrderPesquisaProcuracao() {
  return (
    <ResearchFlow
      header={
        <p className="mb-5 text-gray-600">
          Informe o CPF ou CNPJ que deseja pesquisar se possui procurações (exceto para o estado de São
          Paulo).
        </p>
      }
      renderFormItem={(itemId: number) => <PesquisaProcuracaoForm itemId={itemId} />}
    />
  )
}

function PesquisaProcuracaoForm({ itemId }: { itemId: number }) {
  return (
    <CreateOrderLegalEntityTabs
      itemId={itemId}
      fisica={
        <>
          <CPFInput itemId={itemId} autoFocus={itemId === 0} />
          <NameField itemId={itemId} field={nome_pf} />
        </>
      }
      juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} autoFocus={itemId === 0} />}
    />
  )
}

const { nome_pf, nome_pj } = config.formFields
