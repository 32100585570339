import { useDispatch } from 'react-redux'
import { createOrderActions } from './createOrderSlice'

export default function useDeleteItem(itemId: number, onDeleted?: (itemId: number) => void) {
  const dispatch = useDispatch()

  return function confirm() {
    dispatch(createOrderActions.deleteFormItem(itemId))
    onDeleted && onDeleted(itemId)
  }
}