import React from 'react'

import { useSelectedMonth } from 'domain/invoices/state'
import { MonthPicker } from 'ui'

function PrepaidInvoicesFiltersMonth() {
  const [selectedMonth, setSelectedMonth] = useSelectedMonth()

  return (
    <div className="mb-5 md:mb-0 md:mr-5">
      <div className="font-bold text-gray-600 text-2xs mb-1 uppercase">Mês/Ano</div>
      <MonthPicker
        value={selectedMonth}
        onChange={date => setSelectedMonth(new Date(date))}
      />
    </div>
  )
}

export default PrepaidInvoicesFiltersMonth
