import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

import { atomURI } from 'utils/navigation'
import { NotificationType } from '../types'

const isHydratedAtom = atom(false)
const filterByStatus = atom<'unread' | undefined>(undefined)
const filterByType = atom<NotificationType | undefined>(undefined)
const filterByStatusURI = atomURI(filterByStatus, 'status')
const filterByTypeURI = atomURI(filterByType, 'type')
const hasFiltersAtom = atom(get => get(filterByType) !== undefined || get(filterByStatus) !== undefined)

const clearFilters = atom(() => null, (get, set) => {
  set(filterByStatusURI, undefined)
  set(filterByTypeURI, undefined)
})

export const useFilterByStatus = () => useAtom(filterByStatusURI)
export const useFilterByType = () => useAtom(filterByTypeURI)
export const useIsHydrated = () => useAtom(isHydratedAtom)

export const useGetFilterByStatus = () => useAtomValue(filterByStatus)
export const useGetFilterByType = () => useAtomValue(filterByType)
export const useGetIsHydrated = () => useAtomValue(isHydratedAtom)
export const useHasFilters = () => useAtomValue(hasFiltersAtom)

export const useSetStatusFilter = () => useSetAtom(filterByStatusURI)
export const useSetTypeFilter = () => useSetAtom(filterByTypeURI)

export const useClearNotificationFilters = () => useSetAtom(clearFilters)
