import { AuthorizedAPI } from 'api/network/v2'
import { AxiosResponse } from 'axios'
import { PrepaidInvoicesReportAPI } from 'domain/invoices/types'
import convertPrepaidInvoiceReport from './convertPrepaidInvoiceReport'

async function getPrepaidInvoicesReport(year: number, month: number) {
  const response: AxiosResponse<PrepaidInvoicesReportAPI[]> = await AuthorizedAPI.get(
    `/reports/invoices/${year}/${month}`
  )

  return response.data.map((invoice, index) => convertPrepaidInvoiceReport(index, invoice))
}

export default getPrepaidInvoicesReport
