import { LabelInValue } from 'domain/app/types'
import { SelectedService } from 'domain/createOrder/types'
import { KitLegalEntity } from 'domain/kits/types'
import { getLocationOf, getServiceConfig } from 'domain/servicesCBRdoc'
import { FederativeUnitSelect, FederativeUnitSelectProps } from 'ui'

import { useServiceFederativeUnitsQuery } from '../../services'
import { FieldName } from '../types'

export interface LocationFederativeUnitSelectProps extends Omit<FederativeUnitSelectProps, 'suffix'> {
  service: SelectedService
  legalEntity: KitLegalEntity | undefined
  onChange?: (selected: LabelInValue | LabelInValue[] | undefined) => void
}

export default function LocationFederativeUnitSelect({
  service,
  name = FieldName.URL_UF,
  legalEntity,
  ...props
}: LocationFederativeUnitSelectProps) {
  const { data, isFetching, isError } = useServiceFederativeUnitsQuery(service, legalEntity)
  const { formFields } = getServiceConfig(service.code!)

  return (
    <FederativeUnitSelect
      name={name}
      suffix={getLocationOf(service.type!)}
      loading={isFetching}
      hasError={isError}
      options={data ?? []}
      multiple={formFields.url_uf?.multiple}
      {...props}
    />
  )
}
