import React from 'react'
import { useLocation, useHistory } from 'react-router'
import { Button } from 'antd'

import { RoutePaths } from 'app/routes'
import { PurchaseWithErrorState } from 'domain/createOrder/types'
import { Alert, Icon } from 'ui'
import { displayDateAndTime } from 'utils/dateTime'

export default function AfterPurchaseWithError() {
  const history = useHistory()
  const locationState = useLocation<PurchaseWithErrorState>().state
  const hideCartButton = origin !== 'cart'

  return (
    <div className="p-7 flex flex-col flex-1 bg-gray-200 justify-center items-center">
      <div className="pt-5 border-t border-gray-300 max-w-md mx-auto">
        <Alert
          type="error"
          icon={<Icon name="cross" />}
          className="my-8 mb-2 text-gray-700"
          message={`Houve um erro ao efetuar sua compra. ${
            hideCartButton ? '' : 'Você pode ir ao carrinho e tentar novamente.'
          }`}
        />
        {locationState?.message && <WhatWentWrong message={locationState.message} />}
        {locationState?.requestID && (
          <div className="text-xs text-center text-gray-500 mb-8">{locationState.requestID}</div>
        )}
        <div className="text-xs text-center text-gray-500 mb-8">{displayDateAndTime(new Date())}</div>
      </div>

      {hideCartButton ? (
        <Button type="primary" onClick={() => history.goBack()} className="mt-8 mb-20 w-64">
          Voltar
        </Button>
      ) : (
        <Button
          type="primary"
          icon="cart"
          onClick={() => history.push(RoutePaths.SHOPPING_CART)}
          className="mt-8 mb-20 w-64"
        >
          Ir para o carrinho
        </Button>
      )}
    </div>
  )
}

function WhatWentWrong({ message }: { message: string }) {
  return <p className="my-5 font-bold text-red-500">{message}</p>
}
