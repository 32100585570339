import React, { useMemo } from 'react'

import { useAllServices } from 'domain/servicesCBRdoc'
import { SelectField } from 'ui'

interface Props {
  name: string
}

function ServiceSelect({ name }: Props) {
  const services = useAllServices()

  const options = useMemo(() => {
    return [{ id: 0, name: 'Outros' }, ...services]
  }, [services])

  return (
    <SelectField
      name={name}
      label="Documento"
      placeholder="Selecione o tipo de documento do upload"
      size="middle"
      options={options}
      optionLabelKey="name"
      optionValueKey="id"
      required
      allowClear={false}
    />
  )
}

export default ServiceSelect
