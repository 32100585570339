import { KitItem, KitItemDefaultData, KitLegalEntity } from 'domain/kits/types'
import { CertificateCode, ResearchCode } from 'domain/servicesCBRdoc/types'

import KitItemLocationFields from './KitItemLocationFields'

interface Props {
  kitLegalEntity: KitLegalEntity
  item: KitItem
  onChange: (data: Partial<KitItemDefaultData>) => void
}

export default function KitItemFormContent({ kitLegalEntity, item, onChange }: Props) {
  switch (item.service.code) {
    case CertificateCode.CERTIDAO_AMBIENTAL:
    case CertificateCode.CERTIDAO_CASAMENTO:
    case CertificateCode.CERTIDAO_DEBITOS_ESTADUAIS:
    case CertificateCode.CERTIDAO_DEBITOS_MUNICIPAIS:
    case CertificateCode.CERTIDAO_EMPRESA_CARTORIO_PJ:
    case CertificateCode.CERTIDAO_ESCRITURA:
    case CertificateCode.CERTIDAO_IMOVEL:
    case CertificateCode.CERTIDAO_INTERDICAO:
    case CertificateCode.CERTIDAO_JUNTA_COMERCIAL:
    case CertificateCode.CERTIDAO_JUSTICA_ESTADUAL:
    case CertificateCode.CERTIDAO_MPE_CIVIL:
    case CertificateCode.CERTIDAO_MPE_CRIMINAL:
    case CertificateCode.CERTIDAO_NASCIMENTO:
    case CertificateCode.CERTIDAO_NEGATIVA_DEBITOS_TRIBUTARIOS_PGE:
    case CertificateCode.CERTIDAO_NEGATIVA_FGTS:
    case CertificateCode.CERTIDAO_OBITO:
    case CertificateCode.CERTIDAO_PREVIA_MATRICULA:
    case CertificateCode.CERTIDAO_PROCURACAO:
    case CertificateCode.CERTIDAO_PROTESTO:
    case ResearchCode.PESQUISA_BENS:
    case ResearchCode.PESQUISA_DIVIDA_ATIVA_PGE:
    case ResearchCode.PESQUISA_JUNTA_COMERCIAL:
    case ResearchCode.PESQUISA_SINTEGRA:
      return <KitItemLocationFields item={item} legalEntity={kitLegalEntity} onChange={onChange as any} />

    default:
      return (
        <p className="text-secondary-500 font-bold mb-3">
          Não existem dados a serem fixos para este tipo de documento.
        </p>
      )
  }
}
