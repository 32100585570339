import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoAtasNotariaisFields = Record<
  | FieldName.ARQUIVOS 
  | FieldName.URL_UF 
  | FieldName.URL_CIDADE 
  | FieldName.URL_CARTORIO 
  | FieldName.TIPO_SERVICO 
  | FieldName.MENSAGEM 
  | FieldName.LOCAL_SERVICO,
  FieldConfig
>

class CertidaoAtasNotariais extends Certificate {
  formFields: CertidaoAtasNotariaisFields = {
    arquivos: {
      ...Fields.arquivos,
      dataScope: 'service',
      uploadMultiple: true,
      optional: false,
    },
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    url_cartorio: Fields.url_cartorio,
    tipo_servico: Fields.tipo_servico,
    mensagem: Fields.mensagem,
    local_servico: {
      ...Fields.local_servico,
      label: 'Onde o tabelião deve ir para registrar os fatos?',
      labelDescription: 'Informe o local ou endereço na qual deseja que seja feita a Ata Notarial',
      showIf: (formData: any) => formData.tipo_servico === 'diligencia-presencial-do-tabeliao',
    },
  }

  constructor() {
    super({
      id: 111,
      shortName: 'Certidão Atas Notariais',
      steps: ['dados'],
      priceByFields: [FieldName.URL_CARTORIO, FieldName.URL_CIDADE, FieldName.URL_UF, FieldName.TIPO_SERVICO],
      availableFormatsFields: [FieldName.URL_CARTORIO, FieldName.URL_CIDADE, FieldName.URL_UF],
      displayIsNewTag: true,
    })
  }
}

const service = new CertidaoAtasNotariais().initialize()

export default service
