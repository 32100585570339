import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { CustomerAPI } from 'domain/customers/types'
import { useGetLatestStorageOperation } from '../state'

function useStorageUsageQuery() {
  const latestStorageOperation = useGetLatestStorageOperation()

  return useQuery(['storage-usage', latestStorageOperation], async () => {
    const { data }: AxiosResponse<CustomerAPI> = await AuthorizedAPI.get('/customers', {
      params: {
        append: 'used_storage',
      },
    })

    return data?.used_storage
  })
}

export default useStorageUsageQuery
