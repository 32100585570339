import React, { useReducer, useState } from 'react'
import { Button } from 'antd'

import { SimpleHelpForm } from 'domain/app'
import { useStorageUsageQuery } from 'domain/myFiles/services'
import { StorageUsage } from 'domain/myFiles/types'
import { Modal, StatisticCard, SuccessModalMessage } from 'ui'
import Icon from 'ui/Icon/Icon'
import { formatNumber } from 'utils/formatters'

function StorageUsageCard() {
  const [showHelp, toggleShowHelp] = useReducer(prev => !prev, false)
  const [messageSent, setMessageSent] = useState(false)
  const { data } = useStorageUsageQuery()

  if (!data) return null

  const [quantity, unit] = getUsageToDisplay(data)

  const onCloseModal = () => {
    setMessageSent(false)
    toggleShowHelp()
  }

  return (
    <StatisticCard
      title="Armazenamento"
      value={
        <>
          {formatNumber(quantity)}
          <span className="text-sm pl-1">{unit}</span>
        </>
      }
    >
      {!quantity && (
        <>
          <Button type="link" icon={<Icon name="help-circle" />} onClick={toggleShowHelp} />

          <Modal title="Upload de arquivos" open={showHelp} onCancel={onCloseModal} destroyOnClose>
            {messageSent ? (
              <SuccessModalMessage title="Mensagem enviada!" onClick={onCloseModal}>
                <p className="my-7">
                  Nossa equipe analisará a sua questão e lhe retornará o mais breve possível.
                </p>
              </SuccessModalMessage>
            ) : (
              <>
                <p className="text-sm mb-4">
                  Habilite a funcionalidade de upload de arquivos, dentro da página “Meus arquivos”.
                </p>
                <p className="text-sm mb-4">
                  Armazene qualquer documento em um só lugar e conte com a tecnologia de OCR da CBRdoc para
                  poder passar seus PDF’s para texto e fazer pesquisas dentro de qualquer documento.
                </p>

                <p className="text-sm mb-4">
                  Quer saber mais? Mande uma mensagem no formulário abaixo ou entre em contato com nosso time
                  comercial através do telefone 4020-1716 (para todo o Brasil).
                </p>

                <SimpleHelpForm subject="Armazenamento" onSuccess={() => setMessageSent(true)} />
              </>
            )}
          </Modal>
        </>
      )}
    </StatisticCard>
  )
}

function getUsageToDisplay(usage: StorageUsage) {
  if (usage.GB >= 1) return [usage.GB, 'GB']
  if (usage.MB >= 1) return [usage.MB, 'MB']
  return [usage.B, 'B']
}

export default StorageUsageCard
