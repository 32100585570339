import React from 'react'

import AlertFullPage from './AlertFullPage'
import Icon from 'ui/Icon'

interface Props {
  title: string
  description?: string
}

const AlertFilterNoResults = ({title, description}: Props) => {
  return (
    <AlertFullPage
      title={title}
      description={description}
      icon={<Icon name="search" size={80} className="mb-4 mx-auto text-6xl text-secondary-500" />}
    />
  )
}

export default AlertFilterNoResults