import React from 'react'

import { InvoicesSummaryContainer } from 'domain/invoices/components'
import { useGetSelectedMonth } from 'domain/invoices/state'
import { StatisticCard } from 'ui'
import { formatMoney } from 'utils/formatters'
import usePostpaidSummaryQuery from '../services/usePostpaidSummaryQuery'

function PostpaidInvoicesSummary({ children }: { children?: React.ReactNode }) {
  const selectedMonth = useGetSelectedMonth()

  const { data: stats, isFetching } = usePostpaidSummaryQuery(selectedMonth)

  return (
    <InvoicesSummaryContainer loading={isFetching}>
      <StatisticCard title="Nota de Débito" value={formatMoney(stats?.debit.sum)} />
      <StatisticCard title="Nota Fiscal" value={formatMoney(stats?.fiscal.sum)} />
      <StatisticCard
        title="Total"
        value={<span className="text-green-500">{formatMoney(stats?.total.sum ?? 0)}</span>}
      />
      {children}
    </InvoicesSummaryContainer>
  )
}

export default PostpaidInvoicesSummary
