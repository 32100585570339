import React from 'react'

import {
  ViewOrderResearchActionsInline,
  ViewResearchActions,
  ViewResearchWithResults,
} from 'domain/orderItem'
import { ResultDescription } from 'domain/orderItem/research'
import { ExcelGenerator, TableColumn } from 'domain/orderItem/types'
import { ResultCode } from 'domain/orders/types'

export default function ViewPesquisaBens() {
  return (
    <ViewResearchWithResults columns={columns} actions={<ViewResearchActions excel={excelGenerator} />} />
  )
}

const columns: TableColumn[] = [
  {
    title: 'Cidade',
    dataIndex: ['url_cidade', 'label'],
    className: 'font-bold',
  },
  {
    title: 'Estado',
    dataIndex: ['url_uf', 'label'],
  },
  {
    title: 'Cartório',
    dataIndex: ['url_cartorio', 'label'],
  },
  {
    title: 'Matrícula',
    dataIndex: 'matricula',
    render: (registration: string) => registration ?? '-',
  },
  {
    title: 'Resultado',
    dataIndex: 'resultado',
    render: (result: ResultCode) => <span>{ResultDescription[result] ?? result}</span>,
  },
  {
    title: 'Proprietário',
    dataIndex: 'proprietario',
    className: 'text-center',
    render: (isOwner: boolean) => <span>{isOwner ? 'Sim' : 'Não'}</span>,
  },
  {
    title: '',
    dataIndex: 'actions',
    align: 'right',
    render: (_, result) => <ViewOrderResearchActionsInline result={result} />,
  },
]

const excelGenerator: ExcelGenerator = {
  columns: [...columns].splice(0, columns.length - 1),
  transform: {
    matricula: (value: string) => value || '-',
    resultado: (value: ResultCode) => ResultDescription[value] ?? value
  }
}
