import React, { useState } from 'react'

import { handleRequestError } from 'api/HandleRequestError'
import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'
import { useGetTableSelection, useResetTableSelection } from 'domain/notifications/state'
import { DrawerActions } from 'ui'
import { GenericError } from 'api/errors'

function NotificationMassStatus({ status }: { status: 'read' | 'unread' }) {
  const [loading, setLoading] = useState(false)
  const selection = useGetTableSelection()
  const resetSelection = useResetTableSelection()

  const onClick = async () => {
    try {
      setLoading(true)

      const rowsToUpdate = selection.rows
        .filter(o => (status === 'read' ? !o.readAt : !!o.readAt))
        .map(({ id }) => id)
        .join(',')

      if (!rowsToUpdate.length) {
        const errorNotAvailableNotifications = status === 'read' 
          ? 'Não há notificações não lidas para marcar.' 
          : 'Não há notificações lidas para marcar.'
        throw new GenericError('Ação inseperada',errorNotAvailableNotifications)
      }

      await AuthorizedAPI.put(`/notifications/${rowsToUpdate}/${status}`)

      setLoading(false)
      resetSelection()
      queryClient.invalidateQueries(['notifications'])
    } catch (error) {
      setLoading(false)
      handleRequestError(error)
    }
  }

  return (
    <DrawerActions.Button
      icon={status === 'read' ? 'envelope-open' : 'envelope'}
      loading={loading}
      onClick={onClick}
    >
      {status === 'read' ? 'Marcar como lida' : 'Marcar como não lida'}
    </DrawerActions.Button>
  )
}

export default NotificationMassStatus
