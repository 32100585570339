import React, { useState } from 'react'
import { Form } from 'antd'

import { AuthorizedAPI } from 'api/network/v2'
import { errorHandler } from 'api/errors'
import { SubmitButton, TextAreaInput } from 'ui'

interface Props {
  subject: string
  onSuccess: () => void
}

function SimpleHelpForm({ subject, onSuccess }: Props) {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const onFinish = async (data: any) => {
    try {
      setLoading(true)
      await AuthorizedAPI.post('/contacts/help', { subject, body: data.description })

      onSuccess()
    } catch (error) {
      errorHandler(error, { code: '0x466' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      requiredMark={false}
      scrollToFirstError
      className="max-w-lg"
    >
      <TextAreaInput name="description" label="Mensagem" className="h-40" maxLength={10000} required />

      <SubmitButton label="Enviar" loading={loading} />
    </Form>
  )
}

export default SimpleHelpForm
