import React from 'react'
import { Modal } from 'antd'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { errorHandler } from 'api/errors'
import queryClient from 'app/config/queryClient'
import { useCurrentUser, useHasPermission } from 'domain/auth'

import { useUpdateLatestStorageOperation } from '../state'
import { ExplorerItem } from '../types'
import QueryKeys from './MyFilesQueryKeys'

export default function useDeleteExplorerItem(explorerItem: ExplorerItem) {
  const user = useCurrentUser()
  const updateLatestStorageOperation = useUpdateLatestStorageOperation()
  const canDeleteOtherUsersUploadedFiles = useHasPermission('canDeleteOtherUsersUploadedFiles')
  const canDeleteOwnUploadedFiles = useHasPermission('canDeleteOwnUploadedFiles')

  async function confirmAndDeleteFile(options?: any) {
    const isFolder = explorerItem.type === 'folder'

    Modal.confirm({
      title: (
        <>
          Tem certeza que deseja excluir {isFolder ? 'a pasta' : 'o arquivo'} <b>{explorerItem.name}</b>?
        </>
      ),
      content: isFolder ? (
        <span>
          O conteúdo da pasta será movido para raiz dos <b>Meus documentos</b>.
        </span>
      ) : (
        <span>Esta operação não poderá ser desfeita.</span>
      ),
      okText: 'Sim',
      cancelText: 'Não',
      onOk: async () => {
        try {
          await deleteExplorerItem(explorerItem.id)

          Modal.success({
            title: isFolder ? 'Pasta deletada com sucesso!' : 'Arquivo deletado com sucesso!',
            content: isFolder ? (
              <>
                O conteúdo da pasta foi movido para a raiz dos <b>Meus documentos</b>.
              </>
            ) : (
              'O arquivo foi excluído.'
            ),
          })

          queryClient.invalidateQueries(QueryKeys.MyFilesList)

          updateLatestStorageOperation()

          options?.onSuccess && options.onSuccess()
        } catch (error) {
          errorHandler(error, { code: '0x704', showBackError: true })
        }
      },
    })
  }

  const hasPermission =
    canDeleteOtherUsersUploadedFiles || (canDeleteOwnUploadedFiles && explorerItem.ownerId === user.id)

  return {
    deleteExplorerItem: confirmAndDeleteFile,
    permissionError: hasPermission ? undefined : 'Você não tem permissão para excluir o arquivo.',
  }
}

export const deleteExplorerItem = async (itemId: number) => {
  const { data }: AxiosResponse<any> = await AuthorizedAPI.delete(`/explorer/${itemId}`)
  return data
}
