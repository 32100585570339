import { InvoicePostpaidReportAPI, PostpaidInvoice } from 'domain/invoices/types'
import { displayDate } from 'utils/dateTime'
import { formatMoney } from 'utils/formatters'

function convertPostpaidInvoiceReport(invoice: InvoicePostpaidReportAPI, id: number): PostpaidInvoice {
  return {
    createdAtDisplay: displayDate(invoice.order_placed_at),
    debitAmount: invoice.invoice_postpaid_debit_amount,
    debitAmountDisplay: formatMoney(invoice.invoice_postpaid_debit_amount),
    deliveredAtDisplay: displayDate(invoice.order_finished_at),
    fiscalAmountDisplay: formatMoney(invoice.invoice_postpaid_fiscal_amount),
    id,
    orderItem: {
      name: invoice.order_name,
      orderItemNumber: invoice.order_backoffice_id,
      user: {
        name: invoice.user_name,
      } as any,
    } as any,
  }
}

export default convertPostpaidInvoiceReport
