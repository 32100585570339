import { useDispatch } from 'react-redux'

import { CertificateFlow } from 'domain/createOrder/certificates'
import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document'
import { NumberField, YearField } from 'domain/servicesCBRdoc/fields'

import config from '../certidaoRAISConfig'
import CertidaoRAISType from './CertidaoRAISType'
import { CertidaoRaisTypes } from '../types'
import CertidaoRaisFieldByType from './CertidaoRaisFieldByType'

export default function CreateOrderCertidaoRAIS() {
  return <CertificateFlow renderFormItem={(itemId: number) => <CertidaoRAISForm itemId={itemId} />} />
}

function CertidaoRAISForm({ itemId }: { itemId: number }) {
  const { formItems } = useCreateOrderState()
  const dispatch = useDispatch()

  return (
    <>
      <YearField itemId={itemId} field={ano_base} autoFocus={itemId === 0} />
      <NumberField itemId={itemId} field={crea} min="0" />

      <CertidaoRAISType
        itemId={itemId}
        onChangeType={(selected: any) => {
          dispatch(createOrderActions.setMultipleItemsType({ id: itemId, value: selected }))
        }}
      />

      <CertidaoRaisFieldByType
        selectedType={formItems[itemId]?.selectedType as CertidaoRaisTypes}
        itemId={itemId}
      />
    </>
  )
}

const { ano_base, crea } = config.formFields
