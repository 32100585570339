import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { FORMATO } = FieldName

type CertidaoAntecedentesCriminaisFields = Record<
  | FieldName.CPF 
  | FieldName.DATA_NASCIMENTO 
  | FieldName.NOME_PF
  | FieldName.NOME_MAE,
  FieldConfig
  >

class CertidaoAntecedentesCriminais extends Certificate {
  formFields: CertidaoAntecedentesCriminaisFields = {
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nascimento: Fields.nascimento,
    mae: Fields.mae,
  }

  constructor() {
    super({
      id: 37,
      legalEntity: 'fisica',
      priceByFields: [FORMATO],
      shortName: 'Antecedentes Criminais',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoAntecedentesCriminais().initialize()

export default service

