import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { errorHandler } from 'api/errors'
import { useIsCustomerPostPaid } from 'domain/customers'
import { OrderAPI } from 'domain/orders/types'

import { convertOrder } from './convertOrder'

export default function useOrderQuery(orderId: number | undefined) {
  const isPostPaid = useIsCustomerPostPaid()

  return useQuery(
    ['orders', orderId],
    async () => {
      const response: AxiosResponse<OrderAPI> = await AuthorizedAPI.get(`/purchases/${orderId}`, {
        params: {
          include: ['waiting_invoice_payment'],
        },
      })
      return convertOrder(response.data, isPostPaid!)
    },
    {
      enabled: !!orderId && isPostPaid !== undefined,
      onError: (error: any) => {
        errorHandler(error, { code: '0x372' })
      },
    }
  )
}
