import { useCallback } from 'react'
import { useCurrentUser } from '../state'
import { PermissionName } from '../types'

function useCheckPermission() {
  const user = useCurrentUser()

  return useCallback(
    (permissionName: PermissionName) => user.permissions.some(p => p.code === permissionName),
    [user]
  )
}

export default useCheckPermission
