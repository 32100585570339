import { useDispatch } from 'react-redux'

import {
  CreateOrderLegalEntityTabs,
  createOrderActions,
  useCreateOrderState,
} from 'domain/createOrder/document'
import { AllExtraInfos } from 'domain/orderItem/types'
import {
  CNPJAndNameFields,
  ExtraInfoFields,
  MaskedField,
  NameField,
  ResearchTimeField,
  TextField,
} from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../certidaoProtestoConfig'

interface Props {
  itemId: number
  extraInfos: AllExtraInfos | undefined
  loading: boolean
}

export default function FormCertidaoProtesto({ itemId, extraInfos, loading }: Props) {
  const dispatch = useDispatch()
  const { selectedService } = useCreateOrderState()
  const researchOptions =
    extraInfos?.[config.dynamicFields.tempo_pesquisa.apiExtraInfo!]

  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={
          <>
            <CPFInput itemId={itemId} autoFocus={itemId === 0} />
            <NameField itemId={itemId} field={nome_pf} />
            <TextField itemId={itemId} field={rg} />
          </>
        }
        juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} autoFocus={itemId === 0} />}
      />

      <ExtraInfoFields
        serviceCode={selectedService.code!}
        data={extraInfos}
        loading={loading}
        itemId={itemId}
      />

      {researchOptions && (
        <ResearchTimeField
          options={researchOptions as number[]}
          description="pesquisa de protestos"
          itemId={itemId}
          onChange={time =>
            dispatch(
              createOrderActions.setFormItemField({ id: itemId, field: 'selectedResearchTime', value: time })
            )
          }
        />
      )}

      <MaskedField field={cep} />
    </>
  )
}

const { cep, nome_pf, nome_pj, rg } = config.formFields
