import React from 'react'

import { formatMoney } from 'utils/formatters'

interface Props {
  fieldName: string
  selectedRows: any[]
}

function SumTotalInvoices({ selectedRows, fieldName }: Props) {
  const selectedTotalAmount = selectedRows?.reduce((accumulator, row) => {
    return accumulator + (row[fieldName] ? parseFloat(row[fieldName]) : 0)
  }, 0)

  return (
    <div className="mr-7">
      Valor total: <span className="pl-3 text-2xl text-green-500">{formatMoney(selectedTotalAmount)}</span>
    </div>
  )
}

export default SumTotalInvoices
