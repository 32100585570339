import { Tag, TagProps } from 'antd'

type Props = Omit<TagProps, 'className' | 'closable'> & { onClick: () => void }

export default function FilterTag({ children, color, onClick }: Props) {
  return (
    <Tag
      color={color}
      className="rounded-lg truncate w-fit max-w-[180px]"
      closable
      onClose={e => {
        e.preventDefault()
        onClick()
      }}
    >
      <span>{children}</span>
    </Tag>
  )
}
