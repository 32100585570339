import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { errorHandler } from 'api/errors'
import { AuthorizedAPI } from 'api/network/v2'
import { useGetIsAuthenticated } from 'domain/auth'
import { useCurrentCustomer } from 'domain/customers'
import { CustomerWithBalanceAPI } from 'domain/customers/types'

export default function useCustomerCreditsQuery(options?: { showError: boolean }) {
  const isAuthenticated = useGetIsAuthenticated()
  const customer = useCurrentCustomer()

  return useQuery(
    ['customer', customer.id],
    async () => {
      const response: AxiosResponse<CustomerWithBalanceAPI> = await AuthorizedAPI.get('/customers', {
        params: {
          append: 'account_balance',
        },
      })

      return response.data?.account_balance
    },
    {
      enabled: Boolean(isAuthenticated && customer.id && !customer.isPostpaid),
      initialData: 0,
      onError: error => {
        if (options?.showError && customer) {
          errorHandler(error, {
            code: '0x498',
            description: 'Não conseguimos recuperar seu saldo. Tente recarregar a página.',
          })
        }
      },
    }
  )
}
