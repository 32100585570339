import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { errorHandler } from 'api/errors'
import { useCurrentUser } from 'domain/auth'
import { OrderAPI } from 'domain/orders/types'
import { useGetFilters } from 'domain/orders/state/filters'
import {
  useGetCurrentPage,
  useGetPageSize,
  useGetSortBy,
  useSetTotalPages,
  useSetTotalResults,
} from 'domain/orders/state/pagination'
import { getFilters } from 'utils/transformData'

import { convertOrderList } from './convertOrder'

export const ORDERS_QUERY_KEY = ['orders', 'list']

export default function useOrdersQuery() {
  const authState = useCurrentUser()
  const filters = useGetFilters()
  const {
    ai,
    automaticGenerated,
    dateRange,
    isExpired,
    ownersIds,
    recurrenceId,
    results,
    searchTerm,
    servicesIds,
    statuses,
    groupsIds,
  } = filters
  const currentPage = useGetCurrentPage()
  const pageSize = useGetPageSize()
  const sortBy = useGetSortBy()
  const setTotalPages = useSetTotalPages()
  const setTotalResults = useSetTotalResults()

  return useQuery(
    [...ORDERS_QUERY_KEY, currentPage, pageSize, authState, filters],
    async () => {
      // Esses filtros não exibem pedidos, apenas os itens de pedido. Por isso retornamos []
      if (results.length > 0 || isExpired) {
        setTotalPages(0)
        setTotalResults(0)
        return []
      }

      const response: AxiosResponse<OrderAPI[]> = await AuthorizedAPI.get('/purchases', {
        params: {
          append: [
            'downloadable_orders_ids',
            'first_order.ai_service_name',
            'first_order.register',
            'last_status_change_at',
            'has_ai_extracted_data',
            'has_consumed_ai_free_analysis',
            'has_ai_analysis_pending',
            'orders_expired_count',
            'orders_status_count',
          ],
          include: ['first_order.service', 'recurrence', 'user'],
          sort: sortBy,
          'per-page': pageSize,
          page: currentPage,
          ...getFilters({
            ai: ai ? 'ai-yes' : 'any',
            automatic_generated: automaticGenerated ? true : undefined,
            name_or_id: searchTerm,
            'orders.service_id': servicesIds.join(','),
            'orders.group_id': groupsIds.join(','),
            placed_between: dateRange,
            status: statuses.join(','),
            user_id: ownersIds.join(','),
            recurrence_generated: filters.onlyFromRecurrence ? true : undefined, // se enviar false a listagem traz somente as orders que nao foram criadas por recorrencias
            recurrence_id: recurrenceId,
          }),
        },
      })

      setTotalPages(Number(response.headers['x-pagination-page-count']))
      setTotalResults(Number(response.headers['x-pagination-total-count']))

      return response.data?.map(convertOrderList)
    },
    {
      keepPreviousData: true,
      enabled: currentPage !== undefined,
      onError: (error: any) => {
        errorHandler(error, { code: '0x371' })
      },
    }
  )
}
