export const getStorageString = (item: string, defaultValue?: string) => {
  const data = localStorage.getItem(item)
  return data ?? defaultValue ?? ''
}

export const setStorageString = (item: string, data: string) => {
  localStorage.setItem(item, data)
}

export const getStorageInt = (item: string, defaultValue: number | null = null) => {
  const data = localStorage.getItem(item)
  return data ? parseInt(data) : defaultValue
}

export const setStorageInt = (item: string, data: number) => {
  localStorage.setItem(item, String(data))
}

export const getStorageBoolean = (key: string, defaultValue?: boolean) => {
  const data = localStorage.getItem(key)
  return data ? data === 'true' : defaultValue
}

export const setStorageBoolean = (item: string, data: boolean) => {
  localStorage.setItem(item, data.toString())
}

export const getStorageData = (item: string, defaultValue?: any) => {
  const data = localStorage.getItem(item)
  try {
    return data ? JSON.parse(data) : defaultValue
  } catch {
    return defaultValue
  }
}

export const setStorageData = (item: string, data: Record<string, any> | string) => {
  localStorage.setItem(item, JSON.stringify(data))
}

export const removeStorageItem = (item: string) => localStorage.removeItem(item)
