import { Form } from 'antd'

import { useGetOrderName } from 'domain/checkout'
import { Modal, SubmitButton } from 'ui'
import OrderDetailsForm from './CartOrderDetailsForm'

interface Props {
  isCheckout?: boolean
  open: boolean
  onFinish?: () => void
  onCancel: () => void
}

export default function CartOrderDetails({ open, onFinish, onCancel, isCheckout }: Props) {
  const [form] = Form.useForm()
  const orderName = useGetOrderName()

  return (
    <Modal
      open={open}
      title={isCheckout ? 'Dê um nome para seu pedido!' : 'Alterando nome da compra'}
      onCancel={onCancel}
    >
      <OrderDetailsForm
        form={form}
        initialValues={{
          orderName,
        }}
        isCheckout={isCheckout}
        onFinish={onFinish}
      >
        <SubmitButton label={isCheckout ? 'Finalizar pedido' : 'Salvar'} />
      </OrderDetailsForm>
    </Modal>
  )
}
