import React from 'react'

import { errorHandler } from 'api/errors'
import { useRedoOrder } from 'domain/createOrder/redo'
import { useIsRedirecting } from 'domain/orders/state/redirecting'
import { OrderItemPurchasable } from 'domain/orderItem/types'
import { ServiceType } from 'domain/servicesCBRdoc/types'
import { DrawerActions } from 'ui'

export default function OrderItemsMassRedo({ orderItems }: { orderItems: OrderItemPurchasable[] }) {
  const addToCart = useRedoOrder()
  const [isRedirecting, setIsRedirecting] = useIsRedirecting()

  const handleClick = async () => {
    try {
      setIsRedirecting(true)

      const promisses = orderItems
        .filter(o => !o.isFromPassportWithoutAllData)
        .map(async orderItem => await addToCart(orderItem))

      await Promise.all(promisses)
    } catch (err) {
      errorHandler(err, {
        code: '0x792',
      })
      setIsRedirecting(false)
    }
  }

  const isDocRegistration = orderItems.some(o => o.service.type === ServiceType.DOCUMENT_REGISTRATION)

  if (isDocRegistration || orderItems.length === 0) return null

  return (
    <DrawerActions.Button icon="repeat" loading={isRedirecting} onClick={handleClick}>
      Refazer pedido
    </DrawerActions.Button>
  )
}
