import React, { useReducer } from 'react'
import { Button } from 'antd'

import RejectModal from './OrderPriceQuotationRejectModal'

interface Props {
  orderId: number
}

export default function OrderPriceQuotationReject({ orderId }: Props) {
  const [open, toggleOpen] = useReducer(prev => !prev, false)

  return (
    <>
      <Button type="primary" ghost size="small" onClick={toggleOpen}>
        Rejeitar
      </Button>

      <RejectModal open={open} onClose={toggleOpen} orderId={orderId} />
    </>
  )
}
