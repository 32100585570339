import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { ReactComponent as LogoCBRDocLettering } from 'assets/images/logo-cbrdoc-lettering.svg'
import { ReactComponent as LogoCBRDocSymbol } from 'assets/images/logo-cbrdoc-symbol.svg'
import { Icon } from 'ui'
import { springTransition } from 'utils/animations'

import SideNavToggleButton from './SideNavToggleButton'
import SideNavOrderButton from './SideNavOrderButton'
import SideNavOptions from './SideNavOptions'

interface Props {
  collapsed: boolean
  onToggle: () => void
}

export default function SideNav({ collapsed, onToggle }: Props) {
  const animation = collapsed ? 'collapsed' : 'normal'

  return (
    <motion.div
      className={`flex flex-col justify-between scroll text-gray-400 h-screen min-h-screen bg-secondary-500 
        shadow-lg sm:no-shadow overflow-y-auto overflow-x-hidden z-[1000] absolute sm:static`}
      initial={animation}
      animate={animation}
      variants={navCollapsing}
      transition={springTransition}
    >
      <div className="flex flex-1 flex-col p-5">
        <Link
          to={RoutePaths.ORDERS}
          className="flex items-start justify-start whitespace-nowrap overflow-hidden text-ellipsis h-16"
        >
          <div className="flex flex-row items-center justify-between gap-4 w-100">
            <div className="flex flex-row items-center">
              <LogoCBRDocSymbol
                className={`transition-all duration-150 ease-linear ${
                  collapsed ? 'text-[50px] ml-3' : 'text-[38px]'
                }`}
              />
              {!collapsed && <LogoCBRDocLettering color="white" />}
            </div>

            <button className="sm:hidden text-white cursor-pointer" onClick={() => onToggle()}>
              <Icon name="close" size={30} />
            </button>
          </div>
        </Link>

        <SideNavOrderButton collapsed={collapsed} />
        <SideNavOptions collapsed={collapsed} />
      </div>

      <SideNavToggleButton collapsed={collapsed} onToggle={onToggle} />
    </motion.div>
  )
}

const isSmallDevice = window.innerWidth <= 640

const navCollapsing = {
  collapsed: {
    width: isSmallDevice ? 0 : 100,
  },
  normal: {
    width: 256,
  },
}
