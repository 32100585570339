import React from 'react'

import { Icon } from 'ui'

function ShippingDelayedDays({ days }: { days: number }) {
  return (
    <div className="mt-3 flex items-center">
      <Icon name="calendar" className="text-error text-2xl mr-2" />
      <span>+ {days} dias</span>
    </div>
  )
}

export default ShippingDelayedDays
