import { CertificateFlow } from 'domain/createOrder/certificates'
import {
  CreateOrderLegalEntityTabs,
  SelectLocationFields,
  useLoadExtraInfos,
  useCreateOrderState,
} from 'domain/createOrder/document'
import { CNPJAndNameFields, ExtraInfoFields, NameField } from 'domain/servicesCBRdoc/fields'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { CPFInput } from 'ui'

import config from '../certidaoAmbientalConfig'

export default function CreateOrderCertidaoAmbiental() {
  return (
    <CertificateFlow
      header={<SelectLocationFields autoFocus />}
      renderFormItem={(itemId: number) => <CertidaoAmbientalForm itemId={itemId} />}
    />
  )
}

const CertidaoAmbientalForm = ({ itemId }: { itemId: number }) => {
  const { selectedService } = useCreateOrderState()
  const { data, isFetching } = useLoadExtraInfos({ itemId })

  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={
          <>
            <CPFInput itemId={itemId} />
            <NameField itemId={itemId} field={nome_pf} />
          </>
        }
        juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} />}
      />

      <ExtraInfoFields
        serviceCode={selectedService.code!}
        data={data}
        loading={isFetching}
        itemId={itemId}
        exceptions={exceptions}
      />
    </>
  )
}

const { nome_pf, nome_pj } = config.formFields
const exceptions = [FieldName.NOME_PF, FieldName.NOME_PJ, FieldName.CPF, FieldName.CNPJ]
