import { PrepaidInvoice } from 'domain/invoices/types'

function extractDataToExport(tableData: PrepaidInvoice[]): any[] {
  return tableData.map(invoice => ({
    purchase_name: invoice.order.name,
    purchase_number: invoice.order.orderNumber,
    created_at: invoice.createdAtDisplay,
    amount: invoice.amountDisplay,
    bonus: invoice.bonusAmountDisplay,
    delivered_at: invoice.deliveredAtDisplay,
    user_name: invoice.user.name,
  }))
}

export default extractDataToExport
