import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs, SelectLocationFields } from 'domain/createOrder/document'
import { CNPJAndNameFields, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../certidaoDebitosEstaduaisConfig'

export default function CreateOrderCertidaoDebitosEstaduais() {
  return (
    <CertificateFlow
      header={
        <>
          <p className="mb-5 text-gray-600">
            Selecione Pessoa ou Empresa e complete as informações de quem deseja a Certidão de Débitos
            Estaduais.
          </p>

          <SelectLocationFields autoFocus />
        </>
      }
      renderFormItem={(itemId: number) => <CertidaoDebitosEstaduaisForm itemId={itemId} />}
    />
  )
}

const CertidaoDebitosEstaduaisForm = ({ itemId }: { itemId: number }) => {
  return (
    <CreateOrderLegalEntityTabs
      itemId={itemId}
      fisica={
        <>
          <CPFInput itemId={itemId} />
          <NameField itemId={itemId} field={nome_pf} />
        </>
      }
      juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} />}
    />
  )
}

const { nome_pf, nome_pj } = config.formFields
