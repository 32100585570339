import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithReset, useResetAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { useResetUriParams, useUpdateUri_deprecated, useUrlQuery } from 'utils/navigation'
import { removeUndefined } from 'utils/transformData'
import { KitFilters, KitUrlParams } from '../types'

import { currentPageAtom } from './table'

const filtersAtom = atomWithReset({} as KitFilters)

const searchAtom = atom<string>('')
const searchInputAtom = atom(
  get => get(searchAtom),
  (_, set, value: string) => {
    set(searchAtom, value.trim())
    set(currentPageAtom, 1)
  }
)
const hasFiltersAtom = atom(get => {
  const search = get(searchAtom) !== ''
  const filters = get(filtersAtom)
  const hasFilters = Object.values(removeUndefined(filters)).length > 0
  return search || hasFilters
})

type ReturnUseFilter = [values: KitFilters, setValues: (values: Partial<KitFilters>) => void]

export const useFilters = (): ReturnUseFilter  => {
  const updateUri = useUpdateUri_deprecated<KitUrlParams>()
  const urlParams = useUrlQuery<KitUrlParams>()
  const [filtersVal, setFn] = useAtom(filtersAtom)

  const paramsFilters = useMemo(() => {
    const filtersFromParams = {} as any
    Object.keys(urlParams).forEach(urlParamsKey => {
      if (urlParamsKey as keyof KitFilters) {
        if (urlParamsKey === 'results') {
          const resultsVal = urlParams[urlParamsKey as keyof KitFilters] 
            ? (urlParams[urlParamsKey as keyof KitFilters] as string).split(',')
            : []
          filtersFromParams[urlParamsKey] = resultsVal
        }
      }
    })
    return {...filtersVal, ...filtersFromParams}
  }, [urlParams, filtersVal])

  function setFilter(values: Partial<KitFilters>) {
    updateUri(values)
    setFn(values as any)
  }

  return [paramsFilters, setFilter]
}

export const useHasFilters = () => useAtomValue(hasFiltersAtom)
export const useSearch = () => useAtomValue(searchAtom)
export const useSearchState = () => useAtom(searchInputAtom)
export const useSetSearch = () => useSetAtom(searchInputAtom)
export const useKitsFiltersState = () => useAtomValue(filtersAtom)
export const useSetKitsFiltersState = () => useSetAtom(filtersAtom)
export const useResetKitsFiltersState = () => {
  const resetAtom = useResetAtom(filtersAtom)
  const resetUrl = useResetUriParams()

  return function reset() {
    resetAtom()
    resetUrl()
  }
}

