import { motion } from 'framer-motion'

import { ServiceAPI } from 'domain/servicesCBRdoc/types'
import { LinkCard, LinkCardProps, Tag } from 'ui'
import FavoriteStar from './ServiceLinkFavoriteStar'

export interface ServiceLinkProps extends Omit<LinkCardProps, 'extra' | 'heading' | 'icon'> {
  service: ServiceAPI
  isNew?: boolean
  isUnavailable?: boolean
}

export default function ServiceLink({
  onClick,
  service,
  children,
  isNew = false,
  isUnavailable,
  ...props
}: ServiceLinkProps) {
  return (
    <motion.li layout transition={transition}>
      <LinkCard
        icon={service.icon}
        heading={service.name}
        disabled={isUnavailable}
        noChevron={isUnavailable}
        extra={
          <>
            {isNew && (
              <div>
                <span className="bg-success-500 rounded-full text-white px-6 py-1 text-xs text-center">
                  Novidade
                </span>
              </div>
            )}

            {isUnavailable && (
              <div>
                <span className="bg-gray-700 rounded-full text-white px-6 py-1 text-xs text-center">
                  Em Breve
                </span>
              </div>
            )}

            {service.instant_delivery && (
              <Tag className="ml-2 mr-1 h-5 text-center leading-6">Entrega imediata</Tag>
            )}

            {!isUnavailable && (
              <FavoriteStar
                isFavorite={service.is_favorite}
                serviceId={service.id}
                serviceType={service.type}
              />
            )}
          </>
        }
        onClick={onClick}
        {...props}
      >
        {children}
      </LinkCard>
    </motion.li>
  )
}

const transition = {
  type: 'spring',
  damping: 25,
  stiffness: 120,
}
