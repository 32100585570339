import React from 'react'

interface Props {
  onClick: () => void
  textSize?: 'base' | 'sm'
}

export default function ClearFiltersButton({ onClick, textSize = 'base' }: Props) {
  return (
    <a
      type="link"
      className={`text-${textSize} text-primary font-medium hover:underline`}
      onClick={(e: any) => {
        e.preventDefault()
        onClick()
      }}
    >
      limpar filtros
    </a>
  )
}
