import { useMutation } from 'react-query'
import { message } from 'antd'
import { AxiosResponse } from 'axios'

import { API } from 'api/network/v2'
import { errorHandler } from 'api/errors'
import queryClient from 'app/config/queryClient'

import { Group, GroupAPI, GroupRegister } from '../types'

export default function useEditGroup(onSuccess?: (group: Group) => void) {
  const mutation = useMutation(
    async ([id, data]: [number, GroupRegister]) => await updateGroupAPI(id, data),
    {
      onSuccess: (updatedGroup) => {
        queryClient.invalidateQueries(['groups', 'list'])
        message.success('O grupo atualizado.')
        onSuccess && onSuccess(updatedGroup)
      },
      onError: error => {
        errorHandler(error, { code: '0x708' })
      },
    }
  )

  const editGroup = (id: number, formData: GroupRegister) => mutation.mutate([id, formData])

  return { editGroup, loading: mutation.isLoading }
}

const updateGroupAPI = async (id: number, { name }: GroupRegister) => {
  const { data }: AxiosResponse<GroupAPI> = await API.put(`/groups/${id}`, { name })
  return data
}
