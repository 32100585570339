import { DontKnowBookPageFields } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs, OrderNameField } from 'domain/createOrder/document'
import { CNPJAndNameFields, DateField, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../certidaoEscrituraConfig'

interface Props {
  itemId: number
  dontKnowBookPagePrices: any
  loadingBookPagePrice: boolean
}

export default function CreateOrderCertidaoEscrituraForm({
  itemId,
  dontKnowBookPagePrices,
  loadingBookPagePrice,
}: Props) {
  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={
          <>
            <CPFInput itemId={itemId} />
            <NameField field={nome_pf} itemId={itemId} />
          </>
        }
        juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} />}
      />

      <OrderNameField itemId={itemId} />

      <h2 className="uppercase">Dados complementares</h2>

      <hr className="my-5" />

      <DontKnowBookPageFields
        itemId={itemId}
        price={dontKnowBookPagePrices?.price}
        loading={loadingBookPagePrice}
      />

      <hr className="mt-5 border-none" />

      <DateField field={data_ato} itemId={itemId} />
    </>
  )
}

const { nome_pf, data_ato, nome_pj } = config.formFields
