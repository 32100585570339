import React from 'react'
import { Table } from 'antd'
import { useHistory } from 'react-router'

import { Routes } from 'app/routes'
import { useSetSortDirection, useSetSortField } from 'domain/orders/state/pagination'
import { OrderList, SelectedOrders } from 'domain/orders/types'

import tableColumns from './OrdersTableColumns'

interface Props {
  orders: OrderList[]
  loading: boolean
  selectedIds: number[]
  setSelected: (data: SelectedOrders) => void
}

export default function OrdersTable({ orders, loading, selectedIds, setSelected }: Props) {
  const history = useHistory()
  const setSortDirection = useSetSortDirection()
  const setSortField = useSetSortField()

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortField(sorter.field)
    setSortDirection(sorter.order)
  }

  const onRow = (order: OrderList) => {
    return {
      onClick: () => history.push(Routes.Order(order.id)),
    }
  }

  const rowSelection = {
    selectedRowKeys: selectedIds,
    hideSelectAll: false,
    preserveSelectedRowKeys: true,
    columnWidth: 36,
    onChange: (selectedRowKeys: React.Key[], selectedRows: OrderList[]) => {
      setSelected({ ids: selectedRowKeys as number[], orders: selectedRows })
    },
  }

  return (
    <Table
      dataSource={orders}
      rowKey={record => record.id}
      columns={tableColumns}
      onChange={handleTableChange}
      pagination={false}
      rowSelection={rowSelection}
      onRow={onRow}
      loading={loading}
      className="mask-text"
    />
  )
}
