import React from 'react'

import Button, { ButtonProps } from 'ui/buttons/Button'

type Props = Omit<ButtonProps, 'size'>

export default function DrawerActionButton({ children, disabled, ...props }: Props) {
  return (
    <Button
      ghost
      className={`flex flex-row min-w-40 text-white border-white hover:text-secondary-500 
        hover:bg-white hover:border-white ${disabled ? 'opacity-50 text-white' : ''}`}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  )
}
