import React from 'react'

import { displayDateAndTime } from 'utils/dateTime'
import ViewInfo, { ViewInfoProps } from './ViewInfo'

interface Props extends Omit<ViewInfoProps, 'value'> {
  date: string | Date
}

export default function ViewInfoDate({ label, icon = 'calendar', date }: Props) {
  return <ViewInfo icon={icon} label={label} value={displayDateAndTime(date)} />
}
