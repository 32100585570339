import { FormItemProps } from 'antd'

import { LabelInValue } from 'domain/app/types'
import { SelectedService } from 'domain/createOrder/types'
import { DynamicRadio } from 'domain/servicesCBRdoc/fields'
import { SelectedLocation } from 'domain/servicesCBRdoc/types'
import config from '../pesquisaBensConfig'

interface Props extends Omit<FormItemProps, 'name'> {
  service: SelectedService
  location: SelectedLocation
  onChange: (type: string) => void
  itemId?: number
  required?: boolean
}

export default function PesquisaBensSelectType({ location, ...props }: Props) {
  const federativeUnit = (location.url_uf as LabelInValue)?.value as string

  return (
    <DynamicRadio
      information="tipos"
      field={config.formFields.tipo}
      params={{ url_uf: federativeUnit }}
      {...props}
    />
  )
}
