import { ServiceIconName } from 'domain/servicesCBRdoc'
import { ServiceType } from 'domain/servicesCBRdoc/types'
import { Icon } from 'ui'
import { ShoppingCartOrder } from '../../types'

function CartItemViewServiceIcon({ order }: { order: ShoppingCartOrder }) {
  const serviceType = order.selectedService.type

  if (ServiceType.AI === serviceType) {
    return <Icon name={ServiceIconName.AI} size={24} className="text-secondary-500" />
  }

  if (ServiceType.RESEARCH === serviceType) {
    return <Icon name={ServiceIconName.Research} size={24} className="text-secondary-500" />
  }

  if (ServiceType.DILIGENCE === serviceType) {
    return <Icon name={ServiceIconName.Diligence} size={24} className="text-secondary-500" />
  }

  if (ServiceType.CERTIFICATE === serviceType) {
    return <Icon name={ServiceIconName.Certificate} size={24} className="text-secondary-500" />
  }

  if (ServiceType.DOCUMENT_REGISTRATION === serviceType) {
    return <Icon name={ServiceIconName.DocumentRegistration} size={24} className="text-secondary-500" />
  }

  return <Icon name="outras-certidoes" size={24} className="text-secondary-500" />
}

export default CartItemViewServiceIcon
