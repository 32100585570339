import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { useCheckAppVersionListener } from 'domain/app'
import { useRefreshUserPermissions } from 'domain/auth'
import { ErrorBoundary, HamburguerButton } from 'ui'

import LatestAppRelease from '../LatestAppRelease'
import SideNav from './SideNav/SideNav'
import TopBar from './TopBar'
import useNavbarCollapse from './useNavbarCollapse'

interface Props {
  children?: ReactNode
}

export default function AuthenticatedLayout({ children }: Props) {
  const { pathname } = useLocation()
  const [isNavbarCollapsed, toggleNavbarCollapse] = useNavbarCollapse()
  const hideTopbar = pathname === RoutePaths.SHOPPING_CART

  useRefreshUserPermissions()
  useCheckAppVersionListener()

  useEffect(() => {
    document.getElementById('scroller')?.scroll(0, 0)
  }, [pathname])

  return (
    <div className="flex flex-1 overflow-y-hidden">
      <SideNav collapsed={isNavbarCollapsed} onToggle={toggleNavbarCollapse} />

      <div className="flex-1 flex flex-col h-screen relative bg-gray-100">
        {!hideTopbar && <TopBar toggle={<HamburguerButton onToggle={toggleNavbarCollapse} />} />}

        <div
          className={`flex flex-1 flex-col transition-opacity ease-in-out duration-300 overflow-y-auto ${
            hideTopbar ? 'relative' : 'absolute bottom-0 left-0 right-0 top-[70px]'
          }`}
          id="scroller"
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>

        <div id="bottom-drawer-root"></div>
      </div>

      <LatestAppRelease showProd />
    </div>
  )
}
