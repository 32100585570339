import { CertificateFlow } from 'domain/createOrder/certificates'
import {
  CreateOrderLegalEntityTabs,
  useLoadExtraInfos,
  useCreateOrderState,
} from 'domain/createOrder/document'
import { AllExtraInfos } from 'domain/orderItem/types'
import { ExtraInfoFields } from 'domain/servicesCBRdoc/fields'
import { CPFInput, CNPJInput } from 'ui'

export default function CreateOrderCertidaoMTTrabalhistas() {
  const { data, isFetching } = useLoadExtraInfos()

  return (
    <CertificateFlow
      header={
        <p className="mb-5 text-gray-600">
          Selecione Pessoa ou Empresa e digite o número do CPF ou CNPJ que deseja a Certidão de Infrações
          Trabalhistas - MT.
        </p>
      }
      renderFormItem={(itemId: number) => (
        <CertidaoMTTrabalhistasForm itemId={itemId} extraInfos={data} loading={isFetching} />
      )}
    />
  )
}

interface Props {
  itemId: number
  loading: boolean
  extraInfos: AllExtraInfos | undefined
}

const CertidaoMTTrabalhistasForm = ({ itemId, extraInfos, loading }: Props) => {
  const { selectedService } = useCreateOrderState()
  const inputProps = { itemId, autoFocus: itemId === 0 }

  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={<CPFInput {...inputProps} />}
        juridica={<CNPJInput {...inputProps} />}
      />

      <hr className="my-5" />

      <ExtraInfoFields
        serviceCode={selectedService.code!}
        data={extraInfos}
        loading={loading}
        itemId={itemId}
      />
    </>
  )
}
