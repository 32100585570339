import React, { useState } from 'react'
import { Popover } from 'antd'

import { Icon } from 'ui'

interface Props {
  text: string | number
  className?: string
}

function Clipboard({ text, className = '' }: Props) {
  const [hasAlreadyCopied, setHasAlreadyCopied] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text as string)
    setHasAlreadyCopied(true)
  }

  return (
    <Popover
      content={
        <div className="flex gap-2">
          <ClipboardContent copied={hasAlreadyCopied} />
        </div>
      }
      className="rounded cursor-pointer"
    >
      <span onClick={copyToClipboard} className={className}>
        {text}
      </span>
      &nbsp;
    </Popover>
  )
}

function ClipboardContent({ copied }: { copied: boolean }) {
  if (!copied) {
    return (
      <>
        <Icon name="copy" className="text-sm" />
        <p>Clique para copiar</p>
      </>
    )
  }

  return (
    <>
      <Icon name="checkmark-circle" className="text-sm text-green-500" />
      <p>Copiado!</p>
    </>
  )
}

export default Clipboard