import { useEffect, useState } from 'react'
import { Form } from 'antd'

import { getFieldName, generateOrderName } from 'domain/createOrder/shared'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { Fields } from 'domain/servicesCBRdoc'
import { TextField } from 'domain/servicesCBRdoc/fields'
import { useCreateOrderState } from '../state'

interface Props {
  itemId?: number
}

export default function OrderNameField({ itemId }: Props) {
  const form = Form.useFormInstance()
  const name = getFieldName(NOME_ITEM_PEDIDO, itemId)
  const [value, setValue] = useState(form.getFieldValue(name))
  const [wasEditedByUser, setWasEditedByUser] = useState<boolean | undefined>(!value ? false : undefined)

  useUpdateOrderName({ itemId, value, setValue, wasEditedByUser, setWasEditedByUser })

  return (
    <TextField
      field={Fields[NOME_ITEM_PEDIDO]}
      name={name}
      value={value}
      onChange={e => {
        const value = e.target.value.trim()
        setValue(value)
        setWasEditedByUser(!!value)
      }}
    />
  )
}

type Params = {
  itemId: number | undefined
  value: string
  setValue: (value: string) => void
  wasEditedByUser: boolean | undefined
  setWasEditedByUser: (value: boolean | undefined) => void
}

const useUpdateOrderName = ({ itemId, value, setValue, wasEditedByUser, setWasEditedByUser }: Params) => {
  const form = Form.useFormInstance()
  const createOrder = useCreateOrderState()
  const { formData, selectedEntityType, selectedService, selectedType } = createOrder
  const orderNameFieldsValues = getValuesToGenerateOrderName(formData, itemId)
  const name = getFieldName(NOME_ITEM_PEDIDO, itemId)

  useEffect(() => {
    if (!wasEditedByUser) {
      const getFieldValue = (name: FieldName) => {
        return form.getFieldValue(getFieldName(name, itemId)) ?? form.getFieldValue(name)
      }

      const autoOrderName = generateOrderName(createOrder, itemId, getFieldValue)

      if (autoOrderName.length > 120) {
        form.setFields([
          {
            name,
            errors: ['O nome do pedido excede o limite de 120 caracteres.']
          }
        ])
      } else {
        form.setFields([
          { 
            name, 
            errors: undefined,
          }
        ])
      }

      if (wasEditedByUser === undefined) {
        setWasEditedByUser(value !== autoOrderName)
      } else if (wasEditedByUser === false) {
        form.setFieldValue(name, autoOrderName)
        setValue(autoOrderName)
      }
    }
  }, [orderNameFieldsValues, selectedEntityType, selectedService, selectedType, wasEditedByUser])
}

function getValuesToGenerateOrderName(formData: any, itemId?: number) {
  if (itemId !== undefined && !formData[itemId]) {
    return {}
  }

  const data = itemId === undefined ? formData : formData[itemId]

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name, ...fieldsValues } = data
  return fieldsValues
}

const { NOME_ITEM_PEDIDO } = FieldName
