import React, { useState } from 'react'
import { Button } from 'antd'

import { handleRequestError } from 'api/HandleRequestError'
import { useCurrentCustomer } from 'domain/customers'
import { useGetSelectedMonth, useGetTableSelection, useResetTableSelection } from 'domain/invoices/state'
import { PrepaidInvoice } from 'domain/invoices/types'

import generatePrepaidPDF from '../helpers/generatePrepaidPDF'

function PrepaidDownloadInvoice() {
  const [loading, setLoading] = useState(false)
  const customer = useCurrentCustomer()
  const selectedMonth = useGetSelectedMonth()
  const resetTableSelection = useResetTableSelection()
  const selected = useGetTableSelection()

  const onClick = async () => {
    try {
      setLoading(true)
      await generatePrepaidPDF({
        tableData: selected.rows as PrepaidInvoice[],
        month: selectedMonth,
        customer,
      })
      resetTableSelection()
    } catch (error) {
      handleRequestError(error, { code: '0x774' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button type="primary" onClick={onClick} className="px-10" loading={loading}>
      Emitir fatura
    </Button>
  )
}

export default PrepaidDownloadInvoice
