import React from 'react'
import { Form } from 'antd'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { User } from 'domain/users/types'
import { Select } from 'ui'

function KitsFilterOwner() {
  const { data, isFetching } = useQuery('kits-owners', async () => {
    const { data }: AxiosResponse<User[]> = await AuthorizedAPI.get('/users')
    return data
  })

  return (
    <Form.Item name="ownerId" label="Responsável">
      <Select
        size="middle"
        placeholder="Selecione o responsável"
        loading={isFetching}
        options={data}
        optionLabelKey="name"
        optionValueKey="id"
      />
    </Form.Item>
  )
}

export default KitsFilterOwner
