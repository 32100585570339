import React from 'react'
import { Button } from 'antd'

interface Props {
  loading: boolean
  disabled: boolean
  onCancel: () => void
}

function SpreadsheetConfirmPurchase({ onCancel, loading, disabled }: Props) {
  return (
    <>
      <div className="pt-5">
        <p className="font-bold">Deseja confirmar o pedido?</p>
      </div>

      <div className="flex pt-5">
        <Button type="primary" ghost block className="mr-2 text-primary" onClick={onCancel}>
          Não
        </Button>

        <Button htmlType="submit" type="primary" block className="mr-3" loading={loading} disabled={disabled}>
          Sim
        </Button>
      </div>
    </>
  )
}

export default SpreadsheetConfirmPurchase
