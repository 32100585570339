import React, { useMemo } from 'react'
import { Dropdown } from 'antd'

import { useStatuses } from 'domain/orders/state/filters'
import { StatusCode } from 'domain/orders/types'
import { SelectableButton } from 'ui'

function FilterByStatus() {
  const [currentFilters, setCurrentFilters] = useStatuses()
  const selectedOption = filterOptions.find(o => currentFilters.includes(o.code))
  const selectedCode = selectedOption?.code
  const isSelected = selectedCode !== undefined

  const items = useMemo(() => {
    return filterOptions.map(({ code, label }) => ({
      label,
      key: code,
      onClick: () => {
        if (currentFilters.includes(code)) {
          setCurrentFilters(currentFilters.filter(status => status !== code))
          return
        } 
        
        const updatedFilters = currentFilters.filter(code => !filterOptions.some(o => o.code === code))

        setCurrentFilters([...updatedFilters, code])
      },
    }))
  }, [currentFilters])

  return (
    <Dropdown menu={{ items }} trigger={['hover']}>
      <div>
        <SelectableButton
          onClick={() => {
            const updated = isSelected
              ? currentFilters.filter(code => code !== selectedCode)
              : [...currentFilters, filterOptions[0].code]

            setCurrentFilters(updated)
          }}
          selected={isSelected}
        >
          {selectedOption?.label ?? filterOptions[0].label}
        </SelectableButton>
      </div>
    </Dropdown>
  )
}

const filterOptions = [
  { code: StatusCode.FINALIZADO, label: 'Finalizados' },
  { code: StatusCode.PROCESSANDO, label: 'Processando' },
]

export default FilterByStatus
