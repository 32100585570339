import { RoutePaths } from 'app/routes'
import { Spreadsheet } from 'domain/createOrder/spreadsheet'
import { createSlug } from 'utils/formatters'

import { useGetKit } from '../state'

interface Props {
  open: boolean
  toggleOpen: () => void
}

export default function KitOrderBySpreadsheetModal_deprecated({ open, toggleOpen }: Props) {
  const kit = useGetKit()

  return (
    <Spreadsheet
      open={open}
      toggleOpen={toggleOpen}
      kitId={kit.id}
      downloadUrl={`${RoutePaths.DOWNLOAD_SPREADSHEET_KIT}/${kit.id}`}
      downloadFileName={`cbrdoc-${createSlug(kit.name)}.xlsx`}
    />
  )
}
