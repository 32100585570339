import Icon, { IconName } from '../Icon'

export interface ViewInfoProps {
  label: string | JSX.Element
  value?: string | number | JSX.Element
  icon?: IconName
  valueClassName?: string
}

export default function ViewInfo({ label, value, icon, valueClassName = '' }: ViewInfoProps) {
  return (
    <li className="py-3 border-b border-gray-300 last:border-b-0 flex items-center text-secondary-500">
      {icon && <Icon name={icon} className="flex-none text-xl mr-3" />}
      <div>
        {value && <div className={`capitalize ${valueClassName}`}>{Array.isArray(value) ? value.join(', ') : value}</div>}
        <div className="text-2xs text-gray-600 mt-2">{label}</div>
      </div>
    </li>
  )
}
