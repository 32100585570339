import React from 'react'
import { Collapse, Tooltip } from 'antd'
import { getEstimatedDateStr } from 'domain/orders/helpers'
import { FreeAnalysisPopup } from 'domain/openAI/enrich'
import { Sidebar } from 'layouts'
import { Clipboard, ErrorBoundary, Icon } from 'ui'
import { useCurrentCustomer, useIsCustomerPostPaid } from 'domain/customers'

import { useGetOrderItem } from '../state/viewOrderItem'
import { displayDate } from 'utils/dateTime'
import { fullOrderInfo } from './ViewFullOrderInformation'
import ItemAttachments from './attachments/ItemAttachments'
import ViewOrderGroups from './groups/ViewOrderGroups'

export default function ViewOrderSidebar() {
  const orderItem = useGetOrderItem()
  const isPostPaid = useIsCustomerPostPaid()
  const { showShippingInfoOnOrders } = useCurrentCustomer()

  const deliveryFormat: { [key: string]: string } = {
    papel: 'Correios (papel)',
    email: 'Digital (e-mail)',
    combo: 'Digital (e-mail) e Correios (papel)',
  }

  const hasActiveChallenge = !!orderItem.activeChallenge?.active

  const formattedDeliveryTime = getEstimatedDateStr(
    orderItem.estimatedDeliveryAt,
    orderItem.status,
    hasActiveChallenge
  )
  const formattedFinishedAt = orderItem.finishedAt && displayDate(orderItem.finishedAt)

  const fullOrderElement = fullOrderInfo(orderItem)

  return (
    <Sidebar
      padding=""
      width="
        bg-opacity-0 border-l-0 sm:m-8 md:m-0 md:p-8 lg:p-0 lg:mr-6 mr-0 lg:w-1/3 xl:w-80
        xl:min-w-80 2xl:w-100 2xl:min-w-100 rounded-lg mask-text"
    >
      <div className="bg-white rounded-lg pb-2">
        <div className="order-item-summary pb-0 p-6">
          <p className="pb-4 text-lg">
            Resumo do item
            {orderItem.orderItemNumber && (
              <>
                {' '}
                # <Clipboard text={orderItem.orderItemNumber} />
              </>
            )}
          </p>

          <Tooltip title={orderItem.name}>
            <div className="text-wrap">{orderItem.name}</div>
          </Tooltip>

          <div>
            Tipo:
            <Tooltip title={orderItem.service.name}>
              <div>{orderItem.service.name}</div>
            </Tooltip>
          </div>

          {orderItem.submitData.formato && (
            <div>
              Tipo de entrega:
              <Tooltip title={deliveryFormat[orderItem.submitData.formato]}>
                <div>{deliveryFormat[orderItem.submitData.formato]}</div>
              </Tooltip>
            </div>
          )}

          {orderItem.cpfOrCnpj && (
            <div>
              {orderItem.cpfOrCnpj.length > 15 ? 'CNPJ' : 'CPF'}
              <Tooltip title={orderItem.cpfOrCnpj}>
                <div>{orderItem.cpfOrCnpj}</div>
              </Tooltip>
            </div>
          )}

          <div>
            Solicitante:
            <Tooltip title={orderItem.user.name}>
              <div>{orderItem.user.name}</div>
            </Tooltip>
          </div>

          <div>
            Data da solicitação:
            <Tooltip title={orderItem.createdAtDisplay}>
              <div>{orderItem.createdAtDisplay}</div>
            </Tooltip>
          </div>

          {!!showShippingInfoOnOrders && !formattedFinishedAt && (
            <div>
              Prazo Estimado :
              <Tooltip
                title={
                  hasActiveChallenge
                    ? displayDate(orderItem.activeChallenge.order_new_estimated_at)
                    : formattedDeliveryTime
                }
              >
                <div>
                  {hasActiveChallenge
                    ? displayDate(orderItem.activeChallenge.order_new_estimated_at)
                    : formattedDeliveryTime}
                </div>
              </Tooltip>
            </div>
          )}

          {!!formattedFinishedAt && (
            <div>
              Data da entrega :
              <Tooltip title={formattedFinishedAt}>
                <div>{formattedFinishedAt}</div>
              </Tooltip>
            </div>
          )}

          <div className="!pb-0">
            {isPostPaid ? 'Custo estimado' : 'Créditos utilizados'}:
            <Tooltip title={orderItem.priceDisplay}>
              <div>{orderItem.priceDisplay}</div>
            </Tooltip>
          </div>
        </div>

        {React.Children.count(fullOrderElement?.props?.children.filter(Boolean)) > 0 && (
          <Collapse
            ghost
            className="view-order-collapse"
            expandIconPosition="end"
            expandIcon={({ isActive }) => {
              return (
                <Icon
                  className="transition text-primary"
                  style={{ transform: isActive ? 'rotate(180deg)' : '' }}
                  name="chevron-down-alt"
                  size={16}
                />
              )
            }}
            items={[
              {
                key: 'resume-data',
                label: (
                  <div className="flex leading-[24px] text-primary font-bold text-sm">Pedido completo</div>
                ),
                children: fullOrderElement,
              },
            ]}
          ></Collapse>
        )}
      </div>

      <ErrorBoundary>
        {(orderItem.submitData.arquivos || orderItem.submitData.arquivo) && (
          <div className="bg-white rounded-lg p-6 pb-4 mt-4 empty:hidden">
            <ItemAttachments />
          </div>
        )}
      </ErrorBoundary>

      <div className="bg-white rounded-lg p-6 mt-4">
        <ViewOrderGroups />
      </div>

      {orderItem.hasConsumedAiFreeAnalysis && orderItem.explorerItem?.aiModel?.hasAnswers && (
        <FreeAnalysisPopup position="bottom" />
      )}
    </Sidebar>
  )
}
