import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { useCheckPermission } from 'domain/auth'
import { useIsCustomerPostPaid } from 'domain/customers'
import { Icon, IconName } from 'ui'

type Props = { collapsed: boolean }

export default function SideNavOptions({ collapsed }: Props) {
  const isCustomerPostPaid = useIsCustomerPostPaid()
  const hasPermission = useCheckPermission()

  const navItems = useMemo(() => {
    return [
      {
        id: 'orders',
        icon: 'document',
        label: 'Meus pedidos',
        to: RoutePaths.ORDERS,
        show: hasPermission('canSeeOwnOrders') || hasPermission('canSeeOtherUsersOrders'),
      },
      {
        id: 'kits',
        icon: 'folder',
        label: 'Meus Kits',
        to: RoutePaths.KITS,
        show: hasPermission('canOrderServices'),
      },
      {
        id: 'recurrences',
        icon: 'repeat',
        label: 'Recorrências',
        to: RoutePaths.RECURRENCIES,
        show: hasPermission('canSeeOtherUsersOrders') || hasPermission('canSeeOwnOrders'),
      },
      {
        id: 'files',
        icon: 'attachment',
        label: 'Meus arquivos',
        to: RoutePaths.MY_FILES,
        show: true,
      },
      {
        id: 'credits',
        icon: 'money',
        label: 'Créditos',
        to: RoutePaths.CREDITS,
        show: !isCustomerPostPaid && hasPermission('canInsertCredits'),
      },
      {
        id: 'invoices',
        icon: 'invoice',
        label: 'Faturas',
        to: RoutePaths.INVOICES,
        show: hasPermission('canSeeInvoices'),
      },
      {
        id: 'reports',
        icon: 'chart',
        label: 'Relatórios',
        to: RoutePaths.REPORTS,
        show: hasPermission('canSeeReports'),
      },
    ]
  }, [hasPermission])

  return (
    <>
      {navItems.map(
        navItem =>
          navItem.show && (
            <NavLink
              key={navItem.id}
              exact
              to={navItem.to}
              className={`flex items-center text-white whitespace-nowrap relative text-base rounded  
                overflow-hidden text-ellipsis h-10 mb-2 pr-2 pt-1 transition-colors duration-200 easy-in  
                hover:no-underline hover:bg-secondary-300 [&.active]:bg-secondary-800 [&.active]:font-bold`}
            >
              <NavIcon icon={navItem.icon as IconName} collapsed={collapsed} />
              <div>{navItem.label}</div>
            </NavLink>
          )
      )}
    </>
  )
}

function NavIcon({ icon, collapsed }: { icon: IconName; collapsed: boolean }) {
  return (
    <span className={`${collapsed ? 'min-w-[60px]' : 'min-w-12'} flex justify-center items-center -mt-1.5`}>
      <Icon name={icon} size={22} />
    </span>
  )
}
