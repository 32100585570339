import { Checkbox } from 'antd'
import { CheckboxProps } from 'antd/lib/checkbox'

export interface ExtraCheckboxProps extends Omit<CheckboxProps, 'className'> {
  label: string
  description?: string
}

export default function ExtraCheckbox({ label, description, children, ...props }: ExtraCheckboxProps) {
  return (
    <Checkbox className="mb-4" {...props}>
      <div className="ml-3">
        <div className="mb-1">
          <b>{label}</b>
        </div>
        {description && <div className="text-2xs text-gray-600">{description}</div>}
        {children}
      </div>
    </Checkbox>
  )
}
