import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { atomURI } from 'utils/navigation'

import { RecurrencesSortField } from '../types'

// Current page
export const currentPageAtom = atom(1)
export const currentPageURIAtom = atomURI(currentPageAtom, 'p')

export const useCurrentPage = () => useAtom(currentPageURIAtom)
export const useGetCurrentPage = () => useAtomValue(currentPageAtom)
export const useSetCurrentPage = () => useSetAtom(currentPageURIAtom)

// Page size
const pageSizeAtom = atomWithStorage('recurrences-page-size', 20)
export const useGetPageSize = () => useAtomValue(pageSizeAtom)
export const usePageSize = () => useAtom(pageSizeAtom)

// Sort
const sortField = atomWithStorage<RecurrencesSortField>('recurrences-sort-field', undefined)
const sortDirection = atomWithStorage('recurrences-sort-order', 'descend')

const sortBy = atom(get => {
  const field = get(sortField)
  
  if (!field) {
    return ''
  }

  return get(sortDirection) === 'descend' ? `-${field}` : field
})

export const useGetSortBy = () => useAtomValue(sortBy)
export const useGetSortDirection = () => useAtomValue(sortDirection)
export const useSetSortDirection = () => useSetAtom(sortDirection)
export const useSetSortField = () => useSetAtom(sortField)

// Total pages
const pageCountResultsAtom = atom(0)
export const useSetPageCountResults = () => useSetAtom(pageCountResultsAtom)

// Total results
const totalResultsAtom = atom(0)
export const useGetTotalResults = () => useAtomValue(totalResultsAtom)
export const useSetTotalResults = () => useSetAtom(totalResultsAtom)
