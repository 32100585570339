import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs, UploadStep } from 'domain/createOrder/document'
import { CNPJAndNameFields, NameField, TextField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from './certidaoBaixaProtestoConfig'

export default function CreateOrderCertidaoBaixaProtesto() {
  return (
    <CertificateFlow
      uploadStep={
        <UploadStep headingTitle="Anexe a carta de anuência" maxCount={1}>
          <TextField field={observacao} />
        </UploadStep>
      }
      renderFormItem={(itemId: number) => <CertidaoBaixaProtestoForm itemId={itemId} />}
      disableMultiItems
      noReplicateData
    />
  )
}

const CertidaoBaixaProtestoForm = ({ itemId }: { itemId: number }) => {
  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={
          <>
            <CPFInput itemId={itemId} autoFocus />
            <NameField itemId={itemId} field={nome_pf} />
          </>
        }
        juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} autoFocus />}
      />

      <TextField field={numero_titulo} min="0" itemId={itemId} />
    </>
  )
}

const { nome_pf, nome_pj, numero_titulo, observacao } = config.formFields
