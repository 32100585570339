import React, { useMemo } from 'react'
import { notification } from 'antd'

import useChangeRecurrenceStatus from 'domain/recurrences/services/useChangeRecurrenceStatus'
import { Recurrence } from 'domain/recurrences/types'
import { Icon } from 'ui'
import { displayDate } from 'utils/dateTime'

export interface ToggleRecurrenceActionProps {
  recurrence: Recurrence
  setRecurrenceToInactivate: React.Dispatch<React.SetStateAction<Recurrence | undefined>>
  queryKey: any[]
}

function useToggleRecurrenceAction({
  recurrence,
  setRecurrenceToInactivate,
  queryKey,
}: ToggleRecurrenceActionProps) {
  const { mutate: activateRecurrence } = useChangeRecurrenceStatus({
    queryKey,
    onSuccess: data => {
      notification.success({
        message: 'Recorrência ativada com sucesso.',
        description: `Sua recorrência foi reativada, a próxima execução será na data ${displayDate(
          data.data.next_at
        )}`,
      })
    },
  })

  return useMemo(() => {
    return {
      key: 'disable',
      icon: <Icon name={recurrence.active ? 'trash' : 'calendar'} size={20} className="mr-2 -mt-1" />,
      label: <span>{recurrence.active ? 'Desativar' : 'Ativar'} recorrência</span>,
      onClick: () => {
        if (recurrence.active) {
          setRecurrenceToInactivate(recurrence)
        } else {
          activateRecurrence({ id: recurrence.id, active: true })
        }
      },
    }
  }, [recurrence])
}

export default useToggleRecurrenceAction
