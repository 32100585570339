import React, { useEffect, useState } from 'react'
import { Button } from 'antd'

import { FormShippingAddressData } from 'domain/app/types'
import { useSaveShippingAddress } from 'domain/checkout/services'
import { ShippingAddress } from 'domain/checkout/types'
import { Modal } from 'ui'

import ShippingAddressForm from './AddShippingAddressForm'

interface Props {
  buttonLabel: string
  open?: boolean
  isMainAddress?: boolean
  description?: string
  onSubmit: (address: ShippingAddress) => void
}

export default function AddShippingAddress({
  onSubmit,
  open,
  description,
  buttonLabel,
  isMainAddress,
}: Props) {
  const [modalVisible, setModalVisible] = useState(open)

  const [form, saveAddress, status, error] = useSaveShippingAddress()

  const handleSubmit = async (values: FormShippingAddressData) => {
    const address = await saveAddress(values, !!isMainAddress)

    form.resetFields()

    onSubmit(address)
    setModalVisible(false)
  }

  useEffect(() => {
    setModalVisible(open)
  }, [open])

  return (
    <>
      <Button type="link" onClick={() => setModalVisible(true)} className="my-3">
        {buttonLabel}
      </Button>

      <Modal open={modalVisible} title="Adicionar endereço" onCancel={() => setModalVisible(false)}>
        {description && <p className="mb-5">{description}</p>}

        <ShippingAddressForm
          form={form}
          onSubmit={handleSubmit}
          isMainAddress={isMainAddress}
          status={status}
          error={error}
        />
      </Modal>
    </>
  )
}
