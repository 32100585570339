import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { WalletDepositParamsAPI, WalletDepositResponseAPI } from '../typesAPI'

export const walletDepositAPI = async (params: WalletDepositParamsAPI) => {
  const response: AxiosResponse<WalletDepositResponseAPI> = await AuthorizedAPI.post(
    '/wallets/deposits',
    params
  )
  return response
}
