import { useMutation } from 'react-query'
import { message } from 'antd'

import { AuthorizedAPI } from 'api/network/v2'
import { errorHandler } from 'api/errors'
import queryClient from 'app/config/queryClient'

export default function useDeleteMyModels({ onSuccess }: { onSuccess?: () => void } = {}) {
  return useMutation(
    (modelsIds: number | number[]) => {
      const ids = Array.isArray(modelsIds) ? modelsIds.join(',') : modelsIds
      return AuthorizedAPI.delete(`/ai-models/${ids}`)
    },
    {
      onError: error => {
        errorHandler(error, { code: '0x807' })
      },
      onSuccess: (res, userId: number | number[]) => {
        message.success(
          Array.isArray(userId) && userId.length > 1
            ? 'Modelos excluídos com sucesso!'
            : 'Modelo excluído com sucesso!'
        )
        queryClient.invalidateQueries(['ai-models', 'list'])

        onSuccess && onSuccess()
      },
    }
  )
}
