import { Link } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { useShoppingCartState } from 'domain/shoppingCart'
import { Icon } from 'ui'

export default function TopBarCart() {
  const shoppingCartOrders = useShoppingCartState().orders

  return (
    <Link className="relative" to={RoutePaths.SHOPPING_CART}>
      <Icon name="cart" className="text-secondary-500" size={24} />
      {shoppingCartOrders.length > 0 && (
        <b className="bg-red-400 text-white absolute text-xs -top-1 left-4 px-1 py-1 h-5 rounded flex items-center justify-center">
          {shoppingCartOrders.length}
        </b>
      )}
    </Link>
  )
}
