import { ResultCode, StatusCode } from 'domain/orders/types'
import { periodAtom, periodAtomURIAtom } from 'domain/reports/filters'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomURI } from 'utils/navigation'

export const initialFilters = {
  searchTerm: '',
  dateRange: undefined,
  ownersIds: [],
  servicesIds: [],
  statuses: [],
  results: [],
  isExpired: undefined,
}

const searchTermAtom = atom<string>('')
const ownersIdsAtom = atom<number[]>([])
const servicesIdsAtom = atom<number[]>([])
const statusesAtom = atom<StatusCode[]>([])
const resultsAtom = atom<ResultCode[]>([])
const isExpiredAtom = atom<boolean | undefined>(undefined)

const searchTermUriAtom = atomURI(searchTermAtom, 'q') 
const ownersIdsUriAtom = atomURI(ownersIdsAtom, 'owner')
const servicesIdsUriAtom = atomURI(servicesIdsAtom, 'service')
const statusesUriAtom = atomURI(statusesAtom, 'status')
const resultsUriAtom = atomURI(resultsAtom, 'result')
const isExpiredUriAtom = atomURI(isExpiredAtom, 'expired')

const filters = atom(
  get => {
    const filters = {
      dateRange: get(periodAtom),
      searchTerm: get(searchTermAtom),
      servicesIds: get(servicesIdsAtom),
      ownersIds: get(ownersIdsAtom),
      statuses: get(statusesAtom),
      results: get(resultsAtom),
      isExpired: get(isExpiredAtom),
    }

    return filters
  },
  (_, set, filters: any) => {
    set(searchTermUriAtom, filters.searchTerm)
    set(periodAtomURIAtom, filters.dateRange)
    set(ownersIdsUriAtom, filters.ownersIds)
    set(servicesIdsUriAtom , filters.servicesIds)
    set(statusesUriAtom , filters.statuses)
    set(resultsUriAtom, filters.results)
    set(isExpiredUriAtom, filters.isExpired)
  }
)

export const useFilters = () => useAtom(filters)
export const useExpiredFilter = () => useAtom(isExpiredUriAtom)
export const useStatusFilter = () => useAtom(statusesUriAtom)
export const useResultsFilter = () => useAtom(resultsUriAtom)

export const useGetFilters = () => useAtomValue(filters)

export const useSetFilters = () => useSetAtom(filters)
export const useSetIsExpired = () => useSetAtom(isExpiredUriAtom)
export const useSetOwners = () => useSetAtom(ownersIdsUriAtom)
export const useSetSearch = () => useSetAtom(searchTermUriAtom)
export const useSetServices = () => useSetAtom(servicesIdsUriAtom)
export const useSetStatuses = () => useSetAtom(statusesUriAtom)
export const useSetResults = () => useSetAtom(resultsUriAtom)
