import { DueDiligence } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { DESCRITIVO_DOCUMENTO, LOCAL_SERVICO, MENSAGEM, UPLOAD } = FieldName

type DiligenciaFields = Record<
  FieldName.DESCRITIVO_DOCUMENTO | FieldName.LOCAL_SERVICO | FieldName.MENSAGEM | FieldName.UPLOAD,
  FieldConfig
>

class DiligenciaPreviaCustas extends DueDiligence {
  formFields: DiligenciaFields = {
    [LOCAL_SERVICO]: Fields.local_servico,
    [MENSAGEM]: Fields.mensagem,
    [DESCRITIVO_DOCUMENTO]: Fields.descritivo_documento,
    [UPLOAD]: Fields[UPLOAD],
  }

  constructor() {
    super({
      id: 90,
      shortName: 'Diligência - Prévia de Custas',
    })
  }
}

const service = new DiligenciaPreviaCustas().initialize()

export default service
