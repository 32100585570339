import { useState } from 'react'
import { message } from 'antd'

import { AuthorizedAPI } from 'api/network/v2'
import { resolveFileName, saveAs } from './exportHelpers'

export default function useDownload(): [(url: string, filename?: string) => Promise<void>, boolean] {
  const [loading, setLoading] = useState(false)

  const handleDownload = async (url: string, fallbackFileName = 'cbrdoc') => {
    setLoading(true)
    const hide = message.loading('Preparando o download...')

    try {
      const res = await AuthorizedAPI.get(url, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/zip, application/pdf',
          Accept: 'application/zip, application/pdf, text/plain, */*',
        },
      })

      if (res) {
        const fileName = resolveFileName(res.headers, fallbackFileName)
        const blobData = new Blob([res.data])

        if (blobData.size > 0) {
          saveAs(new Blob([res.data], { type: res.headers['content-type'] }), fileName)
        }

        handleDownloadCodeResponse(res)
      }

      setLoading(false)
    } catch (err) {
      setLoading(false)
      throw err
    } finally {
      hide()
    }
  }

  return [handleDownload, loading]
}

function handleDownloadCodeResponse(response: any) {
  const code = response.status

  switch (code) {
    case 404:
      message.error('Os ids dos documentos não foram encontrados.')
      break
    case 202:
      message.warning(
        'Alguns documentos selecionados não foram baixados porque ainda estão sendo disponibilizados. Você pode tentar novamente mais tarde.'
      )
      break
    case 200:
      message.success('Download com sucesso!')
      break
    default:
      break
  }
}
