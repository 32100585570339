import React, { useState } from 'react'

import { Pix as PixView } from 'ui'

import { ChosenPaymentMethod } from '../types'
import PixForm from './PixForm'

interface Props {
  paymentValue: number
  isAddingCredits?: boolean
  onSuccess?: (method: ChosenPaymentMethod) => void
}

function Pix({ onSuccess, isAddingCredits, paymentValue }: Props) {
  const [pixData, setPixData] = useState<ChosenPaymentMethod | undefined>()

  const handleSuccess = async (pix?: ChosenPaymentMethod) => {
    if (pix) {
      setPixData(pix)
      onSuccess && onSuccess(pix)
    }
  }

  if (pixData && isAddingCredits) {
    return <PixView pixKey={pixData.pix!.pixKey} qrCode={pixData.pix!.qrCode!} />
  }

  return <PixForm paymentValue={paymentValue} isAddingCredits={isAddingCredits} onSuccess={handleSuccess} />
}

export default Pix
