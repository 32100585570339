import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

import { PeriodString } from 'domain/app/types'
import { OrdersFilters, ResultCode, StatusCode } from 'domain/orders/types'
import { checkForFilters } from 'utils/validators'
import { atomURI } from 'utils/navigation'
import { currentPageURI, itemsCurrentPageURI } from './pagination'

export const initialFilters: OrdersFilters = {
  ai: false,
  automaticGenerated: false,
  dateRange: undefined,
  isOCRSearch: false,
  isExpired: undefined,
  groupsIds: [],
  ownersIds: [],
  onlyFromRecurrence: false,
  recurrenceId: undefined,
  results: [],
  searchTerm: '',
  servicesIds: [],
  statuses: [],
}

const automaticGeneratedAtom = atom(false)
const dateRangeAtom = atom<PeriodString | undefined>(undefined)
const isAiAtom = atom(false)
const isExpiredAtom = atom<boolean | undefined>(undefined)
const isOCRSearchAtom = atom(false)
const groupsIdsAtom = atom<number[]>([])
const onlyFromRecurrenceAtom = atom(false)
const ownersIdsAtom = atom<number[]>([])
const recurrenceIdAtom = atom<number | undefined>(undefined)
const resultsAtom = atom<ResultCode[]>([])
const searchTermAtom = atom<string>('')
const servicesIdsAtom = atom<number[]>([])
const statusesAtom = atom<StatusCode[]>([])

const automaticGeneratedURIAtom = atomURI(automaticGeneratedAtom, 'automatic', currentPageURI)
const dateRangeURIAtom = atomURI(dateRangeAtom, 'dates', currentPageURI)
const isAiURIAtom = atomURI(isAiAtom, 'ai', currentPageURI)
const isExpiredURIAtom = atomURI(isExpiredAtom, 'expired', currentPageURI)
const isOCRSearchURIAtom = atomURI(isOCRSearchAtom, 'ocr', currentPageURI)
const groupsIdsURIAtom = atomURI(groupsIdsAtom, 'groups', currentPageURI)
const onlyFromRecurrenceURIAtom = atomURI(onlyFromRecurrenceAtom, 'from_recurrence', currentPageURI)
const ownersIdsURIAtom = atomURI(ownersIdsAtom, 'owners', currentPageURI)
const recurrenceIdURIAtom = atomURI(recurrenceIdAtom, 'recurrence', currentPageURI)
const resultsURIAtom = atomURI<ResultCode[]>(resultsAtom, 'results', currentPageURI)
const searchTermURIAtom = atomURI(searchTermAtom, 'q', currentPageURI)
const servicesIdsURIAtom = atomURI(servicesIdsAtom, 'services', currentPageURI)
const statusesURIAtom = atomURI<StatusCode[]>(statusesAtom, 'status', currentPageURI)

const filters = atom(
  get => {
    const filters: OrdersFilters = {
      ai: get(isAiAtom),
      automaticGenerated: get(automaticGeneratedURIAtom),
      dateRange: get(dateRangeAtom),
      groupsIds: get(groupsIdsAtom),
      searchTerm: get(searchTermAtom),
      servicesIds: get(servicesIdsAtom),
      isExpired: get(isExpiredAtom),
      isOCRSearch: get(isOCRSearchAtom),
      onlyFromRecurrence: get(onlyFromRecurrenceAtom),
      ownersIds: get(ownersIdsAtom),
      recurrenceId: get(recurrenceIdAtom),
      results: get(resultsAtom),
      statuses: get(statusesAtom),
    }

    return filters
  },
  (get, set, filters: OrdersFilters) => {
    set(currentPageURI, 1)
    set(itemsCurrentPageURI, 1)
    set(isAiURIAtom, filters.ai)
    set(automaticGeneratedURIAtom, filters.automaticGenerated)
    set(dateRangeURIAtom, filters.dateRange)
    set(groupsIdsURIAtom, filters.groupsIds)
    set(searchTermURIAtom, filters.searchTerm)
    set(servicesIdsURIAtom, filters.servicesIds)
    set(isExpiredURIAtom, filters.isExpired)
    set(isOCRSearchURIAtom, filters.isOCRSearch)
    set(onlyFromRecurrenceURIAtom, filters.onlyFromRecurrence)
    set(ownersIdsURIAtom, filters.ownersIds)
    set(recurrenceIdURIAtom, filters.recurrenceId)
    set(resultsURIAtom, filters.results)
    set(statusesURIAtom, filters.statuses)
  }
)

export const useFilters = () => useAtom(filters)
export const useIsExpired = () => useAtom(isExpiredURIAtom)
export const useIsOCRSearch = () => useAtom(isOCRSearchURIAtom)
export const useOwnersIds = () => useAtom(ownersIdsURIAtom)
export const useResults = () => useAtom(resultsURIAtom)
export const useSearchTerm = () => useAtom(searchTermURIAtom)
export const useStatuses = () => useAtom(statusesURIAtom)

export const useGetAutomaticGenerated = () => useAtomValue(automaticGeneratedAtom)
export const useGetFilters = () => useAtomValue(filters)
export const useGetIsOCRSearch = () => useAtomValue(isOCRSearchAtom)
export const useGetSearchTerm = () => useAtomValue(searchTermAtom)

export const useSetAutomaticGenerated = () => useSetAtom(automaticGeneratedURIAtom)
export const useSetDatesRange = () => useSetAtom(dateRangeURIAtom)
export const useSetGroupsIds = () => useSetAtom(groupsIdsURIAtom)
export const useSetIsAI = () => useSetAtom(isAiURIAtom)
export const useSetIsExpired = () => useSetAtom(isExpiredURIAtom)
export const useSetIsOCRSearch = () => useSetAtom(isOCRSearchURIAtom)
export const useSetOnlyFromRecurrence = () => useSetAtom(onlyFromRecurrenceURIAtom)
export const useSetOwnersIds = () => useSetAtom(ownersIdsURIAtom)
export const useSetRecurrenceId = () => useSetAtom(recurrenceIdURIAtom)
export const useSetResults = () => useSetAtom(resultsURIAtom)
export const useSetSearchTerm = () => useSetAtom(searchTermURIAtom)
export const useSetServicesIds = () => useSetAtom(servicesIdsURIAtom)
export const useSetStatuses = () => useSetAtom(statusesURIAtom)

export const useClearFilters = () => {
  const setFilters = useSetAtom(filters)

  return function clearFilters() {
    setFilters(initialFilters)
  }
}

const hasFilters = atom(get => checkForFilters(get(filters)))
export const useHasFilters = () => useAtomValue(hasFilters)
