import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { FORMATO, TIPO_PESSOA } = FieldName

type CertidaoMTTrabalhistasFields = Record<FieldName.CNPJ | FieldName.CPF, FieldConfig>

type CertidaoMTTrabalhistasDynamicFields = Record<FieldName.GRUPO | FieldName.SITUACAO, FieldConfig>

class CertidaoMTTrabalhistas extends Certificate {
  formFields: CertidaoMTTrabalhistasFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
  }

  dynamicFields: CertidaoMTTrabalhistasDynamicFields = {
    grupo: {
      ...Fields.grupo,
      label: 'Grupo de infrações',
      labelInValue: true,
    },
    situacao: Fields.situacao,
  }

  constructor() {
    super({
      shoudAutoLoadDynamicFields: true,
      id: 34,
      priceByFields: [FORMATO, TIPO_PESSOA],
      shortName: 'Certidão MT Trabalhistas',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoMTTrabalhistas().initialize()

export default service
