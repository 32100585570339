import React from 'react'

import ShareOrderModalItem from './ShareOrderModalItem'
import useShareOrderItem from '../../services/useShareOrderItem'

interface Props {
  orderItemId: string | number
}

function ShareOrderModalWhats({ orderItemId }: Props) {
  const [shareOrder, loading] = useShareOrderItem()

  const onClick = () => {
    shareOrder({
      orderItemId,
      params: { via: 'whatsapp' },
      onSuccess: data => {
        if (data?.generated_link) {
          window.open(data.generated_link)
        }
      },
    })
  }

  return <ShareOrderModalItem text="Whatsapp" icon="whatsapp" onClick={onClick} loading={loading} />
}

export default ShareOrderModalWhats
