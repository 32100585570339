import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { createOrderActions } from 'domain/createOrder/document'

export default function useResetCreateOrder() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(createOrderActions.resetState())
  }, [])
}
