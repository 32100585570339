import React, { useState } from 'react'
import { Form } from 'antd'

import { errorHandler } from 'api/errors'
import { AuthorizedAPI } from 'api/network/v2'
import { Select, SubmitButton, TextAreaInput } from 'ui'

interface Props {
  setReportedSuccess: React.Dispatch<React.SetStateAction<boolean>>
  orderItemId: string | number
}

function ReportProblemModalForm({ orderItemId, setReportedSuccess }: Props) {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const onSubmit = async (values: any) => {
    try {
      setLoading(true)
      reportProblem(orderItemId, values.subject, values.description)
      setReportedSuccess(true)
    } catch (error) {
      errorHandler(error, { code: '0x731' })
    }
  }

  return (
    <>
      <p className="pb-6 mb-6 border-b border-gray-300">
        Algo inesperado aconteceu com seu pedido? Descreva com detalhes no campo abaixo e iremos te ajudar.
      </p>

      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        requiredMark={false}
        scrollToFirstError
        className="max-w-lg"
      >
        <Form.Item name="subject" label="Assunto" rules={requiredRule}>
          <Select placeholder="Selecione um assunto" options={options} autoFocus />
        </Form.Item>

        <TextAreaInput name="description" label="Mensagem" className="h-40" maxLength={32000} required />

        <SubmitButton label="Enviar" loading={loading} />
      </Form>
    </>
  )
}

const requiredRule = [
  {
    required: true,
    message: 'Assunto é obrigatório',
  },
]

const options = [
  { label: 'Erro ortográfico', value: 'Erro ortográfico' },
  { label: 'Informação divergente', value: 'Informação divergente' },
  { label: 'Outros', value: 'Outros' },
]

const reportProblem = async (orderId: string | number, subject: string, description: string) => {
  const response = await AuthorizedAPI.post(`/orders/${orderId}/problem`, { subject, description })
  return response.data
}

export default ReportProblemModalForm
