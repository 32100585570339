import { useCurrentUser } from '../state'
import { PermissionName } from '../types'

export function useHasPermission(permissionName: PermissionName) {
  const user = useCurrentUser()
  return user.permissions.some(p => p.code === permissionName)
}

export function useIsAdmin() {
  const user = useCurrentUser()
  return user.permissions.some(p => p.code === 'canManageUsersAndCompanyData')
}

export const useHasRecurrencePermission = () => {
  const isAdmin = useIsAdmin()

  return isAdmin
}
