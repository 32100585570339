import React from 'react'
import { Select, SelectProps } from 'antd'

const { Option } = Select

export default function SelectCopies(props: SelectProps) {
  return (
    <Select placeholder="Selecione" defaultValue={1} {...props}>
      <Option value={1}>1 Cópia</Option>
      <Option value={2}>2 Cópias</Option>
      <Option value={3}>3 Cópias</Option>
    </Select>
  )
}
