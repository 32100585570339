import React from 'react'

import { useCurrentCustomer } from 'domain/customers'
import { useGetSelectedMonth } from 'domain/invoices/state'
import ExportButton from 'domain/invoices/components/ExportButton'

import generatePrepaidPDF from '../helpers/generatePrepaidPDF'
import getPrepaidInvoices from '../services/getPrepaidInvoicesReport'

function ExportPrepaidInvoicesToPDF() {
  const selectedMonth = useGetSelectedMonth()
  const customer = useCurrentCustomer()

  return (
    <ExportButton
      kind="pdf"
      fetchData={async () => {
        const res = await getPrepaidInvoices(selectedMonth.getFullYear(), selectedMonth.getMonth())
        
        if (res) {
          await generatePrepaidPDF({ customer, tableData: res, month: selectedMonth })
        }
      }}
    />
  )
}

export default ExportPrepaidInvoicesToPDF
