import { useState } from 'react'

import { AuthorizedAPI } from 'api/network/v2'
import { ERRORS } from 'api/errors'
import { ApiError, ApiStatus } from 'api/types'
import queryClient from 'app/config/queryClient'
import { Customer, CustomerAPI } from 'domain/customers/types'
import { removeUndefined } from 'utils/transformData'

function useUpdateCustomer() {
  const [error, setError] = useState<ApiError | null>(null)
  const [status, setStatus] = useState<ApiStatus>('idle')

  const updateCustomer = async (customer: Partial<CustomerAPI>, onSuccess?: (data: Customer) => void) => {
    try {
      setStatus('loading')
      setError(null)

      const { data } = await AuthorizedAPI.patch('/customers', removeUndefined(customer))

      queryClient.invalidateQueries(['current-customer'])

      setStatus('success')
      onSuccess && onSuccess(data)
    } catch (err) {
      setStatus('error')
      if ([401, 403].includes((err as any).response?.status)) {
        setError(ERRORS.invalidCredentials)
      } else {
        setError(ERRORS.internalServer)
      }
    }
  }

  return { updateCustomer, status, error }
}

export default useUpdateCustomer
