import React, { useEffect, useState } from 'react'

import { useHasPermission } from 'domain/auth'
import { OrderOverview, StatusCode } from 'domain/orders/types'
import { DrawerActions } from 'ui'

import QuotationApprove from './OrderPriceQuotationApprove'
import QuotationReject from './OrderPriceQuotationReject'

interface Props {
  order: OrderOverview
}

function OrderPriceQuotation({ order }: Props) {
  const canApproveQuotation = useHasPermission('canApprovePurchasesQuotes')
  const [open, setOpen] = useState(order.statusCode === StatusCode.AGUARDANDO_APROVACAO)

  useEffect(() => {
    if (order.statusCode !== StatusCode.AGUARDANDO_APROVACAO) {
      setOpen(false)
    }
  }, [order.statusCode])

  return (
    <DrawerActions
      title="Pedido Aguardando Aprovação"
      open={open}
      onClose={() => setOpen(false)}
      className="flex flex-row justify-between"
      closeable={!canApproveQuotation}
      height="auto"
    >
      {canApproveQuotation ? (
        <OrderQuotationOptions order={order} />
      ) : (
        <p>
          Esse pedido foi orçado e excede o limite definido pela empresa. Solicite a aprovação de um usuário
          com acesso para continuar com o pedido.
        </p>
      )}
    </DrawerActions>
  )
}

function OrderQuotationOptions({ order }: Props) {
  return (
    <>
      <p>
        Este pedido foi orçado e ultrapassou o limite definido pela empresa, portanto precisa de aprovação.
      </p>

      <p>
        Total do pedido: <strong className="text-2xl">{order.priceQuotationDisplay}</strong>
      </p>

      <div className="w-fit mr-8">
        <div className="flex flex-row gap-2">
          <QuotationReject orderId={order.id} />
          <QuotationApprove orderId={order.id} />
        </div>
      </div>
    </>
  )
}

export default OrderPriceQuotation
