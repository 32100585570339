class MissingDataFromBackendError extends Error {
  code: string

  constructor(message: string, code: string) {
    super(message)
    Object.setPrototypeOf(this, MissingDataFromBackendError.prototype)

    this.code = code
  }
}

export default MissingDataFromBackendError
