import { useMutation } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'
import { updateItemOnArrayById } from 'utils/transformData'
import { Recurrence } from '../types'

type Params = {
  id: number
  active: boolean
}

type Props = {
  queryKey: any[]
  onSuccess: (data: any) => void
}

function useChangeRecurrenceStatus({ queryKey, onSuccess }: Props) {
  return useMutation(
    async ({ id, active }: Params) => {
      return await AuthorizedAPI.patch(`/recurrences/${id}/${active ? 'active' : 'inactive'}`)
    },
    {
      onMutate: async ({ id, active }: Params) => {
        const previousList = queryClient.getQueryData(queryKey) as Recurrence[]

        queryClient.setQueryData(queryKey, updateItemOnArrayById(previousList, { id, active }))

        return { previousList }
      },
      onError: (data, variables, context: any) => {
        queryClient.setQueryData(queryKey, context.previousList)
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['recurrences', 'active'])
        onSuccess(data)
      },
    }
  )
}

export default useChangeRecurrenceStatus
