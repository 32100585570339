import { LegalEntity } from 'domain/app/types'
import { getService } from 'domain/servicesCBRdoc'
import { ServiceCode } from 'domain/servicesCBRdoc/types'

function resolveLegalEntity(serviceCode: ServiceCode, legalEntity: LegalEntity | null = null) {
  if (!serviceCode) return null

  const serviceConfig = getService(serviceCode)

  if (!serviceConfig.legalEntity) {
    return null
  }

  if (serviceConfig.legalEntity === 'fisica' || serviceConfig.legalEntity === 'juridica') {
    return serviceConfig.legalEntity
  }

  return legalEntity ?? 'fisica'
}

export default resolveLegalEntity
