import { atom, useAtomValue } from 'jotai'

import { LegalEntity } from 'domain/app/types'
import { KitItem } from 'domain/kits/types'
import { Fields, getFieldConfigByAPIName, getServiceConfig } from 'domain/servicesCBRdoc'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'

import { KitCommonFormConfig, KitCommonFormServiceConfig, KitFieldConfig } from '../types'
import { legalEntityAtom, servicesFormDataAtom } from './servicesForm'
import { dynamicFieldsAtom } from './dynamicFields'
import { kitAtom } from './kit'

export const detailsFieldsConfigAtom = atom<KitCommonFormConfig>(get => {
  const kit = get(kitAtom)
  const selectedLegalEntity = get(legalEntityAtom)
  const servicesForm = get(servicesFormDataAtom)
  const allFieldsConfig: Partial<Record<FieldName, KitFieldConfig>> = {}

  // Determina quais campos são comuns entre as certidões do kit
  const getDetailsFieldsConfig = () => {
    for (const kitItem of kit.items) {
      const fields = getDetailsFieldsToShow(kitItem)
      fields.forEach(mapFieldOrMarkAsRequired)
    }

    mergeWithDynamicFields()

    return sortAndSeparateRegistreeFieldsFromServiceFields()
  }

  const sortAndSeparateRegistreeFieldsFromServiceFields = () => {
    const registreeFields: KitFieldConfig[] = []
    const serviceFields: Record<number, KitCommonFormServiceConfig> = {}
    const allFields = Object.values(allFieldsConfig)

    for (const field of allFields) {
      if (field.dataScope === 'registree') {
        registreeFields.push(field)
      } else if (field.name !== FieldName.TIPO_PESSOA) {
        const kitItem = field.kitItem!
        const { id } = kitItem

        if (!serviceFields[id]) {
          serviceFields[id] = {
            kitItem,
            fields: [field],
          }
        } else {
          serviceFields[id].fields.push(field)
        }
      }
    }

    return {
      registreeFields: sortRegistreeFields(registreeFields),
      serviceFields: Object.values(serviceFields),
    }
  }

  const sortRegistreeFields = (fields: KitFieldConfig[]) => {
    const sortedFields: any = {}
    const otherFields: any = []

    fields.forEach(field => {
      const order = RegistreeOrderFields[field.name]

      if (order) {
        sortedFields[order] = field
      } else {
        otherFields.push(field)
      }
    })

    return [...Object.values(sortedFields), ...otherFields]
  }

  const checkEntity = (legalEntity: LegalEntity | undefined) => {
    return !legalEntity || !kit.legalEntity || legalEntity === selectedLegalEntity
  }

  const getDetailsFieldsToShow = (kitItem: KitItem): KitFieldConfig[] => {
    const config = getServiceConfig(kitItem.service.code)

    const fields = Object.values(config.formFields).filter(
      ({ dataScope, ignoreOnKitsAutomaticForm, legalEntity, showIf }) => {
        if (dataScope === 'service' || ignoreOnKitsAutomaticForm) return false
        if (!checkEntity(legalEntity)) return false

        if (showIf) {
          if (!servicesForm[kitItem.id]) return false
          return showIf(servicesForm[kitItem.id])
        }

        return true
      }
    )

    return fields.map(field => ({ ...field, kitItem }))
  }

  const normalizeDivergentFieldNames = (field: KitFieldConfig) => {
    if (field.name === FieldName.RAZAO_SOCIAL) {
      return { ...field, name: FieldName.NOME_PJ }
    }

    if (field.kitFieldEquivalent) {
      if (field.kitFieldEquivalent === FieldName.NOME_PF) {
        return Fields.nome_pf
      }

      if (field.kitFieldEquivalent === FieldName.NOME_PJ) {
        return Fields.nome_pj
      }

      return getFieldConfigByAPIName(field.kitFieldEquivalent)!
    }

    return field
  }

  const mapFieldOrMarkAsRequired = (fieldConfig: KitFieldConfig) => {
    const field = normalizeDivergentFieldNames(fieldConfig)
    const alreadyExists = allFieldsConfig[field.name] !== undefined

    if (!alreadyExists) {
      allFieldsConfig[field.name] = field
    }
    // se um campo já foi configurado como opcional e outra certidão tem ele como obrigatório
    // definimos esse campo como obrigatório para todo o kit
    else if (!fieldConfig.optional) {
      allFieldsConfig[field.name]!.optional = false
    }
  }

  const mergeWithDynamicFields = () => {
    const allDynamicFields = get(dynamicFieldsAtom)

    for (const kitItem of kit.items) {
      allDynamicFields[kitItem.id]?.forEach(field => {
        if (field.dataScope === 'registree') {
          if (field.apiName === 'nome') {
            const { nome_pf, nome_pj } = getServiceConfig(kitItem.service.code).dynamicFields

            mapFieldOrMarkAsRequired(
              selectedLegalEntity === 'juridica' ? nome_pj ?? Fields.nome_pj : nome_pf ?? Fields.nome_pf
            )
          } else {
            mapFieldOrMarkAsRequired(field)
          }
        } else if (field.dataScope !== 'service') {
          if (field.name === 'endereco') {
            mapFieldOrMarkAsRequired({ ...field, kitItem })
          } else {
            mapFieldOrMarkAsRequired({ ...field, kitItem })
          }

          // if (field.name === 'endereco') {
          //   const serviceConfig = getServiceConfig(kitItem.service.code)
          //   const zipCodeField = serviceConfig.allFields.find(field => field.name === 'cep')

          //   if (zipCodeField) {
          //     mapFieldOrMarkAsRequired({ ...zipCodeField, kitItem })
          //   }
          // }
        }
      })
    }

    if (allFieldsConfig.registrado_endereco_cep && allFieldsConfig.registrado_endereco_completo) {
      delete allFieldsConfig.registrado_endereco_cep
    }
  }

  return getDetailsFieldsConfig()
})
detailsFieldsConfigAtom.debugLabel = 'createOrder/kit/detailsFieldsConfigAtom'

export const useGetDetailsFieldsConfig = () => useAtomValue(detailsFieldsConfigAtom)

const entityFields = atom(get => {
  const allCommonFields = get(detailsFieldsConfigAtom).registreeFields
  const selectedEntityType = get(legalEntityAtom)

  return allCommonFields.filter(field => {
    return !field.legalEntity || field.legalEntity === selectedEntityType
  })
})
entityFields.debugLabel = 'createOrder/kit/entityFields'

export const useGetEntityFieldsConfig = () => useAtomValue(entityFields)

const RegistreeOrderFields: Partial<Record<FieldName, number>> = {
  [FieldName.CPF]: 11,
  [FieldName.CNPJ]: 12,
  [FieldName.NOME]: 21,
  [FieldName.NOME_PF]: 22,
  [FieldName.NOME_PJ]: 23,
  [FieldName.NOME_MAE]: 24,
  [FieldName.NOME_PAI]: 25,
  [FieldName.CONJUGE2]: 31,
  [FieldName.CONJUGE2_CPF]: 32,
  [FieldName.DATA_CASAMENTO]: 33,
  [FieldName.GENERO]: 40,
  [FieldName.DATA_NASCIMENTO]: 41,
  [FieldName.CIDADE_NASCIMENTO_IBGE]: 42,
  [FieldName.UF_NASCIMENTO]: 43,
  [FieldName.NATURALIDADE]: 44,
  [FieldName.NACIONALIDADE]: 45,
  [FieldName.RG]: 51,
  [FieldName.RG_DATA_EXPEDICAO]: 52,
  [FieldName.RG_EXPEDIDOR]: 53,
  [FieldName.RG_UF]: 54,
}
