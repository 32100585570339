import React from 'react'
import { ItemType } from 'antd/lib/menu/hooks/useItems'

import { Recurrence } from 'domain/recurrences/types'
import { Icon } from 'ui'
import { useDownload } from 'utils/export'

function useDownloadOrdersReportAction(recurrence: Recurrence): ItemType {
  const [download, loading] = useDownload()
  const disabled = recurrence.ordersIds.length === 0

  return {
    key: 'download',
    icon: <Icon name="download" size={20} className="mr-2 -mt-1" />,
    label: 'Baixar Pedidos',
    disabled,
    title: disabled || loading ? 'Ainda não há pedidos feitos por esta recorrência' : undefined,
    onClick: async () => {
      await download(`/purchases/${recurrence.ordersIds.join(',')}/report/xlsx`)
    },
  }
}

export default useDownloadOrdersReportAction
