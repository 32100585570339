import { useQuery } from 'react-query'

import { AuthorizedAPI as API } from 'api/network/v2'
import { useCurrentUser } from 'domain/auth'
import { formatDate } from 'utils/dateTime'

import {
  useCurrentPage,
  useGetCurrentPage,
  useKitsFiltersState,
  usePageSize,
  useSearch,
  useSetTotalPages,
  useSetTotalResults,
  useSortBy,
} from '../state'
import convertKit from './convertKit'

function useKitsQuery() {
  const authState = useCurrentUser()
  const { nameOrId, ownerId, serviceId, createdBetween, restrictEntityTypeTo } = useKitsFiltersState()
  const search = useSearch()
  const currentPage = useGetCurrentPage()
  const setTotalPages = useSetTotalPages()
  const setTotalResults = useSetTotalResults()
  const pageSize = usePageSize()
  const sortBy = useSortBy()
  const startDate = createdBetween && createdBetween[0]
  const endDate = createdBetween && createdBetween[1]

  return useQuery([
    'kits', 
    currentPage, 
    pageSize, 
    search, 
    sortBy, 
    authState,
    nameOrId,
    ownerId,
    serviceId,
    restrictEntityTypeTo,
    createdBetween,
  ], async () => {
    const response = await API.get('/kits', {
      params: {
        include: 'items,items.service,owner',
        'per-page': pageSize,
        name: search,
        sort: sortBy,
        page: currentPage,
        'filter[name_or_id]': nameOrId ?? search,
        'filter[owner_id]': ownerId,
        'filter[items.service_id]': serviceId,
        'filter[restrict_entity_type_to]': restrictEntityTypeTo,
        'filter[created_between]': createdBetween && `${formatDate(startDate)},${formatDate(endDate)}`
      }
    })

    setTotalPages(Number(response.headers['x-pagination-page-count']))
    setTotalResults(Number(response.headers['x-pagination-total-count']))

    return response.data?.map(convertKit) || []
  }, {
    keepPreviousData: true,
  })
}

export default useKitsQuery
