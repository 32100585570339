import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoCapaIPTUFields = Record<
  FieldName.URL_UF | FieldName.URL_CIDADE | FieldName.CPF | FieldName.CNPJ | FieldName.INSCRICAO_IMOVEL,
  FieldConfig
>

class CertidaoCapaIPTUConfig extends Certificate {
  formFields: CertidaoCapaIPTUFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    cpf: Fields.cpf,
    cnpj: Fields.cnpj,
    inscricao_imovel: Fields.inscricao_imovel,
  }

  constructor() {
    super({
      id: 105,
      orderNameField: FieldName.INSCRICAO,
      shortName: 'Capa de IPTU - Prefeitura',
      steps: ['dados', 'entrega'],
      priceByFields: [FieldName.URL_UF, FieldName.URL_CIDADE],
      availableFormatsFields: [FieldName.URL_UF, FieldName.URL_CIDADE],
    })
  }
}

const service = new CertidaoCapaIPTUConfig().initialize()

export default service
