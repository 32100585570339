import React, { useReducer, useState } from 'react'
import { Form, Modal as AntdModal, Upload } from 'antd'

import { Button, Modal, SubmitButton, TextAreaInput } from 'ui'
import { useViewOrderItem } from '../../state/viewOrderItem'
import useProvideAdditionalInformation from '../../services/useProvideAdditionalInformation'

export default function ProvideAdditionalInformation() {
  const [fileList, setFileList] = useState([] as any[])
  const [showModal, toggleModal] = useReducer(prev => !prev, false)
  const [form] = Form.useForm()
  const [informationSent, setInformationSent] = useState(false)
  const [{ id }, refreshOrderItem] = useViewOrderItem()

  const { provideInformation, loading } = useProvideAdditionalInformation(id, {
    onSuccess: () => {
      AntdModal.success({
        title: 'Informações adicionais enviadas com sucesso',
        content: <p className="p-7">Em breve, atualizaremos o status do seu pedido.</p>,
      })

      setFileList([])
      refreshOrderItem()
      setInformationSent(true)
    },
    onError: () => setFileList([]),
  })

  const handleSubmit = async (values: any) => {
    if (informationSent) {
      toggleModal()
    } else {
      provideInformation(values.information, fileList)
    }
  }

  return (
    <>
      <Button className="rounded-md" type="primary" onClick={toggleModal}>
        Fornecer informações adicionais
      </Button>

      <Modal open={showModal} title="Informações adicionais" onCancel={toggleModal} destroyOnClose>
        <p className="pb-6 mb-6 border-b border-gray-300">
          Para ter sucesso em seu pedido, inclua todas as informações disponíveis não informadas anteriormente
          e que podem contribuir para a localização da documentação. Exemplos de dados úteis: cidade de
          residência, hospital de nascimento, bairro de moradia etc
        </p>

        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark={false}
          scrollToFirstError
          className="max-w-lg"
        >
          <TextAreaInput
            name="information"
            placeholder="Digite aqui mais detalhes sobre a pessoa da certidão..."
            className="h-40"
            disabled={loading}
            required
          />

          <Form.Item>
            <h2 className="text-base uppercase mt-2 mb-5">Envie fotos de documentos (original)</h2>

            <p className="mb-5">
              O envio da foto aumenta as chances de encontrar o documento. Tipos de documentos:{' '}
            </p>

            <div className="flex mb-5">
              <div>
                <div>certidão de casamento</div>
                <div>certidão de óbito </div>
              </div>
              <div className="ml-5">
                <div>verso do RG </div>
                <div>carteira de trabalho</div>
              </div>
            </div>

            <Upload
              beforeUpload={file => {
                setFileList([...fileList, file])
                return false
              }}
              fileList={fileList}
              accept=".png, .jpg, .jpeg, .pdf"
            >
              <Button type="primary" icon="upload" block ghost>
                Selecionar arquivo
              </Button>
            </Upload>
            <div className="text-center text-gray-600 text-xs mt-2">Envie o arquivo em formato PNG, JPG ou PDF com tamanho máximo de 10MB.</div>
          </Form.Item>

          <SubmitButton label={loading ? 'Enviando...' : 'Enviar'} loading={loading} block />
        </Form>
      </Modal>
    </>
  )
}
