import { getFieldName } from 'domain/createOrder/shared'
import { NumberInput, NumberInputProps } from 'ui'
import { FieldConfig } from '../types'

interface Props extends Omit<NumberInputProps, 'maxLength' | 'name' | 'placeholder'> {
  field: FieldConfig
  itemId?: number
}

export default function NumberField({ field, itemId, required = !field.optional, ...props }: Props) {
  const name = getFieldName(field.name, itemId)

  return (
    <NumberInput
      label={field.label}
      placeholder={field.placeholder ?? field.label}
      name={name}
      required={required}
      maxLength={field.maxLength}
      {...props}
    />
  )
}
