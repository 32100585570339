import { validateFullName } from 'utils/validators'
import TextInput, { TextInputProps } from './TextInput'

interface Props extends Omit<TextInputProps, 'id' | 'label' | 'minLength' | 'rules'> {
  label?: string
}

export default function FullNameInput({
  label = 'Nome da pessoa',
  name = 'namePF',
  placeholder = 'Nome completo',
  required = true,
  maxLength = 150,
  ...props
}: Props) {
  const rules = [
    { required, message: 'Campo obrigatório.' },
    {
      validator: (rule: object, value: string) => {
        if (!required && !value) return Promise.resolve()

        return validateFullName(value) ? Promise.resolve() : Promise.reject('Informe o nome completo')
      },
    },
  ]

  return (
    <TextInput
      name={name}
      id={name as string}
      label={label}
      required={required}
      rules={rules}
      minLength={4}
      maxLength={maxLength}
      placeholder={placeholder}
      autoComplete="off"
      {...props}
    />
  )
}
