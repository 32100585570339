import { useMemo } from 'react'
import { Spin } from 'antd'

import { LegalEntity } from 'domain/app/types'
import { AllExtraInfos } from 'domain/orderItem/types'
import { getServiceConfig } from 'domain/servicesCBRdoc/products'
import { ServiceCode } from 'domain/servicesCBRdoc/types'

import DynamicField from './DynamicField'
import { FieldConfig, FieldName } from '../types'

interface Props {
  serviceCode: ServiceCode
  loading: boolean
  data: AllExtraInfos | undefined | void
  itemId?: number
  legalEntity?: LegalEntity
  exceptions?: FieldName[]
}

export default function ExtraInfoFields({
  serviceCode,
  data,
  loading,
  itemId,
  legalEntity,
  exceptions,
}: Props) {
  const fieldConfigs = useMemo(() => {
    if (!data) return []

    const service = getServiceConfig(serviceCode)
    const fields: Partial<Record<FieldName, FieldConfig>> = {}
    const allFields = Object.values({
      ...service.formFields,
      ...service.dynamicFields,
    })
    const entries = Object.entries(data)

    entries.forEach(([key, value]) => {
      if (key !== 'campos-necessarios' && value) {
        const config = allFields.find(o => o.apiExtraInfo === key)

        if (config) {
          fields[config.name] = { ...config, options: value }
        }
      }
    })

    if (data['campos-necessarios']) {
      data['campos-necessarios'].forEach(fieldName => {
        if (fieldName === 'nome' && service.legalEntity === 'indefinido') {
          const config = allFields.find(
            o => o.name === (legalEntity === 'juridica' ? FieldName.NOME_PJ : FieldName.NOME_PF)
          )

          if (config) {
            fields[config.name] = {
              ...config,
              optional: false,
            }
          }
        } else {
          const config = service.dynamicFields[fieldName]

          if (config && !fields[config.name]) {
            fields[config.name] = {
              ...config,
              optional: false,
            }
          }
        }
      })
    }

    const validFields = Object.values(fields).filter(config => {
      if (legalEntity && config.legalEntity && config.legalEntity !== legalEntity) {
        return false
      }

      return !exceptions || !exceptions.includes(config.name as FieldName)
    })

    return validFields
  }, [data, legalEntity])

  return (
    <Spin spinning={loading}>
      {fieldConfigs.map(field => (
        <DynamicField key={field.apiName} field={field} itemId={itemId} labelInValue={false} />
      ))}
    </Spin>
  )
}
