import { useReducer } from 'react'
import { Button, Checkbox, Form, FormInstance } from 'antd'

import { GroupsSelect } from 'domain/groups'
import { initialFilters, useClearFilters, useFilters } from 'domain/orders/state/filters'
import { OrdersFilters } from 'domain/orders/types'
import { DocumentServicesSelect, OrdersOwnerFilter, OrdersStatusFilter } from 'domain/servicesCBRdoc/fields'
import { ClearFiltersButton, DateRangePicker, Modal } from 'ui'

import OrdersFilterButton from './OrdersFilterButton'
import OrdersRecurrenceFilter from './OrdersRecurrenceFilter'
import OrdersResultsFilter from './OrdersResultsFilter'
import OrdersSearchTermFilter from './OrdersSearchTermFilter'

export default function OrdersMoreFilters() {
  const [form] = Form.useForm()
  const [open, toggleVisible] = useReducer(prev => !prev, false)
  const clearFilters = useClearFilters()

  return (
    <>
      <OrdersFilterButton onClick={toggleVisible} />

      <Modal
        open={open}
        title="Filtros"
        extra={
          <ClearFiltersButton
            onClick={() => {
              form.setFieldsValue(initialFilters)
              clearFilters()
              toggleVisible()
            }}
          />
        }
        onCancel={toggleVisible}
      >
        <FiltersForm form={form} onClose={toggleVisible} />
      </Modal>
    </>
  )
}

function FiltersForm({ form, onClose }: { form: FormInstance; onClose: () => void }) {
  const [filters, setFilters] = useFilters()

  const handleSubmit = (formData: OrdersFilters) => {
    setFilters(formData)
    form.resetFields()
    onClose()
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={filters}
      onFinish={handleSubmit}
      requiredMark={false}
      scrollToFirstError
      className="max-w-lg"
    >
      <OrdersSearchTermFilter />
      <DocumentServicesSelect />
      <OrdersStatusFilter />
      <OrdersResultsFilter />
      <OrdersOwnerFilter />

      <Form.Item name="dateRange" label="Data de criação">
        <DateRangePicker allowClear />
      </Form.Item>

      <GroupsSelect />

      <Form.Item name="ai" valuePropName="checked" noStyle>
        <Checkbox>Dados extraídos</Checkbox>
      </Form.Item>

      <Form.Item name="automaticGenerated" valuePropName="checked" className="mb-0">
        <Checkbox>Solicitado automaticamente</Checkbox>
      </Form.Item>
      
      <OrdersRecurrenceFilter />

      <Form.Item>
        <Button htmlType="submit" type="primary" block>
          Aplicar filtros
        </Button>
      </Form.Item>
    </Form>
  )
}
