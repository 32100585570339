import React, { useReducer } from 'react'
import { Rule } from 'antd/lib/form'
import { Checkbox, Form, Divider } from 'antd'

import { useCreateAccount } from 'domain/customers'
import { UserEmail } from 'domain/users'
import { CompanyNameInput, FullNameInput, PasswordInput, PhoneInput, SubmitButton } from 'ui'

import DocumentsQuantityRadio from './DocumentsQuantityRadio'
import { CreateAccountForm } from '../types'

interface Props {
  cnpj: string
}

export default function RegisterForm({ cnpj }: Props) {
  const [form] = Form.useForm()
  const [termsAccepted, toggleTermsAccepted] = useReducer(prev => !prev, false)
  const { createAccount, loading } = useCreateAccount()

  const onSubmit = async (formData: CreateAccountForm) => {
    await createAccount(cnpj, formData)
  }

  return (
    <Form form={form} onFinish={onSubmit} layout="vertical" requiredMark={false}>
      <FullNameInput name="name" maxLength={60} autoFocus />
      <CompanyNameInput name="corporate_name" />
      <UserEmail name="email" />
      <PhoneInput name="phone" required />
      <PasswordInput />
      <PasswordInput label="Confirme sua senha" name="password_repeat" rules={passwordValidationRules} />
      <DocumentsQuantityRadio />

      <Divider />

      <Form.Item>
        <Checkbox className="checkbox-large" checked={termsAccepted} onChange={toggleTermsAccepted}>
          <span>
            Ao clicar em “confirmar” você está ciente e aceita os{' '}
            <a
              href="https://cbrdoc.com.br/termo-de-uso/"
              target="_blank"
              rel="noreferrer"
              className="text-primary underline hover:text-primary/70 whitespace-nowrap"
            >
              Termos de Uso
            </a>{' '}
            e a{' '}
            <a
              href="https://cbrdoc.com.br/politica-de-privacidade/"
              target="_blank"
              rel="noreferrer"
              className="text-primary underline hover:text-primary/70 whitespace-nowrap"
            >
              Política de Privacidade.
            </a>
          </span>
        </Checkbox>
      </Form.Item>

      <SubmitButton disabled={!termsAccepted} loading={loading} />
    </Form>
  )
}

const passwordValidationRules: Rule[] = [
  {
    required: true,
    message: 'Por favor, confirme sua senha!',
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('As senhas precisam ser iguais!'))
    },
  }),
]
