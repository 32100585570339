import { KitItem } from 'domain/kits/types'
import { useUpdateServiceForm } from 'domain/createOrder/kit'
import CertidaoRAISType from './CertidaoRAISType'

interface Props {
  kitItem: KitItem
}

export default function KitOrderCertidaoRAISType({ kitItem }: Props) {
  const updateFormData = useUpdateServiceForm(kitItem.id)

  return (
    <CertidaoRAISType
      itemId={kitItem.id}
      onChangeType={type => {
        updateFormData({ tipo: type })
      }}
    />
  )
}
