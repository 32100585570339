import { GenericError, MissingDataFromBackendError, ServiceNotAvailableError } from '.'
import { ApiError, ErrorExtrasParams } from '../types'

export const FORBIDDEN = 403
export const NOT_FOUND = 404

export const ERRORS: Record<string, ApiError> = {
  dataValidation: {
    message: 'Informações inválidas',
    description: 'Por favor, verifique as informações enviadas',
  },
  fetchFailed: {
    message: 'Não foi possível carregar essa informação',
    description: 'Tente recarregar a página',
  },
  forbiddenAccess: {
    message: 'Sem permissão de acesso',
    description: 'Fale com o administrador',
  },
  internalServer: {
    message: 'Falha de comunicação com o servidor',
    description: 'Tente novamente mais tarde',
  },
  instability: {
    message: 'Opa... O sistema está passando por uma instabilidade temporária. ',
    description: 'Estamos trabalhando para que você possa voltar a utilizar a CBRdoc o mais rápido possível.',
    status: 'instability',
  },
  invalidCredentials: {
    message: 'E-mail ou senha inválidos',
    description: 'Por favor, verifique as informações enviadas',
  },
  notEnoughCredits: {
    message: 'Créditos insuficientes',
    description: 'Você não possui créditos suficientes para realizar o pedido',
  },
  unexpected: {
    message: 'Algo inesperado aconteceu',
    description: 'Tente novamente mais tarde',
  },
  fileWithVirus: {
    message: 'O arquivo enviado contém vírus',
    description: 'Por favor, verifique os arquivos enviados.'
  }
}

export const getErrorMessage = (error: any, extras: ErrorExtrasParams = {}) => {
  if (extras.message && extras.description) {
    return {
      message: extras.message,
      description: extras.description,
    }
  }

  if (error instanceof MissingDataFromBackendError) {
    return {
      message: 'Falha no processamento',
      description: error.message,
    }
  }

  if (error instanceof ServiceNotAvailableError) {
    return {
      message: 'Serviço indisponível',
      description: error.message,
    }
  }

  if (error instanceof GenericError) {
    return {
      message: error.message,
      description: error.errors,
    }
  }

  if (error.response?.data) {
    const { message } = error.response.data

    if (message === 'Not enough credits') {
      return ERRORS.notEnoughCredits
    }
    
    if (message && message.includes('contém vírus')) {
      return ERRORS.fileWithVirus
    }
  }

  return getApiError(error, extras)
}

const getApiError = (error: any, extras: ErrorExtrasParams) => {
  // eslint-disable-next-line prefer-const
  let { message, description } = getDefaultErrorData(error)

  if (error.response && extras.showBackError) {
    const { data, message: errorMessage } = error.response

    if (errorMessage) {
      message = errorMessage
    } else if (data) {
      if (Array.isArray(data)) {
        message = error.response.data.map((msg: any) => msg.message)
      } else if (typeof data === 'string') {
        message = data
      }
    }
  }

  return {
    message,
    description,
  }
}

export const getDefaultErrorData = (error: any): ApiError => {
  switch (error.response?.status) {
    case 400:
      return ERRORS.unexpected
    case 403:
      return ERRORS.forbiddenAccess
    case 422:
      return ERRORS.dataValidation
    case 500:
      return ERRORS.internalServer
    case 504:
      return ERRORS.instability
    default:
      return ERRORS.unexpected
  }
}
