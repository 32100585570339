import { PostData } from 'domain/createOrder/types'
import { ShoppingCartOrder } from 'domain/shoppingCart/types'
import { getLabel } from 'utils/transformData'

export const getCityNameFederativeUnitText = (order: ShoppingCartOrder) => {
  const { url_cidade, url_uf } = order.selectedLocation
  const federativeUnit = getLabel(url_uf) as string
  const city = getLabel(url_cidade) as string

  if (city && federativeUnit) {
    return `${city} - ${federativeUnit}`
  }

  return city || federativeUnit || ''
}

export const getDeliveryInformation = (order: ShoppingCartOrder) => {
  const estimatedDeliveryTime = order.orderDetails?.estimatedDeliveryTime

  if (!estimatedDeliveryTime) {
    return ''
  }

  const isArray = Array.isArray(estimatedDeliveryTime)
  const time1 = isArray ? estimatedDeliveryTime[0] : estimatedDeliveryTime
  const time2 = isArray ? estimatedDeliveryTime[1] : null

  if (!time2 && time1 === 0) {
    return 'Imediato'
  }

  if (time2 && time1 !== time2) {
    return `De ${time1} até ${time2} dias úteis`
  }

  return `${time1} dias úteis`
}

export const showBookPage = ({ livro, pagina }: PostData) => {
  return livro && pagina ? `${livro}/${pagina}` : livro || pagina || ''
}
