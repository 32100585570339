import React from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { SelectedService } from 'domain/orderItem/types'

interface Props {
  service: SelectedService
  submitData: any
}

function RegisterInfo({ service, submitData }: Props) {
  const { data, isFetching } = useQuery(
    ['order-item-register-field', service, submitData],
    async () => {
      try {
        return await getRegister(service, submitData)
      } catch {
        return '-'
      }
    },
    {
      enabled: Boolean(service.id && service.categoryId),
    }
  )

  if (!service.id || !service.categoryId || !data) {
    return null
  }

  return (
    <td className="flex gap-1">
      <strong>Identificação:</strong>
      <span title={data} className="truncate w-[280px]">
        {isFetching ? 'Carregando...' : data}
      </span>
    </td>
  )
}

async function getRegister(service: SelectedService, submitData: any) {
  const response: AxiosResponse<{ register: string }> = await AuthorizedAPI.post(
    `/services/${service.id}/categories/${service.categoryId}/register`,
    {
      detailed_service_data: submitData,
    }
  )
  return response.data?.register || ''
}

export default RegisterInfo
