import { message } from 'antd'

import { errorHandler } from 'api/errors'
import queryClient from 'app/config/queryClient'
import { useUpdateLatestStorageOperation } from 'domain/myFiles/state'
import { ExplorerItem } from '../types'
import { deleteExplorerItem } from './useDeleteExplorerItem'
import MyFilesQueryKeys from './MyFilesQueryKeys'

function useDeleteExplorerItems({ onSuccess }: { onSuccess: () => void }) {
  const updateLatestStorageOperation = useUpdateLatestStorageOperation()

  return function deleteItems(selectedItems: ExplorerItem[]) {
    message.loading({ content: 'Excluindo items selecionados...', key })

    Promise.allSettled(selectedItems.map(async item => await deleteExplorerItem(item.id)))
      .then(data => {
        const error = (data.find(res => res.status === 'rejected') as PromiseRejectedResult | undefined)
          ?.reason

        if (error) {
          throw new Error(error)
        }

        message.success({ content: 'Todos os items foram excluídos com sucesso!', key })
        queryClient.invalidateQueries(MyFilesQueryKeys.MyFilesList)
        updateLatestStorageOperation()

        onSuccess()
      })
      .catch(errorHandler)
  }
}

const key = 'updatable'

export default useDeleteExplorerItems
