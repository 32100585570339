import React from 'react'
import { Table } from 'antd'

import {
  useCurrentPage,
  useGetOperation,
  useGetTotalResults,
  usePageSize,
  useSetSortDirection,
  useTableSelection,
} from 'domain/invoices/state'
import { PrepaidInvoice } from 'domain/invoices/types'
import { Icon, Pagination } from 'ui'
import PrepaidInvoicesMassActions from './PrepaidInvoicesMassActions'

export default function PrepaidInvoicesTable({ invoices }: { invoices: PrepaidInvoice[] }) {
  const [selected, setSelected] = useTableSelection()
  const operation = useGetOperation()
  const setSortDirection = useSetSortDirection()

  const handleTableChange = (sorter: any) => {
    setSortDirection(sorter.order)
  }

  const rowSelection = {
    selectedRowKeys: selected.keys,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelected({
        rows: selectedRows ?? [],
        keys: selectedRowKeys,
      })
    },
    getCheckboxProps: (record: any) => {
      return {
        disabled: record.operation === 'C',
      }
    },
  }

  return (
    <>
      <Table
        rowKey="id"
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={operation === 'C' ? columnsOnlyC : columns}
        dataSource={invoices}
        onChange={handleTableChange}
        onRow={({ operation }) => ({
          className: operation === 'C' ? 'row-operation-c' : 'row-operation-d',
        })}
        pagination={false}
        className="mask-text"
      />

      <TablePagination />
      <PrepaidInvoicesMassActions />
    </>
  )
}

function TablePagination() {
  const totalResults = useGetTotalResults()
  const [pageSize, setPageSize] = usePageSize()
  const [currentPage, setCurrentPage] = useCurrentPage()

  return (
    <div className="pl-3">
      <Pagination
        totalCount={totalResults}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        currentPage={currentPage}
        onChangePage={setCurrentPage}
      />
    </div>
  )
}

const columns = [
  {
    title: 'Nome do pedido',
    dataIndex: ['order', 'name'],
    key: 'purchase.name',
    sorter: true,
    render: (orderName: string, record: any) =>
      record.operation === 'C' ? (
        <b className="text-green-500 whitespace-no-wrap">
          <Icon name="money" className="text-4xl pr-3 " /> {orderName}
        </b>
      ) : (
        <b>{orderName}</b>
      ),
  },
  {
    title: 'Nº do pedido',
    dataIndex: ['order', 'orderNumber'],
    key: 'purchase.backoffice_id',
    sorter: true,
  },
  {
    title: 'Data do pedido',
    dataIndex: 'createdAtDisplay',
  },
  {
    title: 'Créditos utilizados',
    dataIndex: 'amountDisplay',
    align: 'right' as 'right',
    key: 'amout',
    sorter: true,
    render: (priceDisplay: string, record: PrepaidInvoice) => (
      <span className={`${record.operation === 'C' ? 'text-green-500' : 'text-error'} whitespace-no-wrap`}>
        {priceDisplay}
      </span>
    ),
  },
  {
    title: 'Data de envio',
    dataIndex: 'deliveredAtDisplay',
  },
  {
    title: 'Usuário',
    dataIndex: ['user', 'name'],
    className: 'text-2xs',
    key: 'user.name',
    sorter: true,
  },
]

const columnsOnlyC = [
  {
    title: 'Nome',
    dataIndex: ['order', 'orderName'],
    sorter: true,
    render: (text: string) => (
      <b className="text-green-500 whitespace-no-wrap">
        <Icon name="money" className="text-4xl pr-3 " /> {text}
      </b>
    ),
  },
  {
    title: 'Data',
    dataIndex: 'createdAtDisplay',
  },
  {
    title: 'Créditos adicionados',
    dataIndex: 'amountDisplay',
    align: 'right' as 'right',
    className: 'text-green-500 whitespace-no-wrap',
  },
  {
    title: 'Bônus',
    dataIndex: 'bonusAmountDisplay',
  },
  {
    title: 'Usuário',
    dataIndex: ['user', 'name'],
    className: 'text-2xs',
  },
]
