import { OrderList } from 'domain/orders/types'
import OrderDetailsInfoTag from '../ordersList/OrderInfoTag'

interface OrderInfoProps {
  order: OrderList
}

const OrderDetailsInfo = ({ order }: OrderInfoProps) => {
  return (
    <div className="flex flex-row flex-wrap gap-1">
      {order.recurrence && <OrderDetailsInfoTag>Recorrente</OrderDetailsInfoTag>}
      {order.hasAiExtractData && <OrderDetailsInfoTag>Dados extraídos</OrderDetailsInfoTag>}
      {order.hasAiAnalysisPending && (
        <OrderDetailsInfoTag>Dados sendo analisados pela IA</OrderDetailsInfoTag>
      )}
    </div>
  )
}

export default OrderDetailsInfo
