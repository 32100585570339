import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { FORMATO, NIRF } = FieldName

type CertidaoCafirFields = Record<FieldName.NIRF, FieldConfig>

class CertidaoCafir extends Certificate {
  formFields: CertidaoCafirFields = {
    nirf: Fields.nirf,
  }

  constructor() {
    super({
      id: 82,
      orderNameField: NIRF,
      priceByFields: [FORMATO],
      shortName: 'Certidão Cafir',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoCafir().initialize()

export default service
