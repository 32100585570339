import React from 'react'
import { Modal } from 'antd'
// @ts-ignore
import IntlCurrencyInput from 'react-intl-currency-input'

import { PaymentModal } from 'domain/checkout'
import CreditsOptionsHeader from './CreditsOptionsHeader'

import BonusCard from './BonusCard'

interface Props {
  creditAmountSelected: number
  setCreditAmountSelected: React.Dispatch<React.SetStateAction<number>>
  paymentModalVisible: boolean
  setPaymentModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export default function CreditsOptions({
  creditAmountSelected,
  setCreditAmountSelected,
  paymentModalVisible,
  setPaymentModalVisible,
}: Props) {
  return (
    <div className="bg-gray-100 pb-5 px-0 lg:px-7">
      <CreditsOptionsHeader />

      <div className="flex flex-col lg:flex-row">
        <BonusCard
          value={creditAmountSelected}
          input={
            <IntlCurrencyInput
              currency="BRL"
              config={currencyConfig}
              autoSelect
              onChange={(e: any, value: any) => {
                const credit = parseFloat(String(value))
                setCreditAmountSelected(credit)
              }}
              max={Number.MAX_SAFE_INTEGER - 1}
              className="ant-input w-full text-2xl text-gray-600 leading-tight py-3 px-2"
            />
          }
          onButtonClick={() => {
            if (creditAmountSelected < 1) {
              Modal.error({ title: 'Erro de validação', content: 'O valor deve ser no mínimo de R$1' })
            } else {
              setPaymentModalVisible(true)
            }
          }}
          className="mb-6 lg:mb-0 lg:mr-3"
        />
        <BonusCard
          value={500}
          onButtonClick={() => {
            setCreditAmountSelected(500)
            setPaymentModalVisible(true)
          }}
          className="mb-6 lg:mb-0 lg:mr-3"
        />
        <BonusCard
          value={1000}
          onButtonClick={() => {
            setCreditAmountSelected(1000)
            setPaymentModalVisible(true)
          }}
          className="mb-6 lg:mb-0 lg:mr-3"
        />
        <BonusCard
          value={2000}
          onButtonClick={() => {
            setCreditAmountSelected(2000)
            setPaymentModalVisible(true)
          }}
        />
      </div>

      <PaymentModal
        open={paymentModalVisible}
        paymentValue={creditAmountSelected}
        onRequestClose={() => setPaymentModalVisible(false)}
        isAddingCredits
      />
    </div>
  )
}

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}
