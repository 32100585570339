import { SelectableButton } from 'ui'

import { StatusCode } from 'domain/orders/types'
import { useStatusFilter } from '../../state/filters'

type Props = { label: string; status: StatusCode }

export default function FilterByStatusBtn({ label, status }: Props) {
  const [statusFilter, setStatusFilter] = useStatusFilter()
  const isSelected = statusFilter.includes(status)

  return (
    <SelectableButton
      selected={isSelected}
      onClick={() => {
        if (isSelected) {
          setStatusFilter(statusFilter.filter(s => s !== status))
        } else {
          setStatusFilter([...statusFilter, status])
        }
      }}
    >
      {label}
    </SelectableButton>
  )
}
