import React from 'react'
import { useDispatch } from 'react-redux'

import { createOrderActions } from 'domain/createOrder/document'
import { Icon, TextInput } from 'ui'
import { Tooltip } from 'antd'

interface LocationInputProps {
  name?: string | (string | number)[]
  required?: boolean
}

function LocationInput({ name = 'local_servico', required = true }: LocationInputProps) {
  const dispatch = useDispatch()

  const saveOnLocalStorage = (e: any) => {
    dispatch(createOrderActions.updateFormData({ [name as any]: e.target.value }))
  }

  return (
    <TextInput
      label={
        <div className="flex items-center gap-2">
          Localização
          <Tooltip
            title="Informe o local/endereço na qual deseja que seja feita a diligência."
            trigger="hover"
          >
            <Icon name="help-circle" color="#AEB6C8" />
          </Tooltip>
        </div>
      }
      name={name}
      placeholder="Informe onde a diligência será feita"
      maxLength={200}
      onChange={saveOnLocalStorage}
      required={required}
    />
  )
}

export default LocationInput
