import { Form, FormInstance, message } from 'antd'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { errorHandler } from 'api/errors'
import {
  DynamicTypeSelect,
  createOrderActions,
  useAddCreateOrderStateToCart,
  useCreateOrderNextStep,
  useCreateOrderState,
} from 'domain/createOrder/document'
import { DocRegistrationFlow } from 'domain/createOrder/documentRegistration'
import { DatePickerField, TextField } from 'domain/servicesCBRdoc/fields'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { Heading, SubmitButton } from 'ui'
import { DISPLAY_DATE_FORMAT } from 'utils/dateTime'

import config from '../rgiConfig'
import PartsHelper from './PartsHelper'
import RGICollapse from './RGICollapse'
import CreateOrderRGIOutorganteForm from './CreateOrderRGIPartForm'
import CreateOrderRGIOutorgados from './CreateOrderRGIOutorgados'

export default function CreateOrderRGI() {
  return <DocRegistrationFlow dataStep={<DocumentRegistrationForm />} />
}

function DocumentRegistrationForm({ formInstance }: { formInstance?: FormInstance }) {
  const form = formInstance ?? Form.useForm()[0]
  const dispatch = useDispatch()
  const [status, setStatus] = useState<'idle' | 'loading'>('idle')
  const addToCart = useAddCreateOrderStateToCart()
  const [goToNextStep, nextStep] = useCreateOrderNextStep('dados')
  const { files, formData, multipleItemsLoadingPrice, selectedType } = useCreateOrderState()
  const isUploading = files?.some(f => f.status === 'uploading')
  const hasUploadError = files?.some(f => f.status === 'error')
  const isLoading = multipleItemsLoadingPrice === true || status === 'loading' || isUploading
  const [activeItem, setActiveItem] = useState<number | undefined>(0)

  const handleSubmit = async ({ data_titulo, ...formData }: any) => {
    const filledForm = {
      ...formData,
      [FieldName.DATA_TITULO]: data_titulo.format(DISPLAY_DATE_FORMAT),
    }

    dispatch(createOrderActions.updateFormData(filledForm))

    try {
      await form.validateFields()
      setStatus('loading')

      try {
        if (nextStep === 'cart') {
          addToCart(filledForm)
        }

        goToNextStep()
      } catch (error) {
        setStatus('idle')
        errorHandler(error)
      }
    } catch {
      message.error('Verifique o preenchimento do formulário.')
    }
  }

  const initialValues = { ...formData }

  if (typeof formData?.data_titulo === 'object') {
    initialValues.data_titulo = undefined
  }

  if (!initialValues.outorgantes || !Array.isArray(initialValues.outorgantes)) {
    initialValues.outorgantes = [{}]
  }

  if (!initialValues.outorgados || !Array.isArray(initialValues.outorgados)) {
    initialValues.outorgados = [{}]
  }

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      scrollToFirstError
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Heading size="3" text="Dados do Registro" />

      <DynamicTypeSelect field={tipo} autoFocus />
      <DatePickerField field={data_titulo} />
      <TextField field={livro} />
      <TextField field={pagina} />

      {selectedType === 'escritura-publica' && (
        <>
          <Heading size="3" text="Partes" />

          <p className="text-gray-500 mb-5">
            Forneça as informações das partes envolvidas no contrato de registro de imóveis. <PartsHelper />
          </p>

          <Form.List name="outorgantes">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((item, index) => (
                    <RGICollapse
                      key={item.key}
                      activeKey={activeItem}
                      item={item}
                      onChange={setActiveItem}
                      description="Outorgante"
                      index={index}
                      totalItems={fields.length}
                      isSubmitting={isLoading}
                      form={<CreateOrderRGIOutorganteForm itemId={index} />}
                      add={add}
                      remove={remove}
                    />
                  ))}
                </>
              )
            }}
          </Form.List>

          <CreateOrderRGIOutorgados
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            isSubmitting={isLoading}
          />
        </>
      )}

      <SubmitButton marginTop="mt-3" loading={isLoading} disabled={hasUploadError || isUploading} />

      <p className={`text-sm text-center ${hasUploadError ? 'text-error' : 'text-gray-400'}`}>
        {hasUploadError ? 'Houve um erro ao fazer o upload' : isUploading ? 'Upload em andamento...' : ''}
      </p>
    </Form>
  )
}

const { data_titulo, livro, pagina, tipo } = config.formFields
