import { useEffect } from 'react'

export default function useAutoFocusMultipleItems(activeItem: number | undefined) {
  useEffect(() => {
    if (activeItem) {
      let el: HTMLElement | null = document.querySelector('div.ant-collapse-content-active input')

      if (!el) {
        el = document.querySelector('div.ant-collapse-content-active select')
      }

      if (el) {
        el.focus()
      }
    }
  }, [activeItem])
}
