import { useSetRecurrenceId } from 'domain/orders/state/filters'
import { useRecurrenceQuery } from 'domain/recurrences'
import { FilterTag, LoadingIcon } from 'ui'

export default function FilterByRecurrenceTag({ value }: { value: number }) {
  const setRecurrenceId = useSetRecurrenceId()
  const { data: recurrence, isLoading } = useRecurrenceQuery(value, {
    include: 'items,items.order,owner,groups'
  })

  if (isLoading) return <LoadingIcon />
  if (!recurrence) return null

  return (
    <FilterTag onClick={() => setRecurrenceId(undefined)}>
      {recurrence.name}
    </FilterTag>
  )
}
