import { Switch, Route, useLocation, useRouteMatch } from 'react-router-dom'

import { ActionsBar } from 'ui'
import { RoutePaths } from 'app/routes'
import { ServiceTabs } from 'domain/createOrder'
import { ServiceType } from 'domain/servicesCBRdoc/types'
import { GoBackButton, PageLayout } from 'layouts'

import CreateOrderServicePage from './CreateOrderServicePage'
import SelectServiceTypePage from './SelectServiceTypePage'
import CreateOrderRegistration from './CreateOrderRegistration'

export default function CreateOrderPage() {
  const { path } = useRouteMatch()
  const location = useLocation()
  const splitPath = location.pathname.split('/')
  const pageTitle = PagesTitle[splitPath[splitPath.length - 1]]

  return (
    <Switch>
      <Route
        path={[
          RoutePaths.CREATE_ORDER,
          `${path}/diligencias`,
          `${path}/documentos`,
          `${path}/pesquisas`,
          `${path}/signatures-certificates`,
        ]}
        exact
      >
        <PageLayout pageTitle={pageTitle ?? 'Fazer pedido'}>
          {!(location.pathname === RoutePaths.CREATE_ORDER) && (
            <ActionsBar>
              <GoBackButton />
            </ActionsBar>
          )}

          <Switch>
            <Route path={`${path}/diligencias`} exact>
              <ServiceTabs type={ServiceType.DILIGENCE} />
            </Route>
            <Route path={`${path}/documentos`} exact>
              <ServiceTabs type={ServiceType.CERTIFICATE} />
            </Route>
            <Route path={`${path}/pesquisas`} exact>
              <ServiceTabs type={ServiceType.RESEARCH} />
            </Route>
            <Route path={`${path}/signatures-certificates`} exact>
              <ServiceTabs type={ServiceType.SIGNATURES_CERTIFICATES} />
            </Route>
            <Route path={RoutePaths.CREATE_ORDER} exact>
              <SelectServiceTypePage />
            </Route>
          </Switch>
        </PageLayout>
      </Route>
      <Route path={RoutePaths.CREATE_ORDER_DOCUMENT_REGISTRATION} exact>
        <CreateOrderRegistration />
      </Route>
      <Route path={`${path}/:serviceCode`}>
        <CreateOrderServicePage />
      </Route>
    </Switch>
  )
}

const PagesTitle: { [key in string]: string } = {
  diligencias: 'Solicitar diligência',
}
