import React from 'react'
import { Button } from 'antd'

import { Label } from 'ui'

interface Props {
  kitName: string
  totalItems: number
  onClickEditButton?: () => void
}

function KitSidebarName({ kitName, totalItems, onClickEditButton }: Props) {
  return (
    <div className="text-secondary-500 font-bold text-base my-5 flex flex-row items-center transition duration-300 ease-in-out">
      {kitName}{' '}
      {totalItems > 0 && (
        <Label color="red" className="mx-5">
          {totalItems}
        </Label>
      )}
      {!!onClickEditButton && (
        <Button shape="circle" size="small" onClick={onClickEditButton} className="ml-auto" type="link">
          Editar
        </Button>
      )}
    </div>
  )
}

export default KitSidebarName
