import { LabelInValue } from 'domain/app/types'
import { CreateOrderState, GetServiceInformationFn, ShoppingStep } from 'domain/createOrder/types'
import { KitLegalEntity } from 'domain/kits/types'
import { AIDocumentType } from 'domain/openAI/types'
import { OrderItem, ResearchResult } from 'domain/orderItem/types'
import { ResultCode } from 'domain/orders/types'
import { CertidaoJuntaTypes } from 'domain/servicesCBRdoc/products/certificates/certidaoJuntaComercial/types'
import { CertidaoRaisTypes } from 'domain/servicesCBRdoc/products/certificates/certidaoRAIS/types'
import { AvailableTypesPesquisaDividaPGEOptions } from 'domain/servicesCBRdoc/products/researches/pesquisaDividaAtivaPGE/types'
import { AnyDetailedService } from 'domain/shoppingCart/types'

import { FieldConfig, FieldConfigs, FieldName } from './fields/types'

export * from './typesAPI'

export type Location = {
  url: string
  name: string
  sigla?: string
  nome?: string
}

export interface BasicServiceConfig<FormFieldsType = FieldConfigs, DynamicFieldsType = FieldConfigs> {
  availableFormatsFields?: FieldName[]
  defaultType?: AllAvailableTypes
  description?: string
  doNotOrderResultsWithSameNotary?: boolean
  dynamicFields?: DynamicFieldsType
  extractResearchData?: (
    result: ResearchResult,
    resultCode: ResultCode,
    orderItem: OrderItem
  ) => Partial<CreateOrderState>
  extraInfoFields?: FieldName[]
  extraInfoRequiredFieldsOnly?: boolean
  formFields: FormFieldsType
  getAvailableFormatsParams?: GetServiceInformationFn
  getAllDynamicFields?: () => FieldConfig[]
  getExtraInfoParams?: GetServiceInformationFn
  getPriceParams?: GetServiceInformationFn
  shoudAutoLoadDynamicFields?: boolean
  hasResultsInPDF?: boolean
  allowMultipleItems?: boolean
  id: number
  isDueDiligence?: boolean
  isKitUnavailable?: boolean
  isResearch?: boolean
  priceByFields?: FieldName[]
  selectResearchTime?: boolean
  similarOrderFields: (keyof AnyDetailedService)[]
  splittableField?: FieldName
  splittableOrderName?: string
  steps?: ShoppingStep[]
}

export interface ServiceSettings<FormFieldsType = FieldConfigs, DynamicFieldsType = FieldConfigs>
  extends Omit<BasicServiceConfig<FormFieldsType, DynamicFieldsType>, 'steps'> {
  legalEntity: KitLegalEntity | null
  loadDynamicFieldsOnKit: boolean
  steps: ShoppingStep[]
}

export interface Service {
  canAutoPurchaseFromPositiveResearchResult: boolean
  canAutoPurchaseFromNegativeResearchResult: boolean
  canBeMonitored: boolean
  category1Id: number
  category2Id?: number | null
  code: ServiceCode
  hasAI: boolean
  hasSpreadsheet: boolean
  id: number
  name: string
  shortName: string
  type: ServiceType
}

export interface BaseServiceConfig {
  description?: string
  formFields: FieldConfigs
  steps: ShoppingStep[]
  type: ServiceType
  GetServiceInformationParams: FieldName[]
}

export interface DocumentConfig<FormFields> extends BaseServiceConfig {
  defaultType: string | undefined
  allowMultipleItems: boolean
  isKitAvailable: boolean
  similarOrderFields: (keyof FormFields)[]
}

export interface CertificateConfig<FormFields, DynamicFields = FieldConfigs>
  extends DocumentConfig<FormFields> {
  availableFormatsFields: FieldName[]
  dynamicFields?: DynamicFields
  splittableField?: FieldName
  splittableOrderName?: string
}

export interface ResearchConfig<FormFields> extends DocumentConfig<FormFields> {
  hasResultsInPDF?: boolean
  doNotOrderResultsWithSameNotary?: boolean
}

export interface BasicDueDiligenceConfig<Type>
  extends Omit<
    BasicServiceConfig<Type>,
    | 'availableFormatsParams'
    | 'extractOrderDataFromResult'
    | 'GetServiceInformationParams'
    | 'isKitAvailable'
    | 'allowMultipleItems'
    | 'similarOrderFields'
    | 'steps'
  > {}

export type BasicIAConfig<Type> = BasicServiceConfig<Type>

export interface BasicCerticateResearchableConfig<Type>
  extends Omit<BasicServiceConfig<Type>, 'getResearchResultData'> {
  extractResearchData: (
    result: ResearchResult,
    resultCode: ResultCode,
    orderItem: OrderItem
  ) => Partial<CreateOrderState>
}

export type BasicResearchConfig<Type> = BasicServiceConfig<Type>

export interface BasicResearchWithResultsConfig<Type> extends BasicResearchConfig<Type> {
  getIsResultPositive?: (result: ResearchResult) => boolean
  doNotOrderResultsWithSameNotary?: boolean
  checkIfHasMissingFields?: boolean
}

export enum ServiceListType {
  AI = 'AI',
  CERTIFICATES = 'certificates',
  DILIGENCES = 'diligences',
  RESEARCHES = 'researches',
  SIGNATURES_CERTIFICATES = 'signatureCertification',
}

export enum ServiceType {
  AI = 'AI',
  CERTIFICATE = 'Certificate',
  DOCUMENT_REGISTRATION = 'DocumentRegistration',
  DILIGENCE = 'Diligence',
  RESEARCH = 'Research',
  SIGNATURES_CERTIFICATES = 'SignatureCertification',
}

export enum AICode {
  AI_DOCUMENT_ANALYSIS = 'ia-extracao-dados',
}

export enum CertificateCode {
  CERTIDAO_AMBIENTAL = 'certidao-negativa-debitos-ambientais-estadual',
  CERTIDAO_AMBIENTAL_MUNICIPAL = 'certidao-ambiental-municipal',
  CERTIDAO_ANTECEDENTES_CRIMINAIS = 'certidao-antec-criminais',
  CERTIDAO_ATAS_NOTARIAIS = 'certidao-atas-notariais',
  CERTIDAO_BAIXA_PROTESTO = 'certidao-baixa-protesto',
  CERTIDAO_CAFIR = 'certidao-cafir',
  CERTIDAO_CASAMENTO = 'certidao-casamento',
  CERTIDAO_CAPA_IPTU = 'certidao-capa-iptu',
  CERTIDAO_CCIR = 'certidao-cadastro-imovel-rural',
  CERTIDAO_DADOS_CADASTRAIS_IMOVEL = 'certidao-dados-cadastrais-imovel',
  CERTIDAO_CEAT = 'certidao-ceat',
  CERTIDAO_CNDT = 'certidao-cndt',
  CERTIDAO_CNDTNIDA = 'certidao-cndtnida',
  CERTIDAO_CUMPRIMENTO_PCDS = 'certidao-cota-pcd',
  CERTIDAO_DEBITOS_ESTADUAIS = 'certidao-debitos-estaduais',
  CERTIDAO_DEBITOS_MT = 'certidao-debitos-mt',
  CERTIDAO_DEBITOS_MUNICIPAIS = 'certidao-debitos-municipais',
  CERTIDAO_TRF_DISTRIBUICAO = 'certidao-distribuicao',
  CERTIDAO_DISTRIBUIDOR = 'certidao-distribuidor-stf',
  CERTIDAO_EMPRESA_CARTORIO_PJ = 'certidao-empresa-cartorio-pj',
  CERTIDAO_ENTIDADES_SUPERFISIONADAS = 'certidao-entidades-supervisionadas',
  CERTIDAO_ESCRITURA = 'certidao-escritura',
  CERTIDAO_EXTRATO_DEBITOS_MUNICIPAIS = 'certidao-extrato-debitos-municipais',
  CERTIDAO_IBAMA_DEBITOS = 'certidao-ibama',
  CERTIDAO_IBAMA_EMBARGOS = 'certidao-embargo-ibama',
  CERTIDAO_IMOVEL = 'certidao-imovel',
  CERTIDAO_IMPROBILIDADE_ADMINISTRATIVA = 'certidao-improbidade-administrativa',
  CERTIDAO_INTERDICAO = 'certidao-interdicao',
  CERTIDAO_INSS = 'certidao-inss',
  CERTIDAO_IPTU = 'certidao-iptu',
  CERTIDAO_ITR = 'certidao-itr',
  CERTIDAO_JUNTA_COMERCIAL = 'certidao-junta-comercial',
  CERTIDAO_JUSTICA_ESTADUAL = 'certidao-justica-estadual',
  CERTIDAO_MPE_CIVIL = 'certidao-mpe-inquerito-civil',
  CERTIDAO_MPE_CRIMINAL = 'certidao-mpe-inquerito-criminal',
  CERTIDAO_MT_TRABALHISTAS = 'certidao-inf-trab-mt',
  CERTIDAO_NASCIMENTO = 'certidao-nascimento',
  CERTIDAO_NEGATIVA_CONTAS_TCU = 'certidao-negativa-contas-irregulares-tcu',
  CERTIDAO_NEGATIVA_CORRECIONAL = 'certidao-negativa-correcional',
  CERTIDAO_NEGATIVA_CORRECIONAL_CGU = 'certidao-negativa-correcional-cgu',
  CERTIDAO_NEGATIVA_DEBITOS_TRIBUTARIOS_PGE = 'certidao-negativa-debitos-tributarios-pge',
  CERTIDAO_NEGATIVA_FEITOS = 'certidao-negativa-feitos',
  CERTIDAO_NEGATIVA_FGTS = 'certidao-negativa-fgts',
  CERTIDAO_NEGATIVA_MPF = 'certidao-negativa-mpf',
  CERTIDAO_NEGATIVA_PROPRIEDADE = 'certidao-negativa-propriedade',
  CERTIDAO_NEGATIVA_STJ = 'certidao-negativa-stj',
  CERTIDAO_NEGATIVA_TCU = 'certidao-negativa-processo-tcu',
  CERTIDAO_OBITO = 'certidao-obito',
  CERTIDAO_PROCURACAO = 'certidao-procuracao',
  CERTIDAO_PROTESTO = 'certidao-protesto',
  CERTIDAO_PRONAF_APTIDAO = 'certidao-pronaf-aptidao',
  CERTIDAO_QUITACAO_ELEITORAL = 'certidao-quitacao-eleitoral',
  CERTIDAO_PENHOR_SAFRA = 'certidao-penhor-safra',
  CERTIDAO_PREVIA_MATRICULA = 'certidao-previa-matricula',
  CERTIDAO_PROPRIEDADE_AERONAVE = 'certidao-propriedade-aeronave',
  CERTIDAO_RAIS = 'certidao-recibo-entrega-rais',
  CERTIDAO_VALOR_VENAL = 'certidao-valor-venal',
  CERTIDAO_STM_CRIMINAIS = 'certidao-neg-acoes-crim-stm',
  CERTIDAO_SPU = 'certidao-spu',
  CERTIDAO_TRIBUNAL_CONTAS = 'certidao-tribunal-contas-estadual',
  CERTIDAO_SITUACAO_PJ = 'certidao-situacao-pessoa-juridica-tcu',
}

export enum DiligenceCode {
  DILIGENCIA_ACOMPANHAMENTOS = 'diligencia-acompanhamentos',
  DILIGENCIA_ATAS_NOTARIAIS = 'diligencia-atas-notariais',
  DILIGENCIA_CONSULTAS = 'diligencia-consultas',
  DILIGENCIA_COPIAS = 'diligencia-copias',
  DILIGENCIA_DESPACHANTES = 'diligencia-despachantes',
  DILIGENCIA_GUIAS = 'diligencia-guias',
  DILIGENCIA_OUTRAS = 'diligencia-outras',
  DILIGENCIA_PREVIA_CUSTAS_REGISTROS = 'diligencia-previa-custas-registros',
  DILIGENCIA_PROTOCOLOS = 'diligencia-protocolos',
  DILIGENCIA_REGISTRO_IMOVEIS = 'diligencia-registro-imoveis',
  DILIGENCIA_REQUERIMENTOS = 'diligencia-requerimentos',
  DILIGENCIA_RETIRADA_DOCS = 'diligencia-retirada-docs',
}

export enum DocumentRegistrationCode {
  RGI = 'registro-imoveis',
  RTD = 'registro-titulos-documentos',
  ATA_NOTARIAL = 'registro-ata-notarial',
}

export enum ResearchCode {
  PESQUISA_BENS = 'pesquisa-bens',
  PESQUISA_CAR = 'pesquisa-car',
  PESQUISA_COMPLIANCE = 'pesquisa-compliance',
  PESQUISA_DADOS_CADASTRAIS = 'pesquisa-dados-cadastrais',
  PESQUISA_DIVIDA_ATIVA_PGE = 'pesquisa-divida-ativa-pge',
  PESQUISA_DIVORCIO = 'pesquisa-escritura-divorcio',
  PESQUISA_EMPRESA_CARTORIOS_PJ = 'pesquisa-empresa-cartorio-pj',
  PESQUISA_ESCRITURA = 'pesquisa-escritura',
  PESQUISA_IMOVEL_RURAL = 'pesquisa-cadastro-imovel-rural',
  PESQUISA_INVENTARIO = 'pesquisa-inventario',
  PESQUISA_INDICADORES_ATIVIDADE = 'pesquisa-indicadores-atividade',
  PESQUISA_JUNTA_COMERCIAL = 'pesquisa-junta-comercial',
  PESQUISA_OBITO = 'pesquisa-obito',
  PESQUISA_PARTICIPACAO_SOCIETARIA = 'pesquisa-relacionamentos-economicos',
  PESQUISA_PROCURACAO = 'pesquisa-procuracao',
  PESQUISA_PROCESSOS_JUDICIAIS = 'pesquisa-processos-judiciais',
  PESQUISA_PROPRIEDADE_AERONAVE = 'pesquisa-propriedade-aeronave',
  PESQUISA_PROTESTO = 'pesquisa-protesto',
  PESQUISA_RECEITA_FEDERAL = 'pesquisa-receita-federal',
  PESQUISA_SINTEGRA = 'pesquisa-consulta-cadastro-contribuinte',
  PESQUISA_PROPRIEDADE_VEICULOS = 'pesquisa-propriedade-veiculo',
  PESQUISA_VEICULAR = 'pesquisa-veicular',
}

export enum SignatureCertificationCode {
  COLETA_ASSINATURA = 'coleta-assinaturas',
  CERTIFICADO_DIGITAL_CPF = 'certificado-digital-cpf',
}

export type DocumentOrResearchCode =
  | CertificateCode
  | DocumentRegistrationCode
  | ResearchCode
  | SignatureCertificationCode

export type ServiceCode =
  | AICode
  | CertificateCode
  | ResearchCode
  | DiligenceCode
  | DocumentRegistrationCode
  | SignatureCertificationCode

export function isServiceCode(code: string): boolean {
  return (
    Object.values(CertificateCode).includes(code as CertificateCode) ||
    Object.values(DiligenceCode).includes(code as DiligenceCode) ||
    Object.values(DocumentRegistrationCode).includes(code as DocumentRegistrationCode) ||
    Object.values(ResearchCode).includes(code as ResearchCode) ||
    Object.values(SignatureCertificationCode).includes(code as SignatureCertificationCode)
  )
}

export type JusticaEstadualType = 'civel' | 'criminal' | 'titulos-documentos' | 'execucoes-fiscais'

export type PesquisaBensType = 'simples' | 'completa'

export type CertidaoTypes = 'breverelato' | 'inteiroteor' | 'ficha-cadastral' | 'rip'

export type AllAvailableTypes =
  | AIDocumentType
  | CertidaoImovelTypes
  | PesquisaBensType
  | CertidaoTypes
  | CertidaoRaisTypes
  | CertidaoJuntaTypes
  | JusticaEstadualType
  | AvailableTypesPesquisaDividaPGEOptions

export type CertidaoImovelTypes =
  | 'matricula'
  | 'vintenaria'
  | 'onus'
  | 'transcricao'
  | 'condominio'
  | 'docarquivado'
  | 'inteiroteor'
  | 'livro3auxiliar'
  | 'livro3garantias'
  | 'propriedade'
  | 'quesitos'

export type ServicesById = Record<number, Service>

export type SelectedLocation = {
  [FieldName.TODOS_CARTORIOS_CIDADE]?: boolean
  [FieldName.URL_UF]?: LabelInValue | LabelInValue[]
  [FieldName.URL_CIDADE]?: LabelInValue | LabelInValue[]
  [FieldName.URL_CARTORIO]?: LabelInValue | LabelInValue[]
}

export type SelectedType = string | LabelInValue | undefined
