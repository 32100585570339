import { useDispatch } from 'react-redux'

import { Kit } from 'domain/kits/types'

import { kitsStateActions, useKitsState } from './kitsSlice'

export function useSetViewKitState() {
  const dispatch = useDispatch()

  const setViewKit = (value: Kit) => {
    dispatch(kitsStateActions.setViewtKit(value))
  }

  return setViewKit
}

export const useViewKitValue = () => {
  const { viewKit } = useKitsState()
  return viewKit
}

export function useViewKitItemsValue() {
  const viewKit = useViewKitValue()
  return viewKit?.items
}
