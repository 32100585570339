import { LabelInValue } from 'domain/app/types'
import { SelectedLocation } from 'domain/servicesCBRdoc/types'
import { getLabel } from 'utils/transformData'

export function getDisplayLocation({ url_cartorio, url_cidade, url_uf }: SelectedLocation) {
  if (url_cartorio) {
    return getLabel(url_cartorio) as string
  }

  if (url_cidade) {
    const cities = getLabel(url_cidade) as string
    return url_uf ? `${cities}, ${(url_uf as LabelInValue).value}` : cities
  }

  return getLabel(url_uf) as string
}

export function getDisplayLocations({ url_cartorio, url_cidade, url_uf }: SelectedLocation) {
  return {
    notaries: getLabel(url_cartorio, true) as string[],
    cities: getLabel(url_cidade, true) as string[],
    federativeUnits: getLabel(url_uf, true) as string[],
  }
}

export function getDisplayItemLocation({ url_cartorio, url_cidade, url_uf }: SelectedLocation) {
  if (url_cartorio) {
    const label = getLabel(url_cartorio)

    if (label) return label
  }

  if (url_cidade) {
    const label = getLabel(url_cidade)

    if (label) return label
  }

  if (url_uf) {
    return getLabel(url_uf)
  }

  return null
}
