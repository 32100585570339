import { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { clamp, distance } from 'popmotion'
import { arrayMoveMutable as move } from 'array-move'

export const SMALL_WIDTH_BREAKPOINT = 1490

export const useViewWidth = () => {
  const [viewWidth, setViewWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setViewWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [viewWidth])
  return viewWidth
}

export const useIsSmallDevice = () => {
  const width = useViewWidth()
  const [isSmallDevice, setIsSmallDevice] = useState(false)

  useEffect(() => {
    setIsSmallDevice(width < SMALL_WIDTH_BREAKPOINT)
  }, [width])

  return isSmallDevice
}

export function useScrollTo(id: string) {
  useLayoutEffect(() => {
    document.getElementById(id)!.scroll(0, 0)
  }, [])
}

export function useMeasurePosition(update: any) {
  // We'll use a `ref` to access the DOM element that the `motion.li` produces.
  // This will allow us to measure its height and position, which will be useful to
  // decide when a dragging element should switch places with its siblings.
  const ref = useRef<any>(null)

  // Update the measured position of the item so we can calculate when we should rearrange.
  useEffect(() => {
    update({
      height: ref.current!.offsetHeight,
      top: ref.current!.offsetTop,
    })
  })

  return ref
}

export function usePositionReorder(initialState: any) {
  const [order, setOrder] = useState(initialState)

  // We need to collect an array of height and position data for all of this component's
  // `Item` children, so we can later us that in calculations to decide when a dragging
  // `Item` should swap places with its siblings.
  const positions = useRef<any>([]).current
  const updatePosition = (i:number, offset: any) => (positions[i] = offset)

  // Find the ideal index for a dragging item based on its position in the array, and its
  // current drag offset. If it's different to its current index, we swap this item with that
  // sibling.
  const updateOrder = (i: number, dragOffset: any) => {
    const targetIndex = findIndex(i, dragOffset, positions)
    if (targetIndex !== i) setOrder(move(order, i, targetIndex))
  }

  return [order, updatePosition, updateOrder]
}

const buffer = 30

export const findIndex = (i: any, yOffset: any, positions: any) => {
  let target = i
  const { top, height } = positions[i]
  const bottom = top + height

  // If moving down
  if (yOffset > 0) {
    const nextItem = positions[i + 1]
    if (nextItem === undefined) return i

    const swapOffset = distance(bottom, nextItem.top + nextItem.height / 2) + buffer
    if (yOffset > swapOffset) target = i + 1

    // If moving up
  } else if (yOffset < 0) {
    const prevItem = positions[i - 1]
    if (prevItem === undefined) return i

    const prevBottom = prevItem.top + prevItem.height
    const swapOffset = distance(top, prevBottom - prevItem.height / 2) + buffer
    if (yOffset < -swapOffset) target = i - 1
  }

  return clamp(0, positions.length, target)
}
