import React from 'react'

import { ViewOrderResearchActionsInline, ViewResearchActions, ViewResearchWithResults } from 'domain/orderItem'
import { ExcelGenerator, ResearchResult, TableColumn } from 'domain/orderItem/types'
import { displayDate } from 'utils/dateTime'

function ViewPesquisaJuntasComerciais() {
  return (
    <ViewResearchWithResults columns={columns} actions={<ViewResearchActions excel={excelGenerator} />} />
  )
}

const columns: TableColumn[] = [
  {
    title: 'Data',
    dataIndex: 'data',
    className: 'font-bold',
    render: displayDate
  },
  {
    title: 'Número do ato',
    dataIndex: 'numero_ato',
    className: 'font-bold',
  },
  {
    title: 'NIRE',
    dataIndex: 'nire',
  },
  {
    title: 'Descrição',
    dataIndex: 'descricao',
  },
  {
    title: '',
    dataIndex: 'actions',
    align: 'right',
    className: 'text-right',
    render: (_: any, result: ResearchResult) => <ViewOrderResearchActionsInline result={result} />,
  },
]

const excelGenerator: ExcelGenerator = {
  columns: [...columns].splice(0, columns.length - 1),
}

export default ViewPesquisaJuntasComerciais
