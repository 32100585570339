import { useEffect, useMemo } from 'react'
import { Form, Input } from 'antd'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Button } from 'antd'

import { RoutePaths } from 'app/routes'
import { useCheckResetToken, useRedefinePassword } from 'domain/auth'
import { GuestLayout } from 'layouts'
import { ScrollableErrorAlert } from 'ui'

export default function NewPassword() {
  const location = useLocation()
  const history = useHistory()
  const { checkResetToken, ...checkToken } = useCheckResetToken()
  const { redefinePassword, ...redefining } = useRedefinePassword()

  const token = useMemo(() => {
    const search: any = new URLSearchParams(location.search)

    if (search.has('token')) {
      return search.get('token')
    }
  }, [location.search])

  useEffect(() => {
    if (token) {
      checkResetToken(token)
    } else {
      history.push(RoutePaths.LOGIN)
    }
  }, [token])

  const handleSubmit = (values: any) => {
    if (token) {
      redefinePassword(token, values.password)
    }
  }

  return (
    <GuestLayout showMessage>
      {checkToken.error && (
        <>
          <ScrollableErrorAlert error={checkToken.error} />
          <Button type="primary" onClick={() => history.push(RoutePaths.FORGOT_PASSWORD)} block>
            Reenviar email
          </Button>
        </>
      )}

      {checkToken.status === 'success' && (
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark={false}
          scrollToFirstError
          className="w-100"
        >
          <h1 className="text-3xl font-light text-gray-700 mb-5">Redefinir senha</h1>

          {redefining.error && <ScrollableErrorAlert error={redefining.error} />}

          <Form.Item
            name="password"
            label="Nova senha"
            rules={[
              {
                required: true,
                message: 'Informe sua nova senha',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirmar nova senha"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Confirme sua senha',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject('As senhas não conferem.')
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Button type="primary" htmlType="submit" block>
            Enviar
          </Button>
        </Form>
      )}

      <p className="mt-10 text-center text-sm">
        <Link className="underline" to={RoutePaths.LOGIN}>
          Voltar para login
        </Link>
      </p>
    </GuestLayout>
  )
}
