import React, { useMemo } from 'react'

import { LabelInValue } from 'domain/app/types'
import { KitItemDefaultData } from 'domain/kits/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { ServiceCode } from 'domain/servicesCBRdoc/types'
import { Research } from 'domain/servicesCBRdoc/config'

interface Props {
  defaultData: KitItemDefaultData
  serviceCode: ServiceCode
}

function DefaultDataTags({ defaultData, serviceCode }: Props) {
  const defaultDataTags = useMemo(() => {
    const {
      todos_cartorios_cidade,
      situacao,
      url_cartorio,
      url_cidade,
      url_uf,
      tipo_pessoa,
      ...otherFields
    } = defaultData

    const { isResearch } = getServiceConfig<Research>(serviceCode)

    const notaryTags = resolveNotaryFields(
      url_cartorio,
      url_cidade,
      url_uf,
      todos_cartorios_cidade,
      isResearch ? ' da pesquisa' : ''
    )

    const tags = notaryTags ?? []

    Object.values(otherFields).forEach((field: LabelInValue | undefined) => {
      if (field) {
        const label = field?.label ?? field

        if (typeof label === 'string') {
          tags.push(field?.label ?? field)
        }        
      }
    })

    if (situacao) {
      tags.push(`Situação: ${situacao.label}`)
    }

    if (tipo_pessoa) {
      tags.push(tipo_pessoa === 'fisica' ? 'Pessoa física' : 'Pessoa jurídica')
    }

    return tags
  }, [defaultData])

  return (
    <div className="display-block">
      {defaultDataTags.map((text, index) => (
        <span
          key={index}
          title={text as string}
          className="inline-flex items-center justify-center text-secondary-300 py-1 text-xs mr-1 mb-1 px-2 bg-gray-100 border border-secondary-300 rounded-sm"
        >
          {text}
        </span>
      ))}
    </div>
  )
}

function resolveNotaryFields(
  notary: LabelInValue | LabelInValue[] | undefined,
  city: LabelInValue | undefined,
  federativeUnit: LabelInValue | undefined,
  allNotariesSelected: boolean | undefined,
  labelPostfix: string = ' do Cartório'
) {
  let hasNotaries = false
  const tags = []

  if (notary) {
    const notaries = Array.isArray(notary) ? notary : [notary]
    hasNotaries = notaries.length > 0

    if (hasNotaries) {
      if (allNotariesSelected) {
        tags.push(`Todos os ${notaries.length} cartórios`)
      } else {
        notaries.forEach((selectedNotary: any) => {
          tags.push(`${selectedNotary.label} - ${city?.label}/${federativeUnit?.label}`)
        })
      }
    }
  }

  if (!hasNotaries && (city || federativeUnit)) {
    if (city) {
      tags.push(`Cidade${labelPostfix}: ${city.label}/${federativeUnit?.label}`)
    } else if (federativeUnit) {
      tags.push(`Estado${labelPostfix}: ${federativeUnit.label}`)
    }
  }

  return tags.length > 0 ? tags : undefined
}

export default DefaultDataTags
