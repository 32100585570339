import React from 'react'

import { useSetStatuses } from 'domain/orders/state/filters'
import { OrderStatuses, StatusCode } from 'domain/orders/types'
import { FilterTag } from 'ui'

export default function FilterByStatusTags({ value }: { value: StatusCode[] }) {
  const setStatuses = useSetStatuses()
  const statuses = Object.values(OrderStatuses).filter(status => value.includes(status.code))

  return (
    <>
      {statuses.map(status => (
        <FilterTag key={status.code} onClick={() => setStatuses(value.filter(code => code !== status.code))}>
          {status.name}
        </FilterTag>
      ))}
    </>
  )
}
