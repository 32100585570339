import { FormInstance } from 'antd'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'

import { useExplorerSearchQuery } from '../services'

function useValidateExplorerItemName(form: FormInstance) {
  const [searchTerm, setSearchTerm] = useState(form.getFieldValue('name') || '')
  const [debouncedValue] = useDebounce(searchTerm, 300)

  const { data: items, isFetching } = useExplorerSearchQuery(debouncedValue, {
    onSuccess: (data: any) => {
      const itemWithSameName = data.filter((res: any) => res.name === searchTerm)
      if (itemWithSameName.length) {
        return itemWithSameName
      }
    },
  })

  return {
    items,
    isFetching,
    setSearchTerm,
  }
}

export default useValidateExplorerItemName
