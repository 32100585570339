import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ItemType } from 'antd/lib/menu/hooks/useItems'

import { errorHandler } from 'api/errors'
import { RoutePaths } from 'app/routes'
import { MissingFieldsError } from 'domain/createOrder'
import { OrderMissingFieldsModal, useAddResearchResultToCart } from 'domain/createOrder/researchResults'
import { MissingFields } from 'domain/createOrder/types'
import { useGetOrderItem } from 'domain/orderItem/shared'
import { ResearchResult } from 'domain/orderItem/types'
import { ResultCode } from 'domain/orders/types'
import { Icon } from 'ui'

import useResultsToOrder from '../helpers/useResultsToOrder'

export default function useOrderResearchMenuItem(result: ResearchResult): [ItemType, boolean] {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const orderItem = useGetOrderItem()
  const [missingFields, setMissingFields] = useState<MissingFields[]>([])
  const addToCart = useAddResearchResultToCart()
  const resultsToOrder = useResultsToOrder(orderItem, [result])

  const onClick = async () => {
    setLoading(true)

    try {
      await addToCart(result, orderItem)
      history.push(RoutePaths.SHOPPING_CART)
    } catch (error) {
      setLoading(false)

      if (error instanceof MissingFieldsError) {
        setMissingFields(error.data)
      } else {
        errorHandler(error, { code: '0x006' })
      }
    }
  }

  const onClose = () => {
    setLoading(false)
    setMissingFields([])
  }

  const code = (result.resultado as ResultCode.POSITIVE | ResultCode.NEGATIVE) || ResultCode.POSITIVE

  if (!resultsToOrder[code]?.canOrder) {
    return [null, false]
  }

  return [
    {
      label: (
        <>
          <span>Pedir certidão</span>
          <OrderMissingFieldsModal missingFields={missingFields} onClose={onClose} />
        </>
      ),
      icon: <Icon name="paper-plane" className="text-xl" />,
      key: 'order-result',
      onClick,
    },
    loading,
  ]
}
