import { CreateOrderLegalEntityTabs } from 'domain/createOrder/document'
import { ResearchFlow } from 'domain/createOrder/researches'
import { CNPJAndNameFields, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../pesquisaInventarioConfig'

export default function CreateOrderPesquisaInventario() {
  return (
    <ResearchFlow
      header={<p className="mb-5 text-gray-600">Informe o CPF ou CNPJ que deseja pesquisar.</p>}
      renderFormItem={(itemId: number) => <PesquisaInventarioForm itemId={itemId} />}
    />
  )
}

function PesquisaInventarioForm({ itemId }: { itemId: number }) {
  const inputProps = { itemId, autoFocus: itemId === 0 }

  return (
    <CreateOrderLegalEntityTabs
      itemId={itemId}
      fisica={
        <>
          <CPFInput {...inputProps} />
          <NameField itemId={itemId} field={nome_pf} />
        </>
      }
      juridica={<CNPJAndNameFields companyField={nome_pj} {...inputProps} />}
    />
  )
}

const { nome_pf, nome_pj } = config.formFields
