import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { TIPO } = FieldName

type CertidaoNegativaContasTCUFields = Record<
  FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ | FieldName.TIPO,
  FieldConfig
>

class CertidaoNegativaContasTCU extends Certificate {
  formFields: CertidaoNegativaContasTCUFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
    tipo: Fields.tipo,
  }

  constructor() {
    super({
      id: 48,
      priceByFields: [TIPO],
      shortName: 'Certidão Negativa Contas TCU',
      shoudAutoLoadDynamicFields: true,
      steps: ['dados'],      
    })
  }
}

const service = new CertidaoNegativaContasTCU().initialize()

export default service
