import { Form } from 'antd'
import { FormInstance } from 'antd/lib/form'

import { ApiError, ApiStatus } from 'api/types'
import { FormShippingAddressData } from 'domain/app/types'
import { SubmitButton, TextInput } from 'ui'

import ShippingAddressFields from './ShippingAddressFields'

interface Props {
  form: FormInstance
  onSubmit?: (address: FormShippingAddressData) => void
  isMainAddress?: boolean
  status?: ApiStatus | null | undefined
  error: ApiError | null | undefined
  buttonLabel?: string
}

export default function AddShippingAddressForm({
  form,
  onSubmit,
  buttonLabel = 'Adicionar',
  isMainAddress,
  status,
  error,
}: Props) {
  const handleSubmit = async (values: FormShippingAddressData) => {
    if (onSubmit) {
      return await onSubmit(values)
    }

    return values
  }

  const loading = status === 'loading'

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      requiredMark={false}
      scrollToFirstError
      initialValues={{
        name: isMainAddress ? 'Principal' : '',
      }}
      className="md:max-w-4xl lg:max-w-lg"
    >
      <TextInput name="name" label="Identificação do endereço" required />

      <ShippingAddressFields />

      <SubmitButton label={buttonLabel} loading={loading} disabled={loading} />

      {error && <p>{error.message}</p>}
    </Form>
  )
}
