import { Form } from 'antd'
import { NamePath } from 'antd/lib/form/interface'
import { ValidateStatus } from 'antd/es/form/FormItem'
import React from 'react'

import { requiredRule } from 'app/config/validationRules'
import { useSelectOnlyOption } from 'domain/servicesCBRdoc/fields'
import Select, { SelectProps } from './Select'

export interface SelectFieldProps extends SelectProps {
  hasError?: boolean
  label: string
  name?: NamePath
  required?: boolean
  help?: React.ReactNode
  validateStatus?: ValidateStatus
  onChange?: (value: any) => void
}

export default function SelectField({
  disabled,
  hasError,
  label,
  name,
  required,
  options,
  className,
  help,
  validateStatus,
  labelInValue,
  mode,
  optionLabelKey,
  optionValueKey,
  onChange,
  ...props
}: SelectFieldProps) {
  useSelectOnlyOption({
    options,
    fieldName: name,
    labelInValue,
    mode,
    optionLabelKey,
    optionValueKey,
    onChange,
  })

  return (
    <Form.Item
      name={name}
      label={label}
      rules={required ? requiredRule : undefined}
      help={hasError ? 'Erro ao carregar os dados.' : help}
      validateStatus={hasError ? 'error' : validateStatus}
      className={className}
    >
      <Select
        disabled={!options || !options.length || disabled}
        options={options}
        labelInValue={labelInValue}
        mode={mode}
        optionLabelKey={optionLabelKey}
        optionValueKey={optionValueKey}
        onChange={onChange}
        {...props}
      />
    </Form.Item>
  )
}
