import { KitItem } from 'domain/kits/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'

import KitOrderLocationFields from './KitOrderLocationFields'

interface Props {
  autoFocus: boolean
  kitItem: KitItem
}

export default function KitOrderLocation({ kitItem, autoFocus }: Props) {
  const service = getServiceConfig(kitItem.service.code)
  const ufIsOptional = Boolean(service.formFields.url_uf?.optional)

  return (
    <KitOrderLocationFields
      key={kitItem.id}
      kitItem={kitItem}
      autoFocus={autoFocus}
      required={!ufIsOptional}
    />
  )
}
