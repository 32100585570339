import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Routes } from 'app/routes'
import { createOrderActions } from 'domain/createOrder/document'
import { ServiceCode } from 'domain/servicesCBRdoc/types'

import { useShoppingCartState } from '../state'

export default function useEditCartItem(itemId: string) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { orders } = useShoppingCartState()

  return function editCartItem() {
    const order = orders.find(val => val.cartItemId === itemId)

    if (order) {
      const url = getCreateOrderUrl(order.selectedService.code!)

      dispatch(
        createOrderActions.setCreateOrder({
          ...order,
          createStatus: 'process',
        })
      )
      history.push(url)
    }
  }
}

function getCreateOrderUrl(serviceCode: ServiceCode, uri?: string) {
  const url = Routes.CreateOrder(serviceCode)
  return uri ? `${url}/${uri}` : url
}
