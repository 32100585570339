import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { atomURI } from 'utils/navigation'

const currentPageAtom = atom<number | undefined>(undefined)
const currentPageURIAtom = atomURI(currentPageAtom, 'p')
const pageSize = atomWithStorage('reports-page-size', 10)
const pageCount = atom(0)
const totalCount = atom(0)

export const useCurrentPage = () => useAtom(currentPageURIAtom)
export const usePageCount = () => useAtom(pageCount)
export const usePageSize = () => useAtom(pageSize)
export const useTotalCount = () => useAtom(totalCount)

export const useGetCurrentPage = () => useAtomValue(currentPageAtom)
export const useSetCurrentPage = () => useSetAtom(currentPageURIAtom)
