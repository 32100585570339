import { Form } from 'antd'
import { NamePath } from 'antd/lib/form/interface'
import { useEffect } from 'react'

interface Props {
  options: any[] | undefined
  fieldName: NamePath
  onChange?: (value: any) => void
  labelInValue?: boolean
  mode?: 'multiple' | 'tags'
  optionLabelKey?: string
  optionValueKey?: string
}

export default function useSelectOnlyOption({
  options,
  fieldName,
  labelInValue,
  mode,
  optionLabelKey = 'label',
  optionValueKey = 'value',
  onChange,
}: Props) {
  const form = Form.useFormInstance()

  useEffect(() => {
    if (form && options && options.length === 1) {
      form.setFieldValue(fieldName, options[0])

      if (onChange) {
        let value: any = options[0]

        if (labelInValue) {
          value = { label: options[0][optionLabelKey], value: options[0][optionValueKey] }
        }

        if (mode === 'multiple' || mode === 'tags') {
          value = [value]
        }

        onChange(value)
      }
    }
  }, [options])
}
