import { useState } from 'react'

import { Select } from 'ui'
import { useGetMissingFields, useSetColumnsIdentification } from '../../state'
import { FieldIdentificationSelectedOption, FieldIdentificationValue } from '../../types'

type Props = { column: number }

export default function KitSpreadsheetIdentifyColsSelect({ column }: Props) {
  const setColumnsFields = useSetColumnsIdentification()
  const [currentSelected, setValue] = useState<FieldIdentificationSelectedOption | undefined>()
  const missingFields = useGetMissingFields(currentSelected)

  if (missingFields.length === 0) {
    return null
  }

  return (
    <Select
      placeholder="Campo"
      size="small"
      className="min-w-32"
      popupClassName="min-w-64"
      options={missingFields}
      labelInValue
      onChange={newSelected => {
        setColumnsFields(prev => {
          if (currentSelected) {
            const copyPrev = { ...prev }
            delete copyPrev[currentSelected.value as FieldIdentificationValue]

            if (!newSelected) {
              return copyPrev
            }
          }

          return {
            ...prev,
            [newSelected.value]: column,
          }
        })

        setValue(newSelected)
      }}
    />
  )
}
