import { Button } from 'ui'
import { oauthSignIn } from '../services/oAuthGoogle'

export default function GoogleSignIn() {
  return (
    <Button
      type="default"
      size="middle"
      ghost
      icon="google"
      onClick={oauthSignIn}
      className="flex-1 text-sm border-gray-300 text-gray-700 hover:border-primary hover:text-primary"
    >
      Continue com Google
    </Button>
  )
}
