import React, { useState } from 'react'

import { SelectedItems } from 'domain/orders/types'
import { OrderItemListing } from 'domain/orderItem/types'

import OrderItemsTable from '../orderOverview/OrderItemsTable'
import OrderItemsMassActions from './actions/OrderItemsMassActions'

interface Props {
  items: OrderItemListing[]
  loading: boolean
}

export default function OrderItemsList({ items, loading }: Props) {
  const [selected, setSelected] = useState<SelectedItems>({ items: [], ids: [] })

  const onClose = () => {
    setSelected({ items: [], ids: [] })
  }

  return (
    <>
      <OrderItemsTable items={items} loading={loading} selectedIds={selected.ids} setSelected={setSelected} />
      <OrderItemsMassActions selectedItems={selected.items} onClose={onClose} />
    </>
  )
}
