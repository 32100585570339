import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { FORMATO, URL_CARTORIO, URL_CIDADE, URL_UF } = FieldName

type CertidaoPenhorSafraFields = Record<
  | FieldName.CNPJ
  | FieldName.CPF
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.NOME_PROPRIEDADE
  | FieldName.REGISTRO
  | FieldName.TIPO_SAFRA
  | FieldName.URL_CARTORIO
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

class CertidaoPenhorSafra extends Certificate {
  formFields: CertidaoPenhorSafraFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
    nome_propriedade: Fields.nome_propriedade,
    registro: Fields.registro,
    tipo_safra: Fields.tipo_safra,
    url_cartorio: Fields.url_cartorio,
    url_cidade: Fields.url_cidade,
    url_uf: Fields.url_uf,
  }

  constructor() {
    super({
      availableFormatsFields: [URL_CARTORIO, URL_CIDADE, URL_UF],
      id: 53,
      priceByFields: [FORMATO, URL_CARTORIO, URL_CIDADE, URL_UF],
      shortName: 'Certidão Penhor Safra',
      steps: ['localizacao', 'dados', 'entrega'],
    })
  }
}

const service = new CertidaoPenhorSafra().initialize()

export default service
