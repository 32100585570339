import React, { useState } from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { useTableSelection } from 'domain/notifications/state'
import { Notification, NotificationOrderItem, NotificationType as Type } from 'domain/notifications/types'
import { CreateRecurrence } from 'domain/recurrences'
import RegisterInfo from 'domain/shoppingCart/components/RegisterInfo'

import NotificationActions from './NotificationActions'
import NotificationContent from '../NotificationContent'
import NotificationIcon from '../NotificationIcon'
import NotificationType from '../NotificationType'

interface Props {
  notifications: Notification[]
  loading: boolean
}

export default function NotificationsTable({ notifications, loading }: Props) {
  const [itemsToCreateRecurrence, setItemsToCreateRecurrence] = useState<NotificationOrderItem[]>([])
  const [selected, setSelected] = useTableSelection()

  const columns: ColumnsType<Notification> = [
    {
      title: '',
      dataIndex: 'type',
      key: 'icon',
      render: (type: Type) => <NotificationIcon type={type} />,
      className: 'w-16 max-w-16',
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      className: 'w-64',
      render: (type: Type) => {
        return <NotificationType type={type} />
      },
    },
    {
      title: 'Data',
      dataIndex: 'createdAtDisplay',
      className: 'w-20',
    },
    {
      title: 'Conteúdo',
      dataIndex: 'content',
      render: (_, notification) => {
        return <NotificationContent notification={notification} />
      },
    },
    {
      title: 'Registro',
      dataIndex: 'details',
      className: 'min-w-36',
      render: (_: string, notification: Notification) => {
        if (notification.type !== Type.ORDER_ITEM_FINALIZED && notification.type !== Type.ORDER_FINALIZED) {
          return null
        }

        const item =
          notification.type === Type.ORDER_ITEM_FINALIZED
            ? notification.orderItem!
            : notification.order!.firstOrder!

        // TODO: pegar o registro da api notifications
        return <RegisterInfo service={item.service} submitData={item.submitData} />
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_: string, notification: Notification) => (
        <NotificationActions
          notification={notification}
          setItemsToCreateRecurrence={setItemsToCreateRecurrence}
        />
      ),
    },
  ]

  const rowSelection = {
    selectedRowKeys: selected.keys,
    hideSelectAll: false,
    preserveSelectedRowKeys: true,
    columnWidth: 36,
    onChange: (selectedRowKeys: React.Key[], selectedRows: Notification[]) => {
      setSelected({ keys: selectedRowKeys, rows: selectedRows })
    },
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={notifications}
        loading={loading}
        pagination={false}
        rowKey={record => record.id}
        rowSelection={rowSelection as any}
        rowClassName={resolveRowStyle}
      />

      <CreateRecurrence
        items={itemsToCreateRecurrence}
        kind="order-items"
        openModal={itemsToCreateRecurrence.length > 0}
        onCloseModal={() => setItemsToCreateRecurrence([])}
      />
    </>
  )
}

const resolveRowStyle = (record: Notification) => {
  return record.readAt ? 'bg-gray-300 hover:bg-gray-200' : ''
}
