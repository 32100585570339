import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { CONJUGE1, FORMATO, URL_CARTORIO, URL_CIDADE, URL_UF } = FieldName

type CertidaoCasamentoFields = Record<
  | FieldName.CONJUGE1
  | FieldName.CONJUGE2
  | FieldName.DATA_CASAMENTO
  | FieldName.LIVRO
  | FieldName.PAGINA
  | FieldName.TERMO
  | FieldName.URL_CARTORIO
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

class CertidaoCasamento extends Certificate {
  formFields: CertidaoCasamentoFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    url_cartorio: Fields.url_cartorio,
    conjuge1: Fields.conjuge1,
    conjuge2: Fields.conjuge2,
    casamento: Fields.casamento,
    livro: Fields.livro,
    pagina: Fields.pagina,
    termo: Fields.termo,
  }

  constructor() {
    super({
      availableFormatsFields: [URL_CARTORIO, URL_CIDADE, URL_UF],
      legalEntity: 'fisica',
      id: 2,
      orderNameField: CONJUGE1,
      priceByFields: [FORMATO, URL_CARTORIO, URL_CIDADE, URL_UF],
      shortName: 'Certidão Casamento',
      steps: ['localizacao', 'dados', 'entrega'],
    })
  }
}

const service = new CertidaoCasamento().initialize()

export default service
