import React from 'react'

import { useGetExplorerItem } from 'domain/myFiles/state'
import { ExplorerItemType } from 'domain/myFiles/types'

import RenameItem from './RenameItem'
import MoveItem from './MoveItem'
import DocumentType from './DocumentType'

interface Props {
  children?: React.ReactNode
}

export default function ExplorerActions({ children }: Props) {
  const explorerItem = useGetExplorerItem()

  if (!explorerItem) return null

  const canChangeType = explorerItem.type === ExplorerItemType.UPLOADED_FILE

  return (
    <div className="my-2 py-2 pb-1 border-b border-gray-300">
      <div className="mb-3 flex items-center text-gray-600 text-xs">
        <span className="text-base mt-1 text-secondary-500 font-bold">Arquivo</span>
      </div>

      {children}

      <DocumentType editable={canChangeType} />

      <div className="flex justify-between items-center">
        <RenameItem />
      </div>

      <MoveItem />
    </div>
  )
}
