import React from 'react'

import { Heading } from 'ui'

interface Props {
  heading?: string
  children: React.ReactNode
  fullWidth?: boolean
  id?: string
  className?: string
}

export default function Section({ heading, children, fullWidth, id, className = '' }: Props) {
  return (
    <div
      className={`flex-1 px-7 mb-4 ${fullWidth ? '' : 'md:max-w-4xl lg:max-w-lg xl:max-w-xl'} ${className}`}
      id={id}
    >
      {heading && <Heading size="3" text={heading} />}
      {children}
    </div>
  )
}
