import { useEffect } from 'react'
import { Form } from 'antd'
import { NamePath } from 'antd/lib/form/interface'

import { FieldConfig } from 'domain/servicesCBRdoc/fields/types'

export default function useSelectDefaultValue({
  field,
  name,
  onChange,
  availableOptions,
}: {
  field: FieldConfig
  name?: NamePath
  onChange?: (type: any) => void
  availableOptions: Record<any, any>[]
}) {
  const form = Form.useFormInstance()

  useEffect(() => {
    try {
      const fieldName = name ?? field.name

      if (availableOptions && availableOptions.length > 0) {
        const currentValue = form.getFieldValue(fieldName)

        if (!currentValue || !availableOptions.some(option => option.value === currentValue)) {
          const valueKey = field.apiOptionsValueKey ?? 'url'
          const defaultOption = availableOptions.find(o => o[valueKey] === field.defaultValue)
          const value = defaultOption?.[valueKey] ?? availableOptions[0]?.[valueKey]
          onChange && onChange(value)
          form.setFieldValue(fieldName, value)
        }
      }
    } catch {
      // Ignora
    }
  }, [availableOptions])
}
