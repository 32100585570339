import { useEffect } from 'react'

import { useCurrentCustomer, useIsCustomerPostPaid } from 'domain/customers'
import extractCustomerAddress from './extractCustomerAddress'
import { useSetShippingAddress } from '../state'

function useSetPostPaidAddress() {
  const isPostPaid = useIsCustomerPostPaid()
  const customer = useCurrentCustomer()
  const setShippingAddress = useSetShippingAddress()

  useEffect(() => {
    if (isPostPaid && customer.addressZipcode) {
      const customerAddress = extractCustomerAddress(customer)!

      const address = {
        ...customerAddress,
        id: 'main',
        name: 'Principal',
      }

      setShippingAddress(address)
    }
  }, [customer, isPostPaid])
}

export default useSetPostPaidAddress
