import React from 'react'

import { useAddKitItemMutation } from 'domain/kits/services'
import { KitItem, Kit } from 'domain/kits/types'
import { Button } from 'ui'

interface Props {
  kit: Kit
  kitItem: KitItem
  queryKey: any[]
}

export default function DuplicateKitItem({ kit, kitItem, queryKey }: Props) {
  const mutation = useAddKitItemMutation(queryKey)

  const loading = mutation.status === 'loading'

  const onClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, createdAt, ownerId, ...itemToDuplicate } = kitItem
    mutation.mutate({ kitId: kit.id, kitItem: itemToDuplicate })
  }

  return (
    <div className="flex justify-end mb-8">
      <Button
        onClick={onClick}
        loading={loading}
        size="small"
        icon="plus-circle"
        type="primary"
        ghost
      >
        Duplicar
      </Button>
    </div>
  )
}
