import React, { useReducer } from 'react'
import { Form, FormInstance, Input } from 'antd'

import { RecurrenceFilters } from 'domain/recurrences/types'
import { ClearFiltersButton, DateRangePicker, FilterButton, Modal, SubmitButton } from 'ui'

import { useClearFilters, useGetFilters, useFilters } from '../../state/filters'
import RecurrencesFilterOwner from '../RecurrencesFilterOwner'

export default function RecurrencesFilter() {
  const [form] = Form.useForm()
  const [open, toggleOpen] = useReducer(prev => !prev, false)
  const filters = useGetFilters()

  return (
    <>
      <Modal
        open={open}
        title="Filtros"
        extra={<ClearFilters form={form} onCloseModal={toggleOpen} />}
        onCancel={toggleOpen}
        destroyOnClose
      >
        <FiltersForm form={form} onCloseModal={toggleOpen} />
      </Modal>

      <FilterButton activeFilters={filters} onClick={toggleOpen} />
    </>
  )
}

function ClearFilters({ form, onCloseModal }: { form: FormInstance; onCloseModal: () => void }) {
  const clearFilters = useClearFilters()

  return (
    <ClearFiltersButton
      onClick={() => {
        form.setFieldsValue({
          nameOrId: '',
          ownerId: undefined,
          dates: undefined,
        })
        clearFilters()
        onCloseModal()
      }}
    />
  )
}

function FiltersForm({ form, onCloseModal }: { form: FormInstance; onCloseModal: () => void }) {
  const [filters, setFilters] = useFilters()

  const handleSubmit = (formData: RecurrenceFilters) => {
    setFilters(formData)
    onCloseModal()
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={filters}
      requiredMark={false}
      scrollToFirstError
      className="max-w-lg"
    >
      <Form.Item name="nameOrId" label="Nome ou ID">
        <Input size="middle" placeholder="Informe um nome ou ID" />
      </Form.Item>

      <RecurrencesFilterOwner />

      <Form.Item name="dates" label="Data de criação">
        <DateRangePicker allowClear allowEmpty={allowEmpty} />
      </Form.Item>

      <SubmitButton label="Aplicar filtros" />
    </Form>
  )
}

const allowEmpty: any = [true, true]
