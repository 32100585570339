import { useMemo } from 'react'
import { Button, Collapse, Form, FormInstance, Radio } from 'antd'
import { FormListFieldData } from 'antd/lib/form'

import { Icon, PopConfirmDelete } from 'ui'

import ColetaAssinaturasForm from './ColetaAssinaturasForm'

interface ItemsCollapseProps {
  activeItem: number | undefined
  setActiveItem: React.Dispatch<React.SetStateAction<number | undefined>>
  index: number
  item: FormListFieldData
  totalItems: number
  form: FormInstance
  isSubmitting: boolean
  onRemove: () => void
  onMoveUp: () => void
  onMoveDown: () => void
}

const ColetaAssinaturaCollapse = ({
  item,
  index,
  activeItem,
  setActiveItem,
  totalItems,
  form,
  isSubmitting,
  onRemove,
  onMoveUp,
  onMoveDown,
}: ItemsCollapseProps) => {
  const collapseItems = useMemo(() => {
    const nome = form.getFieldValue(['assinantes', item.name, 'nome'])
    return [
      {
        key: item.key,
        label: (
          <div className="font-bold flex items-center justify-start gap-2">
            <Icon name="holder" className="mt-1" />
            <span className="mt-1">
              Pessoa ({index + 1}) {nome ? ` - ${nome}` : ''}
            </span>
          </div>
        ),
        children: (
          <>
            <ColetaAssinaturasForm itemId={item.key} />

            <Form.Item valuePropName="checked" name={[item.key, 'certificado_necessario']} noStyle>
              <Radio
                className="mb-4 [&>span>span]:w-6 [&>span>span]:h-6"
                onClick={() => {
                  const value = form.getFieldValue(['assinantes', item.key, 'certificado_necessario'])
                  if (value) {
                    form.setFieldValue(['assinantes', item.key, 'certificado_necessario'], false)
                  } else {
                    form.setFieldValue(['assinantes', item.key, 'certificado_necessario'], true)
                  }
                }}
              >
                <p className="text-start text-base text-gray-700 pt-1">
                  Necessário criar certificado digital
                </p>
              </Radio>
            </Form.Item>
          </>
        ),
        extra: (
          <div className="flex gap-2">
            <Button
              className="text-secondary-300"
              type="text"
              shape="circle"
              size="small"
              icon={<Icon name="chevron-down-alt" />}
              onClick={ev => {
                ev.preventDefault()
                ev.stopPropagation()
                onMoveDown()
              }}
              title="Mover para baixo"
            />
            <Button
              className="text-secondary-300"
              type="text"
              shape="circle"
              size="small"
              icon={<Icon name="chevron-up-alt" />}
              onClick={ev => {
                ev.preventDefault()
                ev.stopPropagation()
                onMoveUp()
              }}
              title="Mover para cima"
            />

            <PopConfirmDelete
              title="Exclusão de assinador(a)"
              description="Tem certeza que deseja excluir este assinador(a)?"
              disabled={isSubmitting}
              onConfirm={onRemove}
            />
          </div>
        ),
      },
    ]
  }, [form, index, isSubmitting, totalItems, item])

  return (
    <Collapse
      accordion
      expandIconPosition="end"
      expandIcon={({ isActive }) => <Icon name={isActive ? 'chevron-up' : 'chevron-down'} className="text-base mt-2" />}
      activeKey={activeItem}
      onChange={() => setActiveItem(activeItem === item.key ? undefined : item.key)}
      items={collapseItems}
      className="mb-4"
    />
  )
}

export default ColetaAssinaturaCollapse
