import React, { useEffect, useRef } from 'react'

import { FieldError } from 'api/types'
import Alert from 'ui/alerts/Alert'

type ErrorData = {
  message: string
  description: string | FieldError[]
  RID?: string
}

export interface ScrollableErrorAlertProps {
  error: ErrorData 
}

const ScrollableErrorAlert = ({ error }: ScrollableErrorAlertProps) => {
  const formError = useRef<HTMLDivElement>(null)

  useEffect(() => {
    formError.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
  }, [formError])

  return (
    <div ref={formError}>
      <Alert
        type="error"
        message={error.message}
        description={<ErrorDescription error={error} />}
      />
    </div>
  )
}

function ErrorDescription({ error }: { error: ErrorData }) {
  return (
    <>
      {Array.isArray(error.description) ? (
        <ul className="my-2 py-3 border-t border-gray-400">
          {error.description.map(desc => (
            <li key={desc.field}>{desc.message}</li>
          ))}
        </ul>
      ) : (
        <span className="mt-2">{error.description}</span>
      )}

      {error.RID && <div className="text-xs">RID: {error.RID}</div>}
    </>
  )
}

export default ScrollableErrorAlert
