import React from 'react'
import { Button } from 'antd'

import { FileError, TempFile } from 'domain/app/types'
import Icon from 'ui/Icon'

export interface Props {
  error: FileError
  setError: (error: FileError | undefined) => void
  setFileList: React.Dispatch<React.SetStateAction<TempFile[]>>
}

export default function UploadDraggerError({ error, setError, setFileList }: Props) {
  const onTryAgain = () => {
    setFileList(prev => prev.filter(f => f.status !== 'error'))
    setError(undefined)
  }

  return (
    <>
      <Icon name="alert" className="text-5xl text-error mb-3" />

      {error.message.map((text: string, index: number) => (
        <p key={index} className="text-xs text-gray-600 mb-2">
          {text}
        </p>
      ))}

      <Button type="link" onClick={onTryAgain}>
        Tentar novamente
      </Button>
    </>
  )
}
