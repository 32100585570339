import React, { useState } from 'react'

export interface ToggleButtonProps {
  active?: boolean
  disabled?: boolean
  onToggleActive?: (active: boolean) => void
  style?: React.CSSProperties
  className?: string
  children?: React.ReactNode
}

export default function ToggleButton({
  active,
  disabled,
  onToggleActive,
  children,
  className,
  style,
}: ToggleButtonProps) {
  const [isActive, setActiveState] = useState(active)

  function handleToggleActive() {
    if (onToggleActive) {
      onToggleActive(!isActive)
      setActiveState(!isActive)
    }
  }

  return (
    <button
      type="button"
      className={`inline-flex justify-center items-center h-[30px] py-0 px-5 border border-gray-500 
        rounded-2xl text-xs whitespace-nowrap transition-colors duration-200 ease-in-out 
        ${isActive ? 'bg-gray-500 text-white hover:bg-gray-600' : 'bg-transparent text-gray-500 hover:bg-gray-500/10'} 
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'} 
        ${className}`}
      onClick={!disabled ? handleToggleActive : undefined}
      onMouseDown={e => e.preventDefault()}
      style={style}
    >
      {children}
    </button>
  )
}
