import { DocRegistrationProcessing } from 'domain/orderItem/documentRegistration'
import { OrderSuccessfulMessage } from 'domain/createOrder/shared'
import { useGetOrderItem } from '../../state/viewOrderItem'
import { ServiceType, SignatureCertificationCode } from 'domain/servicesCBRdoc/types'
import { Alert, Icon } from 'ui'
import { OrderItem } from 'domain/orderItem/types'
import React from 'react'
import { SignatureCollectionStatus } from 'domain/orderItem/signatureCertification'
import { CertificateProcessing } from 'domain/orderItem/signaturesCertificate'

export default function ViewOrderProcessing() {
  const orderItem = useGetOrderItem()

  return (
    <div className="pr-6 pl-8 flex flex-col flex-1 items-center bg-opacity-0">
      <div className="w-full bg-white flex flex-col items-center rounded-lg gap-8 mb-4 py-14">
        <StatusIcon />

        <div className="flex max-w-lg flex-col gap-4">
          <StatusTitle />
          <StatusDescription />
        </div>

        <StatusMessage
          activeChallenge={orderItem.activeChallenge}
          hidden={orderItem.service.code === SignatureCertificationCode.COLETA_ASSINATURA}
        />

        {orderItem.service.code === SignatureCertificationCode.COLETA_ASSINATURA && (
          <SignatureCollectionStatus orderItem={orderItem} />
        )}
      </div>
    </div>
  )
}

function StatusIcon() {
  const orderItem = useGetOrderItem()

  if (orderItem.service.type === ServiceType.SIGNATURES_CERTIFICATES) {
    return <Icon name="warning-file" />
  }

  return <Icon name="magnifying-file" />
}

function StatusTitle() {
  const orderItem = useGetOrderItem()

  if (orderItem.service.code === SignatureCertificationCode.CERTIFICADO_DIGITAL_CPF) {
    return <h1 className="font-bold text-xl text-center subpixel-antialiased">Certificação Pendente</h1>
  }

  return <h1 className="font-bold text-xl text-center subpixel-antialiased">Pedido em processamento</h1>
}

function StatusDescription() {
  const orderItem = useGetOrderItem()
  const { type, code } = orderItem.service

  if (type === ServiceType.SIGNATURES_CERTIFICATES) {
    if (code === SignatureCertificationCode.CERTIFICADO_DIGITAL_CPF) {
      return <CertificateProcessing />
    }

    return (
      <p className="text-center text-sm">
        Estamos aguardando a finalização da etapa de assinatura para envio do documento ao cartório de
        registro.
      </p>
    )
  }

  if (type === ServiceType.DOCUMENT_REGISTRATION) {
    return <DocRegistrationProcessing />
  }

  if (orderItem.service.type === ServiceType.SIGNATURES_CERTIFICATES) {
    return <CertificateProcessing />
  }

  return (
    <p className="text-center text-sm">
      Seu pedido já está em andamento.{' '}
      {orderItem.service.type === ServiceType.AI
        ? 'Em instantes, a Extração de Dados finalizará a leitura dos documentos.'
        : 'Em breve, teremos novidades sobre sua localização.'}
    </p>
  )
}

interface StatusMessageProps {
  activeChallenge: OrderItem['activeChallenge']
  hidden?: boolean
}

function StatusMessage({ activeChallenge, hidden = false }: StatusMessageProps) {
  if (hidden) return null
  if (!activeChallenge || !activeChallenge.active) return <OrderSuccessfulMessage />

  return (
    <Alert
      type="warning"
      icon={
        <div className="p-2 bg-yellow-500 rounded-full flex items-center justify-center ">
          <Icon name="warning" color="primary" strokeWidth={1.5} />
        </div>
      }
      className={'w-full max-w-md rounded'}
      message={activeChallenge.reason_title}
      description={activeChallenge.reason_description}
    />
  )
}
