import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaDadosCadastraisFields = Record<FieldName.CPF| FieldName.NOME_PF, FieldConfig>

class PesquisaDadosCadastrais extends Research {
  formFields: PesquisaDadosCadastraisFields = {
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
  }

  constructor() {
    super({
      description: 'Retorna dados cadastrais básicos do CPF.',
      id: 42,
      shortName: 'Pesquisa Dados',
    })
  }
}

const service = new PesquisaDadosCadastrais().initialize()

export default service
