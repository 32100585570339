import React from 'react'
import { Form } from 'antd'
import { Rule } from 'antd/lib/form'

import { MAX_TEXT_LENGTH } from 'domain/openAI/data'
import { TextAreaInput, TextAreaInputProps } from 'ui'

interface Props extends Omit<TextAreaInputProps, 'form' | 'name'> {
  name: (string | number)[]
}

function QuestionField({ name, label, ...props }: Props) {
  const value = Form.useWatch(name)

  return (
    <TextAreaInput
      label={label}
      name={name}
      maxLength={MAX_TEXT_LENGTH}
      rules={rules}
      value={value}
      {...props}
    />
  )
}

const rules: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
  {
    type: 'string',
    max: MAX_TEXT_LENGTH,
    message: `Máximo de ${MAX_TEXT_LENGTH} caracteres no total.`,
  },
]

export default React.memo(QuestionField)
