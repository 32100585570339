import React from 'react'

import { Modal } from 'ui'

import ShareOrderModalEmail from './ShareOrderModalEmail'
import ShareOrderModalWhats from './ShareOrderModalWhats'
import ShareOrderModalLink from './ShareOrderModalLink'

interface Props {
  open?: boolean
  onClose: () => void
  orderId: string | number
}

const ShareOrderModal = ({ open, onClose, orderId }: Props) => {
  return (
    <Modal destroyOnClose open={open} title="Compartilhar" onCancel={onClose}>
      <div className="-mt-6">
        <ShareOrderModalWhats orderItemId={orderId} />
        <ShareOrderModalEmail orderItemId={orderId} />
        <ShareOrderModalLink orderItemId={orderId} />
      </div>
    </Modal>
  )
}

export default ShareOrderModal
