import React from 'react'

import { AlertFullPage } from 'ui'

function OrderItemError({ message = 'Pedido inexistente' }) {
  return (
    <AlertFullPage
      title={message}
      description="Por favor, verifique o link acessado ou entre em contato com nossos canais de atendimento para mais detalhes."
    />
  )
}

export default OrderItemError
