import React from 'react'

import { Button } from 'ui'
import { onlyNumbers } from 'utils/formatters'

interface Props {
  value: number
  onValueChange: (val: number) => void
}

export default function ControlButton({ value, onValueChange }: Props) {
  return (
    <div className="flex items-center justify-between h-8 bg-white border border-primary">
      <Button
        type="link"
        size="small"
        icon="minus"
        disabled={value === 1}
        onClick={() => onValueChange(value > 0 ? value - 1 : 1)}
      />

      <input
        value={value}
        onChange={e => onValueChange(Math.max(Number(onlyNumbers(e.target.value)), 1))}
        className="text-center border-0 w-12 focus:outline-none active:outline-none"
      />

      <Button type="link" size="small" icon="plus" disabled={value >= 50} onClick={() => onValueChange(value + 1)}/>
    </div>
  )
}
