import React, { useEffect } from 'react'
import { Form, Radio } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useDispatch } from 'react-redux'

import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document/state'
import { Extras } from 'domain/createOrder/types'
import { SelectField } from 'ui'

import ExtraCheckbox from '../../shared/components/ExtraCheckbox'
import ShippingDelayedDays from '../../document/components/extras/ShippingDelayedDays'

interface Props {
  languages: any
}

function TraducaoJuramentada({ languages }: Props) {
  const dispatch = useDispatch()
  const { selectedExtras } = useCreateOrderState()
  const form = Form.useFormInstance()
  const apostilamento = Form.useWatch('apostilamento', form)
  const translation = Form.useWatch('traducao_juramentada', form)
  const onlyCertificateAllowed = apostilamento && !selectedExtras?.traducao_juramentada 

  const selectExtra = (value: Partial<Extras>) => {
    dispatch(
      createOrderActions.setSelectedExtras({
        ...selectedExtras,
        ...value
      })
    )
  }

  useEffect(() => {
    if (onlyCertificateAllowed) {
      selectExtra({
        tipo_apostilamento_com_traducao: 'certidao'
      })
      form.setFieldValue('tipo_apostilamento_com_traducao','certidao')
    }
  }, [onlyCertificateAllowed])
  
  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    if (!e.target.checked) {
      selectExtra({
        traducao_juramentada: undefined,
        idioma_traducao_juramentada: undefined,
      })
    } else {
      selectExtra({
        traducao_juramentada: true,
        idioma_traducao_juramentada: selectedExtras?.idioma_traducao_juramentada ?? 'italiano',
        tipo_apostilamento_com_traducao: apostilamento && 'certidao' 
      })
      form.setFieldValue('idioma_traducao_juramentada', selectedExtras?.idioma_traducao_juramentada ?? 'italiano')
    }
  }

  const onChangeLanguage = (e: any) => {
    selectExtra({ idioma_traducao_juramentada: e.target.value })
  }

  return (
    <>
      <Form.Item name="traducao_juramentada" valuePropName="checked" noStyle>
        <ExtraCheckbox
          label="Tradução juramentada"
          description="É a tradução de um documento oficial, para um idioma estrangeiro, sem que perca a validade oficial."
          checked={!!selectedExtras?.traducao_juramentada}
          onChange={onChangeCheckbox}
        >
          {languages.alemao.dias_adicionados_prazo && (
            <ShippingDelayedDays days={languages.alemao.dias_adicionados_prazo} />
          )}
        </ExtraCheckbox>
      </Form.Item>

      <Form.Item
        name="idioma_traducao_juramentada"
        label="Escolha o idioma da tradução juramentada"
        className="ml-9 my-2"
        rules={
          selectedExtras?.traducao_juramentada
            ? [{ required: true, message: 'Campo obrigatório.' }]
            : undefined
        }
      >
        <Radio.Group
          value={selectedExtras?.idioma_traducao_juramentada}
          onChange={onChangeLanguage}
          disabled={!translation}
        >
          <Radio value="italiano" className="block h-10">
            Italiano
          </Radio>
          <Radio value="espanhol" className="block h-10">
            Espanhol
          </Radio>
          <Radio value="frances" className="block h-10">
            Francês
          </Radio>
          <Radio value="ingles" className="block h-10">
            Inglês
          </Radio>
          <Radio value="alemao" className="block h-10">
            Alemão
          </Radio>
        </Radio.Group>
      </Form.Item>

      {apostilamento && (
        <SelectField
          name="tipo_apostilamento_com_traducao"
          label="Apostilar"
          placeholder="Selecione"
          className="ml-9 my-2"
          options={apostilamentoOptions}
          value={selectedExtras?.tipo_apostilamento_com_traducao}
          onChange={value => {
            selectExtra({ tipo_apostilamento_com_traducao: value })
          }}
          required
          disabled={onlyCertificateAllowed}
        />
      )}
    </>
  )
}

const apostilamentoOptions = [
  { value: 'certidao', label: 'Somente Certidão' },
  { value: 'traducao', label: 'Somente Tradução' },
  { value: 'certidaotraducao', label: 'Certidão + Tradução' },
]

export default TraducaoJuramentada
