import { useDispatch } from 'react-redux'

import { GridColumns, Section } from 'layouts'
import { createOrderActions, useCreateOrderNextStep, useCreateOrderState } from 'domain/createOrder/document'
import { useServiceById } from 'domain/servicesCBRdoc'
import { CardButton } from 'ui'

export default function SelectNotaryCategory() {
  const { selectedService } = useCreateOrderState()
  const dispatch = useDispatch()
  const [goToNextStep] = useCreateOrderNextStep('categoria')
  const currentService = useServiceById(selectedService.id)

  const selectCategory = (id: number) => {
    dispatch(createOrderActions.setSelectedCategory(id))
    goToNextStep()
  }

  if (!currentService) {
    return null
  }

  return (
    <Section heading="Selecione o tipo de cartório" fullWidth>
      <GridColumns>
        <CardButton
          title="Cartório de Registro Civil"
          icon="registro-civil"
          onClick={() => selectCategory(currentService.category1Id)}
          iconSize={48}
        />
        <CardButton
          title="Cartório de Notas"
          icon="notas"
          onClick={() => selectCategory(currentService.category2Id!)}
          iconSize={50}
        />
      </GridColumns>
    </Section>
  )
}
