import { Button, ButtonProps, IconName } from 'ui'

interface FilterButtonProps extends ButtonProps {
  icon?: IconName
  badge?: string | number
  text: string
  className?: string
}

export default function BadgeButton({ icon, text, onClick, badge, className = '' }: FilterButtonProps) {
  return (
    <Button
      icon={icon}
      type="primary"
      size="small"
      ghost
      onClick={onClick}
      className={`relative ${className}`}
    >
      {!!badge && (
        <div className="w-5 h-5 bg-red-500 rounded-full text-white text-xs font-semibold shadow-md flex items-center justify-center absolute bottom-0 right-0">
          {badge}
        </div>
      )}
      {text}
    </Button>
  )
}
