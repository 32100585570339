import React from 'react'

import { AlertFilterNoResults } from 'ui'

function AlertNoOrdersWithSelectedFilters() {
  return (
    <AlertFilterNoResults
      title="Nenhum pedido encontrado com este filtro"
      description="Por favor, escolha novos critérios de pesquisa."
    />
  )
}

export default AlertNoOrdersWithSelectedFilters
