import { KitFieldConfig } from 'domain/createOrder/kit/types'
import { KitItem } from 'domain/kits/types'
import { MaskedField, RadioGroupField, ResearchTimeField, SelectField, TextField, UploadField } from 'domain/servicesCBRdoc/fields'
import { TextAreaInput } from 'ui'

import { useGetServiceForm, useSetKitFiles, useUpdateServiceForm } from '../../state'

interface Props {
  config: KitFieldConfig
  kitItem: KitItem
}

export default function GenericServiceFields({ kitItem, config }: Props) {
  const { id: itemId } = kitItem
  const serviceFormData = useGetServiceForm(itemId)
  const { apiExtraInfo, ignoreOnKitsAutomaticForm, kind, label, mask, name, optional, showIf } = config

  if (ignoreOnKitsAutomaticForm) return null
  if (showIf && !showIf(serviceFormData)) return null

  if (kind === 'radio' && !apiExtraInfo) {
    return <RadioGroupField field={config} itemId={itemId} />
  }

  if (kind === 'researchTime') {
    return <ResearchTimeField itemId={itemId} />
  }

  if (kind === 'select' && !apiExtraInfo) {
    return <ServiceSelectField field={config} itemId={itemId} />
  }

  if (mask) {
    return <MaskedField field={config} itemId={itemId} />
  }

  if (kind === 'multiline') {
    return <TextAreaInput label={label} name={[itemId, name]} required={optional !== true} />
  }

  if (kind === 'upload') {
    return <ServiceUploadField field={config} itemId={itemId} />
  }

  if (kind === 'text') {
    return <TextField field={config} itemId={itemId} />
  }

  return null
}

function ServiceUploadField({ field, itemId }: { field: KitFieldConfig; itemId: number }) {
  const updateForm = useSetKitFiles()

  return (
    <UploadField
      field={field}
      onChange={updateForm}
      itemId={itemId}
      required={!field.optional}
    />
  )
}

function ServiceSelectField({ field, itemId }: { field: KitFieldConfig; itemId: number }) {
  const updateForm = useUpdateServiceForm(itemId)

  return (
    <SelectField
      field={field}
      onChange={value => updateForm({ [field.name]: value })}
      itemId={itemId}
      labelInValue
    />
  )
}
