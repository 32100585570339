import { Pagination } from 'ui'
import { useCurrentPage, useGetTotalResults, usePageSize } from '../state/pagination'

export default function GroupsPagination() {
  const [pageSize, setPageSize] = usePageSize()
  const totalCount = useGetTotalResults()
  const [currentPage, setCurrentPage] = useCurrentPage()

  return (
    <Pagination
      className="px-7 pb-7"
      totalCount={totalCount}
      currentPage={currentPage!}
      onChangePage={setCurrentPage}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
    />
  )
}
