import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoAmbientalMunicipalFields = Record<
  FieldName.URL_UF | FieldName.URL_CIDADE | FieldName.CPF | FieldName.CNPJ | FieldName.ENDERECO_IMOVEL,
  FieldConfig
>

class CertidaoAmbientalMunicipal extends Certificate {
  formFields: CertidaoAmbientalMunicipalFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    cpf: Fields.cpf,
    cnpj: Fields.cnpj,
    endereco: Fields.endereco
  }

  constructor() {
    super({
      id: 109,
      shortName: 'Certidão Ambiental Municipal',
      steps: ['dados'],
      priceByFields: [FieldName.URL_UF, FieldName.URL_CIDADE],
      availableFormatsFields: [FieldName.URL_UF, FieldName.URL_CIDADE],
    })
  }
}

const service = new CertidaoAmbientalMunicipal().initialize()

export default service
