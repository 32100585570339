import React from 'react'
import { Link } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { GoogleSignIn, LoginForm, MicrosoftSignIn } from 'domain/auth'
import { useResetCreateOrderAndCart } from 'domain/createOrder/document'
import { GuestLayout } from 'layouts'

export default function LoginPage() {
  useResetCreateOrderAndCart()

  return (
    <GuestLayout bgImage="bg-[url('assets/images/bg-login-lateral.png')]">
      <h1 className="text-3xl font-light text-gray-700 mb-5">Acessar conta</h1>

      <LoginForm />

      <p className="mt-10 text-center text-sm">
        <b>Ainda não tem uma conta?</b>{' '}
        <Link className="underline text-primary" to={RoutePaths.REGISTER_CNPJ}>
          Crie agora mesmo
        </Link>
      </p>

      <div className="flex flex-row flex-wrap gap-3 mt-10">
        <GoogleSignIn />
        <MicrosoftSignIn />
      </div>
    </GuestLayout>
  )
}
