import { useReducer } from 'react'

import { useHasPermission } from 'domain/auth'
import { Group } from 'domain/groups/types'
import { Button, Modal } from 'ui'

import CreateGroupsForm from './CreateGroupsForm'

interface Props {
  onFinish?: (newGroups: Group[]) => void
}

export default function CreateGroups({ onFinish }: Props) {
  const canCreateGroups = useHasPermission('canManageUsersAndCompanyData')
  const [modalOpen, toggleModalOpen] = useReducer(prev => !prev, false)

  const handleFinish = (newGroups: Group[]) => {
    onFinish && onFinish(newGroups)
    toggleModalOpen()
  }

  if (!canCreateGroups) {
    return null
  }

  return (
    <>
      <Modal open={modalOpen} title="Adicionar novos grupos" onCancel={toggleModalOpen} destroyOnClose>
        <CreateGroupsForm onFinish={handleFinish} />
      </Modal>

      <Button type="link" className="px-0" icon="plus-circle" onClick={toggleModalOpen}>
        Adicionar novo grupo
      </Button>
    </>
  )
}
