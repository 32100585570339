import React from 'react'

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  className?: string
  text: string
  size: '3'
  description?: string
}

export default function Heading({ className, text, size, description, ...props }: Props) {
  return (
    <>
      <div className={`${Styles[size]} ${className}`} {...props}>
        {text}
      </div>
      {description && (
        <p className="font-normal text-xs text-secondary-500 mb-4">
          {description}
        </p>
      )}
    </>
  )
}

const Styles = {
  3: 'text-base mb-2 uppercase text-secondary'
}
