import { ResearchFlow } from 'domain/createOrder/researches'
import { CNPJInput } from 'ui'

export default function CreateOrderPesquisaIndicadoresAtividade() {
  return (
    <ResearchFlow
      renderFormItem={(itemId: number) => <PesquisaIndicadoresAtividadeForm itemId={itemId} />}
    />
  )
}

function PesquisaIndicadoresAtividadeForm({ itemId }: { itemId: number }) {
  return (
    <CNPJInput itemId={itemId} />
  )
}

