import { useMemo } from 'react'

import { OCRDocument } from 'domain/myFiles/types'
import { AIModel, EnrichmentAvailability } from 'domain/openAI/types'
import { useServiceAIDefaultModelAndQuestionsQuery } from 'domain/openAI/models'

import useAIEnrichQuery from '../services/useAIEnrichQuery'
import OpenAIEnrichmentButton from './OpenAIEnrichmentButton'
import OpenAILayout from './OpenAILayout'
import OpenAIQuestions from './OpenAIQuestions'
import { useCheckPermission } from 'domain/auth'
import { Icon } from 'ui'

type Props = {
  aiModel?: AIModel
  ocr?: OCRDocument
  fileUrl: string
  serviceId: number
}

export default function OpenAIEnrichment({ aiModel, ocr, serviceId }: Props) {
  const checkPermission = useCheckPermission()
  const canExtractDataUsingAi = checkPermission('canExtractDataUsingAi')
  const { data: defaultModel, isFetching: isLoadingDefaultData } = useServiceAIDefaultModelAndQuestionsQuery(
    serviceId,
    {
      enabled: !aiModel?.hasAnswers,
    }
  )

  const modelId = aiModel?.id || defaultModel?.id
  const {
    data: answers,
    isFetching,
    refetch: enrich,
  } = useAIEnrichQuery(ocr?.id, modelId, { enabled: false })

  const questions = useMemo(() => {
    if (answers && answers.length > 0) {
      return answers
    }

    if (aiModel?.hasAnswers || aiModel?.questions.map((q) => q.answer).some(Boolean)) {
      return aiModel.questions
    }

    return defaultModel?.questions
  }, [defaultModel?.questions, answers, aiModel])

  const alreadyHasAnswers = 
    aiModel?.questions.map((q) => q.answer).some(Boolean) || answers !== undefined && answers.length > 0 
  const dataExtractionUnavailable = 
    !canExtractDataUsingAi ||
    ocr?.aiEnrichmentAvailability !== EnrichmentAvailability.AVAILABLE

  return (
    <OpenAILayout
      ocr={ocr}
      sidebar={
        <div className={!alreadyHasAnswers ? 'py-20' : 'pt-2'}>
          <div className="md:w-2/3 lg:w-7/12 mx-auto flex flex-col items-center justify-center">
            <div className={`flex flex-col items-center justify-center ${alreadyHasAnswers && 'hidden'}`}>
              <Icon name="robot" className='size-16' />

              <div className="text-secondary-500 my-4 text-base">
                {dataExtractionUnavailable ? (
                  <p className="text-center">
                    Para habilitar o módulo de inteligência artificial e receber planilhas e relatórios
                    de todos os seus documentos, entre em contato com nosso time pelo telefone: 4020-1716
                  </p>
                ) : (
                  <p className="text-center">Faça um teste gratuito clicando no botão abaixo.</p>
                )}
              </div>
            </div>

            <div className="px-3 py-2 rounded w-full justify-between flex flex-col">
              <div className={`flex justify-center mb-2 ${(alreadyHasAnswers || dataExtractionUnavailable) && 'hidden'}`}>
                <OpenAIEnrichmentButton
                  handleEnrichment={enrich}
                  isFetching={isFetching || isLoadingDefaultData}
                  disabled={!canExtractDataUsingAi || alreadyHasAnswers || !defaultModel?.id}
                  availability={ocr?.aiEnrichmentAvailability}
                />
              </div>
            </div>
          </div>
          {questions && <OpenAIQuestions questions={questions} />}
        </div>
      }
    />
  )
}
