import React from 'react'

import { KitItem as KitItemType } from 'domain/kits/types'

import DefaultDataTags from './DefaultDataTags'

interface Props {
  item: KitItemType
  serviceName: string
  className?: string
}

function KitItem({ item, serviceName, className }: Props) {
  const handleClick = (e: any) => {
    e.preventDefault()

    const element = document.getElementById(`service_${item.service.id}_${item.service.categoryId}`)

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <a href="#" onClick={handleClick} className={className}>
      <span className="mr-3 text-sm font-normal text-secondary-500 display-block mt-3">{serviceName}</span>

      {!!item.defaultData && (
        <DefaultDataTags defaultData={item.defaultData} serviceCode={item.service.code!} />
      )}
    </a>
  )
}

export default KitItem
