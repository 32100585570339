import React from 'react'
import { Tabs, TabsProps } from 'antd'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

import {
  useGetCurrentPage,
  useGetItemsCurrentPage,
  useGetItemsTotalResults,
  useGetTotalResults,
} from 'domain/orders/state/pagination'
import { useCurrentTab } from 'domain/orders/state/tabs'
import { OrdersTab } from 'domain/orders/types'

export default function OrdersTabs() {
  const history = useHistory<any>()
  const location = history.location
  const [tab, setTab] = useCurrentTab()
  const itemsTotalResults = useGetItemsTotalResults()
  const itemsCurrentPage = useGetItemsCurrentPage()
  const totalResults = useGetTotalResults()
  const currentPage = useGetCurrentPage()

  const tabItems: TabsProps['items'] = [
    {
      key: 'orders',
      label: (
        <>
          Pedidos
          <span className="text-primary font-semibold ml-1.5">({totalResults})</span>
        </>
      ),
    },
    {
      key: 'items',
      label: (
        <>
          Itens
          <span className="text-primary font-semibold ml-1.5">({itemsTotalResults})</span>
        </>
      ),
    },
  ]

  const handleChange = (tab: string) => {
    const currentParams = queryString.parse(location.search) ?? {}

    const updatedParams = {
      ...currentParams,
      tab,
      p: tab === 'orders' ? currentPage : itemsCurrentPage,
    }

    const urlParams = Object.entries(updatedParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    history.replace(`${location.pathname}?${urlParams}`)
    setTab(tab as OrdersTab)
  }

  return <Tabs defaultActiveKey={tab} items={tabItems} activeKey={tab} onChange={handleChange} />
}
