import { useRef, useState } from 'react'

import XLSX from 'utils/xlsx'

type ReadFileFn = (params: {
  file: Blob
  initialRow?: number
  sheetRows?: number
  onSuccess: (rows: any[]) => Promise<void>
}) => Promise<void>

export default function useReadFile(): [ReadFileFn, number] {
  const [percent, setPercent] = useState(0)
  const done = useRef(0)

  const readFile: ReadFileFn = async ({ file, initialRow = 0, sheetRows = 0, onSuccess }) => {
    const reader = new FileReader()

    reader.addEventListener('load', async (event: any) => {
      try {
        const workbook = XLSX.read(event.target.result, {
          sheetRows,
          type: 'binary',
          cellFormula: false,
          cellHTML: false,
          cellText: false,
          raw: true
        })

        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const jsonRows: any[] = []
        const jsonObject = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        }) as any[]

        const validRows = jsonObject.filter(colsOfRow => colsOfRow.length > 0)
        const totalPercent = validRows.length

        Object.values(validRows).forEach((row: any, index) => {
          if (index >= initialRow) {
            const rowData: any = { rowId: index }
            const rowValues = Object.values(row)

            rowValues.forEach((cellData, index) => {
              rowData[index] = cellData
            })

            done.current++
            setPercent(Math.max(Math.ceil((done.current * 100) / totalPercent), 100))
            jsonRows.push(rowData)
          }
        })

        await onSuccess(jsonRows)
      } catch (error: any) {
        console.log('error', error)
      }
    })

    reader.readAsArrayBuffer(file)
  }

  return [readFile, percent]
}
