import React, { useReducer } from 'react'

import { useCurrentUser } from 'domain/auth'
import { OrderList } from 'domain/orders/types'
import { Icon } from 'ui'

import OrdersActionsEditName from './OrdersActionsEditName'

interface Props {
  order: Pick<OrderList, 'id' | 'name' | 'user'>
}

function EditOrderNameButton({ order }: Props) {
  const user = useCurrentUser()

  if (user.id !== order.user.id) return null

  return <EditableOrderName order={order} />
}

function EditableOrderName({ order }: Props) {
  const [isOpen, toggleIsOpen] = useReducer(prev => !prev, false)

  return (
    <>
      <span
        className="cursor-pointer invisible text-secondary-500 hover:text-primary text-md group-hover:visible group-hover:text-primary"
        onClick={toggleIsOpen}
      >
        <Icon name="edit" size={20} />
      </span>

      <OrdersActionsEditName orderId={order.id} orderName={order.name} open={isOpen} onClose={toggleIsOpen} />
    </>
  )
}

export default EditOrderNameButton
