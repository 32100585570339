import { CertificateFlow } from 'domain/createOrder/certificates'
import { useLoadExtraInfos } from 'domain/createOrder/document'
import CertidaoSPUForm from './CreateOrderCertidaoSPUForm'
import TypeField from './CreateOrderCertidaoSPUType'

export default function CreateOrderCertidaoSPU() {
  const query = useLoadExtraInfos()

  return (
    <CertificateFlow
      renderFormItem={(itemId: number) => (
        <CertidaoSPUForm itemId={itemId} typeField={<TypeField query={query} itemId={itemId} />} />
      )}
    />
  )
}
