import React from 'react'

import { ViewOrderLayout, useGetOrderItem } from 'domain/orderItem/shared'
import ViewOrderResearchStatusOrPDF from './ViewOrderResearchStatusOrPDF'

interface Props {
  sidebarActions?: React.ReactNode
  children?: React.ReactNode
}

function ViewResearch({ sidebarActions, children }: Props) {
  const orderItem = useGetOrderItem()
  const noResultDetails = !orderItem.researchResults || orderItem.researchResults.length === 0

  return (
    <ViewOrderLayout icon="search" downloadButton={sidebarActions}>
      <div className="ml-8 mr-4 bg-white rounded-lg p-4">
        {noResultDetails ? <ViewOrderResearchStatusOrPDF orderItem={orderItem} /> : children}
      </div>
    </ViewOrderLayout>
  )
}

export default ViewResearch
