import React from 'react'
import { AxiosError } from 'axios'

import EmailInput, { EmailInputProps } from 'ui/dataEntry/EmailInput'
import { getEmailAvailable } from '../api'

export default function UserEmail(props: EmailInputProps) {
  return (
    <EmailInput
      aditionalRules={validationRules}
      validationTrigger={['onBlur', 'onFocus']}
      {...props} 
    />
  )
}

const validationRules = [
  {
    validator: async (_: object, value: string) => {
      if (!value) {
        return Promise.reject('Informe um e-mail válido.')
      }

      try {
        const email  = value.trim()
        const { data, status } = await getEmailAvailable(email)

        if (status === 200 && !data.available) {
          return Promise.reject('Email já cadastrado')
        }

        if (status === 422) {
          return Promise.reject(data.message)
        }

        return Promise.resolve()
      } catch (error) {
        return Promise.reject((error as AxiosError<{ message: string }>).response?.data.message || 'Informe um e-mail válido.')
      }
    },
  },
]
