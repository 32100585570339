import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

import { asArray } from 'utils/transformData'
import { OrdersTab } from '../types'
import {
  useSetAutomaticGenerated,
  useSetDatesRange,
  useSetGroupsIds,
  useSetIsAI,
  useSetIsExpired,
  useSetIsOCRSearch,
  useSetOnlyFromRecurrence,
  useSetOwnersIds,
  useSetRecurrenceId,
  useSetResults,
  useSetSearchTerm,
  useSetServicesIds,
  useSetStatuses,
} from './filters'
import { useCurrentPage, useSetItemsCurrentPage } from './pagination'
import { useSetCurrentTab } from './tabs'

export default function useOrdersInitialValuesFromURL() {
  const history = useHistory()
  const location = history.location
  const setCurrentTab = useSetCurrentTab()
  const setItemsCurrentPage = useSetItemsCurrentPage()
  const [currentPage, setCurrentPage] = useCurrentPage()
  const setAutomaticGenerated = useSetAutomaticGenerated()
  const setDates = useSetDatesRange()
  const setIsAI = useSetIsAI()
  const setIsExpired = useSetIsExpired()
  const setIsOCRSearch = useSetIsOCRSearch()
  const setGroupsIds = useSetGroupsIds()
  const setOnlyFromRecurrence = useSetOnlyFromRecurrence()
  const setOwnersIds = useSetOwnersIds()
  const setRecurrenceId = useSetRecurrenceId()
  const setResults = useSetResults()
  const setSearchTerm = useSetSearchTerm()
  const setServicesIds = useSetServicesIds()
  const setStatuses = useSetStatuses()

  useEffect(() => {
    let page = 1
    let selectedTab: OrdersTab | undefined = undefined

    if (location.search) {
      const {
        ai,
        automatic,
        dates,
        expired,
        from_recurrence,
        groups,
        ocr,
        owners,
        p,
        q,
        services,
        status,
        recurrence,
        results,
        tab,
      } = queryString.parse(location.search)

      if (ai === 'true') setIsAI(true)
      if (automatic === 'true') setAutomaticGenerated(true)
      if (dates) setDates(dates)
      if (expired === 'true') setIsExpired(true)
      if (ocr === 'true') setIsOCRSearch(true)
      if (groups) setGroupsIds(asArray(groups))
      if (from_recurrence === 'true') setOnlyFromRecurrence(true)
      if (owners) setOwnersIds(asArray(owners))
      if (p) page = Number(p)
      if (q) setSearchTerm(q)
      if (recurrence) setRecurrenceId(recurrence)
      if (results) setResults(asArray(results))
      if (services) setServicesIds(asArray(services))
      if (status) setStatuses(asArray(status))

      if (tab) {
        selectedTab = tab as OrdersTab
        setCurrentTab(tab as OrdersTab)
      }
    }

    if (currentPage === undefined) {
      setCurrentPage(selectedTab === 'items' ? 1 : page)
      setItemsCurrentPage(selectedTab === 'items' ? page : 1)
    }
  }, [])
}
