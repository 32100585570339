import {
  AICode,
  CertificateCode,
  DiligenceCode,
  DocumentRegistrationCode,
  ResearchCode,
  ServiceCode,
  SignatureCertificationCode,
} from '../types'

import artificialIntelligence from './ai/config'
import certidaoAmbiental from './certificates/certidaoAmbiental/certidaoAmbientalConfig'
import certidaoAmbientalMunicipal from './certificates/certidaoAmbientalMunicipal/certidaoAmbientalMunicipalConfig'
import certidaoAntecedentesCriminais from './certificates/certidaoAntecedentesCriminais/certidaoAntecedentesCriminaisConfig'
import certidaoAtasNotariais from './certificates/certidaoAtasNotariais/certidaoAtasNotariaisConfig'
import certidaoBaixaProtesto from './certificates/certidaoBaixaProtesto/certidaoBaixaProtestoConfig'
import certidaoDadosCadastraisImovel from './certificates/certidaoDadosCadastraisImovel/certidaoDadosCadastraisImovelConfig'
import certidaoCafir from './certificates/certidaoCafir/certidaoCafirConfig'
import certidaoCasamento from './certificates/certidaoCasamento/certidaoCasamentoConfig'
import certidaoCapaIPTU from './certificates/certidaoCapaIPTU/certidaoCapaIPTUConfig'
import certidaoCCIR from './certificates/certidaoCCIR/certidaoCCIRConfig'
import certidaoCEAT from './certificates/certidaoCEAT/certidaoCEATConfig'
import certidaoCNDT from './certificates/certidaoCNDT/certidaoCNDTConfig'
import certidaoCNDTNIDA from './certificates/certidaoCNDTNIDA/certidaoCNDTNIDAConfig'
import certidaoCumprimentoPcds from './certificates/certidaoCumprimentoPCDs/certidaoCumprimentoPCDsConfig'
import certidaoDebitosMT from './certificates/certidaoDebitosMT/certidaoDebitosMTConfig'
import certidaoDebitosEstaduais from './certificates/certidaoDebitosEstaduais/certidaoDebitosEstaduaisConfig'
import certidaoDistribuicao from './certificates/certidaoTRFDistribuicao/certidaoTRFDistribuicaoConfig'
import certidaoDebitosMunicipais from './certificates/certidaoDebitosMunicipais/certidaoDebitosMunicipaisConfig'
import certidaoEscritura from './certificates/certidaoEscritura/certidaoEscrituraConfig'
import certidaoEmpresaCartoriosPJ from './certificates/certidaoEmpresaCartoriosPJ/certidaoEmpresaCartoriosPJConfig'
import certidaoEntidadesSupervisionadas from './certificates/certidaoEntidadesSupervisionadas/certidaoEntidadesSupervisionadasConfig'
import certidaoExtratoDebitosMuniciapis from './certificates/certidaoExtratoDebitosMunicipais/certidaoExtratoDebitosMunicipaisConfig'
import certidaoIbamaDebitos from './certificates/certidaoIbamaDebitos/certidaoIbamaDebitosConfig'
import certidaoIbamaEmbargos from './certificates/certidaoIbamaEmbargos/certidaoIbamaEmbargosConfig'
import certidaoImovel from './certificates/certidaoImovel/certidaoImovelConfig'
import certidaoInterdicao from './certificates/certidaoInterdicao/certidaoInterdicaoConfig'
import certidaoINSS from './certificates/certidaoINSS/certidaoINSSConfig'
import certidaoIPTU from './certificates/certidaoIPTU/certidaoIPTUConfig'
import certidaoItr from './certificates/certidaoITR/certidaoITRConfig'
import certidaoImprobabilidadeAdministrativa from './certificates/certidaoImprobabilidadeAdministrativa/certidaoImprobabilidadeAdministrativaConfig'
import certidaoJuntaComercial from './certificates/certidaoJuntaComercial/certidaoJuntaComercialConfig'
import certidaoJusticaEstadual from './certificates/certidaoJusticaEstadual/certidaoJusticaEstadualConfig'
import certidaoMPECivil from './certificates/certidaoMPECivil/certidaoMPECivilConfig'
import certidaoMTTrabalhistas from './certificates/certidaoMTTrabalhistas/certidaoMTTrabalhistasConfig'
import certidaoMPECriminais from './certificates/certidaoMPECriminal/certidaoMPECriminalConfig'
import certidaoNascimento from './certificates/certidaoNascimento/certidaoNascimentoConfig'
import certidaoNegativaCorrecional from './certificates/certidaoNegativaCorrecional/certidaoNegativaCorrecionalConfig'
import certidaoNegativaCorrecionalCGU from './certificates/certidaoNegativaCorrecionalCGU/certidaoNegativaCorrecionalCGUConfig'
import certidaoNegativaContasTCU from './certificates/certidaoNegativaContasTCU/certidaoNegativaContasTCUConfig'
import certidaoNegativaDebitosTributariosPGE from './certificates/certidaoNegativaDebitosTributariosPGE/certidaoNegativaDebitosTributariosPGEConfig'
import certidaoNegativaFeitos from './certificates/certidaoNegativaFeitos/certidaoNegativaFeitosConfig'
import certidaoNegativaFGTS from './certificates/certidaoNegativaFGTS/certidaoNegativaFGTSConfig'
import certidaoNegativaMPF from './certificates/certidaoNegativaMPF/certidaoNegativaMPFConfig'
import certidaoNegativaPropriedade from './certificates/certidaoNegativaPropriedade/certidaoNegativaPropriedadeConfig'
import certidaoNegativaSTJ from './certificates/certidaoNegativaSTJ/certidaoNegativaSTJConfig'
import certidaoNegativaTCU from './certificates/certidaoNegativaTCU/certidaoNegativaTCUConfig'
import certidaoObito from './certificates/certidaoObito/certidaoObitoConfig'
import certidaoPenhorSafra from './certificates/certidaoPenhorSafra/certidaoPenhorSafraConfig'
import certidaoProcuracao from './certificates/certidaoProcuracao/certidaoProcuracaoConfig'
import certidaoProtesto from './certificates/certidaoProtesto/certidaoProtestoConfig'
import certidaoPronafAptidao from './certificates/certidaoPronafAptidao/certidaoPronafAptidaoConfig'
import certidaoQuitacaoEleitoral from './certificates/certidaoQuitacaoEleitoral/certidaoQuitacaoEleitoralConfig'
import certidaoSTFDistribuidor from './certificates/certidaoSTFDistribuidor/certidaoSTFDistribuidorConfig'
import certidaoSTMCriminaisConfig from './certificates/certidaoSTMCriminais/certidaoSTMAcoesCriminaisConfig'
import certidaoPreviaMatricula from './certificates/certidaoPreviaMatricula/certidaoPreviaMatriculaConfig'
import certidaoPropriedadeAeronave from './certificates/certidaoPropriedadeAeronave/certidaoPropriedadeAeronaveConfig'
import certidaoValorVenal from './certificates/certidaoValorVenal/certidaoValorVenalConfig'
import certidaoRAIS from './certificates/certidaoRAIS/certidaoRAISConfig'
import certidaoSPU from './certificates/certidaoSPU/certidaoSPUConfig'
import certidaoConsultaSituacaoPJ from './certificates/certidaoConsultaSituacaoPJ/certidaoConsultaSituacaoPJConfig'
import certidaoTribunalContas from './certificates/certidaoTribunalContas/certidaoTribunalContasConfig'
import diligenciaAcompanhamentosConfig from './dueDiligences/diligenciaAcompanhamentos/diligenciaAcompanhamentosConfig'
import diligenciaAtasNotariais from './dueDiligences/diligenciaAtasNotariais/diligenciaAtasNotariaisConfig'
import diligenciaConsultasConfig from './dueDiligences/diligenciaConsultas/diligenciaConsultasConfig'
import diligenciaCopiasConfig from './dueDiligences/diligenciaCopias/diligenciaCopiasConfig'
import diligenciaDespachantesConfig from './dueDiligences/diligenciaDespachantes/diligenciaDespachantesConfig'
import diligenciaGuiasConfig from './dueDiligences/diligenciaGuias/diligenciaGuiasConfig'
import diligenciaOutrasConfig from './dueDiligences/diligenciaOutras/diligenciaOutrasConfig'
import diligenciaPreviaCustasRegistrosConfig from './dueDiligences/diligenciaPreviaCustasRegistros/diligenciaPreviaCustasRegistrosConfig'
import diligenciaProtocolosConfig from './dueDiligences/diligenciaProtocolos/diligenciaProtocolosConfig'
import diligenciaRegistroImoveisConfig from './dueDiligences/diligenciaRegistroImoveis/diligenciaRegistroImoveisConfig'
import diligenciaRequerimentosConfig from './dueDiligences/diligenciaRequerimentos/diligenciaRequerimentosConfig'
import diligenciaRetiradaDocsConfig from './dueDiligences/diligenciaRetiradaDocs/diligenciaRetiradaDocsConfig'
import docRegistrationRGIConfig from './documentsRegistration/rgi/rgiConfig'
import docRegistrationRTDConfig from './documentsRegistration/rtd/rtdConfig'
import docRegistrationAtaNotarialConfig from './documentsRegistration/ataNotarial/ataNotarialConfig'
import pesquisaBens from './researches/pesquisaBens/pesquisaBensConfig'
import pesquisaCar from './researches/pesquisaCar/pesquisaCarConfig'
import pesquisaDadosCadastrais from './researches/pesquisaDadosCadastrais/pesquisaDadosCadastraisConfig'
import pesquisaDividaAtivaPGE from './researches/pesquisaDividaAtivaPGE/pesquisaDividaAtivaPGEConfig'
import pesquisaDivorcio from './researches/pesquisaDivorcio/pesquisaDivorcioConfig'
import pesquisaEmpresaCartoriosPJ from './researches/pesquisaEmpresaCartoriosPJ/pesquisaEmpresaCartoriosPJConfig'
import pesquisaEscritura from './researches/pesquisaEscritura/pesquisaEscrituraConfig'
import pesquisaImovelRural from './researches/pesquisaImovelRural/pesquisaImovelRuralConfig'
import pesquisaInventario from './researches/pesquisaInventario/pesquisaInventarioConfig'
import pesquisaIndicadoresAtividade from './researches/pesquisaIndicadoresAtividade/pesquisaIndicadoresAtividadeConfig'
import pesquisaJuntaComercial from './researches/pesquisaJuntaComercial/pesquisaJuntaComercialConfig'
import pesquisaObitoConfig from './researches/pesquisaObito/pesquisaObitoConfig'
import pesquisaParticipacaoSocietaria from './researches/pesquisaParticipacaoSocietaria/pesquisaParticipacaoSocietariaConfig'
import pesquisaProcessosJudiciais from './researches/pesquisaProcessosJudiciais/pesquisaProcessosJudiciaisConfig'
import pesquisaProcuracao from './researches/pesquisaProcuracao/pesquisaProcuracaoConfig'
import pesquisaPropriedadeAeronave from './researches/pesquisaPropriedadeAeronave/pesquisaPropriedadeAeronaveConfig'
import pesquisaProtesto from './researches/pesquisaProtesto/pesquisaProtestoConfig'
import pesquisaReceitaFederal from './researches/pesquisaReceitaFederal/pesquisaReceitaFederalConfig'
import pesquisaSintegra from './researches/pesquisaSintegra/pesquisaSintegraConfig'
import pesquisaVeiculos from './researches/pesquisaVeicular/pesquisaVeicularConfig'
import pesquisaPropriedadeVeiculos from './researches/pesquisaPropriedadeVeiculos/pesquisaPropriedadeVeiculosConfig'
import pesquisaCompliance from './researches/pesquisaCompliance/pesquisaComplianceConfig'
import coletaAssinaturas from './signatureCertification/coletaAssinaturas/coletaAssinaturasConfig'
import certificadoDigitalCpf from './signatureCertification/certificadoDigitalCpf/certificadoDigitalCpfConfig'
import CBRdocDocument from '../config/classes/CBRdocDocument'
import CBRdocService from '../config/classes/CBRdocService'
import { FieldConfig } from '../fields/types'

const Services = {
  [AICode.AI_DOCUMENT_ANALYSIS]: artificialIntelligence,
  [CertificateCode.CERTIDAO_AMBIENTAL]: certidaoAmbiental,
  [CertificateCode.CERTIDAO_AMBIENTAL_MUNICIPAL]: certidaoAmbientalMunicipal,
  [CertificateCode.CERTIDAO_ANTECEDENTES_CRIMINAIS]: certidaoAntecedentesCriminais,
  [CertificateCode.CERTIDAO_ATAS_NOTARIAIS]: certidaoAtasNotariais,
  [CertificateCode.CERTIDAO_BAIXA_PROTESTO]: certidaoBaixaProtesto,
  [CertificateCode.CERTIDAO_DADOS_CADASTRAIS_IMOVEL]: certidaoDadosCadastraisImovel,
  [CertificateCode.CERTIDAO_CAFIR]: certidaoCafir,
  [CertificateCode.CERTIDAO_CASAMENTO]: certidaoCasamento,
  [CertificateCode.CERTIDAO_CAPA_IPTU]: certidaoCapaIPTU,
  [CertificateCode.CERTIDAO_CCIR]: certidaoCCIR,
  [CertificateCode.CERTIDAO_CEAT]: certidaoCEAT,
  [CertificateCode.CERTIDAO_CNDT]: certidaoCNDT,
  [CertificateCode.CERTIDAO_CNDTNIDA]: certidaoCNDTNIDA,
  [CertificateCode.CERTIDAO_CUMPRIMENTO_PCDS]: certidaoCumprimentoPcds,
  [CertificateCode.CERTIDAO_DEBITOS_ESTADUAIS]: certidaoDebitosEstaduais,
  [CertificateCode.CERTIDAO_DEBITOS_MT]: certidaoDebitosMT,
  [CertificateCode.CERTIDAO_DEBITOS_MUNICIPAIS]: certidaoDebitosMunicipais,
  [CertificateCode.CERTIDAO_NEGATIVA_DEBITOS_TRIBUTARIOS_PGE]: certidaoNegativaDebitosTributariosPGE,
  [CertificateCode.CERTIDAO_TRF_DISTRIBUICAO]: certidaoDistribuicao,
  [CertificateCode.CERTIDAO_DISTRIBUIDOR]: certidaoSTFDistribuidor,
  [CertificateCode.CERTIDAO_EMPRESA_CARTORIO_PJ]: certidaoEmpresaCartoriosPJ,
  [CertificateCode.CERTIDAO_ENTIDADES_SUPERFISIONADAS]: certidaoEntidadesSupervisionadas,
  [CertificateCode.CERTIDAO_EXTRATO_DEBITOS_MUNICIPAIS]: certidaoExtratoDebitosMuniciapis,
  [CertificateCode.CERTIDAO_ESCRITURA]: certidaoEscritura,
  [CertificateCode.CERTIDAO_IBAMA_DEBITOS]: certidaoIbamaDebitos,
  [CertificateCode.CERTIDAO_IBAMA_EMBARGOS]: certidaoIbamaEmbargos,
  [CertificateCode.CERTIDAO_IMOVEL]: certidaoImovel,
  [CertificateCode.CERTIDAO_INTERDICAO]: certidaoInterdicao,
  [CertificateCode.CERTIDAO_INSS]: certidaoINSS,
  [CertificateCode.CERTIDAO_IPTU]: certidaoIPTU,
  [CertificateCode.CERTIDAO_ITR]: certidaoItr,
  [CertificateCode.CERTIDAO_IMPROBILIDADE_ADMINISTRATIVA]: certidaoImprobabilidadeAdministrativa,
  [CertificateCode.CERTIDAO_JUNTA_COMERCIAL]: certidaoJuntaComercial,
  [CertificateCode.CERTIDAO_JUSTICA_ESTADUAL]: certidaoJusticaEstadual,
  [CertificateCode.CERTIDAO_MPE_CIVIL]: certidaoMPECivil,
  [CertificateCode.CERTIDAO_MPE_CRIMINAL]: certidaoMPECriminais,
  [CertificateCode.CERTIDAO_MT_TRABALHISTAS]: certidaoMTTrabalhistas,
  [CertificateCode.CERTIDAO_NASCIMENTO]: certidaoNascimento,
  [CertificateCode.CERTIDAO_NEGATIVA_CONTAS_TCU]: certidaoNegativaContasTCU,
  [CertificateCode.CERTIDAO_NEGATIVA_CORRECIONAL]: certidaoNegativaCorrecional,
  [CertificateCode.CERTIDAO_NEGATIVA_CORRECIONAL_CGU]: certidaoNegativaCorrecionalCGU,
  [CertificateCode.CERTIDAO_NEGATIVA_FEITOS]: certidaoNegativaFeitos,
  [CertificateCode.CERTIDAO_NEGATIVA_FGTS]: certidaoNegativaFGTS,
  [CertificateCode.CERTIDAO_NEGATIVA_MPF]: certidaoNegativaMPF,
  [CertificateCode.CERTIDAO_NEGATIVA_PROPRIEDADE]: certidaoNegativaPropriedade,
  [CertificateCode.CERTIDAO_NEGATIVA_STJ]: certidaoNegativaSTJ,
  [CertificateCode.CERTIDAO_NEGATIVA_TCU]: certidaoNegativaTCU,
  [CertificateCode.CERTIDAO_OBITO]: certidaoObito,
  [CertificateCode.CERTIDAO_PENHOR_SAFRA]: certidaoPenhorSafra,
  [CertificateCode.CERTIDAO_PREVIA_MATRICULA]: certidaoPreviaMatricula,
  [CertificateCode.CERTIDAO_PROCURACAO]: certidaoProcuracao,
  [CertificateCode.CERTIDAO_PROTESTO]: certidaoProtesto,
  [CertificateCode.CERTIDAO_PROPRIEDADE_AERONAVE]: certidaoPropriedadeAeronave,
  [CertificateCode.CERTIDAO_PRONAF_APTIDAO]: certidaoPronafAptidao,
  [CertificateCode.CERTIDAO_QUITACAO_ELEITORAL]: certidaoQuitacaoEleitoral,
  [CertificateCode.CERTIDAO_RAIS]: certidaoRAIS,
  [CertificateCode.CERTIDAO_VALOR_VENAL]: certidaoValorVenal,
  [CertificateCode.CERTIDAO_SITUACAO_PJ]: certidaoConsultaSituacaoPJ,
  [CertificateCode.CERTIDAO_SPU]: certidaoSPU,
  [CertificateCode.CERTIDAO_STM_CRIMINAIS]: certidaoSTMCriminaisConfig,
  [CertificateCode.CERTIDAO_TRIBUNAL_CONTAS]: certidaoTribunalContas,
  [DiligenceCode.DILIGENCIA_ACOMPANHAMENTOS]: diligenciaAcompanhamentosConfig,
  [DiligenceCode.DILIGENCIA_ATAS_NOTARIAIS]: diligenciaAtasNotariais,
  [DiligenceCode.DILIGENCIA_CONSULTAS]: diligenciaConsultasConfig,
  [DiligenceCode.DILIGENCIA_COPIAS]: diligenciaCopiasConfig,
  [DiligenceCode.DILIGENCIA_DESPACHANTES]: diligenciaDespachantesConfig,
  [DiligenceCode.DILIGENCIA_GUIAS]: diligenciaGuiasConfig,
  [DiligenceCode.DILIGENCIA_OUTRAS]: diligenciaOutrasConfig,
  [DiligenceCode.DILIGENCIA_PREVIA_CUSTAS_REGISTROS]: diligenciaPreviaCustasRegistrosConfig,
  [DiligenceCode.DILIGENCIA_PROTOCOLOS]: diligenciaProtocolosConfig,
  [DiligenceCode.DILIGENCIA_REGISTRO_IMOVEIS]: diligenciaRegistroImoveisConfig,
  [DiligenceCode.DILIGENCIA_REQUERIMENTOS]: diligenciaRequerimentosConfig,
  [DiligenceCode.DILIGENCIA_RETIRADA_DOCS]: diligenciaRetiradaDocsConfig,
  [DocumentRegistrationCode.RGI]: docRegistrationRGIConfig,
  [DocumentRegistrationCode.RTD]: docRegistrationRTDConfig,
  [DocumentRegistrationCode.ATA_NOTARIAL]: docRegistrationAtaNotarialConfig,
  [ResearchCode.PESQUISA_BENS]: pesquisaBens,
  [ResearchCode.PESQUISA_CAR]: pesquisaCar,
  [ResearchCode.PESQUISA_DADOS_CADASTRAIS]: pesquisaDadosCadastrais,
  [ResearchCode.PESQUISA_DIVIDA_ATIVA_PGE]: pesquisaDividaAtivaPGE,
  [ResearchCode.PESQUISA_DIVORCIO]: pesquisaDivorcio,
  [ResearchCode.PESQUISA_EMPRESA_CARTORIOS_PJ]: pesquisaEmpresaCartoriosPJ,
  [ResearchCode.PESQUISA_ESCRITURA]: pesquisaEscritura,
  [ResearchCode.PESQUISA_IMOVEL_RURAL]: pesquisaImovelRural,
  [ResearchCode.PESQUISA_INVENTARIO]: pesquisaInventario,
  [ResearchCode.PESQUISA_INDICADORES_ATIVIDADE]: pesquisaIndicadoresAtividade,
  [ResearchCode.PESQUISA_JUNTA_COMERCIAL]: pesquisaJuntaComercial,
  [ResearchCode.PESQUISA_OBITO]: pesquisaObitoConfig,
  [ResearchCode.PESQUISA_PARTICIPACAO_SOCIETARIA]: pesquisaParticipacaoSocietaria,
  [ResearchCode.PESQUISA_PROCESSOS_JUDICIAIS]: pesquisaProcessosJudiciais,
  [ResearchCode.PESQUISA_PROCURACAO]: pesquisaProcuracao,
  [ResearchCode.PESQUISA_PROPRIEDADE_AERONAVE]: pesquisaPropriedadeAeronave,
  [ResearchCode.PESQUISA_PROPRIEDADE_VEICULOS]: pesquisaPropriedadeVeiculos,
  [ResearchCode.PESQUISA_PROTESTO]: pesquisaProtesto,
  [ResearchCode.PESQUISA_RECEITA_FEDERAL]: pesquisaReceitaFederal,
  [ResearchCode.PESQUISA_SINTEGRA]: pesquisaSintegra,
  [ResearchCode.PESQUISA_VEICULAR]: pesquisaVeiculos,
  [ResearchCode.PESQUISA_COMPLIANCE]: pesquisaCompliance,
  [SignatureCertificationCode.COLETA_ASSINATURA]: coletaAssinaturas,
  [SignatureCertificationCode.CERTIFICADO_DIGITAL_CPF]: certificadoDigitalCpf,
}

export function getServiceConfig<Type = CBRdocDocument>(serviceCode: ServiceCode) {
  if (!serviceCode) {
    throw new Error('Código do serviço é obrigatório.')
  }

  return Services[serviceCode] as unknown as Type
}

// deprecated
export const getService = getServiceConfig

const ServicesById = Object.values(Services).reduce((acc, service) => {
  acc[service.id] = service as CBRdocService
  return acc
}, {} as { [index: number]: CBRdocService })

export function getServiceById(id: number) {
  if (id) {
    return ServicesById[id]
  }
}

const availableServicesKeys = Object.keys(Services) as ServiceCode[]

export const SERVICES_WITH_FIXABLE_DATA: ServiceCode[] = availableServicesKeys.filter(code => {
  return (
    Services[code].formFields &&
    Object.values(Services[code].formFields).some((field: FieldConfig) => field.dataScope === 'service')
  )
})
