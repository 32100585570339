import React from 'react'

import DeleteKitItemButton from '../registerKit/DeleteKitItemButton'
import { useCreateKit, useSetCreateKit } from './CreateKitContext'

interface Props {
  itemId: number
}

function RemoveKitItem({ itemId }: Props) {
  const setKit = useSetCreateKit()
  const { items } = useCreateKit()

  const removeItem = () => {
    const kitItems = items.filter(o => o.id !== itemId)
    setKit({ items: kitItems })
  }

  return <DeleteKitItemButton onConfirm={removeItem} />
}

export default RemoveKitItem
