import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'antd'

import { createOrderActions } from 'domain/createOrder/document'
import { RadioGroupButtonsField } from 'domain/servicesCBRdoc/fields'
import DocumentField from './DocumentField'
import config from '../certidaoSPUConfig'

interface Props {
  itemId: number
  typeField: JSX.Element
}

export default function CreateOrderCertidaoSPUForm({ itemId, typeField }: Props) {
  const dispatch = useDispatch()
  const form = Form.useFormInstance()
  const documentTypeName = [itemId, tipo_documento.name]
  const documentType = Form.useWatch(documentTypeName, form)

  useEffect(() => {
    dispatch(
      createOrderActions.setMultipleItemsLegalEntity({
        id: itemId,
        value: (documentType === 'cnpj' || documentType === 'rip') ? 'juridica' : 'fisica',
      })
    )
  }, [documentType])

  useEffect(() => {
    if (!form.getFieldValue(documentTypeName)) {
      form.setFieldValue(documentTypeName, 'cnpj')
    }
  }, [])

  return (
    <>
      {typeField}

      <RadioGroupButtonsField itemId={itemId} field={tipo_documento} />
      <DocumentField itemId={itemId} documentType={documentType} />
    </>
  )
}

const { tipo_documento } = config.formFields
