import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { FORMATO, URL_CIDADE, URL_UF } = FieldName

type CertidaoIPTUFields = Record<
  | FieldName.CNPJ
  | FieldName.CPF
  | FieldName.COMPLEMENTO
  | FieldName.INSCRICAO_IMOVEL
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

class CertidaoIPTU extends Certificate {
  formFields: CertidaoIPTUFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    complemento: Fields.complemento,
    inscricao_imovel: Fields.inscricao_imovel,
  }

  constructor() {
    super({
      availableFormatsFields: [URL_CIDADE, URL_UF],
      id: 44,
      priceByFields: [FORMATO, URL_CIDADE, URL_UF],
      shortName: 'Certidão IPTU',
      steps: ['localizacao', 'dados', 'entrega'],
    })
  }
}

const service = new CertidaoIPTU().initialize()

export default service
