import React from 'react'
import { motion } from 'framer-motion'

import { ReactComponent as LogoCBRDocLettering } from 'assets/images/logo-cbrdoc-lettering.svg'
import LatestAppRelease from 'layouts/LatestAppRelease'
import { enteringFromLeft, enteringFromRight } from 'utils/animations'

const logoCBRDoc = require('assets/images/logo-cbrdoc-horizontal.svg').default

interface Props {
  children?: React.ReactNode
  customHeader?: React.ReactNode
  bgImage?: string
  backgroundLogo?: boolean
  showMessage?: boolean
}

export default function GuestLayout({
  customHeader,
  // eslint-disable-next-line quotes
  bgImage = "bg-[url('assets/images/bg-flag.svg')]",
  backgroundLogo,
  showMessage,
  children,
}: Props) {
  return (
    <div className="flex max-w-screen-xl min-h-screen my-0 mx-auto bg-white overflow-hidden">
      <div className="flex flex-1 flex-col">
        {customHeader ?? (
          <div className="mx-auto mt-7 mb-0 sm:mt-20">
            <img src={logoCBRDoc} alt="CBRdoc - Central Brasileira de Documentos" />
          </div>
        )}

        <div className="flex-1 flex items-center">
          <div className="mx-auto lg:max-w-lg p-6">
            <motion.div initial="exit" animate="enter" exit="exit" variants={enteringFromLeft}>
              {children}
            </motion.div>
          </div>
        </div>
      </div>

      <div
        className={`hidden md:flex md:w-[550px] lg:w-[615px] flex-col 
        items-end py-20 px-7 overflow-hidden bg-cover ${bgImage}`}
      >
        {backgroundLogo && <LogoCBRDocLettering color="#aeb5c9" />}

        {showMessage && (
          <motion.div initial="exit" animate="enter" exit="exit" variants={enteringFromRight}>
            <div className="text-white font-extralight text-right text-4xl leading-[52px] mt-10">
              Sem dor de cabeça. <br />
              Documento é com a gente.
            </div>
          </motion.div>
        )}
      </div>

      <LatestAppRelease />
    </div>
  )
}
