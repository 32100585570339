import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { CategoryAPI } from 'domain/servicesCBRdoc/types'

function useServicesCategoriesQuery() {
  return useQuery(
    ['services-categories'],
    async () => {
      const response: AxiosResponse<CategoryAPI[]> = await AuthorizedAPI.get(
        'service-categories?include=services'
      )
      return response.data
    },
    {
      cacheTime: 30 * 60 * 1000, // 30 minutos
    }
  )
}

export default useServicesCategoriesQuery
