import { useState } from 'react'

import { ListViewOptionsType } from 'ui'
import { getStorageData, setStorageData } from './localStorage'
import { useIsSmallDevice } from './screen'

export default function useViewMode(): [ListViewOptionsType, (mode: ListViewOptionsType) => void] {
  const isSmallDevice = useIsSmallDevice()
  const [viewMode, setMode] = useState<ListViewOptionsType>(
    getStorageData('viewMode', isSmallDevice ? 'cardList' : 'table')
  )

  const setViewMode = (mode: ListViewOptionsType) => {
    setStorageData('viewMode', mode)
    setMode(mode)
  }

  return [viewMode, setViewMode]
}
