import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaVeicularFields = Record<
  FieldName.PLACA | FieldName.TIPO,
  FieldConfig
>

class PesquisaVeicular extends Research {
  formFields: PesquisaVeicularFields = {
    placa: Fields.placa,
    tipo: {
      ...Fields.tipo,
      kind: 'radio',
      options: [
        { label: 'Completa', value: 'pesquisa-completa' },
        { label: 'Leilão', value: 'leilao' },
        { label: 'Gravame', value: 'gravame' },
      ],
      defaultValue: 'pesquisa-completa',
      apiExtraInfo: undefined,
      apiOptionsLabelKey: 'label',
      apiOptionsValueKey: 'value',
    },
  }

  constructor() {
    super({
      description: 'Retorna uma descrição completa do veículo a partir da Placa.',
      id: 28,
      orderNameField: FieldName.PLACA,
      priceByFields: [FieldName.TIPO],
      shortName: 'Pesquisa Veicular',
    })
  }
}

const service = new PesquisaVeicular().initialize()

export default service
