import React, { useReducer } from 'react'

import { useCurrentUser } from 'domain/auth'
import { Icon } from 'ui'

import EditableOrderItemNameModal from './EditableOrderItemNameModal'
import useChangeOrderItemName from '../../services/useChangeOrderItemName'

interface Props {
  orderId: number
  itemName: string | JSX.Element
  itemId: number
  ownerId: number
  initialValue?: string
}

function EditableOrderItemName({ itemId, itemName, ownerId, orderId, initialValue }: Props) {
  const user = useCurrentUser()

  if (user.id !== ownerId) {
    return <>{itemName}</>
  }

  return <EditableName itemId={itemId} itemName={itemName} orderId={orderId} initialValue={initialValue} />
}

function EditableName({ itemId, itemName, orderId, initialValue }: Omit<Props, 'ownerId'>) {
  const [isOpen, toggleOpen] = useReducer(prev => !prev, false)

  const handleEditButton = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    e.preventDefault()
    toggleOpen()
  }

  const mutation = useChangeOrderItemName(itemId, orderId)

  return (
    <>
      <p className="flex flex-row items-center gap-2 font-bold group cursor-pointer">
        {itemName}

        <span
          className="cursor-pointer invisible text-secondary-500 hover:text-primary text-md group-hover:visible group-hover:text-primary"
          onClick={handleEditButton}
        >
          <Icon name="edit" size={20} />
        </span>
      </p>

      <EditableOrderItemNameModal
        currentName={initialValue ?? (itemName as string)}
        open={isOpen}
        onClose={toggleOpen}
        onSave={mutation.mutate}
      />
    </>
  )
}

export default EditableOrderItemName
