import React from 'react'
import { Modal } from 'antd'

import useDeleteRecurrenceItems from 'domain/recurrences/services/useDeleteRecurrenceItems'
import { RecurrenceItem } from 'domain/recurrences/types'
import { DrawerActions } from 'ui'

interface Props {
  recurrenceId: number
  selected: RecurrenceItem[]
  onClose: () => void
}

export default function RecurrenceItemsMassActions({ recurrenceId, selected, onClose }: Props) {
  return (
    <DrawerActions onClose={onClose} total={selected.length}>
      <RecurrenceItemsMassDelete recurrenceId={recurrenceId} selected={selected} onClose={onClose} />
    </DrawerActions>
  )
}

function RecurrenceItemsMassDelete({ recurrenceId, selected, onClose }: Props) {
  const { mutate } = useDeleteRecurrenceItems(recurrenceId, {
    onSuccess: onClose,
  })

  return (
    <DrawerActions.Button
      onClick={() => {
        Modal.confirm({
          title: 'Exclusão de Item da Recorrência',
          content: 'Tem certeza que deseja excluir os itens selecionados?',
          okText: 'Excluir',
          cancelText: 'Cancelar',
          onOk: () => mutate(selected.map(({ id }) => id)),
        })
      }}
      icon="trash"
    >
      Excluir Item
    </DrawerActions.Button>
  )
}
