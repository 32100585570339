import { NameField, NumberField } from 'domain/servicesCBRdoc/fields'
import { CNPJInput, CPFInput } from 'ui'
import config from '../certidaoSPUConfig'

interface Props {
  documentType: string
  itemId: number
}

export default function DocumentField({ documentType, itemId }: Props) {
  if (documentType === 'cpf') {
    return <CPFInput itemId={itemId} />
  }

  if (documentType === 'nome') {
    return <NameField itemId={itemId} field={nome_pf} />
  }

  if (documentType === 'rip') {
    return <NumberField itemId={itemId} field={numero_rip} min="0" />
  }

  return <CNPJInput itemId={itemId} />
}

const { nome_pf, numero_rip } = config.formFields
