import { LabelAndIntValue } from 'domain/app/types'
import { CertificateCode } from 'domain/servicesCBRdoc/types'
import { IconName } from 'ui'

export type AICreateOrderQuestion = {
  id?: number
  questionToShow: string
  questionToAi: string
}

export interface AICreateOrderModel {
  documentName: string
  icon?: IconName
  id: number
  modelName: string
  questions: AICreateOrderQuestion[]
  service?: LabelAndIntValue
  fixedSchema: boolean
  hiddenSchema: boolean
}

export interface AIModelForm extends Omit<AICreateOrderModel, 'id' | 'icon' | 'service'> {
  id?: number
  service: LabelAndIntValue | { label: string; value: 'other' } | undefined
}

export type AIDefaultModel = {
  id: number
  questions: AIDefaultQuestion[]
}

export interface AIDefaultQuestion extends Omit<AICreateOrderQuestion, 'questionToAi'> {
  code: string
  serviceId: number
  answerPlaceholder: string
} 

export type AIDocumentType = CertificateCode | 'other' 

export type AIModelsVisibility = 'public' | 'private' | 'all'

export enum EnrichmentAvailability {
  AVAILABLE = 'available',
  DISABLED_FOR_CUSTOMER = 'disabled-for-customer',
  UNKNOWN_SERVICE = 'no-service-explorer-item',
  DISABLED_FOR_SERVICE = 'disabled-for-service',
  DOCUMENT_TOO_LONG = 'document-too-long',
  PREPAID_CUSTOMER = 'prepaid-customer',
}

export type AIQuestion = AICreateOrderQuestion & {
  answer?: string
}

export type AIModel = {
  id: number
  modelName: string
  questions: AIQuestion[]
  hasAnswers: boolean
  downloadAsXml: boolean
}
