import React from 'react'

import { NameField } from 'domain/servicesCBRdoc/fields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { CompanyNameInput, CPFOrCNPJInput, DateInput, TextInput } from 'ui'

interface Props {
  field: FieldConfig
  autoFocus?: boolean
}

function MissingFields({ field, autoFocus }: Props) {
  const { name, apiName, label, kind, optional, maxLength } = field
  if (kind === 'cpfOrCnpj') {
    return <CPFOrCNPJInput name={name} />
  }

  if (kind === 'text') {
    return (
      <TextInput
        name={apiName}
        label={label}
        maxLength={maxLength}
        autoFocus={autoFocus}
        required
      />
    )
  }

  if (kind === 'name') {
    if (name === FieldName.NOME_PJ) {
      return <CompanyNameInput label={label} name={name} autoFocus={autoFocus} />
    }

    return <NameField field={field} required={optional !== true} />
  }

  if (kind === 'date') {
    return <DateInput name={name} label={label} required={!optional} />
  }

  return null
}

export default MissingFields
