import React from 'react'

import HelpLine from './HelpLine'

interface Props {
  title: React.ReactNode
  children?: React.ReactNode
  icon?: React.ReactNode
}

function MessageWithHelpLine({ title, children, icon }: Props) {
  return (
    <div className="p-7 flex flex-col flex-1 justify-center items-center">
      {icon}

      <h1 className="font-bold text-center text-2xl mb-3">{title}</h1>

      {children}

      <HelpLine />
    </div>
  )
}

export default MessageWithHelpLine
