import React from 'react'
import { Tag } from 'antd'

import { Group } from '../types'

interface Props {
  groups: Group[]
  setGroups: React.Dispatch<React.SetStateAction<Group[]>>
}

function CreateGroupsTags({ groups, setGroups }: Props) {
  return (
    <div className="flex flex-row gap-2">
      {groups.map(({ name }) => (
        <Tag
          key={name}
          closable
          onClose={() => setGroups(prev => prev.filter(g => g.name !== name))}
          className="flex justify-between items-center"
        >
          {name}
        </Tag>
      ))}
    </div>
  )
}

export default CreateGroupsTags
