import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { useGetOrderItem } from 'domain/orderItem/shared'

export default function useOrderAttachments() {
  const { id } = useGetOrderItem()

  return useQuery({
    queryKey: [id, 'attachments'],
    queryFn: async () => {
      const res = await AuthorizedAPI.get(`/orders/${id}/attached-files`)
      return res.data
    },
  })
}
