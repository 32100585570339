import { useEffect, useState } from 'react'
import { cnpj as CNPJUtils } from 'cpf-cnpj-validator'
import { Form } from 'antd'

import { AuthorizedAPI } from 'api/network/v2'
import { getFieldName } from 'domain/createOrder/shared'
import { Fields } from 'domain/servicesCBRdoc'
import { FullNameInput, MaskedInput, MaskedInputProps, LoadingIcon } from 'ui'
import { onlyNumbers } from 'utils/formatters'
import { FieldConfig } from '../types'

interface Props extends Omit<MaskedInputProps, 'id' | 'mask' | 'name' | 'placeholder' | 'rules'> {
  itemId?: number | string
  label?: string
  name?: string
  companyField: FieldConfig
  onCompanyNameChange?: (companyNameFields: string | (string | number)[], value: string) => void
}

export default function CNPJAndNameFields({
  label = 'CNPJ',
  name = 'cnpj',
  itemId,
  companyField,
  onCompanyNameChange,
  ...props
}: Props) {
  const [isMounted, setIsMounted] = useState(false)
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle')
  const fieldName = itemId !== undefined ? [itemId, name] : name
  const form = Form.useFormInstance()
  const value = Form.useWatch(fieldName, form)
  const companyNameField = getFieldName(companyField.name, itemId)
  
  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  useEffect(() => {
    const cnpj = onlyNumbers(value)

    if (cnpj.length === 14) {
      const loadCompanyName = async () => {
        setStatus('loading')

        const isValid = CNPJUtils.isValid(value, true)

        if (isValid) {
          try {
            const res = await AuthorizedAPI.get(`/company-data-by-cnpj/${cnpj}`)
            const companyName = res.data.valid ? res.data.corporate_name : ''

            if (isMounted) {
              form.setFieldValue(companyNameField, companyName)
            }
            // eslint-disable-next-line max-len
            // https://ant.design/components/form#setfieldsvalue-do-not-trigger-onfieldschange-or-onvalueschange
            // por causa disso é preciso chamar manualmente setField quando chamado do kit
            onCompanyNameChange && onCompanyNameChange(companyNameField, companyName)
            setStatus(res.data.valid ? 'idle' : 'error')
            form.validateFields([companyNameField])
          } catch (error) {
            setStatus('error')
          }
        } else {
          setStatus('idle')
        }
      }

      loadCompanyName()
    }
  }, [value, isMounted])

  return (
    <>
      <MaskedInput
        mask={Fields.cnpj.mask!}
        placeholder="00.000.000/0000-00"
        name={fieldName}
        label={label}
        rules={validationRules}
        id="cnpj"
        suffix={<div>{status === 'loading' && <LoadingIcon />}</div>}
        required
        help={status === 'error' ? 'Empresa não encontrada com o CNPJ.' : undefined}
        validateStatus={status === 'error' ? 'warning' : undefined}
        {...props}
      />

      <FullNameInput
        label={companyField.label}
        name={companyNameField}
        required={!companyField.optional}
        maxLength={companyField.maxLength}
      />
    </>
  )
}

const validationRules = [
  {
    validator: (rule: object, value: string) =>
      CNPJUtils.isValid(value, true) ? Promise.resolve() : Promise.reject('Informe um CNPJ válido.'),
  },
]
