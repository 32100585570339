import { useState } from 'react'
import { Radio } from 'antd'

import { CertificateFlow } from 'domain/createOrder/certificates'
import { DateField, NameField, TituloEleitorField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../certidaoQuitacaoEleitoralConfig'

export default function CreateOrderCertidaoQuitacaoEleitoral() {
  return (
    <CertificateFlow
      renderFormItem={(itemId: number) => <CertidaoQuitacaoEleitoralForm itemId={itemId} />}
    />
  )
}

const CertidaoQuitacaoEleitoralForm = ({ itemId }: { itemId: number }) => {
  const [documentType, setDocumentType] = useState('cpf')

  return (
    <>
      <NameField field={nome_pf} itemId={itemId} autoFocus />
      <NameField field={mae} itemId={itemId} />
      <NameField field={pai} itemId={itemId} />
      <DateField field={nascimento} itemId={itemId} />

      <Radio.Group onChange={e => setDocumentType(e.target.value)} value={documentType} className="mb-3">
        <Radio value="cpf">CPF</Radio>
        <Radio value="titulo">Título de eleitor</Radio>
      </Radio.Group>

      {documentType === 'cpf' ? (
        <CPFInput name={cpf.apiName} itemId={itemId} />
      ) : (
        <TituloEleitorField name={titulo_eleitor.apiName} itemId={itemId} />
      )}
    </>
  )
}

const { cpf, mae, nascimento, nome_pf, pai, titulo_eleitor } = config.formFields
