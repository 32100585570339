import React, { useEffect } from 'react'

import { LabelInValue } from 'domain/app/types'
import { SelectedService } from 'domain/createOrder/types'
import { KitLegalEntity } from 'domain/kits/types'
import { getLocationOf } from 'domain/servicesCBRdoc/helpers/descriptions'
import { getServiceConfig } from 'domain/servicesCBRdoc/products'
import { CitySelect, CitySelectProps } from 'ui'

import useServiceCitiesUFQuery from '../../services/useServiceCitiesQuery'
import { FieldName } from '../types'

interface Props extends Omit<CitySelectProps, 'optionLabelKey' | 'optionValueKey'> {
  service: SelectedService
  federativeUnit?: string
  legalEntity: KitLegalEntity | undefined
  onChange: (value: LabelInValue | undefined) => void
}

function LocationCitySelect({
  name = FieldName.TODOS_CARTORIOS_CIDADE,
  service,
  federativeUnit,
  legalEntity,
  suffix,
  onChange,
  ...props
}: Props) {
  const { data, isError, isFetching } = useServiceCitiesUFQuery({ service, federativeUnit, legalEntity })
  const { formFields } = getServiceConfig(service.code!)

  useEffect(() => {
    if (data && data.length === 1) {
      onChange({ label: data[0].name, value: data[0].url })
    }
  }, [data])

  return (
    <CitySelect
      name={name}
      hasError={isError}
      loading={isFetching}
      options={data}
      onChange={onChange}
      optionLabelKey="name"
      optionValueKey="url"
      suffix={suffix ?? getLocationOf(service.type!)}
      multiple={formFields.url_cidade?.multiple}
      {...props}
    />
  )
}

export default LocationCitySelect
