
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'

function useInsightsQuery(enabled: boolean) {
  return useQuery(
    ['insights'],
    () => {
      return getInsights()
    },
    {
      enabled,
    }
  )
}

const getInsights = async () => {
  const response: AxiosResponse<any> = await AuthorizedAPI.get('/bi/access-data')
  return response.data
}

export default useInsightsQuery
