import { useState } from 'react'
import { useDebounce } from 'use-debounce'

import { useActiveUsersFilterQuery } from 'domain/users'
import { SelectPaginated } from 'ui'

export default function MyFilesFilterOwnerSelect() {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [debouncedValue] = useDebounce(search, 300)

  const { data, isFetching } = useActiveUsersFilterQuery({ search: debouncedValue, page, setTotal })

  return (
    <SelectPaginated
      name="ownerId"
      label="Responsável"
      placeholder="Selecione o responsável"
      loading={isFetching}
      options={data}
      optionLabelKey="name"
      optionValueKey="id"
      total={total}
      page={page}
      setSearch={setSearch}
      setPage={setPage}
    />
  )
}
