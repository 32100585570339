import React from 'react'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { useCustomerCreditsQuery } from 'domain/credits'
import { useGetSelectedMonth } from 'domain/invoices/state'
import { StatisticCard } from 'ui'
import { displayMonth, displayYear, getMonthPeriod } from 'utils/dateTime'
import { formatMoney } from 'utils/formatters'

import InvoicesSummaryContainer from '../../components/InvoicesSummaryContainer'
import ExportPrepaidInvoicesToExcel from './ExportPrepaidInvoicesToExcel'
import ExportPrepaidInvoicesToPDF from './ExportPrepaidInvoicesToPDF'

export default function PrepaidInvoicesSummaryAndExport() {
  const selectedMonth = useGetSelectedMonth()
  const { currentMonth } = getMonthPeriod(selectedMonth)
  const month = displayMonth(selectedMonth)
  const year = displayYear(selectedMonth)

  const {
    status,
    data: stats,
    isFetching,
  } = useQuery(['invoices-statistics', month, year], async () => {
    const response: AxiosResponse<InvoiceStat> = await AuthorizedAPI.get(
      `/invoices/${year}/${month}/stats?filter[approved_only]=true`
    )
    return response.data
  })

  return (
    <InvoicesSummaryContainer loading={isFetching}>
      <StatisticCard
        title={`Créditos adicionados ${currentMonth}`}
        prefix="+"
        value={formatMoney(stats?.credits.sum)}
      />
      <StatisticCard
        title={`Créditos utilizados ${currentMonth}`}
        prefix="-"
        value={formatMoney(stats?.debits.sum)}
      />

      <CurrentBalance />

      {status === 'success' && (
        <div className="ml-4 flex flex-col justify-between">
          <ExportPrepaidInvoicesToExcel />
          <ExportPrepaidInvoicesToPDF />
        </div>
      )}
    </InvoicesSummaryContainer>
  )
}

function CurrentBalance() {
  const { data: accountBalance } = useCustomerCreditsQuery()

  return (
    <StatisticCard
      title="Créditos Disponíveis"
      value={<span className="text-green-500">{formatMoney(accountBalance)}</span>}
    />
  )
}

export interface InvoiceStat {
  credits: {
    sum: number
    count: number
  }
  debits: {
    sum: number
    count: number
  }
}
