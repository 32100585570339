import { Spin } from 'antd'
import React from 'react'

import { KitItem } from 'domain/kits/types'
import { ResearchTimeField } from 'domain/servicesCBRdoc/fields'
import { getServiceConfig } from 'domain/servicesCBRdoc/products'
import { Loading } from 'ui'

import { useLoadKitDynamicFields } from '../../services'
import DynamicSelectField from './DynamicSelectField'

interface Props {
  kitItem: KitItem
}

function KitOrderDynamicFields({ kitItem }: Props) {
  const service = getServiceConfig(kitItem.service.code!)

  const { fields: allDynamicFields, isLoading, isFetching } = useLoadKitDynamicFields(
    kitItem,
    service.shoudAutoLoadDynamicFields
  )

  if (isLoading) {
    return <Loading />
  }

  const serviceFields =
    allDynamicFields?.filter(field => field.dataScope === 'service' && !field.ignoreOnKitsAutomaticForm) ?? []

  if (serviceFields.length === 0) {
    if (service.shoudAutoLoadDynamicFields) {
      const hasServiceFields = Object.values(service.formFields).some(o => o.dataScope === 'service')

      if (!hasServiceFields) {
        return <p className="text-sm text-gray-400 my-4">Este item não possui dados específicos.</p>
      }      
    }

    return null
  }

  return (
    <Spin spinning={isFetching}>
      {serviceFields.map(field => {
        if (field.kind === 'researchTime') {
          return (
            <ResearchTimeField key={field.apiName} options={field.options as number[]} itemId={kitItem.id} />
          )
        }        

        return <DynamicSelectField key={field.apiName} field={field} itemId={kitItem.id} />
      })}
    </Spin>
  )
}

export default React.memo(KitOrderDynamicFields)
