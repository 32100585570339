import React from 'react'

import Xerox from './Xerox'

function XeroxSimples() {
  return (
    <Xerox
      label="Xerox simples"
      description="Reprodução de um documento sem valor legal."
      name="qtde_xerox_simples"
    />
  )
}

export default XeroxSimples
