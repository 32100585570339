import { v4 as uuidv4 } from 'uuid'

import { CreateOrderFormItem, CreateOrderState, FormItems } from 'domain/createOrder/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { ShoppingCartOrder } from 'domain/shoppingCart/types'
import { DocumentConfig } from '../types'
import CBRdocDocument from './CBRdocDocument'
import { getServiceConfig } from 'domain/servicesCBRdoc/products'

export default class DocumentRegistration extends CBRdocDocument {
  constructor(config: DocumentConfig) {
    super(config)
  }

  protected getDocRegistrationSubmitData = ({
    files,
    formData,
    formItems,
    selectedLocation,
    selectedModel,
    selectedService,
  }: CreateOrderState) => {
    const service = getServiceConfig(selectedService.code!)
    const file = files ? files[0] : undefined

    const entries = Object.entries(formData).map(this.prepareFieldToSubmit)
    const data = Object.fromEntries(entries)
    const submitData: any = {
      ...data,
      ...this.getSubmitLocation(selectedLocation),
      formato: 'email',
      origin: 'Registro Doc',
    }

    if (file) {
      submitData[UPLOAD] = file.fileUploadId
      submitData[ARQUIVO_NOME_ORIGINAL] = file.name
      submitData[NOME_ITEM_PEDIDO] = file.name
    }

    if (selectedModel) {
      const fieldStateKey = Object.values(service.formFields).find(field => field.stateKey === 'selectedModel')
      submitData[fieldStateKey?.apiName ?? 'tipo_fluxo_assinaturas'] = selectedModel
    }

    if (selectedModel === 'com-assinaturas') {
      submitData.assinantes = this.getSignees(formItems, formData)
    }

    return submitData
  }

  private getSignees = (formItems: FormItems, formData: any) => {
    return Object.values(formItems)
      .sort((a, b) => a.itemOrder - b.itemOrder)
      .map((item: CreateOrderFormItem) => {
        const signee: any = formData.assinantes[item.id]
        const { nome_pf, ...signeeData } = signee

        if (nome_pf) {
          signeeData.nome = nome_pf
        }

        const entries = Object.entries(signeeData)
        const normalizedEntries = entries.map(entry => this.prepareFieldToSubmit(entry as [FieldName, any]))

        return Object.fromEntries(normalizedEntries)
      })
  }

  convertToCartItems = (createOrder: CreateOrderState) => {
    const { cartItemId, files } = createOrder

    if (files && files.length > 0) {
      const orders = files.map((file, index) => {
        const docItem = this._generateCreateOrderState({
          ...createOrder,
          cartItemId: index > 0 ? uuidv4() : cartItemId,
          files: [file],
        })

        const cartItem: ShoppingCartOrder = {
          ...docItem,
          submitData: this.getSubmitData(docItem),
        }

        return cartItem
      })

      return orders
    }

    const orders = this.generateCartItems(createOrder)
    const splittedOrders = orders.map(this.splitOrders).flat()
    return splittedOrders
  }
}

const { ARQUIVO_NOME_ORIGINAL, NOME_ITEM_PEDIDO, UPLOAD } = FieldName
