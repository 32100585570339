import { useMutation } from 'react-query'
import { message } from 'antd'

import { handleRequestError } from 'api/HandleRequestError'
import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'

import { MoveExplorerItemParams } from '../types'

function useMoveMutation(options: any = {}) {
  const mutation = useMutation(
    async (explorerItem: MoveExplorerItemParams) => await moveExplorerItem(explorerItem),
    {
      onError: error => {
        handleRequestError(error, { code: '0x703' })
      },
      onSuccess: (data: any) => {
        queryClient.invalidateQueries(['my-files', 'list'])

        message.success('Item movido com sucesso.')

        options.onSuccess && options.onSuccess(data)
      },
    }
  )

  return {
    moveItem: mutation.mutate,
    loading: mutation.status === 'loading',
  }
}

const moveExplorerItem = async (params: MoveExplorerItemParams) => {
  const url = `/explorer/${params.id}/parent/${params.parentId}`
  return await AuthorizedAPI.patch(url)
}

export default useMoveMutation
