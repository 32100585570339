import React from 'react'

import { LabelInValue } from 'domain/app/types'
import { SelectField } from 'ui'

function FrequencySelect() {
  return <SelectField label="Repetir" name="frequency" options={options} required allowClear={false} />
}

const options: LabelInValue[] = [
  { label: 'Semanalmente', value: 'weekly' },
  { label: 'Mensalmente', value: 'monthly' },
  { label: 'Anualmente', value: 'yearly' },
  { label: 'Escolher dias', value: 'every_x_days' }
]

export default FrequencySelect
