import React from 'react'

import { Icon } from 'ui'
import { useDownload } from 'utils/export'

interface Props {
  itemId: number
  disabled?: boolean
}

export default function DownloadFile({ itemId, disabled }: Props) {
  const [handleDownload, isFileLoading] = useDownload()

  const onClick = async () => {
    const downloadUrl = `/orders/${itemId}/download`
    await handleDownload(downloadUrl)
  }

  return (
    <div
      className={`dropdown-options-primary flex items-center p-0 ${disabled && 'text-gray-500 cursor-not-allowed'}`}
      onClick={!disabled ? onClick : undefined}
    >
      <Icon name={isFileLoading ? 'file-loading' : 'download'} className="mr-2 text-base" />
      Baixar documento
    </div>
  )
}
