import React from 'react'
import { Button, Dropdown } from 'antd'

import { AICreateOrderModel } from 'domain/openAI/types'
import { Icon } from 'ui'
import { useDownload } from 'utils/export'

import ModelCard from './ModelCard'

interface Props {
  model: AICreateOrderModel
}

export default function CBRdocModelCard({ model }: Props) {
  const [download, loading] = useDownload()

  const items: any = [
    {
      label: 'Exportar',
      key: 'export',
      onClick: () => download(`/reports/ai-answers/xlsx?ai_model_id=${model.id}`),
      disabled: loading,
    }
  ]

  return (
    <ModelCard model={model}>
      <Dropdown
        menu={{ items }}
        placement="bottomRight"
        className="text-primary max-h-8"
      >
        <Button type="primary" ghost size="small" icon={<Icon name="ellipsis" />} />
      </Dropdown>
    </ModelCard>
  )
}
