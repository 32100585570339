import React from 'react'

export interface StatisticCardProps {
  title: string
  prefix?: '+' | '-'
  value: string | number | React.ReactNode
  className?: string
  children?: React.ReactNode
}

const StatisticCard = ({ title, prefix, value, className = '', children }: StatisticCardProps) => {
  return (
    <div
      // eslint-disable-next-line max-len
      className={`flex flex-1 flex-row ${className} bg-white rounded py-1 px-2 min-h-card justify-between items-end`}
    >
      <div className="flex flex-1 flex-col justify-end">
        <div className="font-extralight text-gray-600 text-3xl whitespace-nowrap">
          {prefix && <ValuePrefix prefix={prefix} />} {value}
        </div>
        <div className="text-xs text-gray-600 whitespace-nowrap">{title}</div>
      </div>
      {children}
    </div>
  )
}

function ValuePrefix({ prefix }: { prefix: '+' | '-' }) {
  const textColor = prefix === '+' ? 'green-500' : 'error'
  return <span className={`text-${textColor} text-3xl`}>{prefix}</span>
}

export default StatisticCard
