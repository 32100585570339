import React from 'react'

import { DeliveryInfo } from 'domain/createOrder/shared/components'
import { useCreateOrderState } from 'domain/createOrder/document/state'

function DueDiligencePriceDeliveryInfo() {
  const { orderDetails } = useCreateOrderState()

  if (!orderDetails?.estimatedDeliveryTime) return null

  return <DeliveryInfo estimatedDeliveryTime={orderDetails.estimatedDeliveryTime} />
}

export default DueDiligencePriceDeliveryInfo
