import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { PeriodString } from 'domain/app/types'

export default function useStatisticsQuery(period: PeriodString) {
  return useQuery(['report-order-stats', period], async () => await getOrderStats(period), {
    enabled: Boolean(period),
  })
}

interface OrderStatsData {
  average_expended_per_order: number
  count: number
  expended_amount: number
}

const getOrderStats = async (period: PeriodString) => {
  const dates = period.split(',')

  const response: AxiosResponse<OrderStatsData> = await AuthorizedAPI.get('/reports/order-stats', {
    params: {
      begin_date: dates[0],
      end_date: dates[1],
    },
  })

  if (response.data) {
    const { average_expended_per_order, count, expended_amount } = response.data

    return {
      averageExpendedPerOrder: average_expended_per_order,
      count,
      expendedAmount: expended_amount,
    }
  }
}
