import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { useGetIsHydratedFromURL } from 'domain/app'
import { removeEmpties } from 'utils/transformData'

import {
  useGetCurrentPage,
  useGetPageSize,
  useGetSortBy,
  useSetPageCountResults,
  useSetTotalResults,
} from '../state/pagination'
import { useGetFilters } from '../state/filters'
import { RecurrenceAPI } from '../types'
import { convertRecurrence } from './convertRecurrence'

export default function useRecurrencesQuery() {
  const filters = useGetFilters()
  const sort = useGetSortBy()
  const page = useGetCurrentPage()
  const pageSize = useGetPageSize()
  const setPageResults = useSetPageCountResults()
  const setTotalResults = useSetTotalResults()
  const queryKey = ['recurrences', 'all', page, pageSize, filters, sort]
  const isHydrated = useGetIsHydratedFromURL()

  const query = useQuery(
    queryKey,
    async () => {
      const { dates, nameOrId, ownerId } = filters

      const response: AxiosResponse<RecurrenceAPI[]> = await AuthorizedAPI.get('/recurrences', {
        params: removeEmpties({
          include: 'owner,items,items.order,groups',
          append: 'purchases_ids',
          'per-page': pageSize,
          'filter[name_or_id]': nameOrId,
          'filter[owner_id]': ownerId,
          'filter[created_between]': dates,
          sort,
          page,
        }),
      })

      setPageResults(Number(response.headers['x-pagination-page-count']))
      setTotalResults(Number(response.headers['x-pagination-total-count']))

      return response.data.map(convertRecurrence)
    },
    {
      enabled: isHydrated,
      keepPreviousData: true,
    }
  )

  return { ...query, queryKey }
}
