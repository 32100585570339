import { ServiceType } from './types'

export const ServiceTypeDescription: Record<ServiceType, string> = {
  [ServiceType.AI]: 'Extração de Dados',
  [ServiceType.CERTIFICATE]: 'Certidão',
  [ServiceType.DILIGENCE]: 'Diligência',
  [ServiceType.DOCUMENT_REGISTRATION]: 'Registro de Documentos',
  [ServiceType.RESEARCH]: 'Registro de Pesquisa',
  [ServiceType.SIGNATURES_CERTIFICATES]: 'Certidão e assinatura digital',
}
