import React from 'react'
import { Spin } from 'antd'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { displayMonthAndYear } from 'utils/dateTime'
import useYearOrdersChartQuery from '../services/useYearOrdersChartQuery'

export default function OrdersChart() {
  const { data: ordersPerMonth, isLoading } = useYearOrdersChartQuery()

  const ondersPerMonthChartData =
    ordersPerMonth &&
    Object.entries(ordersPerMonth).map(([key, value]) => ({
      month: displayMonthAndYear(key).toUpperCase(),
      ordersCount: value,
    }))

  return (
    <Spin spinning={isLoading}>
      <ResponsiveContainer width="100%" aspect={5}>
        <LineChart data={ondersPerMonthChartData} margin={lineChartMargin}>
          <CartesianGrid stroke="#32394b" strokeDasharray="3 3" />
          <XAxis
            dataKey="month"
            stroke="#8293be"
            tick={tickMonth}
            tickMargin={15}
            tickSize={0}
            axisLine={strokeWidth}
          />
          <YAxis
            dataKey="ordersCount"
            stroke="#8293be"
            tick={tickOrdersCount}
            tickMargin={15}
            tickSize={0}
            axisLine={strokeWidth}
          />
          <Line type="monotone" dataKey="ordersCount" stroke="#40ca8f" strokeWidth={2} />
          <Tooltip formatter={formatter} separator=" " />
        </LineChart>
      </ResponsiveContainer>
    </Spin>
  )
}

const formatter: any = (value: any) => [`${value} pedidos`, '']

const lineChartMargin = { top: 5, right: 50, left: 0, bottom: 0 }
const tickMonth = { fontSize: 11, fontWeight: 'bold' }
const tickOrdersCount = { fontSize: 12, fontWeight: 'bold' }
const strokeWidth = { strokeWidth: 2 }
