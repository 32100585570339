import { Upload, UploadFile } from 'antd'
import React from 'react'

import { Button } from 'ui'
import useReadFile from './useReadFile'
import { useGetNecessaryFields, useSetColumnsIdentification } from '../../state'

type Props = {
  file: UploadFile | undefined
  setFile: (data: UploadFile | undefined) => void
  setRows: React.Dispatch<React.SetStateAction<any[]>>
}

export default function KitSpreadsheetFile({ file, setFile, setRows }: Props) {
  const [readFile] = useReadFile()
  const setColumnsFields = useSetColumnsIdentification()

  return (
    <div className="max-w-lg">
      <h3 className="text-lg text-secondary font-bold mb-2">1. Selecione o arquivo</h3>

      <p className="text-sm text-gray-500 mb-3 max-w-md">
        Certifique-se de que as colunas de {<b>data</b>} estejam formatadas como texto.
      </p>

      <Upload
        name="file"
        accept=".xls,.xlsx,application/vnd.ms-excel"
        customRequest={({ file }) => {
          if (file) {
            setRows([])

            readFile({
              file: file as Blob,              
              sheetRows: 5,
              onSuccess: async (jsonRows) => {
                setRows(prev => [...prev, ...jsonRows])
              },
            })
          }

          return file
        }}
        fileList={file ? [file] : undefined}
        onChange={({ file, fileList }) => {
          setColumnsFields({})

          if (file && fileList.length > 0) {
            setFile({ ...file, status: 'done' })
          } else {
            setFile(undefined)
            setRows([])
          }
        }}
        className="mb-8"
      >
        <Button icon="upload" className="bg-secondary-500">
          Escolher o arquivo...
        </Button>
      </Upload>

      <NecessaryFields />
    </div>
  )
}

function NecessaryFields() {
  const fields = useGetNecessaryFields()

  return (
    <>
      <p className="text-sm text-gray-500 mt-6 mb-3">Para solicitar este kit, sua planilha deve ter as seguintes colunas (em qualquer ordem):</p>
      <ul className="list-disc list-inside">
        {fields.map(field => (
          <li key={field.label} className="text-sm"><b>{field.label}</b> {field.optional ? ' (opcional)' : ''}</li>
        ))}
      </ul>
    </>
  )
}
