import { RadioGroupProps, RadioChangeEvent } from 'antd'

import KitLegalEntityRadio from '../registerKit/KitLegalEntityRadio'
import { useCreateKit, useSetCreateKit } from './CreateKitContext'

interface Props extends RadioGroupProps {
  compact?: boolean
}

export default function CreateKitEntityType({ compact, ...props }: Props) {
  const setKit = useSetCreateKit()
  const { items, legalEntity } = useCreateKit()

  const currentServices = items.map(item => item.service)

  const handleChange = (e: RadioChangeEvent) => {
    setKit({ legalEntity: e.target.value })
  }

  return (
    <KitLegalEntityRadio
      {...props}
      compact={compact}
      legalEntity={legalEntity}
      currentServices={currentServices}
      onChange={handleChange}
    />
  )
}
