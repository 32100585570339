import React from 'react'
import { useDispatch } from 'react-redux'

import { SelectedService } from 'domain/orderItem/types'
import { LocationFields } from 'domain/servicesCBRdoc/fields'
import { SelectedLocation } from 'domain/servicesCBRdoc/types'
import { createOrderActions, useCreateOrderState } from '../state'

interface Props {
  required?: boolean
  requiredUrlUf?: boolean
  requiredUrlCidade?: boolean
  requiredUrlCartorio?: boolean
  autoFocus?: boolean
  onChange?: () => void
  onChangeFederativeUnit?: () => void
}

export default function SelectLocationFields({
  required = true,
  requiredUrlUf,
  requiredUrlCidade,
  requiredUrlCartorio,
  autoFocus,
  onChange,
  onChangeFederativeUnit,
}: Props) {
  const dispatch = useDispatch()
  const { selectedEntityType, selectedLocation, selectedService, selectedType } = useCreateOrderState()

  const handleChange = (location: SelectedLocation) => {
    dispatch(createOrderActions.setSelectedLocation(location))
    onChange && onChange()
  }

  const onChangeType = (type: string) => {
    dispatch(createOrderActions.setSelectedType(type))
  }

  return (
    <LocationFields
      legalEntity={selectedEntityType}
      selectedLocation={selectedLocation}
      selectedService={selectedService as SelectedService}
      selectedType={selectedType}
      onChange={handleChange}
      onChangeFederativeUnit={onChangeFederativeUnit}
      onChangeType={onChangeType}
      autoFocus={autoFocus}
      required={required}
      requiredUrlCartorio={requiredUrlCartorio}
      requiredUrlCidade={requiredUrlCidade}
      requiredUrlUf={requiredUrlUf}
    />
  )
}
