import { useMemo } from 'react'
import { Dropdown, MenuProps } from 'antd'

import { useClearFilters, useGetFilters } from 'domain/orders/state/filters'
import { OrdersFilters } from 'domain/orders/types'
import { Button, ClearFiltersButton, Icon, useCountActiveFilters } from 'ui'
import { removeFalsyEntries } from 'utils/transformData'
import FilterByGroupTags from './FilterByGroupTags'
import FilterByRecurrenceTag from './FilterByRecurrenceTag'
import FilterByStatusTags from './FilterByStatusTags'
import FilterByServicesTags from './FilterByServicesTags'
import FilterByOwnersTags from './FilterByOwnersTags'
import FilterByPeriodTag from './FilterByPeriodTag'
import FilterByResultsTags from './FilterByResultsTags'
import FilterBySearchTermTag from './FilterBySearchTermTag'
import BooleanFiltersTags from './BooleanFiltersTags'

type ActiveFilters = Partial<OrdersFilters>

export default function OrdersFilterButton({ onClick }: { onClick: () => void }) {
  const filters = useGetFilters()

  const activeFilters = useMemo(() => {
    const entries = removeFalsyEntries(filters)
    return Object.fromEntries(entries) as ActiveFilters
  }, [filters])

  const countActiveFilters = useCountActiveFilters(activeFilters)

  if (countActiveFilters === 0) {
    return (
      <Button className="mt-3" type="primary" ghost icon="filter" onClick={onClick}>
        Mais Filtros
      </Button>
    )
  }

  const filterDropdownItems: MenuProps['items'] = [
    {
      label: <FiltersDropdown activeFilters={activeFilters} />,
      key: '0',
      className: 'p-2 bg-white hover:bg-white',
    },
  ]

  return (
    <div className="mt-2 relative">
      <div className="w-5 h-5 bg-red-500 rounded-full text-white text-xs font-semibold shadow-md text-center absolute -right-1 -bottom-1 z-50">
        {countActiveFilters}
      </div>
      <Dropdown.Button
        menu={{ items: countActiveFilters > 0 ? filterDropdownItems : [] }}
        // @ts-ignore
        type="ghost"
        className="[&>button]:text-primary [&>button]:border-primary [&>button.ant-btn-icon-only]:w-9"
        onClick={onClick}
      >
        <span className="flex flex-row items-center gap-2">
          <Icon name="filter" /> <span>Mais Filtros</span>
        </span>
      </Dropdown.Button>
    </div>
  )
}

function FiltersDropdown({ activeFilters }: { activeFilters: ActiveFilters }) {
  const {
    dateRange,
    groupsIds,
    ownersIds,
    results,
    servicesIds,
    statuses,
    recurrenceId,
    searchTerm,
    ...others
  } = activeFilters

  return (
    <div className="max-w-sm w-64">
      <div className="flex items-center text-4xs uppercase font-bold text-secondary-500 mb-1 h-6">
        Filtros ativos
      </div>

      <div className="flex flex-row gap-1 flex-wrap">
        {servicesIds && <FilterByServicesTags value={servicesIds} />}
        {statuses && <FilterByStatusTags value={statuses} />}
        {results && <FilterByResultsTags value={results} />}
        {groupsIds && <FilterByGroupTags value={groupsIds} />}
        {ownersIds && <FilterByOwnersTags value={ownersIds} />}
        {recurrenceId && <FilterByRecurrenceTag value={recurrenceId} />}
        {dateRange && <FilterByPeriodTag value={dateRange} />}
        {searchTerm && <FilterBySearchTermTag value={searchTerm} />}
        <BooleanFiltersTags filters={others} />
      </div>

      <ClearFilters />
    </div>
  )
}

function ClearFilters() {
  const clearFilters = useClearFilters()

  return (
    <div className="flex justify-center mt-5">
      <ClearFiltersButton textSize="sm" onClick={() => clearFilters()} />
    </div>
  )
}
