import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import { Routes } from 'app/routes'
import { AIShoppingStep } from 'domain/createOrder/types'
import { Empty } from 'ui'
import { useHasFilters } from '../state/filters'

export default function EmptyModels() {
  const history = useHistory()
  const hasFilter = useHasFilters()

  return (
    <Empty
      heading={hasFilter ? 'Nenhum modelo encontrado' : 'Ainda não há modelos criados'}
      description={
        hasFilter
          ? 'Nenhum modelo foi encontrado com esses parâmetros de busca.'
          : 'Escolha quais campos quer que a IA extraia dos documentos'
      }
    >
      <Button
        type="primary"
        size="large"
        onClick={() => history.push(Routes.CreateOrderAI(AIShoppingStep.MODELO))}
      >
        Criar Modelo
      </Button>
    </Empty>
  )
}
