import React from 'react'

import { useServiceById } from 'domain/servicesCBRdoc'
import { GoBackButton } from 'layouts'
import { ActionsBar } from 'ui'
import { useCreateOrderState } from '../state'
import CreateOrderBySpreadsheet from './CreateOrderBySpreadsheet'

function CreateOrderActionBar() {
  const { selectedService } = useCreateOrderState()
  const service = useServiceById(selectedService.id)
  const hasSpreadSheet = service?.hasSpreadsheet && selectedService.categoryId

  return (
    <ActionsBar short>
      <GoBackButton />
      {!!hasSpreadSheet && <CreateOrderBySpreadsheet />}
    </ActionsBar>
  )
}

export default CreateOrderActionBar
