import React from 'react'
import ViewPDF from './ViewPDF'

interface Props {
  fileUrl: string
  fileName?: string
  children: React.ReactNode
}

function PDFComparison({ fileUrl, fileName, children }: Props) {
  return (
    <div className="flex-1 flex flex-col gap-4 lg:gap-0 lg:flex-row lg:grid lg:grid-cols-2">
      <div className="flex">
        <ViewPDF fileUrl={fileUrl} fileName={fileName} />
      </div>

      <div className="px-4 pb-4">{children}</div>
    </div>
  )
}

export default PDFComparison
