import React from 'react'
import { Form, Input } from 'antd'
import { TextAreaProps } from 'antd/lib/input'
import { Rule } from 'antd/lib/form'

export interface TextAreaInputProps extends Omit<TextAreaProps, 'name'> {
  name?: string | (string | number)[]
  label?: React.ReactNode
  suffix?: React.ReactNode
  required?: boolean
  validateFirst?: boolean
  rules?: Rule[]
}

export default function TextAreaInput({
  name,
  label,
  required,
  rows,
  rules = required ? requiredRule : undefined,
  validateFirst,
  ...props
}: TextAreaInputProps) {
  return (
    <Form.Item name={name} label={label} rules={rules} validateFirst={validateFirst}>
      <Input.TextArea
        autoSize={!rows}
        rows={rows}
        data-hj-allow
        {...props}
        classNames={{
          count: '-bottom-6',
        }}
      />
    </Form.Item>
  )
}

const requiredRule = [{ required: true, message: 'Campo obrigatório.' }]
