import React from 'react'
import { Spin } from 'antd'

import { useOrdersStatisticsQuery } from 'domain/orders/services'
import { OrderStatuses, StatusCode } from 'domain/orders/types'
import { DashboardCard } from 'ui'

import OrdersDashboardTotal from './OrdersDashboardTotal'

interface Props {
  extra: React.ReactNode
}

export default function OrdersDashboard({ extra }: Props) {
  const { data, isFetching } = useOrdersStatisticsQuery()

  return (
    <Spin spinning={isFetching}>
      <div className="hidden lg:flex flex-row gap-4 justify-between itens-stretch w-full h-14 my-4">
        <OrdersDashboardTotal statistics={data} />

        {statusToShow.map(statusCode => {
          const status = OrderStatuses[statusCode]
          const statistic = data?.find(o => o.status === statusCode)

          return (
            <DashboardCard
              key={statusCode}
              text={status.name}
              statistic={statistic?.count}
              statisticColor={status.colorHexa}
            />
          )
        })}
        {extra}
      </div>
    </Spin>
  )
}

const statusToShow: StatusCode[] = [
  StatusCode.PROCESSANDO,
  StatusCode.FINALIZADO,
  StatusCode.ACAO_NECESSARIA,
  StatusCode.CANCELADO,
]
