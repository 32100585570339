import React from 'react'

import { LatestNotification, Notification, NotificationType as Type } from '../types'
import NotificationText from './NotificationText'
import LinkToOrderItem from './LinkToOrderItem'
import LinkToOrder from './LinkToOrder'
import NotificationExpired from './NotificationExpired'

interface Props {
  notification: LatestNotification | Notification
  shortenContentText?: boolean
  noActions?: boolean
}

function NotificationContent({ notification, noActions, shortenContentText }: Props) {
  const { type } = notification

  if (type === Type.INFORMATION || type === Type.UNAVAILABILITY_WARNING) {
    return <NotificationText shortenText={shortenContentText} text={notification.text!} linkUrl={notification.linkUrl} />
  }

  if (type === Type.ORDER_FINALIZED) {
    return <LinkToOrder order={notification.order!} />
  }

  if (type === Type.CERTIFICATE_EXPIRED) {
    return <NotificationExpired orderItem={notification.orderItem!} noActions={noActions} />
  }

  if (notification.orderItem) {
    return <LinkToOrderItem orderItem={notification.orderItem!} />
  }

  return null
}

export default NotificationContent
