import { Route, useRouteMatch } from 'react-router-dom'

import {
  CreateOrderFlow,
  CreateOrderFlowProps,
  MultipleItemsSidebar,
  SelectLocationStep,
  UploadStep,
} from 'domain/createOrder/document'

import CreateOrderTypeSelect from './CreateOrderTypeSelect'
import SigneesStep from './SigneesStep'

interface Props extends Omit<CreateOrderFlowProps, 'locationStep' | 'sidebar'> {
  header?: React.ReactNode
}

export default function DocRegistrationFlow(props: Props) {
  const { path } = useRouteMatch()

  return (
    <CreateOrderFlow
      sidebar={<MultipleItemsSidebar />}
      locationStep={
        <SelectLocationStep
          sectionTitle="Tipo de Registro"
          header={
            <>
              <p className="text-gray-700 mb-5">
                Escolha a opção abaixo que melhor se adequa à situação do seu documento:
              </p>

              <CreateOrderTypeSelect />
            </>
          }
          autoFocus={false}
        />
      }      
      uploadStep={<UploadStep header={<p className="mb-4">Cada arquivo irá gerar um pedido diferente.</p>} />}
      {...props}
    >
      <Route path={`${path}/assinantes`} exact>
        <div className="lg:max-w-xl p-7">
          <SigneesStep />
        </div>
      </Route>
    </CreateOrderFlow>
  )
}
