export const STATES_SIMULATOR = [
  // {code: 'brasil', name: 'em todo o Brasil'},
  { code: 'AC', name: 'no Acre (AC)' },
  { code: 'AL', name: 'em Alagoas (AL)' },
  { code: 'AP', name: 'no Amapá (AP)' },
  { code: 'AM', name: 'no Amazonas (AM)' },
  { code: 'BA', name: 'na Bahia (BA)' },
  { code: 'CE', name: 'no Ceará (CE)' },
  { code: 'DF', name: 'no Distrito Federal (DF)' },
  { code: 'ES', name: 'no Espírito Santo (ES)' },
  { code: 'GO', name: 'em Goiás (GO)' },
  { code: 'MA', name: 'no Maranhão (MA)' },
  { code: 'MT', name: 'no Mato Grosso (MT)' },
  { code: 'MS', name: 'no Mato Grosso do Sul (MS)' },
  { code: 'MG', name: 'em Minas Gerais (MG)' },
  { code: 'PA', name: 'no Pará (PA)' },
  { code: 'PB', name: 'na Paraíba (PB)' },
  { code: 'PR', name: 'no Paraná (PR)' },
  { code: 'PE', name: 'em Pernambuco (PE)' },
  { code: 'PI', name: 'no Piauí (PI)' },
  { code: 'RJ', name: 'no Rio de Janeiro (RJ)' },
  { code: 'RN', name: 'no Rio Grande do Norte (RN)' },
  { code: 'RS', name: 'no Rio Grande do Sul (RS)' },
  { code: 'RO', name: 'em Rondônia (RO)' },
  { code: 'RR', name: 'em Roraíma (RR)' },
  { code: 'SC', name: 'em Santa Catarina (SC)' },
  { code: 'SP', name: 'em São Paulo (SP)' },
  { code: 'SE', name: 'no Sergipe (SE)' },
  { code: 'TO', name: 'no Tocantins (TO)' },
]