import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { DeliveryFormat, SelectedService } from 'domain/createOrder/types'
import { OrderItemDetails } from 'domain/orderItem/types'

export default async function getAvailableFormats(
  service: SelectedService,
  params: OrderItemDetails | null
) {
  const details = params ? { detailed_service_data: params } : undefined

  const response: AxiosResponse<DeliveryFormat[]> = await AuthorizedAPI.post(
    `/services/${service.id}/categories/${service.categoryId}/available-formats`,
    details
  )
  return response.data
}
