import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaIndicadoresAtividadesFields= Record<
  FieldName.CNPJ,
  FieldConfig
>

class PesquisaIndicadoresAtividades extends Research {
  formFields: PesquisaIndicadoresAtividadesFields = {
    cnpj: Fields.cnpj,
  }

  constructor() {
    super({
      id: 119,
      shortName: 'Pesquisa de Indicadores de Atividade',
      description: 'Retorna quantidade de funcionários e a receita estimada de um CNPJ.',
      legalEntity: 'juridica',
    })
  }
}

const service = new PesquisaIndicadoresAtividades().initialize()

export default service
