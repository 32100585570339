import React from 'react'

type Props = {
  className?: string
  disabled?: boolean
  label: React.ReactNode
} & React.ButtonHTMLAttributes<HTMLButtonElement>

function TextButton({ className = '', label, disabled, ...props }: Props, ref: any) {
  return (
    <button
      ref={ref}
      type="button"
      className={`bg-transparent text-primary hover:text-primary/80 font-display 
        transition-colors duration-200 ease-in-out ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} 
        ${className}`}
      disabled={disabled}
      {...props}
    >
      {label}
    </button>
  )
}

export default React.forwardRef(TextButton)
