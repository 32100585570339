import { useMemo } from 'react'

import { CertificateFlow } from 'domain/createOrder/certificates'
import { createOrderActions, SelectLocationFields, useCreateOrderState } from 'domain/createOrder/document'
import { LocationTextInput, RadioGroupField, TextAreaField, UploadField } from 'domain/servicesCBRdoc/fields'

import config from '../certidaoAtasNotariaisConfig'
import { TempFile } from 'domain/app/types'
import { useDispatch } from 'react-redux'

function CertidaoAtasNotariais() {
  return (
    <CertificateFlow renderFormItem={(itemId: number) => <CertidaoAtasNotariaisForm itemId={itemId} />} />
  )
}

const CertidaoAtasNotariaisForm = ({ itemId }: { itemId: number }) => {
  const { formData, files } = useCreateOrderState()
  const dispatch = useDispatch()

  const isInPersonDueDiligence = useMemo(() => {
    const item = formData[itemId]

    if (item) {
      return item[tipo_servico.name] === 'diligencia-presencial-do-tabeliao'
    }

    return false
  }, [formData])

  function handleUploadFile(files: TempFile[]) {
    dispatch(createOrderActions.updateFormData({
      arquivos: files?.map(f => f.fileUploadId)
    }))
    dispatch(createOrderActions.setFiles(files))
  }

  return (
    <>
      <RadioGroupField field={tipo_servico} itemId={itemId} required vertical />
      
      {isInPersonDueDiligence && (
        <LocationTextInput field={local_servico} itemId={itemId} required={isInPersonDueDiligence} />
      )}

      <SelectLocationFields
        required={false}
        requiredUrlUf
        requiredUrlCidade={isInPersonDueDiligence}
        requiredUrlCartorio={isInPersonDueDiligence}
      />

      <TextAreaField field={mensagem} itemId={itemId} />

      <UploadField files={files} field={arquivos} onChange={handleUploadFile} required />

    </>
  )
}

const { arquivos, tipo_servico, mensagem, local_servico } = config.formFields

export default CertidaoAtasNotariais
