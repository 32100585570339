import { ServiceCard } from 'domain/createOrder/shared'
import { KitItem } from 'domain/kits/types'
import { getDisplayLocation } from 'domain/orders/helpers'

import { useGetServiceForm } from '../state'
import RemoveDocumentButton from './kitEdition/RemoveDocumentButton'

interface Props {
  kitItem: KitItem
}

export default function KitOrderPreviewCard({ kitItem }: Props) {
  const { id, service } = kitItem
  const { url_cartorio, url_cidade, url_uf } = useGetServiceForm(id)

  if (!service) return null

  const location = getDisplayLocation({ url_cartorio, url_cidade, url_uf })

  return (
    <ServiceCard
      serviceName={
        <a className="no-underline text-secondary-500 hover:text-gray-700" href={`#${service.code}_${id}`}>
          {service.name}
        </a>
      }
      extra={<RemoveDocumentButton kitItemId={id} />}
      className="border-b border-gray-300 mb-3 pb-3 group"
    >
      {!!location && <p className="text-gray-500 text-sm">{location}</p>}
    </ServiceCard>
  )
}
