import { Form, Radio } from 'antd'

import { requiredRule } from 'app/config/validationRules'
import { getFieldName } from 'domain/createOrder/shared'
import { Fields } from 'domain/servicesCBRdoc/config'
import { RadioMultiline } from 'ui'
import useSelectDefaultOption from '../helpers/useSelectDefaultOption'

export interface ResearchTimeRadioProps {
  options?: number[]
  description?: string
  required?: boolean
  onChange?: (time: number) => void
  selected?: number
  itemId?: number
}

export default function ResearchTimeField({
  description = 'pesquisa',
  itemId,
  onChange,
  selected,
  options = field.options as number[],
  required = !field.optional,
}: ResearchTimeRadioProps) {
  const name = getFieldName(field.name, itemId)
  const handleChange = onChange ? (e: any) => onChange(e.target.value) : undefined

  useSelectDefaultOption<number>({ field, name, onChange, availableOptions: options })

  return (
    <Form.Item label={field.label} name={name} rules={required ? requiredRule : undefined}>
      <Radio.Group onChange={handleChange} className="mt-1">
        {options?.map((time: number) => (
          <RadioMultiline
            key={time}
            value={time}
            checked={selected === time}
            label={`${time} anos`}
            description={`Tempo de ${description} de ${time} anos`}
          />
        ))}
      </Radio.Group>
    </Form.Item>
  )
}

const field = Fields.tempo_pesquisa
