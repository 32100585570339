import React, { useRef } from 'react'

import { useHasFilters } from 'domain/notifications/state'
import { Notification } from 'domain/notifications/types'
import { Empty } from 'ui'
import EmptyNotification from 'assets/images/empty-state-notification.png'

import NotificationFilters from './NotificationFilters'
import NotificationsMassActions from './NotificationsMassActions'
import NotificationsPagination from './NotificationsPagination'
import NotificationsTable from './NotificationsTable'

interface Props {
  notifications: Notification[] | undefined
  loading: boolean
}

function Notifications({ notifications, loading }: Props) {
  const hasFilters = useHasFilters()

  if ((!notifications || notifications.length === 0) && !hasFilters) {
    return <NotificationsEmpty />
  }

  return <NotificationsContent notifications={notifications} loading={loading} />
}

function NotificationsContent({ notifications, loading }: Props) {
  const topRef = useRef<HTMLDivElement>(null)
  const hasResults = notifications && notifications.length > 0

  return (
    <div className="px-7" ref={topRef}>
      {!hasResults && (
        <div className="flex flex-row flex-wrap justify-between items-center w-full bg-white rounded-t">
          <div className="flex flex-row gap-3 justify-start items-center px-4 pt-4">
            <NotificationFilters />
          </div>
        </div>
      )}

      {hasResults ? (
        <>
          <NotificationsTable notifications={notifications!} loading={loading} />
          <NotificationsPagination topRef={topRef} />
          <NotificationsMassActions />
        </>
      ) : (
        <NotificationsEmpty />
      )}
    </div>
  )
}

function NotificationsEmpty() {

  return (
    <Empty
      heading="Você ainda não tem notificações"
      description="Volte mais tarde para conferir as atualizações ou continue explorando nossos recursos."
      image={EmptyNotification}
    />
  )
}

export default Notifications
