import React from 'react'

import { Alert, Icon } from 'ui'
import { formatMoney } from 'utils/formatters'

interface Props {
  bonusAmount: number
  isGranted?: boolean
}

function BonusAlert({ bonusAmount, isGranted }: Props) {
  return (
    <Alert
      type="info"
      icon={<Icon name="award" />}
      className="max-w-md"
      message={
        <>
          {isGranted ? 'Oba! Você ganhou um bônus de' : 'Ao pagar sua compra você receberá um bônus de'}{' '}
          <span className="text-success-500">
            {formatMoney(bonusAmount)} para utilizar os serviços da CBRdoc.
          </span>
        </>
      }
    />
  )
}

export default BonusAlert
