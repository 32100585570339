import React from 'react'

import { RoutePaths } from 'app/routes'
import { Spreadsheet } from 'domain/createOrder/spreadsheet'
import { useCreateOrderState } from 'domain/createOrder/document/state'
import { useServiceById } from 'domain/servicesCBRdoc'

interface Props {
  open: boolean
  toggleOpen: React.DispatchWithoutAction
}

function CreateOrderBySpreadsheetModal({ open, toggleOpen }: Props) {
  const { selectedService } = useCreateOrderState()
  const serviceId = selectedService.id
  const service = useServiceById(serviceId)

  return (
    <Spreadsheet
      open={open}
      toggleOpen={toggleOpen}
      serviceId={serviceId}
      canAutoOrderPositiveResults={service?.canAutoPurchaseFromPositiveResearchResult}
      canAutoOrderNegativeResults={service?.canAutoPurchaseFromNegativeResearchResult}
      categoryId={selectedService.categoryId}
      downloadUrl={`${RoutePaths.DOWNLOAD_SPREADSHEET_SERVICE}/${serviceId}`}
      downloadFileName={`cbrdoc-${selectedService.code}.xlsx`}
    />
  )
}

export default CreateOrderBySpreadsheetModal
