import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { ServiceAPI, ServiceType } from 'domain/servicesCBRdoc/types'

function useCertificatesQuery() {
  return useQuery(
    ['services', 'certificates'],
    async () => {
      const response: AxiosResponse<ServiceAPI[]> = await AuthorizedAPI.get('services', {
        params: {
          'filter[type]': ServiceType.CERTIFICATE,
          sort: 'name',
        },
      })
      return response.data
    },
    {
      cacheTime: 30 * 60 * 1000, // 30 minutos
    }
  )
}

export default useCertificatesQuery
