import { stream } from 'exceljs'
import React from 'react'

import CardViewItem from './CardViewItem'

interface Props { 
  children: React.ReactNode 
  variant?: 'normal' | 'selected'
}

function CardView({ children, variant = 'normal' }: Props) {
  return (
    <div className={`card flex-1 mb-5 rounded ${CardVariants[variant]}`}>
      <ul className="px-5">{children}</ul>
    </div>
  )
}

const CardVariants = {
  normal: 'bg-white',
  selected: 'bg-blue-50 border-2 border-primary'
}

CardView.Item = CardViewItem

export default CardView
