import { useState } from 'react'

import { GroupsAssociationModal } from 'domain/groups'
import { useAssociateExplorerGroups } from 'domain/myFiles/services'
import { useActionState, useGetExplorerItem } from 'domain/myFiles/state'

export default function ExplorerGroupsAssociationInline() {
  const explorerItem = useGetExplorerItem()
  const [action, setAction] = useActionState()
  const { associateGroups, loading } = useAssociateExplorerGroups(explorerItem?.id!)
  const [groups, setGroups] = useState(explorerItem?.groups ?? [])

  return (
    <GroupsAssociationModal
      selectedGroups={groups}
      setSelectedGroups={setGroups}
      open={action === 'tags'}
      onClose={() => setAction(undefined)}
      onFinish={associateGroups}
      loading={loading}
    />
  )
}
