import React, { useReducer, useState } from 'react'
import { Button } from 'antd'

import { useRefreshCustomerCredits } from 'domain/credits'
import { Modal } from 'ui'

import RequestRefundForm from './RequestRedundForm'
import RequestRefundDone from './RequestRefundDone'
import { useViewOrderItem } from '../../state/viewOrderItem'

export default function RequestRefund() {
  const [{ id, price, refundValue, canAskRefund }, refreshOrderItem] = useViewOrderItem()
  const [showModal, toggleModal] = useReducer(prev => !prev, false)
  const [refundRequested, setRefundRequested] = useState(false)
  const refreshCredits = useRefreshCustomerCredits()

  const refundRequestedSuccess = () => {
    setRefundRequested(true)
    refreshOrderItem()
    refreshCredits()
  }

  const setRefundAskedAndCloseModal = () => {
    refreshOrderItem()
    toggleModal()
  }

  if (!canAskRefund) return null

  return (
    <>
      <Button className="rounded-md" type="primary" ghost block disabled={showModal} onClick={toggleModal}>
        Solicitar reembolso
      </Button>

      <Modal
        open={showModal}
        title="Solicitar reembolso"
        onCancel={refundRequested ? setRefundAskedAndCloseModal : toggleModal}
        destroyOnClose
      >
        {refundRequested ? (
          <RequestRefundDone
            action={
              <Button type="primary" onClick={setRefundAskedAndCloseModal} block>
                OK
              </Button>
            }
          />
        ) : (
          <RequestRefundForm
            orderItemId={id}
            refundValue={refundValue}
            orderValue={price}
            onSuccess={refundRequestedSuccess}
          />
        )}
      </Modal>
    </>
  )
}
