import {
  CreateOrderLegalEntityTabs,
  OrderNameField,
  useCreateOrderState,
} from 'domain/createOrder/document'
import { CNPJAndNameFields, DateField, NameField, TextField, YearField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../certidaoInterdicaoConfig'
import PlaceOfBirthFields from './PlaceOfBirthFields'

interface Props {
  itemId: number
}

const CreateOrderCertidaoInterdicaoForm = ({ itemId }: Props) => {
  const { selectedLocation } = useCreateOrderState()

  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={
          <>
            <CPFInput itemId={itemId} autoFocus={itemId === 0} />
            <NameField itemId={itemId} field={nome_pf} />
            <NameField itemId={itemId} field={mae} />
            <NameField itemId={itemId} field={pai} />
            <DateField itemId={itemId} field={nascimento} />
            <PlaceOfBirthFields itemId={itemId} />
            <TextField itemId={itemId} field={rg} />
            <TextField itemId={itemId} field={rg_expedidor} />
          </>
        }
        juridica={
          config.hasPJ(selectedLocation) ? (
            <CNPJAndNameFields itemId={itemId} companyField={nome_pj} autoFocus={itemId === 0} />
          ) : null
        }
      />

      <OrderNameField itemId={itemId} />

      <h2 className="uppercase">Dados complementares</h2>

      <hr className="my-5" />

      <YearField itemId={itemId} field={ano_aproximado_ato} />
    </>
  )
}

const { ano_aproximado_ato, mae, nascimento, nome_pf, nome_pj, pai, rg, rg_expedidor } = config.formFields

export default CreateOrderCertidaoInterdicaoForm
