import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { useCurrentUser } from 'domain/auth'
import { InvoiceStat } from 'domain/invoices/types'
import { getMonthNumber } from 'utils/dateTime'

function usePostpaidSummaryQuery(selectedMonth: Date) {
  const authState = useCurrentUser()
  const currentMonth = getMonthNumber(selectedMonth)

  return useQuery(['invoices-statistics', authState, selectedMonth], async () => {
    const response: AxiosResponse<InvoiceStat> = await AuthorizedAPI.get(
      `/invoice-postpaids/${selectedMonth.getFullYear()}/${currentMonth}/stats`
    )
    return response.data
  })
}

export default usePostpaidSummaryQuery
