import { MaskType } from 'antd-mask-input/build/main/lib/MaskedInput'

import { LabelInValue } from 'domain/app/types'
import { CreateOrderFormData, CreateOrderState } from 'domain/createOrder/types'
import { ExtraInfo } from 'domain/orderItem/types'

export const orderDetailsLabels = {
  rg: 'RG',
  cib: 'CIB',
  mae: 'Nome da mãe',
  pai: 'Nome do pai',
  cpf: 'CPF',
  cnpj: 'CNPJ',
  nome: 'Nome',
  livro: 'Livro',
  termo: 'Termo',
  url_uf: 'Estado',
  pagina: 'Página',
  genero: 'Gênero',
  mensagem: 'Mensagem',
  conjuge1: 'Cônjuge 1',
  conjuge2: 'Cônjuge 2',
  inscricao: 'Inscrição',
  casamento: 'Data do casamento',
  nascimento: 'Data de nascimento',
  preferencia: 'Preferências',
  razao_social: 'Razão social',
  local_cidade: 'Cidade',
  local_servico: 'Localização',
  tipo_processo: 'Tipo de processo',
  local_cartorio: 'Cartório',
  numero_processo: 'Número do processo',
  rg_data_expedicao: 'Data de expedição',
  ano_aproximado_ato: 'Ano aproximado do ato',
  descritivo_documento: 'Descritivo do documento',
  solicitacao_automatica: 'Solicitação automática',
} as any

export enum FieldName {
  AIIM = 'aiim',
  ARQUIVOS = 'arquivos',
  ANO_APROX_ATO = 'ano_aproximado_ato',
  ANO_BASE = 'ano_base',
  ARQUIVO_NOME_ORIGINAL = 'arquivo_nome_original',
  ASSINANTES = 'assinantes',
  BUSCA_POR = 'buscar_por',
  CAR = 'car', // Cadastro Ambiental Rural
  CAEPF = 'caepf',
  CDA = 'cda',
  CEI = 'cei',
  CEP = 'cep',
  CIDADE_NASCIMENTO_IBGE = 'cidade_nascimento',
  CIB = 'cib',
  CPF = 'cpf',
  CNPJ = 'cnpj',
  CODIGO_IMOVEL_RURAL = 'codigo_imovel',
  CONDOMINIO = 'condominio',
  CONJUGE1 = 'conjuge1',
  CONJUGE2 = 'conjuge2',
  CONJUGE1_CPF = 'conjuge1_cpf',
  CONJUGE2_CPF = 'conjuge2_cpf',
  COMARCA = 'comarca',
  COMPLEMENTO = 'complemento',
  CREA = 'crea',
  DATA_ATO = 'data_ato',
  DATA_BASE = 'data_base',
  DATA_CASAMENTO = 'casamento',
  DATA_EMISSAO = 'data_emissao',
  DATA_NASCIMENTO = 'nascimento',
  DATA_OBITO = 'obito',
  DATA_REGISTRO = 'data_registro',
  DATA_TITULO = 'data_titulo',
  EMAIL = 'email',
  ETIQUETA = 'etiqueta',
  DESCRITIVO_DOCUMENTO = 'descritivo_documento',
  DOCUMENTO = 'documento',
  ENDERECO_IMOVEL = 'endereco',
  ENDERECO_LOGRADOURO = 'endereco_logradouro',
  ENDERECO_NUMERO = 'endereco_numero',
  ENDERECO_COMPLEMENTO = 'endereco_complemento',
  ENDERECO_BAIRRO = 'endereco_bairro',
  ENDERECO_CIDADE = 'endereco_cidade',
  ENDERECO_ESTADO = 'endereco_estado',
  ESTADO_CIVIL = 'estado_civil',
  FILTRO = 'filtro',
  FORMATO = 'formato',
  GENERO = 'genero',
  GRUPO = 'grupo',
  INSCRICAO = 'inscricao',
  INSCRICAO_CADASTRAL = 'inscricao_cadastral',
  INSCRICAO_ESTADUAL = 'inscricao_estadual',
  INSCRICAO_IMOVEL = 'inscricao_imovel',
  INSCRICAO_MERCANTIL = 'inscricao_mercantil',
  INSCRICAO_MUNICIPAL = 'inscricao_municipal',
  INSTANCIA = 'instancia',
  IPTU = 'iptu',
  LIVRO = 'livro',
  LOCAL_SERVICO = 'local_servico',
  MATRICULA = 'matricula',
  MENSAGEM = 'mensagem',
  MODELO = 'modelo',
  MODELO_IA = 'modelo_ia_id',
  MOTIVO_SOLICITACAO = 'motivo_solicitacao',
  NACIONALIDADE = 'nacionalidade',
  NAO_SEI_LIVRO_PAGINA = 'nao_sei_livro_pagina',
  NATURALIDADE = 'naturalidade',
  NATUREZA = 'natureza',
  NATUREZA_JURIDICA = 'natureza_juridica',
  NIRF = 'nirf',
  NOME = 'nome',
  NOME_MAE = 'mae',
  NOME_PAI = 'pai',
  NOME_PF = 'nome_pf',
  NOME_PJ = 'nome_pj',
  NOME_PROPRIEDADE = 'nome_propriedade',
  NOME_ITEM_PEDIDO = 'name',
  NUMERO_ATO = 'numero_ato',
  NUMERO_PROCESSO = 'numero_processo',
  NUMERO_REGISTRO = 'numero_registro',
  NUMERO_RIP = 'numero_rip',
  NUMERO_TITULO = 'numero_titulo',
  OBSERVACAO = 'observacao',
  ORGAO = 'orgao',
  ORGAO_EMISSOR = 'orgao_emissor',
  PAGINA = 'pagina',
  PLACA = 'placa',
  PREFERENCIA = 'preferencia',
  PROTOCOLO = 'protocolo',
  QUESITO = 'quesito',
  RAZAO_SOCIAL = 'razao_social',
  REGIAO = 'regiao',
  REGISTRADO_ENDERECO_CEP = 'registrado_endereco_cep',
  REGISTRADO_ENDERECO_COMPLETO = 'registrado_endereco_completo',
  REGISTRO = 'registro',
  REGISTRO_LIVRO3 = 'registro_livro3',
  RENAVAM = 'renavam',
  REPRESENTANTE_LEGAL = 'representante_legal',
  RG = 'rg',
  RG_DATA_EXPEDICAO = 'rg_data_expedicao',
  RG_EXPEDIDOR = 'rg_expedidor',
  RG_UF = 'rg_uf_expedicao',
  SITUACAO = 'situacao',
  TELEFONE = 'telefone',
  TEMPO_PESQUISA = 'tempo_pesquisa',
  TERMO = 'termo',
  TIPO = 'tipo',
  TIPO_CERTIDAO = 'tipo_certidao',
  TIPO_DOCUMENTO = 'tipo_documento',
  TIPO_FLUXO_ASSINATURAS = 'tipo_fluxo_assinaturas',
  TIPO_PESSOA = 'tipo_pessoa',
  TIPO_PROCESSO = 'tipo_processo',
  TIPO_SAFRA = 'tipo_safra',
  TIPO_SERVICO = 'tipo_servico',
  TITULO_ELEITOR = 'titulo_eleitor',
  TRANSCRICAO = 'transcricao',
  TODOS_CARTORIOS_CIDADE = 'todos_cartorios_cidade',
  UF = 'uf',
  UF_NASCIMENTO = 'uf_nascimento',
  UPLOAD = 'arquivo',
  URL_CIDADE = 'url_cidade',
  URL_CARTORIO = 'url_cartorio',
  URL_UF = 'url_uf',
}

export type StateKey = keyof Pick<
  CreateOrderState,
  | 'allNotariesSelected'
  | 'dontKnowBookPage'
  | 'selectedComarca'
  | 'selectedEntityType'
  | 'selectedInstance'
  | 'selectedModel'
  | 'selectedRegion'
  | 'selectedResearchTime'
  | 'selectedType'
>

export interface FieldConfig {
  label: string
  labelDescription?: string
  apiName: FieldName | string
  apiRemoveMask?: boolean
  apiExtraInfo?: ExtraInfo
  apiOptionsKey?: ExtraInfo
  apiOptionsLabelKey?: string
  apiOptionsValueKey?: string
  dataScope?: 'service' | 'registree'
  defaultValue?: any
  infoLabel?: string
  ignoreOnKitsAutomaticForm?: boolean
  ignoreOnKitsDetailsForm?: boolean
  kind?: FieldKind
  kitFieldEquivalent?: FieldName
  legalEntity?: 'fisica' | 'juridica'
  labelInValue?: boolean
  mask?: string | MaskType
  maxLength?: number
  minLength?: number
  multiple?: boolean
  name: FieldName
  optional?: boolean
  options?: LabelInValue[] | any[]
  orderNameText?: string
  placeholder?: string
  showIf?: (formData: CreateOrderFormData) => boolean
  stateKey?: StateKey
  value?: any
  validationRules?: Record<any, any>[]
  uploadMultiple?: boolean
  valueAsURL?: boolean
  vertical?: boolean
}

export type FieldKind =
  | 'car'
  | 'checkbox'
  | 'checkboxGroup'
  | 'cpfOrCnpj'
  | 'cpfOrCnpjAndName'
  | 'date'
  | 'dontKnowBookPage'
  | 'email'
  | 'ibgeCity'
  | 'licensePlate'
  | 'location'
  | 'multiline'
  | 'fullAddress'
  | 'name'
  | FieldName.NATUREZA_JURIDICA
  | 'number'
  | 'phone'
  | 'radio'
  | 'researchTime'
  | 'select'
  | 'text'
  | 'uf'
  | 'zipCode'
  | 'year'
  | 'upload'
  | 'splittable'
  | 'phone'

export type Agency = {
  url: string
  nome: string
  nomeOrgao?: string
}

export enum DynamicFieldName {
  NAME = 'nome',
  DOCUMENT = 'documento',
  MODEL = 'modelo',
  MOTHER = 'mae',
  BIRTHDAY = 'nascimento',
  GENDER = 'genero',
}

export type FieldConfigs = Partial<Record<FieldName, FieldConfig>>

export enum RegionType {
  REGIAO_1 = '1_regiao',
  REGIAO_2 = '2_regiao',
  REGIAO_3 = '3_regiao',
  REGIAO_4 = '4_regiao',
  REGIAO_5 = '5_regiao',
  TODAS_AS_REGIOES = 'todas-as-regioes',
}

export interface RegionSelect {
  label: string
  value: RegionType
}
