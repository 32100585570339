import { useReducer } from 'react'

import { AddBySpreadsheetButton } from 'ui'

import KitOrderBySpreadsheetModal_deprecated from './KitOrderBySpreadsheetModal_deprecated'
import { useGetKit } from '../state'

export default function KitOrderBySpreadsheet_deprecated() {
  const kit = useGetKit()
  const [isSpreadsheetModalVisible, toggleSpreadsheetModalOpen] = useReducer(prev => !prev, false)

  if (!kit || !kit.hasSpreadsheet) {
    return null
  }

  return (
    <>
      <AddBySpreadsheetButton onClick={toggleSpreadsheetModalOpen} />
      <KitOrderBySpreadsheetModal_deprecated
        open={isSpreadsheetModalVisible}
        toggleOpen={toggleSpreadsheetModalOpen}
      />
    </>
  )
}
