import { ViewOrderLayout, useGetOrderItem } from 'domain/orderItem/shared'
import { ViewPDF } from 'ui'
import ViewDocRegistrationActions from './ViewDocRegistrationActions'
import ViewDocRegistrationStatus from './ViewDocRegistrationStatus'

export default function ViewDocRegistration() {
  const { fileURL } = useGetOrderItem()

  return (
    <ViewOrderLayout downloadButton={<ViewDocRegistrationActions />}>
      <ViewDocRegistrationStatus>
        <div className="row flex flex-1 h-full">
          <ViewPDF fileUrl={fileURL!} />
        </div>
      </ViewDocRegistrationStatus>
    </ViewOrderLayout>
  )
}
