import { GenericError, MissingDataFromBackendError } from 'api/errors'
import { APP_VERSION } from 'app/constants'
import { MissingFieldsError } from 'domain/createOrder/shared/errors'
import { CreateOrder } from 'domain/createOrder/shared/services'
import { CreateOrderState, Origin } from 'domain/createOrder/types'
import { OrderItem, ResearchResult } from 'domain/orderItem/types'
import { ResultCode } from 'domain/orders/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { Certificate, Research } from 'domain/servicesCBRdoc/config'
import { FieldConfig } from 'domain/servicesCBRdoc/fields/types'
import { SelectedLocation, ServiceType } from 'domain/servicesCBRdoc/types'
import { validateDate } from 'utils/dateTime'

class ResearchResultOrder {
  private createOrder: CreateOrderState

  constructor(orderItem: OrderItem, result: any, resultCode: ResultCode | 'extractReport') {
    const research = getServiceConfig(orderItem.service.code) as Research
    //const isPositive = !result.resultado || result.resultado === ResultCode.POSITIVE
    //const resultCode = isPositive ? ResultCode.POSITIVE : ResultCode.NEGATIVE
    const settings = orderItem.researchResultsOrderSettings![resultCode]?.placeOrder

    if (!settings || (!settings.service.categoryId && !result.categoria_servico_id)) {
      throw new MissingDataFromBackendError(
        'Resultado de pesquisa sem dados suficientes para fazer o pedido.',
        '0x697'
      )
    }

    if (result.categoria_servico_id) {
      settings.service.categoryId = result.categoria_servico_id
    }

    const certificate = new CreateOrder(settings.service)
    const certificateConfig = certificate.service as Certificate

    if (!certificateConfig.extractResearchData) {
      throw new GenericError(
        'Verifique a configuração do serviço que está tentando utilizar!',
        '0x698'
      )
    }

    const resultData = certificateConfig.extractResearchData(result, resultCode as ResultCode, orderItem)

    const originalFormData = {
      ...settings.formData,
      ...resultData.formData,
      ...this.getLocation(result, orderItem, certificateConfig),
    }

    if (result.format) {
      originalFormData.formato = result.format
    }

    if ((result as ResearchResult).formato) {
      originalFormData.formato = (result as ResearchResult).formato
    }

    if (!originalFormData.formato) {
      originalFormData.formato = 'email'
    }

    const origin = {
      description: `${orderItem.service.type ?? 'research'} ${orderItem.id} v${APP_VERSION}`,
    } as Origin

    if (orderItem.service.type === ServiceType.RESEARCH) {
      origin.researchId = orderItem.id
    }

    if (orderItem.service.type === ServiceType.CERTIFICATE) {
      origin.certificateId = orderItem.id
    }

    const createOrder = certificate.generateCreateOrder(originalFormData, origin)

    if (research.checkMissingFieldsOnResults) {
      const missingFields = this.checkIfFormDataHasAllValues(certificateConfig, createOrder)

      if (missingFields.length > 0) {
        throw new MissingFieldsError([{ result, orderItem, fields: missingFields }])
      }
    }

    this.createOrder = createOrder
  }

  private checkIfFormDataHasAllValues = (
    { formFields, allowMultipleItems }: Certificate,
    createOrder: CreateOrderState
  ) => {
    const { formData, selectedEntityType } = createOrder
    const fieldsToCheck = Object.values(formFields)

    const missingFields = fieldsToCheck.filter(fieldConfig => {
      const formValue =
        allowMultipleItems && fieldConfig.dataScope !== 'service'
          ? formData['0'][fieldConfig.name]
          : formData[fieldConfig.name]
      const valueExistis = this.checkIfFormValueExists(formValue, fieldConfig)

      if (fieldConfig.optional || valueExistis) {
        return false
      }

      if (selectedEntityType && fieldConfig.legalEntity) {
        return fieldConfig.legalEntity === selectedEntityType && !valueExistis
      }

      return fieldConfig.defaultValue === undefined
    })

    return missingFields
  }

  private checkIfFormValueExists(formValue: any, fieldConfig: FieldConfig) {
    if (fieldConfig.kind === 'date') {
      return validateDate(formValue)
    }

    return formValue !== '' && formValue !== undefined
  }

  private getLocation = (
    result: ResearchResult,
    orderItem: OrderItem,
    certificateConfig: Certificate
  ): SelectedLocation => {
    const { formFields } = certificateConfig

    const federativeUnit = result.url_uf ?? orderItem.locationInfo.url_uf
    const city = result.url_cidade ?? orderItem.locationInfo.url_cidade
    const notary = result.url_cartorio ?? orderItem.locationInfo.url_cartorio

    return {
      url_uf: formFields.url_uf?.multiple ? [federativeUnit] : federativeUnit,
      url_cidade: formFields.url_cidade?.multiple ? [city] : city,
      url_cartorio: formFields.url_cartorio?.multiple ? [notary] : notary,
    }
  }

  generate = async (isPostPaid: boolean) => {
    const ordersWithPrice = await CreateOrder.appendPrice([this.createOrder], isPostPaid)
    return ordersWithPrice[0]
  }
}

export default ResearchResultOrder
