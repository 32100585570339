import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

import { atomURI } from 'utils/navigation'

const currentPageAtom = atom(1)
const currentPageUpdateURIAtom = atomURI(currentPageAtom, 'p')
const pageSize = atom(10)
const totalPages = atom(0)
const totalResults = atom(0)

export const useCurrentPage = () => useAtom(currentPageUpdateURIAtom)
export const useGetCurrentPage = () => useAtomValue(currentPageAtom)
export const useGetPageSize = () => useAtomValue(pageSize)
export const useGetTotalPages = () => useAtomValue(totalPages)
export const useGetTotalResults = () => useAtomValue(totalResults)
export const usePageSize = () => useAtom(pageSize)
export const useSetCurrentPage = () => useSetAtom(currentPageUpdateURIAtom)
export const useSetTotalPages = () => useSetAtom(totalPages)
export const useSetTotalResults = () => useSetAtom(totalResults)
