import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoINSSFields = Record<
  FieldName.CEI | FieldName.CNPJ,
  FieldConfig
>

class CertidaoINSS extends Certificate {
  formFields: CertidaoINSSFields = {
    cnpj: Fields.cnpj,
    cei: Fields.cei,
  }

  constructor() {
    super({
      id: 124,
      priceByFields: [FieldName.FORMATO],
      shortName: 'Certidão do INSS',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoINSS().initialize()

export default service
