import { UseExtraInformationQueryParams, useExtraInformationQuery } from 'domain/orderItem/shared'
import { checkParams } from 'utils/validators'
import { FieldConfig } from '../types'
import SelectField, { SelectFieldProps } from './SelectField'

export interface ExtraInfoSelectProps
  extends Omit<UseExtraInformationQueryParams<any>, 'information'>,
    Omit<SelectFieldProps, 'hasError' | 'information' | 'loading' | 'options'> {
  field: FieldConfig
}

export default function ExtraInfoSelect({ service, itemId, params, field, ...props }: ExtraInfoSelectProps) {
  const { data, isFetching, isError } = useExtraInformationQuery<any>({
    service,
    information: field.apiExtraInfo!,
    itemId,
    params,
  })

  const isEnabled = checkParams(service, params ?? [])

  if (!isEnabled) {
    return null
  }

  return (
    <SelectField
      field={field}
      itemId={itemId}
      options={data}
      loading={isFetching}
      hasError={isError}
      {...props}
    />
  )
}
