import React from 'react'

import Drawer, { DrawerProps } from './Drawer'
import DrawerActionButton from './DrawerActionButton'

export interface DrawerActionsProps extends DrawerProps {
  total?: number
}

export default function DrawerActions({
  total,
  open = total !== undefined && total > 0,
  children,
  className = '',
  height = 132,
  title,
  closeable = true,
  ...props
}: DrawerActionsProps) {
  return (
    <Drawer title={getDrawerTitle(total, title)} open={open} height={height} closeable={closeable} {...props}>
      <div className={`flex flex-row items-center gap-2 ${className}`}>{children}</div>
    </Drawer>
  )
}

DrawerActions.Button = DrawerActionButton

function getDrawerTitle(total?: number, title?: string) {
  if (total) {
    return total === 1 ? '1 Item selecionado' : `${total} Itens selecionados`
  }

  return title ? title : 'Ações'
}
