import React from 'react'
import { Button, Dropdown, message, Modal } from 'antd'
import { useHistory } from 'react-router-dom'

import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'
import { Routes } from 'app/routes'
import { useHasPermission } from 'domain/auth'
import { AICreateOrderModel } from 'domain/openAI/types'
import { Icon } from 'ui'
import { useDownload } from 'utils/export'

import ModelCard from './ModelCard'

interface Props {
  model: AICreateOrderModel
  checkbox: React.ReactElement
}

export default function MyModelCard({ model, checkbox }: Props) {
  const canManageAiModels = useHasPermission('canManageAiModels')
  const history = useHistory()
  const [download, loading] = useDownload()

  const items: any = [
    {
      label: 'Editar',
      key: 'edit',
      title: () => model.fixedSchema || model.hiddenSchema ? 'Este modelo não pode ser editado' : '',
      disabled: model.fixedSchema || model.hiddenSchema,
      onClick: () => {
        history.push(Routes.EditAIModel(model.id))
      },
    },
    {
      label: 'Exportar',
      key: 'export',
      onClick: () => download(`/reports/ai-answers/xlsx?ai_model_id=${model.id}`),
      disabled: loading,
    },
    {
      label: 'Excluir',
      className: 'text-red-500',
      key: 'delete',
      onClick: () => {
        Modal.confirm({
          title: (
            <>
              Tem certeza que deseja excluir o modelo <b>{model.modelName}</b>?
            </>
          ),
          content: (
            <p>
              <b>Documento:</b> {model.service?.label ?? model.documentName}
            </p>
          ),
          okText: 'Excluir',
          cancelText: 'Cancelar',
          onOk: async () => {
            await deleteModel(model.id)
            message.success('O modelo foi excluído.')
            queryClient.invalidateQueries(['ai-models'])
          },
        })
      },
    },
  ]

  return (
    <ModelCard model={model} checkbox={checkbox}>
      {canManageAiModels && (
        <Dropdown menu={{ items }} placement="bottomRight" className="text-primary max-h-8">
          <Button type="primary" ghost size="small" icon={<Icon name="ellipsis" />} />
        </Dropdown>
      )}
    </ModelCard>
  )
}

async function deleteModel(modelId: number) {
  await AuthorizedAPI.delete(`/ai-models/${modelId}`)
}
