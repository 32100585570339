import { NamePath } from 'antd/lib/form/interface'

import { getFieldName } from 'domain/createOrder/shared'
import { FullNameInput, TextInputProps } from 'ui'
import { FieldConfig } from '../types'

interface Props extends Omit<TextInputProps, 'label' | 'name' | 'rules'> {
  field: FieldConfig
  itemId?: number | string
  name?: string | (string | number)[]
}

export default function NameField({
  field,
  itemId,
  required = !field.optional,
  maxLength = field.maxLength,
  name = field.name,
  ...props
}: Props) {
  const inputName = Array.isArray(name) ? name : getFieldName(name, itemId)

  return (
    <FullNameInput
      label={field.label}
      name={inputName as NamePath}
      required={required}
      maxLength={maxLength}
      {...props}
    />
  )
}
