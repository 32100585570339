import { useMemo } from 'react'
import { Tag } from 'antd'

import { singularOrPlural } from 'utils/formatters'

import { useShoppingCartState } from '../state'

function CartItemsCountTag() {
  const {orders} = useShoppingCartState()
  const countItems = orders.length

  const label = useMemo(() => {
    if (countItems === 0) {
      return 'Vazio'
    }

    return singularOrPlural(countItems, 'Item', 'Itens')
  }, [countItems])

  return (
    <Tag className="px-4 text-2xs rounded-lg bg-secondary-300 text-white">
      {label}
    </Tag>
  )
}

export default CartItemsCountTag
