import { CreateOrderLegalEntityTabs } from 'domain/createOrder/document'
import { ResearchFlow } from 'domain/createOrder/researches'
import { CNPJAndNameFields, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../pesquisaEscrituraConfig'

export default function CreateOrderPesquisaEscritura() {
  return (
    <ResearchFlow
      header={
        <p className="mb-5 text-gray-600">
          Informe o CPF ou CNPJ que deseja pesquisar se possui escrituras (exceto para o estado de São Paulo).
        </p>
      }
      renderFormItem={(itemId: number) => <PesquisaEscrituraForm itemId={itemId} />}
    />
  )
}

function PesquisaEscrituraForm({ itemId }: { itemId: number }) {
  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={
          <>
            <NameField itemId={itemId} field={nome_pf} autoFocus={itemId === 0} />
            <CPFInput itemId={itemId} />
          </>
        }
        juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} autoFocus={itemId === 0} />}
      />
    </>
  )
}

const { nome_pf, nome_pj } = config.formFields
