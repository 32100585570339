import { RadioGroupButtonsField } from 'domain/servicesCBRdoc/fields'

import { useGetLegalEntity } from 'domain/createOrder/kit'
import config from '../certidaoSPUConfig'

interface Props {
  kitItemId: number
}

export default function KitOrderCertidaoSPUDocumentType({ kitItemId }: Props) {
  const legalEntity = useGetLegalEntity()

  return (
    <RadioGroupButtonsField
      itemId={kitItemId}
      field={tipo_documento}
      options={tipo_documento.options!.filter(
        option =>
          (option.value !== 'cnpj' || legalEntity === 'juridica') &&
          (option.value !== 'cpf' || legalEntity === 'fisica')
      )}
    />
  )
}

const { tipo_documento } = config.formFields
