import { Form } from 'antd'

import { useGetRegistreeForm } from 'domain/createOrder/kit'
import { getFieldName } from 'domain/createOrder/shared'
import { KitItem } from 'domain/kits/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { FullNameInput } from 'ui'

interface Props {
  kitItem: KitItem
}

export default function KitOrderPesquisaObito({ kitItem }: Props) {
  const selectedType = Form.useWatch(getFieldName(FieldName.TIPO, kitItem.id))
  const { nome_pf } = useGetRegistreeForm()

  if (nome_pf) return null

  return <FullNameInput name={[kitItem.id, FieldName.NOME_PF]} required={selectedType === 'completa'} />
}
