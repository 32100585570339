import { useReducer, useState } from 'react'

import useAssociateOrderItemGroups from '../../services/useAssociateOrderItemGroups'
import { GroupsAssociationModal } from 'domain/groups'
import { useGroupsFilterQuery } from 'domain/groups/services'
import { removeFromArrayById } from 'utils/transformData'
import { useOrderItem } from '../../state/viewOrderItem'
import { Select} from 'ui'
import { Group } from 'domain/groups/types'

function ViewOrderGroups() {
  const [isOpen, toggleIsOpen] = useReducer(prev => !prev, false)
  const [orderItem, setOrderItem] = useOrderItem()
  const { id, groups } = orderItem
  const [search, setSearch] = useState('')
  const [selectedGroups, setSelectedGroups] = useState<Group[]>(groups)
  const { data: selectableGroups } = useGroupsFilterQuery({ page: 1, search, setTotalResults: () => {} })
  const { associateGroups, loading } = useAssociateOrderItemGroups(id)
  
  const onSelectGroup = async (group: {value: number}) => {
    const newGroup = selectableGroups?.filter((groups) => groups.id === group.value)

    if (newGroup) {
      setSelectedGroups([...selectedGroups, ...newGroup])
      associateGroups([...selectedGroups, ...newGroup])
    }
  }

  const onDeleteGroup = (groupId: number) => {
    setOrderItem({ ...orderItem, groups: removeFromArrayById(groups, groupId) })
  }

  return (
    <>
      <p className="font-bold text-lg text-gray-700">
        Grupos
      </p>
      <p className="font-normal text-sm text-gray-700">
        Organize seus pedidos adicionando categorias ou centros de custo.
      </p>

      <Select
        size="middle"
        value={search}
        onSearch={setSearch}
        className="w-full rounded-none h-11 my-4 border-secondary-300"
        labelInValue
        placeholder="Selecione grupo"
        labelRender={() => <>Selecione grupo</>}
        optionValueKey="id"
        optionLabelKey="name"
        options={
          selectableGroups?.filter((group) =>
            !selectedGroups.find((selectedGroup) => selectedGroup.id === group.id)
          )
        }
        onSelect={(selected) => {
          setSearch('')
          onSelectGroup(selected)
        }}
      />

      <p onClick={toggleIsOpen} className="transition underline cursor-pointer text-primary hover:text-blue-300">
        Criar novo grupo
      </p>

      <GroupsAssociationModal
        open={isOpen}
        onClose={toggleIsOpen}
        loading={loading}
        onFinish={associateGroups}
        onDeleteGroup={onDeleteGroup}
        selectedGroups={selectedGroups ?? []}
        setSelectedGroups={setSelectedGroups}
      />
      
      {selectedGroups && (
        <div className="flex flex-row flex-wrap gap-2 overflow-x-clip">
          <div className="w-full font-semibold text-sm py-4 pb-2 text-gray-700">Grupos associados:</div>
          {selectedGroups.map(group => {
            return (
              <div
                key={group.id}
                style={{backgroundColor: group.color}}
                className="rounded-lg py-0.5 px-2 w-min text-sm text-white text-nowrap"
              >
                {group.name}
              </div>
            )
          })}
        </div>
      )}
    </>    
  )
}

export default ViewOrderGroups
