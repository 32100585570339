import { Form } from 'antd'
import { InputProps } from 'antd/lib/input'
import { cpf as cpfUtils, cnpj as CNPJUtils } from 'cpf-cnpj-validator'

import { formatCPFCNPJ, onlyNumbers } from 'utils/formatters'
import TextInput from './TextInput'

const MAX_INPUT_LENGTH = 18

interface Props extends Omit<InputProps, 'defaultVxalue' | 'name' | 'onChange' | 'rules' | 'value'> {
  name: string
  value?: string
  itemId?: number
}

export default function CPFOrCNPJInput({ name, itemId, ...props }: Props) {
  const form = Form.useFormInstance()
  const fieldName = itemId !== undefined ? [itemId, name] : name

  return (
    <TextInput
      name={fieldName}
      label="CPF ou CNPJ"
      maxLength={MAX_INPUT_LENGTH}
      rules={validationRules}
      onChange={e => {
        const eventValue = onlyNumbers(e.target.value)
        form.setFieldValue(fieldName, formatCPFCNPJ(eventValue))
      }}
      {...props}
    />
  )
}

const validationRules = [
  {
    max: MAX_INPUT_LENGTH,
    validator: (rule: object, value: string) => {
      if (onlyNumbers(value).length > 11) {
        return CNPJUtils.isValid(value, true) ? Promise.resolve() : Promise.reject('Informe um CNPJ válido.')
      }

      if (value && !cpfUtils.isValid(value, true)) {
        return Promise.reject('Informe um CPF válido.')
      }

      return Promise.resolve()
    },
  },
]
