import { PrintConfig } from 'domain/app/types'
import { PostpaidInvoice } from 'domain/invoices/types'
import { exportToPDF } from 'utils/export'

async function generatePostpaidPDF(selectedMonth: Date, invoices: PostpaidInvoice[]) {
  const body = invoices.map(invoice => ({
    ...invoice,
    orderName: invoice.orderItem.name,
    orderNumber: invoice.orderItem.orderItemNumber,
    userName: invoice.orderItem.user.name,
  }))
  const fileName = `Faturas-CBRDoc_${selectedMonth.getMonth() + 1}/${selectedMonth.getFullYear()}`

  exportToPDF({ body, columns, fileName, orientation: 'landscape', styles: { columnStyles } })
}

const columns: PrintConfig[] = [
  { header: 'Nome do pedido', dataKey: 'orderName' },
  { header: 'Nº do pedido', dataKey: 'orderNumber' },
  {
    header: 'Data do pedido',
    dataKey: 'placedAtDisplay',
  },
  { header: 'Nota fiscal', dataKey: 'fiscalAmountDisplay' },
  { header: 'Nota de débito', dataKey: 'debitAmountDisplay' },
  { header: 'Data de envio', dataKey: 'deliveredAtDisplay' },
  { header: 'Usuário', dataKey: 'userName' },
]

const columnStyles = {
  orderName: { cellWidth: 'auto', minCellWidth: 40 },
  orderNumber: { minCellWidth: 25 },
  placeAtDisplay: { minCellWidth: 25 },
  fiscalAmountDisplay: { minCellWidth: 30 },
  debitAmountDisplay: { minCellWidth: 30 },
  deliveredAtDisplay: { minCellWidth: 30 },
  userName: { cellWidth: 'wrap', minCellWidth: 20 },
}

export default generatePostpaidPDF
