import { captureException, captureMessage, withScope } from '@sentry/browser'

import { ErrorExtrasParams } from 'api/types'
import { PROD } from '../network/config'
import GenericError from './GenericError'
import ServiceNotAvailableError from './ServiceNotAvailableError'
import showErrorModal from './showErrorModal'

const BAD_REQUEST = 400
const FORBIDEN = 403
const UNAUTHORIZED = 401
const UNPROCESSABLE_CONTENT = 422
const DO_NOT_CAPTURE = [BAD_REQUEST, FORBIDEN, UNAUTHORIZED, UNPROCESSABLE_CONTENT]

export const errorHandler = (
  error: any,
  extras?: ErrorExtrasParams
) => {
  captureError(error, extras)
  showErrorModal(error, { ...extras, requestId: error.response?.headers?.['x-request-id'] })
}

export function captureError(error: any, extras: ErrorExtrasParams = {}) {
  const canIgnoreError = DO_NOT_CAPTURE.includes(error.response?.status ?? error.code)

  if (PROD) {
    if (!canIgnoreError && !(error instanceof ServiceNotAvailableError || error instanceof GenericError)) {
      captureErrorData(error.response, { ...extras, params: error.request?.responseText })
    }
  } else if (!canIgnoreError) {
    console.log(error)
  }
}

export function captureErrorData(err: any, extras: ErrorExtrasParams = {}) {
  if (PROD) {
    const { code, params } = extras

    withScope(function (scope) {
      if (params) {
        scope.setExtra('data', params)
      }

      if (code) {
        captureMessage(code)
      }

      captureException(err)
    })
  }
}
 
