import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { joinStrings } from 'utils/formatters'

const {
  BUSCA_POR,
  CEP,
  CONJUGE1_CPF,
  FORMATO,
  MATRICULA,
  NUMERO_REGISTRO,
  TIPO,
  URL_CARTORIO,
  URL_CIDADE,
  URL_UF,
} = FieldName

type CertidaoImovelFields = Record<
  FieldName.URL_CARTORIO |
  FieldName.URL_CARTORIO |
  FieldName.URL_CIDADE |
  FieldName.OBSERVACAO |
  FieldName.URL_CIDADE |
  FieldName.BUSCA_POR |
  FieldName.URL_UF |
  FieldName.URL_UF |
  FieldName.TIPO,
  FieldConfig
>

export type CertidaoImovelDynamicFields = Record<
  | FieldName.CEP
  | FieldName.CONDOMINIO
  | FieldName.CONJUGE1
  | FieldName.CONJUGE1_CPF
  | FieldName.CONJUGE2
  | FieldName.CONJUGE2_CPF
  | FieldName.DATA_CASAMENTO
  | FieldName.DATA_EMISSAO
  | FieldName.DOCUMENTO
  | FieldName.ENDERECO_IMOVEL
  | FieldName.LIVRO
  | FieldName.MATRICULA
  | FieldName.NOME
  | FieldName.NUMERO_ATO
  | FieldName.NUMERO_REGISTRO
  | FieldName.PROTOCOLO
  | FieldName.REGISTRO_LIVRO3
  | FieldName.TRANSCRICAO,
  FieldConfig
>

class CertidaoImovel extends Certificate {
  formFields: CertidaoImovelFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    url_cartorio: Fields.url_cartorio,
    buscar_por: {
      ...Fields.buscar_por,
      stateKey: 'selectedModel',
      ignoreOnKitsAutomaticForm: true,
      apiOptionsValueKey: 'value',
    },
    tipo: {
      ...Fields.tipo,
      kind: 'radio',
      apiOptionsLabelKey: 'name',
      ignoreOnKitsAutomaticForm: true,
      defaultValue: 'matricula',
      optional: false,
    },
    observacao: {
      ...Fields.observacao,
      apiName: 'observacoes',
    },
  }

  dynamicFields: CertidaoImovelDynamicFields = {
    matricula: {
      ...Fields.matricula,
      label: 'Matrícula(s)',
      orderNameText: 'Matrícula',
      placeholder: 'Informe uma matrícula por linha',
    },
    transcricao: {
      ...Fields.transcricao,
      maxLength: 18,
      optional: false,
    },
    cep: Fields.cep,
    endereco: Fields.endereco,
    condominio: Fields.condominio,
    numero_ato: {
      ...Fields.numero_ato,
      apiName: 'ato',
    },
    registro_livro3: Fields.registro_livro3,
    data_emissao: Fields.data_emissao,
    nome: Fields.nome,
    documento: {
      ...Fields.documento,
      kind: 'cpfOrCnpj',
    },
    protocolo: Fields.protocolo,
    livro: {
      ...Fields.livro,
      optional: false,
    },
    conjuge1: {
      ...Fields.conjuge1,
      apiName: 'conjuge1_nome',
    },
    conjuge1_cpf: Fields.conjuge1_cpf,
    conjuge2: {
      ...Fields.conjuge2,
      apiName: 'conjuge2_nome',
    },
    conjuge2_cpf: Fields.conjuge2_cpf,
    casamento: Fields.casamento,
    numero_registro: Fields.numero_registro,
  }

  constructor() {
    super({
      availableFormatsFields: [TIPO, URL_CARTORIO, URL_CIDADE, URL_UF],
      extraInfoFields: [BUSCA_POR, TIPO, URL_CARTORIO, URL_CIDADE, URL_UF],
      extraInfoRequiredFieldsOnly: true,
      id: 10,
      orderNameFieldMap: {
        endereco: CEP,
        registro_imovel: NUMERO_REGISTRO,
        nome_pactuantes: CONJUGE1_CPF,
      },
      orderNamePrefixField: BUSCA_POR,
      priceByFields: [FORMATO, TIPO, URL_CARTORIO, URL_CIDADE, URL_UF],
      shortName: 'Certidão Imóvel',
      splittableField: MATRICULA,
      splittableFieldInOrderName: true,
      steps: ['localizacao', 'dados', 'entrega'],
      extractedReportSections: [
        {
          title: 'Dados do imóvel',
          properties: [
            { label: 'Última atualização', key: 'ultima_atualizacao' },
            { label: 'Tipo de ativo', key: 'tipo_ativo' },
            { label: 'Sub-tipo de ativo', key: 'subtipo_ativo' },
            { label: 'Endereço', key: 'endereco' },
            { label: 'Cidade', key: 'cidade' },
            { label: 'Estado', key: 'unidade_federativa' },
            { label: 'Área', key: 'area' },
            { label: 'Valor do imóvel', key: 'valor' },
          ],
        },
        {
          title: 'Proprietários',
          properties: [
            { label: 'Pessoa / Empresa', key: 'nome', formatter: joinStrings, fromArray: 'proprietarios' },
            { label: 'CPF/CNPJ', key: 'documento', formatter: joinStrings, fromArray: 'proprietarios'  },
          ],
        },
        {
          title: 'Registros de ônus ativos',
          properties: [
            { label: 'Ônus ativos', key: 'gravames_ativos', formatter: joinStrings },
            { label: 'Ônus inativos', key: 'gravames_inativos', formatter: joinStrings },
          ],
        },
      ],
    })
  }
}

const service = new CertidaoImovel().initialize()

export default service
