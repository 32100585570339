import React from 'react'

import { PopupAlert } from 'ui'

function FreeAnalysisPopup({ position }: { position: 'top' | 'bottom' }) {
  return (
    <PopupAlert
      position={position}
      type="success"
      message="Esse pedido passou pelo Teste Gratuito de Extração de Dados dos documentos de forma automática! "
      description="Você pode visualizar nos detalhes dos documentos ou baixar nas Ações do Pedido e dos Documento.
Para mais detalhe, entrar em contato através do chat com nosso time de atendimento :)"
    />
  )
}

export default FreeAnalysisPopup
