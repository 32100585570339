import { useQuery } from 'react-query'

import { SelectedService } from 'domain/createOrder/types'
import { OrderItemDetails } from 'domain/orderItem/types'
import getAvailableFormats from './getAvailableFormats'
import { checkParams } from 'utils/validators'

export default function useAvailableFormatsQuery(
  service: SelectedService,
  params: OrderItemDetails | null,
  options: Record<any, any> = {}
) {
  const enabled = checkParams(service, params)

  return useQuery(
    [`available-formats-${service.id}`, service.categoryId, params],
    () => getAvailableFormats(service, params),
    {
      enabled,
      ...options,
    }
  )
}
