import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

import { atomURI } from 'utils/navigation'

const searchAtom = atom<string | undefined>(undefined)
const searchUpdateURIAtom = atomURI(searchAtom, 'q')

export const useSearch = () => useAtom(searchUpdateURIAtom)
export const useGetSearch = () => useAtomValue(searchUpdateURIAtom)
export const useSetSearch = () => useSetAtom(searchUpdateURIAtom)

export const useHasFilters = () => {
  const term = useAtom(searchAtom)
  return !!term
}
