import React from 'react'

import { KitItem as KitItemType } from 'domain/kits/types'
import KitItem from './KitItem'

interface Props {
  items: KitItemType[]
  kitName?: string
  deleteItems?: boolean
}

function KitItems({ items, kitName, deleteItems = false }: Props) {
  return (
    <>
      <p className="text-secondary-500 text-sm mb-4">Tem certeza que deseja {deleteItems ? 'excluir' : 'duplicar'} o kit <b>{kitName}</b>?</p>
      
      <div className="border border-secondary-500 rounded-sm">
        <div className="bg-gray-200">
          <h4 className="text-secondary-500 px-3 py-4 font-normal text-sm leading-5">{kitName}</h4>
        </div>

        <div className="px-3 divide-y divide-gray-200">
          {items.map((item, index) => (
            <div key={index} className="py-4">
              <KitItem item={item} serviceName={item.service?.name as string} className="mb-3" />
            </div>
          ))}
        </div>
        
      </div>
      
    </>
  )
}

export default KitItems
