import { CreateOrderState } from '../../types'

class MissingPriceParams extends Error {
  data: CreateOrderState

  constructor(createOrder: CreateOrderState) {
    super('Faltam campos para solicitar o serviço.')
    Object.setPrototypeOf(this, MissingPriceParams.prototype)

    this.message = 'Faltam dados para consultar o preço.'
    this.data = createOrder
  }
}

export default MissingPriceParams
