import React from 'react'
import { Dropdown, MenuProps } from 'antd'
import { useHistory } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { useHasPermission } from 'domain/auth'
import { useCurrentCustomer } from 'domain/customers'
import { Button, Icon } from 'ui'

import MyFilesActionsUpload from './MyFilesActionsUpload'

export default function MyFilesAdd() {
  const customer = useCurrentCustomer()
  const history = useHistory()
  const canOrderServices = useHasPermission('canOrderServices')
  const canUploadExplorerItems = useHasPermission('canUploadExplorerItems')
  const cannotOrderPermissionTitle = !canOrderServices ? 'Sem permissão para fazer pedidos' : undefined

  const dropdownItems: MenuProps['items'] = [
    {
      label: 'Solicitar documento',
      onClick: () => history.push(`${RoutePaths.CREATE_ORDER}/documentos`),
      key: '0',
      disabled: !canOrderServices,
      title: cannotOrderPermissionTitle,
    },
    {
      label: 'Fazer pesquisa',
      onClick: () => history.push(`${RoutePaths.CREATE_ORDER}/pesquisas`),
      key: '1',
      disabled: !canOrderServices,
      title: cannotOrderPermissionTitle,
    }
  ]

  if (customer.permissions.canUploadFiles) {
    dropdownItems.push({
      label: <MyFilesActionsUpload />,
      key: '2',
      disabled: !canUploadExplorerItems,
      title: !canUploadExplorerItems ? 'Sem permissão para fazer upload de arquivos' : undefined,
    })
  }

  return (
    <Dropdown placement="bottomRight" menu={{ items: dropdownItems }}>
      <Button
        type="primary"
        icon="plus-circle"
        iconSize={24}
        iconStroke={2}
        className="px-4 rounded min-w-44 max-h-[44px] font-semibold"
        iconClassName="mb-1"
      >
        Adicionar arquivo
      </Button>
    </Dropdown>
  )
}
