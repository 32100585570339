import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

import { Kit, KitItem } from 'domain/kits/types'

export const kitAtom = atom<Kit>({ items: [] as KitItem[] } as Kit)
kitAtom.debugLabel = 'createoOrder/kitAtom'

const writeKitAtom = atom(
  null,
  (get, set, value: Kit) => {
    const sortedKit = {
      ...value,
      items: value.items.sort((a: KitItem, b: KitItem) => {
        if (a.service.id < b.service.id) {
          return -1
        } else if (a.service.id > b.service.id) {
          return 1
        }
        // a must be equal to b
        return 0
      })
    } as Kit
    set(kitAtom, sortedKit)
  }
)
writeKitAtom.debugLabel = 'createOrder/writeKitAtom'

export const useKit = () => useAtom(kitAtom)
export const useGetKit = () => useAtomValue(kitAtom)
export const useSetKit = () => useSetAtom(writeKitAtom)

export const KIT_ORDER_CURRENT_ID = 'kit_order_id'
export const KIT_ORDER_CURRENT_KIT = 'kit_order'
