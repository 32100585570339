import { useMemo } from 'react'

import { ResultCode } from 'domain/orders/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { Research } from 'domain/servicesCBRdoc/config'
import { OrderItem, ResearchResult } from '../../types'

export default function useResultsToOrder(
  orderItem: OrderItem,
  selectedItems?: ResearchResult[]
) {
  return useMemo(() => {
    const { isFromPassportWithoutAllData, researchResults, service } = orderItem
    const selectedResults = selectedItems ?? researchResults ?? []
    const serviceConfig = getServiceConfig<Research>(service.code)

    const filterResultsThatCanBeOrdered = (results: ResearchResult[]) => {
      if (results.length === 0) return []

      if (serviceConfig.doNotOrderResultsWithSameNotary) {
        return results.filter(
          (element, index: number, self) => {
            const indexFound = self.findIndex(t => t.url_cartorio?.value === element.url_cartorio?.value)
            return index === indexFound
          }
        )
      }

      return results
    }

    const filterPositiveResults = (allResults: ResearchResult[]) => {
      return allResults.filter(o => !o.resultado || o.resultado === ResultCode.POSITIVE)
    }

    const filterNegativeResults = (allResults: ResearchResult[]) => {
      return allResults.filter(o => o.resultado === ResultCode.NEGATIVE)
    }    

    const validResults = filterResultsThatCanBeOrdered(selectedResults ?? [])
    const positiveResults = filterPositiveResults(validResults)
    const negativeResults = filterNegativeResults(validResults)

    const checkCanOrderResults = (
      code: ResultCode,
      resultsToCheck: ResearchResult[]
    ): [boolean, string?] => {
      if (!orderItem || !selectedResults.length || (resultsToCheck && !resultsToCheck.length)) {
        return [false, NO_RESULTS_SELECTED]
      }

      if (isFromPassportWithoutAllData) {
        return [false, NOT_ENOUGH_DATA]
      }

      const { autoPurchase, placeOrder } =
        orderItem.researchResultsOrderSettings?.[code as ResultCode.POSITIVE | ResultCode.NEGATIVE] ?? {}

      if (!placeOrder) {
        return [false, NOT_ENOUGH_DATA]
      }

      if (autoPurchase) {
        return [false, AUTOMATIC_PURCHASE]
      }

      return [true]
    }

    const [canOrderPositives, reasonPositives] = checkCanOrderResults(ResultCode.POSITIVE, positiveResults)
    const [canOrderNegatives, reasonNegatives] = checkCanOrderResults(ResultCode.NEGATIVE, negativeResults)

    const resultsThatCanBeOrdered = {
      any: {
        canOrder: canOrderPositives || canOrderNegatives,
        reason: reasonPositives ?? reasonNegatives
      },
      positive: {
        resultsToOrder: positiveResults,
        canOrder: canOrderPositives,
        reason: reasonPositives
      },
      negative: {
        resultsToOrder: negativeResults,
        canOrder: canOrderNegatives,
        reason: reasonNegatives
      }
    }

    return resultsThatCanBeOrdered
  }, [selectedItems])
}

const NO_RESULTS_SELECTED = 'Selecione os resultados para os quais deseja solicitar as certidões.'
const NOT_ENOUGH_DATA = 'Os resultados não possuem dados suficientes para solicitar as certidões.'
const AUTOMATIC_PURCHASE = 'Os resultados são solicitados automaticamente.'
