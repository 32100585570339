import React from 'react'

import { Icon } from 'ui'

type AlertType = 'success' | 'warning' | 'error' | 'info'

export interface AlertProps extends React.HTMLAttributes<HTMLElement> {
  type: AlertType
  icon?: React.ReactNode
  message: string | React.ReactNode
  description?: string | React.ReactNode
  closeIcon?: boolean
  handleCloseAlert?: () => void
}

export default function Alert({
  type,
  icon,
  description,
  message,
  closeIcon = false,
  handleCloseAlert,
  className,
  ...props
}: AlertProps) {
  return (
    <div
      className={`flex justify-between items-center mb-5 py-2.5 px-3 border rounded-sm 
      ${StyleType[type]} ${className}`}
      {...props}
    >
      {icon && <div className="text-2xl mr-3">{icon}</div>}
      <div className="flex flex-col w-full gap-1 justify-center text-secondary-500">
        <span className={`block text-2xs leading-5 ${description ? 'text-base font-semibold' : ''}`}>
          {message}
        </span>
        {description && <span className="block text-sm">{description}</span>}
      </div>
      {closeIcon && (
        <Icon name="close" className="self-start ml-2 cursor-pointer" onClick={handleCloseAlert} />
      )}
    </div>
  )
}

const StyleType: Record<AlertType, string> = {
  error: 'border-error bg-error-bg',
  info: 'border-info bg-info-bg',
  success: 'border-success-500 bg-success-50',
  warning: 'border-yellow-500 bg-yellow-150',
}
