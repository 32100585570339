import React from 'react'
import { Spin } from 'antd'

import { Icon, IconName } from 'ui'

interface Props {
  text: string
  icon: IconName
  onClick?: () => void
  className?: string
  loading?: boolean
  children?: React.ReactNode
}

function ShareOrderModalItem({ text, icon, children, className = '', onClick, loading = false }: Props) {
  return (
    <div className="border-b border-b-300">
      <Spin spinning={loading}>
        <div
          role="button"
          onClick={onClick}
          className={`flex flex-row items-center gap-6 py-5 px-4 
          w-full text-primary hover:bg-gray-100 ${className}`}
        >
          <Icon name={icon} size={30} />
          <span className="flex flex-1 text-base leading-snug -mb-1">{text}</span>
          {children}
        </div>
      </Spin>
    </div>
  )
}

export default ShareOrderModalItem
