import React from 'react'

import { ExportButton } from 'domain/invoices/components'
import { useGetSelectedMonth, useGetPostpaidSortBy } from 'domain/invoices/state'
import generatePostpaidPDF from '../helpers/generatePostpaidPDF'
import fetchExportPostpaidInvoices from '../services/fetchExportPostpaidInvoices'

function ExportPostpaidInvoicesPDF() {
  const selectedMonth = useGetSelectedMonth()

  return (
    <ExportButton
      kind="pdf"
      fetchData={async () => {
        const month = selectedMonth
        const data = await fetchExportPostpaidInvoices(month)
        
        await generatePostpaidPDF(selectedMonth, data)
      }}
    />
  )
}

export default ExportPostpaidInvoicesPDF
