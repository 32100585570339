import { FieldConfig } from 'domain/servicesCBRdoc/fields/types'
import { SplittableTextInput } from 'ui'

export interface Props {
  autoFocus?: boolean
  field: FieldConfig
  itemId?: number | string
  name?: string
  onChange?: (value: string) => void
  onChangeValue?: (value: string[]) => void
}

export default function SplittableField({
  autoFocus,
  itemId,
  field,
  name = field.name,
  onChangeValue,
}: Props) {
  return (
    <SplittableTextInput
      name={itemId !== undefined ? [itemId, name] : name}
      label={field.label}
      placeholder={field.placeholder!}
      maxLength={field.maxLength ?? 12}
      autoFocus={autoFocus}
      onChangeValue={onChangeValue}
      onlyNumbers
    />
  )
}
