import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { SortOrder } from 'domain/app/types'
import { PrepaidInvoiceAPI } from 'domain/invoices/types'
import { displayMonth, displayYear } from 'utils/dateTime'
import { removeEmpties } from 'utils/transformData'

import convertPrepaidInvoice from './convertPrepaidInvoice'

type PrepaisInvoicesParams = { 
  currentPage?: number
  operation?: 'C' | 'D' | ''
  pageSize?: number
  searchValue?: string
  selectedMonth: Date
  sortField?: string
  sortOrder?: SortOrder
}

const getPrepaidInvoices = async ({ 
  operation, 
  searchValue, 
  selectedMonth, 
  sortField, 
  pageSize, 
  currentPage 
}: PrepaisInvoicesParams) => {
  const dayDate = displayMonth(selectedMonth)
  const yearDate = displayYear(selectedMonth)

  const params: any = removeEmpties({
    include: 'purchase,purchase.orders,user',
    'per-page': pageSize,
    sort: sortField,
    page: currentPage,
    'filter[operation]': operation,
  })

  if (searchValue) {
    params['filter[purchase_name_or_id]'] = searchValue
  }

  if (!params['per-page']) {
    params.disable_pagination = true
  }
  
  const response: AxiosResponse<PrepaidInvoiceAPI[]> = await AuthorizedAPI.get(
    `/invoices/${yearDate}/${dayDate}`,
    { params }
  )

  return {
    ...response,
    data: response.data?.map(convertPrepaidInvoice) 
  }
}

export default getPrepaidInvoices
