import { getFieldName } from 'domain/createOrder/shared'
import { FormLabel, TextAreaInput, TextAreaInputProps } from 'ui'
import { FieldConfig } from '../types'

interface Props extends Omit<TextAreaInputProps, 'label' | 'name'> {
  field: FieldConfig
  itemId?: number
}

export default function TextAreaField({ field, itemId, required = !field.optional, ...props }: Props) {
  const name = getFieldName(field.name, itemId)

  return (
    <TextAreaInput
      label={<FormLabel label={field.label} description={field.labelDescription} required={required} />}
      name={name}
      required={required}
      maxLength={field.maxLength}
      {...props}
    />
  )
}
