import { useState } from 'react'
import { Tag } from 'antd'
import { useDebounce } from 'use-debounce'

import { SelectPaginated } from 'ui'
import { useGroupsFilterQuery } from '../services'

export default function GroupsSelect() {
  const [page, setPage] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const [search, setSearch] = useState('')
  const [debouncedValue] = useDebounce(search, 300)
  const [selectedColors, setSelectedColors] = useState<any>({})

  const { data: groups, isFetching } = useGroupsFilterQuery({ page, setTotalResults, search: debouncedValue })

  return (
    <SelectPaginated
      name="groupsIds"
      label="Grupos"
      mode="multiple"
      placeholder="Selecione os grupos"
      loading={isFetching}
      options={groups}
      optionLabelKey="name"
      optionValueKey="id"
      tagRender={({ label, value, closable, onClose }: any) => {
        const color = selectedColors[value] ?? groups?.find(g => g.id === value)?.color ?? 'gray'

        return (
          <Tag color={color} closable={closable} onClose={onClose} className="mr-1">
            {label}
          </Tag>
        )
      }}
      page={page}
      setPage={setPage}
      total={totalResults}
      setSearch={setSearch}
      onSelect={(values, option) => {
        if (option) {
          const color = groups?.find(g => g.id === option.value)?.color

          if (color) {
            setSelectedColors({
              ...selectedColors,
              [option.value!]: color,
            })
          }          
        }
      }}
    />
  )
}
