import { useEffect } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import { useIsHydratedFromURL } from 'domain/app'
import { useSetCurrentPage } from './pagination'

export default function useSetInitialValuesFromURL() {
  const history = useHistory()
  const location = history.location
  const setCurrentPage = useSetCurrentPage()
  const [isHydrated, setIsHydrated] = useIsHydratedFromURL()

  useEffect(() => {
    if (!isHydrated) {
      if (location.search) {
        const { p } = queryString.parse(location.search)
  
        if (p) {
          setCurrentPage(Number(p))
        }
      }

      setIsHydrated(true)
    }
  }, [])
}
