import React from 'react'

export default function SidebarHeading2({
  text,
  border = true,
}: {
  text: string | React.ReactElement
  border?: boolean
}) {
  return (
    <h2
      className={'font-bold text-base pt-4 text-secondary-600' + (border ? 'border-b border-gray-300 ' : '')}
    >
      {text}
    </h2>
  )
}
