import { Spin } from 'antd'
import { useDispatch } from 'react-redux'

import { InteiroTeor, TraducaoJuramentada } from 'domain/createOrder/certificates'
import { useExtrasQuery } from 'domain/createOrder/shared'
import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document/state'
import { CreateOrderState } from 'domain/createOrder/types'
import { getLocationUrl } from 'domain/servicesCBRdoc/helpers/location'
import { getValue } from 'utils/transformData'

import ExtraCheckboxField from './ExtraCheckboxField'
import ShippingDelayedDays from './ShippingDelayedDays'
import XeroxAutenticado from './XeroxAutenticado'
import XeroxSimples from './XeroxSimples'

export default function SelectExtras() {
  const dispatch = useDispatch()
  const createOrder = useCreateOrderState()

  const { data: extras, isFetching } = useExtrasQuery({
    service: createOrder.selectedService,
    params: getParams(createOrder),
    onSuccess: data => {
      if (data) {
        dispatch(createOrderActions.setAvailableExtras(data))
      }
    },
  })

  return (
    <Spin spinning={isFetching}>
      {extras?.qtde_xerox_simples && <XeroxSimples />}

      {extras?.qtde_xerox_autenticado && <XeroxAutenticado />}

      {extras?.previa_digitalizada && (
        <ExtraCheckboxField
          name="previa_digitalizada"
          label="Prévia digitalizada"
          description="Receba a cópia antecipada por e-mail assim que a certidão for emitida."
        />
      )}

      {extras?.reconhecimento_firma && (
        <ExtraCheckboxField
          name="reconhecimento_firma"
          label="Reconhecimento firma"
          description="Selo que reconhece quem assina a certidão tem firma reconhecida."
        />
      )}

      {extras?.apostilamento && (
        <ExtraCheckboxField
          name="apostilamento"
          label="Apostilamento"
          description="É um certificado de autenticidade nos termos da Convenção de Haia."
        >
          {extras?.apostilamento.dias_adicionados_prazo && (
            <ShippingDelayedDays days={extras.apostilamento.dias_adicionados_prazo} />
          )}
        </ExtraCheckboxField>
      )}

      {extras?.pasta_protecao && (
        <ExtraCheckboxField
          name="pasta_protecao"
          label="Pasta de proteção"
          description="Pasta plástica para proteção da certidão."
        />
      )}

      {extras?.traducao_juramentada && (
        <TraducaoJuramentada languages={extras.traducao_juramentada.idiomas} />
      )}

      {extras?.aviso_recebimento && (
        <ExtraCheckboxField
          name="aviso_recebimento"
          label="Aviso de recebimento (A.R.)"
          description="Recibo dos correios que comprova a entrega do documento para o remetente."
        />
      )}

      {extras?.inteiro_teor && <InteiroTeor />}
    </Spin>
  )
}

function getParams(createOrder: CreateOrderState) {
  const { selectedLocation, selectedType, selectedFormat } = createOrder
  const type = getValue(selectedType)
  const { url_cartorio, url_cidade, url_uf } = getLocationUrl(selectedLocation)

  return {
    formato: selectedFormat,
    tipo: type,
    url_cartorio,
    url_uf,
    url_cidade,
  }
}
