import { SignatureCertification } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { CPF, NOME_PF, DATA_NASCIMENTO, ENDERECO_IMOVEL, EMAIL, TELEFONE } = FieldName

export type CertificadoDigitalCpfFields = Record<
  | FieldName.CPF
  | FieldName.NOME_PF
  | FieldName.DATA_NASCIMENTO
  | FieldName.ENDERECO_IMOVEL
  | FieldName.EMAIL
  | FieldName.TELEFONE,
  FieldConfig
>

class CertificadoDigitalCpf extends SignatureCertification {
  formFields: CertificadoDigitalCpfFields = {
    [CPF]: Fields.cpf,
    [NOME_PF]: Fields.nome_pf,
    [DATA_NASCIMENTO]: Fields.nascimento,
    [ENDERECO_IMOVEL]: {
      ...Fields.endereco,
      kind: 'text',
      dataScope: 'registree',
      label: 'Endereço',
      placeholder: 'Digite o endereço completo',
    },
    [EMAIL]: { ...Fields.email, kind: 'text', placeholder: 'E-mail' },
    [TELEFONE]: {
      ...Fields.telefone,
      optional: false,
    },
  }

  constructor() {
    super({
      id: 120,
      shortName: 'Certificado Digital',
    })
  }
}

const service = new CertificadoDigitalCpf().initialize()

export default service
