import { atom, useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'

import { globalScope } from 'domain/app/state/scope'
import { Customer } from './types'

const customerAtom = atom<Customer>({
  maxUsers: 0,
  permissions: {},
} as Customer)

export const useCurrentCustomer = () => useAtomValue(customerAtom, { store: globalScope })

const isPostPaid = selectAtom(customerAtom, data => data.isPostpaid)

export const useIsCustomerPostPaid = () => useAtomValue(isPostPaid, { store: globalScope })

export const useSetCustomer = () => useSetAtom(customerAtom, { store: globalScope })
