import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { OrderItemAPI } from '../../typesAPI'
import { convertOrderItemListing } from './convertOrderItem'
import { OrderItemPurchasable } from '../../types'

async function fetchPurchaseableOrderItem(itemId: number, isPostPaid: boolean) {
  const response: AxiosResponse<OrderItemAPI> = await AuthorizedAPI.get(`/orders/${itemId}`, {
    params: {
      include: ['groups', 'service', 'service_category', 'user'],
    },
  })

  return convertOrderItemListing(response.data, isPostPaid) as OrderItemPurchasable
}

export default fetchPurchaseableOrderItem
