import React from 'react'

import { LabelInValue } from 'domain/app/types'
import { DeliveryInfo, ServiceCard } from 'domain/createOrder/shared/components'
import { useCreateOrderState } from 'domain/createOrder/document/state'
import { ServiceType } from 'domain/servicesCBRdoc/types'
import { Sidebar } from 'layouts'

interface Props {
  cardContent?: React.ReactNode
  footer: JSX.Element
}

export default function CreateOrderSidebar({ cardContent, footer }: Props) {
  const { orderDetails, selectedLocation, selectedService } = useCreateOrderState()
  const serviceName = selectedService?.name

  if (!serviceName) return null

  const notaries = selectedLocation.url_cartorio

  return (
    <Sidebar
      sectionTitle={
        selectedService.type === ServiceType.DOCUMENT_REGISTRATION ? 'Seu registro' : 'Seu pedido'
      }
    >
      <ServiceCard serviceName={serviceName}>
        <div className="flex flex-col gap-3 empty:hidden">
          {Array.isArray(notaries) ? (
            notaries.map((notary, index) => <NotaryName key={index} notary={notary} />)
          ) : (
            <NotaryName notary={notaries!} />
          )}

          {cardContent ??
            (orderDetails.estimatedDeliveryTime && (
              <DeliveryInfo estimatedDeliveryTime={orderDetails.estimatedDeliveryTime} />
            ))}
        </div>
      </ServiceCard>
      {footer}
    </Sidebar>
  )
}

function NotaryName({ notary }: { notary: LabelInValue }) {
  if (!notary?.label) return null

  return <h2 className="text-gray-500 text-sm">{notary.label}</h2>
}
