import React, { useMemo } from 'react'
import { Button, Dropdown } from 'antd'

import { errorHandler } from 'api/errors'
import { getNoPermissionText, useHasRecurrencePermission } from 'domain/auth'
import { useRedoOrder } from 'domain/createOrder/redo'
import { useDeleteNotification, useToggleNotificationRead } from 'domain/notifications/services'
import { Notification, NotificationOrderItem, NotificationType } from 'domain/notifications/types'
import { Icon } from 'ui'

interface Props {
  notification: Notification
  setItemsToCreateRecurrence: React.Dispatch<React.SetStateAction<NotificationOrderItem[]>>
}

export default function NotificationActions({ notification, setItemsToCreateRecurrence }: Props) {
  const deleteNotification = useDeleteNotification()
  const toggleRead = useToggleNotificationRead()
  const addToCart = useRedoOrder('fetchOrderItem')
  const hasRecurrencePermission = useHasRecurrencePermission()

  const menu: any = useMemo(() => {
    const items: any = [
      {
        key: 'read',
        label: <>Marcar como {notification.readAt ? 'não lida' : 'lida'}</>,
        onClick: () => toggleRead(notification),
      },
      {
        key: 'delete',
        label: 'Excluir',
        children: [
          {
            key: 'delete-1',
            label: 'Excluir',
            className: 'py-0 text-error',
            icon: <Icon name="trash" />,
            onClick: () => deleteNotification(notification.id),
          },
        ],
      },
    ]

    if (notification.type === NotificationType.CERTIFICATE_EXPIRED) {
      items.push({
        key: 'redo',
        label: 'Refazer pedido',
        icon: <Icon name="redo" />,
        disabled: !notification.orderItem,
        onClick: async () => {
          try {
            await addToCart(notification.orderItem!)
          } catch (error) {
            errorHandler(error, {
              code: '0x790',
            })
          }
        },
      })

      if (notification.orderItem) {
        items.push({
          key: 'recurrence',
          label: 'Criar recorrência',
          icon: <Icon name="repeat" />,
          title: getNoPermissionText(hasRecurrencePermission),
          disabled: !hasRecurrencePermission,
          onClick: () => {
            setItemsToCreateRecurrence([notification.orderItem!])
          },
        })
      }
    }

    return { items }
  }, [notification])

  return (
    <Dropdown menu={menu}>
      <Button type="link" size="small" className="flex items-center gap-1">
        Ações <Icon name="chevron-down" />
      </Button>
    </Dropdown>
  )
}
