import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const {
  FORMATO,
  NOME_PF,
  URL_CARTORIO,
  URL_CIDADE,
  URL_UF,
} = FieldName

type CertidaoObitoFields = Record<
  | FieldName.DATA_OBITO
  | FieldName.LIVRO
  | FieldName.NOME_MAE
  | FieldName.NOME_PAI
  | FieldName.NOME_PF
  | FieldName.PAGINA
  | FieldName.TERMO
  | FieldName.URL_CARTORIO
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

class CertidaoObito extends Certificate {
  formFields: CertidaoObitoFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    url_cartorio: Fields.url_cartorio,
    nome_pf: Fields.nome_pf,
    mae: Fields.mae,
    pai: Fields.pai,
    obito: Fields.obito,
    livro: Fields.livro,
    pagina: Fields.pagina,
    termo: Fields.termo,
  }

  constructor() {
    super({
      availableFormatsFields: [URL_CARTORIO, URL_CIDADE, URL_UF],
      id: 3,
      legalEntity: 'fisica',
      orderNameField: NOME_PF,
      priceByFields: [FORMATO, URL_CARTORIO, URL_CIDADE, URL_UF],
      shortName: 'Certidão Óbito',
      steps: ['localizacao', 'dados', 'entrega'],
    })
  }
}

const service = new CertidaoObito().initialize()

export default service

