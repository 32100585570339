import { ShoppingCartOrder } from 'domain/shoppingCart/types'
import { SimilarOrder } from '../../types'
import { SimilarOrdersError } from '../errors'

export default function checkSimilarOrdersBeforeSubmit(
  orderItem: ShoppingCartOrder,
  localItems: ShoppingCartOrder[]
) {
  if (localItems.length > 0) {
    const fieldsToIgnore = [
      'auto_purchase_certificate_from_result_negative',
      'auto_purchase_certificate_from_result_positive',
      'name',
      'origin',
    ]

    const getDataHash = (cartItem: ShoppingCartOrder) => {
      return Object.entries(cartItem.submitData)
        .filter(([field, value]) => {
          return Boolean(value) && !fieldsToIgnore.includes(field)
        })
        .map(([, value]) => value)
        .join(';')
    }
    const currentItemHash = getDataHash(orderItem)
    const localItemsHashData = localItems.map(getDataHash)
    const similarCartItems: ShoppingCartOrder[] = []
    
    localItemsHashData.forEach((itemHashData, index) => {
      if (currentItemHash === itemHashData && orderItem.cartItemId !== localItems[index].cartItemId) {
        similarCartItems.push(localItems[index])
      }
    })

    if (similarCartItems.length > 0) {
      const similarOrders: SimilarOrder[] = similarCartItems.map(({ cartItemId, kitItemId, submitData }) => ({
        cartItemId,
        orderName: submitData.name,
        formItemId: Object.values(orderItem.formItems!)[0].id,
        checkedValues: currentItemHash,
        kitItemId: kitItemId!
      }))

      throw new SimilarOrdersError(similarOrders)
    }
  }
}
