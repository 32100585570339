import React, { useReducer } from 'react'
import { Button } from 'ui'

import { useGetOrderName } from 'domain/checkout'

import CartOrderDetails from './CartOrderDetails'

export default function OrderName() {
  const [isOpen, toggleOpen] = useReducer(prev => !prev, false)
  const orderName = useGetOrderName()

  return (
    <div className="flex items-center gap-4">
      <span className="text-base font-normal pt-1">{orderName}</span>

      <span>
        <Button type="link" icon="edit" size="small" iconSize={18} className="text-sm p-0" onClick={toggleOpen}>
          Renomear pedido
        </Button>
      </span>

      <CartOrderDetails open={isOpen} onFinish={toggleOpen} onCancel={toggleOpen} />
    </div>
  )
}
