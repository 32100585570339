import React from 'react'

import { useShoppingCartState } from 'domain/shoppingCart'
import { useCheckoutStep, useSetPaymentMethod } from '../state'
import PaymentModal from './PaymentModal'

interface Props {
  paymentValue: number
}

function CheckoutPaymentModal({ paymentValue }: Props) {
  const setPaymentMethod = useSetPaymentMethod()
  const { totalPrice } = useShoppingCartState()
  const [checkoutStep, setStep] = useCheckoutStep()

  return (
    <PaymentModal
      open={checkoutStep === 'payment'}
      onRequestClose={() => setStep('idle')}
      paymentValue={paymentValue}
      purchaseTotal={totalPrice}
      onPaymentOk={setPaymentMethod}
    />
  )
}

export default CheckoutPaymentModal
