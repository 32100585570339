import React, { useMemo, useState } from 'react'
import { Form, Input, notification } from 'antd'

import { useValidateExplorerItemName } from 'domain/myFiles/helpers'
import { useRenameMutation } from 'domain/myFiles/services'
import { useExplorerItem } from 'domain/myFiles/state'
import { normalizeString } from 'utils/formatters'
import { ExplorerItem } from 'domain/myFiles/types'
import { SubmitButton } from 'ui'

interface Props {
  onItemRenamed?: (item: ExplorerItem) => void
}

export default function RenameItem({ onItemRenamed }: Props) {
  const [form] = Form.useForm()
  const [explorerItem, setExplorerItem] = useExplorerItem()

  const [newName, setNewName] = useState(explorerItem!.name || '')
  const { items, setSearchTerm, isFetching } = useValidateExplorerItemName(form)

  const { renameItem, loading } = useRenameMutation({
    onSuccess: () => {
      const updatedItem = { ...explorerItem!, name: newName }
      setExplorerItem(updatedItem)
      onItemRenamed && onItemRenamed(updatedItem)
    },
  })
 
  const handleSubmit = () => {
    renameItem({ id: explorerItem!.id, name: newName })
    notification.success({ message: 'Arquivo renomeado com sucesso' })
    setNewName('')
    form.setFieldsValue(
      [{
        name: 'name',
        value: '',
        errors: [],
      }],
    )
  }

  const isFormInvalid = useMemo(() => {
    const names = Object.keys(form.getFieldsValue())
    const errors = form.getFieldsError()
    const hasError = errors.some((item) => item.errors.length > 0)
    const newValueEmpty = !newName?.trim().length
    const alreadyExists = items?.some((item) => normalizeString(item.name) === normalizeString(newName))

    if (alreadyExists) {
      form.setFields([
        {
          name: 'name',
          errors: ['Já existe um arquivo com esse nome'],
        },
      ])
      return true
    }

    return newValueEmpty || hasError || isFetching || form.isFieldsValidating(names)
  }, [newName, isFetching, form.getFieldsError()])

  return (
    <div className="w-full flex items-end">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
        scrollToFirstError
        className="max-w-lg flex items-start justify-between w-full mb-3"
      >
        <Form.Item
          label={<span className="font-normal text-gray-500">Nome do arquivo</span>}
          name="name"
          className="mb-2 mr-2 w-full"
          rules={requiredRule}
        >
          <Input
            onChange={e => {
              setNewName(e.target.value)
              setSearchTerm(e.target.value)
            }}
            placeholder={explorerItem?.name ?? 'Digite o nome'}
            size="small"
            className="h-11 px-2"
          />
        </Form.Item>
        <SubmitButton
          label="Renomear"
          className="text-xs h-[44px]"
          disabled={isFormInvalid || loading}
          marginTop="items-end mt-8"
        />
      </Form>
    </div>
  )
}

const requiredRule = [
  {
    required: true,
    message: 'Informe um nome',
  },
]
