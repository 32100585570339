import React from 'react'

import { ReactComponent as IlustraPlane } from 'assets/images/ilustra-plane-blue.svg'
import { useHasPostpaidFilters } from 'domain/invoices/state'
import { AlertNoOrders } from 'domain/orders'

import usePostpaidInvoicesQuery from '../services/usePostpaidInvoicesQuery'
import PostPaidInvoicesList from './PostPaidInvoicesList'
import PostpaidInvoicesFilters from './PostpaidInvoicesFilters'
import { Spin } from 'antd'

function PostpaidInvoices() {
  const hasFilters = useHasPostpaidFilters()

  const { data: invoices, isFetching, isLoading } = usePostpaidInvoicesQuery()

  return (
    <div className="relative flex-1 flex flex-col">
      <PostpaidInvoicesFilters />

      <div className="p-7 pl-5 pt-0 bg-gray-200 overflow-x-auto flex-1">
        <Spin spinning={isFetching || isLoading}>
          {invoices && invoices.length > 0 && (
            <PostPaidInvoicesList invoices={invoices!} loading={isFetching} />
          )}

          {hasFilters && invoices?.length === 0 && (
            <div className="py-7 pl-3">
              <IlustraPlane className="mb-4 mx-auto" />
              <h2 className="font-bold text-2xl text-center mb-6">
                Não foi encontrada nenhuma fatura com esse filtro.
              </h2>
            </div>
          )}

          {!hasFilters && invoices?.length === 0 && (
            <AlertNoOrders
              title="Você ainda não possui faturas para esse mês"
              description="Depois que você fizer um pedido, as faturas aparecerão aqui."
            />
          )}

        </Spin>
      </div>
    </div>
  )
}

export default PostpaidInvoices
