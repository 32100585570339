import { Tooltip } from 'antd'
import useOrderAttachments from '../../services/useOrderAttachments'

export default function OrderItemAttachments() {
  const { data } = useOrderAttachments()

  if (!data || !Array.isArray(data) || data.length === 0) return null

  return (
    <div className="">
      <div className="mb-3 flex items-center">
        <span className="text-base mt-1 text-secondary-500 font-bold">Arquivos anexados</span>
      </div>

      <ul>
        {data.map((item: any) => (
          <Tooltip
            key={item}
            title={item}
            placement="top"
          >
            <li  className="flex items-center justify-between mb-2">
              <p className="text-sm text-secondary-300 max-w-[50%] text-ellipsis overflow-clip">{item.split('/').at(-1)}</p>{' '}
              <a
                href={item}
                target="_blank"
                rel="noreferrer"
                className="text-primary underline ml-auto text-sm"
              >
                Ver arquivo
              </a>
            </li>
          </Tooltip>
        ))}
      </ul>
    </div>
  )
}
