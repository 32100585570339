import { Progress, UploadFile } from 'antd'
import { useState } from 'react'

import { Button } from 'ui'
import { useGetKit, useGetMissingFieldsLabels, useGetUnavailableKitItems } from '../../state'
import useReadFile from './useReadFile'
import KitSpreadsheetAddToCartRows from './KitSpreadsheetAddToCartRows'

type Props = {
  file: UploadFile
}

export default function KitSpreadsheetAddToCart({ file }: Props) {
  const [rows, setRows] = useState<any[]>([])
  const [readFile, percent] = useReadFile()
  const kit = useGetKit()
  const missingFields = useGetMissingFieldsLabels()
  const unavailableKitItems = useGetUnavailableKitItems()
  const hasMissingFields = !!missingFields
  const allItemsAreUnavailable = unavailableKitItems.length === kit.items.length

  const onClick = async () => {
    readFile({
      initialRow: 1,
      file: file!.originFileObj as Blob,
      sheetRows: 101, // TODO: máximo de linhas?
      onSuccess: async jsonRows => {
        setRows(prev => [...prev, ...jsonRows])
      },
    })
  }

  return (
    <>
      <h3 className="text-lg text-secondary font-bold mb-2 mt-8">3. Adicione ao carrinho</h3>

      {percent > 0 && percent < 100 && (
        <>
          <p className="text-sm mt-2">Lendo o arquivo...</p>
          <Progress percent={percent} status="active" className="mt-1 mb-3" />
        </>
      )}

      {percent === 100 ? (
        <KitSpreadsheetAddToCartRows rows={rows} setRows={setRows} />
      ) : (
        <>
          <Button
            type="primary"
            size="large"
            onClick={onClick}
            disabled={!file || allItemsAreUnavailable || hasMissingFields}
            title={
              allItemsAreUnavailable
                ? 'Nenhum item deste kit está disponível para pedido via planilha.'
                : hasMissingFields
                  ? 'Há campos necessários não identificados.'
                  : undefined
            }
          >
            Adicione ao Carrinho
          </Button>

          {hasMissingFields && (
            <p className="mt-2 text-error text-xs">Campos não identificados: {missingFields}</p>
          )}
        </>
      )}
    </>
  )
}
