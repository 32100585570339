import { CreateOrderState } from 'domain/createOrder/types'
import { DocumentRegistration } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { formatDateToApi } from 'utils/dateTime'

type RTDFields = Record<
  | FieldName.DATA_TITULO
  | FieldName.DOCUMENTO
  | FieldName.NOME
  | FieldName.EMAIL
  | FieldName.LIVRO
  | FieldName.PAGINA
  | FieldName.TIPO
  | FieldName.TIPO_FLUXO_ASSINATURAS
  | FieldName.URL_CARTORIO
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

const { TIPO_FLUXO_ASSINATURAS, URL_CARTORIO, URL_CIDADE, URL_UF } = FieldName

class RGI extends DocumentRegistration {
  formFields: RTDFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    url_cartorio: Fields.url_cartorio,
    tipo_fluxo_assinaturas: Fields.tipo_fluxo_assinaturas,
    tipo: {
      ...Fields.tipo,
      apiOptionsLabelKey: 'name',
    },
    data_titulo: Fields.data_titulo,
    livro: Fields.livro,
    pagina: Fields.pagina,
    nome: {
      ...Fields.nome,
      label: 'Nome completo / Razão',
      optional: false,
    },
    documento: {
      ...Fields.documento,
      kind: 'cpfOrCnpj',
      label: 'CPF / CNPJ',
      maxLength: 14,
      optional: false,
      apiRemoveMask: true,
    },
    email: Fields.email,
  }

  constructor() {
    super({
      id: 104,
      isKitAvailable: false,
      legalEntity: 'indefinido',
      priceByFields: [TIPO_FLUXO_ASSINATURAS, URL_CARTORIO, URL_CIDADE, URL_UF],
      shortName: 'RGI',
      steps: ['localizacao', 'upload', 'dados', 'assinantes'],
    })
  }

  protected getSubmitData = (createOrderState: CreateOrderState) => {
    const { formData, selectedType } = createOrderState
    const { livro, pagina } = formData

    const submitData: any = {
      ...this.getDocRegistrationSubmitData(createOrderState),
      data_titulo: formatDateToApi(formData.data_titulo),
      tipo: selectedType,      
    }

    if (livro) {
      submitData.livro = livro
    }
    if (pagina) {
      submitData.pagina = pagina
    }

    if (selectedType === 'escritura-publica') {
      const outorgantes: any = formData.outorgantes.map((part: any) => ({
        ...part,
        tipo: 'outorgante',
      }))
  
      let outorgados: any = []
  
      if (formData.outorgados.nao_possui) {
        outorgados = [
          {
            ...outorgantes[0],
            tipo: 'outorgado'
          }
        ]
      } else {
        outorgados = formData.outorgados.map((part: any) => ({
          ...part,
          tipo: 'outorgado',
        }))
      }

      submitData.partes = [
        ...outorgantes,
        ...outorgados,
      ]
    }

    return submitData
  }
}

const service = new RGI().initialize()

export default service
