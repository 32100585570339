import { StatusCode } from 'domain/orders/types'
import { useCurrentUser } from 'domain/auth'
import { SidebarContainer, useGetOrderItem } from 'domain/orderItem/shared'

import DownloadFile from './DownloadFile'
import SetExpirationDate from './SetExpirationDate'

export default function ViewCertificateActions() {
  const user = useCurrentUser()
  const orderItem = useGetOrderItem()
  const { id, fileURL } = orderItem

  const showExpirationDateButton =
    orderItem.status === StatusCode.FINALIZADO && orderItem.user.id === user.id

  return (
    <SidebarContainer
      expirationDateButton={showExpirationDateButton ? <SetExpirationDate /> : undefined}
      downloadButton={
        <DownloadFile 
          itemId={id}
          disabled={!fileURL} 
        />
      }
    />
  )
}
