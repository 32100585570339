import { ExplorerFilters } from '../types'
import { useGetMyFilesFilters, useSetMyFilesFilters } from './myFilesState'

export const useFilters = (): ExplorerFilters => useGetMyFilesFilters()

export const useFiltersState = (): [ExplorerFilters, (filters: ExplorerFilters) => void] => {
  const filters = useGetMyFilesFilters()
  const setFiltersHook = useSetMyFilesFilters()

  const setFilters = (filters: ExplorerFilters) => {
    setFiltersHook(filters)
  }

  return [filters, setFilters]
}
