import { useDispatch } from 'react-redux'

import { ExtraInfoRadioButtonGroup, ExtraInfoSelectProps } from 'domain/servicesCBRdoc/fields'
import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document/state'

export default function DynamicModelRadioButton(
  props: Omit<ExtraInfoSelectProps, 'onChange' | 'service' | 'value'>
) {
  const dispatch = useDispatch()
  const { selectedModel, selectedService } = useCreateOrderState()

  const onChange = (value: any) => {
    dispatch(createOrderActions.setSelectedModel(value))
  }

  return (
    <ExtraInfoRadioButtonGroup
      service={selectedService}
      value={selectedModel}
      onChange={onChange}
      {...props}
    />
  )
}
