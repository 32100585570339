import { NameField } from 'domain/servicesCBRdoc/fields'
import {
  CPFInput,
  EmailInput,
} from 'ui'

import config from '../coletaAssinaturasConfig'

interface CertidaoColetaAssinaturasFormProps { 
  itemId: number 
}

const ColetaAssinaturasForm = ({ itemId }: CertidaoColetaAssinaturasFormProps) => {
  return (
    <>
      <NameField
        autoFocus
        field={nome_pf}
        name={[itemId, 'nome']}
      />
      <CPFInput 
        name={[ itemId, 'cpf']} 
      />
      <EmailInput 
        name={[itemId, email.name]} 
        required 
      />
    </>
  )
}

const { nome_pf, email } = config.formFields

export default ColetaAssinaturasForm
