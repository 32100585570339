import AlertFullPage from 'ui/alerts/AlertFullPage'
import Icon from 'ui/Icon'

interface Props {
  fileUrl: string
  fileName?: string
}

function ViewPDF({ fileUrl }: Props) {
  return (
    <object 
      data={fileUrl}
      type="application/pdf" 
      className={`w-full flex justify-center align-center pt-2 pb-6 px-6 ${fileUrl ? "min-h-[750px]" : "min-h-[400px]"}`}
    >
      <div className="flex h-128 align-center">
        <AlertFullPage 
          description="A visualização do documento ainda não está disponível."
          title="PDF indisponível"
          icon={<Icon name="pdf-file" className="size-16" />}
        />
      </div>
    </object>
  )
}

export default ViewPDF
