import React from 'react'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
import { message } from 'antd'

import { OrderItem, ExcelGenerator } from 'domain/orderItem/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { DownloadButton, DownloadFileDropDown } from 'ui'
import { onlyNumbers } from 'utils/formatters'

interface Props {
  orderItem: OrderItem
  excel?: ExcelGenerator
}

function ExportFile({ orderItem, excel }: Props) {
  const { fileURL, researchResults } = orderItem

  if (excel && researchResults && researchResults.length > 0) {
    return <ExportOptions orderItem={orderItem} excel={excel} />
  }

  return <DownloadButton fileURL={fileURL} />
}

function ExportOptions({ orderItem, excel }: Props) {
  const { fileURL, researchResults } = orderItem

  const onHandleExcel = async () => {
    const hide = message.loading('Preparando o download...')
    const workbook = new Excel.Workbook()
    const fileName = `cbrdoc-${orderItem.service.code}-${onlyNumbers(orderItem.cpfOrCnpj)}`
    const sheet = workbook.addWorksheet(fileName)

    sheet.columns = excel!.columns.map(col => ({
      header: col.title,
      width: col.width ?? 20,
      bold: true
    }))

    researchResults!.forEach(data => {
      const row = excel!.columns.map(col => {
        // Trata os campos de localização, no formato LabelInValue
        if (Array.isArray(col.dataIndex)) {
          const field = col.dataIndex[0] as FieldName
          return data[field].label
        }

        const transformValue = excel!.transform?.[col.dataIndex as FieldName]
        const value = data[col.dataIndex as FieldName]

        if (transformValue) {
          return transformValue(value)
        }

        return value
      })

      sheet.addRow(row)
    })

    const buf = await workbook.xlsx.writeBuffer()
    saveAs(new Blob([buf]), fileName + '.xlsx')
    hide()
  }

  return <DownloadFileDropDown pdfURL={fileURL} onHandleExcel={onHandleExcel} />
}

export default ExportFile
