import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { API } from 'api/network/v2'
import { useGetIsHydratedFromURL } from 'domain/app'
import { useCurrentUser } from 'domain/auth'
import { useIsCustomerPostPaid } from 'domain/customers'
import { convertOrderItemSummary } from 'domain/orderItem'
import { OrderItemAPI } from 'domain/orderItem/typesAPI'
import { useGetPeriodFilter } from 'domain/reports/filters'
import { getFilters } from 'utils/transformData'
import { useCurrentPage, usePageCount, usePageSize, useTotalCount } from '../state/pagination'
import { useGetFilters } from '../state/filters'

export default function useOrdersByPeriodQuery() {
  const authState = useCurrentUser()
  const isPostPaid = useIsCustomerPostPaid()
  const [currentPage, setCurrentPage] = useCurrentPage()
  const [pageCount, setPageCount] = usePageCount()
  const [totalCount, setTotalCount] = useTotalCount()
  const [pageSize, setPageSize] = usePageSize()
  const filters = useGetFilters()
  const period = useGetPeriodFilter()
  const isHydrated = useGetIsHydratedFromURL()

  const query = useQuery(
    ['orders', currentPage, pageSize, period, authState, filters],
    async () => {
      const response: AxiosResponse<OrderItemAPI[]> = await API.get('/orders', {
        params: {
          append: ['ai_service_name', 'register', 'is_expired'],
          include: ['service', 'user'],
          'per-page': pageSize,
          page: currentPage,
          sort: '-placed_at',
          ...getFilters({
            name_or_id: filters.searchTerm,
            placed_between: period,
            status: filters.statuses,
            user_id: filters.ownersIds,
            result: filters.results,
            expired: filters.isExpired ? 'true' : undefined,
            service_id: filters.servicesIds,
          }),
        },
      })
      
      setPageCount(Number(response.headers['x-pagination-page-count']))
      setTotalCount(Number(response.headers['x-pagination-total-count']))

      return response.data?.map(item => convertOrderItemSummary(item, isPostPaid!))
    },
    {
      keepPreviousData: true,
      enabled: Boolean(period && isPostPaid !== undefined && isHydrated),
    }
  )

  return {
    ...query,
    pagination: {
      currentPage,
      setCurrentPage,
      pageCount,
      totalCount,
      pageSize,
      setPageSize,
    },
  }
}
