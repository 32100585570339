import React from 'react'
import { Button } from 'antd'

import { useCreateKitCurrentStep } from 'domain/kits/helpers'
import { useCreateKit } from './CreateKitContext'

export default function CreatekitSubmitButton() {
  const { items, saving } = useCreateKit()
  const currentStep = useCreateKitCurrentStep()

  return (
    <Button
      disabled={items.length === 0 || saving}
      loading={saving}
      form="kit-register-form"
      htmlType="submit"
      type="primary"
      className="mt-3 text-base"
      block
    >
      {currentStep === 'fixar-dados' ? 'Criar Kit' : 'Configurar Kit'}
    </Button>
  )
}
