import { ResearchFlow } from 'domain/createOrder/researches'
import { NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'
import config from '../pesquisaDadosCadastraisConfig'

export default function CreateOrderPesquisaDadosCadastrais() {
  return (
    <ResearchFlow
      header={<p className="mb-5 text-gray-600">Informe o CPF que deseja pesquisar.</p>}
      renderFormItem={(itemId: number) => <PesquisaDadosCadastraisForm itemId={itemId} />}
    />
  )
}

function PesquisaDadosCadastraisForm({ itemId }: { itemId: number }) {
  return (
    <>
      <CPFInput itemId={itemId} autoFocus={itemId === 0} />
      <NameField itemId={itemId} field={config.formFields.nome_pf} />
    </>
  )
}
