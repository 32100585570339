import { Form } from 'antd'
import { InputProps } from 'antd/lib/input'
import { MaskedInput } from 'antd-mask-input'

import { getFieldName } from 'domain/createOrder/shared'
import { Fields } from 'domain/servicesCBRdoc'
import { validateTituloEleitor } from 'utils/validators'

interface Props extends Pick<InputProps, 'autoFocus' | 'name' | 'onChange' | 'required'> {
  itemId?: number
}

export default function TituloEleitorField({
  name = Fields.titulo_eleitor.name,
  itemId,
  onChange,
  autoFocus,
  required,
}: Props): JSX.Element {
  const fieldName = getFieldName(name, itemId)

  const validationRules = [
    { required, message: 'Campo obrigatório.' },
    {
      validator: (rule: object, value: string) => {
        return validateTituloEleitor(value) ? Promise.resolve() : Promise.reject('Informe um Título válido.')
      },
    },
  ]

  return (
    <Form.Item name={fieldName} label="Título de eleitor" rules={validationRules}>
      <MaskedInput
        mask={Fields.titulo_eleitor.mask!}
        placeholder={Fields.titulo_eleitor.mask as string}
        maskOptions={maskOptions}
        onChange={onChange}
        autoFocus={autoFocus}
      />
    </Form.Item>
  )
}

const maskOptions = { placeholderChar: ' ' }
