import React, { SVGProps } from 'react'

export interface SvgProps extends SVGProps<SVGSVGElement> {
  width?: string | number
  height?: string | number
  size?: string | number
  viewBox?: string
  color?: string
  children?: React.ReactNode
  fill?: string
  stroke?: string
  strokeWidth?: number
  className?: string
  style?: React.CSSProperties
}

function Svg({ 
  size = '1em',
  width = '1em',
  height = '1em',
  viewBox = '0 0 22 22',
  children,
  color,
  fill,
  stroke,
  strokeWidth,
  className,
  style,
  ...props
}: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      zoomAndPan="disable"
      width={size ?? width}
      height={size ?? height}
      viewBox={viewBox}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      className={className}
      style={style}
      color={color}
      {...props}
    >
      {children}
    </svg>
  )
}

export default Svg
