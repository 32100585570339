import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ChosenPaymentMethod } from 'domain/checkout/types'
import { Customer } from 'domain/customers/types'

import { useAppState } from '../store'

type SessionSliceState = {
  customer: Customer | undefined
  payment: ChosenPaymentMethod | undefined
}

export const initialState: SessionSliceState = {
  customer: undefined,
  payment: undefined
}

const sessionStateSlice = createSlice({
  name: 'sessionState',
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<Customer>) => {
      state.customer = action.payload
    },
    setPayment: (state, action: PayloadAction<ChosenPaymentMethod | undefined>) => {
      state.payment = action.payload
    },
  },
})

export const sessionStateActions = sessionStateSlice.actions
export const useSessionState = () => useAppState(state => state.session)

export default sessionStateSlice.reducer
