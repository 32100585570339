import { useDownload } from 'utils/export'

export default function useDownloadOcrTranscription() {
  const [download, loading] = useDownload()

  async function handleOcrDownload(ocrId: number, format: 'docx' | 'pdf' = 'docx') {
    const url = `ocrs/${ocrId}/transcription/download/${format}`
    await download(url)
  }

  return {handleOcrDownload, loading}
}
