import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { KitOrderStepURI } from '../types'
import DetailsStep from './detailsStep/KitOrderDetailsStep'
import ServiceDataStep from './KitOrderServicesStep'

export default function KitOrderFlow() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}${KitOrderStepURI.COMMON_DATA}`} exact>
        <DetailsStep />
      </Route>
      <Route path={path} exact>
        <ServiceDataStep />
      </Route>
    </Switch>
  )
}
