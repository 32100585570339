import { Popover, Tag } from 'antd'
import { getUniqueGroups } from 'domain/groups/helpers'
import { OrderItemListing } from 'domain/orderItem/types'
import { useMemo } from 'react'

interface Props {
  orderItems: OrderItemListing[]
}

const OrderOverviewGroups = ({ orderItems }: Props) => {
  const uniqueGroups = getUniqueGroups(orderItems)

  if (!uniqueGroups.length) return <div className="text-sm ml-8">Sem grupo atrelado</div>

  const allGroupTags = useMemo(
    () => (
      <div className="flex flex-wrap max-w-lg gap-2">
        {uniqueGroups.map(group => (
          <Tag
            bordered={false}
            style={{
              backgroundColor: group.color,
            }}
            className="text-white px-2 py-1 m-0"
            key={group.id}
          >
            {group.name}
          </Tag>
        ))}
      </div>
    ),
    [uniqueGroups]
  )

  const splitedGroups = uniqueGroups.slice(0, 3)
  const overflowCount = uniqueGroups.length - 3

  return (
    <Popover title="Todos grupos" content={allGroupTags} placement="bottom">
      <div className="ml-8 flex flex-row items-center">
        {splitedGroups.map(group => (
          <Tag
            bordered={false}
            style={{
              backgroundColor: group.color,
            }}
            className="text-white px-2 py-1 overflow-hidden text-ellipsis max-w-20"
            key={group.id}
          >
            {group.name}
          </Tag>
        ))}
        {overflowCount > 0 && (
          <Tag bordered={false} className="bg-primary text-white px-2 py-1">
            +{overflowCount}
          </Tag>
        )}
      </div>
    </Popover>
  )
}

export default OrderOverviewGroups
