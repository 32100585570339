import { LabelInValue } from '../types'

const civilStatus: LabelInValue[] = [
  { value: 'Solteiro', label: 'Solteiro(a)' },
  { value: 'Casado', label: 'Casado(a)' },
  { value: 'Divorciado', label: 'Divorciado(a)' },
  { value: 'Viúvo', label: 'Viúvo(a)' },
  { value: 'Separado', label: 'Separado(a)' },
]

export default civilStatus
