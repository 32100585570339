import React from 'react'
import { Button, Form, Input } from 'antd'

import { Modal } from 'ui'

interface Props {
  currentName: string
  open: boolean
  loading: boolean
  onCancel: () => void
  onSave: (newName: string) => Promise<void>
}

export default function EditOrderNameModal({ currentName, open, onCancel, onSave, loading }: Props) {
  const [form] = Form.useForm()

  const handleSubmit = (values: any) => {
    onSave(values.newName)
  }

  return (
    <Modal open={open} title="Alterar nome da compra" onCancel={onCancel}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
        scrollToFirstError
        initialValues={{
          newName: currentName,
        }}
        className="max-w-lg"
      >
        <Form.Item name="newName" label="Nome" rules={requiredRule}>
          <Input maxLength={120} />
        </Form.Item>

        <Form.Item className="mt-7">
          <Button htmlType="submit" type="primary" block loading={loading}>
            Alterar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const requiredRule = [
  {
    required: true,
    message: 'Informe o nome do item da compra',
  },
]
