import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

import { stringToDate } from 'utils/dateTime'
import { useSetSelectedMonth, useSetOperation, useSetCurrentPage } from '../state'

function useInvoicesURLParamsEffect() {
  const history = useHistory()
  const location = history.location
  const setSelectedMonth = useSetSelectedMonth()
  const setOperation = useSetOperation()
  const setCurrentPage = useSetCurrentPage()

  useEffect(() => {
    if (location.search) {
      const { operation, month, p } = queryString.parse(location.search)

      if (month) {
        setSelectedMonth(stringToDate(month as string))
      }

      if (operation) {
        setOperation(operation as string)
      }

      if (p) {
        setCurrentPage(Number(p))
      }
    }
  }, [])
}

export default useInvoicesURLParamsEffect
