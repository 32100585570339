import { useMemo } from 'react'
import { Tabs, TabsProps } from 'antd'

import { useIsCustomerPostPaid } from 'domain/customers'
import {
  AIReportTab,
  InsightsReportTab,
  OrdersReportTab,
  PeriodFilter,
  useSetInitialValuesFromURL,
  useTabs,
} from 'domain/reports'
import { PageHeader } from 'layouts'

export default function ReportsPage() {
  const isPostPaid = useIsCustomerPostPaid()
  const [tab, setTab] = useTabs()

  const items = useMemo(() => {
    const tabItems: TabsProps['items'] = [
      {
        key: 'orders',
        label: 'Resumo',
        children: <OrdersReportTab />,
      },
      {
        key: 'ai',
        label: 'Extração de Dados',
        children: <AIReportTab />,
      },
    ]

    if (isPostPaid) {
      tabItems.push({
        key: 'analytics',
        label: 'Insights',
        children: <InsightsReportTab />,
      })
    }

    return tabItems
  }, [isPostPaid])

  useSetInitialValuesFromURL()

  return (
    <>
      <PageHeader title="Relatórios" />
      <PeriodFilter />
      <div className="px-7 pt-4 bg-gray-100">
        <Tabs
          items={items}
          activeKey={tab}
          onChange={setTab as any}
          tabBarStyle={tabStyle}
          className="mask-text"
        />
      </div>
    </>
  )
}

const tabStyle = { height: 30 }
