import React from 'react'

import { OrderPrice, useCreateOrderState } from 'domain/createOrder/document'
import { DeliveryInfo, ServiceCard } from 'domain/createOrder/shared/components'
import { Sidebar } from 'layouts'

function AIOrderSidebar() {
  const { orderDetails, selectedModel } = useCreateOrderState()

  return (
    <Sidebar>
      <ServiceCard serviceName="Extração de Dados">
        {orderDetails.estimatedDeliveryTime && (
          <DeliveryInfo estimatedDeliveryTime={orderDetails.estimatedDeliveryTime} />
        )}
      </ServiceCard>

      {selectedModel && <OrderPrice />}
    </Sidebar>
  )
}

export default AIOrderSidebar
