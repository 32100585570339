import React, { useState } from 'react'

import { useActionState, useFilters, useGetExplorerItem } from 'domain/myFiles/state'
import { ExplorerItem } from 'domain/myFiles/types'
import { ShareOrderModal } from 'domain/orderItem'
import { AlertNoOrders } from 'domain/orders'
import { removeFalsyEntries } from 'utils/transformData'

import GroupsAssociation from '../items/ExplorerGroupsAssociationInline'
import MoveItemModal from '../items/MoveItemModal'
import RenameItemModal from '../items/RenameItemModal'
import MyFilesTable, { MyFilesTableProps } from './MyFilesTable'
import MyFilesMassActions from './MyFilesMassActions'

interface Props extends Omit<MyFilesTableProps, 'rowSelection' | 'setSelectedItems' | 'setSelectedRowKeys'> {
  refetchData: () => Promise<void>
}

export default function MyFilesContent({
  refetchData,
  pagination,
  data,
  ...props
}: Props) {
  const [selectedItems, setSelectedItems] = useState<ExplorerItem[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const explorerItem = useGetExplorerItem()
  const [action, setAction] = useActionState()
  const filters = useFilters()

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any, selectedRows: any[]) => {
      setSelectedRowKeys(selectedRowKeys)
      setSelectedItems(selectedRows ?? [])
    },
  }

  const refetchAndCloseAction = () => {
    refetchData()
    setAction(undefined)
  }

  const noFiltersApplied = Object.keys(removeFalsyEntries(filters)).length === 0

  if (data?.length === 0 && noFiltersApplied) {
    return (
      <AlertNoOrders
        title="Você ainda não possui documentos para esse mês"
        description="Depois que você fizer um pedido ou adicionar arquivos, seus documentos aparecerão aqui"
      />
    )
  }

  return (
    <>
      <MyFilesTable
        data={data}
        setSelectedItems={setSelectedItems}
        setSelectedRowKeys={setSelectedRowKeys}
        pagination={pagination}
        rowSelection={rowSelection}
        {...props}
      />

      <MyFilesMassActions 
        items={selectedItems} 
        onClose={() => rowSelection.onChange([], [])} 
      />

      <>
        <MoveItemModal
          selectedItems={selectedItems.length > 0 ? selectedItems : explorerItem ? [explorerItem] : []}
          onItemMoved={() => {
            setSelectedRowKeys([])
            setSelectedItems([])
            refetchAndCloseAction()
          }}
          onFolderCreated={refetchData}
        />

        <GroupsAssociation />
        <RenameItemModal onFinish={refetchData} />

        {explorerItem?.order?.id && (
          <ShareOrderModal
            orderId={explorerItem.order.id}
            open={action === 'share'}
            onClose={() => setAction(undefined)}
          />
        )}
      </>
    </>
  )
}
