import { CertificateFlow } from 'domain/createOrder/certificates'
import { DateField, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'
import config from '../certidaoAntecedentesCriminaisConfig'

export default function CreateOrderCertidaoAntecedentesCriminais() {
  return (
    <CertificateFlow
      renderFormItem={(itemId: number) => <CertidaoAntecedentesCrimianisForm itemId={itemId} />}
    />
  )
}

const CertidaoAntecedentesCrimianisForm = ({ itemId }: { itemId: number }) => {
  return (
    <>
      <CPFInput itemId={itemId} autoFocus={itemId === 0} />
      <NameField itemId={itemId} field={nome_pf} />
      <DateField field={nascimento} itemId={itemId} />
      <NameField itemId={itemId} field={mae} />

    </>
  )
}

const { nascimento, nome_pf, mae } = config.formFields
