import { Form } from 'antd'
import { FormInstance } from 'antd/lib/form'

import { ApiError, ApiStatus } from 'api/types'
import { FormShippingAddressData } from 'domain/app/types'
import { ShippingAddress } from 'domain/checkout/types'
import { useUpdateCustomerProfile } from 'domain/customers'
import { ProfileForm } from 'domain/customers/types'
import { normalizeString, onlyNumbers } from 'utils/formatters'

type onSubmitForm = (values: FormShippingAddressData, isMainAddress: boolean) => Promise<ShippingAddress>

function useSaveShippingAddress(): [FormInstance, onSubmitForm, ApiStatus, ApiError | null] {
  const { updateCustomerProfile, status, error } = useUpdateCustomerProfile()

  const [form] = Form.useForm()

  const onSubmit: onSubmitForm = async (formData: FormShippingAddressData, isMainAddress) => {
    const address: ShippingAddress = {
      id: formData.name === 'Principal' ? 'main' : normalizeString(formData.name || ''),
      name: normalizeString(formData.name || ''),
      address_zip_code: onlyNumbers(formData.zip_code || ''),
      address_neighborhood: formData.neighborhood,
      address_public_place: formData.public_place,
      address_number: formData.number,
      address_city: formData.city,
      address_uf: formData.uf,
      address_complement: formData.complement,
    }

    if (isMainAddress) {
      const address: Partial<ProfileForm> = {
        address: formData.public_place,
        addressZipcode: formData.zip_code || '',
        addressNeighborhood: formData.neighborhood,
        addressNumber: formData.number,
        addressCity: formData.city,
        addressState: formData.uf,
        addressComplement: formData.complement,
      }

      await updateCustomerProfile(address)
    }

    return address
  }

  return [form, onSubmit, status, error]
}

export default useSaveShippingAddress
