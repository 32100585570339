import { useReducer, useState } from 'react'
import { Form, Input } from 'antd'

import { checkOCRSearchError } from 'domain/orders/helpers'
import { useGetIsOCRSearch, useGetSearchTerm } from 'domain/orders/state/filters'
import { CheckboxInput } from 'ui'

export default function OrdersSearchTermFilter() {
  const search = useGetSearchTerm()
  const isOCR = useGetIsOCRSearch()
  const [searchTerm, setSearchTerm] = useState(search)
  const [isOCRSearch, toggleIsOCRSearch] = useReducer(prev => !prev, isOCR)

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value)
  }

  const hasError = checkOCRSearchError(isOCRSearch, searchTerm)

  return (
    <>
      <Form.Item
        name="searchTerm"
        label={isOCRSearch ? 'Buscar em documentos' : 'Nome ou ID'}
        validateStatus={hasError ? 'error' : undefined}
        help={hasError ? 'Texto muito curto, tente com um texto maior!' : undefined}
        noStyle
      >
        <Input
          placeholder={isOCRSearch ? 'Busca no arquivo dos documentos' : 'Informe um nome ou ID'}
          onChange={handleChange}
          allowClear
        />
      </Form.Item>

      <CheckboxInput name="isOCRSearch" checked={isOCRSearch} onChange={toggleIsOCRSearch}>
        Buscar dentro de documentos
      </CheckboxInput>
    </>
  )
}
