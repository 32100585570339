import { onlyNumbers } from 'utils/formatters'

import MaskedInput, { MaskedInputProps } from './MaskedInput'

interface Props extends Omit<MaskedInputProps, 'mask' | 'onChange'> {
  onChange?: (value: string) => void
}

export default function ZipCodeInput({
  label = 'CEP',
  name = 'cep',
  onChange,
  className = '',
  ...props
}: Props) {
  return (
    <MaskedInput
      name={name}
      label={label}
      className={`w-40 ${className}`}
      mask="00.000-000"
      onChange={e => {
        if (onChange) {
          const onlyNumbersCep = onlyNumbers(e.target.value)
          onChange(onlyNumbersCep)
        }
      }}
      {...props}
    />
  )
}
