import React, { useState } from 'react'
import { Button, Input, Modal, message } from 'antd'

import ShareOrderModalItem from './ShareOrderModalItem'
import useShareOrderItem from '../../services/useShareOrderItem'

interface Props {
  orderItemId: string | number
}

function ShareOrderModalEmail({ orderItemId }: Props) {
  const [destinationEmail, setDestinationEmail] = useState<string>()
  const [destinationEmailVisible, setDestinationEmailVisible] = useState(false)
  const [shareOrder, loading] = useShareOrderItem()

  const onConfirm = () => {
    if (!destinationEmail?.trim()) {
      message.error('Informe um e-mail valido')
      return
    }

    Modal.confirm({
      title: 'Enviar e-mail',
      content: (
        <>
          Tem certeza que deseja compartilhar este documento com <b>{destinationEmail}</b>?
        </>
      ),
      onOk: () => {
        shareOrder({
          orderItemId: orderItemId,
          params: {
            via: 'email',
            destination_email: destinationEmail,
          },
          onSuccess: (data: any) => {
            if (data) {
              message.success('Documento compartilhado com sucesso!')
              setDestinationEmailVisible(false)
            }
          }
        })
      },
      okText: 'Sim',
      cancelText: 'Não',
    })
  }

  return (
    <>
      <ShareOrderModalItem
        text="E-mail"
        icon="mail"
        onClick={() => setDestinationEmailVisible(prev => !prev)}
        className={destinationEmailVisible ? 'bg-gray-100' : ''}
      />

      {destinationEmailVisible && (
        <div className="flex py-3 border-b border-gray-300">
          <Input
            placeholder="Informe o e-mail de destino"
            value={destinationEmail}
            onChange={e => setDestinationEmail(e.target.value)}
            className="mr-2"
          />
          <Button type="primary" className="w-40" loading={loading} onClick={onConfirm}>
            Enviar
          </Button>
        </div>
      )}
    </>
  )
}

export default ShareOrderModalEmail
