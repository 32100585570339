import dayjs from 'dayjs'
import { Form, Input, InputProps } from 'antd'
import { NamePath } from 'antd/lib/form/interface'

import { getFieldName } from 'domain/createOrder/shared'
import { FieldConfig } from '../types'

interface Props extends Omit<InputProps, 'defaultValue' | 'name' | 'value'> {
  field: FieldConfig
  name?: NamePath
  itemId?: number
}

export default function YearField({
  field,
  itemId,
  name = getFieldName(field.name, itemId),
  ...props
}: Props) {
  return (
    <Form.Item
      name={name}
      label={field.label}
      rules={!field.optional ? [requiredRule, ...validationRules] : validationRules}
    >
      <Input type="number" maxLength={4} min={1900} max={currentYear} {...props} />
    </Form.Item>
  )
}

const requiredRule = {
  required: true,
  message: 'Campo obrigatório',
}

const currentYear = dayjs().year()

const validationRules = [
  {
    validator: (rule: object, value: string = '') => {
      const yearString = value.trim()

      if (yearString.length < 4) {
        return Promise.reject('Informe o ano com quatro dígitos.')
      }

      const year = parseInt(value)

      return year < 1900 || year > currentYear ? Promise.reject('Ano inválido.') : Promise.resolve()
    },
  },
]
