import React from 'react'

interface Props {
  sectionTitle?: string | JSX.Element
  children: React.ReactNode
  width?: string
  padding?: string
}

export default function Sidebar({ sectionTitle, children, width = 'lg:w-100 lg:max-w-md', padding = 'p-7' }: Props) {
  return (
    <div className={`bg-white border-l border-gray-300 min-h-screen sm:w-full ${width}`}>
      <div className={`${padding} sticky top-0 flex flex-1 flex-col overflow-y-hidden`}>
        {!!sectionTitle && <h1 className="font-bold text-base text-gray-600 w-full border-b border-gray-300 pb-1 mb-4">{sectionTitle}</h1>}  

        {children}
      </div>
    </div>
  )
}
