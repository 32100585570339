import React from 'react'

import { RowItemsContainer } from 'ui'

import FiltersByStatus from './NotificationFiltersByStatus'
import FiltersByType from './NotificationFiltersByType'

function NotificationFilters() {
  return (
    <RowItemsContainer title="Filtrar por:">
      <FiltersByType />
      <FiltersByStatus />
    </RowItemsContainer>
  )
}

export default NotificationFilters
