import { saveAs } from 'file-saver'

async function downloadLink(url: string, fileName?: string) {
  try {
    saveAs(url, fileName)
    
  } catch (error) {
    console.log(`Error downloading file. ${error}`)
  }
}

export default downloadLink
 