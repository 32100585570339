import { Kit } from 'domain/kits/types'
import { GoBackButton, PageLayout } from 'layouts'
import { ActionsBar, Loading } from 'ui'

import KitOrderFlow from './KitOrderFlow'
import KitOrderBySpreadsheet_deprecated from './KitOrderBySpreadsheet_deprecated'
import KitOrderHeaderSteps from './KitOrderHeaderSteps'
import KitOrderSidebar from './KitOrderSidebar'
import { useLoadkitOrderInitialState } from '../services'

export default function KitOrder({ kit }: { kit: Kit }) {
  const isReady = useLoadkitOrderInitialState(kit)

  if (!isReady) return <Loading />

  return (
    <PageLayout pageTitle="Fazer pedido" extra={<KitOrderHeaderSteps />} sidebar={<KitOrderSidebar />}>
      <ActionsBar short>
        <GoBackButton />
        <KitOrderBySpreadsheet_deprecated />
      </ActionsBar>
      
      <div className="mt-4">
        <KitOrderFlow />
      </div>
    </PageLayout>
  )
}
