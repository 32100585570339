import React from 'react'
import { Icon } from 'ui'

function CreditsAdvantages() {
  return (
    <div className="pb-7 lg:p-7 lg:pt-6 lg:border border-gray-500 rounded-sm lg:max-w-xs">
      <h2 className="uppercase text-center mb-6">Confira todas as vantagens</h2>

      <Advantage text="Créditos que nunca expiram" />
      <Advantage text="Ganhe bônus a cada recarga" />

      <div className="flex">
        <Icon name="award" className="text-2xl mr-2 flex-none text-secondary-500" />
        <div>
          <h3 className="text-gray-700 mb-1 font-bold">Garantia de serviço</h3>
          <p>
            Créditos de volta caso o pedido não seja encontrado (descontando a taxa de serviço de R$39,90).
          </p>
        </div>
      </div>
    </div>
  )
}

function Advantage({ text }: { text: string }) {
  return (
    <div className="flex flex-row flex-nowrap items-center gap-3 mb-3">
      <Icon name="check" className="text-xl text-secondary-500" />
      <span className="flex flex-wrap">{text}</span>
    </div>
  )
}

export default CreditsAdvantages
