import React from 'react'
import { Form } from 'antd'

import { Section } from 'layouts'
import { useCreateOrderState } from 'domain/createOrder/document/state'
import { getLocationUrl } from 'domain/servicesCBRdoc/helpers/location'
import { SubmitButton } from 'ui'

import { useCreateDueDiligenceNextStep } from '../services'
import { SelectLocationFields } from '../../document'
import LocationInput from './LocationInput'

function LocationStep() {
  const [form] = Form.useForm()
  const goToNextStep = useCreateDueDiligenceNextStep('localizacao')
  const { formData, selectedLocation } = useCreateOrderState()
  const { url_cidade, url_uf } = getLocationUrl(selectedLocation)

  const initialValues = {
    url_uf,
    url_cidade,
    local_servico: formData.local_servico ?? '',
  }

  return (
    <Section heading="Onde você deseja fazer a diligência?">
      <Form
        form={form}
        layout="vertical"
        onFinish={goToNextStep}
        requiredMark={false}
        className="pb-24"
        initialValues={initialValues}
      >
        <SelectLocationFields autoFocus />
        <LocationInput />
        <SubmitButton />
      </Form>
    </Section>
  )
}

export default LocationStep
