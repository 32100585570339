import { LabelInValue } from '../types'

const NATIONALITIES = [
  'Antiguano',
  'Argentino',
  'Bahamense',
  'Barbadiano/Barbadense',
  'Belizenho',
  'Boliviano',
  'Brasileiro',
  'Chileno',
  'Colombiano',
  'Costarriquenho',
  'Cubano',
  'Dominicano',
  'Equatoriano',
  'Salvadorenho',
  'Granadino',
  'Guatemalteco',
  'Guianês',
  'Guianense',
  'Haitiano',
  'Hondurenho',
  'Jamaicano',
  'Mexicano',
  'Nicaraguense',
  'Panamenho',
  'Paraguaio',
  'Peruano',
  'Portorriquenho',
  'Dominicana',
  'São-cristovense',
  'São-vicentino',
  'Santa-lucense',
  'Surinamês',
  'Trindadense',
  'Uruguaio',
  'Venezuelano',
  'Alemão',
  'Austríaco',
  'Belga',
  'Croata',
  'Dinamarquês',
  'Eslovaco',
  'Esloveno',
  'Espanhol',
  'Francês',
  'Grego',
  'Húngaro',
  'Irlandês',
  'Italiano',
  'Noruego',
  'Holandês',
  'Polonês',
  'Português',
  'Britânico',
  'Inglês',
  'Galês',
  'Escocês',
  'Romeno',
  'Russo',
  'Sérvio',
  'Sueco',
  'Suíço',
  'Turco',
  'Ucraniano',
  'Americano',
  'Canadense',
  'Angolano',
  'Moçambicano',
  'Sul', // Sul?
  'Zimbabuense',
  'Argélia',
  'Comorense',
  'Egípcio',
  'Líbio',
  'Marroquino',
  'Ganés',
  'Queniano',
  'Ruandês',
  'Ugandense',
  'Bechuano',
  'Marfinense',
  'Camaronense',
  'Nigeriano',
  'Somali',
  'Australiano',
  'Neozelandês',
  'Afegão',
  'Saudita',
  'Armeno',
  'Bangladesh',
  'Chinês',
  // 'Sul', // Sul?
  'Norte', // Norte?
  'Indiano',
  'Indonésio',
  'Iraquiano',
  'Iraniano',
  'Israelita',
  'Japonês',
  'Malaio',
  'Nepalês',
  'Omanense',
  'Paquistanês',
  'Palestino',
  'Qatarense',
  'Sírio',
  'Cingalês',
  'Tailandês',
  'Leste', // Leste?
  'Árabe/Emiratense',
  'Vietnamita',
  'Iemenita',
]

const nationalities: LabelInValue[] = NATIONALITIES.map(label => ({ label, value: label }))

export default nationalities
