import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { useIsCustomerPostPaid } from 'domain/customers'

function useDontKnowBookPagePriceQuery() {
  const isPostPaid = useIsCustomerPostPaid()

  return useQuery(
    'dont-know-book-page-price',
    async () => {
      const response: AxiosResponse<any> = await AuthorizedAPI.get('/services/dont-know-book-page-price')
      return response.data
    },
    { enabled: !isPostPaid }
  )
}

export default useDontKnowBookPagePriceQuery
