import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { convertUser } from 'domain/users'
import { User, UserAPI } from 'domain/users/types'
import { getStorageData, setStorageData } from 'utils/localStorage'

const initialAuthState: User = {
  accessToken: '',
  customerId: 0,
  email: '',
  id: 0,
  lastRequest: '',
  lastLogin: '',
  name: '',
  phone: '',
  permissions: []
}

const initialOrPersistedAuth: User = getStorageData('auth', initialAuthState)

const authSlice = createSlice({
  name: 'auth',
  initialState: initialOrPersistedAuth.accessToken ? initialOrPersistedAuth : initialAuthState,
  reducers: {
    setAuth: (_, action: PayloadAction<UserAPI>) => {
      const userData = convertUser(action.payload)
      setStorageData('auth', userData)

      return userData
    },
  },
})

export const authActions = authSlice.actions

export default authSlice.reducer
