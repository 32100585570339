import React from 'react'
import { Link } from 'react-router-dom'

import Routes from 'app/routes/Routes'
import { NotificationOrderItem } from 'domain/notifications/types'
import { getDisplayItemLocation } from 'domain/orders/helpers'
import { SelectedLocation } from 'domain/servicesCBRdoc/types'

function LinkToOrderItem({
  orderItem,
}: {
  orderItem: Pick<NotificationOrderItem, 'id' | 'locationInfo' | 'name' | 'orderItemNumber'>
}) {
  return (
    <Link to={Routes.ViewOrder(orderItem.id)} className="text-slate-500 hover:text-primary cursor-pointer">
      <p>
        {orderItem.name} &nbsp; #{orderItem.orderItemNumber}
      </p>
      {orderItem.locationInfo && <ShowLocation location={orderItem.locationInfo} />}
    </Link>
  )
}

function ShowLocation({ location }: { location: SelectedLocation }) {
  const locationInfo = getDisplayItemLocation(location)

  return <p className="text-gray-500 text-xs group-hover:text-gray-600">{locationInfo}</p>
}

export default LinkToOrderItem
