import React, { useEffect, useMemo, useState } from 'react'
import { AutoComplete } from 'antd'
import { useDebounce } from 'use-debounce'
import { Link } from 'react-router-dom'

import { Routes } from 'app/routes'
import { TypeDescription } from 'domain/myFiles/data'
import { useExplorerSearchQuery } from 'domain/myFiles/services'
import { useFiltersState } from 'domain/myFiles/state'
import { ExplorerItemsSearchResult } from 'domain/myFiles/types'
import { SearchInput } from 'ui'

export default function SearchFiles() {
  const [filters, setFilters] = useFiltersState()
  const [searchInputValue, setSearchInputValue] = useState(filters.name ?? '')
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedValue] = useDebounce(searchTerm, 300)

  const { data, isFetching, isLoading } = useExplorerSearchQuery(debouncedValue)

  const options = useMemo(() => {
    const searchOptions: any[] = []

    if (data) {
      data.forEach(option => {
        searchOptions.push({
          label: (
            <Link to={getItemURL(option)} className="block flex-1">
              {option.name} ({TypeDescription[option.type]})
            </Link>
          ),
        })
      })
    }

    return searchOptions
  }, [data])

  const onSearchEnter = () => {
    setFilters({ ...filters, name: searchInputValue.trim() })
  }

  useEffect(() => {
    if (filters.name) {
      setSearchInputValue(filters.name)
    }
  }, [filters.name])

  return (
    <AutoComplete
      dropdownMatchSelectWidth={450}
      options={options}
      notFoundContent={
        debouncedValue.length > 2 ? (
          <>{!isFetching && <span className="p-2 text-sm text-gray-600">Nada encontrado</span>}</>
        ) : (
          <span className="p-2 text-sm text-gray-600">Informe pelo menos 3 caracteres</span>
        )
      }
      value={searchInputValue}
      onSearch={value => {
        setSearchInputValue(value)
        if (value.length > 2) {
          setSearchTerm(value)
        }
      }}
    >
      <SearchInput placeholder="Pesquise aqui..." onSearch={onSearchEnter} loading={isLoading} className="flex-1 max-w-sm"/>
    </AutoComplete>
  )
}

const getItemURL = ({ id, orderItemId, type }: ExplorerItemsSearchResult) => {
  if (orderItemId) {
    return Routes.ViewOrder(orderItemId)
  }

  if (type === 'folder') {
    return Routes.MyFilesFolder(id)
  }

  return Routes.ViewFile(id)
}
