import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { convertAIQuestion } from 'domain/openAI/models'
import { AIQuestionAPI } from 'domain/openAI/typesAPI'

export default function useAIEnrichQuery(
  ocrId: number | undefined,
  modelId: number | undefined,
  options: any = {}
) {
  return useQuery(
    ['AI', 'answers', ocrId, modelId],
    async () => {
      const response = await AuthorizedAPI.get<AIQuestionAPI[]>(
        `/ocrs/${ocrId}/ai/models/${modelId}/answers`
      )
      return response.data?.map(convertAIQuestion) ?? []
    },
    {
      enabled: Boolean(ocrId && modelId),
      ...options,
    }
  )
}
