import React from 'react'
import { Button } from 'antd'

import { HelpPopover } from 'domain/app'

function FieldNameHelp({ className }: { className?: string }) {
  return (
    <HelpPopover
      content={
        <>
          <p className="mb-1">
            Aqui você pode incluir qual o título do campo que deseja extrair dos documentos. Alguns exemplos
            são: “Nome”, “Valor”, “Endereço”, etc.
          </p>
          <Button
            type="primary"
            ghost
            href="https://suporte.cbrdoc.com.br/hc/pt-br/articles/17456620798107"
            target="_blank"
          >
            Saiba mais
          </Button>
        </>
      }
      title="Nome do campo"
      className={className}
    />
  )
}

export default FieldNameHelp
