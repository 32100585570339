import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { FORMATO, TIPO_PESSOA } = FieldName

type CertidaoPropriedadeAeronaveFields = Record<
  FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ,
  FieldConfig
>

class CertidaoPropriedadeAeronave extends Certificate {
  formFields: CertidaoPropriedadeAeronaveFields = {
    cpf: Fields.cpf,
    cnpj: Fields.cnpj,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
  }

  constructor() {
    super({
      id: 61,
      priceByFields: [FORMATO, TIPO_PESSOA],
      shortName: 'Propriedade de Aeronave',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoPropriedadeAeronave().initialize()

export default service
