import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { DataStep, ExtrasStep, LocationStep, Sidebar, Steps } from 'domain/createOrder/dueDiligence'
import { GoBackButton, PageLayout } from 'layouts'
import { ActionsBar } from 'ui'

function CreateDueDiligencePage() {
  const { path } = useRouteMatch()

  return (
    <PageLayout pageTitle="Solicitar diligência" extra={<Steps />} sidebar={<Sidebar />}>
      <ActionsBar className="mb-2">
        <GoBackButton />
      </ActionsBar>

      <Switch>
        <Route path={[path, `${path}/localizacao`]} exact>
          <LocationStep />
        </Route>

        <Route path={`${path}/dados`} exact>
          <DataStep />
        </Route>

        <Route path={`${path}/extras`} exact>
          <ExtrasStep />
        </Route>
      </Switch>
    </PageLayout>
  )
}

export default CreateDueDiligencePage
