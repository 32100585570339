import { CreateOrderLegalEntityTabs, useCreateOrderState } from 'domain/createOrder/document'
import { ResearchFlow } from 'domain/createOrder/researches'
import { CNPJAndNameFields, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput, Heading } from 'ui'

import config from '../pesquisaBensConfig'
import PreferenceField from './PreferenceField'

export default function CreateOrderPesquisaBens() {
  return (
    <ResearchFlow renderFormItem={(itemId: number) => <PesquisaBensForm itemId={itemId} />} />
  )
}

function PesquisaBensForm({ itemId }: { itemId: number }) {
  const { selectedType } = useCreateOrderState()
  const inputProps = { itemId, autoFocus: itemId === 0 }

  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={
          <>
            <CPFInput {...inputProps} />
            <NameField itemId={itemId} field={nome_pf} />
          </>
        }
        juridica={<CNPJAndNameFields companyField={nome_pj} {...inputProps} />}
      />

      {selectedType === 'completa' && (
        <>
          <hr className="my-5" />
          <Heading size="3" text={`${preferencia.label} (opcional)`} />
          <PreferenceField itemId={itemId} />
          <hr className="my-5" />
        </>
      )}
    </>
  )
}

const { nome_pf, nome_pj, preferencia } = config.formFields
