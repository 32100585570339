import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { showErrorModal } from 'api/errors'
import { ApiStatus } from 'api/types'
import { RoutePaths } from 'app/routes'
import { onlyNumbers } from 'utils/formatters'

import { checkCNPJAPI } from '../customersAPI'

function useCheckCNPJ() {
  const history = useHistory()
  const [status, setStatus] = useState<ApiStatus>('idle')

  const checkCNPJ = async (cnpj: string) => {
    try {
      setStatus('loading')

      const strippedCNPJ = onlyNumbers(cnpj)
      
      await checkCNPJAPI(strippedCNPJ)

      history.push(RoutePaths.REGISTER_CNPJ + '/' + strippedCNPJ)
    } catch (error: any) {
      setStatus('error')
      showErrorModal(error, { message: 'CNPJ inválido', description: error?.message ?? error })
    }
  }

  return {
    checkCNPJ,
    loading: status === 'loading',
  }
}

export default useCheckCNPJ
