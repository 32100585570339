import { Collapse, CollapseProps, FormListFieldData } from 'antd'

import { AddFormItemButton } from 'ui'
import DeletePartButton from './DeletePartButton'

interface Props extends Pick<CollapseProps, 'activeKey'> {
  add: () => void
  description: 'Outorgante' | 'Outorgado'
  form: JSX.Element
  index: number
  item: FormListFieldData
  isSubmitting: boolean | undefined
  onChange: (key: number | undefined) => void
  remove: (index: number | number[]) => void
  totalItems: number
  disableAdd?: boolean
}

export default function RGICollapse({
  activeKey,
  form,
  item,
  isSubmitting,
  onChange,
  add,
  remove,
  description,
  totalItems,
  index,
  disableAdd,
  ...props
}: Props) {
  return (
    <Collapse
      accordion
      expandIconPosition="start"
      activeKey={activeKey}
      onChange={() => onChange!(activeKey === item.key ? undefined : item.key)}
      items={[
        {
          key: item.key,
          label: (
            <span className="font-bold">
              {description} {totalItems > 1 ? ` (${index + 1})` : ''}
            </span>
          ),
          children: (
            <>
              {form}              

              <AddFormItemButton
                label={`Adicionar outro ${description}`}
                disabled={isSubmitting || disableAdd}
                onSuccess={() => add()}
              />
            </>
          ),
          extra: (
            <DeletePartButton
              isDeletable={totalItems > 1}
              description={description}
              onConfirm={() => remove(index)}
              disabled={isSubmitting}
            />
          ),
        },
      ]}
      className="mb-4"
      {...props}
    />
  )
}
