import { Icon } from 'ui'
import PixViewData from './PixViewData'

interface Props {
  qrCode: string
  pixKey: string
}

export default function Pix({ qrCode, pixKey }: Props) {
  const markerClassList = 
    'bg-gray-300 min-w-10 aspect-square rounded-full text-secondary-500 font-bold text-base p-2 flex items-center justify-center'

  return (
    <div className="flex flex-col">
      <h1 className="flex flex-row items-center gap-2">
        <Icon name="checkmark-circle" size={30} className="text-green-500" />
        <span className="font-bold mt-2 text-2xl">Agora é só efetuar o pagamento!</span>
      </h1>

      <p className="my-2 text-center leading-4">
        Você tem 30 minutos para efetuar o pagamento. <br />
        Após este prazo esta chave PIX será cancelada.
      </p>

      <PixViewData qrCode={qrCode} pixKey={pixKey} />

      <div className="mt-8">
        <div className="flex items-center mb-4">
          <div className={markerClassList}> 1 </div>
          <p className="ml-4 font-normal text-xs leading-3 text-secondary-500">
            Para pagar, tenha em mãos seu celular, abra o aplicativo do seu banco, localize a opção PIX
          </p>
        </div>
        <div className="flex items-center mb-4">
          <div className={markerClassList}> 2 </div>
          <p className="ml-4 text-xs text-secondary-500 leading-3">
            Use a câmera do seu celular para fazer a leitura do QR Code, confira os dados do pedido para
            confirmar que está tudo certo e realize o pagamento. Se você Clicou no botão ”Copiar chave PIX”,
            basta colar o código, avançar para a próxima etapa, conferir os dados e pagar.
          </p>
        </div>
        <div className="flex items-center mb-4">
          <div className={markerClassList}> 3 </div>
          <p className="ml-4 my-3 text-xs text-secondary-500 leading-3">
            Após realizar o pagamento, você receberá a por e-mail a confirmação da compra.
          </p>
        </div>
      </div>
    </div>
  )
}
