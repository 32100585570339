import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidalAmbientalFields = Record<
  FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ | FieldName.URL_UF,
  FieldConfig
>

type CertidaoAmbientalDynamicFields = Record<
  | FieldName.DATA_NASCIMENTO
  | FieldName.MOTIVO_SOLICITACAO
  | FieldName.RG
  | FieldName.RG_DATA_EXPEDICAO
  | FieldName.RG_EXPEDIDOR
  | FieldName.RG_UF,
  FieldConfig
>

const { TIPO_PESSOA, URL_UF } = FieldName

class CertidaoAmbiental extends Certificate {
  formFields: CertidalAmbientalFields = {
    url_uf: Fields.url_uf,
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
  }

  dynamicFields: CertidaoAmbientalDynamicFields = {
    nascimento: Fields.nascimento,
    rg: Fields.rg,
    rg_expedidor: Fields.rg_expedidor,
    rg_data_expedicao: Fields.rg_data_expedicao,
    rg_uf_expedicao: Fields.rg_uf_expedicao,
    motivo_solicitacao: Fields.motivo_solicitacao,
  }

  constructor() {
    super({
      availableFormatsFields: [URL_UF],
      extraInfoFields: [URL_UF, TIPO_PESSOA],
      id: 58,
      priceByFields: [URL_UF],
      shortName: 'Certidão Ambiental',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoAmbiental().initialize()

export default service
