import { Button } from 'antd'
import { useReducer } from 'react'

import { SimilarOrder } from 'domain/createOrder/types'
import { KitItem } from 'domain/kits/types'
import KitSimilarOrdersModal from '../KitSimilarOrdersModal'

type Props = {
  rowId: number
  addToCart: (params: any) => any
  similarOrders: SimilarOrder[]
  setRows: React.Dispatch<React.SetStateAction<any[]>>
}

export default function KitSpreadsheetAddToCartRowSimilar({
  rowId,
  addToCart,
  setRows,
  similarOrders,
}: Props) {
  const [open, toggleOpen] = useReducer(prev => !prev, false)

  return (
    <div>
      <Button type="link" onClick={toggleOpen}>
        Verificar similares
      </Button>

      <KitSimilarOrdersModal
        addToCart={(itemsToAdd: KitItem[]) => addToCart({ items: itemsToAdd, checkSimilars: false })}
        similarOrders={similarOrders}
        open={open}
        onClose={toggleOpen}
        onResetOrRedirect={() => setRows(prev => prev.filter(row => row.rowId !== rowId))}
      />
    </div>
  )
}
