import { Form, FormInstance, message } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { errorHandler } from 'api/errors'
import {
  AddFormItemButton,
  createOrderActions,
  useAddCreateOrderStateToCart,
  useCreateOrderNextStep,
  useCreateOrderState,
  useGetSortableFormItems,
} from 'domain/createOrder/document'
import { Fields } from 'domain/servicesCBRdoc'
import { NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput, EmailInput, Heading, SubmitButton } from 'ui'

import ICPBrasilHelp from './ICPBrasilHelp'
import ItemsCollapse from './ItemsCollapse'

export default function SigneesStep({
  footer,
  formInstance,
}: {
  formInstance?: FormInstance
  footer?: React.ReactNode
}) {
  const form = formInstance ?? Form.useForm()[0]
  const dispatch = useDispatch()
  const [status, setStatus] = useState<'idle' | 'loading'>('idle')
  const [goToNextStep, nextStep] = useCreateOrderNextStep('assinantes')
  const { files, formData, multipleItemsLoadingPrice, selectedService } = useCreateOrderState()
  const addToCart = useAddCreateOrderStateToCart()
  const items = useGetSortableFormItems()
  const isUploading = files?.some(f => f.status === 'uploading')
  const hasUploadError = files?.some(f => f.status === 'error')
  const isLoading = multipleItemsLoadingPrice === true || status === 'loading' || isUploading

  const [activeItem, setActiveItem] = useState<number | undefined>(
    items.length > 0 ? items[items.length - 1]?.id ?? 0 : 0
  )

  const handleSubmit = async () => {
    try {
      await form.validateFields()
      setStatus('loading')
    } catch {
      message.error('Verifique o preenchimento do formulário.')
    }
  }

  // Salva no localStorage
  const onValuesChange = (changedFields: any, allValues: any) => {
    dispatch(createOrderActions.updateFormData({
      assinantes: allValues
    }))
  }

  useEffect(() => {
    const handleStatusChange = async () => {
      try {
        if (status === 'loading') {
          if (nextStep === 'cart') {
            addToCart()
          }

          goToNextStep()
        }
      } catch (error) {
        setStatus('idle')
        errorHandler(error)
      }
    }

    handleStatusChange()
  }, [status])

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      scrollToFirstError
      form={form}
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      initialValues={formData}
    >
      <Heading size="3" text="Dados das Pessoas que irão assinar" />

      <p className="text-gray-500 mb-5">
        Inclua os assinadores do documento na ordem de assinatura. Todas as assinaturas são realizadas
        utilizando o padrão ICP-Brasil. <ICPBrasilHelp />
      </p>

      <ItemsCollapse
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        items={items}
        service={selectedService}
        renderFormItem={(itemId: number) => <CreateOrderRTDForm itemId={itemId} />}
      />

      <AddFormItemButton
        label="Adicionar outro assinador(a)"
        setActiveItem={setActiveItem}
        disabled={isLoading}
      />

      {footer}

      <SubmitButton marginTop="mt-3" loading={isLoading} disabled={hasUploadError || isUploading} />

      <p className={`text-sm text-center ${hasUploadError ? 'text-error' : 'text-gray-400'}`}>
        {hasUploadError ? 'Houve um erro ao fazer o upload' : isUploading ? 'Upload em andamento...' : ''}
      </p>
    </Form>
  )
}

interface Props {
  itemId: number
}

function CreateOrderRTDForm({ itemId }: Props) {
  return (
    <>
      <NameField itemId={itemId} field={Fields.nome_pf} autoFocus={itemId === 0} required />
      <CPFInput itemId={itemId} required />
      <EmailInput name={[itemId, 'email']} required />
    </>
  )
}
