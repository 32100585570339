import { Descriptions, Modal } from 'antd'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { SelectedService } from 'domain/orderItem/types'
import { ServiceTypeDescription } from 'domain/servicesCBRdoc/data'
import { formatMoney } from 'utils/formatters'
import { Button, Pagination } from 'ui'

import { usePagination, useSetPage, useSetPageSize } from '../../state/shoppingCartPagination'
import { shoppingCartStateActions } from '../../state/shoppingCartSlice'
import { ShoppingCartOrder } from '../../types'
import DeliveryInfo from '../DeliveryInfo'
import RegisterInfo from '../RegisterInfo'
import CartItemViewServiceIcon from './CartItemViewServiceIcon'
import CartItemViewEditButton from './CartItemViewEditButton'
import { showBookPage } from 'domain/shoppingCart/helpers'
import CartItemNotaries from '../CartItemNotaries'
import CartItemLocation from '../CartItemLocation'
import { ServiceType } from 'domain/servicesCBRdoc/types'

interface Props {
  orders: ShoppingCartOrder[]
}

function CartItemsView({ orders }: Props) {
  const dispatch = useDispatch()
  const hasPagination = orders.length > 10
  const { pageSize, currentPage } = usePagination()
  const setPage = useSetPage()
  const setPageSize = useSetPageSize()
  const bookPage = showBookPage
  const hasNotaries = (order: ShoppingCartOrder) => !!order.selectedLocation.url_cartorio
  const currentOrders = useMemo(() => {
    const items = []

    for (let i = (currentPage - 1) * pageSize; i < currentPage * pageSize; i++) {
      if (i === orders.length) break
      items.push(orders[i])
    }

    return items
  }, [orders, currentPage, pageSize])

  const removeOrderFromShoppingCart = (itemIdOrIndex: number | string) => {
    Modal.confirm({
      title: 'Excluir do carrinho',
      content: 'Você tem certeza que deseja excluir o item do carrinho?',
      onOk: () => {
        if (typeof itemIdOrIndex === 'string') {
          dispatch(shoppingCartStateActions.removeOrderByCartId(itemIdOrIndex))
        } else {
          dispatch(shoppingCartStateActions.removeOrder(itemIdOrIndex))
        }
      },
    })
  }

  const cardItemTotalValue = (order: ShoppingCartOrder) => {
    return order.orderDetails.totalValue ?? order.formItems['0'].orderDetails.totalValue ?? 0
  }

  return (
    <div className="flex-1 h-full pt-4">
      {currentOrders.map(order => {
        return (
          <table
            key={order.cartItemId}
            className="bg-white rounded-lg min-w-[688px] mb-4 p-6 mx-auto cursor-default"
          >
            <thead>
              <tr>
                <th className="flex items-center justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <CartItemViewServiceIcon order={order} />
                    <span className="font-bold text-start text-secondary-500 text-lg truncate w-112 mt-1">
                      {order.submitData.name}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <CartItemViewEditButton order={order} />
                    <Button
                      icon="trash"
                      type="text"
                      size="small"
                      className="p-0"
                      onClick={() => removeOrderFromShoppingCart(order.cartItemId)}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm space-y-4">
              <tr className="flex items-center justify-start">
                {order.selectedService.type !== ServiceType.DILIGENCE && (
                  <RegisterInfo
                    service={order.selectedService as SelectedService}
                    submitData={order.submitData}
                  />
                )}
                {order.selectedService.type && (
                  <td className="flex gap-1">
                    <strong>Tipo:</strong>
                    {ServiceTypeDescription[order.selectedService.type]}
                  </td>
                )}
                {bookPage(order) ? (
                  <td>
                    <strong>Livro/Página:</strong>
                    {bookPage(order)}
                  </td>
                ) : null}
              </tr>
              {hasNotaries(order) && (
                <tr>
                  <td className="flex items-center gap-1">
                    <strong>Emissor:</strong>
                    <span className="flex items-center gap-1 text-sm">
                      <CartItemNotaries item={order} /> - <CartItemLocation item={order} />
                    </span>
                  </td>
                </tr>
              )}
              <tr>
                <td className="flex items-center justify-between text-sm">
                  <DeliveryInfo order={order} />
                  {cardItemTotalValue(order) ? (
                    <span className="flex-1 text-end font-bold text-lg">
                      {formatMoney(cardItemTotalValue(order))}
                    </span>
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
        )
      })}

      {hasPagination && (
        <div className="mb-28 mx-auto max-w-[758px]">
          <Pagination
            currentPage={currentPage}
            pageSize={pageSize}
            totalCount={orders.length}
            onChangePage={setPage}
            onPageSizeChange={setPageSize}
          />
        </div>
      )}
    </div>
  )
}
export default CartItemsView
