import { useEffect } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import { useIsHydrated, useSetStatusFilter, useSetTypeFilter } from './filters'

function useSetInitialValuesFromURL() {
  const history = useHistory()
  const location = history.location
  const setStatusFilter = useSetStatusFilter()
  const setTypeFilter = useSetTypeFilter()
  const [isHydrated, setIsHydrated] = useIsHydrated()

  useEffect(() => {
    if (location.search) {
      const { status, type } = queryString.parse(location.search)

      if (status) {
        setStatusFilter(status)
      }

      if (type) {
        setTypeFilter(type)
      }
    }

    if (!isHydrated) {
      setIsHydrated(true)
    }
  }, [])
}

export default useSetInitialValuesFromURL
