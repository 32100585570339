import React from 'react'
import { FormInstance, Modal as AntModal } from 'antd'
import { ModalProps as AntdModalProps } from 'antd/lib/modal'

import Icon from 'ui/Icon/Icon'

export interface ModalProps extends AntdModalProps {
  children?: React.ReactNode
  extra?: React.ReactNode
  subTitle?: React.ReactNode | string
  form?: FormInstance
  contentClassName?: string
}

export default function Modal({
  children,
  okText,
  footer = okText ? undefined : null,
  title,
  subTitle,
  extra,
  form,
  contentClassName,
  afterClose = form ? () => form.resetFields() : undefined,
  ...props
}: ModalProps) {
  return (
    <AntModal
      title={
        title && (
          <div className="flex gap-3 justify-between items-center pr-12 border-b border-gray-300 pb-5 mb-5 relative">
            <div>
              <h1 className="text-2xl font-light">{title}</h1>
              {subTitle && <p className="text-xs mt-4 uppercase">{subTitle}</p>}
            </div>
            {extra}
          </div>
        )
      }
      footer={footer}
      closeIcon={<Icon name="close" size={36} strokeWidth={1} />}
      okText={okText}
      afterClose={afterClose}
      className="w-screen-xs mx-0 my-0 ml-auto top-0 p-0 h-full"
      classNames={{
        content: contentClassName
          ? contentClassName + ' min-h-fit rounded-sm px-8'
          : 'min-h-screen rounded-sm px-8',
      }}
      {...props}
    >
      {children}
    </AntModal>
  )
}
