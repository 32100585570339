import React from 'react'

import { useAllServices } from 'domain/servicesCBRdoc'
import { Select } from 'ui'
import { Form } from 'antd'

function KitsFilterDocumentSelect() {
  const services = useAllServices()

  return (
    <Form.Item
      name="serviceId"
      label="Documento"
    >
      <Select
        placeholder="Selecione o documento"
        size="middle"
        options={services}
        optionLabelKey="name"
        optionValueKey="id"
      />
    </Form.Item>
  )
}

export default KitsFilterDocumentSelect
