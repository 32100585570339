import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaParticipacaoSocietariaFields = Record<
  | FieldName.CPF
  | FieldName.DATA_OBITO
  | FieldName.NOME_PF
  | FieldName.NOME_MAE
  | FieldName.NOME_PAI
  | FieldName.TIPO,
  FieldConfig
>

class PesquisaObito extends Research {
  formFields: PesquisaParticipacaoSocietariaFields = {
    cpf: Fields.cpf,
    nome_pf: {
      ...Fields.nome_pf,
      maxLength: 200,
    },
    tipo: {
      ...Fields.tipo,
      kind: 'radio',
      apiExtraInfo: undefined,
      options: [
        { label: 'Simples', value: 'simples' },
        { label: 'Completa', value: 'completa' },
      ],
      apiOptionsLabelKey: 'label',
      apiOptionsValueKey: 'value',
      defaultValue: 'simples',
    },
    obito: {
      ...Fields.obito,
      optional: true,
    },
    mae: Fields.mae,
    pai: Fields.pai,
  }

  constructor() {
    super({
      description: 'Retorna a indicação e ano de óbito.',
      id: 62,
      priceByFields: [FieldName.TIPO],
      shortName: 'Pesquisa Óbito',
    })
  }
}

const service = new PesquisaObito().initialize()

export default service
