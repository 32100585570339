import { Modal } from 'antd'

import { PROD } from 'api/network/config'
import { ErrorExtrasParams, Errors } from 'api/types'
import { getErrorMessage } from './errorMessages'

export default function showErrorModal(error: any, extras?: ErrorExtrasParams) {
  const { description, message } = getErrorMessage(error, extras)
  const { code, errors, requestId } = extras ?? {}

  if (!PROD) {
    console.error('error', error)
  }

  Modal.error({
    icon: false,
    title: message,
    content: (
      <>
        <p>{description}</p>

        {!!errors && <ShowErrors errors={errors} />}

        {!!code && (
          <div className="text-3xs color-gray-500 leading-3 border-t border-gray-300 pt-4 mt-4">
            Código: {code}
          </div>
        )}

        {!!requestId && <p>RID: {requestId}</p>}
      </>
    ),
  })
}

function ShowErrors({ errors }: { errors: Errors }) {
  const allErrors = Object.values(errors)

  return (
    <div className="flex flex-col gap-3">
      {allErrors?.map((message, index) => (
        <p key={index}>{message}</p>
      ))}
    </div>
  )
}
