import { useEffect, useReducer, useState } from 'react'

import { Group } from 'domain/groups/types'
import { Icon, TextButton } from 'ui'

import AssociatedGroups from './AssociatedGroups'
import GroupsAssociationModal from './GroupsAssociationModal'

interface Props {
  groups: Group[]
  className?: string
  actionLabel?: string
  loading?: boolean
  onFinish: (groups: Group[]) => void
  onDeleteGroup?: (groupId: number) => void
}

export default function GroupsAssociation({
  groups,
  className = '',
  actionLabel = 'Editar grupos',
  loading,
  onDeleteGroup,
  onFinish,
}: Props) {
  const [isOpen, toggleIsOpen] = useReducer(prev => !prev, false)
  const [selectedGroups, setSelectedGroups] = useState<Group[]>(groups)

  useEffect(() => {
    setSelectedGroups(groups)
  }, [groups])

  return (
    <div className={`flex flex-row items-center justify-center mb-5 gap-2 ${className}`}>
      <AssociatedGroups groups={selectedGroups} />

      <TextButton label={actionLabel} className="min-w-fit" onClick={toggleIsOpen} />

      <GroupsAssociationModal
        selectedGroups={selectedGroups}
        setSelectedGroups={setSelectedGroups}
        open={isOpen}
        onClose={toggleIsOpen}
        loading={loading}
        onDeleteGroup={onDeleteGroup}
        onFinish={onFinish}
      />

      <a
        href="https://suporte.cbrdoc.com.br/hc/pt-br/articles/16455734239643"
        target="_blank"
        rel="noreferrer"
        className="-mb-2"
      >
        <Icon name="help-circle" />
      </a>
    </div>
  )
}
