import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { API } from 'api/network/v2'
import { useCurrentUser } from 'domain/auth'
import { UserNotificationSettings, UserNotificationSettingsAPI } from '../types'

export default function useUserNotificationsSettingsQuery() {
  const user = useCurrentUser()

  return useQuery(
    ['user-notifications-settings', user.id],
    async () => {
      const response: AxiosResponse<{ notifications_preferences: UserNotificationSettingsAPI }> =
        await API.get(`users/${user.id}`, { params: { include: 'notifications_preferences' } })
      
      const {
        bank_slip_notification_via,
        certificate_expired_notification_via,
        finished_order_notification_via,
        finished_purchase_notification_via,
        pending_action_notification_via,
        refunded_order_notification_via,
        system_information_notification_via,
        system_unavailable_notification_via,
        placed_purchase_notification_via,
        spreadsheet_placed_purchase_notification_via,
        summary_extracted_notification_via,
      } = response.data?.notifications_preferences ?? {}

      const data: UserNotificationSettings = {
        bankSlipCreated: bank_slip_notification_via || [],
        certificateExpired: certificate_expired_notification_via || [],
        pendingAction: pending_action_notification_via || [],
        placedOrder: placed_purchase_notification_via || [],
        finishedOrder: finished_purchase_notification_via || [],
        finishedOrderItem: finished_order_notification_via || [],
        refundedOrder: refunded_order_notification_via || [],
        spreadsheetPlacedOrder: spreadsheet_placed_purchase_notification_via || [],
        systemInformation: system_information_notification_via || [],
        systemUnavailable: system_unavailable_notification_via || [],
        extractedSummary: summary_extracted_notification_via || [], 
      }

      return data
    },
    {
      enabled: Boolean(user.accessToken),
    }
  )
}
