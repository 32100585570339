import React from 'react'

import { useCurrentUser } from 'domain/auth'
import { useDeleteKitItemMutation } from 'domain/kits/services'
import { Kit, KitItem } from 'domain/kits/types'

import DeleteKitItemButton from '../registerKit/DeleteKitItemButton'

interface Props {
  queryKey: any
  kit: Kit
  item: KitItem 
}

function DeleteKitItem({ queryKey, kit, item }: Props) {
  const { mutate: deleteItem, isLoading } = useDeleteKitItemMutation({ queryKey, kit, itemId: item.id })
  const user = useCurrentUser()
  const isOwner = item.ownerId === user.id

  return (
    <DeleteKitItemButton
      onConfirm={deleteItem}
      loading={isLoading}
      title={
        isOwner ? 'Excluir' : 'Você não tem permissão para excluir este item, pois não é seu criador(a).'
      }
      disabled={!isOwner}
    />
  )
}

export default DeleteKitItem
