import { HelpPopover } from 'domain/app'

export default function ICPBrasilHelp() {
  return (
    <HelpPopover
      content={
        <p className="mb-2">
          O padrão ICP-Brasil garante validade jurídica das assinaturas eletrônicas, seguindo 
          normas e procedimentos específicos do governo brasileiro para certificação digital.
        </p>
      }
      title="ICP-Brasil"
      className="relative"
    />
  )
}
