import { useEffect, useState } from 'react'

import { DocumentLink } from 'domain/createOrder/document'
import { ResearchServiceLink } from 'domain/createOrder/researches'
import { CategoryAPI, ServiceAPI, ServiceType } from 'domain/servicesCBRdoc/types'
import { normalizeString } from 'utils/formatters'
import { SearchInput } from 'ui'

interface Props {
  category?: CategoryAPI
  services: ServiceAPI[]
  autoFocus?: boolean
  type: ServiceType
}

export default function ServiceTabsItems({ category, services, autoFocus, type }: Props) {
  const [filteredServices, setFilteredServices] = useState<ServiceAPI[]>(services)

  const SelectService = type == ServiceType.RESEARCH ? ResearchServiceLink : DocumentLink

  useEffect(() => {
    setFilteredServices(services)
  }, [services])

  return (
    <div className="bg-gray-100 px-7 pt-4 flex-1 2xl:max-w-screen-lg">
      {services && services.length > 10 && (
        <SearchInput
          placeholder={`Qual tipo de ${placeholders[type]} você precisa?`}
          onChange={input => {
            const filter = input.target.value.trim()
            const filtered = filter
              ? services.filter(o => normalizeString(o.name).includes(normalizeString(filter)))
              : services

            setFilteredServices(filtered)
          }}
          className="mb-7 md:w-96"
          allowClear
          autoFocus={autoFocus}
        />
      )}

      {filteredServices.length > 0 ? (
        <ul>
          {filteredServices.map(service => (
            <SelectService key={service.id} service={service} categoryId={category?.id} />
          ))}
        </ul>
      ) : (
        <div>Não localizado, refaça sua busca.</div>
      )}
    </div>
  )
}

const placeholders: Record<ServiceType, string> = {
  [ServiceType.AI]: 'AI',
  [ServiceType.CERTIFICATE]: 'documento',
  [ServiceType.DILIGENCE]: 'diligência',
  [ServiceType.DOCUMENT_REGISTRATION]: 'registro',
  [ServiceType.RESEARCH]: 'pesquisa',
  [ServiceType.SIGNATURES_CERTIFICATES]: 'certificado e assinatura',
}
