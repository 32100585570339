interface FormLabelProps {
  label: string
  required?: boolean
  description?: string
}

export default function FormLabel({ label, description, required }: FormLabelProps) {
  return (
    <div>
      {required ? label : `${label} (opcional)`}
      {description && <p className="my-2 text-xs font-normal">{description}</p>}
    </div>
  )
}
