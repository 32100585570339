import { MissingFields } from '../../types'

class MissingFieldsError extends Error {
  data: MissingFields[]

  constructor(missingFields: MissingFields[]) {
    super('Faltam campos para solicitar o serviço.')
    Object.setPrototypeOf(this, MissingFieldsError.prototype)

    this.data = missingFields
  }
}

export default MissingFieldsError
