import { Popconfirm } from 'antd'

import { Icon } from 'ui'
import { useKit } from '../../state'

export default function RemoveDocumentButton({ kitItemId }: { kitItemId: number }) {
  const [kit, setKit] = useKit()
  const isLastItem = kit.items.length === 1

  const onConfirm = () => {
    const updatedKit = {
      ...kit,
      items: kit.items.filter(item => item.id !== kitItemId),
    }

    setKit(updatedKit)
  }

  return (
    <Popconfirm
      title="Tem certeza que deseja remover o documento?"
      onConfirm={onConfirm}
      okText="Sim"
      cancelText="Não"
      disabled={isLastItem}
      placement="topLeft"
    >
      <a href="#" className="invisible group-hover:visible" title="Excluir item do kit">
        <Icon name="trash" width="28" height="28" className="text-gray-500" />
      </a>
    </Popconfirm>
  )
}
