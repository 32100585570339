import React, { useReducer } from 'react'

import { useSetExplorerItem } from 'domain/myFiles/state'
import { ExplorerItem } from 'domain/myFiles/types'

import UploadItemModal from '../items/UploadItemModal'

export default function MyFilesActionsUpload() {
  const setExplorerItem = useSetExplorerItem()
  const [isOpen, toggleOpen] = useReducer(prev => !prev, false)

  const handleClick = async (e: any) => {
    e.preventDefault()
    setExplorerItem({ tags: [] } as unknown as ExplorerItem)
    toggleOpen()
  }

  return (
    <>
      <a href="#" onClick={handleClick}>
        Enviar arquivos
      </a>

      <UploadItemModal open={isOpen} toggleOpen={toggleOpen} />
    </>
  )
}
