import React from 'react'

import { Statistic } from 'domain/orders/services/useOrdersStatisticsQuery'
import { DashboardCard } from 'ui'

export default function OrdersDashboardTotal({ statistics }: { statistics: Statistic[] | undefined }) {
  let total = 0

  statistics?.forEach(data => {
    total += parseInt(data.count)
  })

  return (
    <DashboardCard text="Total" statistic={total} statisticColor="currentColor" />
  )
}
