import { AccountBalance, useIsCustomerPostPaid } from 'domain/customers'
import { TopBarNotifications } from 'domain/notifications'

import TopBarCart from './TopBarCart'
import TopBarMenu from './TopBarMenu'
import TopBarHelp from './TopBarHelp'

type Props =  { toggle: JSX.Element }

export default function TopBar({ toggle }: Props) {  
  const isCustomerPostPaid = useIsCustomerPostPaid()

  return (
    <div className="flex items-center h-[70px] px-5 absolute shadow-material top-0 left-0 right-0 z-20 bg-white">
      {toggle}

      {!isCustomerPostPaid && <AccountBalance />}

      <div className="flex flex-1 flex-row flex-wrap gap-6 items-center justify-end">
        <div className="flex flex-1 flex-row flex-wrap gap-6 items-center justify-end mt-2">
          <TopBarCart />
          <TopBarNotifications />
          <TopBarHelp />
        </div>
        <TopBarMenu />
      </div>
    </div>
  )
}
