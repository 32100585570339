import { Alert, Icon } from 'ui'

export default function OrderSuccessfulMessage({ className = '' }) {
  return (
    <Alert
      type="success"
      icon={<Icon name="email" color="#40ca8f" />}
      className={`w-full max-w-md !rounded ${className}`}
      message="Fique tranquilo, você receberá um e-mail a cada mudança de status para acompanhar a evolução do seu pedido."
    />
  )
}
