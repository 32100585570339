import { useQuery } from 'react-query'
import { getComarcas } from './request'
import { SelectedService } from 'domain/createOrder/types'
import { createUseLoadComarcasKey } from './key'
import { LegalEntity } from 'domain/app/types'

interface Params {
  service: SelectedService
  itemId?: number
  onSuccess?: () => void
  enabled?: boolean
  params: { uf: string | number; instance: string; model: string | number; entity: LegalEntity }
}

export const useLoadComarcas = ({ service, enabled, itemId, onSuccess, params }: Params) => {
  const { uf, instance, model, entity } = params

  return useQuery(
    createUseLoadComarcasKey(service.id, uf, instance, model, entity, itemId),
    () => getComarcas({ service, uf, instance, model, entity }),
    {
      enabled,
      onSuccess,
    }
  )
}
