import { NamePath } from 'antd/lib/form/interface'

import { NumberField } from 'domain/servicesCBRdoc/fields'
import { CNPJInput } from 'ui'

import config from '../certidaoRAISConfig'
import { CertidaoRaisTypes } from '../types'

interface Props {
  itemId: number
  selectedType: CertidaoRaisTypes
  name?: NamePath
}

export default function CertidaoRaisFieldByType({ itemId, selectedType }: Props) {
  if (selectedType === 'cnpj') {
    return <CNPJInput itemId={itemId} />
  }

  if (selectedType === 'cei-cno') {
    return <NumberField field={cei} itemId={itemId} />
  }

  if (selectedType === 'caepf') {
    return <NumberField field={caepf} itemId={itemId} />
  }

  return null
}

const { caepf, cei } = config.formFields
