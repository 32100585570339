import { OrderOverviewFilters } from 'domain/orders/types'

const OrdersQueryKeys = {
  overview: (orderId: number | string | undefined) => ['orders', 'overview', orderId],
  orderItems: (
    orderId: number | string | undefined, 
    overviewCurrentPage: number, 
    filters: OrderOverviewFilters, 
    overviewPageSize: number, 
    overviewSortBy: string
  ) =>
    ['orders', 'order-items', Number(orderId), overviewCurrentPage, filters, overviewPageSize, overviewSortBy],
}

export default OrdersQueryKeys
