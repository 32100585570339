import { useResetCreateOrder } from 'domain/createOrder/document'
import { AlertViewOrderPermission } from 'domain/orderItem'
import {
  Orders,
  OrdersDashboard,
  ReportButton,
  useHasViewOrderPermission,
  useOrdersInitialValuesFromURL,
} from 'domain/orders'
import { BasicLayout } from 'layouts'
import { useHasPermission } from 'domain/auth'

export default function OrdersPage() {
  useResetCreateOrder()
  useOrdersInitialValuesFromURL()

  const hasViewPermission = useHasViewOrderPermission()
  const canViewReports = useHasPermission('canSeeReports')

  if (!hasViewPermission) {
    return <AlertViewOrderPermission />
  }

  return (
    <BasicLayout
      pageTitle="Meus pedidos"
      className="pb-0 bg-gray-300"
      header={<OrdersDashboard extra={canViewReports && <ReportButton />} />}
    >
      <Orders />
    </BasicLayout>
  )
}
