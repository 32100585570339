import { useQuery } from 'react-query'

import { CreateOrderParamsFields } from 'domain/createOrder/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import CBRdocDocument from 'domain/servicesCBRdoc/config/classes/CBRdocDocument'
import { AllExtraInfos, ExtraInfo, SelectedService } from 'domain/orderItem/types'
import { checkParams } from 'utils/validators'
import { getExtraInformations } from './getExtraInfoAPI'

interface Params {
  service: SelectedService
  information?: ExtraInfo
  itemId?: number
  data: CreateOrderParamsFields
  onSuccess?: (data: AllExtraInfos) => void
  enabled?: boolean
}

/**
 * Algumas certidões tem o endpoint /extra-informations, outras é necessário especificar
 *  a informação desejada (/extra-informations/[info])
 * (quando existe alguma dependência e todas as informações não podem ser retornadas)
 *
 * useLoadExtraInformations evita chamadas para /extra-informations
 * nos casos em que há dependências e normaliza a resposta,
 * para ter o mesmo formato como se fosse uma requisição para /extra-informations
 */
export default function useAllExtraInformationsQuery({
  service,
  data,
  itemId,
  information,
  onSuccess,
  enabled = true,
}: Params) {
  const { extraInfoRequiredFieldsOnly, getExtraInfoParams } = getServiceConfig<CBRdocDocument>(service.code!)
  const filter = extraInfoRequiredFieldsOnly ? 'campos-necessarios' : information
  let params = {}

  if (getExtraInfoParams) {
    params = getExtraInfoParams(data, itemId)
  }

  return useQuery(
    [`extra-infos-${service.id}-${itemId}`, service.categoryId, params, filter],
    async () => {
      const data = await getExtraInformations<AllExtraInfos>(service, params ?? null, filter)

      if (extraInfoRequiredFieldsOnly) {
        return { 'campos-necessarios': data } as AllExtraInfos
      }

      return data
    },
    {
      enabled: enabled && checkParams(service, params ?? []),
      onSuccess,
    }
  )
}
