import { useGetDetailsFieldsConfig, useGetLegalEntity, useGetServiceForm } from 'domain/createOrder/kit'
import RegisterInput from './RegisterInput'

interface Props {
  kitItemId: number
}

export default function KitOrderPesquisaDividaAtivaPGE({ kitItemId }: Props) {
  const legalEntity = useGetLegalEntity()
  const { tipo } = useGetServiceForm(kitItemId)
  const { registreeFields } = useGetDetailsFieldsConfig()

  if (
    !tipo ||
    (tipo.value === 'cpf' && legalEntity === 'fisica' && registreeFields.some(field => field.name === 'cpf')) ||
    (tipo.value === 'cnpj' && legalEntity === 'juridica' && registreeFields.some(field => field.name === 'cnpj'))
  ) {
    return null
  }

  return <RegisterInput itemId={kitItemId} selectedType={tipo} />
}
