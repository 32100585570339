import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { atomURI } from 'utils/navigation'

// Current page
export const currentPageAtom = atom<number>(1)
export const currentPageURIAtom = atomURI(currentPageAtom, 'p')

export const useCurrentPage = () => useAtom(currentPageURIAtom)
export const useGetCurrentPage = () => useAtomValue(currentPageAtom)
export const useSetCurrentPage = () => useSetAtom(currentPageURIAtom)

// Page size
const pageSizeAtom = atomWithStorage('recurrences-page-size', 20)
export const useGetPageSize = () => useAtomValue(pageSizeAtom)
export const usePageSize = () => useAtom(pageSizeAtom)

// Total pages
const pageCountResultsAtom = atom(0)
export const useSetPageCountResults = () => useSetAtom(pageCountResultsAtom)

// Total results
const totalResultsAtom = atom(0)
export const useGetTotalResults = () => useAtomValue(totalResultsAtom)
export const useSetTotalResults = () => useSetAtom(totalResultsAtom)
