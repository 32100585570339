import React from 'react'
import { RadioGroupProps, RadioChangeEvent } from 'antd'

import { useEditKitMutation } from 'domain/kits/services'
import { Kit } from 'domain/kits/types'
import KitLegalEntityRadio from '../registerKit/KitLegalEntityRadio'

interface Props extends RadioGroupProps {
  kit: Kit
  queryKey: any[]
}

function EditkitLegalEntity({ kit, queryKey, onChange, ...props }: Props) {
  const currentServices = kit.items.map(item => item.service)
  const { mutate, isLoading } = useEditKitMutation(queryKey, kit.id)

  const onChangeEntity = (e: RadioChangeEvent) => {
    mutate({ legalEntity: e.target.value })
    if (onChange) onChange(e)
  }

  return (
    <KitLegalEntityRadio
      {...props}
      compact
      legalEntity={kit.legalEntity}
      currentServices={currentServices}
      onChange={onChangeEntity}
      className="mb-6"
      disabled={isLoading}
    />
  )
}

export default EditkitLegalEntity
