import React from 'react'
import { Button } from 'antd'

import Icon from '../Icon'

export interface Props extends React.HTMLAttributes<HTMLElement> {
  barcode: string
  url: string
}

export default function Boleto({ barcode, url, className = '' }: Props) {
  const [boletoBarcodeCopied, setBoletoBarcodeCopied] = React.useState(false)

  return (
    <div className={`mt-1 mb-7 p-6 relative ${className}`}>
      <Icon name="barcode" className="absolute right-0 top-0 mt-6 mr-6 text-4xl text-gray-700" />

      <h1 className="uppercase text-base">Número do boleto</h1>

      <div className="pt-2">
        <Button
          type="link"
          onClick={() => {
            navigator.clipboard.writeText(barcode)
            setBoletoBarcodeCopied(true)
          }}
        >
          copiar número
        </Button>

        {boletoBarcodeCopied && <span className="ml-4 text-xs text-gray-600">Copiado!</span>}
      </div>

      <p className="text-center text-lg p-7">{barcode}</p>

      <Button type="primary" block href={url} target="_blank" download>
        Visualizar boleto
      </Button>
    </div>
  )
}
