import React from 'react'
import { useDispatch } from 'react-redux'

import { DueDiligenceForm } from 'domain/createOrder/dueDiligence/types'
import { getService } from 'domain/servicesCBRdoc'
import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document/state'
import { TextAreaInput, TextInput } from 'ui'
import { UploadField } from 'domain/servicesCBRdoc/fields'
import { TempFile } from 'domain/app/types'

function Document() {
  const dispatch = useDispatch()
  const { selectedService } = useCreateOrderState()
  const { formFields } = getService(selectedService.code!)

  const saveOnLocalStorage = (fieldName: keyof DueDiligenceForm) => (e: any) => {
    dispatch(createOrderActions.updateFormData({ [fieldName]: e.target.value }))
  }

  const onChangeUpload = (files: TempFile[]) => {
    dispatch(createOrderActions.setFiles(files))
    saveOnLocalStorage('arquivos')({
      target: {
        value: files.map(f => f.fileUploadId)
      }
    })
  }

  return (
    <>
      {formFields.numero_processo && (
        <TextInput
          label="Número do Processo"
          name="numero_processo"
          onChange={saveOnLocalStorage('numero_processo')}
        />
      )}

      {formFields.descritivo_documento && (
        <TextAreaInput
          label="Descritivo deste documento"
          name="descritivo_documento"
          rows={3}
          onChange={saveOnLocalStorage('descritivo_documento')}
        />
      )}

      {formFields.mensagem && (
        <TextAreaInput
          label="Mensagem"
          name="mensagem"
          rows={3}
          onChange={saveOnLocalStorage('mensagem')}
          maxLength={500}
          showCount
          required
        />
      )}

      {formFields.arquivos && (
        <UploadField field={formFields.arquivos} onChange={onChangeUpload} required/>
      )}
    </>
  )
}

export default Document
