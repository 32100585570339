import { useMutation } from 'react-query'

import { handleRequestError } from 'api/HandleRequestError'
import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'

import { Notification } from '../types'

function useToggleNotificationRead() {
  const mutation = useMutation(
    async (notification: Pick<Notification, 'id' | 'readAt'>) => {
      const action = notification.readAt ? 'unread' : 'read'
      return await AuthorizedAPI.put(`/notifications/${notification.id}/${action}`)
    },
    {
      onMutate: async (notification) => {
        await queryClient.cancelQueries({ queryKey: ['notifications'] })

        if (!notification.readAt) {
          queryClient.setQueryData(['notifications', 'latest'], (old: any) =>
            old.filter((o: any) => o.id !== notification.id)
          )
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['notifications', 'all'] })
      },
      onError: error => {
        handleRequestError(error)
      },
    }
  )

  return mutation.mutate
}

export default useToggleNotificationRead
