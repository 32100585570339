import React from 'react'
import { Spin } from 'antd'

import { AICreateOrderModel } from 'domain/openAI/types'

import EmptyModels from './EmptyModels'
import CBRdocModelCard from './CBRdocModelCard'
import ModelsActionsBar from './ModelsActionsBar'
import useCBRDocModelsQuery from '../services/useCBRDocModelsQuery'

export default function CBRDocModelsTab() {
  return (
    <div className="flex-col flex-1">
      <ModelsActionsBar />

      <div className="px-7">
        <CBRDocModels />
      </div>
    </div>
  )
}

function CBRDocModels() {
  const { data, isLoading } = useCBRDocModelsQuery()

  if (isLoading || !data) {
    return <Spin />
  }

  if (data.length === 0) {
    return <EmptyModels />
  }

  return <CBRDocModelsList models={data} />
}

function CBRDocModelsList({ models }: { models: AICreateOrderModel[] }) {
  return (
    <>
      {models.map(model => (
        <CBRdocModelCard key={model.id} model={model} />
      ))}
    </>
  )
}
