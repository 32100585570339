import React from 'react'
import { ColumnsType } from 'antd/lib/table'

import { useGetOrderItem } from 'domain/orderItem/shared'
import useViewMode from 'utils/useViewMode'

import { ResearchResult } from '../../types'
import ViewOrderResearchActionsBar from './ViewOrderResearchActionsBar'
import ResearchResultsTable from './ResearchResultsTable'
import ResearchResultCards from './ResearchResultCards'
import ViewResearch from './ViewResearch'

interface Props {
  columns: ColumnsType<ResearchResult>
  actions: React.ReactElement
}

function ViewResearchWithResults({ columns, actions }: Props) {
  const orderItem = useGetOrderItem()
  const [viewMode, setViewMode] = useViewMode()

  return (
    <ViewResearch sidebarActions={actions}>
      <ViewOrderResearchActionsBar tableView={viewMode} setTableView={setViewMode} />

      {viewMode === 'table' ? (
        <ResearchResultsTable columns={columns} data={orderItem.researchResults ?? []} />
      ) : (
        <div className="mx-7 mt-2 mb-7">
          <ResearchResultCards dataSource={orderItem.researchResults ?? []} columns={columns} />
        </div>
      )}
    </ViewResearch>
  )
}

export default ViewResearchWithResults
