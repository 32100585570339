import { useMemo } from 'react'
import { Spin } from 'antd'

import { useGetOrderItem } from 'domain/orderItem/shared'
import { SignaturesProgressAPI } from 'domain/orderItem/typesAPI'
import { Icon } from 'ui'
import { formatCPF } from 'utils/formatters'

import useSignaturesProgressQuery from '../services/useSignaturesProgressQuery'

export default function SignaturesProgress() {
  const orderItem = useGetOrderItem()
  const { data, isFetching } = useSignaturesProgressQuery(orderItem.id)

  const isWaitingSignatures = useMemo(() => {
    if (!data) return undefined
    return data.some(signee => !signee.assinado)
  }, [data])

  return (
    <div className="flex flex-col gap-4 items-center text-secondary-500">
      <p className="text-center text-sm">
        {isWaitingSignatures
          ? 'Estamos aguardando a finalização da etapa de assinatura para envio do documento ao cartório de registro.'
          : isWaitingSignatures === undefined
            ? 'Seu pedido está em processamento.'
            : 'A etapa de assinatura foi concluída e o documento foi encaminhado ao cartório para registro.'}
      </p>

      <div className="bg-white rounded p-6 shadow-sm w-full md:min-w-lg lg:min-w-fit">
        <p className="font-bold mb-6 subpixel-antialiased">Status atual da coleta de assinaturas:</p>

        <Spin spinning={isFetching}>
          <div className="flex flex-1 flex-col gap-5">
            {data?.map((signee, index) => (
              <SignatureProgress key={index} signee={signee} />
            ))}
          </div>
        </Spin>
      </div>
    </div>
  )
}

function SignatureProgress({ signee }: { signee: SignaturesProgressAPI }) {
  return (
    <div className="flex flex-col md:flex-row flex-wrap items-center gap-3 w-full text-sm text-secondary">
      <span
        className={`rounded-full h-6 w-6 flex justify-center items-center ${
          signee.assinado ? 'bg-success-500' : 'bg-gray-200'
        }`}
        title={signee.assinado ? 'Assinado' : 'Não assinado'}
      >
        {signee.assinado && <Icon name="check" size={14} className="text-white" strokeWidth={2} />}
      </span>

      <div className="flex flex-row">
        <span>{signee.nome} <span className="px-1">-</span> {formatCPF(signee.documento)}</span> <span className="px-1">-</span>
        <span>{signee.email}</span>
      </div>
    </div>
  )
}
