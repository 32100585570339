import React from 'react'
import { Tabs } from 'antd'

import { CBRdocModelsTab, useResetAIForm } from 'domain/openAI/models'
import MyModelsTab from 'domain/openAI/models/components/MyModelsTab'

export default function AIModelsPage() {
  useResetAIForm()

  return <Tabs className="[&>div.ant-tabs-nav]:bg-gray-200 [&>div.ant-tabs-nav]:pt-2 [&>div.ant-tabs-nav]:px-7" items={tabs} />
}

const tabs: any = [
  {
    label: 'Meus Modelos',
    key: '1',
    children: <MyModelsTab />,
  },
  {
    label: 'Modelos CBRdoc',
    key: '2',
    children: <CBRdocModelsTab />,
  },
]
