import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { AICreateOrderModelAPI } from 'domain/openAI/typesAPI'
import { convertCreateOrderModel } from './convertModel'

export default function useModelQuery(modelId: number) {
  return useQuery(
    ['ai-models', modelId],
    async () => {
      const response = await AuthorizedAPI.get<AICreateOrderModelAPI>(`/ai-models/${modelId}`, {
        params: {
          append: 'questions',
          include: 'service',
        },
      })

      return convertCreateOrderModel(response.data)
    },
    { enabled: Boolean(modelId) }
  )
}
