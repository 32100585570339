import React, { useState } from 'react'

import { Modal } from 'ui'
import ReportProblemModalForm from './ReportProblemModalForm'
import ReportProblemModalSuccess from './ReportProblemModalSuccess'

interface Props {
  open?: boolean
  onRequestClose: () => void
  orderId: string | number
}

const ReportProblemModal = ({ open, orderId, onRequestClose }: Props) => {
  const [reportedSuccess, setReportedSuccess] = useState(false)

  return (
    <Modal
      open={open}
      title="Reportar problema"
      onCancel={onRequestClose}
      afterClose={() => setReportedSuccess(false)}
      destroyOnClose
    >
      {reportedSuccess ? (
        <ReportProblemModalSuccess onRequestClose={onRequestClose} />
      ) : (
        <ReportProblemModalForm orderItemId={orderId} setReportedSuccess={setReportedSuccess} />
      )}
    </Modal>
  )
}

export default ReportProblemModal
