import React from 'react'
import { Link } from 'react-router-dom'

import { Routes } from 'app/routes'

interface Props {
  orderId: number
  children: React.ReactNode
  title?: string
}

function LinkToOrder({ orderId, children, title }: Props) {
  return (
    <Link className="text-secondary-500" to={Routes.Order(orderId)} title={title}>
      {children}
    </Link>
  )
}

export default LinkToOrder
