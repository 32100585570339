import { PopconfirmProps } from 'antd'

import { PopConfirmDelete } from 'ui'

type Props = {
  isDeletable: boolean
} & Omit<PopconfirmProps, 'title'>

export default function DeletePartButton({ description, isDeletable, ...props }: Props) {
  if (!isDeletable) {
    return null
  }

  return (
    <PopConfirmDelete
      title={`Exclusão de ${description}`}
      description={`Tem certeza que deseja excluir este ${description}?`}
      {...props}
    />
  )
}
