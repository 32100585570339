import React, { useEffect, useState } from 'react'

import { Icon } from 'ui'

import { Alert, AlertProps } from 'antd'

interface Props extends Omit<AlertProps, 'icon' | 'closeIcon' | 'handleCloseAlert'> { 
  show?: boolean
  position: 'top' | 'bottom'
  time?: number
}

const positions = {
  top: 'top-2 z-50',
  bottom: 'bottom-2'
}

function PopupAlert({ show = true, position, time = 10000, ...props }: Props) {
  const [showAlert, setShowAlert] = useState(show)

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShowAlert(false)
      }, time)
    }
  }, [show])

  return showAlert ? (
    <div className={`fixed left-1/2 transform -translate-x-1/2 min-w-[400px] ${positions[position]}`}>
      <Alert
        showIcon
        icon={<Icon name="ia-documento" size={40}/>}
        closeIcon
        onClose={() => setShowAlert(false)}
        {...props}
      />  
    </div>
  ) : null
}

export default PopupAlert

