import React, { createContext, useContext } from 'react'
import { Spin } from 'antd'

import { CategoryAPI } from 'domain/servicesCBRdoc/types'
import useServicesCategoriesQuery from '../services/useServicesCategoriesQuery'

type CategoriesContext = CategoryAPI[] | undefined

const initialState: CategoriesContext = [] as CategoryAPI[]
const CategoriesContext = createContext<CategoriesContext>(initialState)

export const useCategoriesContext = () => useContext(CategoriesContext) as CategoriesContext

export const CategoriesProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, isLoading } = useServicesCategoriesQuery()

  if (isLoading) return <Spin />

  return <CategoriesContext.Provider value={data}>{children}</CategoriesContext.Provider>
}
