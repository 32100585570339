import { Spin } from 'antd'

import { useGetPeriodFilter } from 'domain/reports/filters'
import { StatisticCard } from 'ui'
import useStatisticsQuery from '../services/useStatisticsQuery'

export default function StatisticsCards() {
  const period = useGetPeriodFilter()
  const { data: reportOrderStats, isLoading } = useStatisticsQuery(period)

  return (
    <Spin spinning={isLoading}>
      <div className="pb-7 bg-gray-200 flex flex-row gap-3 justify-between items-stretch max-w-[25%]">
        <StatisticCard title="Pedidos com extração de dados" value={reportOrderStats?.count ?? 0} />
      </div>
    </Spin>
  )
}
