import { useEffect, useState } from 'react'
import { Form } from 'antd'
import { useDebounce } from 'use-debounce'

import { GroupRegister, Group } from 'domain/groups/types'
import { SubmitButton, TextButton, TextInput } from 'ui'

import CreateGroupsTags from './CreateGroupsTags'
import { useCreateGroups, useGroupByNameQuery } from '../services'

interface Props {
  onFinish?: (newGroups: Group[]) => void
}

export default function CreateGroupsForm({ onFinish }: Props) {
  const [form] = Form.useForm()
  const [groups, setGroups] = useState([] as any[])
  const name = Form.useWatch('name', form)
  const [debouncedValue] = useDebounce(name?.trim(), 300)
  const { data: existentGroupName, isLoading: isCheckingGroupName } = useGroupByNameQuery(debouncedValue)
  const { createGroups, loading } = useCreateGroups(onFinish)

  useEffect(() => {
    if (existentGroupName) {
      form.setFields([
        {
          name: 'name',
          errors: ['Grupo já existe.']
        }
      ])
    }
  }, [existentGroupName])

  const onSaveGroups = async (formData: any) => {
    const entryNameTrimmed = formData?.name?.trim()

    if (groups.length === 0 && entryNameTrimmed === '') {
      form.setFields([
        {
          name: 'name',
          errors: ['Campo obrigatório.'],
        },
      ])
      return
    }

    const newGroups: GroupRegister[] =
      entryNameTrimmed !== '' ? [...groups, { name: entryNameTrimmed }] : groups

    createGroups(newGroups.filter(val => !!val.name))
  }

  const onAddGroup = () => {
    const formValue = form.getFieldValue('name')?.trim()

    if (formValue) {
      setGroups(prev => [...prev, { name: formValue }])
      form.resetFields()
    }
  }

  const disabled = isCheckingGroupName || !!existentGroupName

  return (
    <>
      <CreateGroupsTags groups={groups} setGroups={setGroups} />

      <Form
        form={form}
        layout="vertical"
        onFinish={onSaveGroups}
        requiredMark={false}
        scrollToFirstError
        className="max-w-lg mt-5"
      >
        <TextInput
          label="Nome"
          name="name"
          placeholder="Digite o nome do grupo"
          maxLength={40}
          required={groups.length === 0}
          hideOptional
          autoFocus
        />

        <TextButton
          label="Adicionar outro grupo"
          onClick={onAddGroup}
          disabled={disabled}
        />

        <SubmitButton label="Finalizar" loading={loading} disabled={disabled} />
      </Form>
    </>
  )
}
