import { memo } from 'react'
import { Form, FormInstance } from 'antd'
import { AnimatePresence, LayoutGroup } from 'framer-motion'

import { AddFormItemButton, ControlButton } from 'ui'

import ColetaAssinaturaCollapse from './ColetaAssinaturasCollapse'

interface FormItemsProps {
  handleAddFormItem: (active: number, add: any) => void
  disableAddBtn: boolean
  itemsQuantity: number
  setItemsQuantity: React.Dispatch<React.SetStateAction<number>>
  form: FormInstance
  activeItem?: number
  setActiveItem: React.Dispatch<React.SetStateAction<number | undefined>>
}

const FormItems = memo(
  ({
    form,
    itemsQuantity,
    setItemsQuantity,
    handleAddFormItem,
    disableAddBtn,
    activeItem,
    setActiveItem,
  }: FormItemsProps) => {
    return (
      <LayoutGroup>
        <AnimatePresence>
          <Form.List name="assinantes">
            {(fields, { add, remove, move }) => (
              <>
                {fields.map((field, index) => (
                  <ColetaAssinaturaCollapse
                    key={field.key}
                    index={index}
                    item={field}
                    form={form}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    isSubmitting={disableAddBtn}
                    totalItems={itemsQuantity}
                    onRemove={() => remove(field.name)}
                    onMoveUp={() => {
                      move(index, index - 1)
                    }}
                    onMoveDown={() => {
                      move(index, index + 1)
                    }}
                  />
                ))}
                <div className="flex gap-2">
                  <AddFormItemButton
                    label="Adicionar mais"
                    disabled={disableAddBtn}
                    onSuccess={val => handleAddFormItem(val, add)}
                  />

                  <ControlButton value={itemsQuantity} onValueChange={setItemsQuantity} />
                </div>
              </>
            )}
          </Form.List>
        </AnimatePresence>
      </LayoutGroup>
    )
  }
)

export default FormItems
