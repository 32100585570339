import { Link } from 'react-router-dom'
import { Dropdown } from 'antd'

import RoutePaths from 'app/routes/RoutePaths'
import { useCurrentUser } from 'domain/auth'
import { Avatar } from 'ui'
import Icon from 'ui/Icon'

import ChangePasswordMenu from './ChangePasswordMenu'
import LogoutMenu from './LogoutMenu'

export default function TopBarMenu() {
  const user = useCurrentUser()

  return (
    <Dropdown className="ml-4" menu={menu} trigger={['click']}>
      <div className="flex items-center cursor-pointer">
        <Avatar username={user.name} />
        <Icon name="chevron-down" className="ml-2" />
      </div>
    </Dropdown>
  )
}

const items = [
  {
    key: 'help',
    label: (
      <a href={RoutePaths.HELP_EXTERNAL} target="_blank" rel="noopener noreferrer">
        Ajuda
      </a>
    ),
    icon: <Icon name="help-circle" className="text-base" />,
  },
  {
    key: 'password',
    label: <ChangePasswordMenu />,
    icon: <Icon name="user" className="text-base" />,
  },
  {
    key: 'settings',
    label: <Link to={RoutePaths.SETTINGS}>Configurações</Link>,
    icon: <Icon name="settings" className="text-base" />,
  },
  {
    key: 'divider',
    type: 'divider',
  },
  {
    key: 'logout',
    label: <LogoutMenu />,
    icon: <Icon name="logout" className="text-base" />,
  },
]

const menu = { items }
