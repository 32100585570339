import { MissingFieldsError } from 'domain/createOrder/shared/helpers'
import { MissingFields } from 'domain/createOrder/types'
import { OrderItem, ResearchResultsOrderSettings, ResearchResult } from 'domain/orderItem/types'
import { ResultCode } from 'domain/orders/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { Research } from 'domain/servicesCBRdoc/config'

import useAddResearchResultToCart from './useAddResearchResultToCart'

export default function useAddResearchResultsToCart() {
  const addToCart = useAddResearchResultToCart()

  return async function addResearchResultsToCart(results: ResearchResult[], orderItem: OrderItem) {
    if (!results || results.length === 0) {
      return Promise.resolve()
    }

    const researchConfig = getServiceConfig<Research>(orderItem.service.code)

    if (researchConfig.doNotOrderResultsWithSameNotary) {
      results = ignoreSameNotaryResults(results)
    }

    results = ignoreAutoPurchasedResults(results, orderItem.researchResultsOrderSettings!)

    if (results && results.length > 0) {
      const orderWithMissingFields: MissingFields[] = []

      for (const result of results) {
        try {
          await addToCart(result, orderItem)
        } catch (error) {
          if (error instanceof MissingFieldsError) {
            orderWithMissingFields.push(error.data[0])
          } else {
            throw error
          }
        }
      }

      if (orderWithMissingFields.length > 0) {
        throw new MissingFieldsError(orderWithMissingFields)
      }
    }
  }
}

function ignoreAutoPurchasedResults(
  results: ResearchResult[],
  { negative, positive }: ResearchResultsOrderSettings
) {
  if (positive?.autoPurchase) {
    return results.filter(result => result.resultado !== ResultCode.POSITIVE)
  }

  if (negative?.autoPurchase) {
    return results.filter(result => result.resultado !== ResultCode.NEGATIVE)
  }

  return results
}

function ignoreSameNotaryResults(results: ResearchResult[]) {
  return results.filter(
    (element, index: number, self) =>
      index === self.findIndex(t => t.url_cartorio?.value === element.url_cartorio?.value)
  )
}
