import React, { useState } from 'react'
import { Button } from 'antd'

import { errorHandler } from 'api/errors'
import { useSaveShippingAddress } from 'domain/checkout/services'
import { useBuyCreditsWithInvoice } from 'domain/credits'
import { useCurrentCustomer } from 'domain/customers'

import { ChosenPaymentMethod, PaymentMethod } from '../types'
import AddShippingAddressForm from './AddShippingAddressForm'

interface Props {
  paymentValue: number
  isAddingCredits?: boolean
  onSuccess: (chosenMethod: ChosenPaymentMethod) => Promise<void>
}

function InvoiceForm({ paymentValue, onSuccess, isAddingCredits }: Props) {
  const [loading, setLoading] = useState(false)
  const [form, saveAddress, addressStatus, addressError] = useSaveShippingAddress()

  const customer = useCurrentCustomer()
  const hasAddress = !!customer.addressZipcode
  const buyCreditsWithInvoice = useBuyCreditsWithInvoice()

  const onGenerateInvoice = async (shippingAddress?: any) => {
    setLoading(true)

    if (!hasAddress && shippingAddress) {
      await saveAddress(shippingAddress, true)
    }

    if (isAddingCredits) {
      try {
        const data = await buyCreditsWithInvoice(paymentValue)

        if (data) {
          await onSuccess(data)
        }
      } catch (error) {
        errorHandler(error, { code: '0x491' })
      }
    }
    // Nas compras do carrinho, o boleto é gerado após o fechamento da compra
    else {
      await onSuccess({ chosenMethod: PaymentMethod.INVOICE })
    }
  }

  if (!hasAddress) {
    return (
      <AddShippingAddressForm
        onSubmit={onGenerateInvoice}
        form={form}
        isMainAddress
        buttonLabel="Gerar boleto bancário"
        error={addressError}
      />
    )
  }

  return (
    <Button
      type="primary"
      size="large"
      onClick={() => onGenerateInvoice()}
      loading={loading || addressStatus === 'loading'}
      block
    >
      Gerar boleto bancário
    </Button>
  )
}

export default InvoiceForm
