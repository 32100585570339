import React from 'react'
import { Link } from 'react-router-dom'

import { RoutePaths, Routes } from 'app/routes'
import { SimilarOrder } from 'domain/createOrder/types'
import DeleteItem from './DeleteItem'

export interface MultipleItemsSimilarOrdersProps {
  similarOrders: SimilarOrder[]
  setSimilarOrders: React.Dispatch<React.SetStateAction<SimilarOrder[]>>
  totalItems: number
}

function MultipleItemsSimilarOrders({
  similarOrders,
  setSimilarOrders,
  totalItems,
}: MultipleItemsSimilarOrdersProps) {
  if (similarOrders.length === 0) {
    return null
  }

  const onItemDeleted = (itemId: number) => {
    setSimilarOrders(prev => prev.filter(o => o.formItemId !== itemId))
  }

  return (
    <>
      <p className="mb-4">Você já possui pedidos com os mesmos dados.</p>

      {similarOrders.map(
        data =>
          data && (
            <div key={data.orderName} className="mb-6">
              <div className="flex gap-1 mb-1">
                <span className="text-secondary-300">
                  &bull; {!data.isRepeatedOnForm && <LinkToOrderOrCart itemId={data.id} />} -{' '}
                </span>
                <p className="flex-1 text-secondary-500 font-bold">{data.orderName}</p>
                {data.formItemId !== undefined && totalItems > 1 && (
                  <DeleteItem
                    itemId={data.formItemId}
                    onDeleted={data.isRepeatedOnForm ? onItemDeleted : undefined}
                  />
                )}
              </div>
            </div>
          )
      )}
    </>
  )
}

function LinkToOrderOrCart({ itemId }: { itemId?: number }) {
  return (
    <Link
      className="text-secondary-300 font-bold hover:underline"
      to={itemId ? Routes.ViewOrder(itemId) : RoutePaths.SHOPPING_CART}
      target="_blank"
    >
      {itemId ? `#${itemId}` : 'Item no carrinho'}
    </Link>
  )
}

export default MultipleItemsSimilarOrders
