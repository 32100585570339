import React, { useMemo, useState } from 'react'
import { Spin } from 'antd'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { AxiosResponse } from 'axios'

import { SecretAPI } from 'api/network/v2'
import { ReactComponent as LogoCBRDocLettering } from 'assets/images/logo-cbrdoc-vertical.svg'
import { Alert, ViewPDF } from 'ui'

const SharedOrder = () => {
  const location = useLocation()
  const [file, setFile] = useState<{url: string, name: string}>()
  
  const token = useMemo(() => {
    const search: any = new URLSearchParams(location.search)

    if (search.has('token')) {
      return search.get('token')
    }    
  }, [location.search])

  const { status, error } = useQuery(['shared-document', token], () => getSharedOrder(token), {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: data => {
      if (data?.url) {
        setFile(data)
      }
    },
    enabled: Boolean(token),
  })

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center bg-gray-200">
      {file && file.url ? (
        <ViewPDF fileUrl={file.url} fileName={file.name} />
      ) : (
        <>
          <LogoCBRDocLettering className="mb-10" />
          {status === 'loading' && <Spin />}
          {error && <Alert type="error" message="Pedido não encontrado" />}
        </>
      )}
    </div>
  )
}

const getSharedOrder = async (token?: string | null) => {
  const response: AxiosResponse<{
    url: string
    name: string
  }> = await SecretAPI.get(`/orders/shared/${token}`)
  return response.data
}

export default SharedOrder
