import React from 'react'

import { ViewResearchActions, ViewResearchWithResults } from 'domain/orderItem'
import { ExcelGenerator, TableColumn } from 'domain/orderItem/types'

function ViewPesquisaEscritura() {
  return (
    <ViewResearchWithResults columns={columns} actions={<ViewResearchActions excel={excelGenerator} />} />
  )
}
const columns: TableColumn[] = [
  {
    title: 'Ato',
    dataIndex: 'ato',
  },
  {
    title: 'Natureza do Ato',
    dataIndex: 'tipo_documento',
  },
  {
    title: 'Data do Ato',
    dataIndex: 'data_ato',
  },
  {
    title: 'Livro/Folha',
    dataIndex: 'livro_pagina',
  },
  {
    title: 'Partes',
    dataIndex: 'nome_das_partes',
  },
  {
    title: 'CPF / CNPJ',
    dataIndex: 'documento',
  },
  {
    title: 'Qualidade',
    dataIndex: 'tipo_papel',
  },
  {
    title: 'Cartório',
    dataIndex: ['url_cartorio', 'label'],
    width: 35,
  },
  {
    title: 'Cidade',
    dataIndex: ['url_cidade', 'label'],
    width: 35,
  },
  {
    title: 'Estado',
    dataIndex: ['url_uf', 'label'],
  },
]

const excelGenerator: ExcelGenerator = {
  columns
}

export default ViewPesquisaEscritura
