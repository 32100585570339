import { useEffect } from 'react'

import { useCurrentCustomer } from 'domain/customers'
import { Alert, AlertFullPage, Empty, Icon } from 'ui'
import { diffDays, displayDate } from 'utils/dateTime'

import useInsightsQuery from '../services/useInsightsQuery'

export default function InsightsReportTab() {
  const { analyticsEnabled, analyticsUntil } = useCurrentCustomer()
  const { data } = useInsightsQuery(analyticsEnabled)
  const isExpiring = analyticsUntil && diffDays(new Date(), analyticsUntil) <= 7

  useEffect(() => {
    if (data?.url) {
      window.open(data.url, '_blank')
    }
  }, [data])

  if (!analyticsEnabled) {
    return (
      <Empty
        heading="Você não tem acesso aos insights"
        description="Para ter acesso aos insights entre em contanto com nossa equipe."
      >
        <a target="_blank" href="https://cbrdoc.com.br/contato-enterprise/" rel="noreferrer">
          Entre em contato com nosso atendimento
        </a>
      </Empty>
    )
  }

  return (
    <AlertFullPage
      title="Redirecionando"
      description="Você será redirecionado para uma nova página. Se isso não acontecer, clique no link abaixo para abrir os insights."
    >
      <a className="text-base" href={data?.url} target="_blank" rel="noreferrer">
        Clique aqui
      </a>

      <Alert
        message={isExpiring ? 'Expira em breve' : 'Disponível'}
        description={`Você pode visualizar os insights até ${displayDate(analyticsUntil)}. 
          Para extender o período entre em contato com nossa equipe.`}
        icon={<Icon name={isExpiring ? 'warning' : 'info'} size={32} />}
        className="mt-8 max-w-sm"
        type={isExpiring ? 'warning' : 'info'}
      />
    </AlertFullPage>
  )
}
