import { useRef, useState } from 'react'
import { Form, Modal as AntdModal } from 'antd'

import { AuthorizedAPI } from 'api/network/v1'
import { RoutePaths } from 'app/routes'
import {
  Checkout,
  useCheckoutFlow,
  useGetCheckoutLoading,
  useGetChosenPaymentMethod,
  useGetGroups,
} from 'domain/checkout'
import { PaymentMethod } from 'domain/checkout/types'
import { PlaceOrderResponseAPI, SpreadsheetValidateData } from 'domain/createOrder/types'
import { useIsCustomerPostPaid } from 'domain/customers'
import { Modal } from 'ui'

import DownloadButton, { SpreadsheetDownloadProps } from './SpreadsheetDownload'
import SpreadsheetForm from './SpreadsheetForm'

interface Props extends SpreadsheetDownloadProps {
  open: boolean
  toggleOpen: () => void
  serviceId?: number
  canAutoOrderPositiveResults?: boolean
  canAutoOrderNegativeResults?: boolean
  categoryId?: number
  kitId?: number
}

export default function Spreadsheet({
  open,
  toggleOpen,
  serviceId,
  canAutoOrderPositiveResults,
  canAutoOrderNegativeResults,
  categoryId,
  kitId,
  downloadUrl,
  downloadFileName,
}: Props) {
  const [form] = Form.useForm()
  const loading = useGetCheckoutLoading()
  const [validateData, setValidateData] = useState<SpreadsheetValidateData>({})
  const formData = useRef<any>(new FormData())
  const groups = useGetGroups()
  const paymentMethod = useGetChosenPaymentMethod()
  const isCustomerPostPaid = useIsCustomerPostPaid()
  const creditsToInsert = isCustomerPostPaid ? 0 : validateData.lackingCreditsAmount!

  const startCheckout = useCheckoutFlow({
    onSubmit: async () => {
      const isOrderPostPaid = creditsToInsert > 0 && paymentMethod?.chosenMethod !== PaymentMethod.CREDIT_CARD
      const data = formData.current

      data.append('post_payment', isOrderPostPaid as any)

      if (isOrderPostPaid) {
        data.append('post_payment_method', paymentMethod!.chosenMethod as string)
      }

      data.set('name', form.getFieldValue('orderName'))

      if (canAutoOrderPositiveResults && form.getFieldValue('autoOrderPositiveResults')) {
        data.append('auto_purchase_certificate_from_result_positive', true)
      }

      if (canAutoOrderNegativeResults && form.getFieldValue('autoOrderNegativeResults')) {
        data.append('auto_purchase_certificate_from_result_negative', true)
      }

      if (groups.length > 0) {
        groups.map(group => {
          data.append('groups_ids[]', group.id as any)
        })
      }

      const { data: response } = await AuthorizedAPI.post(RoutePaths.CREATE_ORDER_BY_SPREADSHEET, data, {
        headers: { 'content-type': 'multipart/form-data' },
      })

      return response
    },
    onSuccess: (data: PlaceOrderResponseAPI) => {
      // TODO: padronizar resposta
      // Kit normal vem como data.purhase, kit via planilha vem como data.1.purchase
      if (!kitId && !data.purchase) {
        AntdModal.error({
          title: 'Falha na compra',
          content: 'Não foi possível completar o pedido. Revise seu arquivo e tente novamente.',
        })

        return false
      }

      const response: any = kitId ? Object.values(data) : [data]
      const results = response
        .filter((o: any) => o.results)
        .map((o: any) => Object.values(o.results))
        .flat()
      const resultsWithError = results.filter((o: any) => !o.success)

      if (resultsWithError.length > 0) {
        AntdModal.warn({
          title: 'Atenção',
          content: (
            <>
              <p>Alguns items falharam e não foram adicionados ao pedido final.</p>

              <ul>
                {resultsWithError.map((item: any) => (
                  <li key={item.backoffice_id}>#{item.backoffice_id}</li>
                ))}
              </ul>
            </>
          ),
        })
      }

      return true
    },
    onError: (error: any) => {
      console.error(error)
      AntdModal.error({
        title: 'Erro de envio',
        content: 'Não foi possível completar o pedido.',
      })

      return false
    },
    isAddressNeeded: validateData?.needsAddress,
    creditsToInsert,
  })

  return (
    <Modal open={open} title="Adicionar via planilha" onCancel={toggleOpen}>
      <Checkout paymentValue={creditsToInsert} />

      <p className="pb-4 text-gray-700">Você pode fazer seus pedidos com a nossa planilha base.</p>

      <DownloadButton downloadUrl={downloadUrl} downloadFileName={downloadFileName} />

      <div className="my-6 h-px w-full bg-gray-300"></div>

      <SpreadsheetForm
        loading={loading}
        onSubmit={startCheckout}
        countItems={validateData?.totalOrders}
        totalCost={validateData?.totalCost}
        setValidateData={setValidateData}
        serviceId={serviceId}
        categoryId={categoryId}
        kitId={kitId}
        canAutoOrderPositiveResults={canAutoOrderPositiveResults}
        canAutoOrderNegativeResults={canAutoOrderNegativeResults}
        form={form}
        formData={formData.current}
      />
    </Modal>
  )
}
