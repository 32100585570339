import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { Routes } from 'app/routes'
import { StepsByPath } from 'domain/app'
import {
  CreateKitProvider,
  CreateKitStep1,
  CreateKitStep2,
  CreateKitStep3,
  CreateKitSidebar,
  useCreateKitCurrentStep,
} from 'domain/kits'
import { CategoriesProvider } from 'domain/servicesCBRdoc'
import { GoBackButton, PageLayout } from 'layouts'
import { ActionsBar } from 'ui'

function CreateKitPage() {
  const { path } = useRouteMatch()
  const step = useCreateKitCurrentStep()

  return (
    <CreateKitProvider>
      <CategoriesProvider>
        <PageLayout
          pageTitle="Criar Kit"
          extra={<StepsByPath steps={CREATION_STEPS} />}
          sidebar={step !== 'inicio' && <CreateKitSidebar />}
        >
          <ActionsBar>
            <GoBackButton />
          </ActionsBar>

          <div className="mt-4">
            <Switch>
              <Route path={Routes.CreateKitStep('fixar-dados')} exact>
                <CreateKitStep3 />
              </Route>

              <Route path={Routes.CreateKitStep('selecionar-documentos')} exact>
                <CreateKitStep2 />
              </Route>

              <Route path={path} exact>
                <CreateKitStep1 />
              </Route>
            </Switch>
          </div>
        </PageLayout>
      </CategoriesProvider>
    </CreateKitProvider>
  )
}

const CREATION_STEPS = {
  inicio: 'Criação',
  'selecionar-documentos': 'Documentos',
  'fixar-dados': 'Definição de Dados',
}

export default CreateKitPage
