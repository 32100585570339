import { Form } from 'antd'
import { NamePath } from 'antd/lib/form/interface'
import { InputProps } from 'antd/lib/input'
import { MaskedInput as MaskedInputLib } from 'antd-mask-input'

import { requiredRule } from 'app/config/validationRules'
import { InputMaskOptions } from 'antd-mask-input/build/main/lib/MaskedInput'

export interface MaskedInputProps extends Omit<InputProps, 'name'> {
  name?: NamePath
  required?: boolean
  onChange?: (event: any) => void
  label?: string
  rules?: any[]
  mask: any
  maskOptions?: InputMaskOptions
  value?: string
  defaultValue?: string
  help?: string
  validateStatus?: 'warning'
}

export default function MaskedInput({
  label,
  name,
  onChange,
  required,
  rules = required ? requiredRule : undefined,
  mask,
  style,
  maskOptions = defaultMaskOptions,
  className = '',
  help,
  validateStatus,
  ...props
}: MaskedInputProps) {
  return (
    <Form.Item
      name={name}
      label={required ? label : label ? `${label} (opcional)` : undefined}
      rules={rules}
      style={style}
      help={help}
      validateStatus={validateStatus}
      validateFirst
      className={className}
    >
      <MaskedInputLib
        mask={mask}
        maskOptions={maskOptions}
        onChange={onChange}
        data-hj-allow
        className="rounded-none py-2 text-base text-gray-700 min-h-12"
        {...props}
      />
    </Form.Item>
  )
}

const defaultMaskOptions = { placeholderChar: ' ' }
