import React from 'react'
import { Form, Radio } from 'antd'
import { useDispatch } from 'react-redux'

import { PROCESS_TYPES } from 'domain/createOrder/dueDiligence/data'
import { createOrderActions } from 'domain/createOrder/document'

function ProcessTypeRadio() {
  const dispatch = useDispatch()

  const onChange = (event: any) => {
    dispatch(createOrderActions.updateFormData({ tipo_processo: event.target.value }))
  }

  return (
    <Form.Item label="Tipo do processo" name="processType" rules={requiredRule}>
      <Radio.Group options={PROCESS_TYPES} onChange={onChange} />
    </Form.Item>
  )
}

const requiredRule = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
]

export default ProcessTypeRadio
