import React from 'react'

import { useAllServices } from 'domain/servicesCBRdoc'
import { useSetServicesIds } from 'domain/orders/state/filters'
import { FilterTag } from 'ui'

export default function FilterByServicesTags({ value }: { value: number[] }) {
  const setServicesIds = useSetServicesIds()
  const services = useAllServices()
  const selectedServices = value.map(serviceId => services.find(s => s.id === serviceId)).filter(Boolean)

  return (
    <>
      {selectedServices.map(service => (
        <FilterTag key={service!.id} onClick={() => setServicesIds(value.filter(id => id !== service!.id))}>
          {service!.shortName || service!.name}
        </FilterTag>
      ))}
    </>
  )
}
