import React from 'react'

import { CertificateFlow, useDontKnowBookPagePriceQuery } from 'domain/createOrder/certificates'
import CertidaoProcuracaoForm from './CreateOrderCertidaoProcuracaoForm'

export default function CreateOrderCertidaoProcuracao() {
  const { data: dontKnowBookPagePrices, isFetching } = useDontKnowBookPagePriceQuery()

  return (
    <CertificateFlow
      header={
        <p className="mb-5 text-gray-600">
          Selecione Pessoa ou Empresa e digite o número do CPF ou CNPJ que deseja a Certidão de Procuração.
        </p>
      }
      renderFormItem={(itemId: number) => (
        <CertidaoProcuracaoForm
          itemId={itemId}
          dontKnowBookPagePrices={dontKnowBookPagePrices}
          loadingBookPagePrices={isFetching}
        />
      )}
      noRenderOrderName
    />
  )
}
