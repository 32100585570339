import { useState } from 'react'

import { getStorageData, setStorageData } from 'utils/localStorage'
import { useViewWidth } from 'utils/screen'

const SIDENAV_COLLAPSE_WIDTH = 1060

export default function useNavbarCollapse(): [boolean, () => void] {
  const width = useViewWidth()
  const [navCollapsed, setNavCollapsed] = useState(
    getStorageData('navCollapsed', width <= SIDENAV_COLLAPSE_WIDTH ? 'true' : 'false') === 'true'
  )

  const toggleNavbarCollapse = () => {
    setNavCollapsed(!navCollapsed)
    setStorageData('navCollapsed', `${!navCollapsed}`)
  }

  return [navCollapsed, toggleNavbarCollapse]
}
