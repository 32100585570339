import React from 'react'
import { Avatar } from 'antd'

interface Props {
  username?: string
  children?: JSX.Element
  size?: 'large' | 'small' | number
  title?: string
  className?: string
}

const CustomAvatar = ({ username, size = 'large', title, children, className }: Props) => {
  const text = username ? username.slice(0, 2).toUpperCase() : ''

  return (
    <span title={title} className={className}>
      <Avatar
        className="font-semibold bg-gray-200 text-secondary-500 drop-shadow-none flex items-center justify-center"
        size={size}
      >
        {text ? (text !== 'CU' ? text : 'C') : children}
      </Avatar>
    </span>
  )
}

export default CustomAvatar
