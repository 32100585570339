import { CreateOrderFormData, CreateOrderParamsFields } from 'domain/createOrder/types'
import { ResultCode } from 'domain/orders/types'
import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import PesquisaBensSelectType from './components/PesquisaBensSelectType'
import { getLocationUrl } from 'domain/servicesCBRdoc/helpers/location'
import { OrderItem, OrderItemDetails, ResearchResult } from 'domain/orderItem/types'
import { ResearchResultAPI } from 'domain/orderItem/typesAPI'

const { URL_CARTORIO, URL_CIDADE, URL_UF } = FieldName

type PesquisaBensFields = Record<
  | FieldName.CNPJ
  | FieldName.CPF
  | FieldName.DATA_BASE
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.PREFERENCIA
  | FieldName.TIPO
  | FieldName.TODOS_CARTORIOS_CIDADE
  | FieldName.URL_CARTORIO
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

class PesquisaBens extends Research {
  cityDependsOnType = 'completa'
  LocationUFType = PesquisaBensSelectType
  checkMissingFieldsOnResults = true
  formFields: PesquisaBensFields = {
    url_uf: Fields[URL_UF],
    tipo: {
      ...Fields.tipo,
      label: 'Tipo de pesquisa',
      ignoreOnKitsAutomaticForm: true,
      defaultValue: 'completa',
      options: [
        {
          value: 'completa',
          label: 'Completa (por cartório) — Qualificada',
          description:
            'Pesquise nos cartórios selecionados e saiba se o CPF/CNPJ é proprietário atual do imóvel ou sejá apareceu em alguma matrícula.',
        },
        {
          value: 'simples',
          label: 'Simples (por estado) — Prévia',
          description:
            'Pesquisa com resultado rápido. Saiba se o CPF/CNPJ pesquisado foi mencionado em alguma matrícula de imóvel, qual cartório e o número dela.',
        },
      ],
      apiOptionsLabelKey: 'label',
      apiOptionsValueKey: 'value',
    },
    url_cidade: Fields[URL_CIDADE],
    url_cartorio: {
      ...Fields[URL_CARTORIO],
      multiple: true,
    },
    todos_cartorios_cidade: Fields.todos_cartorios_cidade,
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
    data_base: {
      ...Fields.data_base,
      label: 'A partir da data',
      optional: true,
      ignoreOnKitsAutomaticForm: true,
    },
    preferencia: {
      ...Fields.preferencia,
      ignoreOnKitsAutomaticForm: true,
      label: 'Preferências de pesquisa',
      apiOptionsLabelKey: 'label',
      apiOptionsValueKey: 'value',
      options: [
        {
          label: 'Informar somente imóveis que seja proprietário',
          value: 'Informar somente imóveis que seja proprietário',
        },
        {
          label: 'Informar também imóveis já transferidos',
          value: 'Informar também imóveis já transferidos',
        },
      ],
      showIf: (data: CreateOrderFormData) => data.tipo === 'completa'
    },
  }

  constructor() {
    super({
      autoOrderResults: ResultCode.POSITIVE,
      description: 'Retorna os números de matrícula dos imóveis associados ao CPF ou CNPJ pesquisado.',
      extraInfoFields: [URL_UF],
      id: 9,
      shortName: 'Pesquisa de Bens',
      steps: ['localizacao', 'dados'],
    })
  }

  getPriceParams = ({ selectedLocation, selectedType }: CreateOrderParamsFields) => {
    const { url_cartorio, url_cidade, url_uf } = getLocationUrl(selectedLocation)

    const params: OrderItemDetails = {
      url_uf,
      tipo: selectedType as string,
    }
    
    if (selectedType === 'completa') {
      params.url_cidade = url_cidade
      params.url_cartorio = url_cartorio
      params.todos_cartorios_cidade = selectedLocation.todos_cartorios_cidade
    }

    return params
  }

  convertResults = (orderItem: OrderItem, results: ResearchResultAPI[] | null) => {
    const researchResults = this._convertResults(orderItem, results)

    if (researchResults) {
      return researchResults
    }

    // Legacy
    if (orderItem.result === ResultCode.NEGATIVE) {
      const { id, locationInfo } = orderItem

      const negativeResult: ResearchResult = {
        id: `${id}_0`,
        formato: 'email',
        matricula: '',
        resultado: 'negative',
        ...locationInfo,
      }

      return [negativeResult]
    }

    return []
  }
}

const service = new PesquisaBens().initialize()

export default service
