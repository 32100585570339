import React from 'react'

import { SidebarContainer, useGetOrderItem } from 'domain/orderItem/shared'
import { DownloadButton } from 'ui'

export default function ViewAIActions() {
  const { fileURL, explorerItem } = useGetOrderItem()
  const file = fileURL ?? explorerItem?.file.signedUrl

  return (
    <SidebarContainer
      downloadButton={<DownloadButton fileURL={file} />}
    />
  )
}
