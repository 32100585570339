import React from 'react'

import { OrderItemListing } from 'domain/orderItem/types'
import { DrawerActions } from 'ui'
import { useDownload } from 'utils/export'

function OrderItemsMassAIDownload({ orderItems }: { orderItems: OrderItemListing[] }) {
  const [handleDownload, loading] = useDownload()

  const download = async () => {
    const itemsNumbers = orderItems.map(({ orderItemNumber }) => orderItemNumber).join('-')
    const itemsIds = orderItems.map(({ ocrId }) => ocrId).join(',')
    const fileName = `cbrdoc-IA-pedido-${itemsNumbers}`
    handleDownload(`reports/ai-answers/xlsx?ocr_id=${itemsIds}`, fileName)
  }

  return (
    <DrawerActions.Button icon="ia-documento" loading={loading} onClick={download}>
      Baixar dados extraídos
    </DrawerActions.Button>
  )
}

export default OrderItemsMassAIDownload
