import { Checkbox, CheckboxProps, Form } from 'antd'
import { NamePath } from 'antd/es/form/interface'

type Props = CheckboxProps & { label?: string; name: NamePath; noStyle?: boolean }

export default function CheckboxInput({ name, noStyle, ...props }: Props) {
  return (
    <Form.Item valuePropName="checked" name={name} noStyle={noStyle}>
      <Checkbox {...props} />
    </Form.Item>
  )
}
