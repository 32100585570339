import React from 'react'

import { useHasPermission } from 'domain/auth'
import { Loading } from 'ui'

import AlertNoOrders from './AlertNoOrders'
import AlertNoOrdersWithSelectedFilters from '../AlertNoOrdersWithSelectedFilters'

interface Props {
  total?: number
  currentPage?: number
  hasFilterApplied: boolean
  showFiltersAlert?: boolean
  children: React.ReactNode
  className?: string
  loading?: boolean
  loadingMessage?: string
}

export default function HasResultsContainer({
  total,
  hasFilterApplied,
  children,
  className = '',
  loading,
  loadingMessage = 'Carregando dados...',
  showFiltersAlert,
}: Props) {
  const hasPermission = useHasPermission('canOrderServices')

  if (loading) {
    return <Loading message={loadingMessage} />
  }

  if (total === 0) {
    if (!hasFilterApplied) {
      if (hasPermission) {
        return (
          <AlertNoOrders
            title="Você ainda não fez pedidos"
            description="Pronto para começar? Clique no botão abaixo para fazer sua primeira solicitação. Saiba mais assistindo ao vídeo!"
          />
        )
      }

      return (
        <AlertNoOrders
          title="Você ainda não tem nenhum pedido"
          description="Seu usuário não ainda não tem nenhum pedido. Entre em contato com o administrador da sua conta. Saiba mais assistindo ao vídeo!"
        />
      )
    }

    if (showFiltersAlert) {
      return <AlertNoOrdersWithSelectedFilters />
    }
  }

  return <div className={`${className} relative`}>{children}</div>
}
