import { HelpPopover } from 'domain/app'

export default function PartsHelper() {
  return (
    <HelpPopover
      content={
        <p className="mb-2">
          Outorgante: É a pessoa ou entidade que concede ou transfere um direito sobre o imóvel. Outorgado: É
          a pessoa ou entidade que recebe o direito sobre o imóvel.
        </p>
      }
      title="Partes"      
    />
  )
}
