import React from 'react'

import { ViewResearchActions, ViewResearchWithResults } from 'domain/orderItem'
import { ExcelGenerator, TableColumn } from 'domain/orderItem/types'

function ViewPesquisaPropriedadeAeronave() {
  return (
    <ViewResearchWithResults columns={columns} actions={<ViewResearchActions excel={excelGenerator} />} />
  )
}

const columns: TableColumn[] = [
  {
    title: 'Documento',
    dataIndex: 'documento',
  },
  {
    title: 'Marca',
    dataIndex: 'marca',
  },
  {
    title: 'Proprietário',
    dataIndex: 'proprietario',
  },
  {
    title: 'Operador',
    dataIndex: 'operador',
  },
]

const excelGenerator: ExcelGenerator = {
  columns
}

export default ViewPesquisaPropriedadeAeronave
