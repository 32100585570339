import { FormInstance, message } from 'antd'
import { useEffect, useRef, useState } from 'react'

import { errorHandler } from 'api/errors'
import { SimilarOrdersError } from 'domain/createOrder/shared'
import { SimilarOrder } from 'domain/createOrder/types'
import { KitItem } from 'domain/kits/types'
import { removeStorageItem } from 'utils/localStorage'
import { KIT_ORDER_COMMON_DATA, useSetCommonForm } from '../../state'
import { AddPersonStatus } from '../../types'
import useAddKitToCart, { AddToCartParams } from './useAddKitToCart'

type AddFormToCartParams = AddToCartParams & { items: KitItem[] }

export default function useAddKitFormToCart(form: FormInstance) {
  const setCommonForm = useSetCommonForm()
  const [addAnotherPersonStatus, setAddOtherPersonStatus] = useState<AddPersonStatus>('idle')
  const hideMessage = useRef<any>()
  const [similarOrders, setSimilarOrders] = useState<SimilarOrder[]>([])

  useEffect(() => {
    if (addAnotherPersonStatus === 'reset' && form) {
      form.resetFields()
      form.setFieldsValue({ registree: {} })
      setAddOtherPersonStatus('idle')
    }
  }, [addAnotherPersonStatus])

  const { addToCart, loading, redirectToCart } = useAddKitToCart({
    onChangeStatus: (content: string) => {
      message.loading({ content, key })
    },
  })

  const onResetOrRedirect = (addAnotherPerson?: boolean) => {
    if (addAnotherPerson) {
      setAddOtherPersonStatus(addAnotherPerson ? 'reset' : 'idle')
      setCommonForm({ registree: {} })
      removeStorageItem(KIT_ORDER_COMMON_DATA)
      message.success('Documentos adicionados ao carrinho.')
    } else {
      redirectToCart()
    }
  }

  const addFormToCart = async (params: AddFormToCartParams) => {    
    try {
      if (params.items.length > 0) {
        hideMessage.current = message.loading({ content: 'Processando...', key })

        await addToCart(params)
  
        hideMessage.current()
      }

      onResetOrRedirect(params.addAnotherPerson)
    } catch (error: any) {
      if (error instanceof SimilarOrdersError) {
        setSimilarOrders(error.data)
      } else {
        errorHandler(error)
      }
    }
  }

  return { addToCart: addFormToCart, loading, similarOrders, setSimilarOrders, onResetOrRedirect }
}

const key = 'updatable'
