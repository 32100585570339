import { displayDate, displayDateAndTime, formatDateToApi, getDayNumber, getMonthNumber, stringToDate } from 'utils/dateTime'
import {
  CreateRecurrenceForm,
  CreateRecurrenceKind,
  EditRecurrenceForm,
  Recurrence,
  RecurrenceAPI,
  RecurrenceForm,
  RecurrenceItem,
  RecurrenceItemAPI,
  RecurrenceOnly,
  RecurrencePostAPI,
} from '../types'

export function convertRecurrenceOnly({
  active,
  created_at,
  every_x_days_number_of_days,
  frequency,
  id,
  monthly_day_of_month,
  name,
  starts_at,
  yearly_day,
  yearly_month,
  weekly_happens_on,
  notify_result_changes_in_items,
}: Omit<RecurrenceAPI, 'groups' | 'items' | 'owner'>): RecurrenceOnly {
  return {
    active,
    createdAtDisplay: displayDateAndTime(created_at),
    dayOfMonth: monthly_day_of_month,
    daysOfWeek:
      weekly_happens_on === undefined || weekly_happens_on === null
        ? []
        : Array.isArray(weekly_happens_on)
        ? weekly_happens_on
        : [weekly_happens_on],
    everyXDays: every_x_days_number_of_days,
    frequency,
    id,
    name,
    startsAt: stringToDate(starts_at)!,
    startsAtDisplay: displayDate(starts_at),
    yearlyDay: yearly_day,
    yearlyMonth: yearly_month,
    notifyResultChangesInItems: notify_result_changes_in_items,
  }
}

export function convertRecurrence({
  groups,
  items,
  owner,
  purchases_ids,
  ...dataFromAPI
}: RecurrenceAPI): Recurrence {
  const recurrence = convertRecurrenceOnly(dataFromAPI)

  return {
    ...recurrence,
    groups,
    items: items?.map(convertRecurrenceItem),
    owner: {
      id: owner.id,
      name: owner.name,
    },
    ordersIds: purchases_ids ?? [],
  }
}

function convertRecurrenceItem({
  created_at,
  id,
  order_id,
  order,
  service_can_be_monitored,
}: RecurrenceItemAPI): RecurrenceItem {
  return {
    createdAtDisplay: displayDateAndTime(created_at),
    id,
    orderItem: {
      id: order_id,
      name: order?.name,
    },
    serviceCanBeMonitored: service_can_be_monitored,
  }
}

export function convertRecurrenceToPostAPI({
  daysOfWeek,
  everyXDays,
  frequency,
  groups,
  name,
  startsAt,
  notify,
}: RecurrenceForm) {
  const starts_at = formatDateToApi(startsAt)
  if (!startsAt || starts_at === null) {
    throw Error('Missing startsAt from recurrence!')
  }

  const data: RecurrencePostAPI = {
    name,
    starts_at,
    frequency,
    groups_ids: groups.map(({ id }) => id),
    notify_result_changes_in_items: notify ? notify : false,
  }

  if (frequency === 'weekly') {
    data.weekly_happens_on = daysOfWeek
  } else if (frequency === 'monthly') {
    data.monthly_day_of_month = getDayNumber(startsAt)
  } else if (frequency === 'yearly') {
    data.yearly_month = getMonthNumber(startsAt)
    data.yearly_day = getDayNumber(startsAt)
  } else {
    data.every_x_days_number_of_days = everyXDays
  }

  return data
}

export function convertCreateRecurrenceToPostAPI(
  { items, ...recurrence }: CreateRecurrenceForm,
  kind: CreateRecurrenceKind
) {
  const data = convertRecurrenceToPostAPI(recurrence)

  if (kind === 'orders') {
    data.purchase_id = items[0].id
  } else {
    data.items = items.map(({ id }) => ({ order_id: id }))
  }

  return data
}

export function convertEditRecurrenceToPostAPI({ items, ...recurrence }: EditRecurrenceForm) {
  const data = convertRecurrenceToPostAPI(recurrence)

  data.items = items.map(({ id, orderItem }) => ({ id, order_id: orderItem.id }))

  return data
}
