import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'

import { useSetAvailableServices } from '../state/services'
import { ServicesById } from '../types'
import { ServiceAPI } from '../typesAPI'
import convertService from './convertService'

function useLoadAvailableServices() {
  const setServices = useSetAvailableServices()

  useQuery(['services', 'all'], getAllServices, {
    onSuccess: (data: ServiceAPI[]) => {
      const servicesById: ServicesById = {}
      
      data.forEach(service => {
        servicesById[service.id] = convertService(service)
      })

      setServices(servicesById)
    },
  })
}

async function getAllServices() {
  const response: AxiosResponse<ServiceAPI[]> = await AuthorizedAPI.get('services', { params: {
    append: 'can_be_monitored'
  } })
  return response?.data
}

export default useLoadAvailableServices
