import React, { useMemo } from 'react'

import BadgeButton from '../buttons/BadgeButton'

interface Props {
  activeFilters: object
  onClick?: () => void
}

export default function FilterButton({ activeFilters, onClick }: Props) {
  const activeFiltersCount = useCountActiveFilters(activeFilters)

  return (
    <BadgeButton
      text="Filtros"
      icon="filter"
      badge={activeFiltersCount}
      onClick={onClick}
      className="h-[44px] text-base px-4 rounded-sm"
    />
  )
}

export function useCountActiveFilters(activeFilters: object) {
  return useMemo(() => {
    return Object.entries(activeFilters)
      .filter(([key]) => key !== 'ocrSearch')
      .reduce((accumulator, entry) => {
        const key = entry[0]
        const value = entry[1]

        if (value) {
          if (!Array.isArray(value) || key === 'dates') {
            accumulator += 1
          } else if (key === 'createdBetween') {
            accumulator += 1
          } else if (value.length > 0) {
            accumulator += value.length
          }
        }

        return accumulator
      }, 0)
  }, [activeFilters])
}
