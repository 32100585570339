import React, { useMemo } from 'react'
import { Dropdown, Button } from 'antd'
import { Link } from 'react-router-dom'

import { Routes } from 'app/routes'
import { Icon } from 'ui'
import useToggleRecurrenceAction, { ToggleRecurrenceActionProps } from './useToggleRecurrenceAction'
import useDownloadOrdersReportAction from './useDownloadOrdersReportAction'
import { useHasPermission } from 'domain/auth'

function RecurrenceActions({
  recurrence,
  setRecurrenceToInactivate,
  queryKey,
  ...props
}: ToggleRecurrenceActionProps) {
  const userCanManageData = useHasPermission('canManageUsersAndCompanyData')
  const toggleRecurenceAction = useToggleRecurrenceAction({
    recurrence,
    setRecurrenceToInactivate,
    queryKey,
  })
  const downloadOrdersReportAction = useDownloadOrdersReportAction(recurrence)

  const menuItems = useMemo(() => {
    const items = []

    items.push({
      key: 'edit',
      label: <Link to={Routes.EditRecurrence(recurrence.id)}>Editar Recorrência</Link>,
      icon: <Icon name="pencil-line" size={20} className="mr-2 -mt-1" />,
      disabled: !userCanManageData,
      title: !userCanManageData ? 'Você não tem permissão' : ''
    })
    items.push({
      ...toggleRecurenceAction,
      disabled: !userCanManageData,
      title: !userCanManageData ? 'Você não tem permissão' : ''
    })
    items.push(downloadOrdersReportAction)

    return items
  }, [userCanManageData, toggleRecurenceAction, downloadOrdersReportAction])

  return (
    <div onClick={e => e.stopPropagation()}>
      <Dropdown
        placement="bottomRight"
        menu={{
          onClick: ({ domEvent }) => domEvent.stopPropagation(),
          items: menuItems,
        }}
        trigger={['hover']}
        {...props}
      >
        <Button type="primary" ghost size="small" icon={<Icon name="ellipsis" />} />
      </Dropdown>
    </div>
  )
}

export default RecurrenceActions
