import React from 'react'
import { Tag } from 'antd'

import { KitItem } from 'domain/kits/types'

interface Props {
  items: KitItem[]
}

function KitsTableDocuments({ items }: Props) {
  const uniqueNames: any = {}

  return (
    <>
      {items.map(item => {
        const serviceCode = item.service.code!

        if (uniqueNames[serviceCode]) return null

        uniqueNames[serviceCode] = true

        return (
          <Tag
            key={item.id}
            title={item.service.name}
            className="mr-0.5 max-w-52 truncate text-secondary-500"
          >
            {item.service.name}
          </Tag>
        )
      })}
    </>
  )
}

export default KitsTableDocuments
