import { useGroupsByIdsQuery } from 'domain/groups'
import { useSetGroupsIds } from 'domain/orders/state/filters'
import { FilterTag, LoadingIcon } from 'ui'

export default function FilterByGroupTags({ value }: { value: number[] }) {
  const setGroupsIds = useSetGroupsIds()
  const { data: groups, isLoading } = useGroupsByIdsQuery(value)

  if (isLoading) return <LoadingIcon />

  return (
    <>
      {groups?.map(group => (
        <FilterTag
          key={group.id}
          color={group.color}
          onClick={() => setGroupsIds(value.filter(id => id !== group.id))}
        >
          {group.name}
        </FilterTag>
      ))}
    </>
  )
}
