import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useServicePriceQuery } from 'domain/createOrder/document/services'
import { createOrderActions } from 'domain/createOrder/document/state'
import { CreateOrderFormItem, PriceShippingInfo } from 'domain/createOrder/types'
import { useIsCustomerPostPaid } from 'domain/customers'
import ItemDetails from './ItemDetails'

interface Props {
  deletable: boolean
  item: CreateOrderFormItem
  itemIndex: number
}

export default function ItemPrice({ item, itemIndex, deletable }: Props) {
  const dispatch = useDispatch()
  const isPostPaid = useIsCustomerPostPaid()

  const { isFetching } = useServicePriceQuery({
    itemId: item.id,
    options: {
      onSuccess: (prices: PriceShippingInfo) => {
        if (prices) {
          dispatch(
            createOrderActions.setFormItemField({
              id: item.id,
              field: 'orderDetails',
              value: {
                totalValue: isPostPaid ? prices.estimated_price_postpaid_customer : prices.price,
                estimatedDeliveryTime: prices.estimated_delivery_days,
              },
            })
          )
        }
      },
    },
  })

  useEffect(() => {
    dispatch(createOrderActions.setMultipleItemsLoadingPrice(isFetching))
  }, [isFetching])

  return <ItemDetails item={item} itemIndex={itemIndex} deletable={deletable} />
}
