import { useMutation } from 'react-query'
import { message } from 'antd'

import { AuthorizedAPI } from 'api/network/v2'
import { getErrorMessage } from 'api/errors'
import queryClient from 'app/config/queryClient'
import { Kit } from 'domain/kits/types'

type Params = {
  queryKey: any
  kit: Kit
  itemId: number
}

function useDeleteKitItemMutation({ queryKey, kit, itemId }: Params) {
  return useMutation(() => deleteKitItem(kit.id, itemId), {
    onMutate: async () => {
      const optimisticItems = kit.items.filter(o => o.id != itemId)
      const previousKit = queryClient.getQueryData(queryKey)

      // Add optimistic items to kit query
      queryClient.setQueryData(queryKey, (old: any) => ({ ...old, items: optimisticItems }))

      // Return context with the optimistic items
      return { previousKit, optimisticItems }
    },
    onError: (error, variables, context: any) => {
      const apiError = getErrorMessage(error)
      message.error(apiError.description)
      queryClient.setQueryData(queryKey, context.previousKit)
    },
    onSuccess: () => {
      message.success('O item foi excluído do kit.')
    },
  })
}

async function deleteKitItem(kitId: number, itemId: number | string) {
  await AuthorizedAPI.delete(`/kits/${kitId}/items/${itemId}`)
}

export default useDeleteKitItemMutation
