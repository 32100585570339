import { LabelInValue } from '../types'

const federalRegions: LabelInValue[] = [
  {
    value: '1_regiao',
    label: '1ª Região',
    description: `Com sede em Brasília, tem jurisdição sobre os estados do Acre, Amapá, 
    Amazonas, Bahia, Goiás, Maranhão, Mato Grosso, Minas Gerais, Pará, Piauí, 
    Rondônia, Roraima, Tocantins e Distrito Federal.`
  },
  {
    value: '2_regiao',
    label: '2ª Região',
    description: 'Com sede no Rio de Janeiro, abrange os estados do Rio de Janeiro e Espírito Santo.'
  },
  {
    value: '3_regiao',
    label: '3ª Região',
    description: 'Tem sede em São Paulo e tem jurisdição sobre São Paulo e Mato Grosso do Sul.'
  },
  {
    value: '4_regiao',
    label: '4ª Região',
    description: 'Sediada em Porto Alegre, abrange os estados da Região Sul.'
  },
  {
    value: '5_regiao',
    label: '5ª Região',
    description: 'Sediada em Recife, abarca os estados do Ceará, Alagoas, Paraíba, Pernambuco, Rio Grande do Norte e Sergipe.'
  },
  {
    value: '6_regiao',
    label: '6ª Região',
    description: 'Tem competência sobre todo o território do Estado de Minas Gerais.'
  },
]

export default federalRegions
