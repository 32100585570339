import React from 'react'

interface Props {
  serviceName: React.ReactNode
  children?: React.ReactNode
  extra?: React.ReactNode
  className?: string
}

export default function ServiceCard({ serviceName, children, extra, className = '' }: Props) {
  return (
    <div className={`flex flex-col gap-2 mb-4 mask-text ${className}`}>
      <div className="flex flex-row justify-between gap-3">
        <h2 className="text-base text-secondary-500 font-bold">{serviceName}</h2>
        {extra}
      </div>

      <div className="empty:hidden">{children}</div>
    </div>
  )
}
