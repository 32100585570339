import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { captureErrorData } from 'api/errors'
import { RoutePaths } from 'app/routes'
import { useIsCustomerPostPaid } from 'domain/customers'
import { PlaceOrderResponseAPI, PurchaseWithSuccessState } from 'domain/createOrder/types'

import { usePlaceOrder } from '../services'
import {
  useCheckoutStep,
  useChosenPaymentMethod,
  useGetOrderDetailsConfirmed,
  useGetShippingAddress,
} from '../state'
import useSetPostPaidAddress from './useSetPostPaidAddress'

interface Props {
  isAddressNeeded?: boolean
  creditsToInsert: number
  onSubmit: () => Promise<PlaceOrderResponseAPI>
  onSuccess?: (response: PlaceOrderResponseAPI) => boolean
  onError: (error: any) => void
}

function useCheckoutFlow({ isAddressNeeded, creditsToInsert, onSubmit, onSuccess, onError }: Props) {
  const history = useHistory()
  const isPostPaid = useIsCustomerPostPaid()
  const placeOrder = usePlaceOrder()
  const isPlacingOrder = useRef(false)
  const shippingAddress = useGetShippingAddress()
  const orderDetailsConfirmed = useGetOrderDetailsConfirmed()
  const [paymentMethod, setPaymentMethod] = useChosenPaymentMethod()
  const [checkoutStep, setCheckoutStep] = useCheckoutStep()

  const startCheckout = () => setCheckoutStep('in-progress')

  useEffect(() => {
    if (checkoutStep === 'in-progress') {
      const hasPaymentMethod = paymentMethod !== null

      if (!orderDetailsConfirmed && origin !== 'spreadsheet') {
        setCheckoutStep('order-details')
      } else if (isAddressNeeded && !shippingAddress) {
        setCheckoutStep('address')
      } else if (!isPostPaid && creditsToInsert > 0 && !hasPaymentMethod) {
        setCheckoutStep('payment')
      } else {
        setCheckoutStep('all-good')
      }
    } else if (checkoutStep === 'all-good' && isPlacingOrder.current === false) {
      const finishPurchase = async () => {
        isPlacingOrder.current = true

        const stopCheckout = () => {
          setCheckoutStep('idle')
          isPlacingOrder.current = false
        }

        try {
          const response = await placeOrder(onSubmit)
          const canRedirect = onSuccess === undefined || onSuccess(response) === true

          if (canRedirect) {
            history.push(RoutePaths.PURCHASED_WITH_SUCCESS, {
              backofficeId: response.backoffice_id ?? response.purchase?.backoffice_id,
              orderId: response.id ?? response.purchase?.id,
              origin,
            } as PurchaseWithSuccessState)
          } else {
            stopCheckout()
          }
        } catch (error: any) {
          stopCheckout()

          const errorResponse = error.response?.data

          captureErrorData(error, {
            code: '0x805',
            params: JSON.stringify(errorResponse),
          })

          onError(error)
        }
      }

      finishPurchase()
    }
  }, [
    checkoutStep,
    creditsToInsert,
    isAddressNeeded,
    isPostPaid,
    orderDetailsConfirmed,
    origin,
    paymentMethod,
    shippingAddress,
  ])

  useEffect(() => {
    setPaymentMethod(null)
    return () => setCheckoutStep('idle')
  }, [])

  useSetPostPaidAddress()

  return startCheckout
}

export default useCheckoutFlow
