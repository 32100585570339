import { OrderItemListing } from 'domain/orderItem/types'
import { CreateRecurrence } from 'domain/recurrences'
import { useState } from 'react'
import { LargeButton } from 'ui'
import AddItemsToOrderButton from './AddItemsToOrderButton'
import { OrderOverview } from 'domain/orders/types'
import { getUniqueGroups } from 'domain/groups/helpers'

interface OrderMassActionsProps {
  order: OrderOverview
  orderItems: OrderItemListing[]
}

const OrderMassActions = ({ order, orderItems }: OrderMassActionsProps) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const orderGroups = getUniqueGroups(orderItems)

  const onClose = () => {
    setModalOpen(false)
  }

  const anyItemIsMissingData = orderItems.some(item => item.isFromPassportWithoutAllData)
  return (
    <>
      <div className="flex items-center gap-4">
        <LargeButton
          icon="repeat"
          title={
            anyItemIsMissingData
              ? 'Alguns itens não possuem todos os dados necessários para criar uma recorrência'
              : undefined
          }
          disabled={anyItemIsMissingData}
          onClick={() => setModalOpen(true)}
          className="bg-white rounded px-4 py-2"
        >
          Criar recorrência
        </LargeButton>
        <AddItemsToOrderButton orderId={order.id} disable={order.type === 'AI' || order.hasAiExtractData} />
      </div>
      <CreateRecurrence
        openModal={isModalOpen}
        onSuccess={onClose}
        onCloseModal={onClose}
        items={[order]}
        kind="orders"
        orderGroups={orderGroups}
      />
    </>
  )
}

export default OrderMassActions
