import { useDispatch } from 'react-redux'

import { useCreateOrderState } from 'domain/createOrder/document'
import { createOrderActions } from 'domain/createOrder/document'
import { RadioGroupField } from 'domain/servicesCBRdoc/fields'
import { Fields } from 'domain/servicesCBRdoc/config'
import { useMemo } from 'react'
import { getServiceConfig } from 'domain/servicesCBRdoc'

export default function CreateOrderTypeSelect() {
  const { selectedService } = useCreateOrderState()
  const serviceCode = selectedService.code!
  const dispatch = useDispatch()

  const onChange = (selected: string) => {
    dispatch(createOrderActions.setSelectedModel(selected))
  }

  const field = useMemo(() => {
    const { formFields } = getServiceConfig(serviceCode)
    if (formFields.tipo_servico) {
      return formFields.tipo_servico
    }

    return typeField
  }, [serviceCode])

  return (
    <RadioGroupField
      field={field}
      onChange={onChange}
      vertical
      className="w-full [&_.ant-radio]:self-start [&_.ant-radio]:mt-0.5"
      autoFocus
    />
  )
}

const typeField = {
  ...Fields.tipo_fluxo_assinaturas,
  label: ''
}
