import jsPDF from 'jspdf'
import autoTable, { CellDef, ColumnInput } from 'jspdf-autotable'

import { Customer } from 'domain/customers/types'
import { formatCPFCNPJ } from 'utils/formatters'

const DOCUMENT_FONT_SIZE = 8
const TEXT_VERTICAL_POSITION = 10

interface Params {
  body: CellDef[][]
  columns: ColumnInput[]
  customer: Customer
  month: Date
}

async function generateInvoicePDF({ body, columns, customer, month }: Params) {
  const doc = new jsPDF()

  doc.setFontSize(DOCUMENT_FONT_SIZE)
  doc.text(
    `CBRDOC – CENTRAL BRASILEIRA DE DOCUMENTOS 
CNPJ: 34.079.555/0001-92
   `,
    15,
    TEXT_VERTICAL_POSITION,
    { align: 'left' }
  )

  doc.text(
    `${customer.companyName || customer.contactPerson}
 ${customer.cnpj && `CNPJ: ${formatCPFCNPJ(customer.cnpj)}`}`,
    100,
    TEXT_VERTICAL_POSITION,
    { align: 'left' }
  )

  autoTable(doc, {
    body,
    columns,
    didDrawPage: function (bodyPDF) {
      const logoCBRdoc = require('../../../assets/images/logoPDFbase64')
      doc.addImage(logoCBRdoc.image, 'PNG', bodyPDF.settings.margin.left, 25, 70, 10)
    },
    margin: { top: 42 },
    styles: {
      fontSize: 8,
    },
  })

  return doc.save(`Faturas-CBRDoc_${month.getMonth() + 1}/${month.getFullYear()}`)
}

export default generateInvoicePDF
