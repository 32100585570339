import { getFieldName } from 'domain/createOrder/shared'
import { AllExtraInfos } from 'domain/orderItem/types'
import { SelectField as SelectFieldUI, SelectFieldProps as SelectFieldPropsUI } from 'ui'

import useSelectOnlyOption from '../helpers/useSelectOnlyOption'
import { FieldConfig } from '../types'

export interface SelectFieldProps extends Omit<SelectFieldPropsUI, 'label' | 'name'> {
  field: FieldConfig
  itemId?: number | string
  extraInfos?: AllExtraInfos
}

export default function SelectField({
  extraInfos,
  field,
  itemId,
  required = !field.optional,
  options = field.options,
  optionValueKey = field.apiOptionsValueKey,
  optionLabelKey = field.apiOptionsLabelKey,
  onChange,
  ...props
}: SelectFieldProps) {
  const name = getFieldName(field.name, itemId)

  useSelectOnlyOption({ options, fieldName: name, onChange: onChange as any })

  return (
    <SelectFieldUI
      name={name}
      label={field.label}
      required={required}
      options={options ?? extraInfos?.[field.apiExtraInfo!] as any[]}
      optionValueKey={optionValueKey ?? 'url'}
      optionLabelKey={optionLabelKey ?? 'nome'}
      onChange={onChange}
      mode={field.multiple ? 'multiple' : undefined}
      {...props}
    />
  )
}
