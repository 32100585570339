import { Button } from 'ui'
import { useEditCartItem } from '../../helpers'
import { ShoppingCartOrder } from '../../types'

function CartItemViewEditButton({ order }: { order: ShoppingCartOrder }) {
  const editCartItem = useEditCartItem(order.cartItemId)
  return <Button icon="edit" type="text" size="small" className="p-0" onClick={editCartItem} />
}

export default CartItemViewEditButton
