import { useHistory } from 'react-router-dom'

import { CardButton } from 'ui'
import { RoutePaths } from 'app/routes'
import { ServiceIconName } from 'domain/servicesCBRdoc'
import { useCheckPermission } from 'domain/auth'
import { useResetCreateOrder } from 'domain/createOrder/document'
import { GridColumns, Section } from 'layouts'
import { useIsCustomerPostPaid } from 'domain/customers'
import { FeatureModal, FeatureModalConfig } from 'domain/app'

export default function SelectServiceTypePage() {
  const history = useHistory()
  const isPostPaid = useIsCustomerPostPaid()
  const checkPermission = useCheckPermission()
  const canExtractDataUsingAi = checkPermission('canExtractDataUsingAi')

  useResetCreateOrder()

  return (
    <Section fullWidth className="bg-gray-100">
      <GridColumns columnsOnLargeScreens={3}>
        <CardButton
          title="Solicitar um documento"
          icon="document"
          iconSize={36}
          iconBackgroundColor="#527edd"
          description="Peça a emissão de diversos tipos de documentos e certidões. Entregamos em formato digital ou físico, de acordo com sua preferência."
          onClick={() => history.push(RoutePaths.CREATE_ORDER_DOCUMENT)}
        />

        <CardButton
          title="Fazer uma pesquisa"
          icon={ServiceIconName.Research}
          iconBackgroundColor="#527edd"
          iconSize={38}
          description="Pesquise documentos e certidões em instituições de todo o Brasil. Depois de encontrar, solicite a emissão de toda documentação que precisar."
          onClick={() => history.push(RoutePaths.CREATE_ORDER_RESEARCH)}
        />

        {canExtractDataUsingAi && (
          <CardButton
            title="Extrair Dados de Documentos"
            icon={ServiceIconName.AI}
            iconSize={36}
            iconBackgroundColor="#527edd"
            description="Extraia as informações mais importantes de seus documentos. Basta fazer o upload do arquivo e nossa ferramenta identifica e captura o que você precisa."
            onClick={() => history.push(RoutePaths.CREATE_ORDER_AI)}
          />
        )}

        <CardButton
          title="Solicitar um kit"
          icon="folder"
          iconBackgroundColor="#527edd"
          description="Solicite ou crie um conjunto pré-definido de documentos. Ideal para procedimentos de Due Diligence e pedidos com mais de um item."
          onClick={() => history.push(RoutePaths.KITS)}
          iconSize={36}
        />

        <CardButton
          title="Coletar assinaturas e certificados"
          icon={ServiceIconName.SignatureCertification}
          iconBackgroundColor="#527edd"
          iconSize={36}
          description="Ideal para contratos e documentos que exigem validação oficial."
          onClick={() => history.push(RoutePaths.CREATE_ORDER_SIGNATURES_CERTIFICATES)}
        />

        {isPostPaid && (
          <CardButton
            title="Registrar e Lavrar"
            icon={ServiceIconName.DocumentRegistration}
            iconBackgroundColor="#527edd"
            iconSize={32}
            description="Faça o registro dos seus títulos e documentos. Isso inclui contratos, atas, CPR’s, averbações em matrículas entre outros documentos nos cartórios."
            onClick={() => history.push(RoutePaths.CREATE_ORDER_DOCUMENT_REGISTRATION)}
          />
        )}

        <CardButton
          title="Solicitar uma Diligência"
          icon={ServiceIconName.Diligence}
          iconBackgroundColor="#527edd"
          iconSize={30}
          description="Serviços de correspondentes jurídicos em diversas cidades. Isso inclui prefeituras, cartórios, juntas comerciais e outros órgãos."
          onClick={() => history.push(RoutePaths.CREATE_ORDER_DUE_DILIGENCE)}
        />
      </GridColumns>

      <FeatureModal config={config} show={isPostPaid === true} />
    </Section>
  )
}

const config: FeatureModalConfig = {
  id: 'rtd',
  label: 'Nova funcionalidade',
  icon: 'stamp',
  title: 'Registre seus títulos e documentos',
  texts: [
    // eslint-disable-next-line quotes
    "Agora você pode registrar títulos e documentos através de nossa plataforma. Registre contratos, atas, CPR's, averbações em matrículas e muito mais.",
    'Experimente esta nova funcionlidade e simplifique seu processo de registro.',
  ],
  btnOK: {
    label: 'Fazer Registro',
    href: RoutePaths.CREATE_ORDER_DOCUMENT_REGISTRATION,
  },
  btnCancel: 'Testar mais tarde',
}
