import React from 'react'

import { useFilters } from 'domain/orders/state/filters'
import { FilterTag } from 'ui'
import { OrdersFilters } from 'domain/orders/types'

export default function BooleanFiltersTags({ filters }: { filters: Partial<OrdersFilters> }) {
  const [currentFilters, setFilters] = useFilters()
  const booleanFilters = Object.entries(filters) as [Labels, any][]

  return (
    <>
      {booleanFilters.map(([filterName]) => (
        <FilterTag
          key={filterName}
          onClick={() => setFilters({ ...currentFilters, [filterName]: undefined })}
        >
          {FilterLabels[filterName]}
        </FilterTag>
      ))}
    </>
  )
}

type Labels = keyof Omit<
  OrdersFilters,
  | 'dateRange'
  | 'groupsIds'
  | 'ownersIds'
  | 'recurrenceId'
  | 'results'
  | 'searchTerm'
  | 'servicesIds'
  | 'statuses'
>

const FilterLabels: Record<Labels, string> = {
  ai: 'IA',
  automaticGenerated: 'Automáticos',
  isOCRSearch: 'OCR',
  isExpired: 'Vencidos',
  onlyFromRecurrence: 'Criados por recorrência',
}
