import React, { useState } from 'react'
import { Button } from 'antd'

import { useEditKitItemtMutation } from 'domain/kits/services'
import { Kit, KitItem, KitItemDefaultData } from 'domain/kits/types'

import KitItemForm from '../registerKit/KitItemForm'

interface Props {
  kit: Kit
  kitItem: KitItem
  queryKey: any[]
}

function EditKitItem({ kit, kitItem, queryKey }: Props) {
  const [item, setItem] = useState<KitItem>(kitItem)
  const kitMutation = useEditKitItemtMutation(queryKey)
  const isLoading = kitMutation.status === 'loading'

  const onChange = (newData: Partial<KitItemDefaultData>) => {
    setItem({
      ...item,
      defaultData: {
        ...item.defaultData,
        ...newData,
      } as KitItemDefaultData,
    })
  }

  const onFinish = () => {
    kitMutation.mutate({ kitId: kit.id, kitItem: item })
  }

  return (
    <KitItemForm
      kitLegalEntity={kit.legalEntity}
      onFinish={onFinish}
      item={item}
      onChange={onChange}
    >
      <div className="mt-3">
        <Button
          htmlType="submit"
          disabled={isLoading}
          loading={isLoading}
          type="primary"
          size="small"
          className="mr-3"
        >
          Atualizar Dados
        </Button>
      </div>
    </KitItemForm>
  )
}

export default EditKitItem
