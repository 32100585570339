import React from 'react'
import { Button } from 'antd'

import useStartAIOrder from 'domain/createOrder/ai/helpers/useStartAIOrder'
import { AICreateOrderModel } from 'domain/openAI/types'
import { IconText } from 'ui'

import { useAIFormContext } from './AIFormContext'
import { useCheckPermission } from 'domain/auth'

interface Props {
  model: AICreateOrderModel
  children?: React.ReactNode
  checkbox?: React.ReactElement
}

export default function ModelCard({ model, children, checkbox }: Props) {
  const checkPermission = useCheckPermission()
  const canExtractDataUsingAi = checkPermission('canExtractDataUsingAi') 
  const [, setFormData] = useAIFormContext()
  const startIAOrder = useStartAIOrder()

  const onClick = () => {
    const { service, ...formData }: any = model

    if (service) {
      formData.service = {
        label: service.name,
        value: service.id as any,
      }
    } else {
      formData.service = {
        label: 'Outros',
        value: null,
      }
    }

    setFormData(formData)
    startIAOrder(model)
  }

  return (
    <div
      role="button"
      onClick={onClick}
      className="w-full outline-offset-2 bg-white border border-gray-300 rounded-sm hover:border-primary cursor-pointer transition-colors duration-200 mb-3 px-4 py-5 flex flex-row gap-4"
    >
      {checkbox && <div onClick={e => e.stopPropagation()}>{checkbox}</div>}

      <div className="flex-1">
        <div className="w-full flex flex-row justify-between items-center">
          <IconText icon={model.icon} text={model.modelName} />

          <div className="flex flex-row gap-2 items-center text-secondary-500">
            <Button size="small" type="primary" disabled={!canExtractDataUsingAi}>
              Solicitar
            </Button>

            <div onClick={e => e.stopPropagation()} className="flex items-center">
              {children}
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start">
          <div className="text-gray-600 mt-1 max-w-xl overflow-hidden truncate lg:text-clip leading-6">
            {model.service?.label ?? model.documentName}
          </div>
        </div>
      </div>
    </div>
  )
}
