import React, { useMemo, useState } from 'react'
import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useHistory } from 'react-router-dom'

import { errorHandler } from 'api/errors'
import { RoutePaths } from 'app/routes'
import { useAddResearchResultToCart } from 'domain/createOrder/researchResults'
import { MissingFields as MissingFieldsType } from 'domain/createOrder/types'
import { FieldConfig } from 'domain/servicesCBRdoc/fields/types'
import { Modal, SubmitButton } from 'ui'

import MissingFields from './MissingFields'
import { onlyNumbers } from 'utils/formatters'

interface Props {
  missingFields: MissingFieldsType[]
  onClose: () => void
}

function OrderMissingFieldsModal({ missingFields, onClose }: Props) {
  const [loading, setLoading] = useState(false)
  const [form] = useForm()
  const history = useHistory()
  const addResearchResultToCart = useAddResearchResultToCart()

  const uniqueMissingFields = useMemo(() => {
    return missingFields
      .map(({ fields }) => fields)
      .flat()
      .reduce((acc, field) => {
        if (!acc.some((o: FieldConfig) => o.apiName === (field as FieldConfig).apiName)) {
          acc.push(field as FieldConfig)
        }
        return acc
      }, [] as FieldConfig[])
  }, [missingFields])

  const handleSubmit = async (data: any) => {
    setLoading(true)

    for (const missing of missingFields) {
      try {
        const { result, orderItem, fields } = missing
        const missingData: any = {}

        fields.forEach(field => {
          const value = data[field.name]

          if (field.apiRemoveMask) {
            missingData[(field as FieldConfig).name] = onlyNumbers(value)
          } else {
            missingData[(field as FieldConfig).name] = value
          }
        })

        const resultWithFilledData = {
          ...result,
          ...missingData,
        }

        await addResearchResultToCart(resultWithFilledData, orderItem)
      } catch (error) {
        setLoading(false)
        errorHandler(error, { code: '0x006' })
      }
    }

    onClose()
    history.push(RoutePaths.SHOPPING_CART)
  }

  return (
    <Modal open={missingFields.length > 0} title="Adicionar informações" onCancel={onClose}>
      <p className="mb-3">
        Para concluir seu pedido da certidão você precisa preencher alguns dados:{' '}
        <strong>{uniqueMissingFields.map(field => field.label).join(',')}</strong>
      </p>

      <Form
        layout="vertical"
        requiredMark={false}
        scrollToFirstError
        className="md:max-w-4xl lg:max-w-lg"
        form={form}
        onFinish={handleSubmit}
      >
        {uniqueMissingFields.map((field: FieldConfig, index: number) => (
          <MissingFields key={field.apiName} field={field} autoFocus={index === 0} />
        ))}
        <SubmitButton loading={loading} />
      </Form>
    </Modal>
  )
}

export default OrderMissingFieldsModal
