import React from 'react'
import { Button } from 'antd'

import { useOrderQuotationApproval } from 'domain/orders/services'

interface Props {
  orderId: number
}

export default function OrderPriceQuotationApprove({ orderId }: Props) {
  const [aprovePurchase, loading] = useOrderQuotationApproval(orderId, 'approve')

  return (
    <Button type="primary" size="small" loading={loading} onClick={() => aprovePurchase()}>
      Aprovar
    </Button>
  )
}
