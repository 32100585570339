import React, { useState } from 'react'

import { SearchInput } from 'ui'
import { useSearch } from '../state/filters'
import { useSetCurrentPage } from '../state/myModels/pagination'

export default function SearchModelInput() {
  const [term, setTerm] = useSearch()
  const [value, setValue] = useState(term)
  const setCurrentPage = useSetCurrentPage()

  return (
    <SearchInput
      placeholder="Busca por tipo, nome do modelo ou documento"
      value={value}
      onChange={input => {
        setValue(input.target.value)
      }}
      onSearch={() => {
        setTerm(value)
        setCurrentPage(1)
      }}
      className="md:w-96"
      allowClear
    />
  )
}
