import React from 'react'
import { Dropdown, MenuProps } from 'antd'

import Icon from 'ui/Icon/Icon'

interface Props {
  pdfURL: string | null
  onHandleExcel: () => void
}

function DownloadFileDropDown({ pdfURL, onHandleExcel }: Props) {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" href={pdfURL ?? '#'}>
          em PDF
        </a>
      ),
      icon: <Icon name="file-pdf" className="mr-2 text-base" />,
      disabled: !pdfURL,
    },
    {
      key: '2',
      label: 'em Xls (Excel)',
      onClick: () => onHandleExcel(),
      icon: <Icon name="file-xls" className="mr-2 text-base" />,
    },
  ]

  return (
    <Dropdown
      menu={{ items }}
      dropdownRender={menu => <div className="dropdown-options-primary">{menu}</div>}
    >
      <div className="dropdown-options-primary p-0 w-full">
        <Icon name="download" className="mr-2 text-base" />
        Baixar
        <Icon name="chevron-down" className="float-end mt-1 text-base" />
      </div>
    </Dropdown>
  )
}

export default DownloadFileDropDown
