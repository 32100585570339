import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { PrimitiveAtom, WritableAtom, atom, useAtom } from 'jotai'

import { formatDate } from './dateTime'

export function getUrlHashParam(): string {
  return decodeURI(window.location.hash.substring(1))
}

export function useResetUriParams() {
  const history = useHistory()
  const location = useLocation()
  const currentParams = useUrlQuery()

  return () => {
    history.replace(queryString.exclude(location.pathname, Object.keys(currentParams as any)))
  }
}

export function useUrlQuery<T>() {
  const location = useLocation()
  return queryString.parse(location.search || '') as unknown as T
}

export function useUpdateUri_deprecated<T extends {}>() {
  const history = useHistory()
  const currentParams = useUrlQuery() as T

  const updateUriParams = (updateUris: T) => {
    const uris = Object.keys(updateUris) as (keyof T)[]

    uris.forEach(uri => {
      const valueToUpdate = updateUris[uri]

      if (valueToUpdate) {
        currentParams[uri] = valueToUpdate
      } else if (currentParams[uri]) {
        delete currentParams[uri]
      }
    })

    const urlParams = Object.entries(currentParams)
      .filter(value => !!value[1])
      .map(([key, value]) => {
        if (value instanceof Date) {
          return `${key}=${value.toISOString()}`
        }

        return `${key}=${value}`
      })
      .join('&')

    history.replace(`${location.pathname}?${urlParams}`)
  }

  return updateUriParams
}

export function useAtomUri_deprecated<T extends {}>(atm: any) {
  const updateUri = useUpdateUri_deprecated()
  const urlParams = useUrlQuery() as T
  const [atmVal, setFn] = useAtom(atm) as any

  const paramsFilters = useMemo(() => {
    const filtersFromParams = {} as any
    Object.keys(urlParams).forEach(urlParamsKey => {
      if (urlParamsKey as keyof T) {
        if (urlParamsKey === 'results') {
          const resultsVal = urlParams[urlParamsKey as keyof T]
            ? (urlParams[urlParamsKey as keyof T] as any).split(',')
            : []
          filtersFromParams[urlParamsKey] = resultsVal
        }
      }
    })
    return { ...atmVal, ...filtersFromParams }
  }, [urlParams, atmVal])

  function setFilter(values: Partial<T>) {
    updateUri(values)
    setFn(values as any)
  }

  return [paramsFilters, setFilter] as [T, (values: Partial<T>) => void]
}

function updateURI(uriKey: string, newValue: any) {
  const parsedURIs: any = queryString.parse(location.search)
  const value = newValue instanceof Date ? formatDate(newValue) : newValue

  if (parsedURIs[uriKey] !== value) {
    parsedURIs[uriKey] = value

    const entries = Object.entries(parsedURIs).filter(
      ([, value]: any) => {
        return !(
          value === null ||
          value === undefined ||
          value === false ||
          (Array.isArray(value) && value.length === 0) ||
          (typeof value === 'string' && value.trim() === '')
        )
      }        
    )

    const browserUri = entries.map(([key, value]) => `${key}=${value}`).join('&')

    history.pushState(parsedURIs, document.title, browserUri ? '?' + browserUri : '')
  }
}

export function atomURI<T = any>(
  primitiveAtom: PrimitiveAtom<T>,
  uri: string,
  pageAtom?: WritableAtom<number | undefined, any, any>
) {
  return atom(
    get => get(primitiveAtom),
    (_, set, value) => {
      updateURI(uri, value)

      if (pageAtom) {
        set(pageAtom, 1)
      }

      set(primitiveAtom, value as T)
    }
  )
}
