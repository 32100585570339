import { v4 as uuidv4 } from 'uuid'

import { LegalEntity } from 'domain/app/types'
import { CreateOrderParamsFields, CreateOrderState } from 'domain/createOrder/types'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { ShoppingCartOrder } from 'domain/shoppingCart/types'
import { convertTextToArray } from 'utils/transformData'

import { DocumentConfig, ExtractedReportSectionConfig } from '../types'
import CreateOrderService from './CreateOrderService'

export default class CBRdocDocument extends CreateOrderService {
  public checkDynamicFieldsOnKit
  public priceWithDynamicFields = false
  public shoudAutoLoadDynamicFields
  private extraInfoFields
  public extraInfoRequiredFieldsOnly
  public splittableField
  private splittableFieldInOrderName
  public isKitAvailable: boolean
  public placeKitDynamicFieldsAtBottom: boolean | undefined
  public extractedReportSections: ExtractedReportSectionConfig[] | undefined
  public extractedReportOrderResults: any | undefined

  constructor(config: DocumentConfig) {
    super(config)

    const {
      checkDynamicFieldsOnKit,
      extraInfoFields,
      extraInfoRequiredFieldsOnly,
      isKitAvailable,
      placeKitDynamicFieldsAtBottom,
      shoudAutoLoadDynamicFields,
      splittableField,
      splittableFieldInOrderName,
      extractedReportSections,
      extractedReportOrderResults
    } = config

    this.checkDynamicFieldsOnKit = checkDynamicFieldsOnKit
    this.isKitAvailable = isKitAvailable ?? true
    this.extraInfoFields = extraInfoFields
    this.extraInfoRequiredFieldsOnly = extraInfoRequiredFieldsOnly
    this.placeKitDynamicFieldsAtBottom = placeKitDynamicFieldsAtBottom
    this.shoudAutoLoadDynamicFields = shoudAutoLoadDynamicFields
    this.splittableField = splittableField
    this.splittableFieldInOrderName = splittableFieldInOrderName
    this.extractedReportSections = extractedReportSections
    this.extractedReportOrderResults = extractedReportOrderResults
  }

  initialize = () => {
    super.initialize()

    if (this.shoudAutoLoadDynamicFields === undefined) {
      this.shoudAutoLoadDynamicFields =
        Boolean(this.extraInfoFields) || Object.keys(this.dynamicFields).length > 0
    }

    return this
  }

  getAllDynamicFields = (entityType?: LegalEntity) => {
    const fields = Object.values(this.formFields).filter(o => o.apiExtraInfo && o.kind !== 'location')

    if (this.dynamicFields) {
      Object.values(this.dynamicFields).forEach(field => fields.push(field))
    }

    if (entityType) {
      return fields.filter(o => !o.legalEntity || o.legalEntity === entityType)
    }

    return fields
  }

  /*
   * Split orders based on the splittable field
   * If the field is an array, it will split the order for each value
   * If the field is a string, it will split the order for each value separated by comma
   * */
  splitOrders = (order: ShoppingCartOrder) => {
    const { allowMultipleItems, splittableField } = this

    if (!splittableField) return [order]

    const { formData } = order
    const fieldValue = allowMultipleItems
      ? formData['0']?.[splittableField] ?? formData[splittableField]
      : formData[splittableField]

    if (!fieldValue) return [order]

    const isText = !Array.isArray(fieldValue)
    const splittedValues = isText ? convertTextToArray(fieldValue) : fieldValue

    if (splittedValues.length <= 1 && isText) {
      return [order]
    }

    const splitableConfig = (this.formFields[splittableField] ??
      this.dynamicFields[splittableField]) as FieldConfig

    const label = splitableConfig.orderNameText ?? splitableConfig.label

    const updateFormData = (value: string, orderName: string) => {
      const formData = { ...order.formData, orderName }

      if (this.allowMultipleItems) {
        return {
          ...formData,
          0: {
            ...order.formData[0],
            [splittableField]: value,
          },
        }
      }

      return { ...formData, [splittableField]: value }
    }

    const originalOrderName = allowMultipleItems
      ? formData['0'][FieldName.NOME_ITEM_PEDIDO]
      : formData[FieldName.NOME_ITEM_PEDIDO]

    return splittedValues.map(value => {
      const orderName = this.splittableFieldInOrderName
        ? `${this.shortName} - ${label} ${value}`
        : originalOrderName

      const cartItem: ShoppingCartOrder = {
        ...order,
        countItems: 1,
        cartItemId: uuidv4(),
        submitData: {
          ...order.submitData,
          [splittableField]: value,
          name: orderName,
        },
        formData: updateFormData(value, orderName),
      }

      return cartItem
    })
  }

  convertToCartItems = (createOrder: CreateOrderState) => {
    const orders = this.generateCartItems(createOrder)

    const splittedOrders = orders.map(this.splitOrders).flat()
    return splittedOrders
  }

  getExtraInfoParams = (createOrder: CreateOrderParamsFields, itemId: number = 0) => {
    if (!this.extraInfoFields) {
      return null
    }

    return this.extractServiceParam(this.extraInfoFields, createOrder, itemId)
  }

  extractResearchData = (
    result: any,
    resultCode: any,
    orderItem: any
  ): Partial<CreateOrderState> => {
    return {
      formData: {
        ...orderItem.researchResultsOrderSettings![resultCode]!.placeOrder?.formData, 
        ...result,      
      }
    }
  }
}
