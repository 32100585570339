import { useState } from 'react'
import { AxiosResponse } from 'axios'
import { useHistory } from 'react-router-dom'

import { ERRORS, captureError } from 'api/errors'
import { SecretAPI } from 'api/network/v2'
import { RDS } from 'api/rdstation'
import { ApiError, ApiStatus } from 'api/types'
import { RoutePaths } from 'app/routes'
import { convertUser } from 'domain/users'
import { UserAPI } from 'domain/users/types'
import { useSetAuth } from '../state'

type FormData = {
  email: string
  password: string
  keepMeLoggedIn: boolean
}

const useLogin = (redirectTo?: string) => {
  const history = useHistory()
  const [error, setError] = useState<ApiError | null>(null)
  const [status, setStatus] = useState<ApiStatus>('idle')
  const setAuth = useSetAuth()

  const login = async ({ email, password, keepMeLoggedIn }: FormData) => {
    try {
      setStatus('loading')
      setError(null)

      const response: AxiosResponse<UserAPI> = await SecretAPI.post(RoutePaths.LOGIN, {
        email: email.trim(),
        password,
      })      

      setAuth(convertUser(response.data))
      setStatus('success')

      RDS.conversionEvent({
        event_type: 'CONVERSION',
        event_family: 'CDP',
        payload: {
          conversion_identifier: 'Login',
          name: response.data.name,
          email: response.data.email,
          mobile_phone: response.data.phone,
          tags: ['login_feito'],
        },
      })

      if (keepMeLoggedIn) {
        //TODO: cookie!
      }

      if (localStorage.getItem('new_user') === 'true') {
        localStorage.removeItem('new_user')
        history.push(RoutePaths.CREATE_ORDER)
      } else {
        if (!redirectTo || redirectTo === '/carrinho/sucesso') {
          redirectTo = RoutePaths.ORDERS
        }

        history.push(redirectTo)
      }
    } catch (error: any) {
      setStatus('error')
      if ([401, 403].includes(error.response?.status)) {
        setError({
          ...ERRORS.invalidCredentials,
          RID: error.response?.headers['x-request-id']
        })
      } else {
        captureError(error)
        const apiError = [502, 504].includes(error.response?.status) 
          ? {
            ...ERRORS.instability,
            RID: error.response?.headers['x-request-id']
          }
          : {
            ...ERRORS.internalServer,
            RID: error.response?.headers['x-request-id']
          }

        setError(apiError)
      }
    }
  }

  return {
    login,
    error,
    status,
    resetStatus: () => setStatus('idle'),
  }
}

export default useLogin
