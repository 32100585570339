import { OrderItem, ResearchResultsFieldName } from 'domain/orderItem/types'
import { ResearchResultAPI } from 'domain/orderItem/typesAPI'
import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { formatMoney } from 'utils/formatters'

type PesquisaProtestoFields = Record<
  FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ,
  FieldConfig
>

class PesquisaProtesto extends Research {
  formFields: PesquisaProtestoFields = {
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    cnpj: Fields.cnpj,
    nome_pj: Fields.nome_pj,
  }

  checkMissingFieldsOnResults = true

  constructor() {
    super({
      description: 'Retorna se um CPF ou CNPJ possui protestos.',
      doNotOrderResultsWithSameNotary: true,
      id: 39,
      shortName: 'Pesquisa Protesto',
    })
  }

  resultConverter = (apiName: string, value: any): [ResearchResultsFieldName, any] | undefined => {
    if (apiName === 'valor_total') {
      return ['valor_total', formatMoney(value)]
    }

    return undefined
  }

  convertResults = (orderItem: OrderItem, results: ResearchResultAPI[] | null) => {
    if (!results || !results.length) {
      return []
    }

    return results.map((result: any, index) => {
      const converted = this._convertResult(orderItem, result, index)
      converted.valor_total = formatMoney(result.valor_total)
      return converted
    })
  }
}

const service = new PesquisaProtesto().initialize()

export default service
