import React from 'react'

import { CategoryAPI } from 'domain/servicesCBRdoc/types'
import Icon from 'ui/Icon/Icon'

function CategoryTabLabel({ category }: { category: CategoryAPI }) {
  return (
    <div className="flex flex-row items-center">
      <Icon name={category.code as any} className="text-xl mr-1" />{' '}
      <span className="-mb-1">{category.name}</span>
    </div>
  )
}

export default CategoryTabLabel
