import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { FORMATO, TIPO_PESSOA } = FieldName

type CertidaoCumprimentoPCDsFields = Record<FieldName.CPF | FieldName.CNPJ, FieldConfig>

class CertidaoCumprimentoPCDs extends Certificate {
  formFields: CertidaoCumprimentoPCDsFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
  }

  constructor() {
    super({
      id: 31,
      priceByFields: [FORMATO, TIPO_PESSOA],
      shortName: 'MT - Cumprimento PCDs',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoCumprimentoPCDs().initialize()

export default service
