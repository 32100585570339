import React from 'react'

import { useCurrentUser } from 'domain/auth'
import { Pagination } from 'ui'
import { setStorageInt } from 'utils/localStorage'
import {
  useGetOverviewTotalResults,
  useOverviewCurrentPage,
  useOverviewPageSize,
} from 'domain/orders/state/pagination'

interface Props {
  topRef: React.RefObject<HTMLDivElement>
}

function OrderOverviewPagination({ topRef }: Props) {
  const user = useCurrentUser()
  const localPageSizeName = `orderItemsPageSize-${user.id}`
  const [itemsCurrentPage, setOverviewCurrentPage] = useOverviewCurrentPage()
  const [itemsPageSize, setOverviewPageSize] = useOverviewPageSize()
  const itemsTotalResults = useGetOverviewTotalResults()

  const handleChangePage = (page: string) => {
    setOverviewCurrentPage(Number(page))
    topRef.current?.scrollIntoView()
  }

  const handleChangePageSize = (pageSize: number) => {
    setStorageInt(localPageSizeName, pageSize)
    setOverviewPageSize(pageSize)
  }

  return (
    <Pagination
      className="pb-7"
      totalCount={itemsTotalResults}
      currentPage={Number(itemsCurrentPage)}
      onChangePage={handleChangePage as any}
      pageSize={itemsPageSize}
      onPageSizeChange={handleChangePageSize}
    />
  )
}

export default OrderOverviewPagination
