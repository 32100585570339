import React from 'react'
import { Alert } from 'antd'
import { Icon } from 'ui'
import { ExtractedReportSectionConfig, ExtractedReportSectionsKeys } from 'domain/servicesCBRdoc/config/types'

type ViewExtractedReportProps = {
  sections: ExtractedReportSectionConfig[]
  extracted?: Record<string, any>
}

export default function ViewExtractedReport({ extracted, sections }: ViewExtractedReportProps) {
  const { summary } = extracted as any
  return (
    <div className="px-4 pt-4" id='extracted-report-content'>
      {summary && (
        <section className="border-b-2 border-gray-400 mx-auto">
          <h1 className="text-secondary-500 font-bold text-lg mb-2">Resumo da matrícula</h1>
          <div className="flex items-center justify-between my-4">
            <div className="text-secondary-300 text-sm font-semibold flex items-center gap-4">
              <Icon name="home" strokeWidth={2} className="text-secondary-300 mb-1" /> Área total{' '}
              <span className="text-secondary-600">{summary.area}</span>
            </div>
            <div className="text-secondary-300 text-sm font-semibold flex items-center gap-4">
              <Icon name="user" strokeWidth={2} className="text-secondary-300 mb-1" /> Proprietário(os){' '}
              <span className="text-secondary-600">{summary.owners}</span>
            </div>
            <div className="text-secondary-300 text-sm font-semibold flex items-center gap-4">
              <Icon name="alert-circle" strokeWidth={2} className="text-secondary-300 mb-1" /> Ônus ativos{' '}
              <span className="text-secondary-600">{summary.gravames}</span>
            </div>
          </div>
        </section>
      )}

      {sections.map(({ title, properties, fromArray, propertiesTitleFrom }) => {
        if (fromArray && extracted && extracted[fromArray]) {
          return extracted[fromArray].map((data: any) => (
            <section key={title} className="border-b-2  border-gray-400 mx-auto">
              <h2 className="text-secondary-500 font-bold text-lg mb-2 mt-8">{propertiesTitleFrom ? data[propertiesTitleFrom] : title}</h2>
              <ul>
                {data &&
                  properties.map(({ label, key, formatter, fromArray }) => {
                    const value = extractValueWithKey(fromArray ? data[fromArray] : data, key)
                    if (!value) return null

                    return (
                      <PropertyDetails
                        key={Array.isArray(key) ? key.join(':') : key}
                        label={label}
                        value={formatter ? formatter(value) : value}
                      />
                    )
                  })}
              </ul>
            </section>
          ))
        }

        return (
          <section key={title} className="border-b-2 border-gray-400 mx-auto">
            <h2 className="text-secondary-500 font-bold text-lg mb-2 mt-8">{title}</h2>
            <ul>
              {extracted &&
                properties.map(({ label, key, formatter, fromArray }) => {
                  const value = extractValueWithKey(fromArray ? extracted[fromArray] : extracted, key)
                  if (!value) return null

                  return (
                    <PropertyDetails
                      key={Array.isArray(key) ? key.join(':') : key}
                      label={label}
                      value={formatter ? formatter(value) : value}
                    />
                  )
                })}
            </ul>
          </section>
        )
      })}

      <Alert
        type="info"
        message="Essas informações foram extraídas do documento por meio de inteligência artificial. Embora tenhamos tomado todas as precauções possíveis, é importante observar que algumas informações podem não ser completamente precisas."
        showIcon
        icon={<Icon name="robot" color="#527edd" />}
        className="mt-8 mx-auto mb-4"
      />
    </div>
  )
}

function extractValueWithArrayKey(
  summary: Record<string, any>,
  key: ExtractedReportSectionsKeys | ExtractedReportSectionsKeys[]
) {
  if (!summary) return null
  if (Array.isArray(key)) {
    return key.map(k => summary[k] ?? '-').join('/')
  }

  return summary[key] ?? null
}

function extractValueWithKey(
  summary: Record<string, any> | Array<Record<string, any>>,
  key: ExtractedReportSectionsKeys | ExtractedReportSectionsKeys[]
) {
  if (Array.isArray(summary)) {
    return summary.map(sum => extractValueWithArrayKey(sum, key) ?? '-')
  }

  return extractValueWithArrayKey(summary, key) ?? null
}

function PropertyDetails({ label, value }: { label: string; value?: string | React.ReactElement }) {
  if (!value) return null

  return (
    <li className="text-secondary-300 text-sm my-4 flex gap-4">
      <p className="basis-1/4 shrink-0">{label}</p> <span className="text-secondary-600">{value}</span>
    </li>
  )
}
