import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { removeUndefined } from 'utils/transformData'
import { AvailableExtras, DeliveryFormat, SelectedService } from '../../types'

type UseExtrasQueryType = {
  service: SelectedService
  params: GetAvailableExtrasParams
  onSuccess?: (data: AvailableExtras) => void
}

function useExtrasQuery({ service, params, onSuccess }: UseExtrasQueryType) {
  return useQuery(
    ['available-extras', service, params],
    () => getAvailableExtras(service, removeUndefined(params)),
    {
      enabled: Boolean(service.id && service.categoryId),
      onSuccess,
    }
  )
}

export interface GetAvailableExtrasParams {
  url_cartorio?: string | string[]
  tipo?: string | null
  url_uf?: string | string[]
  url_cidade?: string
  formato?: DeliveryFormat
}

const getAvailableExtras = async (service: SelectedService, params: GetAvailableExtrasParams) => {
  const response: AxiosResponse<AvailableExtras> = await AuthorizedAPI.post(
    `/services/${service.id}/categories/${service.categoryId}/extras-prices-shipping-info`,
    {
      detailed_service_data: params,
    }
  )

  return response.data
}

export default useExtrasQuery
