import {
  useSetCurrentPage,
  usePageSize,
  useGetTotalResults,
  useGetCurrentPage,
} from 'domain/orders/state/pagination'
import { Pagination } from 'ui'

interface Props {
  topRef: React.RefObject<HTMLDivElement>
}

export default function OrdersPagination({ topRef }: Props) {
  const totalResults = useGetTotalResults()
  const [pageSize, setPageSize] = usePageSize()
  const setCurrentPage = useSetCurrentPage()
  const page = useGetCurrentPage()

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    topRef.current?.scrollIntoView()
  }

  return (
    <Pagination
      totalCount={totalResults}
      currentPage={page!}
      onChangePage={handlePageChange}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
    />
  )
}
