import React from 'react'

import { Icon } from 'ui'

function RequestRefundDone({ action }: { action: JSX.Element }) {
  return (
    <>
      <div className="border-b border-gray-300 pt-0 pb-7">
        <div className="flex">
          <Icon name="checkmark-circle" className="text-green-500 text-6xl mr-5" />
          <h1 className="font-bold text-2xl mb-3">Reembolso solicitado com sucesso</h1>
        </div>
      </div>

      <p className="my-7">Os créditos já estão disponíveis na sua conta.</p>

      {action}
    </>
  )
}

export default RequestRefundDone
