import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const {
  UPLOAD,
  URL_CARTORIO,
  URL_CIDADE,
  URL_UF,
} = FieldName

type CertidaoProtestoFields = Record<
  | FieldName.CNPJ
  | FieldName.CPF
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.NUMERO_TITULO
  | FieldName.OBSERVACAO
  | FieldName.UPLOAD
  | FieldName.URL_CARTORIO
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

class CertidaoBaixaProtesto extends Certificate {
  formFields: CertidaoProtestoFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    url_cartorio: Fields.url_cartorio,
    cpf: Fields.cpf,
    cnpj: Fields.cnpj,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
    numero_titulo: Fields.numero_titulo,
    [UPLOAD]: {
      ...Fields[UPLOAD],
      apiName: 'carta_anuencia'
    },
    observacao: {
      ...Fields.observacao,
      apiName: 'observacoes',
      maxLength: 260
    },
  }

  constructor() {
    super({
      availableFormatsFields: [URL_CARTORIO, URL_CIDADE, URL_UF],
      extraInfoFields: [URL_CARTORIO, URL_CIDADE, URL_UF],
      id: 103,
      isKitAvailable: false,
      shoudAutoLoadDynamicFields: true,
      priceByFields: [
        URL_CARTORIO,
        URL_CIDADE,
        URL_UF,
      ],
      shortName: 'Baixa de Protesto',
      steps: ['localizacao', 'upload', 'dados'],
    })
  }
}

const service = new CertidaoBaixaProtesto().initialize()

export default service
