import React from 'react'
import { useLocation } from 'react-router-dom'

import { Step, Steps } from 'ui'

interface Props {
  steps: Record<string, string>
}

function StepsByPath({ steps }: Props) {
  const location = useLocation()
  const uri = location.pathname.split('/').filter(Boolean).pop()
  const stepPaths = Object.keys(steps)
  const index = stepPaths.findIndex(step => step === uri)
  const currentStep = index > -1 ? index : 0

  const orderSteps = stepPaths!.map(
    (step, i): Step => ({
      title: steps[step],
      status: currentStep > i ? 'done' : 'pending',
    })
  )

  return <Steps current={currentStep} steps={orderSteps} className="max-w-xl ml-8" />
}

export default StepsByPath
