import Excel from 'exceljs'

async function generatePrepaidExcel(tableData: any) {
  const workbook = new Excel.Workbook()

  const sheet = workbook.addWorksheet('faturas-cbrdoc')

  sheet.columns = 
    [{ width: 35 }, { width: 15 }, { width: 15 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }]

  //add headers
  const rowHeader = sheet.addRow([
    'Nome do pedido',
    'Nº do pedido',
    'Data do pedido',
    'Créditos utilizados',
    'Bônus',
    'Data de entrega',
    'Usuário',
  ])

  rowHeader.font = { bold: true }

  tableData?.forEach((row: any) => {
    sheet.addRow(Object.values(row))
  })

  const buf = await workbook.xlsx.writeBuffer()

  return buf as Buffer
}

export default generatePrepaidExcel
