import { useMemo } from 'react'

import {
  useGetKit,
  useGetLegalEntity,
  useGetServicesForm,
  useSetDynamicFields,
} from 'domain/createOrder/kit/state'
import { KitItem } from 'domain/kits/types'
import { useAllExtraInformationsQuery } from 'domain/orderItem/shared'
import { KitCreateOrder, getServiceConfig } from 'domain/servicesCBRdoc'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { KitFieldConfig } from '../types'

export default function useLoadKitDynamicFields(kitItem: KitItem, enabled = true) {
  const { id } = kitItem
  const formServicesData = useGetServicesForm()
  const setDynamicFields = useSetDynamicFields(id)
  const kit = useGetKit()
  const legalEntity = useGetLegalEntity()

  const createOrderState = useMemo(() => {
    return new KitCreateOrder(
      { formServicesData, kitLegalEntity: kit.legalEntity },
      kitItem
    ).generate()
  }, [formServicesData, kit])
   
  const query = useAllExtraInformationsQuery({
    service: kitItem.service,
    itemId: kitItem.id,
    data: createOrderState,
    enabled
  })

  const { data } = query

  const fields = useMemo(() => {
    if (!data) {
      setDynamicFields([])
      return []
    }

    const service = getServiceConfig(kitItem.service.code)
    const entries = Object.entries(data)
    let requiredFields: string[] = []
    const fieldsConfig: Partial<Record<FieldName, KitFieldConfig>> = {}

    const checkLegalEntity = (field: FieldConfig) => {      
      return !kit.legalEntity || !field.legalEntity || field.legalEntity === legalEntity
    }

    entries.forEach(([key, value]) => {
      if (key === 'campos-necessarios') {
        requiredFields = value as string[]
      } else {
        const field = service.getFieldBy(key, 'apiExtraInfo')

        if (
          field &&
          !field.ignoreOnKitsAutomaticForm &&
          checkLegalEntity(field) &&
          field.name !== 'tipo_pessoa'
        ) {
          fieldsConfig[field.name] = {
            ...field,
            apiExtraInfo: undefined,
            options: value,
            kitItem,
          }
        }
      }
    })

    if (requiredFields.length > 0) {
      requiredFields.forEach(name => {
        if (name === 'nome' && legalEntity) {
          name = legalEntity === 'juridica' ? 'nome_pj' : 'nome_pf'
        }

        const field = service.getField(name)

        if (field && !field.ignoreOnKitsAutomaticForm && checkLegalEntity(field)) {
          if (!fieldsConfig[field.name]) {
            fieldsConfig[field.name] = {
              ...field,
              optional: false
            }
          } else {
            fieldsConfig[field.name]!.optional = false
          }
        }
      })
    }

    const dynamicFieldsConfig = Object.values(fieldsConfig)

    setDynamicFields(dynamicFieldsConfig)

    return dynamicFieldsConfig
  }, [data, legalEntity])

  return { ...query, fields }
}
