import React, { useState } from 'react'
import { Button, Input } from 'antd'

import ShareOrderModalItem from './ShareOrderModalItem'
import useShareOrderItem from '../../services/useShareOrderItem'

interface Props {
  orderItemId: string | number
}

function ShareOrderModalLink({ orderItemId }: Props) {
  const [generatedLink, setGeneratedLink] = useState<string>()
  const [linkCopied, setLinkCopied] = useState(false)
  const [shareOrder, loading] = useShareOrderItem()

  const onClick = () => {
    shareOrder({
      orderItemId,
      params: { via: 'link' },
      onSuccess: data => {
        if (data?.generated_link) {
          navigator.clipboard.writeText(data.generated_link)
          setLinkCopied(true)
          setGeneratedLink(data.generated_link)
        }
      },
    })
  }

  return (
    <>
      <ShareOrderModalItem text="Gerar link" icon="link" onClick={onClick} loading={loading}>
        {linkCopied && <p className="text-xs text-green-500 text-right">Link copiado!</p>}
      </ShareOrderModalItem>
      {generatedLink && (
        <div className="flex py-3 border-b border-gray-300">
          <Input value={generatedLink} disabled className="mr-2" />
          <Button
            type="primary"
            className="w-40"
            onClick={() => navigator.clipboard.writeText(generatedLink)}
          >
            Copiar link
          </Button>
        </div>
      )}
    </>
  )
}

export default ShareOrderModalLink
