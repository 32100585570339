import React, { useReducer } from 'react'

import { ChangePasswordModal } from 'domain/users'

function ChangePasswordMenu() {
  const [open, toggleOpen] = useReducer(prev => !prev, false)

  return (
    <>
      <a
        href="#"
        onClick={e => {
          e.preventDefault()
          toggleOpen()
        }}
      >
        Alterar minha senha
      </a>

      <ChangePasswordModal open={open} onRequestClose={toggleOpen} />
    </>
  )
}

export default ChangePasswordMenu
