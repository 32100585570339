import { Input, InputProps } from 'antd'

export interface SearchProps extends Omit<InputProps, 'enterbutton' | 'size'> {
  onSearch?: (value: string) => void
  loading?: boolean
}

export default function SearchInput({ className = '', allowClear = true, ...props }: SearchProps) {
  return (
    <Input.Search
      className={`[&_.ant-input-search-button]:h-[41px] [&_button.ant-btn-default]:bg-white 
      [&_input]:text-sm [&_.ant-input-affix-wrapper]:py-2
      [&_button.ant-btn-default]:text-gray-600 [&_button.ant-btn-default]:border-transparent 
      [&_button.ant-btn-default]:border-r-gray-400 [&_span.ant-input-group-addon]:bg-gray-400       
      [&_button.ant-btn-default]:border-l-gray-400
      [&_button.ant-btn-default]:hover:text-gray-600 [&_button.ant-btn-default]:z-0 z-10 
      min-w-[330px]
      ${className}`}
      allowClear={allowClear}
      {...props}
    />
  )
}
