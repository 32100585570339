import React from 'react'

import Month from './PrepaidInvoicesFiltersMonth'
import Operation from './PrepaidInvoicesFiltersOperation'
import Search from './PrepaidInvoicesFiltersSearch'

function PrepaidInvoicesFilters() {
  return (
    <div className="p-7 py-5 w-full flex flex-col md:flex-row bg-gray-200 border-b border-gray-300">
      <Month />
      <Search />
      <Operation />
    </div>
  )
}

export default PrepaidInvoicesFilters
