import React, { useReducer } from 'react'

import { HelpLine } from 'domain/app'
import { OrderItem } from 'domain/orderItem/types'
import { OrderStatuses, ResultCode } from 'domain/orders/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { Research } from 'domain/servicesCBRdoc/config'
import { Alert, Button, Icon, ViewPDF } from 'ui'

interface Props {
  orderItem: OrderItem
}

export default function ViewOrderResearchStatusOrPDF({ orderItem }: Props) {
  const [showPDF, toggleShowPDF] = useReducer(prev => !prev, false)
  const hasPDFFile = !!orderItem.fileURL
  const { hasResultsInPDF } = getServiceConfig<Research>(orderItem.service.code)

  if (hasPDFFile && (showPDF || hasResultsInPDF || orderItem.result === ResultCode.POSITIVE)) {
    return (
      <div className="p-3 flex flex-col flex-1 items-center bg-white rounded-lg">
        <ViewPDF fileUrl={orderItem.fileURL as string} />
      </div>
    )
  }

  return (
    <div className="p-7 flex flex-col flex-1 items-center bg-white">
      <Icon name="warning-file"/>

      <h1 className="font-bold text-2xl mt-10 mb-5">{OrderStatuses[orderItem.status].name}</h1>

      <div className="my-6 mx-auto flex p-4 py-2 bg-opacity-15 border-2 rounded-lg w-1/2 bg-yellow-500 border-yellow-500">
        <div className="w-10 h-10 rounded-full flex flex-none justify-center items-center mr-3">
          <Icon name="warning" color="#c97a04" size={24} />
        </div>
        <p className="mr-3 text-sm my-auto text-wrap hyphens-auto break-words w-full pr-10">
          As pesquisas foram concluídas porém nenhum resultado foi encontrado.
        </p>
      </div>

      {hasPDFFile && !showPDF && (
        <Button type="primary" className="rounded-lg" onClick={toggleShowPDF}>
          Exibir mais informações
        </Button>
      )}

      <HelpLine />
    </div>
  )
}
