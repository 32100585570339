import React, { useReducer } from 'react'
import { Button, Form, Input } from 'antd'

import { useFilters, useResetKitsFiltersState } from 'domain/kits/state'
import { ClearFiltersButton, FilterButton, Modal } from 'ui'

import KitsFilterOwner from './KitsFilterOwner'
import KitsFilterEntityType from './KitsFilterEntityType'
import KitsFilterDocumentSelect from './KitsFilterDocumentSelect'
import KitsFiltersDateRangePicker from './KitsFiltersDateRangePicker'

export default function KitsFilters() {
  const [filters, setFilters] = useFilters()

  const resetFilters = useResetKitsFiltersState()

  const [modalOpen, toggleModalVisible] = useReducer(prev => !prev, false)
  const [form] = Form.useForm()
  
  return (
    <>
      <Modal
        open={modalOpen}
        title="Filtros"
        extra={
          <ClearFiltersButton
            onClick={() => {
              resetFilters()
              Object.keys(filters).forEach(filterKey => {
                form.setFieldValue(filterKey, undefined)
              })
              toggleModalVisible()
            }}
          />
        }
        onCancel={toggleModalVisible}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            setFilters(values)
            toggleModalVisible()
          }}
          initialValues={filters}
          requiredMark={false}
          scrollToFirstError
          className="max-w-lg"
        >
          <Form.Item name="nameOrId" label="Nome ou ID">
            <Input placeholder="Informe um nome ou ID" />
          </Form.Item>

          <KitsFilterOwner />
          <KitsFilterDocumentSelect />
          <KitsFilterEntityType />
          <KitsFiltersDateRangePicker />

          <Form.Item className="mt-7">
            <Button htmlType="submit" type="primary" block>
              Aplicar filtros
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <FilterButton activeFilters={filters} onClick={toggleModalVisible} />
    </>
  )
}
