import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { User } from 'domain/users/types'

export default function useOrdersOwnerFilterQuery() {
  return useQuery('users-with-visible-owners', async () => {
    const { data }: AxiosResponse<User[]> = await AuthorizedAPI.get('/users/with-visible-orders')
    return data
  })
}
