import React from 'react'
import { Focused } from 'react-credit-cards'
import { MaskedInput } from 'ui'
import { isAfterToday, parseDate, validateDate } from 'utils/dateTime'

interface Props {
  cardExpiry: string
  setCardExpiry: (value: string) => void
  setCardFocus: (value: Focused) => void
}

function CreditCardExpiryDate({ cardExpiry, setCardExpiry, setCardFocus }: Props) {
  return (
    <MaskedInput
      label="Validade"
      name="dueDate"
      mask="00/00"
      placeholder="mm/aa"
      size="middle"
      value={cardExpiry}
      onChange={e => setCardExpiry(e.target.value)}
      onFocus={() => setCardFocus('expiry')}
      required
      rules={CardExpiryRules}
    />
  )
}

const CardExpiryRules = [
  {
    validator: (_: any, value: string) => {
      if (!validateDate(value, 'MM/YY')) {
        return Promise.reject('Informe uma data válida')
      }

      const isCardExpired = !isAfterToday(parseDate(value, 'MM/YY').toString())
      if (isCardExpired) {
        return Promise.reject('Informe uma data válida')
      }

      return Promise.resolve()
    }
  }
]

export default CreditCardExpiryDate
