import React from 'react'
import { Button, Checkbox } from 'antd'

import { useHasPermission } from 'domain/auth'
import { Group, GroupAPI } from 'domain/groups/types'
import { removeFromArrayById } from 'utils/transformData'

import GroupsActionsDropdown from './GroupsActionsDropdown'
import { Icon } from 'ui'

interface Props {
  group: GroupAPI
  isChecked: boolean
  onDeleteGroup: (groupId: number) => void
  onEditGroup: React.Dispatch<React.SetStateAction<Group | undefined>>
  onSelectGroup: React.Dispatch<React.SetStateAction<GroupAPI[]>>
}

export default function GroupsAssociationCheckbox({
  group,
  isChecked,
  onDeleteGroup,
  onEditGroup,
  onSelectGroup,
}: Props) {
  const isAdmin = useHasPermission('canManageUsersAndCompanyData')

  const onToggleCheck = (e: any) => {
    onSelectGroup(prev => {
      return e.target.checked ? [group, ...prev] : removeFromArrayById(prev, group.id)
    })
  }

  return (
    <div className="flex justify-between">
      <label className="p-2 w-80 rounded cursor-pointer" style={{ backgroundColor: group.color }}>
        <Checkbox className="mr-2" checked={isChecked} onChange={onToggleCheck} />
        <span className="text-white">{group.name}</span>
      </label>
      {isAdmin && (
        <GroupsActionsDropdown group={group} setGroupToEdit={onEditGroup} onDeleteGroup={onDeleteGroup}>
          <Button
            type="primary"
            ghost
            size="small"
            icon={<Icon name="more-vertical" />}
            className="flex justify-center"
          />
        </GroupsActionsDropdown>
      )}
    </div>
  )
}
