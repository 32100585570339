import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

import { useGetChosenPaymentMethod } from 'domain/checkout'
import { OrderSuccessful } from 'domain/createOrder'
import { PurchaseWithSuccessState } from 'domain/createOrder/types'
import { useResetShoppingCart } from 'domain/shoppingCart'

export default function AfterPurchaseWithSuccess() {
  const locationState = useLocation<PurchaseWithSuccessState>().state
  const resetShoppingCart = useResetShoppingCart()
  const _chosenPaymentMethod = useGetChosenPaymentMethod()
  const payment = useRef(_chosenPaymentMethod).current
  const { backofficeId, orderId } = locationState ?? {}

  useEffect(resetShoppingCart, [])

  return <OrderSuccessful orderId={orderId} orderNumber={backofficeId} payment={payment} />
}
