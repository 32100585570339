import { useMemo } from 'react'
import { Dropdown, Tabs } from 'antd'

import { OCRDocument } from 'domain/myFiles/types'
import { Icon, ViewPDF } from 'ui'
import { DocumentTabName } from 'domain/orderItem/types'

import ViewExtractionReport from './ViewExtractionReport'
import { useActiveTab } from '../state'
import { dropdownOptions } from './TabItems'
import { useGetOrderItem } from 'domain/orderItem/shared'
import { ViewFileOCRContent } from 'domain/myFiles'
import { useExtractedSummaryAndSections } from '../services/useExtractedSummary'
import { useIsSmallDevice } from 'utils/screen'

interface Props {
  fileUrl: string
  ocr: OCRDocument
  onChange: React.Dispatch<React.SetStateAction<DocumentTabName>>
  openAITab?: React.ReactNode
  reportTab?: React.ReactNode
}

export default function ViewCertificateTabs({ fileUrl, openAITab, reportTab, onChange }: Props) {
  const [extracted, sections] = useExtractedSummaryAndSections()
  const isSmallDevice = useIsSmallDevice()
  const orderItem = useGetOrderItem()
  const activeTab = useActiveTab()

  const tabItems = useMemo(() => {
    const items = []

    if (reportTab || sections) {
      items.push({
        label: 'Ficha',
        key: 'report',
        icon: <Icon size={22} name="document" />,
        children: reportTab ?? <ViewExtractionReport extracted={extracted} sections={sections} />,
      })
      onChange('report')
    }

    items.push({
      key: 'original',
      icon: <Icon className='size-5' name="pdf-file" />,
      label: isSmallDevice ? 'PDF' : 'Documento em PDF',
      children: (
        <>
          <div className="row flex flex-1">
            <ViewPDF fileUrl={fileUrl} />
          </div>
        </>
      ),
    })

    if (orderItem.ocr && orderItem.ocr.pages && orderItem.fileURL) {
      items.push({
        label: 'Transcrição',
        key: 'transcript',
        icon: <Icon size={22} name="document" />,
        children: <ViewFileOCRContent />,
      })
    }

    if (openAITab) {
      items.push({
        key: 'openAI',
        icon: <Icon size={22} name="robot" />,
        label: isSmallDevice ? 'Extração' : 'Extração de Dados',
        children: openAITab,
      })
    }

    return items
  }, [fileUrl, reportTab, openAITab, sections, orderItem, isSmallDevice])

  const dropdownMenu = { items: dropdownOptions(orderItem, activeTab) }

  return (
    <div className="flex flex-1 relative lg:pr-4 pl-8 pr-8">
      <Tabs
        className="full-content-tabs w-full pb-0 pt-0 bg-white rounded-lg justify-start"
        tabBarStyle={{ marginLeft: '1.5rem', lineHeight: '14px' }}
        onChange={onChange as any}
        tabBarGutter={16}
        items={tabItems}
      />

      {(activeTab === 'openAI' || activeTab === 'report') && (
        <Dropdown
          menu={dropdownMenu}
          className="absolute cursor-pointer lg:right-6 right-10 top-2"
          dropdownRender={menu => <div className="dropdown-options-primary">{menu}</div>}
        >
          <div className="bg-primary gap-1 flex items-center p-2 px-3 text-white rounded-sm">
            <Icon name="download" /> <p className="leading-[15px]">Baixar</p> <Icon name="chevron-down" />
          </div>
        </Dropdown>
      )}
    </div>
  )
}
