import { useState } from 'react'

import { GenericError } from 'api/errors'
import { AuthorizedAPI } from 'api/network/v2'
import { ApiStatus } from 'api/types'
import { RoutePaths } from 'app/routes'
import queryClient from 'app/config/queryClient'
import MyFilesQueryKeys from './MyFilesQueryKeys'

export default function useUploadExplorerFile() {
  const [status, setStatus] = useState<ApiStatus>('idle')
  const [data, setData] = useState()

  const upload = async (file: FormData) => {
    try {
      setStatus('loading')
      const response = await AuthorizedAPI.post(RoutePaths.UPLOAD_EXPLORER_ITEM, file,  {
        headers: { 'content-type': 'multipart/form-data' },
      })

      const errors = response.data.filter((r: any) => !r.success)

      if (errors.length) {
        throw new GenericError(
          'Erro ao tentar fazer upload dos arquivos',
          errors.map((r: any) => r.error)
        )
      }

      queryClient.invalidateQueries(MyFilesQueryKeys.MyFilesList)

      setData(response.data)
      setStatus('success')
    } catch (error: any) {
      setStatus('error')
      throw error
    }
  }

  return {
    upload,
    data,
    status,
    resetStatus: () => setStatus('idle'),
  }
}
