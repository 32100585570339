import React, { useState } from 'react'
import { Checkbox, Spin } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { AICreateOrderModel } from 'domain/openAI/types'
import { Pagination } from 'ui'
import { useCurrentPage, useGetTotalResults, usePageSize } from '../state/myModels'
import MyModelCard from './MyModelCard'
import MyModelsMassActions from './MyModelsMassActions'

interface Props {
  models: AICreateOrderModel[]
  loading: boolean
}

export default function MyModelsList({ models, loading }: Props) {
  const [selected, setSelected] = useState<AICreateOrderModel[]>([])
  const allSelected = selected.length > 0 && selected.length === models.length

  const onCheckAll = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked
    setSelected(isChecked ? models : [])
  }

  return (
    <div className="pb-64">
      <Spin spinning={loading}>
        <Checkbox className="ml-4 mb-2" checked={allSelected} onChange={onCheckAll} />

        <div>
          {models.map(model => (
            <MyModelCard
              key={model.id}
              model={model}
              checkbox={
                <Checkbox
                  className="mt-1"
                  checked={selected.some(o => o.id === model.id)}
                  onChange={e => {
                    setSelected(prev => {
                      if (e.target.checked) {
                        return [...prev, model]
                      }

                      return prev.filter(o => o.id !== model.id)
                    })
                  }}
                />
              }
            />
          ))}
        </div>

        <MyModelsPagination />
        <MyModelsMassActions selected={selected} onClose={() => setSelected([])} />
      </Spin>
    </div>
  )
}

function MyModelsPagination() {
  const [pageSize, setPageSize] = usePageSize()
  const [currentPage, setCurrentPage] = useCurrentPage()
  const totalResults = useGetTotalResults()

  return (
    <Pagination
      pageSize={pageSize}
      currentPage={currentPage!}
      totalCount={totalResults}
      onChangePage={setCurrentPage}
      onPageSizeChange={setPageSize}
    />
  )
}
