import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Routes } from 'app/routes'
import { createOrderActions } from 'domain/createOrder/document/state'
import { AIShoppingStep } from 'domain/createOrder/types'
import { ModelQuestionsForm } from 'domain/openAI/models'

export default function AIOrderQuestionsStep() {
  const history = useHistory()
  const dispatch = useDispatch()

  const onSubmit = (data: any) => {
    dispatch(createOrderActions.setSelectedModel(data.id))
    history.push(Routes.CreateOrderAI(AIShoppingStep.ARQUIVOS))
  }

  return <ModelQuestionsForm onSubmit={onSubmit} />
}
