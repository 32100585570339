import React from 'react'
import { Form, FormItemProps, Checkbox } from 'antd'

import { requiredRule } from 'app/config/validationRules'
import { getFieldName } from 'domain/createOrder/shared'
import { AllExtraInfos } from 'domain/orderItem/types'
import { FieldConfig } from 'domain/servicesCBRdoc/fields/types'

export interface CheckboxGroupFieldProps extends Omit<FormItemProps, 'label'> {
  field: FieldConfig
  options?: any
  itemId?: number
  required?: boolean
  autoFocus?: boolean
  value?: any
  onChange?: (selected: string) => void
  hasError?: boolean
  extraInfos?: AllExtraInfos
}

export default function CheckboxGroupField({
  field,
  itemId,
  options = field.options,
  required = !field.optional,
  autoFocus,
  onChange,
  value,
  hasError,
  help,
  extraInfos,
  ...props
}: CheckboxGroupFieldProps) {
  const name = getFieldName(field.name, itemId)  
  const handleChange = onChange ? (checkedValues: any) => onChange(checkedValues) : undefined
  const checkboxes = options ?? extraInfos?.[field.apiExtraInfo!]

  if (!checkboxes) return null

  return (
    <Form.Item
      name={name}
      label={required ? field.label : `${field.label} (opcional)`}
      rules={required ? requiredRule : undefined}
      help={hasError ? 'Erro ao carregar os dados.' : help}
      {...props}
    >
      <Checkbox.Group value={value} onChange={handleChange} data-hj-allow>
        {checkboxes.map((option: any, index: number) => {
          const value = option[field.apiOptionsValueKey ?? 'url']
          const label = option[field.apiOptionsLabelKey ?? 'nome']
          const description = option.description

          return (
            <React.Fragment key={value}>
              <Checkbox className="mb-2" value={value} autoFocus={autoFocus && index === 0} data-hj-allow>
                {description ? <b>{label}</b> : label}
                {description && <p className="mb-1 text-sm text-gray-500">{description}</p>}
              </Checkbox>
              <br />
            </React.Fragment>
          )
        })}
      </Checkbox.Group>
    </Form.Item>
  )
}
