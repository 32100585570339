import { useGetServiceForm } from 'domain/createOrder/kit'
import { KitItem } from 'domain/kits/types'
import { ExtraInfoRadioButtonGroup, ExtraInfoRadioGroup } from 'domain/servicesCBRdoc/fields'
import { getLocationUrl } from 'domain/servicesCBRdoc/helpers/location'

import config from '../certidaoEmpresaCartoriosPJConfig'

interface Props {
  kitItem: KitItem
}

export default function KitOrderCertidaoEmpresaCartoriosPJ({ kitItem }: Props) {
  const { id, service } = kitItem
  const { tipo_documento, url_cartorio, url_cidade, url_uf } = useGetServiceForm(id)
  const location = getLocationUrl({ url_uf, url_cidade, url_cartorio })

  if (!url_cartorio) return null

  return (
    <>
      <ExtraInfoRadioGroup
        service={service}
        field={docType}
        itemId={id}
        params={location}
        vertical
      />

      {tipo_documento && (
        <ExtraInfoRadioButtonGroup
          itemId={id}
          service={service}
          params={{ ...location, tipo_documento }}
          field={notaryType}
        />
      )}
    </>
  )
}

const { tipo_certidao: notaryType, tipo_documento: docType } = config.formFields
