import { useCheckoutStep } from 'domain/checkout/state'
import { useIsCustomerPostPaid } from 'domain/customers'

import CheckoutOrderDetailsModal from './CheckoutOrderDetailsModal'
import CheckoutPaymentModal from './CheckoutPaymentModal'
import SelectShippingAddressModal from './SelectShippingAddressModal'

export interface CheckoutProps {
  paymentValue: number
}

export default function Checkout({ paymentValue }: CheckoutProps) {
  const isPostPaid = useIsCustomerPostPaid()
  const [checkoutStep, setStep] = useCheckoutStep()

  if (isPostPaid === undefined) {
    return null
  }

  return (
    <>
      <CheckoutOrderDetailsModal />
      <SelectShippingAddressModal open={checkoutStep === 'address'} onClose={() => setStep('idle')} />
      {!isPostPaid && <CheckoutPaymentModal paymentValue={paymentValue} />}
    </>
  )
}
