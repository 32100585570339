import { Form } from 'antd'
import { useReducer } from 'react'

import { DocumentServicesSelect, OrdersOwnerFilter, OrdersStatusFilter } from 'domain/servicesCBRdoc/fields'
import { Button, ClearFiltersButton, Modal, SubmitButton, TextInput, DatePickerField } from 'ui'
import { formatDate } from 'utils/dateTime'
import { initialFilters, useFilters } from '../../state/filters'

export default function FiltersModal() {
  const [form] = Form.useForm()
  const [open, toggleOpen] = useReducer(prev => !prev, false)
  const [filters, setFilters] = useFilters()

  const onFinish = (values: any) => {
    setFilters({
      ...values,
      dateRange: values.orderDate
        ? `${formatDate(values.orderDate)},${formatDate(values.orderDate)}`
        : filters.dateRange,
    })
    form.resetFields()
    toggleOpen()
  }

  return (
    <>
      <Button type="primary" ghost size="middle" icon="filter" className="ml-2" onClick={toggleOpen}>
        Filtros
      </Button>

      <Modal
        open={open}
        title="Filtros"
        closable
        onCancel={() => {
          form.resetFields()
          toggleOpen()
        }}
        extra={
          <ClearFiltersButton
            onClick={() => {
              setFilters({
                ...initialFilters,
                dateRange: filters.dateRange,
              })
              form.setFieldsValue({
                ...initialFilters,
                dateRange: filters.dateRange,
              })
              toggleOpen()
            }}
          />
        }
        destroyOnClose
      >
        <Form form={form} layout="vertical" onFinish={onFinish} initialValues={filters}>
          <TextInput
            label="Nome do pedido"
            name="searchTerm"
            placeholder="Informe um nome ou ID"
            size="middle"
            hideOptional
          />
          <DatePickerField name="orderDate" label="Data do pedido" size="middle" />
          <DocumentServicesSelect />
          <OrdersStatusFilter />
          <OrdersOwnerFilter />
          <SubmitButton label="Aplicar filtros" />
        </Form>
      </Modal>
    </>
  )
}
