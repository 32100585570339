import { useMemo } from 'react'

import { useGetLatestView } from 'domain/notifications/state'
import { LatestNotification } from 'domain/notifications/types'
import { Badge, Icon } from 'ui'
import { isAfter, stringToDate } from 'utils/dateTime'

interface Props {
  notifications: LatestNotification[] | undefined
}

export default function TopBarNotificationsBadge({ notifications }: Props) {
  const latestView = useGetLatestView()

  const counter = useMemo(() => {
    if (!notifications) {
      return 0
    }

    const viewedAt = stringToDate(latestView)

    if (!viewedAt) {
      return notifications.length
    }

    return notifications.filter(o => isAfter(o.createdAt, viewedAt)).length
  }, [latestView, notifications])

  return (
    <Badge color="red" title="Notificações" dot counter={counter}>
      <Icon name="bell" size={24} className="text-secondary-500" />
    </Badge>
  )
}
