import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Kit, KitFilters, KitItem } from 'domain/kits/types'
import { getStorageData, setStorageData } from 'utils/localStorage'

import { useAppState } from '../store'

type KitsSliceType = {
  filters: KitFilters
  editKit: Kit | undefined
  viewKit: Kit | undefined
}

const VIEW_KIT_PERSISTED = 'viewKit'

export const initialState: KitsSliceType = {
  filters: {} as KitFilters,
  editKit: undefined,
  viewKit: getStorageData(VIEW_KIT_PERSISTED, undefined),
}

const kitsStateSlice = createSlice({
  name: 'kitsState',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<KitFilters>) => {
      state.filters = action.payload
    },
    setEditKit: (state, action: PayloadAction<Kit | undefined>) => {
      state.editKit = action.payload
    },
    setEditKitItems: (state, action: PayloadAction<KitItem[]>) => {
      state.editKit!.items = action.payload
    },
    setEditKitName: (state, action: PayloadAction<string>) => {
      state.editKit!.name = action.payload
    },
    setViewtKit: (state, action: PayloadAction<Kit>) => {
      state.viewKit = action.payload
      setStorageData(VIEW_KIT_PERSISTED, action.payload)
    },
  },
})

export const kitsStateActions = kitsStateSlice.actions
export const useKitsState = () => useAppState(state => state.kits)

export default kitsStateSlice.reducer
