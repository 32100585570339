import React, { useLayoutEffect, useState } from 'react'
import { Collapse, Form } from 'antd'

import { AddFormItemButton } from 'ui'

import ModelQuestionsPanel from './ModelQuestionsPanel'

interface Props {
  totalCharacters: number
  initialActiveItem?: number
}

function ModelQuestionsCollapse({ totalCharacters, initialActiveItem = 0 }: Props) {
  const [activeItem, setActiveItem] = useState(initialActiveItem)

  useLayoutEffect(() => {
    const name = `questions_${activeItem}_label`
    const elements = document.getElementById(name)

    elements?.focus()
  }, [activeItem])

  return (
    <Collapse
      expandIconPosition="start"
      className="spaced-collapse"
      activeKey={activeItem}
      onChange={setActiveItem as any}
    >
      <QuestionsFields totalCharacters={totalCharacters} />
    </Collapse>
  )
}

function QuestionsFields({ totalCharacters, ...props }: Props) {
  return (
    <Form.List name="questions">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field, index) => (
              <ModelQuestionsPanel
                key={field.key}
                field={field}
                deletable={fields.length > 1}
                counter={index}
                totalCharacters={totalCharacters}
                remove={remove}
                {...props}
              />
            ))}

            <AddFormItemButton label="Adicionar outro campo" onSuccess={() => add()} />
          </>
        )
      }}
    </Form.List>
  )
}

export default ModelQuestionsCollapse
