import { lazy, Suspense, useMemo } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { Tabs } from 'antd'

import { useHasPermission } from 'domain/auth'
import { PageHeader } from 'layouts'
import { Loading } from 'ui'

import Profile from './ProfilePage'
import Groups from './SettingsGroupsPage'
const Documents = lazy(() => import('./SettingsDocumentsPage'))
const NotificationSettings = lazy(() => import('domain/notifications/components/settings/NotificationSettings'))
const Users = lazy(() => import('./users/UsersPage'))

export default function SettingsPage() {
  const isAdmin = useHasPermission('canManageUsersAndCompanyData')
  const history = useHistory()
  const location = useLocation()
  const changeRoute = (route: string) => history.push(route)

  const defaultActiveKey = location.pathname.startsWith('/configuracoes/recorrencias')
    ? '/configuracoes/recorrencias'
    : location.pathname

  const tabItems = useMemo(() => {
    const notifications = {
      label: 'Notificações',
      key: '/configuracoes/notificacoes',
    }

    let items: any = []

    if (isAdmin) {
      items = [
        {
          label: 'Dados Principais',
          key: '/configuracoes',
        },
        {
          label: 'Usuários',
          key: '/configuracoes/usuarios',
        },
        notifications,
        {
          label: 'Grupos',
          key: '/configuracoes/grupos',
        },
        {
          label: 'Documentos',
          key: '/configuracoes/documentos',
        },
      ]
    } else {
      items = [notifications]
    }

    return items
  }, [isAdmin])

  return (
    <>
      <PageHeader title="Configurações" />

      <div className="px-7 pt-3 bg-gray-100">
        <Tabs defaultActiveKey={defaultActiveKey} onChange={changeRoute} items={tabItems} />
      </div>

      <div className="flex flex-col flex-1 p-7 bg-gray-100">
        <Suspense fallback={<Loading />}>
          {isAdmin ? (
            <Switch>
              <Route component={Profile} exact path="/configuracoes" />
              <Route component={Users} path="/configuracoes/usuarios" />
              <Route component={NotificationSettings} path="/configuracoes/notificacoes" />
              <Route component={Groups} path="/configuracoes/grupos" />
              <Route component={Documents} path="/configuracoes/documentos" />
            </Switch>
          ) : (
            <Switch>
              <Route component={NotificationSettings} path="/configuracoes" />
            </Switch>
          )}
        </Suspense>
      </div>
    </>
  )
}
