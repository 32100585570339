import { RoutePaths } from 'app/routes'
import { Icon } from 'ui'

export default function TopBarHelp() {
  return (
    <a className="relative" href={RoutePaths.HELP_EXTERNAL} title="Ajuda" target="_blank" rel="noopener noreferrer">
      <Icon name="help-circle" className="text-secondary-500" size={24} />
    </a>
  )
}
