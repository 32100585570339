import React from 'react'
import { Tabs } from 'antd'
import { TabsProps } from 'antd/lib/tabs'
import 'react-credit-cards.css'

import { useCurrentCustomer } from 'domain/customers/'
import { Modal } from 'ui'
import { formatMoney } from 'utils/formatters'

import { ChosenPaymentMethod, PaymentMethod } from '../types'
import CreditCardForm from './CreditCardForm'
import Invoice from './Invoice'
import Pix from './Pix'

interface Props {
  open?: boolean
  onRequestClose: () => void
  paymentValue: number
  purchaseTotal?: number | null
  onPaymentOk?: (method: ChosenPaymentMethod) => void
  isAddingCredits?: boolean
}

const PaymentModal = ({
  open,
  paymentValue,
  onRequestClose,
  onPaymentOk,
  isAddingCredits,
}: Props) => {
  const customer = useCurrentCustomer()

  const renderTabBar = (props: TabsProps, DefaultTabBar: React.ComponentType<any>) => {
    return <DefaultTabBar {...props} className="tabs-as-button" />
  }

  const tabItems = [
    {
      label: 'Cartão de Crédito',
      key: PaymentMethod.CREDIT_CARD,
      children: (
        <CreditCardForm
          paymentValue={paymentValue}
          companyName={customer.companyName}
          onSuccess={onPaymentOk}
          isAddingCredits={isAddingCredits}
          onRequestClose={onRequestClose}
        />
      ),
    },
    {
      label: 'Boleto',
      key: PaymentMethod.INVOICE,
      children: (
        <Invoice paymentValue={paymentValue} onSuccess={onPaymentOk} isAddingCredits={isAddingCredits} />
      ),
    },
    {
      label: 'PIX',
      key: PaymentMethod.PIX,
      children: <Pix paymentValue={paymentValue} onSuccess={onPaymentOk} isAddingCredits={isAddingCredits} />,
    },
  ]

  return (
    <Modal open={open} title="Pagamento" onCancel={onRequestClose}>
      <div className="flex justify-between pb-5 mb-5 border-b border-gray-300">
        <span className="text-gray-600">Valor a pagar:</span>
        <span className="text-base text-bold text-green-500">{formatMoney(paymentValue)}</span>
      </div>

      <p className="mb-3">
        Escolha a melhor forma de pagamento {isAddingCredits ? 'dos seus créditos' : 'do seu pedido'}:
      </p>

      <Tabs defaultActiveKey={PaymentMethod.CREDIT_CARD} renderTabBar={renderTabBar} items={tabItems} />
    </Modal>
  )
}

export default PaymentModal
