import { Tag } from 'antd'
import { ReactNode } from 'react'

interface OrderDetailsInfoTagProps {
  children: ReactNode
}

const OrderDetailsInfoTag = ({ children }: OrderDetailsInfoTagProps) => (
  <Tag className="text-secondary-300 border-secondary-300 m-0">{children}</Tag>
)

export default OrderDetailsInfoTag
