import React from 'react'

import { useToggleNotificationRead } from 'domain/notifications/services'
import Icon from 'ui/Icon/Icon'

function NotificationClose({ notificationId, readAt }: { notificationId: number; readAt: Date | null }) {
  const maskAsRead = useToggleNotificationRead()

  const onClick = (e: any) => {
    e.stopPropagation()
    maskAsRead({ id: notificationId, readAt })
  }

  return (
    <div role="button" title="Fechar" onClick={onClick}>
      <Icon name="close" size={20} className="cursor text-gray-500 hover:text-primary" />
    </div>
  )
}

export default NotificationClose
