import { getFieldName } from 'domain/createOrder/shared'
import React from 'react'
import { onlyNumbers } from 'utils/formatters'

import MaskedInput, { MaskedInputProps } from './MaskedInput'

interface Props extends Omit<MaskedInputProps, 'mask' | 'maskOptions' | 'type'> {
  itemId?: number | string
  label?: string
  defaultValue?: string
  value?: string
}

function PhoneInput({ itemId, name, label = 'Telefone', ...props }: Props) {
  return (
    <MaskedInput
      name={getFieldName(name, itemId)}
      type="tel"
      label={label}
      mask={masks}
      maskOptions={maskOptions}
      rules={[
        { required: props.required, message: 'Campo obrigatório' },
        {
          validator: async (_: any, value: string) => {
            const valueWithoutMask = onlyNumbers(value)
            if (!valueWithoutMask) {
              return Promise.reject('Campo obrigatório')
            }

            return Promise.resolve()
          },
          
        },
      ]}
      {...props}
    />
  )
}

const masks = [
  {
    mask: '(00) 0 0000-0000',
    lazy: false,
  },
  {
    mask: '(00) 0000-0000',
    lazy: false,
  },
]

const maskOptions = {
  dispatch: function (_: any, dynamicMasked: any) {
    const digit = dynamicMasked.unmaskedValue[2]
    const isCellPhone = digit === '9' || digit === '8'
    return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1]
  },
}

export default PhoneInput
