import { message } from 'antd'
import { APP_VERSION } from 'app/constants'
import { CreateOrder } from 'domain/createOrder/shared/services'
import { CreateOrderForm } from 'domain/createOrder/types'
import { fetchPurchaseableOrderItem } from 'domain/orderItem'
import { OrderItemPurchasable } from 'domain/orderItem/types'
import { Fields } from 'domain/servicesCBRdoc'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { displayDate } from 'utils/dateTime'

class RedoOrder extends CreateOrder {
  private mustFetchOrderItem
  private orderItem: OrderItemPurchasable

  constructor(orderItem: OrderItemPurchasable, mustFetchOrderItem = false) {
    super(orderItem.service)
    this.orderItem = orderItem
    this.mustFetchOrderItem = mustFetchOrderItem
  }

  private generateFormData = (initialState: CreateOrderForm) => {
    const formData: CreateOrderForm = { ...initialState }

    const entries = Object.entries(formData).map(([name, value]: any) => {
      const field = Fields[name as FieldName]

      if (field) {
        if (field.kind === 'date') {
          if (!value.includes('/')) {
            value = displayDate(value)
          }
        }
      }

      return [name, value]
    })

    return Object.fromEntries(entries)
  }

  generate = async (isPostPaid: boolean) => {
    const content = this.mustFetchOrderItem ? 'Recuperando dados do item...' : 'Gerando o pedido...'
    const hide = message.loading({ content, key })

    try {
      if (this.mustFetchOrderItem) {
        this.orderItem = await fetchPurchaseableOrderItem(this.orderItem.id, isPostPaid)
        this.selectedService = this.orderItem.service
      }

      const formData = this.generateFormData({
        ...this.orderItem.submitData,
        ...this.orderItem.locationInfo,
        [NOME_ITEM_PEDIDO]: this.orderItem.name,
      })

      const createOrder = this.generateCreateOrder(formData, { description: `redo v${APP_VERSION}` })

      message.success({ content: 'Consultando o preço...', key })
      const ordersWithPrice = await CreateOrder.appendPrice([createOrder], isPostPaid!)
      return ordersWithPrice?.[0]
    } catch (error: any) {
      console.error('error', error)
    } finally {
      hide()
    }
  }
}

const key = 'updatable'
const { NOME_ITEM_PEDIDO } = FieldName

export default RedoOrder
