import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { AICreateOrderModelAPI } from 'domain/openAI/typesAPI'
import { convertCreateOrderModel } from './convertModel'

export default function useAllModelsQuery(options: any = {}) {
  return useQuery(['ai-models', 'list'], async () => {
    const response = await AuthorizedAPI.get<AICreateOrderModelAPI[]>('/ai-models', {
      params:  {
        sortBy: 'name',
        'filter[visibility]': 'all',
      },
    })

    return response.data.map(convertCreateOrderModel)
  }, options)
}
