import React from 'react'

import { useCurrentUser } from 'domain/auth'
import { useOwnersIds } from 'domain/orders/state/filters'
import { SelectableButton } from 'ui'

function FilterByOwnership() {
  const user = useCurrentUser()
  const [ownersIds, setOwnerIds] = useOwnersIds()
  const isSelected = ownersIds.includes(user.id)

  return (
    <SelectableButton
      selected={isSelected}
      onClick={() => {
        const updated = isSelected
          ? ownersIds.filter((id: number) => id !== user.id)
          : [...ownersIds, user.id]
        setOwnerIds(updated)
      }}
    >
      Meus Pedidos
    </SelectableButton>
  )
}

export default FilterByOwnership
