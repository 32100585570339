import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoNegativaDebitosTributariosPGEFields = Record<
  | FieldName.CNPJ
  | FieldName.CPF
  | FieldName.INSCRICAO_ESTADUAL
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.URL_UF,
  FieldConfig
>

const { URL_UF } = FieldName

class CertidaoNegativaDebitosTributariosPGE extends Certificate {
  private UFS_WITH_IE_REQUIRED = ['AC', 'AL', 'CE', 'DF']
  formFields: CertidaoNegativaDebitosTributariosPGEFields = {
    url_uf: Fields.url_uf,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    cnpj: Fields.cnpj,
    nome_pj: Fields.nome_pj,
    inscricao_estadual: {
      ...Fields.inscricao_estadual,
      ignoreOnKitsDetailsForm: true,
      showIf: (formData) => {
        const selectedUf = formData?.url_uf && typeof formData.url_uf === 'string' ? formData.url_uf : formData.url_uf?.value
        if (selectedUf && this.UFS_WITH_IE_REQUIRED.includes(selectedUf)) {
          return true
        }
        return false
      }
    },
  }

  constructor() {
    super({
      id: 46,
      priceByFields: [URL_UF],
      shortName: 'PGE - Débitos Tributários',
      steps: ['dados'],
    })
  }
}

const service = new CertidaoNegativaDebitosTributariosPGE().initialize()

export default service
