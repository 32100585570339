import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaPropriedadeAeronaveFields = Record<
  FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ,
  FieldConfig
>

class PesquisaPropriedadeAeronave extends Research {
  formFields: PesquisaPropriedadeAeronaveFields = {
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    cnpj: Fields.cnpj,
    nome_pj: Fields.nome_pj,
  }

  constructor() {
    super({
      description: 'Retorna se um CPF ou CNPJ possui uma aeronave registrada.',
      id: 60,
      shortName: 'Pesquisa Propriedade Aeronave',
    })
  }
}

const service = new PesquisaPropriedadeAeronave().initialize()

export default service
