import { useEffect } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import { useIsHydratedFromURL } from 'domain/app'
import { useSetDates, useSetOwner, useSetSearch } from './filters'
import { useSetCurrentPage } from './pagination'

export default function useSetInitialValuesFromURL() {
  const history = useHistory()
  const location = history.location
  const setCurrentPage = useSetCurrentPage()
  const setSearch = useSetSearch()
  const setDates = useSetDates()
  const setOwner = useSetOwner()
  const [isHydrated, setIsHydrated] = useIsHydratedFromURL()

  useEffect(() => {
    if (!isHydrated) {
      if (location.search) {
        const { dates, owner, p, q } = queryString.parse(location.search)
  
        if (p) {
          setCurrentPage(Number(p))
        }
  
        if (q) {
          setSearch(q as string)
        }
  
        if (dates) {
          setDates(dates as string)
        }
  
        if (owner) {
          setOwner(Number(owner))
        }
      }

      setIsHydrated(true)
    }
  }, [])
}
