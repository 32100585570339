import { ServiceLink, ServiceLinkProps, useSelectServiceClick } from 'domain/createOrder/shared'
import { getServiceConfig } from 'domain/servicesCBRdoc'

interface Props extends Omit<ServiceLinkProps, 'onClick'> {
  categoryId?: number
}

export default function DocumentLink({ service, categoryId, children, bold }: Props) {
  const serviceConfig = getServiceConfig(service.code)
  const onClick = useSelectServiceClick(service, categoryId, serviceConfig?.startFromStep)

  return (
    <ServiceLink
      service={service}
      bold={bold}
      onClick={onClick}
      isNew={serviceConfig?.displayIsNewTag}
      isUnavailable={!serviceConfig || serviceConfig?.isUnavailable}
    >
      {children}
    </ServiceLink>
  )
}
