import { useDispatch } from 'react-redux'

import { LabelInValue } from 'domain/app/types'
import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document'
import { ResearchFlow } from 'domain/createOrder/researches'
import {
  DateField,
  NameField,
  RadioGroupButtonsField,
  TextField,
  useSelectDefaultValue,
} from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../pesquisaObitoConfig'

export default function CreateOrderPesquisaObito() {
  return <ResearchFlow renderFormItem={itemId => <PesquisaObitoForm itemId={itemId} />} />
}

function PesquisaObitoForm({ itemId }: { itemId: number }) {
  const { formItems } = useCreateOrderState()

  return (
    <>
      <TypeRadio itemId={itemId} />
      <CPFInput itemId={itemId} />
      <NameField
        itemId={itemId}
        field={nome_pf}
        required={formItems[itemId]?.selectedType === 'completa'}
      />
      <DateField itemId={itemId} field={obito} />
      <TextField itemId={itemId} field={mae} />
      <TextField itemId={itemId} field={pai} />
    </>
  )
}

function TypeRadio({ itemId }: { itemId: number }) {
  const dispatch = useDispatch()

  const onChange = (value: string) => {
    dispatch(createOrderActions.setMultipleItemsType({ id: itemId, value }))
  }

  useSelectDefaultValue({
    field: tipo,
    name: [itemId, tipo.name],
    onChange,
    availableOptions: tipo.options as LabelInValue[],
  })

  return (
    <RadioGroupButtonsField field={tipo} itemId={itemId} onChange={onChange} autoFocus={itemId === 0} />
  )
}

const { nome_pf, obito, tipo, mae, pai } = config.formFields
