import React from 'react'
import { Form, Tag } from 'antd'

import Select, { SelectProps } from './Select'

interface Props extends SelectProps {
  name: string
  label: string
}

function SelectMultiple({ name, label, ...props }: Props) {
  return (
    <Form.Item name={name} label={label}>
      <Select mode="multiple" tagRender={CustomTag} {...props} />
    </Form.Item>
  )
}

function CustomTag({ label, onClose }: any) {
  return (
    <Tag color="#507be0" onClose={onClose} style={tagStyle} closable={true}>
      {label}
    </Tag>
  )
}

const tagStyle = { borderRadius: 2, opacity: 0.6, display: 'flex', alignItems: 'center' }

export default SelectMultiple
