import { Form, Radio, RadioGroupProps } from 'antd'
import { NamePath } from 'antd/lib/form/interface'

import { requiredRule } from 'app/config/validationRules'

export interface RadioGroupInputProps extends Omit<RadioGroupProps, 'buttonStyle' | 'name' | 'onChange'> {
  label?: string | React.ReactNode
  name?: NamePath
  required?: boolean
  onChange?: (selected: string) => void
}

export default function RadioGroupInput({
  name,
  label,
  required,
  onChange,
  optionType = 'button',
  ...props
}: RadioGroupInputProps) {
  return (
    <Form.Item name={name} label={label} rules={required ? requiredRule : undefined}>
      <Radio.Group
        optionType={optionType}
        buttonStyle="solid"
        onChange={e => onChange && onChange(e.target.value)}
        {...props}
      />
    </Form.Item>
  )
}
