import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI as API } from 'api/network/v2'

import { ExplorerItemAPI } from '../typesAPI'
import QueryKeys from './MyFilesQueryKeys'
import { convertExplorerItem } from './convertExplorer'

export default function useFileQuery(fileId: number | undefined, options: any = {}) {
  return useQuery(
    [...QueryKeys.MyFilesItem, fileId],
    async () => {
      const response: AxiosResponse<ExplorerItemAPI> = await API.get(`/explorer/${fileId}`, {
        params: {
          append: 'ai_data,file,breadcrumb,ocr.ai_enrich_data_available',
          include: [
            'order',
            'children',
            'parent',
            'groups',
            'owner',
            'ocr',
            'ocr.pages',
            'order.service',
          ].join(','),
        },
      })

      if (response.data) {
        return convertExplorerItem(response.data)
      }

      return {}
    },
    {
      enabled: !!fileId,
      ...options,
    }
  )
}
