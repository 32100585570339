import { Modal, message } from 'antd'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

import { errorHandler } from 'api/errors'
import { API } from 'api/network/v2'
import queryClient from 'app/config/queryClient'

import { Group } from '../types'

export default function useConfirmAndDeleteGroup(onSuccess?: (groupId: number) => void) {
  const mutation = useMutation(async (groupId: number) => await deleteGroupAPI(groupId), {
    onSuccess: (response, groupId) => {
      const filterDeletedGroupCallback = (old: any) => (
        old?.filter((o: Group) => o.id !== groupId)
      )

      queryClient.setQueryData(['groups', 'all'], filterDeletedGroupCallback)
      queryClient.setQueryData(['groups', 'list'], filterDeletedGroupCallback)

      message.success('O grupo foi excluído.')
      onSuccess && onSuccess(groupId)
    },
    onError: error => {
      errorHandler(error, { code: '0x808' })
    },
  })

  const confirmAndDelete = async (group: Group) => {
    Modal.confirm({
      title: 'Exclusão de grupo',
      content: (
        <>
          Tem certeza que deseja excluir o grupo <b>{group.name}</b>?
        </>
      ),
      okText: 'Excluir',
      cancelText: 'Cancelar',
      onOk: () => mutation.mutate(group.id),
    })
  }

  return confirmAndDelete
}

export const deleteGroupAPI = async (id: number) => {
  const { data }: AxiosResponse<any> = await API.delete(`/groups/${id}`)
  return data
}
