import React, { useState } from 'react'
import { Modal as AntModal, Button, Radio } from 'antd'

import queryClient from 'app/config/queryClient'
import { useSetCheckoutStep, useSetShippingAddress } from 'domain/checkout/state'
import { ShippingAddress } from 'domain/checkout/types'
import { useCurrentCustomer } from 'domain/customers'
import { Modal, RadioCard } from 'ui'

import { extractCustomerAddress } from '../helpers'
import AddShippingAddress from './AddShippingAddress'

interface Props {
  open: boolean
  onSuccess?: () => void
  onClose: () => void
}

export default function SelectShippingAddressModal({ open, onSuccess, onClose }: Props) {
  const [addNewAddressModalVisible, setAddNewAddressModalVisible] = useState(false)
  const [selectedAddressId, setSelectedAddressId] = useState<string>('main')
  const customer = useCurrentCustomer()
  const setShippingAddress = useSetShippingAddress()
  const setCheckoutStep = useSetCheckoutStep()

  const [addressList, setAddressList] = useState<ShippingAddress[]>(
    customer.addressZipcode ? [extractCustomerAddress(customer!)!] : []
  )

  const hasAddress = addressList.length > 0

  const handleSubmit = () => {
    if (selectedAddressId) {
      const selectedAddress = addressList.find(a => a.id === selectedAddressId)

      if (selectedAddress) {
        setShippingAddress(selectedAddress)
        setCheckoutStep('in-progress')

        queryClient.invalidateQueries(['customer'])
        onSuccess && onSuccess()
      } else {
        AntModal.error({ title: 'Erro', content: 'Erro ao selecionar o endereço' })
      }
    } else {
      AntModal.error({ title: 'Erro', content: 'Selecione um endereço' })
    }
  }

  const onAddShippingAddress = (address: ShippingAddress) => {
    setAddressList(prev => prev.concat(address))
    setSelectedAddressId(address.id)
    setAddNewAddressModalVisible(false)
  }

  return (
    <Modal open={open} title="Endereço de entrega" onCancel={onClose} destroyOnClose>
      <h2 className="py-5 pt-0 pb-2 text-gray-600 text-base text-secondary-500">
        Em qual endereço deseja receber os documentos?
      </h2>

      {hasAddress && (
        <Radio.Group
          className="w-full"
          value={selectedAddressId}
          onChange={e => setSelectedAddressId(e.target.value)}
        >
          {addressList.map(address => (
            <RadioCard key={address.id} value={address.id}>
              <b>{address.name}</b>
              <div>
                {address.address_public_place}, {address.address_number}, {address.address_neighborhood}
                <br />
                {address.address_zip_code} <br />
                {address.address_city} - {address.address_uf}
              </div>
            </RadioCard>
          ))}
        </Radio.Group>
      )}

      <AddShippingAddress
        buttonLabel={hasAddress ? 'enviar para outro endereço' : 'Cadastrar endereço'}
        isMainAddress={!hasAddress}
        open={addNewAddressModalVisible}
        onSubmit={onAddShippingAddress}
      />

      <hr className="mt-3 border-b border-gray-300" />

      <Button type="primary" onClick={handleSubmit} disabled={!hasAddress} block className="mt-3">
        Confirmar
      </Button>
    </Modal>
  )
}
