import { Popover } from 'antd'

import Icon from 'ui/Icon/Icon'

interface Props {
  title?: string
  content: React.ReactNode
  color?: string
  className?: string
}

export default function HelpPopover({ content, title, color = 'currentColor', className = 'relative -mb-1' }: Props) {
  return (
    <Popover
      content={<div className="max-w-xs">{content}</div>}
      title={title && <h3 className="font-bold text-secondary-500 py-2">{title}</h3>}
      trigger="hover"
    >
      <span>
        <Icon name="help-circle" color={color} className={className} />
      </span>
    </Popover>
  )
}
