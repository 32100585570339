import { getPriceShippingInfo } from 'domain/createOrder/shared/api'
import { CreateOrderState } from 'domain/createOrder/types'
import { getService } from 'domain/servicesCBRdoc'
import { checkIfObjectHasAllValues } from 'utils/validators'

async function getPrice(createOrder: CreateOrderState) {
  const code = createOrder.selectedService.code
  const serviceConfig = getService(code!)
  const priceParams = serviceConfig.getPriceParams(createOrder)

  if (!checkIfObjectHasAllValues(priceParams)) {
    throw new Error(`Falta de parâmetros para consultar o preço de ${code}.`)
  }

  const price = await getPriceShippingInfo({
    origin: createOrder.origin.description,
    service: createOrder.selectedService,
    params: priceParams,
    extras: createOrder.selectedExtras,
  })

  if (price) return price

  throw new Error(`Falha na consulta do preço de ${code}.`)
}

export default getPrice
