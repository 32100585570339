import React, { useState } from 'react'
import { Button } from 'antd'

import { errorHandler } from 'api/errors'
import { useBuyCreditsWithPix } from 'domain/credits'
import { Alert } from 'ui'

import { ChosenPaymentMethod, PaymentMethod } from '../types'

interface Props {
  paymentValue: number
  isAddingCredits?: boolean
  onSuccess: (pix?: ChosenPaymentMethod) => void
}

function PixForm({ paymentValue, onSuccess, isAddingCredits = false }: Props) {
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const buyCreditsWithPix = useBuyCreditsWithPix()

  const onSubmitPIX = async () => {
    // Na compra de créditos, o pedido é fechado na hora e exibido o QRCode na mesma tela  
    if (isAddingCredits) {
      setShowError(false)
      setLoading(true)

      try {
        const pix = await buyCreditsWithPix(paymentValue)
        onSuccess(pix)
      } catch (error) {
        setLoading(false)
        setShowError(true)
        errorHandler(error, { code: '0x492' })
      }
    }     
    // Na compra de um pedido, o pedido é primeiro finalizado e o QRCode é mostrado na tela de sucesso
    else {
      onSuccess({ chosenMethod: PaymentMethod.PIX })
    }
  }

  return (
    <div className="flex-1">
      {showError && (
        <Alert
          type="error"
          message="Algo deu errado ao gerar seu QRCode, aguarde um momento e tente novamente!"
          className="my-4 mb-7"
        />
      )}

      <Button type="primary" size="large" onClick={onSubmitPIX} loading={loading} block>
        Gerar PIX para pagamento
      </Button>
    </div>
  )
}

export default PixForm
