import React from 'react'

import { useAllModelsQuery } from 'domain/openAI/models'
import { SelectField } from 'ui'

interface Props {
  onChange: React.Dispatch<React.SetStateAction<number | undefined>>
}

export default function AIExportToExcelModelSelect({ onChange }: Props) {
  const { data, isLoading } = useAllModelsQuery({
    onSuccess: (res: any) => {
      return [...res, { id: 0, modelName: 'Todos' }]
    }
  })

  return (
    <SelectField
      label="Modelo"
      name="model"
      placeholder="Selecione o modelo"
      options={data}
      optionLabelKey="modelName"
      optionValueKey="id"
      onChange={onChange}
      loading={isLoading}
    />
  )
}
