import { useState } from 'react'

import { AuthorizedAPI } from 'api/network/v2'
import { errorHandler } from 'api/errors'
import { useGetPeriodFilter } from 'domain/reports/filters'
import { Button } from 'ui'
import { resolveFileName, saveAs } from 'utils/export'

interface Props {
  modelId?: number
}

export default function AIExportToExcelButton({ modelId }: Props) {
  const [loading, setLoading] = useState(false)
  const period = useGetPeriodFilter()

  const onClick = async () => {
    try {
      setLoading(true)

      const dates = period.split(',')
      const params: any = {
        begin_date: dates[0],
        end_date: dates[1],
        type: modelId ? 'ai-model-period' : 'period',
      }

      if (modelId) {
        params.ai_model_id = modelId
      }

      const response = await AuthorizedAPI.get('/reports/ai-answers/xlsx', {
        responseType: 'blob',
        params,
      })

      const { data } = response
      const fileName = resolveFileName(response.headers, 'cbrdoc_ia_relatorio_de_uso.xlsx')

      saveAs(data, fileName)
    } catch (error) {
      errorHandler(error, { code: '0x412' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button type="primary" icon="file-xls" ghost loading={loading} onClick={onClick}>
      Exportar para Excel
    </Button>
  )
}
