import config from '../pesquisaBensConfig'
import { DateField, RadioGroupField } from 'domain/servicesCBRdoc/fields'

interface Props {
  itemId: number
}

export default function PreferenceField({ itemId }: Props) {
  return (
    <>
      <p className="font-normal text-gray-600 mb-2">
        Defina aqui como prefere o retorno de sua pesquisa. Caso esteja disponível para o cartório buscado, o
        resultado seguirá suas preferências.
      </p>

      <RadioGroupField field={preferenceField} itemId={itemId} vertical />
      <DateField field={data_base} itemId={itemId} />
    </>
  )
}

const { data_base, preferencia } = config.formFields
const preferenceField = { ...preferencia, label: '' }
