import React, { useReducer } from 'react'

import { useCreateOrderState } from 'domain/createOrder/document/state/createOrderSlice'
import { AddBySpreadsheetButton } from 'ui'

import SelectServiceByCodeInURL from './SelectServiceByCodeInURL'
import SpreadsheetModal from './CreateOrderBySpreadsheetModal'

function CreateOrderBySpreadsheet() {
  const [open, toggleOpen] = useReducer(prev => !prev, false)
  const { selectedService } = useCreateOrderState()

  if (!selectedService.code) {
    return <SelectServiceByCodeInURL />
  }

  return (
    <>
      <AddBySpreadsheetButton onClick={toggleOpen} />
      <SpreadsheetModal open={open} toggleOpen={toggleOpen} />
    </>
  )
}

export default CreateOrderBySpreadsheet
