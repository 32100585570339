import React from 'react'
import { Button, Drawer as AntDrawer } from 'antd'

import Icon from 'ui/Icon'

export interface DrawerProps {
  children: React.ReactNode
  open?: boolean
  keyboard?: boolean
  onClose: () => void
  className?: string
  height?: string | number
  title?: string
  closeable?: boolean
}

function Drawer({
  open,
  onClose,
  children,
  keyboard = true,
  height = 'auto',
  title,
  closeable = true,
}: DrawerProps) {
  return (
    <AntDrawer
      title={<span className="text-xs text-white uppercase mr-4">{title}</span>}
      placement="bottom"
      closable={false}
      onClose={onClose}
      keyboard={keyboard}
      open={open}
      getContainer="#bottom-drawer-root"
      mask={false}
      height={height}
      rootStyle={style}
      rootClassName="absolute"
      className="bg-secondary-800"
      classNames={{
        header: 'border-none',
        body: 'pt-0 text-white overflow-y-hidden'
      }}
      extra={
        closeable && (
          <Button
            type="text"
            size="small"
            className="flex flex-row text-white mr-4 lg:mr-8"
            onClick={onClose}
            icon={<Icon name="close" color="currentColor" className="text-xl"/>}
          >
             Fechar
          </Button>
        )
      }
    >
      {children}
    </AntDrawer>
  )
}

const style: any = { position: 'absolute' }

export default Drawer
