import Excel from 'exceljs'
import useDownloadOcrTranscription from 'domain/myFiles/services/useDownloadOcrTranscription'
import { message } from 'antd'
import { OrderItem } from 'domain/orderItem/types'
import { DocumentTabName } from 'domain/orderItem/types'
import { CertificateCode } from 'domain/servicesCBRdoc/types'
import { renderToStaticMarkup } from 'react-dom/server'
import { exportHTMLToPDF, saveAs, useDownload } from 'utils/export'

const reportFieldsLabels = {
  'area': 'Área',
  'ultima_atualizacao': 'Última atualização',
  'tipo_ativo': 'Tipo de ativo',
  'subtipo_ativo': 'Sub-tipo de ativo',
  'endereco': 'Endereço',
  'cidade': 'Cidade',
  'unidade_federativa': 'Estado',
  'numero_processo': 'Número do processo',
  'valor': 'Valor do imóvel',
  'apelantes': 'Apelante',
  'data_ato': 'Data do ato',
  'data_emissao': 'Data da certidão',
  'numero': 'Número',
  'integralizacao_capital_com_imoveis': 'Integralização de capital com imóveis',
  'objeto_social': 'Objeto social',
  'tipo_societario': 'Tipo societário',
  'capital_social': 'Capital social',
  'socios_acionistas': 'Sócios acionistas',
  'vara': 'Vara',
  'administracao_representacao': 'Administração representação',
  'alteracoes_societarias': 'Alterações societárias',
  'situacao': 'Situação',
  'classe': 'Classe',
  'orgao_julgador': 'Órgão Julgador / Relator',
  'assunto': 'Assunto',
  'data_distribuicao': 'Data da distribuição',
  'nome': 'Nome',
  'status_certidao': 'Status da certidão',
  'nome_reus': 'Réu',
  'processos': 'Processos',
  'proprietarios': 'Proprietários',
  'documento': 'Documento',
  'comarca': 'Comarca',
  'foro': 'Foro',
  'requerente': 'Requerente',
  'requerido': 'Requerido',
  'status': 'Status',
  'tipo_processo': 'Tipo de processo',
}

async function handlePDFDownload(orderItem: OrderItem) {
  if (orderItem.service.code === CertificateCode.CERTIDAO_JUSTICA_ESTADUAL) {
    
    const sortObjectKeys = (obj: any) => {
      const sortedObj: any = {}
      Object.keys(reportFieldsLabels).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          sortedObj[key] = obj[key]
        }
      })
      return sortedObj
    }

    const sortedProcesses = orderItem?.extractSummary?.processos?.map(sortObjectKeys)
    const reportHtml = (
      <div>
        <h1 style={{fontSize: '2rem'}}>Ficha da certidão</h1>
        <div>
          {sortedProcesses?.map((item) => (
            <div key={String(item)} style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'}}>
              {Object.entries(item).map(([key, value]) => (
                <div key={key + value}>
                  <strong>{reportFieldsLabels[key as keyof typeof reportFieldsLabels]}</strong>
                  <p>{value as string}</p>
                </div>
              ))}
            </div>
          )).reduce<JSX.Element[]>((acc, element) => {
            return acc.length === 0 ? [element] : [...acc, (<hr key={String(element)}/>), element]
          }, [])}
        </div>
      </div>
    )

    exportHTMLToPDF(
      new DOMParser().parseFromString(renderToStaticMarkup(reportHtml), 'text/html').body,
      `cbrdoc-fi as cha-pedido-${orderItem.order.orderNumber}`
    )

    return
  }

  const reportHtml = 
    document.querySelector('#extracted-report-content')?.cloneNode(true) as HTMLElement

  if (reportHtml) {
    const removableSelectors = ['.ant-alert', 'svg']
  
    removableSelectors.forEach((selector) => {
      const elements = reportHtml.querySelectorAll(selector)
      elements.forEach((element) => {
        element.remove()
      })
    })
  }
  exportHTMLToPDF(reportHtml, `cbrdoc-ficha-pedido-${orderItem.order.orderNumber}`)
}

async function handleExcelDownload(orderItem: OrderItem) {
  const workbook = new Excel.Workbook()
  
  const sheet =
    workbook.addWorksheet(`Ficha ${orderItem.service?.shortName ?? 'pedido'} - ${orderItem.orderItemNumber}`)

  const filteredSummary =
    JSON.parse(JSON.stringify(orderItem?.extractSummary), (key, value) => value === null || value.toString() === '' ? undefined : value)

  const sortedReport = Object.keys(reportFieldsLabels).reduce((acc: any, key) => {
    if (Object.prototype.hasOwnProperty.call(filteredSummary, key)) {
      acc[key] = filteredSummary[key]
    }
    return acc
  }, {})

  const reportKeys = sortedReport ? Object.keys(sortedReport) : []

  const sheetConfig = [] as any
  let header = reportKeys.map((headerItem) => {
    sheetConfig.push({ width: 40 })  
    return reportFieldsLabels[headerItem as keyof typeof reportFieldsLabels]
  })

  let body
  if (reportKeys.includes('area')) {
    const { area } = sortedReport
    sortedReport.area = area.total || area.total_area || '-'
  }

  if (reportKeys.includes('processos')) {
    header = header.filter(value => value !== 'Processos')
    const { processos: processes, ...otherProcessKeys } = sortedReport
    const customKeys = [] as string[]

    if (processes && Object.keys(processes[0]).length > 0) {
      customKeys.push(...Object.keys(processes[0]))
    }

    header.push(...customKeys.map((headerItem) => {
      sheetConfig.push({ width: 40 })
      return reportFieldsLabels[headerItem as keyof typeof reportFieldsLabels]
    }))

    body = processes.map((process: any[]) => [
      ...Object.values(otherProcessKeys),
      ...customKeys.map(key => process[key as any]),
    ])
  }

  if (reportKeys.includes('proprietarios')) {
    const { proprietarios: landlords, ...otherLandlordKeys } = sortedReport
    header = header.filter(value => value !== 'Proprietários')
    const customKeys = [] as string[]

    if (landlords && Object.keys(landlords[0]).length > 0) {
      customKeys.push(...Object.keys(landlords[0]))
    }

    header.push(...customKeys.map((headerItem) => {
      sheetConfig.push({ width: 40 })
      return reportFieldsLabels[headerItem as keyof typeof reportFieldsLabels]
    }))

    body = landlords.map((landlord: any[]) => [
      ...Object.values(otherLandlordKeys),
      ...customKeys.map(key => landlord[key as any]),
    ])
  }

  const alternativeKeys = ['proprietarios', 'processos', 'area']
  if (!(reportKeys.some(key => alternativeKeys.includes(key)))) {
    body = [Object.values(sortedReport)]
  }

  sheet.columns = sheetConfig

  const rowHeader = sheet.addRow(header)
  rowHeader.font = { bold: true }

  if (body) {
    body.forEach((row: string[]) => {
      sheet.addRow(row)
    })
  }

  const buffer = await workbook.xlsx.writeBuffer()
  saveAs(new Blob([buffer as Buffer]), `cbrdoc-ficha-excel-pedido-${orderItem.orderItemNumber}.xlsx`)
}

export const dropdownOptions = (orderItem: OrderItem, menuTab: DocumentTabName) => {
  const [handleDownload] = useDownload()
  const { handleOcrDownload } = useDownloadOcrTranscription()
  
  switch (menuTab) {
    case 'report':
      return [
        {
          disabled: !orderItem?.fileURL,
          key: '0',
          label: 'Baixar ficha em pdf',
          onClick: async () => {
            const hide = message.loading('Preparando o download...')
            await handlePDFDownload(orderItem)
            hide()
            message.success('Download com sucesso!')
          },
        },
        {
          disable: orderItem?.ocr?.id,
          key: '2',
          label: 'Baixar ficha em excel',
          onClick: async () => {
            const hide = message.loading('Preparando o download...')
            await handleExcelDownload(orderItem)
            hide()
            message.success('Download com sucesso!')
          },
        },
      ]

    case 'openAI':
      return [
        {
          disabled: !orderItem?.fileURL,
          key: '0',
          label: 'Documento em pdf',
          onClick: () => {
            const downloadUrl = `/orders/${orderItem.id}/download`
            handleDownload(downloadUrl)
          },
        },
        {
          disable: orderItem?.ocr?.id,
          key: '2',
          label: 'Transcrição em .txt',
          onClick: () => handleOcrDownload(orderItem?.ocr?.id ?? 0, 'docx'),
        },
        {
          visible: orderItem?.ocr?.id,
          key: '3',
          label: 'Extração de dados',
          onClick: () => {
            const itemsId = orderItem?.ocr?.id || ''
            const fileName = `cbrdoc-IA-pedido-${orderItem.order.orderNumber}`
            handleDownload(`reports/ai-answers/xlsx?ocr_id=${itemsId}`, fileName)
          },
        },
      ]

    default:
      return []
  }
}