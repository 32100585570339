import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaImovelRuralFields = Record<
  FieldName.CPF | FieldName.CNPJ | FieldName.NOME_PF | FieldName.NOME_PJ,
  FieldConfig
>

class PesquisaImovelRural extends Research {
  formFields: PesquisaImovelRuralFields = {
    cpf: Fields.cpf,
    cnpj: Fields.cnpj,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
  }

  constructor() {
    super({
      description: 'Retorna os dados do certificado de cadastro do imóvel rural (CCIR)',
      id: 47,
      shortName: 'Pesquisa CCIR',
    })
  }
}

const service = new PesquisaImovelRural().initialize()

export default service
