import React from 'react'
import { Dropdown, DropdownProps } from 'antd'

import DropdownActionsButton from './DropdownActionsButton'

function DropdownActions(props: DropdownProps) {
  return (
    <Dropdown {...props}>
      <DropdownActionsButton />
    </Dropdown>
  )
}

export default DropdownActions
