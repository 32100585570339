import React from 'react'
import { Button, Form } from 'antd'
import { Dayjs } from 'dayjs'

import { DatePickerField } from 'ui'
import { displayEndOfDay, getTomorrow, isAfterToday } from 'utils/dateTime'

interface Props {
  onChange: (value: Dayjs) => void
}

export default function StartAtInput({ onChange }: Props) {
  const form = Form.useFormInstance()

  return (
    <DatePickerField
      label="Inicia em"
      name="startsAt"
      disabledDate={disabledDate}
      rules={startsAtValidation}
      showToday={false}
      renderExtraFooter={() => (
        <Button
          type="primary"
          onClick={() => {
            const tomorrow = getTomorrow()
            form.setFieldValue('startsAt', tomorrow)
            onChange(tomorrow)
          }}
          ghost
          block
          size="small"
        >
          Amanhã
        </Button>
      )}
    />
  )
}

const disabledDate = (current: any) => {
  return current && current < displayEndOfDay()
}

const startsAtValidation = [
  { required: true, message: 'Informe a data de início' },
  () => ({
    validator(_: any, value: any) {
      if (isAfterToday(value)) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('A data de início deve ser maior do que a de hoje.'))
    },
  }),
]
