import { Radio, RadioGroupProps, RadioChangeEvent, message } from 'antd'

import { SelectedService } from 'domain/createOrder/types'
import { KitLegalEntity } from 'domain/kits/types'
import { getService } from 'domain/servicesCBRdoc'

interface Props extends RadioGroupProps {
  legalEntity?: KitLegalEntity
  currentServices: SelectedService[]
  compact?: boolean
}

export default function KitLegalEntityRadio({
  legalEntity,
  currentServices,
  compact,
  onChange,
  ...props
}: Props) {
  const onChangeEntity = (e: RadioChangeEvent) => {
    const newLegalEntity = e.target.value

    const incompatibleService = currentServices.find(service => {
      const serviceLegalEntity = getService(service.code!).legalEntity

      if (newLegalEntity === 'indefinido') {
        return serviceLegalEntity === 'fisica' || serviceLegalEntity === 'juridica'
      }

      const incompatibleEntity = newLegalEntity === 'fisica' ? 'juridica' : 'fisica'
      return serviceLegalEntity === incompatibleEntity
    })

    if (incompatibleService) {
      message.error(`Tipo de Pessoa incompatível com ${incompatibleService.name}.`)
    } else {
      onChange && onChange(e)
    }
  }

  return (
    <Radio.Group
      {...props}
      size={compact ? 'small' : 'middle'}
      value={legalEntity}
      onChange={onChangeEntity}
    >
      <Radio.Button value="fisica">Pessoa Física</Radio.Button>
      <Radio.Button value="juridica">Pessoa Jurídica</Radio.Button>
      <Radio.Button value="indefinido">Pessoa Física ou Jurídica</Radio.Button>
    </Radio.Group>
  )
}
