import { DueDiligence } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { ARQUIVOS, LOCAL_SERVICO, MENSAGEM, NUMERO_PROCESSO, TIPO_PROCESSO } = FieldName

type DiligenciaFields = Record<
  | FieldName.ARQUIVOS
  | FieldName.LOCAL_SERVICO
  | FieldName.MENSAGEM
  | FieldName.NUMERO_PROCESSO
  | FieldName.TIPO_PROCESSO,
  FieldConfig
>

class DiligenciaOutras extends DueDiligence {
  formFields: DiligenciaFields = {
    [ARQUIVOS]: Fields.arquivos,
    [LOCAL_SERVICO]: Fields.local_servico,
    [NUMERO_PROCESSO]: Fields.numero_processo,
    [TIPO_PROCESSO]: Fields.tipo_processo,
    [MENSAGEM]: Fields.mensagem,
  }

  constructor() {
    super({
      id: 92,
      shortName: 'Diligência - Outras',
    })
  }
}

const service = new DiligenciaOutras().initialize()

export default service
