import { useState } from 'react'
import dayjs from 'dayjs'
import { DatePicker } from 'antd'
import { RangePickerProps } from 'antd/es/date-picker'

import { PeriodString } from 'domain/app/types'
import Icon from 'ui/Icon/Icon'
import { formatDatesToAPI } from 'utils/dateTime'

const { RangePicker } = DatePicker

interface Props {
  value?: PeriodString | ''
  onChange?: (date: PeriodString | '') => void
  allowClear?: boolean
  allowFuture?: boolean
  allowEmpty?: [boolean, boolean]
  disabledDate?: any
}

export default function DateRangePicker({
  value = '',
  onChange,
  allowClear = true,
  allowFuture,
  ...props
}: Props) {
  const dates = value?.split(',')
  const beginDate = dates[0]
  const endDate = dates[1]
  const [begin, setBegin] = useState(beginDate ? dayjs(beginDate) : undefined)
  const [end, setEnd] = useState(endDate ? dayjs(endDate) : undefined)

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && !allowFuture && dayjs(current as any).isAfter(today, 'date')
  }

  return (
    <RangePicker
      separator={<Icon name="arrow-right" />}
      className="w-full h-46px"
      format="DD/MM/YYYY"
      placeholder={placeholder}
      value={[begin, end] as any}
      onChange={dates => {
        if (dates) {
          setBegin(dates[0]! as dayjs.Dayjs)
          setEnd(dates[1]! as dayjs.Dayjs)
          onChange && onChange((formatDatesToAPI(dates as any) as PeriodString) ?? '')
        }
      }}
      disabledDate={disabledDate}
      allowClear={allowClear}
      {...props}
    />
  )
}

const placeholder: any = ['Data inicial', 'Data final']
const today = new Date()
