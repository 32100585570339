import React from 'react'
import { Form } from 'antd'

import { ExplorerItemType } from 'domain/myFiles/types'
import { Select } from 'ui'

function MyFilesFilterTypeSelect() {
  return (
    <Form.Item name="type" label="Tipo">
      <Select size="middle" placeholder="Selecione o tipo" options={options} />
    </Form.Item>
  )
}

const options: { label: string; value: ExplorerItemType }[] = [
  { label: 'Pasta', value: ExplorerItemType.FOLDER },
  { label: 'Solicitação', value: ExplorerItemType.ORDER },
  { label: 'Upload', value: ExplorerItemType.UPLOADED_FILE },
  { label: 'Extração de dados', value: ExplorerItemType.AI }
]

export default MyFilesFilterTypeSelect
