import React from 'react'
import { Spin } from 'antd'

interface Props {
  message?: string
}

function Loading({ message = 'Carregando...' }: Props) {
  return (
    <div className="flex-1 flex flex-col">
      <div className="bg-gray-300 py-10 px-7 border-b border-gray-400"></div>
      <div className="flex-1 flex flex-col justify-center items-center">
        <Spin size="default" />
        {!!message && <div className="text-gray-600 mt-3">{message}</div>}
      </div>
    </div>
  )
}

export default Loading
