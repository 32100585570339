import React, { useMemo, useState } from 'react'
import { Button } from 'antd'

import { useAllServices } from 'domain/servicesCBRdoc'
import { ServiceType } from 'domain/servicesCBRdoc/types'
import { Alert, Icon } from 'ui'

import { ServiceListType, SimulationInputItem, SimulationType } from '../types'
import FormPriceSimulationSimplified from './FormPriceSimulationSimplified'
import FormPriceSimulationAdvanced from './FormPriceSimulationAdvanced'
import SelectServiceTypeRadio from './SelectServiceTypeRadio'

interface Props {
  simulationType: SimulationType
  setSimulationType: React.Dispatch<React.SetStateAction<SimulationType>>
  simulationInput: SimulationInputItem[]
  setSimulationInput: React.Dispatch<React.SetStateAction<SimulationInputItem[]>>
  restartSimulation: () => void
  loading: boolean
}

export default function FormPriceSimulation({
  simulationType,
  setSimulationType,
  simulationInput,
  setSimulationInput,
  restartSimulation,
  loading,
}: Props) {
  const [serviceListType, setServiceListType] = useState<ServiceListType>(ServiceType.CERTIFICATE)
  const allServices = useAllServices()

  const onServiceTypeChange = (type: any) => {
    setServiceListType(type)
    restartSimulation()
  }

  const servicesList = useMemo(() => {
    return allServices.filter(o => o.type === serviceListType)
  }, [allServices, serviceListType])

  return (
    <>
      {simulationType === 'simplified' ? (
        <Alert
          type="warning"
          icon={<Icon name="light-bulb" />}
          message={
            <span>
              Você pode calcular seus gastos anuais e ganhar muito mais em bônus,{' '}
              <b>pois seus créditos não expiram!</b>
            </span>
          }
        />
      ) : (
        <div className="pb-5 mb-5 border-b border-gray-300">
          Simule quantos tipos de documentos desejar em diversos estados.
        </div>
      )}

      <SelectServiceTypeRadio value={serviceListType} onChange={onServiceTypeChange} />

      {simulationType === 'simplified' ? (
        <FormPriceSimulationSimplified
          servicesList={servicesList}
          setSimulationInput={setSimulationInput}
          loading={loading}
        />
      ) : (
        <FormPriceSimulationAdvanced
          servicesList={servicesList}
          simulationInput={simulationInput}
          setSimulationInput={setSimulationInput}
          serviceType={serviceListType}
          onServiceTypeChange={onServiceTypeChange}
          loading={loading}
        />
      )}

      <div className="text-center">
        <Button
          type="link"
          className="mt-2"
          onClick={() => {
            setSimulationType(simulationType === 'advanced' ? 'simplified' : 'advanced')
          }}
        >
          {simulationType === 'advanced' ? 'Voltar para simulação simples' : 'Simulação avançada'}
        </Button>
      </div>
    </>
  )
}
