import React from 'react'

import { SuccessModalMessage } from 'ui'

function ReportProblemModalSuccess({ onRequestClose }: { onRequestClose: () => void }) {
  return (
    <SuccessModalMessage title="Mensagem enviada com sucesso" onClick={onRequestClose}>
      <p className="my-7">
        Nossa equipe irá analisar o ocorrido e, em breve, entrará em contato. Nosso prazo de resposta é de até
        5 dias úteis.
      </p>
    </SuccessModalMessage>
  )
}

export default ReportProblemModalSuccess
