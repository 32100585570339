import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

import { useSetIsExpired, useSetResults, useSetStatuses } from './filters'
import { useOverviewCurrentPage } from './pagination'

function useOverviewInitialValuesFromURL() {
  const history = useHistory()
  const location = history.location
  const [currentPage, setCurrentPage] = useOverviewCurrentPage()
  const setIsExpired = useSetIsExpired()
  const setResults = useSetResults()
  const setStatuses = useSetStatuses()

  useEffect(() => {
    let page = 1

    if (location.search) {
      const { expired, p, results, status } = queryString.parse(location.search)

      if (expired) setIsExpired(true)
      if (p) page = Number(p)
      if (results) setResults(results)
      if (status) setStatuses(status)
    }

    if (currentPage === undefined) {
      setCurrentPage(page)
    }
  }, [])
}

export default useOverviewInitialValuesFromURL
