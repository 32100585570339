import React from 'react'

import { useCurrentPage, useGetTotalResults, usePageSize } from 'domain/notifications/state'
import { Pagination } from 'ui'

interface Props {
  topRef: React.RefObject<HTMLDivElement>
}

function NotificationsPagination({ topRef }: Props) {
  const [currentPage, setCurrentPage] = useCurrentPage()
  const [pageSize, setPageSize] = usePageSize()
  const totalResults = useGetTotalResults()

  const handleChangePage = (page: string) => {
    setCurrentPage(Number(page))
    topRef.current?.scrollIntoView()
  }

  return (
    <Pagination
      className="pb-7"
      totalCount={totalResults}
      currentPage={currentPage}
      onChangePage={handleChangePage as any}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
    />
  )
}

export default NotificationsPagination
