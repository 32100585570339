import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { FORMATO, CIB } = FieldName

type CertidaoITRFields = Record<FieldName.CIB, FieldConfig>

class CertidaoITR extends Certificate {
  formFields: CertidaoITRFields = {
    [CIB]: Fields.cib,
  }

  constructor() {
    super({
      id: 18,
      orderNameField: CIB,
      priceByFields: [FORMATO],
      shortName: 'ITR',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoITR().initialize()

export default service
