import React from 'react'

import { ViewResearchActions, ViewResearchWithResults } from 'domain/orderItem'
import { ExcelGenerator, TableColumn } from 'domain/orderItem/types'

function ViewPesquisaDivorcio() {
  return (
    <ViewResearchWithResults columns={columns} actions={<ViewResearchActions excel={excelGenerator} />} />
  )
}

const columns: TableColumn[] = [
  {
    title: 'Nome',
    dataIndex: 'nome',
  },
  {
    title: 'CPF',
    dataIndex: 'documento',
  },
  {
    title: 'Tipo de Ato',
    dataIndex: 'tipo_ato',
  },
  {
    title: 'Data',
    dataIndex: 'data',
  },
  {
    title: 'Livro/Folha',
    dataIndex: 'livro_pagina',
  },
  {
    title: 'Estado',
    dataIndex: ['url_uf', 'label'],
  },
  {
    title: 'Cidade',
    dataIndex: ['url_cidade', 'label'],
  },
  {
    title: 'Cartório',
    dataIndex: ['url_cartorio', 'label'],
  },
]

const excelGenerator: ExcelGenerator = {
  columns: columns
}

export default ViewPesquisaDivorcio
