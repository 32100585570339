import TagManager from 'react-gtm-module'

import { IGNORE_RDS_TO_USERS, RDS } from 'api/rdstation'
import { CheckoutData } from 'domain/checkout/types'
import { Customer } from 'domain/customers/types'
import { User } from 'domain/users/types'

import placeOrderAPI, { ShoppingCartData } from './placeOrderAPI'

type TransactionProduct = {
  sku: string
  name: any
  category: any
  price: any
  quantity: number
}

interface PlaceOrderWithMetrics extends ShoppingCartData {
  customer: Customer
  purchaseTotal: number
  user: User
  origin: string
}

async function placeOrderAPIWithMetrics(
  shoppingCartData: PlaceOrderWithMetrics,
  checkoutState: CheckoutData
) {
  const { customer, orderItems, purchaseTotal, user } = shoppingCartData
  const data = await placeOrderAPI(shoppingCartData, checkoutState)

  try {
    TagManager.dataLayer({
      dataLayerName: 'PageDataLayer',
      dataLayer: {
        event: 'orderPlaced',
        transactionId: data.backoffice_id,
        transactionAffiliation: 'Sem-Cupom',
        transactionTotal: purchaseTotal,
        transactionTax: 0,
        transactionShipping: 0,
        transactionProducts: orderItems.map(order => {
          return {
            sku: 'DD44',
            name: order.selectedService.name,
            category: order.selectedService.type,
            price: order.orderDetails.totalValue,
            quantity: 1,
          } as TransactionProduct
        }),
      },
    })

    if (!IGNORE_RDS_TO_USERS.includes(user.email)) {
      RDS.conversionEvent({
        event_type: 'CONVERSION',
        event_family: 'CDP',
        payload: {
          conversion_identifier:
            orderItems.length === 1
              ? 'Pedido de ' + orderItems[0].selectedService.name + ' feito com sucesso'
              : 'Pedido misto feito com sucesso',
          name: user.name,
          email: user.email,
          company_name: customer.companyName,
          mobile_phone: user.phone,
          tags: ['pedido_feito'],
          cf_tipo_documento: orderItems.length === 1 ? orderItems[0].selectedService.name : 'Misto',
          cf_valor_pedido: purchaseTotal,
          cf_documento_cliente: customer.cnpj || customer.cpf,
        },
      })

      RDS.conversionEvent({
        event_type: 'CONVERSION',
        event_family: 'CDP',
        payload: {
          conversion_identifier: 'Venda',
          email: user.email,
        },
      })
    }
  } catch {
    // ignore
  }

  return data
}

export default placeOrderAPIWithMetrics
