import React from 'react'

import Button from './Button'

export default function AddBySpreadsheetButton({ onClick }: { onClick: () => void }) {
  return (
    <Button icon="file-xls" type="link" onClick={onClick}>
      Adicionar via planilha
    </Button>
  )
}
