import React from 'react'

import { useSetResults } from 'domain/orders/state/filters'
import { OrderResults, ResultCode } from 'domain/orders/types'
import { FilterTag } from 'ui'

export default function FilterByResultsTags({ value }: { value: ResultCode[] }) {
  const setResults = useSetResults()
  const results = allResults.filter(result => value.includes(result.code))

  return (
    <>
      {results.map(result => (
        <FilterTag key={result.code} onClick={() => setResults(value.filter(code => code !== result.code))}>
          {result.name}
        </FilterTag>
      ))}
    </>
  )
}

const allResults = Object.entries(OrderResults).map(([code, { label }]) => ({
  name: label,
  code: code as ResultCode,
}))
