import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { InvoicePostpaidReportAPI } from 'domain/invoices/types'

import convertPostpaidInvoice from './convertPostpaidInvoiceReport'

const fetchExportPostpaidInvoices = async (selectedMonth: Date) => {
  const dayDate = selectedMonth.getMonth() + 1
  const yearDate = selectedMonth.getFullYear()

  const response: AxiosResponse<InvoicePostpaidReportAPI[]> = await AuthorizedAPI.get(
    `/reports/invoice-postpaids/${yearDate}/${dayDate}`
  )
  return response.data.map((invoice, index) => convertPostpaidInvoice(invoice, index))
}

export default fetchExportPostpaidInvoices
