import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoNegativaFGTSFields = Record<
  FieldName.CEI | FieldName.CNPJ,
  FieldConfig
>

class CertidaoNegativaFGTS extends Certificate {
  formFields: CertidaoNegativaFGTSFields = {
    cnpj: Fields.cnpj,
    cei: Fields.cei,
  }

  constructor() {
    super({
      id: 17,
      priceByFields: [FieldName.FORMATO],
      shortName: 'Certidão Negativa FGTS',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoNegativaFGTS().initialize()

export default service
