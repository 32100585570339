import dayjs from 'dayjs'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

import { PeriodString } from 'domain/app/types'
import { atomURI } from 'utils/navigation'

const date = dayjs()
const today = date.format('YYYY-MM-DD')
const lastMonth = date.subtract(1, 'month').format('YYYY-MM-DD')

export const periodAtom = atom(`${lastMonth},${today}` as PeriodString)
export const periodAtomURIAtom = atomURI(periodAtom, 'period')

export const usePeriodFilter = () => useAtom(periodAtomURIAtom)
export const useGetPeriodFilter = () => useAtomValue(periodAtom)
export const useSetPeriodFilter = () => useSetAtom(periodAtomURIAtom)
