import { Dropdown } from 'antd'
import { useOpenAIDownload } from 'domain/openAI/reports'
import {
  useDownloadOrderItems,
  useDownloadOrderItemsReport,
  useDownloadOrderOverviewPDF,
  useFirstDownloadOrderItems,
} from 'domain/orders/helpers'
import { OrderOverview } from 'domain/orders/types'
import { OneResultByRowCheckbox } from 'domain/reports'
import { Button, Modal } from 'ui'
import { stopPropagation } from 'utils/stopPropagation'

interface DownloadActionsProps {
  order: OrderOverview
}

const DownloadActions = ({ order }: DownloadActionsProps) => {
  const { onClick } = useDownloadOrderItems({ downloadUrl: `purchases/${order.id}/download`, order })
  const {
    downloadableItems,
    notDownloadedItems,
    onClick: firstDownloadOnClick,
  } = useFirstDownloadOrderItems({ order })

  const { loading, onDownload, open, toggleModal, toggleResultOption } = useDownloadOrderItemsReport({
    orderId: order.id,
    orderNumber: order.orderNumber,
  })
  const { handleExport } = useDownloadOrderOverviewPDF({ order })

  const { download } = useOpenAIDownload({
    orderNumber: order.orderNumber,
    orderId: order.id,
  })

  const menuItemClassName = 'text-primary aria-disabled:text-gray-500'

  return (
    <>
      <Dropdown
        placement="bottomRight"
        className="self-end"
        menu={{
          onClick: stopPropagation,
          items: [
            {
              key: '0',
              label: `Itens finalizado (${downloadableItems}/${order.totalItems})`,
              onClick: async () => await onClick(),
              className: menuItemClassName,
              disabled: downloadableItems === 0,
            },
            {
              key: '1',
              label: `Itens não baixados (${notDownloadedItems}/${order.totalItems})`,
              onClick: firstDownloadOnClick,
              className: menuItemClassName,
              disabled: notDownloadedItems === 0,
            },
            {
              key: '2',
              label: 'Dossiê (resumo) em Excel',
              onClick: () => toggleModal(),
              className: menuItemClassName,
              disabled: order.hasAiExtractData,
            },
            {
              key: '3',
              label: 'Dossiê (resumo) em PDF',
              onClick: handleExport,
              className: menuItemClassName,
              disabled: order.hasAiExtractData,
            },
            {
              key: '4',
              label: 'Baixar dados extraídos',
              onClick: download,
              className: menuItemClassName,
              disabled: !order.hasAiExtractData,
            },
          ],
        }}
        trigger={['click']}
      >
        <Button icon="download" endIcon="chevron-down">
          Baixar
        </Button>
      </Dropdown>
      <Modal open={open} onCancel={toggleModal} title="Exportar para Excel">
        <OneResultByRowCheckbox onToggle={toggleResultOption} />

        <Button type="primary" block onClick={onDownload} loading={loading}>
          Exportar
        </Button>
      </Modal>
    </>
  )
}

export default DownloadActions
