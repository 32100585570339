import { DateRangePicker } from 'ui'
import { usePeriodFilter } from '../filtersState'

export default function PeriodFilter() {
  const [period, setPeriod] = usePeriodFilter()

  return (
    <div className="mx-7 w-80">
      <label className="text-gray-600 uppercase font-bold text-2xs pb-1 block">Período</label>

      <DateRangePicker value={period} onChange={setPeriod} allowClear={false} />
    </div>
  )
}
