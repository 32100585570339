import React from 'react'

import { KitItem } from 'domain/kits/types'
import { useCategoriesContext } from 'domain/servicesCBRdoc'
import { Sidebar } from 'layouts'

import KitSidebarItem from './KitSidebarItem'

interface Props {
  items: KitItem[]
  header: React.ReactNode
  children?: React.ReactNode
}

export default function KitSidebar({ children, header, items }: Props) {
  const categories = useCategoriesContext()

  return (
    <Sidebar sectionTitle="Seu kit">
      <div className="divide-y divide-gray-300">
        <div className="mb-4">{header}</div>

        {items.map((item, index) => (
          <KitSidebarItem key={index} item={item} categories={categories || []} />
        ))}

        <div>{children}</div>
      </div>
    </Sidebar>
  )
}
