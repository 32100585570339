import { CertificateFlow } from 'domain/createOrder/certificates'
import { NumberField } from 'domain/servicesCBRdoc/fields'
import { CNPJInput } from 'ui'

import config from '../certidaoNegativaFGTSConfig'

export default function CreateOrderCertidaoNegativaFGTS() {
  return (
    <CertificateFlow
      header={
        <>
          <p className="mb-5 text-gray-600">
            Digite o número do CNPJ que deseja a Certidão Negativa do FGTS (Certificado de Regularidade Fiscal
            CRF).
          </p>
        </>
      }
      renderFormItem={(itemId: number) => <CertidaoNegativaFGTSForm itemId={itemId} />}
    />
  )
}

const CertidaoNegativaFGTSForm = ({ itemId }: { itemId: number }) => {
  return (
    <>
      <CNPJInput itemId={itemId} autoFocus={itemId === 0} />
      <NumberField itemId={itemId} field={cei} />
    </>
  )
}

const { cei } = config.formFields
