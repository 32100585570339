import { useMutation } from 'react-query'
import { message } from 'antd'

import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'
import OrdersQueryKeys from 'domain/orders/services/OrdersQueryKeys'
import { useGetOverviewFilters } from 'domain/orders/state/filtersOverview'
import {
  useGetOverviewCurrentPage,
  useGetOverviewPageSize,
  useGetOverviewSortBy,
} from 'domain/orders/state/pagination'
import { useSetOrderItem } from '../state/viewOrderItem'

function useChangeOrderItemName(orderItemId: number, orderId: number) {
  const setOrderItem = useSetOrderItem()
  const filters = useGetOverviewFilters()
  const overviewCurrentPage = useGetOverviewCurrentPage()
  const overviewPageSize = useGetOverviewPageSize()
  const overviewSortBy = useGetOverviewSortBy()

  const mutation = useMutation((newName: string) => changeOrderItemName(orderItemId, newName), {
    onError: () => {
      message.error('Ocorreu um erro ao salvar o nome do item do pedido.')
    },
    onSuccess: (data: any, name: string) => {
      queryClient.refetchQueries({ queryKey: OrdersQueryKeys.overview(orderId) })
      queryClient.refetchQueries({
        queryKey: OrdersQueryKeys.orderItems(
          orderId,
          overviewCurrentPage!,
          filters,
          overviewPageSize,
          overviewSortBy
        ),
      })
      queryClient.invalidateQueries(['orders', 'all-items'])

      setOrderItem(prev => ({ ...prev, name }))
      message.success('O nome do item do pedido foi atualizado.')
    },
  })

  return mutation
}

async function changeOrderItemName(id: number | string, name: string) {
  return await AuthorizedAPI.patch(`orders/${id}/name`, {
    name,
  })
}

export default useChangeOrderItemName
