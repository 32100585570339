import React from 'react'

import { KitItem as KitItemType } from 'domain/kits/types'
import { CategoryAPI } from 'domain/servicesCBRdoc/types'
import KitItem from '../viewKit/KitItem'

interface Props {
  item: KitItemType
  categories: CategoryAPI[]
}

function KitSidebarItem({ item, categories }: Props) {
  const category = categories.find(o => o.id === item.service.categoryId)
  const service = category?.services.find(o => o.id === item.service.id)

  if (!service || !category) return <p>Serviço não encontrado.</p>  

  return (
    <div className="px-2 py-4 flex items-center transition duration-300 ease-in-out">
      <KitItem item={item} serviceName={service.name} />
    </div>
  )
}

export default KitSidebarItem
