import React, { useReducer } from 'react'
import { useHistory } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { Kit } from 'domain/kits/types'
import { Button, Icon } from 'ui'

import KitSidebar from '../registerKit/KitSidebar'
import KitSidebarName from '../registerKit/KitSidebarName'
import EditKitLegalEntity from './EditKitLegalEntity'
import EditKitName from './EditKitName'

interface Props {
  kit: Kit
  queryKey: any[]
}

export default function EditKitSidebar({ kit, queryKey }: Props) {
  const history = useHistory()
  const [isEditing, toggleEditing] = useReducer(prev => !prev, false)

  const KitName = isEditing ? (
    <EditKitName kit={kit} onFinish={toggleEditing} queryKey={queryKey} />
  ) : (
    <KitSidebarName kitName={kit.name} totalItems={kit.items.length} onClickEditButton={toggleEditing} />
  )

  return (
    <KitSidebar
      header={
        <>
          {KitName}

          <EditKitLegalEntity kit={kit} queryKey={queryKey} />

          <Button
            icon="paper-plane"
            type="primary"
            className="mb-6 mt-8 text-center"
            onClick={() => history.push(RoutePaths.CREATE_ORDER_KIT.replace(':id', String(kit.id)))}
            block
          >
            Solicitar o Kit
          </Button>
        </>
      }
      items={kit.items}
    />
  )
}
