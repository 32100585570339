import React from 'react'
import { NamePath } from 'antd/lib/form/interface'

import { LabelInValue } from 'domain/app/types'
import { SelectedService } from 'domain/createOrder/types'
import { getServiceConfig } from 'domain/servicesCBRdoc/products'
import { useNotariesQuery } from 'domain/servicesCBRdoc/services'
import { SelectField, SelectFieldProps } from 'ui'
import LocationAllNotariesCheck from './LocationAllNotariesCheck'

interface Props extends Omit<SelectFieldProps, 'label' | 'mode' | 'onChange'> {
  checkAllName: NamePath
  city?: string
  federativeUnit?: string
  name: NamePath
  required?: boolean
  service: SelectedService
  onChange: (value: LabelInValue | LabelInValue[] | undefined, isAllNotaries?: boolean) => void
}

function LocationNotarySelect({
  checkAllName,
  service,
  federativeUnit,
  city,
  onChange,
  name,
  ...props
}: Props) {
  const serviceConfig = getServiceConfig(service.code!)
  const { data: allNotaries, status } = useNotariesQuery(service, federativeUnit, city)
  const isMultiple = serviceConfig.formFields.url_cartorio!.multiple
  const hasAllNotariesCheck = isMultiple && serviceConfig.formFields.todos_cartorios_cidade

  const handleChangeAll = (checked: boolean) => {
    const selectedNotaries = checked ? allNotaries : []
    onChange(selectedNotaries, checked)
  }

  const handleChangeNotary = (selected: LabelInValue | LabelInValue[] | undefined) => {
    const isAllSelected = Array.isArray(selected) && selected.length === allNotaries?.length
    onChange(selected, isAllSelected)
  }

  return (
    <>
      {hasAllNotariesCheck && (
        <LocationAllNotariesCheck
          name={checkAllName}
          disabled={!city}
          allNotariesQuantity={allNotaries?.length ?? 0}
          onChange={handleChangeAll}
        />
      )}

      <SelectField
        name={name}
        label="Cartório"
        placeholder="Selecione o cartório"
        hasError={status === 'error'}
        onChange={handleChangeNotary}
        loading={status === 'loading'}
        mode={isMultiple ? 'multiple' : undefined}
        options={allNotaries}
        labelInValue
        {...props}
      />
    </>
  )
}

export default LocationNotarySelect
