import { useDispatch } from 'react-redux'

import { CertificateFlow } from 'domain/createOrder/certificates'
import { createOrderActions, useCreateOrderState, useLoadExtraInfos } from 'domain/createOrder/document'
import CertidaoImovelType from './CertidaoImovelType'
import CertidaoImovelSearchFor from './CertidaoImovelSearchFor'
import CertidaoImovelForm from './CreateOrderCertidaoImovelForm'

export default function CreateOrderCertidaoImovel() {
  const dispatch = useDispatch()
  const { selectedLocation, selectedService, selectedType } = useCreateOrderState()
  const { data, isFetching } = useLoadExtraInfos()

  return (
    <CertificateFlow
      header={
        <>
          <CertidaoImovelType
            service={selectedService}
            location={selectedLocation}
            onChange={(type: string) => {
              dispatch(createOrderActions.setSelectedType(type))
              dispatch(createOrderActions.setSelectedModel(undefined))
            }}
            autoFocus
          />

          {selectedType && (
            <CertidaoImovelSearchFor
              service={selectedService}
              location={selectedLocation}
              type={selectedType as string}
              onChange={searchFor => dispatch(createOrderActions.setSelectedModel(searchFor))}
            />
          )}
        </>
      }
      renderFormItem={(itemId: number) => (
        <CertidaoImovelForm itemId={itemId} extraInfos={data} loading={isFetching} />
      )}
    />
  )
}
