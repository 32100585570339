import { Form } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document/state'
import { Alert, Icon, SubmitButton } from 'ui'

import { useAddCreateOrderStateToCart } from '../services'
import SelectExtras from './extras/SelectExtras'
import SelectFormat from './SelectFormat'

export default function FormatAndExtrasStep() {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const addToCart = useAddCreateOrderStateToCart()
  const createOrderData = useCreateOrderState()
  const [loading, setLoading] = useState(false)

  const { multipleItemsLoadingPrice } = useCreateOrderState()
  const { selectedFormat, selectedExtras } = createOrderData

  const handleAddToShoppingCart = () => {
    setLoading(true)
    addToCart()
    history.push(RoutePaths.SHOPPING_CART)
  }

  useEffect(() => {
    if (!selectedFormat) {
      dispatch(createOrderActions.setSelectedFormat('email'))
    }
  }, [])
  
  return (
    <div className="my-8 mx-5">
      <h1 className="text-base px-2 mb-6 uppercase">Como quer receber seu documento?</h1>

      <SelectFormat />

      <div className="mt-8 mx-2">
        <Form
          form={form}
          className="max-w-lg"
          layout="vertical"
          onFinish={handleAddToShoppingCart}
          requiredMark={false}
          initialValues={selectedExtras}
        >
          <div>
            <h2 className="text-base mb-6 font-bold text-gray-600">Serviços adicionais</h2>

            {selectedFormat === 'email' ? (
              <div className="text-gray-600 mb-5">
                Por enquanto, não oferecemos serviços adicionais para esse formato.
              </div>
            ) : (
              <>
                <Alert
                  type="error"
                  icon={<Icon name="calendar" />}
                  message={
                    <span className="text-2xs">Alguns serviços podem aumentar o prazo de entrega.</span>
                  }
                  className="mb-7"
                />

                <SelectExtras />
              </>
            )}
          </div>

          <SubmitButton autoFocus loading={loading || multipleItemsLoadingPrice} />
        </Form>
      </div>
    </div>
  )
}
