import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'

import { RoutePaths } from 'app/routes'
import { Empty } from 'ui'

import EmptyKits from 'assets/images/empty-state-kits.png'

export default function KitsEmpty() {
  const history = useHistory()

  return (
    <Empty
      heading="Ainda não há kits criados"
      description="Kits são formas inteligentes de criar um pedido composto de documentos. Comece agora mesmo."
      image={EmptyKits}
    >
      <Button type="primary" ghost onClick={() => history.push(RoutePaths.CREATE_KIT)} className="min-w-56">
        Criar novo kit
      </Button>
    </Empty>
  )
}
