import React, { useState } from 'react'
import { Form } from 'antd'

import { getService } from 'domain/servicesCBRdoc'
import { Section } from 'layouts'
import { useCreateOrderState } from 'domain/createOrder/document/state'
import { SubmitButton } from 'ui'

import { useAddDueDiligenceToCart, useCreateDueDiligenceNextStep } from '../services'
import Document from './Document'
import ProcessTypeRadio from './ProcessTypeRadio'
import { RoutePaths } from 'app/routes'
import { useHistory } from 'react-router-dom'
import { DiligenceCode } from 'domain/servicesCBRdoc/types'
import { handleRequestError } from 'api/HandleRequestError'

function DataStep() {  
  const [form] = Form.useForm()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const { files, formData, selectedService } = useCreateOrderState()
  const isUploading = files?.some(f => f.status === 'uploading')
  const hasUploadError = files?.some(f => f.status === 'error')
  const { formFields } = getService(selectedService.code!)
  const goToNextStep = useCreateDueDiligenceNextStep('dados')  
  const addToCart = useAddDueDiligenceToCart()

  async function goToCart() {
    try {
      setLoading(true)

      await addToCart()

      history.push(RoutePaths.SHOPPING_CART)
    } catch (error: any) {
      setLoading(false)
      handleRequestError(error)
    } finally {
      setLoading(false)
    }

  }

  const onFinish = async () => {
    if (selectedService.code === DiligenceCode.DILIGENCIA_ATAS_NOTARIAIS) {
      await goToCart()
    } else {
      goToNextStep()
    }
  }

  return (
    <Section heading="Qual é a Diligência?">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
        className="pb-24"
        initialValues={formData}
      >
        {formFields.tipo_processo && <ProcessTypeRadio />}
        <Document />
        <SubmitButton loading={loading || isUploading} disabled={loading || isUploading || hasUploadError} />
      </Form>
    </Section>
  )
}

export default DataStep
