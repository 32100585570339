import React from 'react'
import { Checkbox, Tag } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

import { getService } from 'domain/servicesCBRdoc'
import { ServiceAPI } from 'domain/servicesCBRdoc/types'
import { Tag as UiTag } from 'ui'

import { useCreateKit, useCreateKitAddItem, useCreateKitRemoveService } from './CreateKitContext'

interface Props {
  categoryId: number
  service: ServiceAPI
}

export default function CreateKitStep2Checkbox({ categoryId, service }: Props) {
  const { legalEntity, items } = useCreateKit()
  const addKitItem = useCreateKitAddItem()
  const removeService = useCreateKitRemoveService()

  const serviceLegalEntity = getService(service.code)?.legalEntity
  const isAvailable =
    !serviceLegalEntity || serviceLegalEntity === legalEntity || serviceLegalEntity === 'indefinido'

  const handleToggleItem = (e: CheckboxChangeEvent) => {
    // Add service
    if (e.target.checked) {
      addKitItem({
        service: {
          id: service.id,
          code: service.code,
          name: service.name,
          type: service.type,
          categoryId,
        },
      })
    } else {
      removeService(service.code, categoryId)
    }
  }

  return (
    <div className="flex justify-between">
      <Checkbox
        checked={items.some(
          item => item.service.code === service.code && item.service.categoryId === categoryId
        )}
        onChange={handleToggleItem}
        disabled={!isAvailable}
      >
        {service.name}
      </Checkbox>

      <div>
        {service.instant_delivery && (
          <UiTag className="ml-2 mr-1">
            Entrega imediata
          </UiTag>
        )}

        {!isAvailable && (
          <Tag title="Tipo de pessoa incompatível com o kit" color="gold">
            Apenas pessoa {serviceLegalEntity === 'fisica' ? 'física' : 'jurídica'}
          </Tag>
        )}
      </div>
    </div>
  )
}
