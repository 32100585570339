import React, { useState } from 'react'
import { Form, message, Spin } from 'antd'
import Axios from 'axios'
import { useQuery } from 'react-query'

import { useHasPermission } from 'domain/auth'
import { useUpdateCustomerProfile } from 'domain/customers'
import { useCurrentCustomer } from 'domain/customers'
import { ProfileForm } from 'domain/customers/types'
import { NoAdminPermission } from 'domain/users'
import {
  CNPJInput,
  CompanyNameInput,
  CPFInput,
  EmailInput,
  FederativeUnitSelect,
  FullNameInput,
  PhoneInput,
  ScrollableErrorAlert,
  SubmitButton,
  TextInput,
  ZipCodeInput,
} from 'ui'

export default function ProfilePage() {
  const isAdmin = useHasPermission('canManageUsersAndCompanyData')
  const customer = useCurrentCustomer()
  const { updateCustomerProfile, error } = useUpdateCustomerProfile()
  const [cep, setCep] = useState('')
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const { isFetching } = useQuery(
    ['address-via-cep', cep],
    async () => {
      const response = await Axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      return response.data
    },
    {
      enabled: Boolean(cep && cep.trim().length === 8),
      onSuccess: data => {
        if (data) {
          form.setFieldsValue({
            address: data.logradouro,
            addressCity: data.localidade,
            addressState: data.uf,
            addressNeighborhood: data.bairro,
            addressNumber: '',
            addressComplement: '',
          })
        }
      },
    }
  )

  const onSubmit = async (formData: ProfileForm) => {
    setLoading(true)

    await updateCustomerProfile(formData, () => {
      message.success('Dados atualizados com sucesso.')
    })

    setLoading(false)
  }

  if (!isAdmin) return <NoAdminPermission />
  if (!customer.id) return null

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      scrollToFirstError
      className="md:max-w-xl lg:max-w-3xl"
      onFinish={onSubmit}
      form={form}
      initialValues={customer}
    >
      {error && <ScrollableErrorAlert error={error} />}

      {customer.entityType === 'juridica' ? (
        <>
          <CNPJInput style={style35} disabled />
          <CompanyNameInput name="companyName" label="Razão social" style={style65} disabled />
        </>
      ) : (
        <>
          <CPFInput style={style35} disabled />
          <FullNameInput label="Nome" name="contactName" style={style65} required disabled />
        </>
      )}

      <EmailInput disabled style={style65} />

      <PhoneInput label="Telefone" name="phone" style={style35} defaultValue={customer.phone} required />

      <ZipCodeInput
        name="addressZipcode"
        label="CEP"
        required
        rules={[
          {
            validator: (_: any, value: string) => {
              if (!/\d{2}[.\s]?\d{3}[-.\s]?\d{3}$/.test(value)) {
                return Promise.reject('Informe um CEP válido')
              }
              return Promise.resolve()
            },
          },
        ]}
        onChange={(value: string) => {
          if (value.length === 8) {
            setCep(value)
          }
        }}
        style={style35}
      />

      <Spin spinning={isFetching}>
        <TextInput label="Endereço" name="address" maxLength={120} required style={style65} />

        <TextInput label="Número" name="addressNumber" style={style35} maxLength={8} required />

        <TextInput label="Complemento" style={style65} name="addressComplement" maxLength={60} />

        <TextInput label="Bairro" required maxLength={80} name="addressNeighborhood" />

        <TextInput label="Cidade" required maxLength={120} style={style65} name="addressCity" />

        <FederativeUnitSelect name="addressState" required style={style35} labelInValue={false} />
      </Spin>

      <SubmitButton label="Atualizar" loading={loading} />
    </Form>
  )
}

const style35 = { display: 'inline-block', width: 'calc(35% - 10px)', marginRight: '8px' }
const style65 = { display: 'inline-block', width: 'calc(65% - 10px)', marginRight: '8px' }
