import { getStorageInt, setStorageInt } from 'utils/localStorage'

const LATEST_VERSION_STORAGE = 'latest-app-version'

export function getAppVersion() {
  return getStorageInt(LATEST_VERSION_STORAGE, 0)
}

export function setAppVersion(version: number) {
  return setStorageInt(LATEST_VERSION_STORAGE, version)
}

export function getDisplayAppVersion() {
  const latestReleaseTimestamp = getAppVersion()

  if (!latestReleaseTimestamp) {
    return '0.1.0'
  }

  const date = new Date(latestReleaseTimestamp)
  const month = date.getMonth()
  const day = date.getDate()
  const hour = date.getHours()
  const minutes = date.getMinutes()

  return `${month}.${day}.${hour}${minutes}`
}
