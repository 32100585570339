import React from 'react'
import { Button, ButtonProps } from 'antd'

import Icon, { IconName } from 'ui/Icon/Icon'

interface Props extends Omit<ButtonProps, 'icon' | 'type' | 'block' | 'ghost' | 'size'> {
  icon: IconName
}

function LargeButton({ children, icon, className, ...props }: Props, ref: any) {
  return (
    <Button
      {...props}
      type="primary"
      block
      ghost
      ref={ref}
      icon={<Icon name={icon} size={18} />}
      className={`flex gap-2 items-center justify-center ${className}`}
    >
      <span className="mt-1">{children}</span>
    </Button>
  )
}

export default React.forwardRef(LargeButton)
