import { Icon } from 'ui'

type Props = { name: string; color: string }

export default function GroupTag({ name, color }: Props) {
  return (
    <div className="flex items-center gap-2">
      <Icon name="tags" className="text-xl" />
      <span className="font-bold text-sm flex items-center justify-center gap-1">
        <div className="w-1 h-4 mb-1" style={{ backgroundColor: color }} />
        {name}
      </span>
    </div>
  )
}
