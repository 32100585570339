import { Button, Popconfirm } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Routes } from 'app/routes'
import { useGetKit } from 'domain/createOrder/kit/state'
import { SimilarOrder } from 'domain/createOrder/types'
import { KitItem } from 'domain/kits/types'
import { Modal } from 'ui'

type Props = {
  addToCart: (itemsToAdd: KitItem[]) => void
  loading?: boolean
  similarOrders: SimilarOrder[]
  open: boolean
  clearOnCancel?: boolean
  onClose: () => void
  onResetOrRedirect?: () => void
}

export default function KitSimilarOrdersModal({
  addToCart,
  loading,
  similarOrders,
  open,
  onClose,
  onResetOrRedirect,
}: Props) {
  const kit = useGetKit()
  const [similarOrdersToAdd, setSimilarOrdersToAdd] = useState<SimilarOrder[]>([])

  const onCancel = () => {
    onResetOrRedirect && onResetOrRedirect()
    onClose()
  }

  const onContinue = () => {
    const idsOfSimilarOrdersToAdd = similarOrdersToAdd.map(({ kitItemId }) => kitItemId!)
    const itemsToAdd = kit.items.filter(item => idsOfSimilarOrdersToAdd.includes(item.id))

    addToCart(itemsToAdd)
    onClose()
  }

  useEffect(() => {
    setSimilarOrdersToAdd(similarOrders)
  }, [similarOrders])

  return (
    <Modal open={open} title="Pedido já Realizado" onCancel={onCancel} destroyOnClose>
      <div>
        <p className="mb-4">Você já possui pedidos com os mesmos dados.</p>

        {similarOrdersToAdd.map(({ id, orderName }) => (
          <div key={id} className="flex justify-between items-center mb-4">
            <span className="text-secondary-500 font-bold mr-3">
              <Link className="hover:underline text-secondary-300" to={Routes.ViewOrder(id!)} target="_blank">
                &bull; #{id} -
              </Link>{' '}
              {orderName}
            </span>

            <Popconfirm
              title="Remover o item"
              description="O item removido não será adicionado ao carrinho."
              onConfirm={() => {
                setSimilarOrdersToAdd(prev => prev.filter(item => item.id !== id))
              }}
              okText="Sim"
              cancelText="Não"
            >
              <Button type="primary" size="small" ghost>
                Remover
              </Button>
            </Popconfirm>
          </div>
        ))}

        <div className="mt-4 flex flex-col gap-2">
          <Button
            type="primary"
            block
            tabIndex={1}
            onClick={onContinue}
            loading={loading}
            disabled={loading}
            autoFocus
            size="large"
          >
            {similarOrdersToAdd.length > 0 ? 'Adicionar ao carrinho' : 'Continuar'}
          </Button>

          <Popconfirm
            title="Remover todos os similares"
            description="Os itens removidos não serão adicionados ao carrinho."
            onConfirm={() => {
              setSimilarOrdersToAdd([])
              onCancel()
            }}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="primary" ghost>
              Remover similares e continuar
            </Button>
          </Popconfirm>
        </div>
      </div>
    </Modal>
  )
}
