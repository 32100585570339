import React from 'react'

import { SearchInvoice } from 'domain/invoices/components'
import { useResetTableSelection, useSetSearch } from 'domain/invoices/state'

function PrepaidInvoicesFiltersSearch() {
  const resetSelected = useResetTableSelection()
  const setSearch = useSetSearch()

  return (
    <SearchInvoice
      onSearch={value => {
        setSearch(value)
        resetSelected()
      }}
    />
  )
}

export default PrepaidInvoicesFiltersSearch
