import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import queryString from 'query-string'

import { Loading } from 'ui'
import useLoginWithToken from '../services/useLoginWithToken'

let isRedirecting = false

export default function OAuthRedirect() {
  const location = useLocation()
  const loginWithToken = useLoginWithToken()

  useEffect(() => {
    if (location.hash && isRedirecting === false) {
      isRedirecting = true

      const oauthResponse = queryString.parse(location.hash)

      // Google
      if (oauthResponse.access_token) {
        loginWithToken(oauthResponse.access_token as string)
      }
    }
  }, [])

  return <Loading />
}
