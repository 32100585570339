import { CanAutoPurchaseResearchOption, Customer, CustomerAPI } from '../types'

export default function convertCustomer({
  id,
  backoffice_email,
  corporate_name,
  document_number,
  name,
  phone,
  entity_type,
  address_public_place,
  address_number,
  address_complement,
  address_neighborhood,
  address_city,
  address_uf,
  address_zip_code,
  postpaid,
  explorer_item_file_upload_enabled,
  ai_enabled,
  max_users,
  users_count,
  group_mandatory_on_purchase,
  demands_approval_purchases_over_value,
  auto_purchase_certificate_from_research_result_available,
  auto_purchase_certificate_from_research_result_default,
  analytics_enabled_until,
  show_shipping_info_on_orders,
}: CustomerAPI) {
  const customer: Customer = {
    id,
    companyName: corporate_name,
    contactPerson: name,
    cpf: entity_type === 'PF' ? document_number : undefined,
    cnpj: entity_type === 'PJ' ? document_number : undefined,
    email: backoffice_email,
    entityType: entity_type === 'PF' ? 'fisica' : 'juridica',
    phone,
    address: address_public_place,
    addressNumber: address_number,
    addressComplement: address_complement,
    addressNeighborhood: address_neighborhood,
    addressCity: address_city,
    addressState: address_uf,
    addressZipcode: address_zip_code,
    isPostpaid: postpaid,
    permissions: {
      canUploadFiles: explorer_item_file_upload_enabled,
      canUseAI: ai_enabled === 1,
      autoPurchaseFromResearch: getCanAutoPurchaseFromResearch(
        auto_purchase_certificate_from_research_result_available,
        auto_purchase_certificate_from_research_result_default
      ),
    },
    maxUsers: max_users,
    usersCount: users_count || 0,
    orderGroupRequired: group_mandatory_on_purchase,
    valueForOrderApproval: demands_approval_purchases_over_value || 0,
    analyticsEnabled: analytics_enabled_until !== null,
    analyticsUntil: analytics_enabled_until !== null ? new Date(analytics_enabled_until) : null,
    showShippingInfoOnOrders: show_shipping_info_on_orders,
  }

  return customer
}

const getCanAutoPurchaseFromResearch = (availableToCustomer: 0 | 1, defaultValue: 0 | 1) => {
  if (!availableToCustomer) {
    return CanAutoPurchaseResearchOption.NO
  }

  if (defaultValue === 1) {
    return CanAutoPurchaseResearchOption.YES_DEFAULT
  }

  return CanAutoPurchaseResearchOption.YES
}
