import React from 'react'

import Icon from 'ui/Icon/Icon'
import { NotificationTypesConfig } from '../data'
import { NotificationType as Type } from '../types'

function NotificationType({ type }: { type: Type }) {
  return (
    <Icon
      name={NotificationTypesConfig[type].icon}
      className={`${NotificationTypesConfig[type].color} mt-1`}
    />
  )
}

export default NotificationType
