import React from 'react'

import { Address } from 'domain/orders/types'
import { Alert, Icon } from 'ui'

interface Props {
  address: Address
}

export default function OrderSuccessfulAddress({ address }: Props) {
  return (
    <Alert
      type="info"
      icon={<Icon name="pin-map" className="text-gray-600" strokeWidth={2} width={48} height={48} />}
      className="max-w-md"
      message={
        <>
          Conforme solicitado as certidões no formato <span className="font-bold">Papel</span> serão
          entregues no seguinte endereço: {getFormattedShippingAddress(address)}
        </>
      }
    />
  )
}

const getFormattedShippingAddress = (shippingAddress: Address) => {
  const addressInfo = [
    shippingAddress.number,
    shippingAddress.complement,
    shippingAddress.neighborhood,
    shippingAddress.city,
    shippingAddress.uf,
  ]
    .filter(Boolean)
    .join(', ')

  const zipcode = shippingAddress.zipCode ? ' - CEP:' + shippingAddress.zipCode : ''

  return `${shippingAddress.street} ${addressInfo} ${zipcode}`
}
