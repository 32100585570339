import { Checkbox, Form } from 'antd'
import { useState } from 'react'

import CreateOrderRGIOutorganteForm from './CreateOrderRGIPartForm'
import RGICollapse from './RGICollapse'

type Props = {
  activeItem: number | undefined
  setActiveItem: (value: number | undefined) => void
  isSubmitting: boolean | undefined
}

export default function CreateOrderRGIOutorgados({ activeItem, setActiveItem, isSubmitting }: Props) {
  const [doesNotHave, setDoesNotHave] = useState(false)

  return (
    <Form.List name="outorgados">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((item, index) => (
              <RGICollapse
                key={item.key}
                activeKey={activeItem}
                item={item}
                onChange={setActiveItem}
                description="Outorgado"
                index={index}
                totalItems={fields.length}
                isSubmitting={isSubmitting}
                form={
                  <>
                    <Form.Item name="nao_possui" valuePropName="checked">
                      <Checkbox
                        value={doesNotHave}
                        onChange={e => setDoesNotHave(e.target.checked)}
                        className="text-secondary-500"
                      >
                        Não possui outorgado
                      </Checkbox>
                    </Form.Item>

                    {!doesNotHave && <CreateOrderRGIOutorganteForm itemId={index} />}
                  </>
                }
                add={add}
                remove={remove}
                disableAdd={doesNotHave}
              />
            ))}
          </>
        )
      }}
    </Form.List>
  )
}
