import { SimilarOrder } from '../../types'

class SimilarOrdersError extends Error {
  data: SimilarOrder[]

  constructor(similarOrders: SimilarOrder[]) {
    super('Pedido já realizado.')
    Object.setPrototypeOf(this, SimilarOrdersError.prototype)

    this.data = similarOrders
  }
}

export default SimilarOrdersError
