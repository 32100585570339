import React from 'react'

import Xerox from './Xerox'

function XeroxAutenticado() {
  return (
    <Xerox
      label="Xerox autenticado"
      description="Reprodução de um documento com mesmo valor legal dos originais."
      name="qtde_xerox_autenticado"
    />
  )
}

export default XeroxAutenticado
