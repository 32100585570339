import { atom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { globalScope as store } from 'domain/app/state/scope'
import { User } from 'domain/users/types'

export const initialAuthState: User = {
  accessToken: '',
  customerId: 0,
  email: '',
  id: 0,
  lastRequest: '',
  lastLogin: '',
  name: '',
  phone: '',
  permissions: [],
}

export const authAtom = atomWithStorage<User>(
  'auth',
  localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth') as string) : initialAuthState
)

const resetAuthAtom = atom(null, (_, set) => {
  set(authAtom, initialAuthState)
})

export const useCurrentUser = () => useAtomValue(authAtom, { store })
export const useSetAuth = () => useSetAtom(authAtom, { store })
export const useRemoveAuth = () => useSetAtom(resetAuthAtom, { store })

export const isAuthenticatedAtom = atom(get => {
  const user = get(authAtom)
  return !!user.accessToken
})

export const useGetIsAuthenticated = () => useAtomValue(isAuthenticatedAtom, { store })
