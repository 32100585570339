import React from 'react'
import { Button, ButtonProps } from 'antd'

import Icon from 'ui/Icon/Icon'

function DropdownActionsButton(props: ButtonProps) {
  return (
    <Button type="primary" ghost size="small" icon={<Icon name="ellipsis" />} {...props} />
  )
}

export default DropdownActionsButton
