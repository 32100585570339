import { useHistory } from 'react-router-dom'

import { Routes } from 'app/routes'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { useCreateOrderState } from 'domain/createOrder/document'
import { useCallback } from 'react'

function useCreateDueDiligenceNextStep(currentStep: string) {
  const history = useHistory()
  const { selectedService } = useCreateOrderState()
  const serviceCode = selectedService.code!

  const goToNextStep = useCallback(function() {
    const index = steps.findIndex(step => step === currentStep)
    const nextStep = steps[index + 1]

    history.push(Routes.CreateDueDiligenceStep(nextStep))
  }, [serviceCode])

  return goToNextStep
}

const steps = ['localizacao', 'dados', 'extras']

export default useCreateDueDiligenceNextStep
