import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { addDateByMonths, formatYearAndMonth, subtractByMonths } from 'utils/dateTime'

export default function useYearAIOrdersChartQuery() {
  return useQuery(
    ['orders-per-month-ai'],
    async () => {
      const lastYear = subtractByMonths(new Date(), 12)
      const endOfMonth = addDateByMonths(new Date(), 1)

      const response: AxiosResponse<any> = await AuthorizedAPI.get('/reports/number-ai-analysis-per-month', {
        params: {
          begin_date: formatYearAndMonth(lastYear),
          end_date: formatYearAndMonth(endOfMonth),
        },
      })
      return response.data
    }
  )
}
