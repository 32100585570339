import { LegalEntity } from 'domain/app/types'
import { RadioGroupInput, RadioGroupInputProps } from 'ui'
import { NamePath } from 'antd/lib/form/interface'

import { FieldName } from '../types'

export interface TabsLegalEntityProps extends RadioGroupInputProps {
  name?: NamePath
  selected?: LegalEntity
  isDisabledPJ?: boolean
}

export default function EntityTypeTabs({
  name = FieldName.TIPO_PESSOA,
  isDisabledPJ = false,
  ...props
}: TabsLegalEntityProps) {
  const options = [
    {
      label: 'Pessoa',
      value: 'fisica',
    },
    {
      label: 'Empresa',
      value: 'juridica',
      disabled: isDisabledPJ,
    },
  ]

  return (
    <RadioGroupInput
      name={name}
      options={options}
      className="grid grid-cols-2 text-center font-bold"
      {...props}
    />
  )
}
