import { OrderItemListing } from 'domain/orderItem/types'

import OrderDetailsInfoTag from '../ordersList/OrderInfoTag'

interface OrderDetailsInfoProps {
  item: OrderItemListing
}

const OrderDetailsInfo = ({ item }: OrderDetailsInfoProps) => {
  return (
    <div className="flex flex-row flex-wrap gap-1">
      {item?.isExpired && <OrderDetailsInfoTag>Vencido</OrderDetailsInfoTag>}
      {item.recurrence && <OrderDetailsInfoTag>Recorrente</OrderDetailsInfoTag>}
      {!item?.isExpired && !!item.validUntil && <OrderDetailsInfoTag>Válido</OrderDetailsInfoTag>}
      {item.hasAiExtractData && <OrderDetailsInfoTag>Dados extraídos</OrderDetailsInfoTag>}
      {item.hasAiAnalysisPending && <OrderDetailsInfoTag>Dados sendo analisados pela IA</OrderDetailsInfoTag>}
      {!!item.extractSummary && <OrderDetailsInfoTag>Ficha do documento</OrderDetailsInfoTag>}
    </div>
  )
}

export default OrderDetailsInfo
