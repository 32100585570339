import React from 'react'

export interface PageHeaderProps {
  children?: React.ReactNode
  title?: React.ReactNode
  subTitle?: React.ReactNode
  extra?: React.ReactNode
  alwaysShowExtra?: boolean
  footer?: React.ReactNode
  className?: string
}

export default function PageHeader({
  children,
  className = '',
  title,
  subTitle,
  extra,
  alwaysShowExtra,
  footer,
}: PageHeaderProps) {
  return (
    <div className={`text-secondary-500 bg-gray-100 p-7 relative ${className}`}>
      <div
        className={`flex ${alwaysShowExtra ? 'flex-row align-center' : 'flex-col'} md:flex-row align-center`}
      >
        <div className="flex flex-col justify-center w-full">
          {title && (
            <h1 className="leading-7 text-xl font-bold truncate w-full max-w-lg">{title}</h1>
          )}
          {subTitle && <h2 className="text-sm font-normal flex-row md:flex-col">{subTitle}</h2>}
        </div>
        <div className={`flex-1 justify-end ${alwaysShowExtra ? 'flex' : 'hidden'} md:flex`}>{extra}</div>
      </div>
      {footer && <div className="pt-3 hidden md:block">{footer}</div>}
      {children}
    </div>
  )
}
