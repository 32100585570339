import React, { useReducer } from 'react'

import { ServiceLink, ServiceLinkProps, useSelectServiceClick } from 'domain/createOrder/shared'
import { getService } from 'domain/servicesCBRdoc'
import ResearchExampleModal from './ResearchExampleModal'
import { TextButton } from 'ui'

interface Props extends Omit<ServiceLinkProps, 'onClick'> {
  categoryId?: number
}

export default function ResearchServiceLink({ service, categoryId }: Props) {
  const [isModalOpen, toggleModal] = useReducer(prev => !prev, false)
  const config = getService(service.code)
  const onClick = useSelectServiceClick(service, categoryId)

  return (
    <>
      <ServiceLink
        service={service}
        description={config?.description}
        onClick={onClick}
        bold
        isNew={config?.displayIsNewTag}
      >
        <TextButton
          label="Ver exemplo"
          className="mt-3 underline text-sm"
          onClick={(ev) => {
            ev.stopPropagation()
            toggleModal()
          }}
        />
      </ServiceLink>

      <ResearchExampleModal
        open={isModalOpen}
        onCancel={toggleModal}
        service={service}
        onSelectService={onClick}
        exampleHTMLContent={service.example_html_content}
        exampleFileURL={service.example_file_url}
      />
    </>
  )
}
