import { Form, Select } from 'antd'
import { requiredRule } from 'app/config/validationRules'

import { convertToSelectedService } from 'domain/createOrder/document'
import { KitLegalEntity } from 'domain/kits/types'
import { SelectedService } from 'domain/orderItem/types'
import { useCategoriesContext } from 'domain/servicesCBRdoc'
import { getService } from 'domain/servicesCBRdoc'
import { Icon } from 'ui'
import { normalizeString } from 'utils/formatters'

interface Props {
  allowClear?: boolean
  label?: string
  name?: string
  autoFocus?: boolean
  kitLegalEntity: KitLegalEntity
  onChange: (service: SelectedService) => void
  required?: boolean
}

const { Option, OptGroup } = Select

export default function DocumentSelect({
  label,
  allowClear,
  kitLegalEntity,
  onChange,
  name = 'service',
  autoFocus,
  required
}: Props) {
  const categories = useCategoriesContext()

  const onChangeService = (value: string) => {
    const data = value.split('_')
    const categoryId = parseInt(data[0])
    const serviceId = parseInt(data[1])
    const category = categories!.find(o => o.id === categoryId)!
    const service = category.services.find(o => o.id === serviceId)!

    onChange(convertToSelectedService(service, category.id) as SelectedService)
  }

  return (
    <Form.Item label={label} name={name} rules={required ? requiredRule : undefined}>
      <Select
        optionFilterProp="children"
        filterOption={(input, option) =>
          normalizeString((option as any)?.children).indexOf(normalizeString(input)) >= 0
        }
        onChange={onChangeService}
        className="block"
        placeholder="Selecione o Documento"
        showSearch
        allowClear={allowClear}
        autoFocus={autoFocus}        
      >
        {categories?.map(category => {
          return (
            <OptGroup
              key={category.id}
              label={
                <>
                  <span className="inline-block align-middle">
                    <Icon name={category.code as any} className="text-lg mr-2" />{' '}
                  </span>
                  <span className="text-secondary-500 font-bold inline-block align-middle">
                    {category.name}
                  </span>
                </>
              }
            >
              {category.services.map(service => {
                const serviceLegalEntity = getService(service.code)?.legalEntity
                const isAvailable =
                  !serviceLegalEntity ||
                  serviceLegalEntity === kitLegalEntity ||
                  serviceLegalEntity === 'indefinido'

                return (
                  <Option
                    key={`${category.id}_${service.code}`}
                    value={`${category.id}_${service.id}`}
                    disabled={!isAvailable}
                    title={
                      !isAvailable
                        ? `Disponível apenas para pessoa ${
                            serviceLegalEntity === 'fisica' ? 'física' : 'jurídica'
                          }`
                        : undefined
                    }
                  >
                    {service.name}
                  </Option>
                )
              })}
            </OptGroup>
          )
        })}
      </Select>
    </Form.Item>
  )
}
