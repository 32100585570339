import React from 'react'
import { Tag } from 'antd'

import { StatusCode, OrderStatuses } from 'domain/orders/types'

interface StatusTagProps {
  statusCode: StatusCode
  children?: React.ReactNode
}

function getTagColor(status: StatusCode) {
  if (status == StatusCode.FINALIZADO) return 'bg-success-200 text-success-700'
  if (status == StatusCode.ACAO_NECESSARIA) return 'bg-yellow-400 text-error'
  if (status == StatusCode.INDISPONIVEL) return 'bg-yellow-400 text-error'
  if (status == StatusCode.CANCELADO) return 'bg-red-300 text-red-500'
  if (status == StatusCode.AGUARDANDO_PAGAMENTO) return 'bg-gray-350 text-gray-700'
  if (status == StatusCode.AGUARDANDO_RESPOSTA) return 'bg-gray-350 text-gray-700'
  if (status == StatusCode.AGUARDANDO_APROVACAO) return 'bg-gray-350 text-gray-700'

  return 'bg-blue-100 text-blue-500'
}

function OrderStatusTag({ statusCode, children }: StatusTagProps) {
  const status = OrderStatuses[statusCode]

  if (!status) return null

  const statusColors = getTagColor(statusCode)

  return (
    <Tag bordered={false} className={`${statusColors} py-1 px-2`}>
      {children} {status.name}
    </Tag>
  )
}

export default OrderStatusTag
