import React from 'react'

import { useResetTableSelection, useOperation } from 'domain/invoices/state'
import { Operation } from 'domain/invoices/types'
import { ToggleButton } from 'ui'

function PrepaidInvoicesFiltersOperation() {
  const resetSelected = useResetTableSelection()
  const [operation, setOperation] = useOperation()

  const toggleStatusFilter = (active: boolean, selected: Operation) => {
    let selectedOperation: Operation = ''

    if (!operation) {
      selectedOperation = active ? selected : selected === 'C' ? 'D' : 'C'
    }

    setOperation(selectedOperation)
    resetSelected()
  }

  return (
    <div className="flex-1">
      <div className="text-gray-600 font-bold text-4xs uppercase mt-4 pb-1">Filtrar pedidos por:</div>
      <div>
        <ToggleButton onToggleActive={active => toggleStatusFilter(active, 'C')} className="mr-2 mb-2">
          Créditos
        </ToggleButton>
        <ToggleButton onToggleActive={active => toggleStatusFilter(active, 'D')} className="mb-2">
          Pedidos
        </ToggleButton>
      </div>
    </div>
  )
}

export default PrepaidInvoicesFiltersOperation
