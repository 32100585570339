import { ResearchFlow } from 'domain/createOrder/researches'
import { MaskedField } from 'domain/servicesCBRdoc/fields'

import config from '../pesquisaCarConfig'

export default function CreateOrderPesquisaCAR() {
  return <ResearchFlow renderFormItem={(itemId: number) => <PesquisaCARForm itemId={itemId} />} />
}

function PesquisaCARForm({ itemId }: { itemId: number }) {
  return <MaskedField field={config.formFields.car} itemId={itemId} autoFocus={itemId === 0} />
}
