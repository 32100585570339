import { CertificateFlow } from 'domain/createOrder/certificates'
import { TextField } from 'domain/servicesCBRdoc/fields'
import config from '../certidaoCafirConfig'

export default function CreateOrderCertidaoCafir() {
  return <CertificateFlow renderFormItem={(itemId: number) => <CertidaoCafirForm itemId={itemId} />} />
}

const CertidaoCafirForm = ({ itemId }: { itemId: number }) => {
  return <TextField autoFocus={itemId === 0} field={nirf} itemId={itemId} />
}

const {
  formFields: { nirf },
} = config
