import { useDispatch } from 'react-redux'
import { Form } from 'antd'

import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs, useLoadExtraInfos } from 'domain/createOrder/document'
import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document/state'
import { AllExtraInfos } from 'domain/orderItem/types'
import { CNPJAndNameFields, ExtraInfoFields, NameField, SelectField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../certidaoTRFDistribuicaoConfig'

export default function CreateOrderCertidaoTRFDistribuicao() {
  const { data, isFetching } = useLoadExtraInfos()

  return (
    <CertificateFlow
      header={<CertidaoTRFHeader />}
      renderFormItem={(itemId: number) => (
        <CertidaoTRFDistribuicaoForm itemId={itemId} extraInfos={data} loading={isFetching} />
      )}
    />
  )
}

function CertidaoTRFHeader() {
  const dispatch = useDispatch()
  const form = Form.useFormInstance()

  return (
    <>
      <p className="mb-5 text-gray-600">
        Primeiro selecione a região do Tribunal Federal, depois selecione Pessoa ou Empresa e digite o número
        do CPF ou CNPJ que deseja a Certidão de Distribuição.
      </p>

      <SelectField
        field={regiao}
        onChange={(selected: string) => {
          form.setFieldsValue({
            [tipo.name]: undefined,
            [orgao.name]: undefined,
            [filtro.name]: undefined,
            [instancia.name]: undefined,
          })

          dispatch(createOrderActions.setSelectedRegion(selected))
        }}
        autoFocus
      />
    </>
  )
}

interface Props {
  itemId: number
  loading: boolean
  extraInfos: AllExtraInfos | undefined
}

const CertidaoTRFDistribuicaoForm = ({ itemId, extraInfos, loading }: Props) => {
  const { selectedService } = useCreateOrderState()

  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={
          <>
            <CPFInput itemId={itemId} />
            <NameField itemId={itemId} field={nome_pf} />
          </>
        }
        juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} />}
      />

      <ExtraInfoFields
        serviceCode={selectedService.code!}
        data={extraInfos}
        loading={loading}
        itemId={itemId}
      />
    </>
  )
}

const { nome_pf, nome_pj, orgao, regiao, tipo } = config.formFields
const { filtro, instancia } = config.dynamicFields!
