import { SelectLocationFields } from 'domain/createOrder/document'
import { ResearchFlow } from 'domain/createOrder/researches'
import { CNPJAndNameFields, MaskedField } from 'domain/servicesCBRdoc/fields'

import config from '../pesquisaSintegraConfig'

export default function CreateOrderPesquisaSintegra() {
  return (
    <ResearchFlow
      header={<SelectLocationFields autoFocus />}
      renderFormItem={itemId => <PesquisaSintegraForm itemId={itemId} />}
    />
  )
}

const PesquisaSintegraForm = ({ itemId }: { itemId: number }) => {
  return (
    <>
      <CNPJAndNameFields itemId={itemId} companyField={nome_pj} />
      <MaskedField itemId={itemId} field={inscricao_estadual} required />
    </>
  )
}

const { inscricao_estadual, nome_pj } = config.formFields
