import { useMutation } from 'react-query'
import { message } from 'antd'

import { errorHandler } from 'api/errors'
import { useExplorerItem } from 'domain/myFiles/state'

import { editExplorerItemService } from './api'

export default function useMyFileDocumentTypeMutation() {
  const [explorerItem, setExplorerItem] = useExplorerItem()

  return useMutation(
    async (serviceId: number) =>
      await editExplorerItemService(explorerItem!.id, serviceId),
    {
      onError: error => {
        errorHandler(error, { code: '0x705' })
      },
      onSuccess: (data, serviceId: number) => {
        setExplorerItem({ ...explorerItem, serviceId })
        message.success('Serviço alterado com sucesso.')
      },
    }
  )
}
