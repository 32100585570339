import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { RoutePaths, Routes } from 'app/routes'
import { useIsCustomerPostPaid } from 'domain/customers'
import { useCreateOrderState } from 'domain/createOrder/document/state'

import { useCreateOrderSteps } from '../state'

export default function useCreateOrderNextStep(currentStep: string): [() => void, string] {
  const { selectedService } = useCreateOrderState()
  const serviceCode = selectedService.code!
  const history = useHistory()
  const steps = useCreateOrderSteps()
  const isPostPaid = useIsCustomerPostPaid()

  const nextStep = useMemo(() => {
    const validSteps = Object.entries(steps)
      .filter(([, show]) => show)
      .map(([step]) => step)

    const index = validSteps.findIndex(step => step === currentStep)
    const nextStep = validSteps[index + 1] ?? 'cart'
    const skipStep = nextStep === 'entrega' && isPostPaid

    return skipStep ? 'cart' : nextStep
  }, [steps, isPostPaid])

  const goToNextStep = useCallback(() => {
    const goTo =
      nextStep === 'cart' ? RoutePaths.SHOPPING_CART : Routes.CreateOrderStep(serviceCode, nextStep)
    history.push(goTo)
  }, [serviceCode, nextStep])

  return [goToNextStep, nextStep]
}
