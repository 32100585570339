import React from 'react'
import { MenuProps } from 'antd'

import { useHasPermission } from 'domain/auth'
import { useDeleteExplorerItem } from 'domain/myFiles/services'
import { useSetAction, useSetExplorerItem } from 'domain/myFiles/state'
import { ExplorerItem } from 'domain/myFiles/types'
import { DropdownActions, Icon } from 'ui'

interface Props {
  explorerItem: ExplorerItem
  setSelectedRowKeys: any
  setSelectedItems: any
}

function MyFilesInlineActions({
  explorerItem,
  setSelectedRowKeys,
  setSelectedItems,
}: Props) {
  const canShareOrders = useHasPermission('canShareOrders')
  const setAction = useSetAction()
  const setExplorerItem = useSetExplorerItem()
  const { deleteExplorerItem, permissionError } = useDeleteExplorerItem(explorerItem)

  const renameOption = {
    key: 'rename',
    label: 'Renomear',
    icon: <Icon name="edit" />,
    onClick: () => {
      setExplorerItem(explorerItem)
      setAction('rename')
    },
  }

  const moveToOption = {
    key: 'move',
    label: 'Mover para',
    icon: <Icon name="folder-move" />,
    onClick: (ev: any) => {
      ev.domEvent.stopPropagation()
      setSelectedRowKeys([])
      setSelectedItems([])
      setExplorerItem(explorerItem)
      setAction('move')
    },
  }

  const isFolder = explorerItem.type === 'folder'

  const deleteOption = {
    key: 'delete',
    label: `Excluir ${isFolder ? 'pasta' : 'arquivo'}`,
    icon: <Icon name="trash" />,
    onClick: () => {
      deleteExplorerItem(explorerItem)
    },
    disabled: Boolean(permissionError),
    title: permissionError,
  }

  const folderItems: MenuProps['items'] = [renameOption, moveToOption, deleteOption]

  let filesItems: MenuProps['items'] = [    
    renameOption,
    {
      key: 'tags',
      label: 'Tags',
      onClick: () => {
        setExplorerItem(explorerItem)
        setAction('tags')
      },
      icon: <Icon name="tag" />,
    },
    moveToOption,    
  ]

  if (canShareOrders) {
    filesItems = [
      {
        key: 'share',
        label: 'Compartilhar',
        icon: <Icon name="share" />,
        onClick: () => {
          setExplorerItem(explorerItem)
          setAction('share')
        },
        disabled: !explorerItem.order,
      },
      ...filesItems,
    ]
  }

  if (!explorerItem.order) {
    filesItems.push(deleteOption)
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <DropdownActions
        placement="bottomRight"
        menu={{ items: isFolder ? folderItems : filesItems }}
        trigger={['click']}
      />
    </div>
  )
}

export default MyFilesInlineActions
