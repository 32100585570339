import { Query, QueryCache, QueryClient } from 'react-query'

import { errorHandler } from 'api/errors'

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any, query: Query) => {
      if (error.response?.status && error.response.status > 500) {
        errorHandler(error, { params: { queryKey: query.queryKey, options: query.options } })
      } else {
        throw error
      }
    },
  }),
  defaultOptions: {
    queries: {
      cacheTime: 15 * 60 * 1000, // 15 minutos
      retry: false,
      retryOnMount: false,
      refetchInterval : false,
      refetchOnWindowFocus: false,
      refetchOnReconnect : false,
    },
  },
})

export default queryClient
