import React from 'react'
import { Empty as AntdEmpty } from 'antd'

interface Props {
  heading?: string
  description?: string
  children?: React.ReactNode
  image?: React.ReactNode | string
}

export default function Empty({ heading = 'Não há dados', description, image, children }: Props) {
  return (
    <div className="flex flex-1 m-4 justify-center items-center md:h-64 min-h-full">
      <AntdEmpty
        image={image}
        imageStyle={{
          height: '139px',
          marginBottom: '16px',
        }}
        description={
          <div className="md:max-w-sm lg:max-w-md flex flex-col justify-center items-center gap-3">
            <h1 className="text-2xl text-secondary-500 font-bold">{heading}</h1>

            {description && <p className="text-secondary-500 text-base font-normal mb-2">{description}</p>}

            {children}
          </div>
        }
      />
    </div>
  )
}
