import React from 'react'
import { Link } from 'react-router-dom'

import RoutePaths from 'app/routes/RoutePaths'

function AllNotificationsButton() {
  return (
    <Link to={RoutePaths.NOTIFICATIONS}>
      <span className="uppercase text-slate-500 hover:text-primary">Ver todas</span>
    </Link>
  )
}

export default AllNotificationsButton
