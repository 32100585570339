import './index.css'
import './tailwind.generated.css'

import React from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'

import App from './app/App'
import { msalInstance } from 'domain/auth/msaConfig'
// import reportVitals from './reportVitals'

const tagManagerArgs = {
  gtmId: 'GTM-NPM3S2B',
  dataLayerName: 'PageDataLayer',
}

TagManager.initialize(tagManagerArgs)

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <App microsoftInstance={msalInstance} />
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// reportVitals(console.log)
