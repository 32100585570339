import React from 'react'

import { KitItem, KitLegalEntity } from 'domain/kits/types'
import { LocationFields } from 'domain/servicesCBRdoc/fields'
import { SelectedLocation } from 'domain/servicesCBRdoc/types'

interface Props {
  item: KitItem
  legalEntity: KitLegalEntity | undefined
  onChange: (location: SelectedLocation | { tipo: any }) => void
}

function KitItemLocationFields({ item, legalEntity, onChange }: Props) {
  const { url_cartorio, url_cidade, url_uf, tipo, todos_cartorios_cidade } = item.defaultData ?? {}
  
  const onChangeType = (tipo: string) => {
    onChange({ tipo })
  }

  return (
    <LocationFields
      required={false}
      legalEntity={legalEntity}
      selectedService={item.service}
      selectedLocation={{ url_cartorio, url_cidade, url_uf, todos_cartorios_cidade } as SelectedLocation}
      selectedType={tipo}
      onChange={onChange}
      onChangeType={onChangeType}
    />
  )
}

export default KitItemLocationFields
