import React from 'react'

import { handleRequestError } from 'api/HandleRequestError'
import { OrderList } from 'domain/orders/types'
import { DrawerActions } from 'ui'
import { useDownload } from 'utils/export'

interface Props {
  selected: OrderList[]
}

function OrderMassExportExcel({ selected }: Props) {
  const [handleDownload, loading] = useDownload()

  async function handleExport() {
    try {
      const ordersIds = selected.map(({ id }) => id).join(',')
      await handleDownload(`purchases/${ordersIds}/report`, 'cbrdoc-pedidos-xlsx')
    } catch (err: any) {
      handleRequestError(err)
    }
  }
  return (
    <DrawerActions.Button disabled={selected.length === 0} loading={loading} onClick={handleExport} icon="file-xls">
      Exportar para Excel
    </DrawerActions.Button>
  )
}

export default OrderMassExportExcel
