import React from 'react'

import { useGetTableSelection, useResetTableSelection } from 'domain/notifications/state'
import { DrawerActions } from 'ui'

import NotificationMassDelete from './NotificationMassDelete'
import NotificationMassStatus from './NotificationMassStatus'

function NotificationsMassActions() {
  const selected = useGetTableSelection()
  const resetSelection = useResetTableSelection()

  return (
    <DrawerActions onClose={resetSelection} total={selected.rows.length}>
      <NotificationMassDelete />
      <NotificationMassStatus status="read" />
      <NotificationMassStatus status="unread" />
    </DrawerActions>
  )
}

export default NotificationsMassActions
