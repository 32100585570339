import { NamePath } from 'antd/es/form/interface'

import TextInput, { TextInputProps } from './TextInput'

interface Props extends Omit<TextInputProps, 'id' | 'label' | 'minLength' | 'name' | 'required' | 'rules'> {
  label?: string
  name: NamePath
}

export default function CompanyNameInput({
  name,
  label = 'Nome da empresa',
  placeholder = 'Nome completo',
  ...props
}: Props) {
  return (
    <TextInput
      name={name}
      label={label}
      rules={rules}
      id={name}
      placeholder={placeholder}
      maxLength={150}
      required
      {...props}
    />
  )
}

const rules = [
  {
    required: true,
    message: 'Informe o nome da Empresa.',
  },
  {
    min: 4,
    message: 'Insira o nome da empresa com no mínimo 4 caracteres.',
  },
  {
    validator: (_: object, value: string) => {
      const regExp = /[a-zA-Z]/g
      return regExp.test(value) ? Promise.resolve() : Promise.reject('Informe um nome válido.')
    },
  }
]
