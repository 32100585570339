import React from 'react'
import { Form } from 'antd'

import { KitItem, KitItemDefaultData, KitLegalEntity } from 'domain/kits/types'

import KitItemFormContent from './KitItemFormContent'

interface Props {
  kitLegalEntity: KitLegalEntity
  item: KitItem
  onChange: (data: Partial<KitItemDefaultData>) => void
  children?: React.ReactNode
  onFinish?: (data: any) => void
}

function KitItemForm({ kitLegalEntity, item, onChange, children, onFinish }: Props) {
  const [form] = Form.useForm()

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={item.defaultData}
      layout="vertical"
      requiredMark={false}
      className="max-w-lg"
    >
      <KitItemFormContent kitLegalEntity={kitLegalEntity} item={item} onChange={onChange} />
      {children}
    </Form>
  )
}

export default KitItemForm
