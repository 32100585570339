import { useHistory } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { useAddToCart } from 'domain/createOrder/shared/services'
import { useIsCustomerPostPaid } from 'domain/customers'
import { OrderItemPurchasable } from 'domain/orderItem/types'
import { RedoOrder } from 'domain/servicesCBRdoc'

function useRedoOrder(option?: 'fetchOrderItem' | undefined): (item: OrderItemPurchasable) => void {
  const history = useHistory()
  const addToCart = useAddToCart()
  const isPostPaid = useIsCustomerPostPaid()

  const redoOrder = async (orderItem: OrderItemPurchasable) => {
    const redo = new RedoOrder(orderItem, option === 'fetchOrderItem')
    const createOrder = await redo.generate(isPostPaid!)

    if (createOrder) {
      addToCart(createOrder)
    }

    history.push(RoutePaths.SHOPPING_CART)
  }

  return redoOrder
}

export default useRedoOrder
