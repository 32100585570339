import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { CategoryAPI, ServiceAPI, ServiceType } from 'domain/servicesCBRdoc/types'

function useAvailableServicesQuery(type: ServiceType) {
  return useQuery(['categories-services', 'type', type], async () => {
    const response: AxiosResponse<CategoryAPI[]> = await AuthorizedAPI.get(
      `service-categories?filter[services_type]=${type}&include=services&append=services.is_favorite`
    )

    return response.data.map(category => {
      return {
        ...category,
        services:
          (category.services?.map(service => ({
            ...service,
            icon: category.code,
          })) as ServiceAPI[]) ?? [],
      }
    })
  })
}

export default useAvailableServicesQuery
