import { useDispatch } from 'react-redux'

import { CertificateFlow } from 'domain/createOrder/certificates'
import {
  CreateOrderLegalEntityTabs,
  createOrderActions,
  useCreateOrderState,
} from 'domain/createOrder/document'
import { CreateOrderParamsFields } from 'domain/createOrder/types'
import { useAllExtraInformationsQuery } from 'domain/orderItem/shared'
import { SelectedService } from 'domain/orderItem/types'
import { CNPJAndNameFields, NameField, RadioGroupField, SelectField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../certidaoCEATConfig'

export default function CreateOrderCertidaoCEAT() {
  const dispatch = useDispatch()
  const { selectedRegion, selectedService } = useCreateOrderState()
  const { data, isError, isFetching } = useAllExtraInformationsQuery({
    service: selectedService as SelectedService,
    data: {} as CreateOrderParamsFields
  })
  const models = data ? data.regioes!.find(o => o.url === selectedRegion)?.modelos ?? [] : []

  return (
    <CertificateFlow
      header={
        <>
          <p className="mb-5 text-gray-600">
            Primeiro selecione a região da Jurisdição, depois selecione Pessoa ou Empresa e digite o número do
            CPF ou CNPJ que deseja a Certidão Eletrônica de Ações Trabalhistas.
          </p>

          <p className="mb-5 text-gray-600">
            Disponibilidade de entrega imediata pode sofrer impactos mediante alterações sistêmicas de cada
            estado do órgão emissor.
          </p>

          <SelectField
            field={regiao}
            options={data?.regioes}
            onChange={value => {
              dispatch(createOrderActions.setSelectedRegion(value))
            }}
            loading={isFetching}
            hasError={isError}
            autoFocus
          />

          {models.length > 0 && <RadioGroupField field={modelo} options={models} vertical />}
        </>
      }
      renderFormItem={(itemId: number) => <CertidaoCEATForm itemId={itemId} />}
    />
  )
}

const CertidaoCEATForm = ({ itemId }: { itemId: number }) => {
  return (
    <CreateOrderLegalEntityTabs
      itemId={itemId}
      fisica={
        <>
          <CPFInput itemId={itemId} />
          <NameField itemId={itemId} field={nome_pf} />
        </>
      }
      juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} />}
    />
  )
}

const {
  formFields: { modelo, nome_pf, nome_pj, regiao },
} = config
