import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoDebitosEstaduaisFields = Record<
  FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ | FieldName.URL_UF,
  FieldConfig
>

const { FORMATO, TIPO_PESSOA, URL_UF } = FieldName

class CertidaoDebitosEstaduais extends Certificate {
  formFields: CertidaoDebitosEstaduaisFields = {
    url_uf: Fields.url_uf,
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
  }

  constructor() {
    super({
      id: 22,
      availableFormatsFields: [URL_UF],
      priceByFields: [URL_UF, FORMATO, TIPO_PESSOA],
      shortName: 'SEFAZ - Déb. Estaduais',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoDebitosEstaduais().initialize()

export default service
