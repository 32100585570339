import React, { useEffect, useState } from 'react'

import { SearchInput } from 'ui'
import { useSearch } from '../../state/filters'

export default function RecurrenceSearch() {
  const [search, setSearch] = useSearch()
  const [searchTerm, setSearchTerm] = useState(search)

  useEffect(() => {
    setSearchTerm(search)
  }, [search])

  return (
    <SearchInput
      placeholder="Pesquise por nome ou número do pedido"
      value={searchTerm}
      onChange={e => setSearchTerm(e.target.value)}
      onSearch={setSearch}
      className="flex-1 transition-all duration-300 max-w-sm"
      allowClear
    />
  )
}
