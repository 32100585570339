import { useState } from 'react'
import { AxiosResponse } from 'axios'

import { errorHandler } from 'api/errors'
import { AuthorizedAPI } from 'api/network/v2'

function useProvideAdditionalInformation(
  orderItemId: number,
  { onSuccess, onError }: { onSuccess: () => void; onError: () => void }
) {
  const [loading, setLoading] = useState(false)

  const provideInformation = async (information: string, fileList: any[]) => {
    try {
      setLoading(true)

      await provideAdditionalInformation(orderItemId, information, fileList)

      onSuccess()
    } catch (err) {
      errorHandler(err, { params: { orderItemId }, code: '0x601' })
      onError()
    } finally {
      setLoading(false)
    }
  }

  return { provideInformation, loading }
}

const provideAdditionalInformation = async (
  orderId: string | number,
  information: string,
  fileList: any[]
) => {
  const formData = new FormData()

  formData.append('description', information)
  fileList.forEach(file => formData.append('files[]', file))

  const response: AxiosResponse<any> = await AuthorizedAPI.post(
    `/orders/${orderId}/additional-information`,
    formData,
    {
      headers: { 'content-type': 'multipart/form-data' },
    }
  )
  return response.data
}

export default useProvideAdditionalInformation
