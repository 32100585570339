import { useMutation } from 'react-query'
import { notification } from 'antd'
import { Dayjs } from 'dayjs'

import { errorHandler } from 'api/errors'
import { AuthorizedAPI } from 'api/network/v2'
import { DATE_FORMAT } from 'utils/dateTime'

const useEditExpirationDateMutation = (orderItemId: number, { onSuccess }: { onSuccess: () => void }) => {
  return useMutation(
    async (validUntil: Dayjs | undefined) => {
      const body = validUntil ? { valid_until: validUntil.format(DATE_FORMAT) } : undefined
      await AuthorizedAPI.patch(`/orders/${orderItemId}/valid-until`, body)
    },
    {
      onError: (error: any) => {
        errorHandler(error, { code: '0x0018' })
      },
      onSuccess: () => {
        notification.success({ message: 'Data de validade editada com sucesso.' })
        onSuccess()
      },
    }
  )
}

export default useEditExpirationDateMutation
