import React, { useReducer } from 'react'

import { PageHeader } from 'layouts'
import { Button } from 'ui'
import { faqBonus } from './CreditsFaq'
import { Popover } from 'antd'

type Props = {
  onOpenSimulator: React.DispatchWithoutAction
}

export default function CreditsPageHeader({ onOpenSimulator }: Props) {
  const [open, toggleOpen] = useReducer(prev => !prev, false)
  return (
    <PageHeader
      title="Créditos"
      alwaysShowExtra
      subTitle={
        <div className="hidden lg:flex items-center">
          <span className="text-xs xl:text-sm text-right">
            Quanto maior o valor da recarga de créditos, mais bônus você ganha!
          </span>
          <Popover
            content={
              <div className="max-w-80">
                {faqBonus.answer}

                <Button onClick={toggleOpen} type="link">
                  Fechar
                </Button>
              </div>
            }
            title={<span className="font-bold text-secondary-500 py-1">{faqBonus.question}</span>}
            trigger="click"
            open={open}
            onOpenChange={toggleOpen}
          >
            <Button type="link" size="small">
              Saiba mais
            </Button>
          </Popover>
        </div>
      }
      extra={
        <Button size="middle" type="primary" icon="wallet" iconSize={20} onClick={onOpenSimulator} className="rounded px-8">
          <span className="font-semibold text-sm">Simular recarga</span>
        </Button>
      }
    />
  )
}
