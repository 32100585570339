import React, { useState } from 'react'

import { OrderList } from 'domain/orders/types'
import { DrawerActions } from 'ui'

import MassDownload from './OrdersMassDownload'
import MassExportExcel from './OrderMassExportExcel'
import MassRecurrence from './OrdersMassRecurrence'

interface Props {
  selected: OrderList[]
  onClose: () => void
}

// TODO: criar ação de refazer pedido
export default function OrdersMassActions({ selected, onClose }: Props) {
  const [enableEsc, setEnableEsc] = useState(true)

  return (
    <DrawerActions onClose={onClose} total={selected.length} keyboard={enableEsc}>
      <MassDownload selected={selected} setEnableEsc={setEnableEsc} />
      <MassExportExcel selected={selected} />
      <MassRecurrence selected={selected} onClose={onClose} />
    </DrawerActions>
  )
}
