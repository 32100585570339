import React from 'react'
import { Form } from 'antd'

import { Select } from 'ui'

function KitsFilterEntityType() {
  return (
    <Form.Item name="restrictEntityTypeTo" label="Tipo pessoa">
      <Select
        size="middle"
        placeholder="Selecione um tipo de pessoa"
        options={data}
        optionLabelKey="name"
        optionValueKey="id"
      />
    </Form.Item>
  )
}

const data = [ 
  { name: 'Indefinido', id: 'null' },
  { name: 'Pessoa Jurídica', id: 'PJ' },
  { name: 'Pessoa Física', id: 'PF' },
]

export default KitsFilterEntityType
