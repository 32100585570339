import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { Group } from 'domain/groups/types'

export default function useGroupsByIdsQuery(ids: number[]) {
  return useQuery(
    ['groups', 'id', ids],
    async () => {
      const response: AxiosResponse<Group[]> = await AuthorizedAPI.get('/groups', {
        params: {
          'filter[id]': ids.join(','),
        },
      })

      return response.data ?? []
    },
    {
      enabled: ids.length > 0,
    }
  )
}
