import React from 'react'
import { Collapse, Form } from 'antd'

import { CategoryAPI } from 'domain/servicesCBRdoc/typesAPI'
import { Label, Icon } from 'ui'

import { useCreateKit } from './CreateKitContext'
import CheckboxService from './CreateKitStep2Checkbox'

interface Props {
  category: CategoryAPI
}

const { Panel } = Collapse

export default function ServicePanel({ category, ...props }: Props) {
  const { items } = useCreateKit()
  const countSelectedServices = items.filter(item => item.service.categoryId === category.id).length

  return (
    <Panel
      key={category.id}
      header={
        <>
          <Icon name={category.code as any} className="text-xl mr-3" style={{ marginLeft: '-12px' }} />{' '}
          {category.name}
        </>
      }
      extra={
        countSelectedServices > 0 && (
          <Label color="red" className="mr-16">
            {countSelectedServices}
          </Label>
        )
      }
      {...props}
    >
      <Form.Item>
        {category.services.map(service => (
          <div key={`${category.id}_${service.id}`} className="display-block mt-4">
            <CheckboxService categoryId={category.id} service={service} />
          </div>
        ))}
      </Form.Item>
    </Panel>
  )
}
