import React from 'react'
import { Form, Tag } from 'antd'

import { daysOfWeek } from 'domain/app/data'

const { CheckableTag } = Tag

interface Props {
  value: number[]
  onChange: (value: number[]) => void
}

export default function DaysOfWeekInput({ value, onChange }: Props) {
  const form = Form.useFormInstance()

  const handleChange = (weekDay: number) => (checked: boolean) => {
    const current = [...value]

    if (checked) {
      current.push(weekDay)
    } else {
      const index = current.indexOf(weekDay)
      current.splice(index, 1)
    }

    form.setFieldValue('daysOfWeek', current)
    onChange(current)
  }

  return (
    <Form.Item rules={validation} label="Ativado sempre às" name="daysOfWeek">
      <>
        {daysOfWeek.map(dayOfWeek => (
          <CheckableTag
            key={dayOfWeek.value}
            className="border-3 border-secondary-300 px-4 py-3 text-base"
            checked={value.indexOf(dayOfWeek.value) > -1}
            onChange={handleChange(dayOfWeek.value)}
          >
            {dayOfWeek.label}
          </CheckableTag>
        ))}
      </>
    </Form.Item>
  )
}

const validation = [{ required: true, message: 'Informe os dias da recorrência.' }]
