import React from 'react'
import { Button } from 'antd'

import { Group } from 'domain/groups/types'
import { Modal } from 'ui'

import AddNewGroup from './CreateGroups'
import List from './GroupsAssociationList'

interface Props {
  selectedGroups: Group[]
  open: boolean
  setSelectedGroups: React.Dispatch<React.SetStateAction<Group[]>>
  loading?: boolean
  onDeleteGroup?: (groupId: number) => void
  onFinish: (groups: Group[]) => void
  onClose: () => void
}

export default function GroupsAssociationModal({
  selectedGroups,
  open,
  setSelectedGroups,
  loading,
  onFinish,
  onClose,
  onDeleteGroup,
}: Props) {
  const handleFinish = () => {
    onFinish(selectedGroups)
    onClose()
  }

  const onFinishNewGroups = async (newGroups: Group[]) => setSelectedGroups([...selectedGroups, ...newGroups])

  return (
    <Modal open={open} title="Grupos do Item" onCancel={onClose}>
      <List selectedGroups={selectedGroups} onSelectGroup={setSelectedGroups} onDeleteGroup={onDeleteGroup} />

      <AddNewGroup onFinish={onFinishNewGroups} />

      <Button type="primary" className="mt-5" onClick={handleFinish} loading={loading} block>
        Concluir
      </Button>
    </Modal>
  )
}
