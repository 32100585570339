import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs, useCreateOrderState } from 'domain/createOrder/document'
import { CNPJAndNameFields, DateField, NameField, NumberField, TextField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../certidaoDebitosMunicipaisConfig'

export default function CreateOrderCertidaoDebitosMunicipais() {
  return (
    <CertificateFlow
      header={
        <p className="mb-5 text-gray-600">
          Selecione Pessoa ou Empresa e complete as informações de quem deseja a Certidão de Débitos
          Municipais.
        </p>
      }
      renderFormItem={(itemId: number) => <CertidaoDebitosMunicipaisForm itemId={itemId} />}
    />
  )
}

const CertidaoDebitosMunicipaisForm = ({ itemId }: { itemId: number }) => {
  const inputProps = { itemId, autoFocus: itemId === 0 }
  const { selectedLocation: location } = useCreateOrderState()

  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={
          <>
            <CPFInput {...inputProps} />
            <NameField itemId={itemId} field={nome_pf} />

            {inscricao_cadastral.showIf!(location) && (
              <TextField itemId={itemId} field={inscricao_cadastral} />
            )}
            {mae.showIf!(location) && <NameField itemId={itemId} field={mae} />}
            {nascimento.showIf!(location) && <DateField itemId={itemId} field={nascimento} />}

          </>
        }
        juridica={
          <>
            <CNPJAndNameFields {...inputProps} companyField={nome_pj} />
            {inscricao_cadastral.showIf!(location) && (
              <TextField itemId={itemId} field={inscricao_cadastral} />
            )}
            {inscricao_municipal.showIf!(location) && (
              <TextField itemId={itemId} field={inscricao_municipal} />
            )}
          </>
        }
      />

      {inscricao_mercantil.showIf!(location) && (
        <NumberField itemId={itemId} field={inscricao_mercantil} />
      )}

    </>
  )
}

const {
  inscricao_mercantil,
  inscricao_cadastral,
  inscricao_municipal,
  mae,
  nascimento,
  nome_pf,
  nome_pj,
} = config.formFields
