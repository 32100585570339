import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

require('dayjs/locale/pt-br')

dayjs.extend(customParseFormat)

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY'

export type DateRange = dayjs.Dayjs[]

export const validateDate = (value: string | undefined, format?: dayjs.OptionType) => {
  if (!value) return false
  if (value === '0000-00-00') return false
  return dayjs(value, format ?? DISPLAY_DATE_FORMAT, true).isValid()
}

export const parseDate = (value: Date | string | dayjs.Dayjs, format?: dayjs.OptionType) => {
  return dayjs(value, format)
}

/**
 * Retorna uma data formatada para mostrar para o
 * usuario.
 *
 * @param date Input javascript Date type
 * @returns formatted day in format DD/MM/YYYY
 */
export function displayDate(date: Date | string | undefined | null | Dayjs) {
  if (!date) return ''
  if (date === '0000-00-00') return ''
  return dayjs(date).format(DISPLAY_DATE_FORMAT)
}

export function displayDateAndTime(date: Date | string | undefined | null) {
  if (!date) return ''
  return dayjs(date).format('DD/MM/YY HH:mm')
}

/**
 * Retorna uma data com o mes escrito como abreviacao
 *
 * @param date input javascript date
 * @returns string with date formated '5 set 1991 '
 */
export function displayDateWithMonth(date: Date | string) {
  return dayjs(date).format('D MMM YYYY')
}

export function displayNiceDate(date: Date, prefix?: string): string {
  const returnedDate = dayjs(date)
  const isToday = returnedDate.isSame(new Date(), 'day')

  if (isToday) return 'hoje'

  const yesterday = dayjs().subtract(1, 'days')
  const isYesterday = returnedDate.isSame(yesterday, 'day')

  if (isYesterday) return 'ontem'

  const formattedDate = displayDate(date)

  return prefix ? `${prefix} ${formattedDate}` : formattedDate
}

export function getLastThirtyDaysRange() {
  const daysAgo = dayjs().subtract(30, 'day').toDate()
  const today = new Date()

  return [daysAgo, today]
}

export function startOfMonth(date?: Date | string): string {
  return dayjs(date).startOf('month').format(DATE_FORMAT)
}

export function endOfMonth(date?: Date | string): string {
  return dayjs(date).endOf('month').format(DATE_FORMAT)
}

export function getMonthPeriod(currentDate?: Date | string) {
  const currentMonth = dayjs(currentDate).format('MMM/YY').toUpperCase()

  return {
    startOfMonth: startOfMonth(currentDate),
    endOfMonth: endOfMonth(currentDate),
    currentMonth,
  }
}

export function getMonthNumber(currentDate: Date | Dayjs) {
  const parsed = parseDate(currentDate)
  return parsed.get('month') + 1
}

export function getDayNumber(currentDate: Date | Dayjs) {
  const parsed = parseDate(currentDate)
  return parsed.get('date')
}

/**
 * Funcao para retorar o final do dia.
 * @returns Date
 */
export function displayEndOfDay() {
  return dayjs().endOf('day').toDate()
}

export function displayDateISOString(date?: Date | string) {
  return dayjs(date).toISOString()
}

export function displayDay(date?: Date | string | Dayjs) {
  return dayjs(date).format('DD')
}

export function displayMonth(date?: Date | string) {
  return dayjs(date).startOf('month').format('M')
}

export function displayMonthString(date?: Date | string | Dayjs) {
  return dayjs(date).startOf('month').format('MMMM')
}

export function displayYear(date?: Date | string) {
  return dayjs(date).endOf('month').format('YYYY')
}

export const displayOperationDate = (operationTime: string | null) => {
  const today = dayjs()
  const date = dayjs(operationTime, DATE_FORMAT)

  if (date.isSame(today, 'day')) {
    return 'hoje'
  }

  const yesterday = dayjs().subtract(1, 'day')

  if (date.isSame(yesterday, 'day')) {
    return 'ontem'
  }

  return date.format('DD MMM YY').toUpperCase()
}

export function displayMonthAndYear(date: Date | string) {
  return dayjs(date, 'YYYY-MM').format('MMM/YY')
}

export function displayDateSmallYear(date: Date | string) {
  return dayjs(date).format('DD/MM/YY')
}

/**
 * Retrona uma string formatada segundo a doc
 *
 * @param date
 * @returns return formated date MMMM D, YYYY
 */
export function displayFulMonthDayYearString(date?: Date | string | Dayjs) {
  return dayjs(date).format('MMMM D, YYYY')
}

export function subtractByMonths(date: Date | string, months: number) {
  return dayjs(date).subtract(months, 'months').toDate()
}

export function addDateByMonths(date: Date | string, months: number) {
  return dayjs(date).add(months, 'months').toDate()
}

export function isDateFormatToApi(date: Date | string) {
  return dayjs(date, DATE_FORMAT, true).isValid()

}

export function formatDateToApi(date?: Date | string | Dayjs | null) {
  if (!date) return null

  if (typeof date === 'string') {
    date = date.split('/').reverse().join('-')
  }

  const format = dayjs(date).format(DATE_FORMAT)

  if (format === 'Invalid Date') return null
  
  return format
}

export function formatDate(date: Date | string | Dayjs) {
  return dayjs(date).format(DATE_FORMAT)
}

export function formatCreditCardDate(date: string) {
  return dayjs(date, 'MM/YY').format('YYYY-MM')
}

export function formatYearAndMonth(date: Date | string) {
  return dayjs(date).format('YYYY-MM')
}

export function formatDatesToAPI(dates: [string, string] | undefined) {
  if (!dates || !dates[0] || !dates[1]) {
    return undefined
  }

  const beginDate = dayjs(dates[0]).format(DATE_FORMAT)
  const endDate = dayjs(dates[1]).format(DATE_FORMAT)

  return `${beginDate},${endDate}`
}

export function convertDateRage(dates: DateRange | undefined) {
  if (!dates || !dates[0] || !dates[1]) {
    return ''
  }

  const beginDate = dates[0].format(DATE_FORMAT)
  const endDate = dates[1].format(DATE_FORMAT)

  return `${beginDate},${endDate}`
}

function diffBetweenDates(dateBefore: Date | string, dateAfter: Date | string, diff: 'day' | 'hour') {
  const date1 = dayjs(dateAfter)
  const date2 = dayjs(dateBefore)
  return date1.diff(date2, diff as any)
}

export function diffDays(dateBefore: Date | string, dateAfter: Date | string = new Date()) {
  return diffBetweenDates(dateBefore, dateAfter, 'day')
}

export function diffHours(dateBefore: Date, dateAfter: Date) {
  return diffBetweenDates(dateBefore, dateAfter, 'hour')
}

export function stringToDate(date: string | null) {
  if (!date) return null
  return dayjs(date).toDate()
}

export function getTomorrow() {
  return dayjs().add(1, 'day')
}

export function jsDate(date: string | undefined) {
  return !date ? undefined : dayjs(date)
}

export function isAfterToday(date: string) {
  const isDisplayFormat = validateDate(date)

  if (isDisplayFormat) {
    const formatedDate = formatDateToApi(date)

    return dayjs(formatedDate).isAfter(new Date(), 'day')
  }

  return dayjs(date).isAfter(new Date(), 'day')
}

export function isAfter(date1: Date | string, date2: Date = new Date()) {
  return dayjs(date1, DISPLAY_DATE_FORMAT).isAfter(date2, 'date')
}

export function isBefore(date1: Date | string, date2: Date = new Date()) {
  return dayjs(date1).isBefore(date2, 'date')
}

export function isToday(date: string) {
  return dayjs().isSame(date, 'date')
}

export function isTomorrow(date: string) {
  return dayjs().add(1, 'day').isSame(date, 'date')
}

export function getExcelDateToJSDate(excelSerialDate: number) {
  const d = new Date(-2209075200000 + excelSerialDate * 86400000)
  return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
}
