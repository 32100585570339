import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { SimulationInput, SimulationInputItemData, SimulationOutput } from './types'

export const getSimulation = async (simulationInput: SimulationInput) => {
  const newInput: SimulationInput = []

  simulationInput.forEach(input => {
    if (input.url_uf) {
      const newInputData: SimulationInputItemData[] = []

      input.data.forEach(data => {
        if (data.service_id) {
          newInputData.push(data)
        }
      })

      if (newInputData.length > 0) {
        input.data = newInputData
        newInput.push(input)
      }
    }
  })

  const response: AxiosResponse<SimulationOutput> = await AuthorizedAPI.post('/simulator', {
    simulated_data: newInput
  })
  return response.data
}
