import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

import { EditOrderType } from '../types'

const editingOrderAtom = atom<EditOrderType | null>(null)

export const useEditingOrder = () => useAtom(editingOrderAtom)
export const useSetEditingOrder = () => useSetAtom(editingOrderAtom)

const overviewRefreshQueryAtom = atom(0)

export const useGetOverviewRefresh = () => useAtomValue(overviewRefreshQueryAtom)

export const useRefreshOverviewQuery = () => {
  const setRefresh = useSetAtom(overviewRefreshQueryAtom)
  return () => setRefresh(Date.now())
}
