import { PrepaidInvoice, PrepaidInvoicesReportAPI } from 'domain/invoices/types'
import { displayDate } from 'utils/dateTime'
import { formatMoney } from 'utils/formatters'

function convertPrepaidInvoiceReport(id: number, invoiceAPI: PrepaidInvoicesReportAPI): PrepaidInvoice {
  const {
    invoice_amount,
    invoice_operation,
    invoice_bonus_amount,
    invoice_approved_at,
    purchase_placed_at,
    user_name,
    purchase_name,
    purchase_backoffice_id,
  } = invoiceAPI

  return {
    amount: invoice_amount,
    amountDisplay: (invoice_operation === 'C' ? '+' : '-') + formatMoney(invoice_amount),
    approvedAtDisplay: displayDate(invoice_approved_at),
    createdAtDisplay: displayDate(purchase_placed_at),
    deliveredAtDisplay: getDeliveredDate(invoiceAPI),
    order: {
      name: purchase_name,
      orderNumber: purchase_backoffice_id,
    },
    bonusAmountDisplay: formatMoney(invoice_bonus_amount),
    user: {
      name: user_name,
    },
    operation: invoice_operation,
    id,
  }
}

const getDeliveredDate = (invoice: PrepaidInvoicesReportAPI) => {
  if (invoice.invoice_operation === 'C' && invoice.invoice_approved_at) {
    return displayDate(invoice.invoice_approved_at)
  }

  if (invoice.invoice_operation === 'D' && invoice.purchase_finished_at) {
    return displayDate(new Date(invoice.purchase_finished_at))
  }

  return '-'
}

export default convertPrepaidInvoiceReport
