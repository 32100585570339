import { useIsOCRSearch, useSearchTerm } from 'domain/orders/state/filters'
import SearchInput from './SearchInput'

export default function OrdersSearch({ className }: { className?: string }) {
  const [isOCRSearch, setIsOCRSearch] = useIsOCRSearch()
  const [searchTerm, setSearchTerm] = useSearchTerm()

  const handleOCRCheck = (isOCR: boolean, searchTerm: string) => {
    setSearchTerm(searchTerm.trim())
    setIsOCRSearch(isOCR)
  }

  const setSearch = (text: string) => {
    setSearchTerm(text.trim())
  }

  return (
    <SearchInput
      text={searchTerm}
      isOCR={isOCRSearch}
      setIsOCR={handleOCRCheck}
      onSearch={setSearch}
      className={className}
      inline
    />
  )
}
