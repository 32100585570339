import { useDontKnowBookPagePriceQuery } from 'domain/createOrder/certificates'
import { DontKnowBookPageFields } from 'domain/servicesCBRdoc/fields'
import { useUpdateServiceForm } from '../../state'

interface Props {
  itemId: number | string
  onChangeField?: any
}

export default function KitDontKnowBookPage({ itemId, onChangeField }: Props) {
  const updateForm = typeof itemId === 'number' ? useUpdateServiceForm(itemId) : undefined
  const { data: prices, isFetching } = useDontKnowBookPagePriceQuery()

  return (
    <DontKnowBookPageFields
      itemId={itemId}
      price={prices?.price}
      loading={isFetching}
      onChangeDontKnow={nao_sei_livro_pagina =>
        onChangeField ? onChangeField('nao_sei_livro_pagina', nao_sei_livro_pagina) : updateForm!({ nao_sei_livro_pagina })
      }
    />
  )
}
