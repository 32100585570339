import { OrderItemPurchasable } from 'domain/orderItem/types'
import { OrderItemAPI } from 'domain/orderItem/typesAPI'
import { IconName } from 'ui'

export enum NotificationType {
  ACTION_REQUIRED = 'order-pending-action',
  CERTIFICATE_EXPIRED = 'certificate-expired',
  INFORMATION = 'information',
  ORDER_ITEM_FINALIZED = 'order-finished',
  ORDER_ITEM_UPDATED = 'order-updated',
  SUMMARY_EXTRACTED = 'summary-extracted',
  ORDER_FINALIZED = 'purchase-finished',
  UNAVAILABILITY_WARNING = 'system-down',
}

export type NotificationTypeConfig = {
  code: NotificationType
  text: string
  color: string
  icon: IconName
}

export type LatestNotification = {
  id: number
  type: NotificationType
  title?: string
  text?: string
  linkUrl?: string
  order?: NotificationOrder
  orderItem?: NotificationOrderItem
  createdAt: Date
  createdAtDisplay: string
  readAt: Date | null
}

export interface Notification extends LatestNotification {
  id: number
  type: NotificationType
  title?: string
  text?: string
  linkUrl?: string
  order?: NotificationOrder
  createdAt: Date
  createdAtDisplay: string
  readAt: Date | null
}

export type NotificationOrder = {
  id: number
  orderNumber: number
  name: string
  firstOrder: NotificationOrderItem
  totalItems: number
}

export interface NotificationOrderItem extends OrderItemPurchasable {
  orderItemNumber: number
}

export type NotificationOrderItemAPI = Pick<
  OrderItemAPI,
  | 'ai_service_name'
  | 'backoffice_id'
  | 'detailed_service_data'
  | 'id'
  | 'location_info'
  | 'name'
  | 'register'
  | 'service'
>

export type NotificationAPI = {
  id: number
  data: {
    type: NotificationType
    text?: string
    link?: string
    purchase?: NotificationOrderAPI
    order?: NotificationOrderItemAPI
  }
  read_at: string | null
  created_at: string
}

export type NotificationOrderAPI = {
  id: number
  backoffice_id: number
  name: string
  first_order: OrderItemAPI
  total_items: number
}

export type NotificationMedium = 'mail' | 'database'

export type UserNotificationSettingsAPI = {
  bank_slip_notification_via: NotificationMedium[]
  certificate_expired_notification_via: NotificationMedium[]
  finished_order_notification_via: NotificationMedium[]
  finished_purchase_notification_via: NotificationMedium[]
  pending_action_notification_via: NotificationMedium[]
  placed_purchase_notification_via: NotificationMedium[]
  refunded_order_notification_via: NotificationMedium[]
  spreadsheet_placed_purchase_notification_via: NotificationMedium[]
  system_information_notification_via: NotificationMedium[]
  system_unavailable_notification_via: NotificationMedium[]
  summary_extracted_notification_via: NotificationMedium[]
}

export type NotificationSettingName =
  | 'bankSlipCreated'
  | 'certificateExpired'
  | 'finishedOrder'
  | 'finishedOrderItem'
  | 'pendingAction'
  | 'placedOrder'
  | 'refundedOrder'
  | 'spreadsheetPlacedOrder'
  | 'systemInformation'
  | 'systemUnavailable'
  | 'extractedSummary'

export type UserNotificationSettings = Record<NotificationSettingName, NotificationMedium[]>

export type NotificationConfigCode = keyof UserNotificationSettingsAPI

export type NotificationConfigFieldName = `${NotificationMedium}_${NotificationSettingName}`
export type NotificationConfigForm = Record<NotificationConfigFieldName, boolean>

export type NotificationConfig = {
  code: NotificationConfigCode
  description: string
  label: string
}

export type SettingsByMedium = {
  medium: NotificationMedium
  label: string
  settings: NotificationSettingName[]
}
