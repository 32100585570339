import { Form } from 'antd'

import { FederativeUnitsField, IBGECidadeNascimentoSelect } from 'domain/servicesCBRdoc/fields'
import config from '../certidaoInterdicaoConfig'

interface Props {
  itemId: number
}

export default function PlaceOfBirthFields({ itemId }: Props) {
  const form = Form.useFormInstance()
  const federativeUnit = Form.useWatch([itemId, uf_nascimento.name])
  const cityName = [itemId, cidade_nascimento.name]

  return (
    <>
      <FederativeUnitsField
        field={uf_nascimento}
        itemId={itemId}
        labelInValue={false}
        onChange={() => {
          form.setFieldValue(cityName, undefined)
        }}
      />

      <IBGECidadeNascimentoSelect
        federativeUnit={federativeUnit}
        name={cityName}
        required={!cidade_nascimento.optional}
      />
    </>
  )
}

const { cidade_nascimento, uf_nascimento } = config.formFields
