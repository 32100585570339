import { useMutation } from 'react-query'
import { message } from 'antd'

import { AuthorizedAPI } from 'api/network/v2'
import queryClient from 'app/config/queryClient'
import { Kit } from 'domain/kits/types'

import { convertPartialKitToAPI } from './convertToAPI'

type MutationParams = {
  kitId: number
  data: Partial<Kit>
}

function useEditKitMutation(queryKey: any, kitId: number, options: any = {}) {
  return useMutation(
    async (data: any) => {
      await AuthorizedAPI.patch(`/kits/${kitId}`, convertPartialKitToAPI(data))
    },
    {
      onMutate: async ({ data }: MutationParams) => {
        const previousKit = queryClient.getQueryData(queryKey) as Kit

        // Add optimistic items to kit query
        queryClient.setQueryData(queryKey, {
          ...previousKit,
          ...data,
        })

        // Return context with the optimistic items
        return { previousKit }
      },
      onError: (data, variables, context: any) => {
        message.error('Ocorreu um erro ao tentar editar o kit.')
        queryClient.setQueryData(queryKey, context.previousKit)
      },
      onSuccess: (data, variables) => {
        queryClient.setQueryData(queryKey, (old: any) => ({
          ...old,
          ...variables,
        }))

        message.success('Dados atualizados com sucesso.')
      },
      ...options,
    }
  )
}

export default useEditKitMutation
