import React from 'react'

interface Props {
  selected?: boolean
  children: React.ReactNode
  onClick?: () => void
}

export default function SelectableButton({ selected, ...props }: Props) {
  const styles = selected
    ? 'bg-secondary-500 text-white hover:bg-secondary-600'
    : 'border-gray-600 text-gray-600 hover:text-white hover:bg-secondary-500 hover:border-secondary-500 transition transition-colors'

  return <button type="button" className={`border rounded-full px-4 py-2 text-sm active:bg-primary cursor-pointer ${styles}`} {...props} />
}
