import React from 'react'

import { OrderItemListing, SelectedService } from 'domain/orderItem/types'
import { CertificateCode, SignatureCertificationCode } from 'domain/servicesCBRdoc/types'
import { getDetailText } from 'utils/formatters'

interface Props {
  item: OrderItemListing
}

export default function ServiceName({ item }: Props) {
  let description = ''

  if (item.service.code === CertificateCode.CERTIDAO_JUSTICA_ESTADUAL) {
    description = ' - ' + getDetailText(item.submitData.instancia)
  }

  return (
    <>{getSimplifiedServiceName(item.service)} {description}</>
  )
}

function getSimplifiedServiceName(service: SelectedService) {
  if (service.code === SignatureCertificationCode.COLETA_ASSINATURA) {
    return 'Assinatura'
  }

  if (service.code === SignatureCertificationCode.CERTIFICADO_DIGITAL_CPF) {
    return 'Certificado Digital'
  }

  return service.name
}
