import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

import { MassSelection } from 'domain/app/types'
import { Notification } from '../types'
import { currentPage } from './pagination'

const initialState: MassSelection<Notification> = { keys: [], rows: []}

const selection = atom(initialState)

const resetSelectedRows = atom(
  () => null,
  (get, set) => {
    set(currentPage, 1)
    set(selection, initialState)
  }
)

export const useGetTableSelection = () => useAtomValue(selection)

export const useResetTableSelection = () => useSetAtom(resetSelectedRows)

export const useTableSelection = () => useAtom(selection)
