import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaProcuracaoFields = Record<
  FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ,
  FieldConfig
>

class PesquisaProcuracao extends Research {
  formFields: PesquisaProcuracaoFields = {
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    cnpj: Fields.cnpj,
    nome_pj: Fields.nome_pj,
  }

  constructor() {
    super({
      description: 'Retorna se o CPF ou CNPJ pesquisado possui alguma certidão de procuração.',
      id: 13,
      shortName: 'Pesquisa Procuração',
    })
  }
}

const service = new PesquisaProcuracao().initialize()

export default service
