import {
  CreateOrderFlow,
  CreateOrderFlowProps,
  MultipleItemsSidebar,
  UploadStep,
} from 'domain/createOrder/document'
import SignatureCertificationSidebar from './SignatureCertificationSidebar'

interface Props extends Omit<CreateOrderFlowProps, 'locationStep' | 'sidebar'> {
  header?: React.ReactNode
}

export default function SignatureCertificationFlow(props: Props) {

  return (
    <CreateOrderFlow
      sidebar={<MultipleItemsSidebar />}
      uploadStep={<UploadStep header={<p className="mb-4">Cada arquivo irá gerar um pedido diferente.</p>} />}
      {...props}
    />
  )
}
