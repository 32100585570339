import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaCARFields= Record<
  FieldName.CAR,
  FieldConfig
>

class PesquisaCAR extends Research {
  formFields: PesquisaCARFields = {
    car: Fields.car,
  }

  constructor() {
    super({
      description: 'Retorna a situação do cadastro ambiental rural.',
      id: 70,
      orderNameField: FieldName.CAR,
      shortName: 'Pesquisa CAR',
    })
  }
}

const service = new PesquisaCAR().initialize()

export default service
