import { SelectLocationFields } from 'domain/createOrder/document'
import { ResearchFlow } from 'domain/createOrder/researches'
import { CNPJAndNameFields } from 'domain/servicesCBRdoc/fields'

import config from '../pesquisaJuntaComercialConfig'

export default function CreateOrderPesquisaJuntaComercial() {
  return (
    <ResearchFlow
      header={<SelectLocationFields autoFocus />}
      renderFormItem={(itemId: number) => <PesquisaJuntaComercialForm itemId={itemId} />}
    />
  )
}

function PesquisaJuntaComercialForm({ itemId }: { itemId: number }) {
  return <CNPJAndNameFields itemId={itemId} companyField={razao_social} />
}

const { razao_social } = config.formFields
