import React, { useState } from 'react'

import { Modal, SuccessModalMessage } from 'ui'
import ChangePasswordModalForm from './ChangePasswordModalForm'

interface Props {
  open?: boolean
  onRequestClose: () => void
}

const ChangePasswordModal = ({ open, onRequestClose }: Props) => {
  const [passwordChanged, setPasswordChanged] = useState(false)

  const afterClose = () => setPasswordChanged(false)

  return (
    <Modal open={open} title="Alterar senha" onCancel={onRequestClose} afterClose={afterClose} destroyOnClose>
      {!passwordChanged ? (
        <ChangePasswordModalForm setPasswordChanged={setPasswordChanged} />
      ) : (
        <SuccessModalMessage title="Senha alterada com sucesso" onClick={onRequestClose}>
          <p className="my-7">Você já pode logar com sua nova senha.</p>
        </SuccessModalMessage>
      )}
    </Modal>
  )
}

export default ChangePasswordModal
