import React from 'react'

import { LatestNotification } from 'domain/notifications/types'

import NotificationIcon from '../NotificationIcon'
import NotificationType from '../NotificationType'
import NotificationDate from '../NotificationDate'
import NotificationContent from '../NotificationContent'
import NotificationClose from './NotificationClose'

interface Props {
  notification: LatestNotification
}

function TopBarNotificationsCard({ notification }: Props) {
  const { createdAtDisplay, id, readAt, type } = notification

  return (
    <div className="flex flex-row justify-between gap-2 cursor-default">
      <NotificationIcon type={type} />

      <div className="flex flex-col leading-5 w-80 max-w-80 group py-1">
        <div className="flex flex-row justify-between gap-3">
          <NotificationType type={type} />
          <NotificationClose notificationId={id} readAt={readAt} />
        </div>
        <NotificationDate date={createdAtDisplay} />
        <div className="mt-1">
          <NotificationContent shortenContentText notification={notification} />
        </div>
      </div>
    </div>
  )
}

export default TopBarNotificationsCard
