import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { RecurrenceAPI } from '../types'
import { convertRecurrence } from './convertRecurrence'

type Params = { include?: string, append?: string }

export default function useRecurrenceQuery(recurrenceId: number, params?: Params) {
  return useQuery(
    ['recurrence', recurrenceId],
    async () => {
      const response: AxiosResponse<RecurrenceAPI> = await AuthorizedAPI.get(`/recurrences/${recurrenceId}`, {
        params,
      })

      return convertRecurrence(response.data)
    }
  )
}
