import React from 'react'

import { formatMoney } from 'utils/formatters'

interface Props {
  countItems: number
  totalCost: number
}

function SpreadsheetReviewOrder({ countItems, totalCost }: Props) {
  return (
    <div className="py-6">
      <div className="flex justify-between border-b border-gray-300">
        <div className="text-gray-600 mb-2">Nº de pedidos:</div>
        <div className="font-bold">{countItems}</div>
      </div>
      <div className="flex justify-between items-center py-4 border-b border-gray-300">
        <div className="text-gray-600">Custo total:</div>
        <div className="font-bold text-xl flex-1 text-right">{formatMoney(totalCost)}</div>
      </div>
    </div>
  )
}

export default SpreadsheetReviewOrder
