import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { LabelInValue } from 'domain/app/types'
import { AuthorizedAPI } from 'api/network/v2'
import { SelectedService } from 'domain/createOrder/types'
import { LocationAPI } from 'domain/orders/typesAPI'

import { getServiceConfig } from '../products'

function useNotariesQuery(
  service: SelectedService,
  federativeUnit: string | undefined,
  city: string | undefined
) {
  return useQuery(
    [`notaries-${service.id}-${service.categoryId}`, federativeUnit, city],
    async (): Promise<LabelInValue[]> => {
      const response: AxiosResponse<LocationAPI[]> = await AuthorizedAPI.get(
        `services/${service.id}/categories/${service.categoryId}/federative-units/${federativeUnit}/${city}`
      )

      if (!response.data) return []

      const serviceConfig = getServiceConfig(service.code!)
      return serviceConfig.getAvailableNotaries(response.data)
    },
    {
      enabled: Boolean(service.id && service.categoryId && federativeUnit && city),
    }
  )
}

export default useNotariesQuery
