import { NamePath } from 'antd/lib/form/interface'

import { getFieldName } from 'domain/createOrder/shared'
import { RadioGroupButtonsField, useSelectDefaultOption } from 'domain/servicesCBRdoc/fields'

import config from '../certidaoRAISConfig'

interface Props {
  itemId: number
  onChangeType: (value: string) => void
  name?: NamePath
}

export default function CertidaoRAISType({ itemId, onChangeType }: Props) {
  const name = getFieldName(tipo.name, itemId)

  useSelectDefaultOption({ field: tipo, name, onChange: onChangeType })

  return <RadioGroupButtonsField field={tipo} itemId={itemId} onChange={onChangeType as any} />
}

const { tipo } = config.formFields
