import React from 'react'

import { useIsExpired } from 'domain/orders/state/filters'
import { SelectableButton } from 'ui'

function FilterExpired() {
  const [isExpired, setIsExpired] = useIsExpired()

  return (
    <SelectableButton
      selected={isExpired}
      onClick={() => {
        setIsExpired(isExpired ? undefined : true)
      }}
    >
      Vencidos
    </SelectableButton>
  )
}

export default FilterExpired
