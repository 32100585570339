import { atom, useAtomValue, useSetAtom } from 'jotai'

const currentPage = atom(1)
const pageSize = atom(10)

const pagination = atom((get) => {
  return {
    currentPage: get(currentPage),
    pageSize: get(pageSize),
  }
})

export const usePagination = () => useAtomValue(pagination)
export const useSetPage = () => useSetAtom(currentPage)
export const useSetPageSize = () => useSetAtom(pageSize)
