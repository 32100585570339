import { useDispatch } from 'react-redux'

import { useAllExtraInformationsQuery } from 'domain/orderItem/shared'
import { SelectedService } from 'domain/orderItem/types'
import { AllExtraInfos } from 'domain/orderItem/types'
import { createOrderActions, useCreateOrderState } from '../state'

interface Props {
  itemId?: number
}

export default function useLoadExtraInfos({ itemId }: Props = {}) {
  const dispatch = useDispatch()
  const createOrderState = useCreateOrderState()
  const { selectedService } = createOrderState

  return useAllExtraInformationsQuery({
    service: selectedService as SelectedService,
    data: createOrderState,
    itemId,
    onSuccess: async (data: AllExtraInfos) => {
      if (data['campos-necessarios']) {
        if (itemId !== undefined) {
          dispatch(
            createOrderActions.setMultipleItemsDynamicFields({
              id: itemId,
              value: data['campos-necessarios'],
            })
          )
        } else {
          dispatch(createOrderActions.setDynamicFields(data['campos-necessarios']))
        }
      }

      return data
    },
  })
}
