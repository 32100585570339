import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useIsCustomerPostPaid } from 'domain/customers/'
import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document'
import { CreateOrderFormItem, PriceShippingInfo } from 'domain/createOrder/types'

import TotalPrice from './TotalPrice'
import TotalPriceForPrePaidCustomer from './TotalPriceForPrePaidCustomer'

interface Props {
  loading?: boolean
  prices: PriceShippingInfo | undefined | null
  sameNotaries?: CreateOrderFormItem
}

function TotalPriceForCustomer({ loading, prices }: Props) {
  const dispatch = useDispatch()
  const { countItems, orderDetails, formItems } = useCreateOrderState()
  const isPostPaid = useIsCustomerPostPaid()

  useEffect(() => {
    if (prices) {
      dispatch(
        createOrderActions.setOrderDetails({
          totalValue: isPostPaid ? prices.estimated_price_postpaid_customer : prices.price,
          estimatedDeliveryTime: prices.estimated_delivery_days,
        })
      )
    }
  }, [prices])

  let totalPrice =
    orderDetails && orderDetails.totalValue ? orderDetails.totalValue * (countItems ?? 1) : null

  if (formItems && totalPrice !== null) {
    const subItems = Object.values(formItems).reduce((acc, data) => {
      return acc + (data.countItems || 1)
    }, 0)

    totalPrice *= subItems
  }

  if (isPostPaid) {
    return <TotalPrice totalPrice={totalPrice} loading={loading} />
  }

  return <TotalPriceForPrePaidCustomer totalPrice={totalPrice} loading={loading} />
}

export default TotalPriceForCustomer
