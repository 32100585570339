import { convertAIModel } from 'domain/openAI/models'
import { objectToCamelCase } from 'utils/transformData'
import { ExplorerItem } from '../types'
import { ExplorerItemAPI } from '../typesAPI'
import convertOCR from './convertOCR'

export function convertExplorerItem({ ai_data, ocr, order_id, ...item }: ExplorerItemAPI) {
  const explorerItem: ExplorerItem = {
    ...objectToCamelCase(item),
    ocr: convertOCR(ocr!),
    orderItemId: order_id
  }

  if (ai_data) {
    explorerItem.aiModel = convertAIModel(ai_data)
  }
  
  return explorerItem
}
