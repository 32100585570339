import { CreateOrderLegalEntityTabs } from 'domain/createOrder/document'
import { ResearchFlow } from 'domain/createOrder/researches'
import { CNPJAndNameFields, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../pesquisaProtestoConfig'

export default function CreateOrderPesquisaProtesto() {
  return (
    <ResearchFlow
      header={<p className="mb-5 text-gray-600">Informe o CPF ou CNPJ que deseja pesquisar</p>}
      renderFormItem={(itemId: number) => <PesquisaProtestoForm itemId={itemId} />}
    />
  )
}

function PesquisaProtestoForm({ itemId }: { itemId: number }) {
  return (
    <CreateOrderLegalEntityTabs
      itemId={itemId}
      fisica={
        <>
          <CPFInput itemId={itemId} autoFocus={itemId === 0} />
          <NameField itemId={itemId} field={nome_pf} />
        </>
      }
      juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} autoFocus={itemId === 0} />}
    />
  )
}

const { nome_pf, nome_pj } = config.formFields
