import React from 'react'

import Icon, { IconName } from 'ui/Icon/Icon'

interface CardButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    React.AriaAttributes {
  title: string
  secondIcon?: IconName
  description?: string
  checked?: boolean
  icon?: IconName
  iconSize?: number
  isNew?: boolean
  strokeWidth?: number
  iconOnTitle?: boolean
  iconBackgroundColor?: string
}

export default function CardButton({
  title,
  icon,
  iconSize = 20,
  iconOnTitle = false,
  iconBackgroundColor,
  secondIcon,
  description,
  checked,
  onClick,
  isNew,
  ...props
}: CardButtonProps) {

  const iconElement = (
    <div
      className={`
        flex items-center rounded-lg
        ${checked ? 'text-white' : 'text-gray-700'}
        ${iconOnTitle ? 'justify-start mr-4' : 'justify-center aspect-square min-w-16'}`
      }
      style={{backgroundColor: iconBackgroundColor}}
    >
      <Icon
        name={icon}
        size={iconSize}
        color='currentColor'
        className={`text-3xl ${iconBackgroundColor && 'text-white'}`}
      />
    </div>)

  return (
    <button
      onClick={onClick}
      className={`
        flex gap-6 border border-gray-300 relative cursor-pointer
        rounded leading-6 text-left transition-all duration-200
        hover:shadow-gray-300 hover:shadow-lg active:shadow-inner
        ${iconOnTitle ? 'p-3' : 'p-6 py-4 min-h-[16vh] items-center'}
        ${checked ? 'bg-primary' : 'bg-white'}
      `}
      {...props}
    >
      {icon && !iconOnTitle && !isNew && iconElement}

      <div className="flex flex-col items-between">
        <div className={`flex flex-row ${iconOnTitle ? 'items-center mb-2' : 'justify-between'}`}>
          {iconOnTitle && iconElement}
          <h2
            className={`
              text-lg font-display font-semibold
              ${checked ? 'text-white' : 'text-gray-700'}
              ${icon && secondIcon ? 'mr-16' : 'mr-8'}
              ${description && !iconOnTitle && 'mb-2'}
            `}
          >
            {title}
          </h2>

          {isNew && <div><span className="bg-success-500 rounded-full text-white px-6 py-1 text-xs text-center">Novidade</span></div>}
        </div>
        {description && <p className={`text-sm ${checked ? 'text-white' : 'text-gray-700'}`}>{description}</p>}
      </div>
    </button>
  )
}
