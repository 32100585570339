import { useState } from 'react'
import { Form } from 'antd'

import ExportToExcelButton from './AIExportToExcelButton'
import ModelSelect from './AIExportToExcelModelSelect'

export default function AIExportToExcel() {
  const [modelId, setModelId] = useState<number>()
  const [form] = Form.useForm()

  return (
    <div className="bg-white p-4 my-7 rounded lg:max-w-lg">
      <h3 className="font-bold text-base pb-2 mb-7 border-b border-gray-300 text-gray-600">
        Extração de Dados
      </h3>

      <p className="mb-4 leading-5">
        Selecione abaixo o tipo de documento para exportar, em uma única planilha, todos os campos
        importantes.
      </p>

      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        scrollToFirstError
        className="md:max-w-4xl lg:max-w-lg"
      >
        <ModelSelect onChange={setModelId} />

        <ExportToExcelButton modelId={modelId} />
      </Form>
    </div>
  )
}
