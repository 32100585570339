import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

import { updateItemOnArrayByIndex } from 'utils/transformData'
import { SelectedResearchResults } from '../../types'

const selectedResults = atom<SelectedResearchResults[]>([])
const countSelectedResultsAtom = atom(0)

export function useSelectResearchResults() {
  const [currentSelected, setSelected] = useAtom(selectedResults)
  const setTotalOrders = useSetAtom(countSelectedResultsAtom)

  return function selectResearchResults(selectedOrder: SelectedResearchResults) {
    const { orderItem, selected, selectedResultsKeys } = selectedOrder
    const orderAlreadySelectedIndex = currentSelected.findIndex(o => o.orderItem.id == orderItem.id)
    let selectedOrders = [...currentSelected]

    if (orderAlreadySelectedIndex > -1) {
      selectedOrders = updateItemOnArrayByIndex(
        selectedOrders,
        { selected, selectedResultsKeys },
        orderAlreadySelectedIndex
      )
    } else {
      selectedOrders.push(selectedOrder)
    }

    setSelected(selectedOrders)

    let totalOrders = 0

    selectedOrders.forEach(selectedState => {
      totalOrders += selectedState.selected.length
    })

    setTotalOrders(totalOrders)
  }
}

export function useClearSelectedResults() {
  const setSelected = useSetAtom(selectedResults)
  const setTotal = useSetAtom(countSelectedResultsAtom)

  return function clearSelectedOrders() {
    setSelected([])
    setTotal(0)
  }
}

export const useGetSelectedResults = () => useAtomValue(selectedResults)
export const useGetCountSelectedResults = () => useAtomValue(countSelectedResultsAtom)
