import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'

import { ReactComponent as IlustraPlane } from 'assets/images/ilustra-plane-blue.svg'
import { useGetSearch } from 'domain/invoices/state'

import usePrepaidInvoicesQuery from '../services/usePrepaidInvoicesQuery'
import AlertNoInvoices from './AlertNoInvoices'
import PrepaidInvoicesFilters from './PrepaidInvoicesFilters'
import PrepaidInvoicesTable from './PrepaidInvoicesTable'

function PrepaidInvoices() {
  const searchValue = useGetSearch()
  const { data: invoices, isFetching } = usePrepaidInvoicesQuery()
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    if (invoices?.length === 0 && !searchValue) {
      setShowAlert(true)
    }
  }, [invoices])

  return (
    <>
      {showAlert && <AlertNoInvoices handleClickOutside={() => setShowAlert(false)} />}

      <div className="relative flex-1 flex flex-col">
        <PrepaidInvoicesFilters />

        <div className="p-7 pl-5 pt-0 bg-gray-200 overflow-x-auto flex-1">
          <Spin spinning={isFetching}>
            {invoices && invoices.length > 0 && <PrepaidInvoicesTable invoices={invoices} />}

            {searchValue && invoices?.length === 0 && (
              <div className="py-7 pl-3">
                <IlustraPlane className="mb-4 mx-auto" />
                <h2 className="font-bold text-2xl text-center mb-6">
                  Não foi encontrada nenhuma fatura com esse filtro.
                </h2>
              </div>
            )}
          </Spin>          
        </div>
      </div>
    </>
  )
}

export default PrepaidInvoices
