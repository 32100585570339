import React from 'react'

import { AlertWithOrderButton } from 'domain/orders'
import { FloatingContainer } from 'ui'

interface Props {
  handleClickOutside: () => void
}

function AlertNoInvoices({ handleClickOutside }: Props) {
  return (
    <FloatingContainer handleClickOutside={handleClickOutside}>
      <AlertWithOrderButton
        title="Você ainda não possui faturas"
        description="Depois que você fizer um pedido ou adicionar créditos, as faturas aparecerão aqui"
      />
    </FloatingContainer>
  )
}

export default AlertNoInvoices
