import React from 'react'
import { message, Modal } from 'antd'

import { duplicateKit } from 'domain/kits/services'
import { Kit } from 'domain/kits/types'

import KitItems from '../viewKit/KitItems'

interface Props {
  kit: Kit
  refetch: () => void
}

function DuplicateKitMenuItem({ kit, refetch }: Props) {
  return Modal.confirm({
    title: 'Duplicar kit',
    content: <KitItems items={kit.items} kitName={kit.name} />,
    okText: 'Duplicar',
    cancelText: 'Cancelar',
    onOk: async () => {
      await duplicateKit(kit)
      message.success('O kit foi duplicado com sucesso.')
      refetch()
    },
  })
}

export default DuplicateKitMenuItem
