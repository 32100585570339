import React from 'react'
import { Radio } from 'antd'
import { RadioProps } from 'antd/lib/radio'

export default function RadioCard({ children, ...props }: Omit<RadioProps, 'className'>) {
  return (
    <div className="mb-2">
      <Radio
        className="flex flex-row items-center gap-3 p-5 border border-gray-300 rounded bg-white w-100 [&.ant-radio-wrapper-checked]:border-primary"
        {...props}
      >
        <div>{children}</div>
      </Radio>
    </div>
  )
}
